import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EAggregationFunctionSchema, EMediaGalleryEntryTypeSchema, EPayoutTypeSchema } from '@greenisland/stores';
import { Autocomplete, Button, capitalize, DialogActions, InputAdornment, Stack, TextField } from '@mui/material';

import DateTimeInput from '@greenisland-common/components/molecules/DateTimeInput/DateTimeInput';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';
import SelectInputDurationField from '@greenisland-common/components/organisms/SelectInputDurationField';

import { ContentFilterButton } from '../../../../../Content/ContentFilter/components';
import {
  useBoostDurationValues,
  useBoostLoyaltyDependentOptions,
} from '../../../../Campaigns/Actions/Individual/components/helpers/BoostFormContext';
import EarlyMaxPayoutSection from '../../EarlyMaxPayoutSection';
import LoyaltyDependantMaxPayoutSection from '../../LoyaltyDependantMaxPayoutSection';
import { GlobalBoostForm } from './GlobalBoostCreateForm';

interface Props {
  isLoyaltyDependent: boolean;
  onClose: () => void;
  handleLoyaltyDependentChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}

const GlobalBoostCreateFormFields = ({
  isLoyaltyDependent,
  onClose,
  handleLoyaltyDependentChange,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { register, control, errors, watch, setValue } = useFormContext<GlobalBoostForm>();
  const maxPayout = watch('maxPayout');

  const durationOptions = useBoostDurationValues();
  const options = useBoostLoyaltyDependentOptions();

  const [boostDuration, setBoostDuration] = useState(durationOptions[0]);

  useEffect(() => {
    if (boostDuration) {
      register('boostDurationSelectOption');
      setValue('boostDurationSelectOption', boostDuration.value);
    }
  }, [boostDuration, register, setValue]);

  return (
    <Stack spacing={2} sx={{ m: 2 }}>
      <TextField
        size="small"
        key="name"
        name="name"
        helperText={
          errors['name'] ? capitalize(t('boost.helpText.name.error')) : capitalize(t('boost.helpText.name.default'))
        }
        error={Boolean(errors['name'])}
        inputRef={register({ required: true, maxLength: 256 })}
        type="text"
        required
        label={capitalize(t('boost.name'))}
      />
      <p>{capitalize(t('boost.subtitle.payoutSettings'))}</p>
      <Autocomplete
        size="small"
        disablePortal
        id="aggregation-function-selector"
        options={Object.values([EAggregationFunctionSchema.Max, EAggregationFunctionSchema.Sum])}
        defaultValue={watch('aggregationFunction') || undefined}
        renderInput={params => (
          <TextField
            {...params}
            inputRef={register({ required: true })}
            label={capitalize(t('boost.aggregationFunction'))}
            required={true}
            name="aggregationFunction"
            key="aggregationFunction"
          />
        )}
      />
      <TextField
        size="small"
        key="factor"
        name="factor"
        helperText={
          errors['factor']
            ? capitalize(t('boost.helpText.factor.error'))
            : capitalize(t('boost.helpText.factor.default'))
        }
        error={Boolean(errors['factor'])}
        disabled={!true}
        type="text"
        InputProps={{ endAdornment: <InputAdornment position={'end'}>%</InputAdornment> }}
        required={true}
        inputRef={register({ required: true, min: 1 })}
        label={capitalize(t('boost.factor'))}
      />
      <Autocomplete
        size="small"
        disablePortal
        id="payout-type-selector"
        options={Object.values(EPayoutTypeSchema)}
        defaultValue={watch('payoutType') || undefined}
        renderInput={params => (
          <TextField
            {...params}
            inputRef={register({ required: true })}
            label={capitalize(t('boost.payoutType'))}
            required={true}
            name={'payoutType'}
            key={'payoutType'}
          />
        )}
      />
      <LoyaltyDependantMaxPayoutSection
        isLoyaltyDependentActive={isLoyaltyDependent}
        isLoyaltyDependentRequired={!maxPayout}
        loyaltyDependentName="isLoyaltyDependent"
        changeLoyaltyDependentHandler={handleLoyaltyDependentChange}
        loyaltyDependentOptions={options}
        maxPayoutName="maxPayout"
        isMaxPayoutDisabled={isLoyaltyDependent}
        isMaxPayoutRequired={!isLoyaltyDependent}
      />
      <EarlyMaxPayoutSection name="earlyMaxPayoutEnabled" />
      <p>{capitalize(t('boost.subtitle.contentSettings'))}</p>
      <ContentFilterButton control={control} name="contentFilterId" />
      <p>{capitalize(t('boost.imageSettings'))}</p>
      <MediaFieldsWithPreview
        control={control}
        size="small"
        key="images"
        name="images"
        error={Boolean(errors['images'])}
        type="text"
        required={false}
        label={capitalize(t('addImages'))}
        mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.BoostImage}
        isMultiSelect={true}
      />
      <p>{capitalize(t('boost.subtitle.timeSettings'))}</p>
      <DateTimeInput name={'startDate'} label={capitalize(t('boost.startDate'))} rules={{ required: true }} />
      <SelectInputDurationField
        size="small"
        key="duration"
        name="duration"
        inputRef={register({ required: true, min: 1, setValueAs: value => parseInt(value) })}
        inputProps={{ min: 1 }}
        label={capitalize(t('boost.duration'))}
        error={Boolean(errors['duration'])}
        type="number"
        required={true}
        chosenValue={boostDuration}
        options={durationOptions}
        onChange={setBoostDuration}
        disabled={false}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
      <DialogActions>
        <Button fullWidth={true} variant="text" onClick={onClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button fullWidth={true} color="primary" variant="contained" type="submit" disabled={isLoading}>
          {t('save')}
        </Button>
      </DialogActions>
    </Stack>
  );
};

export default GlobalBoostCreateFormFields;
