import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Checkbox } from '@greenisland/components';
import {
  AddMatchReviewsMutationBody,
  EMatchStatusSchema,
  getGetMatchesQueryKey,
  MatchReviewSchema,
  useAddMatchReviews,
} from '@greenisland/stores';
import { LoadingButton } from '@mui/lab';
import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

interface AddMatchReviewDialogProps {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  userId: number;
  qrCode: string;
  matchVersion: number;
}

interface AddMatchReviewForm {
  block: boolean;
  reason: string;
}

const AddMatchReviewDialog = memo(
  ({ openDialog, setOpenDialog, userId, qrCode, matchVersion }: AddMatchReviewDialogProps) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const qc = useQueryClient();
    const { handleSubmit, register, errors } = useForm<AddMatchReviewForm>();

    const { mutate: addMatchReviews, isLoading: addMatchReviewsIsLoading } = useAddMatchReviews({
      mutation: {
        onSuccess: () => {
          enqueueSnackbar(t('success'), { variant: 'success' });
          qc.invalidateQueries(getGetMatchesQueryKey(userId));
        },
        onError: () => {
          enqueueSnackbar(t('error'), { variant: 'error' });
        },
      },
    });

    const onSubmit = (data: AddMatchReviewForm) => {
      const singleSchema: MatchReviewSchema = {
        qrCode: qrCode,
        clientComment: data.reason,
        clientRiskRating: data.block
          ? t('kyc.monitoring.matchReview.suspension')
          : t('kyc.monitoring.matchReview.noSuspension'),
        status: data.block ? EMatchStatusSchema.TruePositive : EMatchStatusSchema.FalsePositive,
        matchVersion: matchVersion,
      };
      const batchedSchema: AddMatchReviewsMutationBody = [singleSchema];
      addMatchReviews({ data: batchedSchema, userId });
    };

    return (
      <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{capitalize(t('kyc.headers.addMatchReview'))}</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Checkbox label={t('kyc.truePositive')} color="primary" ref={register()} name="block" />
            <TextField
              fullWidth
              name="reason"
              label={t('reason')}
              inputRef={register({ required: t('reason') })}
              required
              error={!!errors.reason}
              helperText={errors.reason?.message}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
              {t('cancel')}
            </Button>
            <LoadingButton variant="contained" color="error" type="submit" disabled={addMatchReviewsIsLoading}>
              {t('proceed')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);

export default AddMatchReviewDialog;
