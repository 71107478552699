import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  BoosterPackCardPoolEntrySchema,
  BoosterPackUpdateSchema,
  EMediaGalleryEntryTypeSchema,
  useAddBoosterPack,
  useUpdateBoosterPack,
} from '@greenisland/stores';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';

import BoosterCardPackPoolSettings from './BoosterCardPackPoolSettings';
import {
  BoosterFormType,
  BoosterPackDialogSchema,
  Ordinal,
  useBoosterPackFormHandlers,
  useGetBoosterPackDefaultValues,
} from './helpers';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedCard?: BoosterPackDialogSchema;
}

const BoosterPackDialog = ({ open, onClose, selectedCard }: Props) => {
  const { t } = useTranslation();

  const methods = useForm<BoosterPackDialogSchema>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: useGetBoosterPackDefaultValues(selectedCard),
  });

  const { handleSubmit, reset, control, setError, errors } = methods;

  const { onSuccessHandler, onErrorHandler } = useBoosterPackFormHandlers(
    BoosterFormType.BOOSTER_PACKS,
    onClose,
    reset
  );

  const { mutate: addBoosterPack, isLoading: isAddBoosterPackLoading } = useAddBoosterPack({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: error => onErrorHandler(error),
    },
  });
  const { mutate: updateBoosterPack, isLoading: isUpdateBoosterPackLoading } = useUpdateBoosterPack({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: error => onErrorHandler(error),
    },
  });

  const transformToCardPoolData = (data: Ordinal[]): BoosterPackCardPoolEntrySchema[] => {
    return data
      ?.flatMap((item, index) =>
        item?.cards?.map(card => ({
          ordinal: index + 1,
          cardId: card?.card,
          weight: card?.weight,
        }))
      )
      .filter(entry => entry !== undefined);
  };

  const onSubmit = (data: BoosterPackDialogSchema) => {
    if (!data?.ordinals || !data?.ordinals?.length) {
      return setError('ordinals', { type: 'custom', message: 'custom message' });
    }

    const payload = {
      ...data,
      imageIds: data?.boosterPackImages?.map(image => image?.id),
      cardPool: transformToCardPoolData(data.ordinals) || [],
    } as BoosterPackUpdateSchema;

    if (selectedCard) {
      updateBoosterPack({ boosterPackId: selectedCard.id, data: payload });
    } else {
      addBoosterPack({ data: payload });
    }
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        {t(
          selectedCard
            ? 'marketing.boosterPacks.titles.updateBoosterPack'
            : 'marketing.boosterPacks.titles.addBoosterPack'
        )}
      </DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Typography fontWeight={500} variant="body1" fontSize="large" sx={{ pt: 1, mb: 1 }}>
                {capitalize(t('common.generalSettings'))}
              </Typography>
              <Box display="flex" flexDirection="column">
                <Input
                  size="small"
                  label={capitalize(t('name'))}
                  control={control}
                  type="text"
                  name="name"
                  defaultValue={selectedCard?.name}
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  sx={{ mb: 2 }}
                />
                <Input
                  multiline
                  size="small"
                  label={capitalize(t('description'))}
                  control={control}
                  type="text"
                  name="description"
                  defaultValue={selectedCard?.description}
                  sx={{ mb: 2 }}
                  rules={{
                    maxLength: {
                      value: 255,
                      message: t('common.descriptionMaxLengthError'),
                    },
                  }}
                  error={!!errors?.description}
                  helperText={errors?.description ? errors.description.message : ''}
                />
                <Input
                  multiline
                  size="small"
                  label={capitalize(t('skinType'))}
                  control={control}
                  type="text"
                  name="skinType"
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  defaultValue={selectedCard?.skinType}
                  sx={{ mb: 2 }}
                />
                <Input
                  size="small"
                  label={capitalize(t('redirectUrl'))}
                  control={control}
                  type="url"
                  name="redirectUrl"
                  defaultValue={selectedCard?.redirectUrl}
                  sx={{ mb: 2 }}
                />
                <Input
                  size="small"
                  label={capitalize(t('expirationDuration'))}
                  control={control}
                  type="number"
                  name="expirationDuration"
                  defaultValue={selectedCard?.expirationDuration}
                  sx={{ mb: 2 }}
                  InputProps={{
                    endAdornment: <InputAdornment position={'end'}>{t('days')}</InputAdornment>,
                    inputProps: { min: 1, step: 'any' },
                  }}
                  rules={{
                    valueAsNumber: true,
                    min: 1,
                    required: t('fieldIsRequired'),
                  }}
                  required={true}
                />
              </Box>
              <Typography fontWeight={500} variant="body1" fontSize="large">
                {capitalize(t('imageUrl'))}
              </Typography>
              <MediaFieldsWithPreview
                control={control}
                size="small"
                key="boosterPackImages"
                name="boosterPackImages"
                error={Boolean(errors['boosterPackImages'])}
                type="text"
                required={false}
                label={capitalize(t('addImages'))}
                mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.Template}
                isMultiSelect={true}
              />
              <BoosterCardPackPoolSettings selectedCard={selectedCard} />
            </Stack>
            <DialogActions>
              <Button variant="text" onClick={onClose} color="secondary">
                {t('cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                loading={isAddBoosterPackLoading || isUpdateBoosterPackLoading}
              >
                {t('save')}
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default BoosterPackDialog;
