import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { GameProvidersReportSchemaItem, useGetGameProvidersReport } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, capitalize, Card, CardContent, Stack, Typography } from '@mui/material';
import { DataGridProProps, GridColumns } from '@mui/x-data-grid-pro';
import { endOfMonth, getUnixTime, startOfMonth } from 'date-fns';

import { DataGridContainer, PermissionWrapper } from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import GameProviderDateFilters, { GameProviderSearchFilters } from './DetailsComponents/GameProviderDateFilters';
import GameProviderDetailsTableData from './DetailsComponents/GameProviderDetailsTableData';
import { formatCurrency } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const PAGE_SIZE = 25;

type Row = GameProvidersReportSchemaItem & { id: number };

const GameProviders = () => {
  const { t } = useTranslation();
  const canReadGameProviders = usePermission(OnlineCasinoPermissions.getGameProvidersReport);
  const canReadGameProviderDetails = usePermission(OnlineCasinoPermissions.getGameProviderGames);

  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get(GameProviderSearchFilters.START_DATE);
  const endDate = searchParams.get(GameProviderSearchFilters.END_DATE);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const {
    data: gameProviders,
    isLoading,
    isError,
  } = useGetGameProvidersReport(
    {
      startdate: Number(startDate),
      enddate: Number(endDate),
    },
    {
      query: {
        enabled: canReadGameProviders && !!startDate && !!endDate,
      },
    }
  );

  const updateQueryParamsHandler = useCallback(
    (key: string, value: string) => {
      searchParams.set(key, value);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (!startDate && !endDate) {
      updateQueryParamsHandler(GameProviderSearchFilters.START_DATE, String(getUnixTime(startOfMonth(new Date()))));
      updateQueryParamsHandler(GameProviderSearchFilters.END_DATE, String(getUnixTime(endOfMonth(new Date()))));
    }
  }, [endDate, startDate]);

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'provider',
        headerName: capitalize(t('provider')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{row?.provider}</Typography>;
        },
      },
      {
        field: 'tax',
        headerName: capitalize(t('tax')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{formatCurrency(row?.tax)}</Typography>;
        },
      },
      {
        field: 'vat',
        headerName: capitalize(t('vat')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{formatCurrency(row?.vat)}</Typography>;
        },
      },
      {
        field: 'commission',
        headerName: capitalize(t('commission')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{formatCurrency(row?.commission)}</Typography>;
        },
      },
      {
        field: 'revenue',
        headerName: capitalize(t('revenue')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{formatCurrency(row?.revenue)}</Typography>;
        },
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      gameProviders?.map<GameProvidersReportSchemaItem>((gameProvider, index) => ({
        id: index,
        ...gameProvider,
      })) ?? [],
    [gameProviders]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: Row }) => {
      if (canReadGameProviderDetails && (row?.details?.platform || row?.details?.provider)) {
        return (
          <Box sx={{ m: 2 }}>
            <Card elevation={1} sx={{ px: 2, mb: 4 }}>
              <CardContent>
                {row?.details?.platform && (
                  <GameProviderDetailsTableData
                    title={t('reporting.financial.gameProviders.titles.platformDetails')}
                    provider={row?.provider}
                    details={row.details.platform}
                  />
                )}
                {row?.details?.provider && (
                  <GameProviderDetailsTableData
                    title={t('reporting.financial.gameProviders.titles.providerDetails')}
                    details={row.details.provider}
                  />
                )}
              </CardContent>
            </Card>
          </Box>
        );
      }
    },
    [canReadGameProviderDetails, t]
  );

  return (
    <Box mt={4}>
      <GameProviderDateFilters />
      <Stack spacing={2} mt={2}>
        <PermissionWrapper
          errorMessage={t('reporting.financial.gameProviders.permissions.fetchError')}
          isError={isError}
          isLoading={false}
          permission={OnlineCasinoPermissions.getGameProvidersReport}
        >
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              columns={columns}
              rows={rows}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              components={{
                Footer: () => (
                  <DataGridCustomPagination
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100, 200]}
                    count={gameProviders?.length ? Math.ceil(gameProviders.length / pageSize) : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    paginationMode="client"
                  />
                ),
              }}
            />
          </DataGridContainer>
        </PermissionWrapper>
      </Stack>
    </Box>
  );
};

export default GameProviders;
