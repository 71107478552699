import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionV2Schema, useGetTransactionsGeneral } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { GridColumns, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPagination, StyledDataGrid } from '@greenisland-common/components/atoms';

import { formatCurrency, formatDateTime } from '@greenisland-common/helpers/formatters';

import { numberOfRowsPerPage } from '../../../../../app/constants/defaultvalues';
import { usePermission } from '../../../../../app/hooks';

const LookupTxs = () => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);

  const canReadTransactions = usePermission(OnlineCasinoPermissions.getTransactionsGeneral);

  const { data: txsData, isLoading } = useGetTransactionsGeneral(
    {
      numberOfItems: numberOfRowsPerPage,
      pageNumber: page,
    },
    {
      query: {
        enabled: canReadTransactions,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
      },
    }
  );

  const columns = useMemo<GridColumns<TransactionV2Schema>>(() => {
    return txsData?.items?.at(0)
      ? Object.keys(txsData?.items![0]).map(key => {
          switch (key) {
            case 'timestamp':
              return {
                headerName: t('timestamp'),
                field: 'timestamp',
                type: 'date',
                valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
                valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
                flex: 2,
                minWidth: 150,
                description: t('theRequestTime'),
                headerAlign: 'center',
                align: 'center',
              };
            case 'promoAmount':
            case 'promoBalance':
            case 'amount':
            case 'balance':
              return {
                headerName: t(key),
                field: key,
                type: 'number',
                valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
                flex: 1,
                minWidth: 150,
                headerAlign: 'center',
                align: 'center',
              };
            case 'description':
              return {
                headerName: t(key),
                field: key,
                minWidth: 250,
                flex: 250,
                headerAlign: 'center',
                align: 'center',
              };
            default:
              return {
                headerName: t(key),
                field: key,
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
              };
          }
        })
      : [];
  }, [txsData?.items, t]);

  return (
    <>
      <DataGridContainer>
        <StyledDataGrid
          density="compact"
          loading={isLoading}
          rows={txsData?.items ?? []}
          columns={columns}
          pagination
          page={page}
          pageSize={numberOfRowsPerPage}
          paginationMode="server"
          onPageChange={p => {
            setPage(p);
          }}
          showColumnRightBorder={false}
          disableDensitySelector
          disableSelectionOnClick
          rowCount={txsData?.pagingDetails?.totalItems ?? 0}
          components={{
            Pagination: DataGridPagination,
          }}
        />
      </DataGridContainer>
    </>
  );
};

export default memo(LookupTxs);
