import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@greenisland/components';
import { DepositSchema, useAdvancedUserSearchV2Hook, useGetDepositsGeneral } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { getUnixTime, startOfDay, subHours } from 'date-fns';
import { numberOfRowsPerPage } from 'src/app/constants/defaultvalues';

import {
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { formatCurrency, formatDateTime } from '@greenisland-common/helpers';

import { StatusSpan } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const DepositsOverview = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const canLookupUser = usePermission(OnlineCasinoPermissions.advancedUserSearchV2);
  const canReadDepositDetails = usePermission(OnlineCasinoPermissions.getDepositDetails);
  const canReadDeposits = usePermission(OnlineCasinoPermissions.getDepositsGeneral);

  const [page, setPage] = useState(0);
  const [startDate] = useState(subHours(startOfDay(new Date()), 24));
  const [endDate] = useState(new Date());
  const advancedUserSearchV2 = useAdvancedUserSearchV2Hook();

  const {
    data: depositsData,
    isLoading,
    isError,
  } = useGetDepositsGeneral(
    {
      numberOfItems: numberOfRowsPerPage,
      pageNumber: page,
      start: getUnixTime(startDate),
      end: getUnixTime(endDate),
    },
    {
      query: {
        enabled: canReadDeposits,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
      },
    }
  );

  const rows = useMemo(() => {
    return depositsData?.items?.map(item => ({ ...item, id: item.pkeyInt })) ?? [];
  }, [depositsData?.items]);

  const columns = useMemo<GridColumns<DepositSchema>>(() => {
    const handleAccountIdClick = (accountId: number) => {
      advancedUserSearchV2({ accountId: accountId }).then(res =>
        navigate(`../../users/${res.results?.at(0)?.userId}/details`)
      );
    };

    const handleDepositDetailsClick = (accountId: number, depositGuid: string) => {
      advancedUserSearchV2({ accountId: accountId }).then(res =>
        navigate(`../../../users/${res.results?.at(0)?.userId}/deposits/${depositGuid}`)
      );
    };
    const headers: GridColumns<DepositSchema> = [
      {
        headerName: t('id'),
        field: 'pkeyInt',
        type: 'string',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('timestamp'),
        field: 'timestamp',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        flex: 2,
        minWidth: 100,
        description: 'The request time',
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('amount'),
        field: 'amount',
        type: 'number',
        valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('accountId'),
        field: 'accountId',
        type: 'string',
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<string, DepositSchema>) => {
          return canLookupUser ? (
            <span
              onClick={() => handleAccountIdClick(parseInt(params.row.accountId ?? ''))}
              style={{ cursor: 'pointer' }}
            >
              {params.row.accountId}
            </span>
          ) : (
            <span>{params.row.accountId}</span>
          );
        },
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('provider'),
        field: 'provider',
        type: 'string',
        flex: 2,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('method'),
        field: 'paymentMethod',
        type: 'string',
        flex: 2,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('status'),
        field: 'status',
        type: 'string',
        flex: 2,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<string, DepositSchema>) => (
          <StatusSpan value={params.row.status?.toString() ?? ''} />
        ),
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('reference'),
        field: 'depositId',
        type: 'string',
        flex: 4,
        minWidth: 250,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'actions',
        headerName: t('details'),
        flex: 1,
        minWidth: 70,
        disableColumnMenu: true,
        disableExport: true,
        disableReorder: true,
        filterable: false,
        hideable: false,
        sortable: false,
        resizable: false,
        renderCell: (params: GridRenderCellParams<string, DepositSchema>) => {
          return (
            <Button
              variant="transparent"
              color="secondary"
              onClick={() =>
                handleDepositDetailsClick(parseInt(params.row.accountId ?? ''), params.row.depositId ?? '')
              }
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          );
        },
        headerAlign: 'center',
        align: 'center',
      },
    ];

    return headers;
  }, [t, canLookupUser, i18n.language, advancedUserSearchV2, navigate]);

  return (
    <PermissionWrapper
      errorMessage={t('reporting.depositOverview.permissions.fetchError')}
      isError={isError}
      isLoading={false}
      permission={OnlineCasinoPermissions.getDepositsGeneral}
    >
      <DataGridContainer>
        <LimitedDataGrid
          initialState={{ columns: { columnVisibilityModel: { details: canReadDepositDetails } } }}
          density="compact"
          loading={isLoading}
          rows={rows}
          columns={columns}
          pagination
          page={page}
          pageSize={numberOfRowsPerPage}
          paginationMode="server"
          onPageChange={page => {
            if (!isLoading) {
              setPage(page);
            }
          }}
          showColumnRightBorder={false}
          rowCount={depositsData?.pagingDetails?.totalItems ?? 0}
          components={{
            Pagination: DataGridPagination,
          }}
        />
      </DataGridContainer>
    </PermissionWrapper>
  );
};

export default memo(DepositsOverview);
