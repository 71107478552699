import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageSchema, LoyaltyDependentDecimalSchema } from '@greenisland/stores';

import { LoyaltyDependentIconType } from '../../../../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import { BoostDurationsEnum } from '../../components/helpers/BoostFormContext';

export interface DepositBonusFormData {
  enabledDeposit: boolean;
  depositBonusName: string;
  depositBonusExpirationDuration: number;
  depositBonusExpirationDurationSelectOption: BonusDurationsEnum;
  depositBonusPlaythroughExpirationDuration: number;
  depositBonusPlaythroughExpirationDurationSelectOption: BonusDurationsEnum;
  depositBonusContentFilterId?: number | null;
  depositBonusImages: ImageSchema[] | null;
  percentageIsLoyaltyDependent: boolean;
  limitIsLoyaltyDependent: boolean;
  playthroughIsLoyaltyDependent: boolean;
  percentageLoyaltyDependentValue: number | null;
  limitLoyaltyDependentValue: number | null;
  playthroughLoyaltyDependentValue: number | null;
  loyaltyDependentPercentageField: LoyaltyDependentDecimalSchema;
  loyaltyDependentLimitField: LoyaltyDependentDecimalSchema;
  loyaltyDependentPlaythroughField: LoyaltyDependentDecimalSchema;
  depositBonusAbsoluteExpiryDate: Date | null;
}

export enum BonusDurationsEnum {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export enum BonusLoyaltyDependentTypeEnum {
  PERCENTAGE = 'percentage',
  LIMIT = 'limit',
  PLAYTHROUGH = 'playthrough',
}

export function useDepositBonusDurationValues() {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { value: BonusDurationsEnum.SECONDS, label: t('seconds') },
      { value: BonusDurationsEnum.MINUTES, label: t('minutes') },
      { value: BonusDurationsEnum.HOURS, label: t('hours') },
      { value: BonusDurationsEnum.DAYS, label: t('days') },
    ],
    [t]
  );
}

export function useBonusDepositLoyaltyDependentOptions(type: BonusLoyaltyDependentTypeEnum, name: string) {
  const { t } = useTranslation();
  return useMemo(() => {
    const percentageTypeOptions = [
      {
        label: t('marketing.loyaltyDependentMaxPayout.standard'),
        name: `${name}.standardValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.bronze'),
        name: `${name}.bronzeValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.silver'),
        name: `${name}.silverValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.gold'),
        name: `${name}.goldValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.platinum'),
        name: `${name}.platinumValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.diamond'),
        name: `${name}.diamondValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.vip'),
        name: `${name}.vipValue`,
      },
    ];
    const limitTypeOptions = [
      {
        label: t('marketing.loyaltyDependentMaxPayout.standard'),
        name: `${name}.standardValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.bronze'),
        name: `${name}.bronzeValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.silver'),
        name: `${name}.silverValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.gold'),
        name: `${name}.goldValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.platinum'),
        name: `${name}.platinumValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.diamond'),
        name: `${name}.diamondValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.vip'),
        name: `${name}.vipValue`,
      },
    ];
    const playthroughTypeOptions = [
      {
        label: t('marketing.loyaltyDependentMaxPayout.standard'),
        name: `${name}.standardValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.bronze'),
        name: `${name}.bronzeValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.silver'),
        name: `${name}.silverValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.gold'),
        name: `${name}.goldValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.platinum'),
        name: `${name}.platinumValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.diamond'),
        name: `${name}.diamondValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.vip'),
        name: `${name}.vipValue`,
      },
    ];

    if (type === BonusLoyaltyDependentTypeEnum.PERCENTAGE) return percentageTypeOptions;
    else if (type === BonusLoyaltyDependentTypeEnum.LIMIT) return limitTypeOptions;
    else if (type === BonusLoyaltyDependentTypeEnum.PLAYTHROUGH) return playthroughTypeOptions;
    else return [];
  }, [name, t, type]);
}

export function calculatePromotionDuration(
  expirationDurationValue: number,
  expirationDurationSelectOption: BonusDurationsEnum | BoostDurationsEnum
) {
  switch (expirationDurationSelectOption) {
    case BonusDurationsEnum.SECONDS:
      return expirationDurationValue;
    case BonusDurationsEnum.MINUTES:
      return expirationDurationValue * 60;
    case BonusDurationsEnum.HOURS:
      return expirationDurationValue * 3600;
    case BonusDurationsEnum.DAYS:
      return expirationDurationValue * 86400;
    default:
      return 0;
  }
}

export const getBonusLoyaltyDependentValues = (
  isLoyaltyDependent: boolean,
  value: number | null,
  data: LoyaltyDependentDecimalSchema
): LoyaltyDependentDecimalSchema => {
  const result: LoyaltyDependentDecimalSchema = {};
  const defaultValues: Partial<LoyaltyDependentDecimalSchema> = {
    standardValue: 0,
    bronzeValue: 0,
    silverValue: 0,
    goldValue: 0,
    platinumValue: 0,
    diamondValue: 0,
    vipValue: 0,
  };

  if (!isLoyaltyDependent) {
    if (value) {
      Object.keys(defaultValues).forEach(property => {
        result[property as keyof LoyaltyDependentDecimalSchema] = value;
      });
    } else {
      Object.keys(defaultValues).forEach(property => {
        result[property as keyof LoyaltyDependentDecimalSchema] = 0;
      });
    }
  } else {
    Object.keys(data).forEach(property => {
      const value = data[property as keyof LoyaltyDependentDecimalSchema];
      if (value) {
        result[property as keyof LoyaltyDependentDecimalSchema] = parseFloat(value.toString());
      }
    });
  }

  return result;
};

export function useGetDepositBonusLoyaltyDependentIcon(iconType?: LoyaltyDependentIconType) {
  return useMemo(() => {
    if (iconType === LoyaltyDependentIconType.EURO) return '€';
    else if (iconType === LoyaltyDependentIconType.PERCENTAGE) return '%';
    else if (iconType === LoyaltyDependentIconType.BONUS) return 'x';
    else return '';
  }, [iconType]);
}
