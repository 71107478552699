import { useTranslation } from 'react-i18next';
import { EPromotionNameConfigurationTypeSchema, useGetPromotionNameConfigurations } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress } from '@mui/material';

import { NoData, PermissionWrapper } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../app/hooks';
import {
  groupPromotionNameConfigurations,
  PromotionNameConfigurationsByType,
} from '../Components/Forms/helpers/promotionalNameConfigurationHelpers';
import PromotionNameConfigurationsTypeSection from './PromotionNameConfigurationsTypeSection';

const PromotionNameConfigurations = () => {
  const { t } = useTranslation();

  const canReadPromotionNameConfigurations = usePermission(OnlineCasinoPermissions.getPromotionNameConfigurations);

  const {
    data: promotionNameConfigurations,
    isLoading,
    isError,
    isFetching,
  } = useGetPromotionNameConfigurations({
    query: {
      enabled: canReadPromotionNameConfigurations,
      select: (data): PromotionNameConfigurationsByType => groupPromotionNameConfigurations(data),
    },
  });

  if (isLoading || isFetching) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PermissionWrapper
      errorMessage={t('settings.configurations.promotionalNameConfigurations.permissions.fetchError')}
      isError={isError}
      isLoading={false}
      permission={OnlineCasinoPermissions.getPromotionNameConfigurations}
    >
      <Box mt={6}>
        {!promotionNameConfigurations ? (
          <NoData />
        ) : (
          (Object.keys(promotionNameConfigurations) as EPromotionNameConfigurationTypeSchema[]).map(key => (
            <PromotionNameConfigurationsTypeSection
              key={key}
              type={key}
              configurations={promotionNameConfigurations[key] || []}
              isLoading={isLoading || isFetching}
            />
          ))
        )}
      </Box>
    </PermissionWrapper>
  );
};

export default PromotionNameConfigurations;
