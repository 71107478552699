import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd, faPencil } from '@fortawesome/free-solid-svg-icons';
import {
  ELanguagesSchema,
  EPromotionNameConfigurationTypeSchema,
  PromotionNameConfigurationSchema,
  TranslationsSchema,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Stack, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';

import { DataGridContainer, FontAwesomeIcon } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { useGetPromotionNameConfigurationMissingTypes } from './helpers/promotionNameConfigurationHooks';
import {
  PROMOTION_NAME_CONFIGURATION_COLUMN_FIELDS,
  PROMOTION_NAME_CONFIGURATION_TITLES,
  PromotionNameConfigurationActionMode,
} from './helpers/promotionNameConfigurationInterfaces';
import { usePermission } from '../../../../../app/hooks';
import PromotionNameConfigurationsDialog from './PromotionNameConfigurationsDialog';

interface Props {
  type: EPromotionNameConfigurationTypeSchema;
  configurations: PromotionNameConfigurationSchema[];
  isLoading: boolean;
}

const PromotionNameConfigurationsTypeSection = ({ type, configurations, isLoading }: Props) => {
  const { t } = useTranslation();
  const canUpdatePromotionNameConfigurations = usePermission(OnlineCasinoPermissions.updatePromotionNameConfigurations);
  const promotionalNameConfigurationMissingTypes = useGetPromotionNameConfigurationMissingTypes(configurations, type);

  const [mode, setMode] = useState<PromotionNameConfigurationActionMode | null>(null);
  const [selectedConfiguration, setSelectedConfiguration] = useState<PromotionNameConfigurationSchema | undefined>(
    undefined
  );

  const getLanguageTranslationContentHandler = useCallback(
    (translations: TranslationsSchema, language: ELanguagesSchema) => {
      const content = translations?.find(translation => translation?.language === language)?.content;

      return <Typography variant="body2">{content || '-'}</Typography>;
    },
    []
  );

  const columns = useMemo<GridColumns<PromotionNameConfigurationSchema>>(
    () => [
      {
        field: PROMOTION_NAME_CONFIGURATION_COLUMN_FIELDS[type],
        headerName: capitalize(t('key')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{capitalize(value)}</Typography>;
        },
      },
      {
        field: ELanguagesSchema.EN,
        headerName: capitalize(ELanguagesSchema.EN),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ row }) => getLanguageTranslationContentHandler(row.translations, ELanguagesSchema.EN),
      },
      {
        field: ELanguagesSchema.NL,
        headerName: capitalize(ELanguagesSchema.NL),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ row }) => getLanguageTranslationContentHandler(row.translations, ELanguagesSchema.NL),
      },
      {
        field: ELanguagesSchema.FR,
        headerName: capitalize(ELanguagesSchema.FR),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ row }) => getLanguageTranslationContentHandler(row.translations, ELanguagesSchema.FR),
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 150,
        flex: 0.1,
        headerName: '',
        getActions: (params: GridRowParams<PromotionNameConfigurationSchema>) => [
          <>
            <Tooltip title={t('edit')} key="details">
              <>
                <GridActionsCellItem
                  title={t('edit')}
                  label={t('edit')}
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  key="edit"
                  onClick={() => {
                    setMode(PromotionNameConfigurationActionMode.EDIT);
                    setSelectedConfiguration(params.row);
                  }}
                />
              </>
            </Tooltip>
          </>,
        ],
      },
    ],
    [getLanguageTranslationContentHandler, t, type]
  );

  const rows = useMemo(
    () =>
      configurations?.map<PromotionNameConfigurationSchema>(configuration => ({
        ...configuration,
      })) ?? [],
    [configurations]
  );

  const isMissingTypeAvailable = promotionalNameConfigurationMissingTypes?.length;

  return (
    <>
      <Stack spacing={2} mb={10}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" fontSize="x-large">
            {capitalize(t(PROMOTION_NAME_CONFIGURATION_TITLES[type]))}
          </Typography>
          <Button
            variant="contained"
            size="medium"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            disabled={!isMissingTypeAvailable}
            onClick={() => setMode(PromotionNameConfigurationActionMode.CREATE)}
          >
            {`${t('add')} ${isMissingTypeAvailable ? `(${isMissingTypeAvailable})` : ''}`}
          </Button>
        </Box>
        <Box>
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              columns={columns}
              rows={rows}
              hideFooter={true}
            />
          </DataGridContainer>
        </Box>
      </Stack>
      {mode && canUpdatePromotionNameConfigurations ? (
        <PromotionNameConfigurationsDialog
          selectedConfiguration={selectedConfiguration}
          open={true}
          onClose={() => {
            setMode(null);
            setSelectedConfiguration(undefined);
          }}
          type={type}
          configurations={configurations}
        />
      ) : null}
    </>
  );
};

export default PromotionNameConfigurationsTypeSection;
