import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Checkbox } from '@greenisland/components';
import { EInterventionStatusSchema, ESourceTypeSchema, GetInterventionsParams } from '@greenisland/stores';
import { useDebouncedValue } from '@lilib/hooks';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Chip, Divider, FormControlLabel, InputBase, Paper, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';

import FilterMenu, { FilterMenuOption } from '@greenisland-common/components/molecules/FilterMenu/FilterMenu';

interface ChipData {
  id: string;
  label: string;
  type: string;
}

enum InterventionFilterType {
  STATUS = 'status',
  SOURCE_TYPE = 'sourceType',
}

export type Filters = Pick<GetInterventionsParams, 'userId' | 'sourceValue' | 'status' | 'sourceType'>;

interface Props {
  onChange: (filters: Filters) => void;
}

const InterventionsFilter = ({ onChange }: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const user = searchParams.get('user');
  const [manualActionRequired, setManualActionRequired] = useState(true);
  const [createdAfter, setCreatedAfter] = useState<Date | null>(null);
  const [search, setSearch] = useState(user ? `user:${user}` : '');
  const [chips, setChips] = useState<ChipData[]>([]);

  const [debouncedChips] = useDebouncedValue(chips, { wait: 500 });
  const [debouncedSearch] = useDebouncedValue(search, { wait: 500 });

  const params = useMemo(() => {
    const status = debouncedChips.find(chip => chip.type === InterventionFilterType.STATUS);
    const sourceType = debouncedChips.find(chip => chip.type === InterventionFilterType.SOURCE_TYPE);
    let search = {};
    if (debouncedSearch.length > 0) {
      const userIdMatch = debouncedSearch.match(/user:(\d+)/);
      if (userIdMatch) {
        search = { userId: parseInt(userIdMatch[1], 10) };
      } else {
        search = { sourceValue: debouncedSearch };
      }
    }
    return {
      ...search,
      ...(manualActionRequired ? { manualActionRequired } : {}),
      ...(createdAfter ? { createdAfter: createdAfter.getTime() / 1000 } : {}),
      ...(status ? { status: status.id as EInterventionStatusSchema } : {}),
      ...(sourceType ? { sourceType: sourceType.id as ESourceTypeSchema } : {}),
    };
  }, [manualActionRequired, debouncedChips, debouncedSearch, createdAfter]);

  useEffect(() => {
    onChange(params);
  }, [onChange, params]);

  const handleDelete = (chipId: string) => () => {
    setChips(chips => chips.filter(chip => chip.id !== chipId));
  };

  const handleSelect = (type: InterventionFilterType, selection: FilterMenuOption & { selected?: boolean }) => {
    const filteredChips = chips.filter(chip => chip.type !== type);
    if (selection.selected) setChips([...filteredChips, { type, ...selection }]);
    else setChips(filteredChips);
  };

  const selectedType = useMemo(
    () => chips.filter(chip => chip.type === InterventionFilterType.SOURCE_TYPE).map(c => c.id),
    [chips]
  );
  const selectedStatus = useMemo(
    () => chips.filter(chip => chip.type === InterventionFilterType.STATUS).map(c => c.id),
    [chips]
  );

  return (
    <Paper sx={{ backgroundColor: 'white' }} variant="outlined" elevation={0}>
      <Box display="flex" alignItems="center" flexWrap="wrap" paddingX={2} paddingY={1}>
        <SearchIcon />
        <Box ml={3} flexGrow={1}>
          <InputBase
            value={search}
            placeholder={t('tasks.interventions.searchPlaceholder')}
            fullWidth
            onChange={e => setSearch(e.target.value.trim())}
          />
        </Box>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" flexWrap="wrap" paddingX={2} paddingY={1}>
        {chips.length === 0 && !createdAfter ? (
          <Typography variant="subtitle2" textTransform="initial">
            {t('tasks.interventions.noFilter')}
          </Typography>
        ) : (
          <Stack component="div" direction="row" flexWrap="wrap" gap={1}>
            {chips.map(({ id, type, label }) => (
              <Chip
                key={id}
                label={
                  <span>
                    <strong>{t(`tasks.interventions.${type}`)}:</strong> {label}
                  </span>
                }
                variant="outlined"
                onDelete={handleDelete(id)}
              />
            ))}
            {createdAfter ? (
              <Chip
                label={
                  <span>
                    <strong>{t(`tasks.interventions.createdAfter`)}:</strong> {format(createdAfter, 'dd-MM-yyyy')}
                  </span>
                }
                variant="outlined"
                onDelete={() => setCreatedAfter(null)}
              />
            ) : null}
          </Stack>
        )}
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" flexWrap="wrap" padding={1} gap={1}>
        <FormControlLabel
          control={
            <Checkbox checked={manualActionRequired} onChange={e => setManualActionRequired(e.target.checked)} />
          }
          label={t('tasks.interventions.manualActionRequired')}
          sx={{ ml: 0.5 }}
        />
        <FilterMenu
          label={t('tasks.interventions.status')}
          options={
            Object.values(EInterventionStatusSchema)
              .map(type => ({ id: type, label: type }))
              .sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })) ?? []
          }
          selected={selectedStatus}
          onClick={selected => handleSelect(InterventionFilterType.STATUS, selected)}
        />
        <FilterMenu
          label={t('tasks.interventions.sourceType')}
          options={
            Object.values(ESourceTypeSchema)
              .map(type => ({ id: type, label: type }))
              .sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })) ?? []
          }
          selected={selectedType}
          onClick={selected => handleSelect(InterventionFilterType.SOURCE_TYPE, selected)}
        />
        <DatePicker
          disableFuture
          label={t('tasks.interventions.createdAfter')}
          onChange={date => {
            setCreatedAfter(date ?? null);
          }}
          value={createdAfter}
          renderInput={params => <TextField size="small" {...params} />}
          inputFormat="dd/MM/yyyy"
          mask="__/__/____"
        />
      </Box>
    </Paper>
  );
};

export default InterventionsFilter;
