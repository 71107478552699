import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetActiveBalanceLimitForUser } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { formatCurrency } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import { DataGridContainer, DateTime, PermissionWrapper } from '@greenisland-common/components/atoms';
import StyledDataGrid from '@greenisland-common/components/atoms/StyledDataGrid';

type DepositLimitsProps = {
  userId: string;
};

const ActiveLimitsTable = ({ userId }: DepositLimitsProps) => {
  const { t } = useTranslation();
  const canReadActiveLimit = usePermission(OnlineCasinoPermissions.getActiveBalanceLimitForUser);

  const {
    data: activeBalanceLimit,
    isLoading,
    isError,
    error,
  } = useGetActiveBalanceLimitForUser(parseInt(userId, 10), { query: { enabled: canReadActiveLimit } });

  const headers: GridColumns = [
    {
      headerName: t('startDate'),
      field: 'startDate',
      renderCell: ({ row }) => <DateTime dateTime={row.startDate ?? 0} />,
      flex: 1,
      minWidth: 160,
    },
    {
      headerName: t('value'),
      field: 'value',
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) =>
        row.value
          ? `${row.currentValue !== undefined ? `${formatCurrency(row.currentValue)} /` : ''} ${formatCurrency(
              row.value
            )}`
          : '',
    },
  ];

  const rows: GridRowsProp = activeBalanceLimit ? [activeBalanceLimit].map((row, i) => ({ ...row, id: i })) : [];

  return (
    <PermissionWrapper
      errorMessage={error?.message}
      isError={isError}
      isLoading={isLoading}
      permission={OnlineCasinoPermissions.getActiveBalanceLimitForUser}
    >
      <DataGridContainer>
        <StyledDataGrid columns={headers} rows={rows} hideFooter />
      </DataGridContainer>
    </PermissionWrapper>
  );
};

export default memo(ActiveLimitsTable);
