import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button, Card, CardBody, CardHeader, EIcons, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import ErrorState from '@greenisland-common/components/molecules/ErrorState';

const NotificationsTriggersOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { fetching, data } = useAppSelector(store => store.eventBasedActionTriggers);
  const getEventBasedActionTriggers = useCallback(
    () => dispatch(actionCreators.getEventBasedActionTriggers()),
    [dispatch]
  );

  useEffect(() => {
    getEventBasedActionTriggers();
  }, [getEventBasedActionTriggers]);

  return (
    <>
      <Button startIcon={EIcons.faPlus} onClick={() => navigate('./add')}>
        {t('notifications.triggers.addNew')}
      </Button>
      <Card>
        <CardHeader></CardHeader>
        <CardBody>
          {data ? (
            <ReactTableGI
              columns={[
                { Header: '#', accessor: 'phoneNumbersAmount' },
                { Header: 'name', accessor: 'name' },
                { accessor: 'actions' },
              ]}
              data={data || []}
            />
          ) : (
            <ErrorState
              loading={fetching}
              errorMessage={t('notifications.triggers.overview.loadFailed')}
              retryAction={getEventBasedActionTriggers}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default NotificationsTriggersOverview;
