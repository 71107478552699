import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReadPermission, WritePermission } from '@greenisland-core/permissions';
import { Skeleton } from '@mui/lab';
import { usePermission } from 'src/app/hooks';

import ErrorCard from '../ErrorCard';

interface Props {
  isLoading?: boolean;
  errorMessage?: string;
  isError?: boolean;
  permission: ReadPermission | WritePermission;
  children?: JSX.Element;
}

const PermissionWrapper = ({ isLoading = true, isError = true, permission, errorMessage = '', children }: Props) => {
  const { t } = useTranslation();
  const hasPermission = usePermission(permission);

  if (!hasPermission) return <ErrorCard severity="info">{t('common.noPermissionToSeeThePage', permission)}</ErrorCard>;
  if (isLoading) return <Skeleton variant="rectangular" height={300} />;
  if (isError) return <ErrorCard>{errorMessage}</ErrorCard>;

  return <>{children}</>;
};

export default PermissionWrapper;
