import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EVerificationStatusSchema } from '@greenisland/stores';
import { Box } from '@mui/material';

interface Props {
  verificationStatus: EVerificationStatusSchema | string;
}

// TODO verify if we still need these string comparaison for the verification status.
// https://greenisland.atlassian.net/browse/BACK-247
const VerificationStatus = ({ verificationStatus }: Props) => {
  const { t } = useTranslation();

  const color = useMemo(() => {
    const stateLC = verificationStatus.toLowerCase();

    if (
      stateLC === 'canceled' ||
      stateLC === 'revoked' ||
      stateLC === 'awardattempted' ||
      stateLC === 'declinerequested' ||
      stateLC === 'declined' ||
      stateLC === 'processingfailed' ||
      stateLC === 'failed' ||
      verificationStatus === EVerificationStatusSchema.NeedsVerification ||
      verificationStatus === EVerificationStatusSchema.Declined
    )
      return 'error.main';
    if (
      stateLC === 'expired' ||
      stateLC === 'pending' ||
      stateLC === 'queued' ||
      stateLC === 'new' ||
      stateLC === 'pended' ||
      stateLC === 'started' ||
      verificationStatus === EVerificationStatusSchema.Unverified
    )
      return 'warning.main';
    if (
      stateLC === 'activated' ||
      stateLC === 'accepted' ||
      stateLC === 'active' ||
      stateLC === 'approved' ||
      stateLC === 'processed' ||
      stateLC === 'completedmanually' ||
      stateLC === 'validated' ||
      stateLC === 'completed' ||
      stateLC === 'claimed' ||
      stateLC === 'finished' ||
      verificationStatus === EVerificationStatusSchema.Verified ||
      verificationStatus === EVerificationStatusSchema.VerifiedByItsMe ||
      verificationStatus === EVerificationStatusSchema.VerifiedByDeposit
    )
      return 'success.main';

    if (verificationStatus === EVerificationStatusSchema.WaitingForApproval) return 'info.main';
    if (stateLC === 'cancelrequested') return 'text.hint';
  }, [verificationStatus]);

  return (
    <Box component="span" sx={{ color }}>
      {t(verificationStatus)}
    </Box>
  );
};

export default VerificationStatus;
