import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetGameProviders } from '@greenisland/stores';
import { GameProvidersSchema } from '@greenisland/stores/dist/api/schemas/gameProvidersSchema';
import { Skeleton } from '@mui/material';

import { AutoCompleteInput } from '@greenisland-common/components/atoms';

import { capitalize } from '@greenisland-common/helpers/formatters';

import { ContentFiltersPrimaryKeysType } from '../helpers/ContentFilterFormContext';

interface Props {
  type: 'included' | 'excluded';
}

const ContentFilterGameProviders = ({ type }: Props) => {
  const { t } = useTranslation();
  const { data: gameProviders, isLoading } = useGetGameProviders();
  const gameProvidersOptions = useMemo(() => gameProviders?.map(provider => ({ ...provider })) ?? [], [gameProviders]);

  if (isLoading) return <Skeleton variant="rounded" height={56} animation="wave" />;

  return (
    <AutoCompleteInput
      size="small"
      multiple={true}
      name={`${type}Filter.${ContentFiltersPrimaryKeysType.gameProviderPKeys}`}
      label={capitalize(t('addGameProvider'))}
      options={gameProvidersOptions}
      getLabel={option => option?.providerName ?? ''}
      rules={{
        required: true,
        setValueAs: (gameProviders: GameProvidersSchema) => {
          return gameProviders?.map(game => Number(game?.providerId));
        },
      }}
    />
  );
};

export default ContentFilterGameProviders;
