import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AcurisIndividualProfileSchema } from '@greenisland/stores';
import { Card, CardHeader, Collapse, IconButton } from '@mui/material';

import KYCEvidencesTable from './Tables/KYCEvidencesTable';
import KYCPEPTable from './Tables/KYCPEPTable/KYCPEPTable';
import KYCPOITable from './Tables/KYCPOITable';
import KYCRELOrRRETable from './Tables/KYCRELOrRRETable';
import KYCSANTable from './Tables/KYCSANTable';
import KYCPrettyPrintedData from '../../KYCPrettyPrintedData';

interface Props {
  data: AcurisIndividualProfileSchema | undefined;
}

const KYCEvidenceDetailsCard = ({ data }: Props) => {
  const [evidencesExpanded, setEvidencesExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <Card style={{ padding: '20px', marginBottom: '20px', marginTop: '20px' }}>
      <CardHeader
        title="Evidence Entries"
        action={
          <IconButton aria-expanded={evidencesExpanded} onClick={() => setEvidencesExpanded(!evidencesExpanded)}>
            <FontAwesomeIcon icon={evidencesExpanded ? faCaretUp : faCaretDown} />
          </IconButton>
        }
      />
      <Collapse in={evidencesExpanded} timeout="auto" unmountOnExit>
        <KYCEvidencesTable evidences={data?.evidences}></KYCEvidencesTable>
        <KYCPEPTable pepEntries={data?.pepEntries}></KYCPEPTable>
        <KYCSANTable sanEntries={data?.sanEntries}></KYCSANTable>
        <KYCPOITable poiEntries={data?.poiEntries}></KYCPOITable>
        <KYCRELOrRRETable entries={data?.relEntries} title={t('kyc.resource.relEntries')}></KYCRELOrRRETable>
        <KYCRELOrRRETable entries={data?.rreEntries} title={t('kyc.resource.rreEntries')}></KYCRELOrRRETable>
        <KYCPrettyPrintedData title={t('kyc.resource.ddEntries')} data={data?.ddEntries}></KYCPrettyPrintedData>
        <KYCPrettyPrintedData title={t('kyc.resource.griEntries')} data={data?.griEntries}></KYCPrettyPrintedData>
        <KYCPrettyPrintedData title={t('kyc.resource.insEntries')} data={data?.insEntries}></KYCPrettyPrintedData>
      </Collapse>
    </Card>
  );
};

export default memo(KYCEvidenceDetailsCard);
