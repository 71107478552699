import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@greenisland/components';
import { CampaignAction } from '@greenisland/stores';
import { Collapse, Typography } from '@mui/material';

interface Props {
  enabledTypes: CampaignAction['enabledTypes'];
}

const EnabledTypesCollapse = ({ enabledTypes }: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Button
        variant="transparent"
        size="small"
        endIcon={<FontAwesomeIcon icon={expanded ? faCaretUp : faCaretDown} />}
        onClick={() => setExpanded(toggle => !toggle)}
      >
        {expanded ? t('hide') : t('show')}
      </Button>
      <Collapse in={expanded} sx={{ '.MuiCollapse-wrapperInner': { mt: 2 } }}>
        {enabledTypes.map(type => (
          <div key={type.type} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography noWrap>{type.type}</Typography>
            {!type.templateFk && (
              <Tooltip content={`${t('noTemplateConfigured')}`}>
                <Button
                  justIcon={<FontAwesomeIcon icon={faQuestion} />}
                  size="small"
                  variant="transparent"
                  error
                  rounded
                />
              </Tooltip>
            )}
          </div>
        ))}
      </Collapse>
    </>
  );
};

export default EnabledTypesCollapse;
