import { useForm } from 'react-hook-form';
import { CountryList, CountryListInner, VerificationsSchemaIdentity } from '@greenisland/stores';
import { VerificationsIdentityArgumentsSchema } from '@greenisland/stores/dist/api/schemas/verificationsIdentityArgumentsSchema';

interface Props {
  countries: CountryList;
  identity: VerificationsSchemaIdentity;
}

export interface FormProps
  extends Omit<
    VerificationsIdentityArgumentsSchema,
    | 'birthdate'
    | 'identificationExpiryDate'
    | 'identificationCountryOfOrigin'
    | 'nationality'
    | 'identificationCardDateOfIssue'
  > {
  birthdate: Date;
  identificationExpiryDate: Date;
  identificationCountryOfOrigin: CountryListInner;
  nationality: CountryListInner;
  identificationCardDateOfIssue: Date;
}

const useIdentityDefaultValues = ({ countries, identity }: Props) => {
  const getCountryDefaultValue = () => {
    const country = countries?.find(
      (country: CountryListInner) => country.countryCode === identity.identificationCountryOfOrigin
    );
    return country || { countryCode: '', countryName: '' };
  };

  const getNationalityDefaultValue = () => {
    const nationality = countries?.find((country: CountryListInner) => country.countryCode === identity.nationality);
    return nationality || { countryCode: '', countryName: '' };
  };

  return useForm<FormProps>({
    defaultValues: {
      birthdate: new Date(identity.birthdate),
      birthplace: identity.birthplace,
      firstName: identity.firstName,
      identificationCountryOfOrigin: getCountryDefaultValue(),
      identificationExpiryDate: identity.identificationExpiryDate
        ? new Date(identity.identificationExpiryDate)
        : undefined,
      identificationNumber: identity.identificationNumber,
      lastName: identity.lastName,
      nationality: getNationalityDefaultValue(),
      sex: identity.sex,
      lastNamePrefix: identity.lastNamePrefix,
    },
    mode: 'onChange',
  });
};

export default useIdentityDefaultValues;
