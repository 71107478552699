import React, { memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardFooter, Inputfield, Select } from '@greenisland/components';
import {
  actionCreators,
  BackofficeApi,
  Casino,
  DepositManuallyData,
  EDepositManuallySource,
  EWithdrawProvider,
  handleFailureFromApp,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { formatCurrency } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    formContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(49%, 1fr))',
      gridGap: '15px 15px',
    },
    cardFooter: { margin: 0 },
    actions: { padding: '16px 24px' },
  })
);

const DepositManually = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const canDepositManually = usePermission(OnlineCasinoPermissions.depositManually);
  const { data: casinoList } = useAppSelector(state => state.casinos);
  const { register, handleSubmit, errors, getValues, watch, control, setValue } = useForm<DepositManuallyData>({
    defaultValues: { source: EDepositManuallySource.WireTransfer, userId: userId, amount: 0, sourceData: '' },
  });

  const [openDialog, setOpenDialog] = useState<DepositManuallyData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(actionCreators.getCasinoList());
    watch();
    setValue('source', EDepositManuallySource.WireTransfer);
  }, []);

  const values = getValues();

  const onOpenDialog = (data: DepositManuallyData) => {
    setOpenDialog(data);
  };

  return canDepositManually ? (
    <>
      <div className={classes.cardContainer}>
        <form onSubmit={handleSubmit(onOpenDialog)}>
          <Card>
            <CardBody padding>
              <div className={classes.formContainer}>
                <Inputfield
                  label={t('userId')}
                  defaultValue={userId}
                  type={'text'}
                  fullWidth
                  inputRef={register({ required: true })}
                  name="userId"
                  error={!!errors.userId}
                  helperText={errors.userId ? t('userIdRequired') : ''}
                />
                <Inputfield
                  label={t('amountOfDeposit')}
                  fullWidth
                  type={'number'}
                  inputRef={register({
                    validate: value => value > 0,
                    required: true,
                  })}
                  name="amount"
                  startIcon={<FontAwesomeIcon icon={faEuroSign} />}
                  defaultValue={0}
                  error={!!errors.amount}
                  helperText={errors.amount ? t('amountValidationMessageZero') : ''}
                />
                <Controller
                  as={
                    <Select
                      fullWidth
                      label={t('source')}
                      selectedValue={values.source}
                      menuItems={Object.values(EDepositManuallySource).map(provider => ({
                        value: provider,
                        label: t(provider),
                      }))}
                    />
                  }
                  rules={{ required: true }}
                  control={control}
                  onChange={([selected]: any) => selected}
                  defaultValue={values.source}
                  name="source"
                />
                {values.source === EWithdrawProvider.Casino ? (
                  <Controller
                    as={
                      <Select
                        fullWidth
                        label={t('casino')}
                        selectedValue={values.sourceData}
                        menuItems={
                          casinoList
                            ? casinoList.map(casino => ({ value: casino.casinoId, label: casino.name }))
                            : [{ value: 'placeholderId', label: t('placeholder') }]
                        }
                      />
                    }
                    rules={{ required: true }}
                    control={control}
                    onChange={([selected]: any) => selected}
                    defaultValue={values.sourceData}
                    name="sourceData"
                  />
                ) : (
                  <Inputfield
                    label={t('iban')}
                    fullWidth
                    type={'text'}
                    inputRef={register({ required: true })}
                    name="sourceData"
                    error={!!errors.sourceData}
                    helperText={errors.sourceData ? t('fieldIsRequired') : ''}
                  />
                )}
              </div>
            </CardBody>
            <CardFooter className={classes.cardFooter}>
              <Button
                size="large"
                color="primary"
                type="submit"
                fullWidth
                loadingState={isLoading}
                disabled={isLoading}
              >
                {t('deposit.title')}
              </Button>
            </CardFooter>
          </Card>
        </form>
      </div>
      {openDialog && (
        <ConfirmDialog
          casinoList={casinoList}
          data={openDialog}
          setOpenDialog={setOpenDialog}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  ) : null;
};

const ConfirmDialog = (props: {
  data: DepositManuallyData;
  setOpenDialog: any;
  setIsLoading: any;
  casinoList: Casino[] | undefined;
}) => {
  const { data, setOpenDialog, setIsLoading, casinoList } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    setIsLoading(true);
    if (data) {
      try {
        const result = await BackofficeApi.calls.depositManually({ ...data, amount: Number(data.amount) });
        if (result.data.success) {
          dispatch(actionCreators.setSuccessAlert(t('manualDepositCompleted')));
        } else {
          dispatch(actionCreators.setErrorAlert(result.data.errorReason || t('somethingWentWrong')));
        }
      } catch (error) {
        handleFailureFromApp(error, dispatch);
      } finally {
        setOpenDialog(false);
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog open={!!props.data}>
      <DialogTitle>{t('confirmDeposit')}</DialogTitle>
      <DialogContent>
        <div>
          <div>
            {t('userId')}: {data?.userId}
          </div>
          <div>
            {t('amountOfDeposit')}: {data && formatCurrency(Number(data.amount))}
          </div>
          <div>
            {t('source')}: {data?.source}
          </div>
          <div>
            {data?.source === EDepositManuallySource.WireTransfer ? 'IBAN' : t('casino')}:{' '}
            {casinoList?.find(casino => casino.casinoId === data.sourceData)?.name}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="transparent" onClick={() => setOpenDialog(false)} color="secondary">
          {t('cancel')}
        </Button>
        <Button variant="contained" type="submit" color="primary" onClick={() => onSubmit()}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DepositManually);
