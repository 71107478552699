import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CheckData, ReactTableGI } from '@greenisland/components';
import { actionCreators, PlayersBlocked } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import { transformBoolean, transformDecimal, transformPercentage } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const Blocked = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data, fetching } = useAppSelector(state => state.reportingPlayerBlocked);
  const canReadBlocked = usePermission(OnlineCasinoPermissions.getPlayersBlocked);

  useEffect(() => {
    if (canReadBlocked) dispatch(actionCreators.getPlayerBlocked());
  }, [dispatch, canReadBlocked]);

  const mutatedData = (playerblocked: PlayersBlocked | undefined) => {
    if (!playerblocked || playerblocked.length === 0) return undefined;
    const keys = Object.keys(playerblocked[0]);
    const headers: any = keys.map((key: string) => {
      switch (key) {
        case 'blockedSports':
        case 'blockedGames':
          return transformBoolean(key, t, true);
        case 'amountOfUsers':
          return transformDecimal(key, t);
        case 'percentage':
          return transformPercentage(key, t, { colored: false, fixedTo: 2 });
        default:
          return { Header: t(key), accessor: key };
      }
    });

    let totalAmountOfUsers = 0;
    let totalPercentage = 0;

    const rows: any = playerblocked.map(blocked => {
      totalAmountOfUsers += blocked.amountOfUsers;
      totalPercentage += blocked.percentage;
      return {
        ...blocked,
      };
    });

    rows.push({
      blockedsports: undefined,
      blockedgames: undefined,
      amountOfUsers: totalAmountOfUsers,
      percentage: totalPercentage,
    });

    return { headers, rows };
  };

  return canReadBlocked ? (
    <Card>
      <CardBody>
        <CheckData
          fallbackText={t('noData')}
          data={mutatedData(data)}
          isLoading={fetching}
          checkProperties={['headers', 'rows']}
          render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
        />
      </CardBody>
    </Card>
  ) : null;
};

export default memo(Blocked);
