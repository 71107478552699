import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CheckData, Link, ReactTablePaginated } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { getUnixTime } from 'date-fns';

import useQuery from '@greenisland-common/hooks/useQuery';

import { setInvisible, transformDefault } from '../../app/helpers/transformFunctions';

const Lookup = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const { fetching, data: playerSearch } = useAppSelector(state => state.players);
  const fetchPlayers = async (queryParameters?: { searchKey?: string; page?: string; pageSize?: string }) =>
    dispatch(actionCreators.getPlayers(queryParameters));

  useEffect(() => {
    const searchKey = query.get('searchKey');
    if (!playerSearch && searchKey) {
      fetchPlayers({ searchKey: searchKey });
    }
  }, []);

  const fetchNext = (props: any) => {
    const { pageIndex, pageSize } = props;
    if (playerSearch && pageIndex + 1 !== playerSearch?.currentPage) {
      fetchPlayers({
        searchKey: localStorage.getItem('gameServerSearchKey') || undefined,
        page: pageIndex + 1,
        pageSize: pageSize,
      });
    }
  };

  const mutatedData = () => {
    if (!playerSearch || !playerSearch.entries || playerSearch.entries.length === 0) return undefined;
    const keys = Object.keys(playerSearch.entries[0]);

    const headers: any = keys.map(key => {
      switch (key) {
        case 'id':
          return setInvisible(key);
        default:
          return transformDefault(key, t);
      }
    });

    headers.push({ id: 'gamerounds', accessor: 'gamerounds', disableFilters: true, disableSortBy: true });

    const rows: any = playerSearch.entries.map(item => ({
      ...item,
      gamerounds: (
        <Link
          to={`../players/${item.externalId}/gamerounds?startdate=${getUnixTime(
            new Date(new Date().getTime() - 2 * 60 * 60 * 1000)
          )}&enddate=${getUnixTime(new Date())}`}
        >
          {t('gamerounds')}
        </Link>
      ),
    }));

    return { headers, rows };
  };

  return (
    <Card>
      <CardBody>
        <CheckData
          data={mutatedData()}
          checkProperties={['headers', 'rows']}
          render={({ data }) => (
            <ReactTablePaginated
              columns={data.headers}
              data={data.rows}
              fetchData={(props: any) => fetchNext(props)}
              pageCount={playerSearch && playerSearch.totalNumberOfPages ? playerSearch.totalNumberOfPages : 1}
              pageNumber={playerSearch && playerSearch.currentPage ? playerSearch.currentPage - 1 : 0}
              loading={fetching}
              pageSize={10}
            />
          )}
        />
      </CardBody>
    </Card>
  );
};

export default Lookup;
