import React, { useMemo } from 'react';
import { EInterventionStatusSchema } from '@greenisland/stores';
import { Box, useTheme } from '@mui/material';

const { New, InProgress, Closed, Completed, Unknown } = EInterventionStatusSchema;

interface Props {
  value: EInterventionStatusSchema;
}

const InterventionStatusField = ({ value }: Props) => {
  const { palette } = useTheme();

  const color = useMemo(() => {
    switch (value) {
      case New:
        return palette.text.primary;
      case InProgress:
        return palette.info.dark;
      case Closed:
        return palette.success.dark;
      case Completed:
        return palette.success.dark;
      case Unknown:
        return palette.warning.dark;
    }
  }, [value, palette]);

  return <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', color }}>{value}</Box>;
};

export default InterventionStatusField;
