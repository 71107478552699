import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CheckData, Link, ReactTablePaginated, Tooltip } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { differenceInMilliseconds } from 'date-fns';

import { DateTimeRangePicker } from '@greenisland-common/components/molecules/CustomDateTimeRangePicker/DateTimeRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  formatCurrency,
  setInvisible,
  transformBooleanYesOrNull,
  transformDefault,
  transformStatus,
  transformUnixDate,
} from '../../app/helpers/transformFunctions';

const gameRoundsStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 1fr)',
      gridGap: 16,
      marginBottom: 16,
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    content: {
      display: 'grid',
      gridTemplateColumns: '1fr 48px',
      alignItems: 'center',
      columnGap: 8,
    },
    url: {
      whiteSpace: 'nowrap',
      overflow: 'scroll',
    },
    copyButton: {
      justifySelf: 'end',
    },
    actions: {
      padding: '16px 24px',
    },
    timeError: {
      color: theme.palette.error.main,
      fontWeight: theme.typography.caption.fontWeight,
      fontSize: theme.typography.caption.fontSize,
      letterSpacing: theme.typography.caption.letterSpacing,
    },
  })
);

const GameRounds = () => {
  const classes = gameRoundsStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { externalId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [timeError, setTimeError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { data: gameRounds, fetching } = useAppSelector(state => state.gameRounds);
  const { data: replay, fetching: fetchingReplay } = useAppSelector(state => state.gameRoundReplay);
  const fetchGameRounds: (
    start: string,
    end: string,
    playerId: string,
    page?: number,
    pageSize?: number
  ) => void = async (start, end, playerId, page, pageSize) =>
    dispatch(actionCreators.getGameRounds({ start, end, page, pageSize }, playerId));

  const getReplay: (gameroundId: number) => void = async gameroundId => {
    setOpenDialog(true);
    dispatch(actionCreators.getGameRoundReplay(gameroundId.toString()));
  };

  const mutatedData = () => {
    if (!gameRounds || !gameRounds.entries || gameRounds.entries.length === 0) return undefined;
    const keys = Object.keys(gameRounds.entries[0]);

    const headers: any = keys.map(key => {
      switch (key) {
        case 'id':
        case 'gameReference':
        case 'currency':
          return setInvisible(key);
        case 'startTimestamp':
        case 'endTimestamp':
          return transformUnixDate(key, t, true);
        case 'ended':
        case 'settled':
          return transformBooleanYesOrNull(key, t);
        case 'finalState':
          return transformStatus(key, t);
        case 'betAmount':
          return {
            Header: t(key),
            accessor: key,
            Cell: (options: any) => <span>{formatCurrency(options.value.amount / 100, options.value.currency)}</span>,
            sortType: 'basic',
          };
        case 'winAmount':
          return {
            Header: t(key),
            accessor: key,
            Cell: (options: any) => <span>{formatCurrency(options.value.amount / 100, options.value.currency)}</span>,
            sortType: 'basic',
          };
        default:
          return transformDefault(key, t);
      }
    });

    headers.push(
      { id: 'transactions', accessor: 'transactions', disableFilters: true, disableSortBy: true },
      { id: 'fetchReplay', accessor: 'fetchReplay', disableFilters: true, disableSortBy: true }
    );

    const rows: any = gameRounds.entries.map(item => {
      return {
        ...item,
        transactions: <Link to={`${item.id}/transactions`}>{t('transactions')}</Link>,
        fetchReplay: <Button onClick={() => getReplay(item.id)}>{t('getReplay')}</Button>,
      };
    });

    return { headers, rows };
  };

  const fetchNext = (props: any) => {
    const { pageIndex, pageSize } = props;
    if (gameRounds && pageIndex + 1 !== gameRounds?.currentPage) {
      fetchGameRounds(
        Number(searchParams.get('startdate')).toString(),
        Number(searchParams.get('enddate')).toString(),
        externalId,
        pageIndex + 1,
        pageSize
      );
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.cardContainer}>
        <CardBody padding>
          <div className={classes.container}>
            <DateTimeRangePicker
              unixStartDate={Number(searchParams.get('startdate')) * 1000}
              unixEndDate={Number(searchParams.get('enddate')) * 1000}
              onChange={(startDate, endDate) => {
                if (differenceInMilliseconds(endDate, startDate) > 2 * 60 * 60) {
                  setTimeError(true);
                } else {
                  setTimeError(false);
                  replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
                  fetchGameRounds(startDate.toString(), endDate.toString(), externalId);
                }
              }}
            />
            {timeError && <div className={classes.timeError}>{t('maximumTwoHours')}.</div>}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CheckData
            data={mutatedData()}
            fallbackText={t('noData')}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => (
              <ReactTablePaginated
                columns={data.headers}
                data={data.rows}
                fetchData={(props: any) => fetchNext(props)}
                pageCount={gameRounds && gameRounds.totalNumberOfPages ? gameRounds.totalNumberOfPages : 1}
                pageNumber={gameRounds && gameRounds.currentPage ? gameRounds.currentPage - 1 : 0}
                loading={fetching}
                pageSize={10}
              />
            )}
          />
        </CardBody>
      </Card>
      {openDialog && (
        <Dialog maxWidth="sm" fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>{t('getReplay')}</DialogTitle>
          <CheckData
            data={replay}
            fallbackText={t('noData')}
            isLoading={fetchingReplay}
            render={({ data }) => (
              <>
                <DialogContent className={classes.content}>
                  <span className={classes.url}>{data.url}</span>
                  <div className={classes.copyButton}>
                    <Tooltip content={t('copy')}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        justIcon={<FontAwesomeIcon icon={faCopy} />}
                        onClick={() => navigator.clipboard.writeText(data.url || '')}
                      />
                    </Tooltip>
                  </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                  <Button color="secondary" variant="transparent" onClick={() => setOpenDialog(false)}>
                    {t('close')}
                  </Button>
                  <Button onClick={() => window.open(data.url, '_blank')}>{t('visitUrl')}</Button>
                </DialogActions>
              </>
            )}
          />
        </Dialog>
      )}
    </div>
  );
};

export default memo(GameRounds);
