import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Column } from 'react-table';
import { Card, CardBody, CheckData, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import { transformUnixDate } from '../../../../../../../app/helpers/transformFunctions';

const Logins = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId = '' } = useParams();
  const { data: logins, fetching } = useAppSelector(state => state.userLogins);

  useEffect(() => {
    dispatch(actionCreators.getUserLogins(userId));
  }, [dispatch, userId]);

  const sortedData = useMemo(() => {
    if (!logins?.length) return undefined;
    const headers: Column[] = Object.keys(logins[0]).map(key => {
      switch (key) {
        case 'startDateTime':
        case 'endDateTime':
          return transformUnixDate(key, t);
        default:
          return { Header: t(key) as string, accessor: key };
      }
    });

    return { headers, rows: logins.sort((a, b) => b.startDateTime - a.startDateTime) };
  }, [logins]);

  return (
    <Card>
      <CardBody>
        <CheckData
          fallbackText={t('noData')}
          data={sortedData}
          isLoading={fetching}
          checkProperties={['headers', 'rows']}
          render={({ data }) => (
            <ReactTableGI columns={data.headers} data={data.rows} pagination={data.rows.length > 5} sortable />
          )}
        />
      </CardBody>
    </Card>
  );
};

export default memo(Logins);
