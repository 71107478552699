import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, CheckData, Link, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import makeStyles from '@mui/styles/makeStyles';

import { formatCurrency, formatPercentage, setInvisible } from '../../../../../app/helpers/transformFunctions';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridGap: '16px 16px',
    marginBottom: 16,
  },
});

const ActiveBonuses = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: activeBonuses, fetching } = useAppSelector(state => state.reportingBonusesActive);

  useEffect(() => {
    dispatch(actionCreators.getBonusesActive());
  }, [dispatch]);

  const mutatedData = () => {
    if (!activeBonuses || activeBonuses.length === 0) return undefined;
    const keys = Object.keys(activeBonuses[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'userid':
          return setInvisible(key);
        case 'username':
          return {
            Header: t(key),
            accessor: key,
            Cell: (data: any) => {
              const username = data.row.values.username;
              const userid = data.row.values.userid;
              return <Link to={`../../../users/${userid}/details`}>{username}</Link>;
            },
          };
        case 'balance':
          return {
            Header: t(key),
            accessor: key,
            Cell: (data: any) => {
              const value = data.row.values.balance;
              return <span>{formatCurrency(value)}</span>;
            },
          };
        case 'amount':
          return {
            Header: t(key),
            accessor: key,
            Cell: (data: any) => {
              const value = data.row.values.amount;
              return <span>{formatCurrency(value)}</span>;
            },
          };
        case 'progress':
          return {
            Header: t(key),
            accessor: key,
            Cell: (data: any) => {
              const percent = data.row.values.progress;
              return <span>{formatPercentage(percent, { fixedTo: 2 })}%</span>;
            },
          };
        default:
          return { Header: t(key), accessor: key };
      }
    });

    const rows = activeBonuses.map((item: any) => {
      return { ...item };
    });

    return { headers, rows };
  };

  return (
    <div className={classes.container}>
      <Card>
        <CardHeader>{t('activeBonuses')}</CardHeader>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(ActiveBonuses);
