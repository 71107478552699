import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Loading } from '@greenisland/components';
import { Authorization, IEntityState, StartupSettingsSchema, useGetStartupSettings } from '@greenisland/stores';
import { useAppSelector } from '@greenisland-core/store';
import { TenantProvider } from 'src/app/context/TenantContext';

import { BaseRouting } from '../RouteInterfaces';
import VisitorPaths from '../VisitorPaths';
import BackofficeEntityWrapper from './BackofficeEntityWrapper';

interface BackofficeEntryProps extends BaseRouting {
  entitySelected: IEntityState;
  agentEntities?: Authorization.Entities;
}

const BackofficeEntry = ({ identifier, ...restProps }: BackofficeEntryProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, fetching } = useAppSelector(state => state.authentication);
  const { data: startupSettings = {} as StartupSettingsSchema, isLoading } = useGetStartupSettings({
    query: { enabled: identifier === 'OnlineCasino' },
  });

  useEffect(() => {
    if (data.token && Object.values(VisitorPaths).find((entry: string) => entry === location.pathname)) {
      navigate('/', { replace: true });
    }
  }, [data.token, location.pathname, navigate]);

  if (fetching || isLoading) {
    return <Loading center />;
  }

  return (
    <TenantProvider value={startupSettings}>
      <BackofficeEntityWrapper identifier={identifier} {...restProps} />
    </TenantProvider>
  );
};

export default BackofficeEntry;
