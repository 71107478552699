import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ETxSortingTypeSchema, ETxTypeSchema } from '@greenisland/stores';
import { Autocomplete, Box, Button, capitalize, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getUnixTime, subHours } from 'date-fns';

import { DATETIME_INPUT_FORMAT, DATETIME_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';
import Select from '@greenisland-common/components/atoms/Select';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { isCheckedValidationHandler } from '../Components/DetailsComponents/Constants/Deposits';
import { TransactionParamsType } from '../Components/DetailsComponents/Constants/Transactions';
import useGetTransactionFiltersQuery from '../hooks/useGetTransactionFiltersQuery';
import TransactionFilterTextField from './TransactionFilterTextField';

interface Props {
  fetchTransactionsHandler: any;
}

const TransactionFilters = ({ fetchTransactionsHandler }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const updateSearchParamsHandler = useUpdateSearchParams();

  const {
    startDateQuery,
    endDateQuery,
    minAmountQuery,
    maxAmountQuery,
    minPromoAmountQuery,
    maxPromoAmountQuery,
    userIdQuery,
    accountIdQuery,
    transactionIdQuery,
    gameProviderNameQuery,
    gameIdQuery,
    gameNameQuery,
    typeQuery,
    sortByQuery,
    isAscendingQuery,
  } = useGetTransactionFiltersQuery();

  const chosenType = useMemo(() => Object.keys(ETxTypeSchema)?.find(type => type === typeQuery), [typeQuery]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ maxWidth: '100%', gridGap: '15px 15px', gridTemplateColumns: '1fr 1fr', marginBottom: '15px' }}
    >
      <Stack direction="row" spacing={1}>
        <DateTimePicker
          label={t('startDate')}
          onChange={date => {
            updateSearchParamsHandler(
              TransactionParamsType.START_DATE,
              String(getUnixTime(date ? Number(date) : subHours(new Date(), 24)))
            );
          }}
          value={startDateQuery ? new Date(Number(startDateQuery) * 1000) : null}
          renderInput={params => <TextField size="small" sx={{ mb: 2 }} fullWidth {...params} />}
          inputFormat={DATETIME_INPUT_FORMAT}
          mask={DATETIME_INPUT_MASK}
          maxDate={endDateQuery ? new Date(Number(endDateQuery) * 1000) : null}
        />
        <DateTimePicker
          label={t('endDate')}
          onChange={date => {
            updateSearchParamsHandler(
              TransactionParamsType.END_DATE,
              String(getUnixTime(date ? Number(date) : new Date()))
            );
          }}
          value={endDateQuery ? new Date(Number(endDateQuery) * 1000) : null}
          renderInput={params => <TextField size="small" sx={{ mb: 2 }} fullWidth {...params} />}
          inputFormat={DATETIME_INPUT_FORMAT}
          mask={DATETIME_INPUT_MASK}
          maxDate={new Date()}
          minDate={startDateQuery ? new Date(Number(startDateQuery) * 1000) : undefined}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <TransactionFilterTextField
          label="minimumAmount"
          value={minAmountQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(TransactionParamsType.MIN_AMOUNT, event.target.value)}
          onRemove={() => updateSearchParamsHandler(TransactionParamsType.MIN_AMOUNT, '')}
        />
        <TransactionFilterTextField
          label="maximumAmount"
          value={maxAmountQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(TransactionParamsType.MAX_AMOUNT, event.target.value)}
          onRemove={() => updateSearchParamsHandler(TransactionParamsType.MAX_AMOUNT, '')}
        />
        <TransactionFilterTextField
          label="minPromoAmount"
          value={minPromoAmountQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(TransactionParamsType.MIN_PROMO_AMOUNT, event.target.value)}
          onRemove={() => updateSearchParamsHandler(TransactionParamsType.MIN_PROMO_AMOUNT, '')}
        />
        <TransactionFilterTextField
          label="maxPromoAmount"
          value={maxPromoAmountQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(TransactionParamsType.MAX_PROMO_AMOUNT, event.target.value)}
          onRemove={() => updateSearchParamsHandler(TransactionParamsType.MAX_PROMO_AMOUNT, '')}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <TransactionFilterTextField
          label="userId"
          value={userIdQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(TransactionParamsType.USER_ID, event.target.value)}
          onRemove={() => updateSearchParamsHandler(TransactionParamsType.USER_ID, '')}
        />
        <TransactionFilterTextField
          label="accountId"
          value={accountIdQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(TransactionParamsType.ACCOUNT_ID, event.target.value)}
          onRemove={() => updateSearchParamsHandler(TransactionParamsType.ACCOUNT_ID, '')}
        />
        <TransactionFilterTextField
          label="txId"
          value={transactionIdQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(TransactionParamsType.TRANSACTION_ID, event.target.value)}
          onRemove={() => updateSearchParamsHandler(TransactionParamsType.TRANSACTION_ID, '')}
        />
        <TransactionFilterTextField
          label="gameId"
          value={gameIdQuery}
          onChange={event => updateSearchParamsHandler(TransactionParamsType.GAME_ID, event.target.value)}
          onRemove={() => updateSearchParamsHandler(TransactionParamsType.GAME_ID, '')}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Box width="50%">
          <Autocomplete
            fullWidth
            id={TransactionParamsType.TYPE}
            multiple={false}
            options={Object.keys(ETxTypeSchema)}
            getOptionLabel={option => option}
            renderInput={params => <TextField {...params} size="small" label={capitalize(t('type'))} />}
            value={chosenType || null}
            onChange={(e, type) => updateSearchParamsHandler(TransactionParamsType.TYPE, type || '')}
          />
        </Box>
        <Box display="flex" width="50%" gap={1}>
          <TransactionFilterTextField
            label="gameProvider"
            value={gameProviderNameQuery}
            onChange={event => updateSearchParamsHandler(TransactionParamsType.GAME_PROVIDER_NAME, event.target.value)}
            onRemove={() => updateSearchParamsHandler(TransactionParamsType.GAME_PROVIDER_NAME, '')}
          />
          <TransactionFilterTextField
            label="gameName"
            value={gameNameQuery}
            onChange={event => updateSearchParamsHandler(TransactionParamsType.GAME_NAME, event.target.value)}
            onRemove={() => updateSearchParamsHandler(TransactionParamsType.GAME_NAME, '')}
          />
        </Box>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ width: 350 }} justifyContent="start" alignItems="center" display="flex">
        <Select
          id={TransactionParamsType.SORT_BY}
          label={capitalize(t('sortBy'))}
          value={sortByQuery}
          onChange={event => {
            if (event.target.value) {
              updateSearchParamsHandler(TransactionParamsType.SORT_BY, event.target.value.toString());
            }
          }}
          options={Object.values(ETxSortingTypeSchema)}
        />
        <FormControlLabel
          control={<Checkbox color="primary" size="medium" />}
          onChange={(_, value) => updateSearchParamsHandler(TransactionParamsType.ASCENDING, value.toString())}
          label={t('ascending')}
          checked={isCheckedValidationHandler(isAscendingQuery)}
        />
      </Stack>
      <Stack direction="row" spacing={1} sx={{ width: 500 }} justifyContent="start" alignItems="start">
        <Button size="medium" variant="contained" onClick={fetchTransactionsHandler}>
          {t('search')}
        </Button>
        <Button size="medium" variant="contained" color="warning" onClick={() => navigate(pathname, { replace: true })}>
          {t('resetFilters')}
        </Button>
      </Stack>
    </Box>
  );
};

export default TransactionFilters;
