import { ERegulatorControllerBadRequestTypeSchema } from '@greenisland/stores';

type CriticalFieldsMismatchErrorReasons = {
  [key in ERegulatorControllerBadRequestTypeSchema]: string;
};

export const CRITICAL_FIELDS_MISMATCH_ERROR_REASONS: CriticalFieldsMismatchErrorReasons = {
  [ERegulatorControllerBadRequestTypeSchema.BGCNoExceedRequest]: 'criticalFieldsMismatch.errors.BGCNoExceedRequest',
  [ERegulatorControllerBadRequestTypeSchema.InvalidRegulator]: 'criticalFieldsMismatch.errors.InvalidRegulator',
  [ERegulatorControllerBadRequestTypeSchema.BGCArticle6NotEnabled]:
    'criticalFieldsMismatch.errors.BGCArticle6NotEnabled',
  [ERegulatorControllerBadRequestTypeSchema.InvalidRegistrationState]:
    'criticalFieldsMismatch.errors.InvalidRegistrationState',
  [ERegulatorControllerBadRequestTypeSchema.InvalidToken]: 'criticalFieldsMismatch.errors.InvalidToken',
  [ERegulatorControllerBadRequestTypeSchema.InvalidUserId]: 'criticalFieldsMismatch.errors.InvalidUserId',
  [ERegulatorControllerBadRequestTypeSchema.NoExistingRegistration]:
    'criticalFieldsMismatch.errors.NoExistingRegistration',
};
