import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMatchStatusSchema, IndividualMatchProfileSchema, useGetMatches } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, MenuItem, Select, Typography } from '@mui/material';
import { DataGridProProps, GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { usePermission } from 'src/app/hooks';

import { DataGridContainer, Link, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

import AddMatchReviewDialog from './AddMatchReviews/AddMatchReviewDialog';
import KYCProfileDetails from './KYCProfileDetails/KYCProfileDetails';

const KYCUserMatches = () => {
  const { t } = useTranslation();
  const { individualId = '' } = useParams();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageTokens, setPageTokens] = useState<(string | undefined)[]>([undefined]);

  const { data, isLoading, isError } = useGetMatches(
    parseInt(individualId, 10),
    { pageSize: 5, ...(pageTokens[page] && { nextToken: pageTokens[page] }) },
    { query: { enabled: !!individualId } }
  );

  const canAddMatchReview = usePermission(OnlineCasinoPermissions.addMatchReviews);
  const [openMatchReviewDialog, setOpenMatchReviewDialog] = useState<string | undefined>(undefined);

  const handlePageChange = (newPage: number) => {
    if (newPage > page && newPage === pageTokens.length) {
      pageTokens.push(data?.nextToken);
      setPageTokens([...pageTokens]);
    }
    setPage(newPage);
  };

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: IndividualMatchProfileSchema }) => {
      if (!row.resourceId) {
        return <p> {t('kyc.resource.missingId')} </p>;
      }
      return <KYCProfileDetails resourceId={row.resourceId} />;
    },
    [t]
  );

  const getDetailPanelHeight = useCallback(() => 1000, []);

  const headers: GridColumns = [
    {
      headerName: capitalize(t('status')),
      field: 'currentStatus',
      maxWidth: 80,
    },
    {
      headerName: capitalize(t('name')),
      field: 'name',
      minWidth: 150,
    },
    {
      headerName: t('kyc.headers.match'),
      field: 'match',
      minWidth: 150,
    },
    {
      headerName: capitalize(t('gender')),
      field: 'gender',
    },
    {
      headerName: t('kyc.headers.countries'),
      field: 'countries',
      renderCell: ({ value }) => <span>{value.join(', ')}</span>,
    },
    {
      headerName: t('kyc.headers.datesOfBirth'),
      field: 'datesOfBirth',
      minWidth: 120,
      renderCell: ({ value }) => <span>{value.join(', ')}</span>,
    },
    {
      headerName: t('kyc.headers.score'),
      field: 'score',
    },
    {
      headerName: capitalize(t('actions')),
      field: 'actions',
      renderCell: ({ value }) => value,
      minWidth: 120,
    },
  ];

  const rows: GridRowsProp =
    data?.results?.map((item, i) => {
      return {
        ...item,
        id: i,
        actions:
          canAddMatchReview && item.currentStatus === EMatchStatusSchema.Open ? (
            <>
              <Button variant="contained" size="small" onClick={() => setOpenMatchReviewDialog(item.qrCode)}>
                <>
                  {t('resolve')}
                  <Box ml={1}>
                    <FontAwesomeIcon icon={faCheck} />
                  </Box>
                </>
              </Button>
              {individualId && item.qrCode && item.matchVersion && (
                <AddMatchReviewDialog
                  openDialog={openMatchReviewDialog === item.qrCode}
                  setOpenDialog={(state: boolean) =>
                    state ? setOpenMatchReviewDialog(item.qrCode) : setOpenMatchReviewDialog(undefined)
                  }
                  userId={parseInt(individualId, 10)}
                  qrCode={item.qrCode}
                  matchVersion={item.matchVersion}
                />
              )}
            </>
          ) : null,
      };
    }) || [];

  return (
    <>
      <Typography sx={{ marginBottom: 2 }} variant="body2">
        {t('kyc.monitoring.openMatchesTitle')}{' '}
        <Link to={`../../users/${individualId}/details`} target="_blank">
          {individualId}
        </Link>
      </Typography>
      <PermissionWrapper
        errorMessage={t('kyc.individual.errors.matches')}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getMatches}
      >
        <DataGridContainer>
          <StyledDataGrid
            disableSelectionOnClick
            autoHeight
            columns={headers}
            rows={rows}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            components={{
              Footer: () => (
                <>
                  <Button onClick={() => handlePageChange(page - 1)} disabled={page === 0}>
                    {t('previous')}
                  </Button>
                  <Button onClick={() => handlePageChange(page + 1)} disabled={!data?.nextToken}>
                    {t('next')}
                  </Button>
                  <Select value={pageSize} onChange={e => setPageSize(e.target.value as number)}>
                    {[10, 25, 50, 100].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ),
            }}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </>
  );
};

export default KYCUserMatches;
