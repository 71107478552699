import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetEvents } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, LinearProgress, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { DataGridContainer, ErrorCard, Link, StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { usePermission } from '../../../../app/hooks';

const EventsMain = () => {
  const { t } = useTranslation();
  const hasPermission = usePermission(OnlineCasinoPermissions.getEvents);
  if (!hasPermission)
    return (
      <ErrorCard severity="info">{t('common.noPermissionToSeeThePage', OnlineCasinoPermissions.getEvents)}</ErrorCard>
    );
  return <EventsInnerChild />;
};

const currentDate = new Date();

const EventsInnerChild = () => {
  const { t } = useTranslation();
  const canReadEventnameStream = usePermission(OnlineCasinoPermissions.getEventstreamEventname);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 1 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));
  const { data, isLoading, isError } = useGetEvents(
    { start, end },
    {
      query: { retry: false },
    }
  );

  const rows = useMemo(() => {
    if (!data) return [];
    return data
      .map(event => ({ ...event, id: event.eventName }))
      .sort((a, b) => a.eventName.localeCompare(b.eventName));
  }, [data]);

  const columns: GridColumns = [
    {
      headerName: capitalize(t('eventName')),
      field: 'eventName',
      renderCell: ({ value }) =>
        canReadEventnameStream ? (
          <Link to={`stream?eventname=${value}&startdate=${start}&enddate=${end}`}>{value}</Link>
        ) : (
          value
        ),
      flex: 1,
      sortable: true,
    },
  ];

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={start * 1000}
        unixEndDate={end * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      {!isError ? (
        <DataGridContainer>
          <StyledDataGrid
            density="compact"
            autoHeight
            loading={isLoading}
            rows={rows}
            columns={columns}
            disableDensitySelector
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableChildrenSorting
            disableChildrenFiltering
            disableMultipleColumnsSorting
            disableColumnResize
            disableColumnReorder
            isRowSelectable={() => false}
            components={{ LoadingOverlay: LinearProgress }}
          />
        </DataGridContainer>
      ) : (
        <ErrorState errorMessage={t('reporting.events.loadingError')} />
      )}
    </Stack>
  );
};

export default memo(EventsMain);
