import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Loading } from '@greenisland/components';
import {
  actionCreators,
  CampaignTemplate,
  CampaignTemplateDetails,
  handleFailureFromApp,
  TemplateType,
  TemplateTypes,
} from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import TemplateForm from './TemplateForm';

const LegacyDefaultTemplateDetails = () => {
  const dispatch = useAppDispatch();
  const { templateType }: any = useParams();
  const navigate = useNavigate();

  const [templateTypesState, setTemplateTypeState] = useState<{ fetching: boolean; types: TemplateTypes }>({
    fetching: true,
    types: [],
  });

  const { selectedEntity, selectedProduct } = useAppSelector(state => state.entities);
  const { data: campaignTemplates, fetching } = useAppSelector(state => state.campaignTemplates);
  const { data: templateContent, fetching: fetchingTemplateContent } = useAppSelector(state => state.campaignTemplate);

  const updateCampaignTemplateType = async (template: CampaignTemplateDetails) =>
    dispatch(actionCreators.updateCampaignDefaultTemplate(template));
  const setCampaignTemplateType = async (template: CampaignTemplateDetails) =>
    dispatch(actionCreators.setCampaignDefaultTemplate(template));
  const fetchTemplateTypes = async () => actionCreators.getTemplateTypes();
  const fetchCampaignDefaultTemplates = () => dispatch(actionCreators.getCampaignDefaultTemplates());
  const fetchTemplateContent = () => dispatch(actionCreators.getCampaignDefaultTemplate(templateType));

  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchTemplateTypes();
        setTemplateTypeState({ fetching: false, types: data });
        if (!campaignTemplates) fetchCampaignDefaultTemplates();
        if (templateType !== 'add') fetchTemplateContent();
      } catch (e) {
        handleFailureFromApp(e, dispatch);
      }
    })();
  }, []);

  const filterTemplateTypes = () => {
    let filteredTemplateTypes: TemplateTypes = templateTypesState.types;
    if (templateType === 'add') {
      if (campaignTemplates) {
        const usedTemplatesTypes = campaignTemplates.map((template: CampaignTemplate) => template.type);
        filteredTemplateTypes = templateTypesState.types.filter(
          (type: TemplateType) => usedTemplatesTypes && !usedTemplatesTypes.includes(type.name)
        );
      }
    } else {
      const usedTemplatesTypes = campaignTemplates?.map((template: CampaignTemplate) => template.type);
      filteredTemplateTypes = templateTypesState.types.filter(
        (type: TemplateType) => usedTemplatesTypes && usedTemplatesTypes.includes(type.name)
      );
    }
    return filteredTemplateTypes;
  };

  const submitCampaignTemplate = (data: CampaignTemplateDetails) => {
    if (templateType === 'add') {
      setCampaignTemplateType(data).then(resp => {
        const typedResp: any = resp;
        if (typedResp && typedResp.status === 200) navigate('../campaigns/overview');
      });
    } else {
      updateCampaignTemplateType(data).then(resp => {
        const typedResp: any = resp;
        if (typedResp && typedResp.status === 200) navigate('../campaigns/overview');
      });
    }
  };

  return !templateTypesState.fetching && !fetching && !fetchingTemplateContent ? (
    <TemplateForm
      onSubmit={data => submitCampaignTemplate(data)}
      templateTypes={filterTemplateTypes()}
      templateData={templateType !== 'add' ? templateContent : undefined}
      returnLink={`/${selectedEntity}/${selectedProduct}/marketing/campaigns/overview`}
    />
  ) : (
    <Loading center />
  );
};

export default memo(LegacyDefaultTemplateDetails);
