import { SyntheticEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ECheckinControllerBadRequestTypeSchema, useResetCheckinAttempts } from '@greenisland/stores';
import { ResetCheckinAttemptsRequestBodySchema } from '@greenisland/stores/dist/api/schemas/resetCheckinAttemptsRequestBodySchema';
import { LoadingButton } from '@mui/lab';
import { capitalize, Dialog, DialogActions, DialogTitle, IconButton, Stack, Tab, Tabs } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon, TabPanel } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import { RESET_SCAN_ATTEMPT__ERROR_REASONS } from '../Constants/ResetScanAttemptErrorReasons';

interface Props {
  open: boolean;
  onClose: () => void;
  userId?: string;
  email?: string;
}

const ResetScanAttemptsFormDialog = ({ open, onClose, userId, email }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTab, setSelectedTab] = useState(0);

  const { mutate: resetCheckinAttempts, isLoading } = useResetCheckinAttempts({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('tasks.verifications.checkin.titles.verificationsSuccessfullyReset'), { variant: 'success' });
        onClose();
        reset();
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: ECheckinControllerBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason ? RESET_SCAN_ATTEMPT__ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const methods = useForm<ResetCheckinAttemptsRequestBodySchema>({
    shouldFocusError: true,
  });

  const { control, handleSubmit, reset } = methods;

  const handleTabChange = (_: SyntheticEvent, newValue: number) => setSelectedTab(newValue);

  const onSubmit = (data: ResetCheckinAttemptsRequestBodySchema) => {
    if (selectedTab === 0 && data?.userId) {
      resetCheckinAttempts({ data: { userId: Number(data.userId) } });
    } else {
      if (data?.email) {
        resetCheckinAttempts({ data: { email: data.email } });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle> {t('tasks.verifications.checkin.titles.resetScanAttempts')}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs period">
                <Tab label={t('tasks.verifications.checkin.titles.byUserId')} id="tab-1" aria-controls="tab-panel-1" />
                <Tab label={t('tasks.verifications.checkin.titles.byEmail')} id="tab-2" aria-controls="tab-panel-2" />
              </Tabs>
              <TabPanel sx={{ mt: 2 }} value={selectedTab} index={0}>
                <Input
                  fullWidth
                  size="small"
                  label={capitalize(t('userId'))}
                  control={control}
                  type="text"
                  name="userId"
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  defaultValue={userId}
                  disabled={!!userId}
                />
              </TabPanel>
              <TabPanel sx={{ mt: 2 }} value={selectedTab} index={1}>
                <Input
                  fullWidth
                  size="small"
                  label={capitalize(t('email'))}
                  control={control}
                  type="email"
                  name="email"
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  defaultValue={email}
                  disabled={!!email}
                />
              </TabPanel>
              <DialogActions>
                <LoadingButton color="primary" type="submit" variant="contained" loading={isLoading}>
                  {t('save')}
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ResetScanAttemptsFormDialog;
