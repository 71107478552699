import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CheckData, Link, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import makeStyles from '@mui/styles/makeStyles';

import { setInvisible } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridGap: '16px 16px',
    marginBottom: 16,
  },
});

const BonusBlacklist = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const canReadBonusesBlacklist = usePermission(OnlineCasinoPermissions.getBonusesBlacklist);
  const { data: bonusesBlacklist, fetching } = useAppSelector(state => state.reportingBonusesBlacklist);

  useEffect(() => {
    if (canReadBonusesBlacklist) {
      dispatch(actionCreators.getBonusesBlacklist());
    }
  }, [dispatch, canReadBonusesBlacklist]);

  const mutatedData = () => {
    if (!bonusesBlacklist || bonusesBlacklist.length === 0) return undefined;

    const keys = Object.keys(bonusesBlacklist[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'username':
          return {
            Header: t(key),
            accessor: key,
            Cell: (row: any) => {
              const userId = row.row.values.userId;
              const username = row.row.values.username;
              return <Link to={`../../../users/${userId}/details`}>{username}</Link>;
            },
          };
        case 'userId':
          return setInvisible(key);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    return { headers, rows: bonusesBlacklist };
  };

  return canReadBonusesBlacklist ? (
    <div className={classes.container}>
      <Card>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};

export default memo(BonusBlacklist);
