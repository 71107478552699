import { memo, useCallback, useState } from 'react';
import { useGetWithdrawalsGeneral, WithdrawalSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { DataGridProProps } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPagination, StyledDataGrid } from '@greenisland-common/components/atoms';

import useGetWithdrawalsHeaders from './hooks/useGetWithdrawalsHeaders';
import { numberOfRowsPerPage } from '../../../../../app/constants/defaultvalues';
import { usePermission } from '../../../../../app/hooks';
import FlagDataDetails from './FlagDataDetails';

const LookupWithdrawals = () => {
  const canReadEventStream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canReadWithdrawals = usePermission(OnlineCasinoPermissions.getWithdrawalsGeneral);

  const [page, setPage] = useState(0);

  const { data: withdrawalsData, isLoading } = useGetWithdrawalsGeneral(
    {
      numberOfItems: numberOfRowsPerPage,
      pageNumber: page,
    },
    {
      query: {
        enabled: canReadWithdrawals,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
      },
    }
  );

  const { columns } = useGetWithdrawalsHeaders();

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: WithdrawalSchema }) => <FlagDataDetails flagData={row.flagData} />,
    []
  );

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  return (
    <>
      <DataGridContainer>
        <StyledDataGrid
          initialState={{ columns: { columnVisibilityModel: { stream: canReadEventStream } } }}
          density="compact"
          loading={isLoading}
          rows={withdrawalsData?.items ?? []}
          columns={columns}
          pagination
          page={page}
          pageSize={numberOfRowsPerPage}
          paginationMode="server"
          onPageChange={p => {
            setPage(p);
          }}
          showColumnRightBorder={false}
          disableDensitySelector
          disableSelectionOnClick
          rowCount={withdrawalsData?.pagingDetails?.totalItems ?? 0}
          components={{
            Pagination: DataGridPagination,
          }}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
        />
      </DataGridContainer>
    </>
  );
};

export default memo(LookupWithdrawals);
