import { useMemo } from 'react';
import { CriticalFieldsMismatchSchema, EResolveCriticalFieldsMismatchUpdateDirectionSchema } from '@greenisland/stores';

import { getDateTimeFromUnix } from '../../../../../../../app/helpers/transformFunctions';

export enum CriticalMismatchFieldMode {
  CONFIRM = 'CONFIRM',
}

export interface CriticalMismatchField {
  mainTitle?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  direction: EResolveCriticalFieldsMismatchUpdateDirectionSchema;
  warningMessage: string;
}

export const getCriticalFieldBirthdayDateHandler = (value: number | undefined) => {
  if (!value) return undefined;

  return getDateTimeFromUnix(value).split(' ')[0];
};

export function useGetMismatchCriticalFields(
  fetchCriticalFieldsMismatchData: boolean,
  oldData?: CriticalFieldsMismatchSchema,
  newData?: CriticalFieldsMismatchSchema
): CriticalMismatchField[] {
  return useMemo(
    () => [
      {
        mainTitle: 'criticalFieldsMismatch.titles.currentCriticalFields',
        warningMessage: 'criticalFieldsMismatch.titles.changeUserDetailsWarning',
        firstName: fetchCriticalFieldsMismatchData
          ? newData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.firstName
          : oldData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.firstName,
        lastName: fetchCriticalFieldsMismatchData
          ? newData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.lastName
          : oldData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.lastName,
        dateOfBirth: getCriticalFieldBirthdayDateHandler(
          fetchCriticalFieldsMismatchData
            ? newData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.dateOfBirth
            : oldData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.dateOfBirth
        ),
        direction: EResolveCriticalFieldsMismatchUpdateDirectionSchema.userDetails,
      },
      {
        mainTitle: 'criticalFieldsMismatch.titles.newCriticalFields',
        warningMessage: 'criticalFieldsMismatch.titles.changeCriticalFieldsWarning',
        firstName: fetchCriticalFieldsMismatchData
          ? newData?.BGCCriticalFieldsMismatch?.newCriticalFields?.firstName
          : oldData?.BGCCriticalFieldsMismatch?.newCriticalFields?.firstName,
        lastName: fetchCriticalFieldsMismatchData
          ? newData?.BGCCriticalFieldsMismatch?.newCriticalFields?.lastName
          : oldData?.BGCCriticalFieldsMismatch?.newCriticalFields?.lastName,
        dateOfBirth: getCriticalFieldBirthdayDateHandler(
          fetchCriticalFieldsMismatchData
            ? newData?.BGCCriticalFieldsMismatch?.newCriticalFields?.dateOfBirth
            : oldData?.BGCCriticalFieldsMismatch?.newCriticalFields?.dateOfBirth
        ),
        direction: EResolveCriticalFieldsMismatchUpdateDirectionSchema.criticalFields,
      },
    ],
    [
      fetchCriticalFieldsMismatchData,
      newData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.dateOfBirth,
      newData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.firstName,
      newData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.lastName,
      newData?.BGCCriticalFieldsMismatch?.newCriticalFields?.dateOfBirth,
      newData?.BGCCriticalFieldsMismatch?.newCriticalFields?.firstName,
      newData?.BGCCriticalFieldsMismatch?.newCriticalFields?.lastName,
      oldData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.dateOfBirth,
      oldData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.firstName,
      oldData?.BGCCriticalFieldsMismatch?.currentCriticalFields?.lastName,
      oldData?.BGCCriticalFieldsMismatch?.newCriticalFields?.dateOfBirth,
      oldData?.BGCCriticalFieldsMismatch?.newCriticalFields?.firstName,
      oldData?.BGCCriticalFieldsMismatch?.newCriticalFields?.lastName,
    ]
  );
}
