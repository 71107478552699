import { createRef, Dispatch, forwardRef, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faAdd, faClose } from '@fortawesome/free-solid-svg-icons';
import {
  ELanguagesSchema,
  EMIMETypeSchema,
  getGetAllMediaGalleryEntriesOfTypeQueryKey,
  MediaGalleryEntrySchema,
  MediaGalleryEntryTypeParamParameter,
  useUploadMediaGalleryEntry,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { AppBar, Box, Button, Dialog, Fab, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { fileToBase64 } from '@greenisland-common/helpers';

import { usePermission } from '../../hooks';
import { MediaGalleryList } from './MediaGalleryList';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  isOpen: boolean;
  mediaGalleryEntryType: MediaGalleryEntryTypeParamParameter;
  onSave: (media: MediaGalleryEntrySchema[]) => void;
  onClose: () => void;
  defaultMedia?: MediaGalleryEntrySchema[];
  isMultiSelect?: boolean;
  disableFilter?: boolean;
  resetMedia?: boolean;
  setResetMedia?: Dispatch<SetStateAction<boolean>>;
}

const MediaGallery = ({
  isOpen,
  defaultMedia,
  mediaGalleryEntryType,
  onSave,
  onClose,
  isMultiSelect = false,
  disableFilter = false,
  resetMedia = false,
  setResetMedia,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const canReadMediaGalleryEntries = usePermission(OnlineCasinoPermissions.getAllMediaGalleryEntriesOfType);
  const canAddMediaGalleryEntries = usePermission(OnlineCasinoPermissions.uploadMediaGalleryEntry);
  const imageInputRef = createRef<HTMLInputElement>();
  const [selectedMedia, setSelectedMedia] = useState<MediaGalleryEntrySchema[]>(defaultMedia ?? []);

  const mediaGalleryEntryTypeParam = {
    mediaGalleryEntryType: mediaGalleryEntryType,
  };

  const uploadMutation = useUploadMediaGalleryEntry({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetAllMediaGalleryEntriesOfTypeQueryKey(mediaGalleryEntryTypeParam));
      },
      onError: async error => {
        enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
      },
    },
  });

  useEffect(() => {
    if (resetMedia) {
      if (setResetMedia && defaultMedia) {
        setSelectedMedia(defaultMedia);
        setResetMedia(false);
      }
    }
  }, [resetMedia, defaultMedia, setResetMedia]);

  const handleSave = () => {
    if (selectedMedia.length > 0) {
      onSave(selectedMedia);
    }
  };

  const handleUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      event.target.value = '';
      if (file) {
        uploadMutation.mutate({
          data: {
            asset: {
              content: await fileToBase64(file),
              name: file.name,
              mimeType: file.type as EMIMETypeSchema,
            },
            mediaGalleryEntryType: mediaGalleryEntryType,
          },
          params: {
            language: ELanguagesSchema.EN,
          },
        });
      }
    },
    [mediaGalleryEntryType, uploadMutation]
  );

  if (!canReadMediaGalleryEntries) return null;

  return (
    <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t('content.mediaGallery.title')}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            {t('save')}
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: 3 }}>
        <input ref={imageInputRef} type="file" accept="image/*" hidden onChange={handleUpload} />
        <MediaGalleryList
          mediaGalleryEntryType={mediaGalleryEntryType}
          selectionEnabled
          onSelect={setSelectedMedia}
          selectedMedia={selectedMedia}
          isMultiSelect={isMultiSelect}
          disableFilter={disableFilter}
        />
        {canAddMediaGalleryEntries ? (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
            }}
            onClick={() => imageInputRef.current?.click()}
          >
            <FontAwesomeIcon icon={faAdd} sx={{ mr: 1 }} />
            {t('content.mediaGallery.uploadImage')}
          </Fab>
        ) : null}
      </Box>
    </Dialog>
  );
};

export default MediaGallery;
