import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { SelfTestSchema, useGetSelfTests } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { DataGridPro, GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  ErrorCard,
  FontAwesomeIcon,
  Link,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import PgsiDialog from './Pgsi/PgsiDialog';
import { getDateFromUnix } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import PgsiResultField from './PgsiResultField';

interface Props {
  userId: string;
}

const Pgsi = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadPgsi = usePermission(OnlineCasinoPermissions.getSelfTests);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedTest, setSelectedTest] = useState<SelfTestSchema>();
  const {
    data: pgsiData,
    isLoading,
    isError,
    error,
  } = useGetSelfTests({ userId: userId }, { query: { enabled: !!userId } });

  const rows = useMemo(() => {
    if (pgsiData?.selfTests) {
      return pgsiData.selfTests.map((test, i) => ({ id: i, ...test }));
    }
    return [];
  }, [pgsiData?.selfTests]);

  if (!canReadPgsi) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={95} />;
  if (isError || !pgsiData) return <ErrorCard sx={{ height: 95 }}>{error?.message}</ErrorCard>;

  const getTest = async (test: SelfTestSchema) => {
    setSelectedTest(test);
    setOpen(true);
  };

  const headers: GridColumns = [
    {
      headerName: capitalize(t('pgsi.date')),
      field: 'created',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
      minWidth: 100,
      flex: 1,
    },
    { headerName: capitalize(t('pgsi.totalScore')), field: 'totalScore', flex: 0.5, minWidth: 120 },
    {
      headerName: capitalize(t('pgsi.classification')),
      field: 'PGSIClassification',
      renderCell: ({ value }) => <PgsiResultField value={value} />,
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faEye} />}
          label="view"
          onClick={() => getTest(params.row)}
          key="view"
        />,
      ],
      flex: 0.3,
    },
  ];

  return (
    <>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('pgsi.title')} />
          <Button sx={{ mr: 1 }} variant="outlined" color="primary" size="small" component={Link} to="../pgsi">
            {t('seeMore')}
          </Button>
        </Box>
        <CardContent>
          {rows.length > 0 ? (
            <DataGridContainer>
              <StyledDataGrid autoHeight density="compact" columns={headers} rows={rows} hideFooter />
            </DataGridContainer>
          ) : (
            <NoData />
          )}
        </CardContent>
      </Card>
      {open && selectedTest ? <PgsiDialog openDialog={open} setOpenDialog={setOpen} test={selectedTest} /> : null}
    </>
  );
};
export default memo(Pgsi);
