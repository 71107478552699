import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EVoucherStatusSchema,
  EVoucherTypeSchema,
  OrderBechargeErrorSchema,
  useGetUserVouchers,
  useGetUserVoucherSensitiveInfoHook,
  useOrderBechargeVoucher,
  UserVoucherSchema,
  UserVoucherSensitiveInfoSchema,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, capitalize } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { formatCurrency, getDateFromUnix, StatusSpan } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import { DataGridContainer, ErrorCard, NoData, StyledDataGrid } from '@greenisland-common/components/atoms';

import CodeDialog from '../CodeDialog';

interface Props {
  userId: string;
  start?: number;
  end?: number;
  pageSize?: number;
}
const VouchersTable = ({ userId, start, end, pageSize = 25 }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [voucherCode, setVoucherCode] = useState<UserVoucherSensitiveInfoSchema | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const canReadVouchers = usePermission(OnlineCasinoPermissions.getUserVouchers);
  const canReadVoucherCode = usePermission(OnlineCasinoPermissions.getUserVoucherCode);
  const canOrderBechargeCode = usePermission(OnlineCasinoPermissions.orderBechargeVoucher);
  const {
    data: vouchersData,
    isLoading,
    isError,
    error,
  } = useGetUserVouchers(
    userId,
    {
      start,
      end,
    },
    {
      query: {
        enabled: canReadVouchers,
      },
    }
  );
  const orderBechargeVoucher = useOrderBechargeVoucher({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: (error: OrderBechargeErrorSchema) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  });
  const showCode = useGetUserVoucherSensitiveInfoHook();

  const getCode = async (voucher: UserVoucherSchema) => {
    try {
      const code = await showCode(userId, voucher.voucherId, voucher.type);

      if (code) {
        setVoucherCode(code);
        setOpen(true);
      }
    } catch (e) {
      enqueueSnackbar(t('error'), { variant: 'error' });
    }
  };

  const headers: GridColumns = [
    { headerName: 'ID', field: 'voucherId', width: 120 },
    { headerName: capitalize(t('amount')), field: 'amount', valueFormatter: ({ value }) => formatCurrency(value) },
    {
      headerName: capitalize(t('code')),
      field: 'code',
      renderCell: ({ value }) => <div>{value.replace(/.(?=.{4})/g, '*')}</div>,
      width: 140,
    },
    { headerName: capitalize(t('status')), field: 'status', renderCell: ({ value }) => <StatusSpan value={value} /> },
    { headerName: capitalize(t('usedUserName')), field: 'usedUserName', width: 140 },
    { headerName: capitalize(t('orderedUserName')), field: 'orderedUserName', width: 140 },
    {
      headerName: capitalize(t('consumedOn')),
      field: 'consumedOn',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
      width: 120,
    },
    {
      headerName: capitalize(t('timestamp')),
      field: 'timestamp',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
    },
    { headerName: capitalize(t('pin')), field: 'pin' },
    { headerName: capitalize(t('type')), field: 'type' },
    { headerName: capitalize(t('product')), field: 'product', width: 120 },
    { headerName: '', field: 'actions', renderCell: ({ value }) => value, width: 150 },
  ];

  const rows: GridRowsProp =
    vouchersData?.map((item, i) => {
      return {
        ...item,
        id: i,
        actions: (
          <>
            {(canReadVoucherCode && item.type === EVoucherTypeSchema.Internal) ||
            (canReadVoucherCode &&
              item.type === EVoucherTypeSchema.BeCharge &&
              (item.status === EVoucherStatusSchema.Available ||
                item.status === EVoucherStatusSchema.Revoked ||
                item.status === EVoucherStatusSchema.RevokedAndReawarded)) ? (
              <Button
                sx={{ minWidth: 'fit-content' }}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => getCode(item)}
              >
                {t('showCode')}
              </Button>
            ) : undefined}
            {item.type === EVoucherTypeSchema.BeCharge &&
            item.status === EVoucherStatusSchema.NotOrdered &&
            canOrderBechargeCode ? (
              <Button
                sx={{ minWidth: 'fit-content' }}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => orderBechargeVoucher.mutate({ bechargeVoucherId: item.voucherId })}
              >
                {t('order')}
              </Button>
            ) : undefined}
          </>
        ),
      };
    }) ?? [];

  if (isError) return <ErrorCard sx={{ height: 95 }}>{error?.message}</ErrorCard>;
  if (rows.length === 0) return <NoData />;

  return (
    <>
      <DataGridContainer>
        <StyledDataGrid
          loading={isLoading}
          autoHeight
          density="compact"
          columns={headers}
          rows={rows}
          pagination
          pageSize={pageSize}
        />
      </DataGridContainer>
      {open && voucherCode ? <CodeDialog isOpen={open} onClose={() => setOpen(false)} code={voucherCode.code} /> : null}
    </>
  );
};

export default VouchersTable;
