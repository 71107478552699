import { memo, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ErrorSchema } from '@greenisland/stores';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';
import {
  getGetOperatorQueryKey,
  Operator,
  OperatorMarketId,
  useGetOperator,
  usePutOperator,
} from '@greenisland-store/gameServer';
import { Button, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { usePermission } from 'src/app/hooks';

import { PermissionWrapper } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';
import SelectDropdownButton from '@greenisland-common/components/organisms/SelectDropdownButton';

export function useOperatorMarketValues() {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { value: OperatorMarketId.DEF, label: t('DEF') },
      { value: OperatorMarketId.ES, label: t('ES') },
      { value: OperatorMarketId.NL, label: t('NL') },
    ],
    [t]
  );
}

const OperatorDetails = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const OperatorMarketIdValues = useOperatorMarketValues();
  const [marketIdValue, setMarketIdValue] = useState(OperatorMarketIdValues[0]);
  const canReadOperator = usePermission(GameServerPermissions.getOperator);
  const canEditOperator = usePermission(GameServerPermissions.setOperator);
  const theme = useTheme();

  const {
    data: operator,
    isLoading,
    isError,
  } = useGetOperator({ query: { enabled: canReadOperator, keepPreviousData: true } });

  const methods = useForm<Operator>({
    shouldFocusError: true,
    mode: 'onChange',
  });
  const { handleSubmit, reset } = methods;

  const { mutate: createGameMutation } = usePutOperator({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetOperatorQueryKey());
        enqueueSnackbar(t('success'), { variant: 'success' });
        reset();
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  useEffect(() => {
    const value = OperatorMarketIdValues.find(x => x.value === operator?.marketId) ?? OperatorMarketIdValues[0];
    setMarketIdValue(value);
  }, [operator, OperatorMarketIdValues]);

  const onSubmit = (formData: Operator) => {
    formData.marketId = marketIdValue.value;
    createGameMutation({ data: formData });
  };

  return (
    <PermissionWrapper
      errorMessage={t('content.gameserver.operator.permissions.getOperator')}
      isError={isError}
      isLoading={isLoading}
      permission={GameServerPermissions.getOperator}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Typography sx={{ mb: 2 }} variant="body2" fontSize="large" fontWeight="bold">
              {t('content.gameserver.operator.form.operatorName')}
            </Typography>

            <Input
              size="medium"
              label={t('content.gameserver.operator.operatorName')}
              type="text"
              name="operatorName"
              disabled={true}
              defaultValue={operator?.operatorName}
              required
              rules={{ required: t('fieldIsRequired') }}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
            />

            <Typography sx={{ mb: 2 }} variant="body2" fontSize="large" fontWeight="bold">
              {t('content.gameserver.operator.form.marketId')}
            </Typography>

            <SelectDropdownButton
              chosenValue={marketIdValue}
              options={OperatorMarketIdValues}
              onChange={setMarketIdValue}
              disabled={!canEditOperator}
            />
            <PermissionWrapper
              errorMessage={t('content.gameserver.operator.permissions.setOperator')}
              isError={isError}
              isLoading={isLoading}
              permission={GameServerPermissions.setOperator}
            >
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {t('save')}
              </Button>
            </PermissionWrapper>
          </Stack>
        </form>
      </FormProvider>
    </PermissionWrapper>
  );
};

export default memo(OperatorDetails);
