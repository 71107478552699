import React from 'react';
import { Card, CardBody, NavPills } from '@greenisland/components';

import EmailNotificationTemplate from './EmailNotificationTemplate';
import PortalNotificationTemplate from './PortalNotificationTemplate';
import PushNotificationTemplate from './PushNotificationTemplate';
import SmsNotificationTemplate from './SmsNotificationTemplate';

const TemplateForm = () => {
  return (
    <Card>
      <CardBody padding>
        <NavPills
          tabs={[
            {
              tabButton: 'Email',
              tabContent: <EmailNotificationTemplate />,
            },
            {
              tabButton: 'Sms',
              tabContent: <SmsNotificationTemplate />,
            },
            {
              tabButton: 'Push',
              tabContent: <PushNotificationTemplate />,
            },
            {
              tabButton: 'Portal',
              tabContent: <PortalNotificationTemplate />,
            },
          ]}
        />
      </CardBody>
    </Card>
  );
};

export default TemplateForm;
