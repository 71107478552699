import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button, Card, CardBody, Inputfield } from '@greenisland/components';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import EditableList from '@greenisland-common/components/organisms/EditableList';

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: { maxWidth: '700px' },
  subtitle: { ...theme.typography.subtitle2, marginBottom: '16px' },
  buttonContainer: { display: 'flex', marginTop: '32px' },
  button: { marginRight: '8px' },
}));

const AddEmailGroup = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [emails, setEmails] = useState<string[]>([]);

  return (
    <Card className={classes.cardContainer}>
      <CardBody padding>
        <Inputfield label={t('Group name')} value={name} onChange={e => setName(e.target.value)} />
        <h6 className={classes.subtitle}>{t('messaging.notificationGroups.emailAddresses')}</h6>
        <EditableList listItems={emails} onChange={setEmails} type="email" />
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={() => navigate('../emailgroups')}>
            {t('save')}
          </Button>
          <Button color="secondary" onClick={() => navigate('../emailgroups')}>
            {t('cancel')}
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddEmailGroup;
