import { useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EMediaGalleryEntryTypeSchema, PromotionalCalendarSchema } from '@greenisland/stores';
import { Autocomplete, Box, Button, capitalize, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FontAwesomeIcon, SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';

import {
  defaultPromotionalCalendarRankSettingValues,
  FORM_FIELD_PRIZES_KEY,
  promotionalCalendarLoyaltyRankOptions,
} from '../../constants';
import {
  CalendarPromotionDialogSchema,
  PromotionalCalendarLoyaltyRankOption,
  PromotionalCalendarPrizeRank,
} from './helpers';

interface Props {
  ordinalIndex: number;
  selectedPromotionalCalendar?: PromotionalCalendarSchema;
  actionOptions: PromotionalCalendarLoyaltyRankOption[];
  isLoadingActions: boolean;
  availableLoyaltyRankOptions: PromotionalCalendarLoyaltyRankOption[];
}

const PromotionalCalendarRankSettings = ({
  ordinalIndex,
  selectedPromotionalCalendar,
  availableLoyaltyRankOptions,
  actionOptions,
  isLoadingActions,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { control, watch, setValue, getValues } = useFormContext<CalendarPromotionDialogSchema>();

  const prizes = watch('prizes');
  const chosenMinimalLoyaltyRankRequirement = watch('minimalLoyaltyRankRequirement');

  const { fields, remove } = useFieldArray({
    control,
    name: `prizes[${ordinalIndex}].ranks`,
  });

  const canAddAdditionalRankSettings = useMemo(
    () => prizes[ordinalIndex]?.ranks?.length !== availableLoyaltyRankOptions?.length,
    [availableLoyaltyRankOptions?.length, ordinalIndex, prizes]
  );

  const updateFormPrizeSettingsHandler = useCallback(
    (newRank: PromotionalCalendarPrizeRank) => {
      const updatedRanks = [...(getValues()?.prizes[ordinalIndex]?.ranks || []), newRank];
      const updatedPrizes = getValues()?.prizes?.map((prize, index) =>
        index === ordinalIndex ? { ...prize, ranks: updatedRanks } : prize
      );
      return setValue(FORM_FIELD_PRIZES_KEY, updatedPrizes);
    },
    [getValues, ordinalIndex, setValue]
  );

  const addRankSectionSettingsHandler = useCallback(() => {
    if (prizes[ordinalIndex]?.ranks?.length >= 1) {
      const lastElementIndex = prizes[ordinalIndex]?.ranks?.length - 1;
      const { name, description, redirectUrl, images, actionId, loyaltyRank } =
        prizes[ordinalIndex].ranks[lastElementIndex];

      return updateFormPrizeSettingsHandler({
        name,
        description,
        redirectUrl,
        images,
        actionId,
        loyaltyRank,
        id: undefined,
      });
    }

    return updateFormPrizeSettingsHandler(defaultPromotionalCalendarRankSettingValues);
  }, [ordinalIndex, prizes, updateFormPrizeSettingsHandler]);

  const isRemoveButtonForRankActive = useCallback(
    (rankIndex: number) => {
      const chosenMinimalLoyaltyRankIndex = promotionalCalendarLoyaltyRankOptions?.findIndex(
        option => option?.value === selectedPromotionalCalendar?.minimalLoyaltyRankRequirement
      );

      const staticRanksQuantity = promotionalCalendarLoyaltyRankOptions?.length - 1 - chosenMinimalLoyaltyRankIndex;

      if (!selectedPromotionalCalendar || (selectedPromotionalCalendar && staticRanksQuantity < rankIndex)) {
        return (
          <FontAwesomeIcon
            icon={faTrash}
            sx={{ marginLeft: 1, color: 'error.main', cursor: 'pointer' }}
            onClick={() => remove(rankIndex)}
          />
        );
      }

      return null;
    },
    [remove, selectedPromotionalCalendar]
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {fields?.map((rank, rankIndex) => {
        return (
          <Box
            key={rank.id}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.up('md')]: {
                width: '70%',
              },
            }}
          >
            <Box
              px={2}
              sx={{
                border: '1px solid #eee',
                borderRadius: '4px',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box width="60%" display="flex" alignItems="center" my={2}>
                  <Typography fontWeight={600} variant="body2" fontSize="large">
                    {`${t('marketing.promotionalCalendar.titles.rank')} ${rankIndex + 1}`}
                  </Typography>
                  {isRemoveButtonForRankActive(rankIndex)}
                </Box>
                <Box width="40%" alignItems="center">
                  <SelectInput
                    size="small"
                    name={`prizes[${ordinalIndex}].ranks[${rankIndex}].loyaltyRank`}
                    label={t('marketing.promotionalCalendar.titles.loyaltyRank')}
                    options={availableLoyaltyRankOptions}
                    rules={{ required: t('fieldIsRequired') }}
                    defaultValue={rank?.loyaltyRank}
                    required={true}
                    disabled={!chosenMinimalLoyaltyRankRequirement}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Input
                  size="small"
                  label={capitalize(t('marketing.promotionalCalendar.titles.prizeName'))}
                  control={control}
                  type="text"
                  name={`prizes[${ordinalIndex}].ranks[${rankIndex}].name`}
                  defaultValue={rank?.name}
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  sx={{ mb: 2 }}
                />
                <Input
                  multiline
                  size="small"
                  label={capitalize(t('description'))}
                  control={control}
                  type="text"
                  name={`prizes[${ordinalIndex}].ranks[${rankIndex}].description`}
                  defaultValue={rank?.description}
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  sx={{ mb: 2 }}
                />
                <Input
                  size="small"
                  label={capitalize(t('redirectUrl'))}
                  control={control}
                  type="url"
                  name={`prizes[${ordinalIndex}].ranks[${rankIndex}].redirectUrl`}
                  defaultValue={rank?.redirectUrl}
                  sx={{ mb: 2 }}
                  required
                  rules={{ required: t('fieldIsRequired') }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Box mb={2}>
                  <Typography fontWeight={500} variant="body1" fontSize="large" mb={1}>
                    {capitalize(t('imageUrl'))}
                  </Typography>
                  <MediaFieldsWithPreview
                    control={control}
                    size="small"
                    name={`prizes[${ordinalIndex}].ranks[${rankIndex}].images`}
                    defaultValue={rank?.images}
                    type="text"
                    required={true}
                    label={capitalize(t('addImages'))}
                    mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.Template}
                    isMultiSelect={false}
                  />
                </Box>
                <Controller
                  control={control}
                  name={`prizes[${ordinalIndex}].ranks[${rankIndex}].actionId`}
                  defaultValue={rank?.actionId}
                  render={({ value, onChange }) => (
                    <Autocomplete
                      fullWidth={true}
                      loading={isLoadingActions}
                      value={actionOptions?.find(option => option.value === value) ?? null}
                      onChange={(_event, value) => onChange(value?.value)}
                      options={actionOptions}
                      getOptionLabel={option => option.label ?? ''}
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )}
                      sx={{ mb: 2 }}
                      renderInput={params => (
                        <TextField required {...params} size="small" label={capitalize(t('actions'))} />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
            {fields && fields?.length > 1 && rankIndex !== fields?.length - 1 && (
              <Typography variant="body2" fontSize="large" fontWeight="bold" my={2} textAlign="center">
                {t('content.gameserver.tournamentconfiguration.form.other.and')}
              </Typography>
            )}
          </Box>
        );
      })}
      {canAddAdditionalRankSettings ? (
        <Button
          onClick={addRankSectionSettingsHandler}
          variant="contained"
          size="medium"
          sx={{
            mx: 'auto',
            my: 2,
          }}
          startIcon={<FontAwesomeIcon icon={faAdd} />}
        >
          {t('marketing.promotionalCalendar.titles.rank')}
        </Button>
      ) : null}
    </Box>
  );
};

export default PromotionalCalendarRankSettings;
