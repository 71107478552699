import { ComponentPropsWithoutRef, useCallback, useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMediaGalleryEntryTypeSchema, MediaGalleryEntrySchema } from '@greenisland/stores';
import { Box, Checkbox as MuiCheckbox, FormControl, FormHelperText, FormLabel } from '@mui/material';

import MediaGallery from '../../../../app/components/MediaGallery';

type Props = ComponentPropsWithoutRef<typeof MuiCheckbox> & {
  name: string;
  label?: string;
  helpText?: string;
  control: Control;
  mediaGalleryEntryType: EMediaGalleryEntryTypeSchema;
  disableFilter?: boolean;
  defaultValue?: string;
};

const MediaField = (props: Props) => {
  const { name, control, label, helpText, mediaGalleryEntryType, defaultValue, disableFilter = true } = props;
  const { t } = useTranslation();
  const {
    field: { value = defaultValue, onChange },
  } = useController({ name, control, defaultValue });

  const [pathValue, setPathValue] = useState(value ?? defaultValue);
  const [showMediaGallery, setShowMediaGallery] = useState(false);

  useEffect(() => {
    if (value !== undefined) {
      setPathValue(value);
    }
  }, [value]);

  const onSave = useCallback(
    (media: MediaGalleryEntrySchema[]) => {
      const newPathValue = media[0].path;
      onChange(newPathValue);
      setPathValue(newPathValue);
      setShowMediaGallery(false);
    },
    [onChange]
  );

  const defaultMedia = [{ path: pathValue } as MediaGalleryEntrySchema];
  return (
    <>
      <FormControl variant="standard">
        <FormLabel>{label}</FormLabel>
        <Box
          sx={{
            width: '100%',
            height: 100,
            position: 'relative',
            textAlign: 'center',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              opacity: 0.5,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: '#9ca3af1a',
              backgroundImage:
                'linear-gradient(135deg,#6b728080 10%,transparent 0,transparent 50%,#6b728080 0,#6b728080 60%,transparent 0,transparent)',
              backgroundSize: '7.07px 7.07px',
            }}
          />
          <Box
            component="img"
            src={pathValue}
            alt={t('content.mediaGallery.selectImageTitle')}
            loading="lazy"
            onClick={() => setShowMediaGallery(true)}
            title={t('content.mediaGallery.selectImageTitle')}
            sx={{
              cursor: 'pointer',
              objectFit: 'contain',
              position: 'relative',
              width: pathValue ? 'auto' : '100px',
              maxWidth: '200px',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </Box>
        {helpText ? <FormHelperText>{helpText}</FormHelperText> : null}
      </FormControl>
      <MediaGallery
        isOpen={showMediaGallery}
        onSave={onSave}
        onClose={() => setShowMediaGallery(false)}
        defaultMedia={defaultMedia}
        mediaGalleryEntryType={mediaGalleryEntryType}
        disableFilter={disableFilter}
      />
    </>
  );
};

export default MediaField;
