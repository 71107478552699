import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoyaltyDependentDecimalSchema } from '@greenisland/stores';
import { Box, InputAdornment } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';

import { useGetDepositBonusLoyaltyDependentIcon } from '../../Campaigns/Actions/Individual/Forms/helpers/DepositBonusFormContext';
import { LoyaltyDependentIconType } from './LoyaltyDependentValue';

export const getLoyaltyDependentMaxPayoutValues = (
  isLoyaltyDependent: boolean,
  data: LoyaltyDependentDecimalSchema | undefined
): LoyaltyDependentDecimalSchema | undefined => {
  if (!isLoyaltyDependent || !data) return undefined;
  const loyaltyDependentMaxPayout: LoyaltyDependentDecimalSchema = {};

  Object.keys(data).forEach(property => {
    const value = data[property as keyof LoyaltyDependentDecimalSchema];
    if (value) {
      loyaltyDependentMaxPayout[property as keyof LoyaltyDependentDecimalSchema] = parseFloat(value.toString());
    }
  });

  return loyaltyDependentMaxPayout;
};

interface Props {
  options: {
    label: string;
    name: string;
  }[];
  isRequired?: boolean;
  iconType?: LoyaltyDependentIconType;
}

const LoyaltyDependentValueDecimal = ({ options, iconType, isRequired = true }: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const icon = useGetDepositBonusLoyaltyDependentIcon(iconType);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {options?.map((option, index) => (
        <Input
          key={index}
          size="small"
          label={option.label}
          name={option.name}
          control={control}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
            inputProps: { min: 0, step: 'any' },
          }}
          required={isRequired}
          rules={isRequired ? { required: t('fieldIsRequired') } : {}}
        />
      ))}
    </Box>
  );
};

export default LoyaltyDependentValueDecimal;
