import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Loading } from '@greenisland/components';
import { actionCreators, CampaignTemplate, CampaignTemplateDetails, TemplateTypes } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import { usePermission } from '../../../../../app/hooks';
import TemplateForm from './TemplateForm';

const LegacySpecificTemplateDetails = () => {
  const dispatch = useAppDispatch();
  const { campaignId, templateType }: any = useParams();
  const navigate = useNavigate();
  const canReadSpecificTemplateDetails = usePermission(OnlineCasinoPermissions.getTemplateTypes);
  const [templateTypesState, setTemplateTypeState] = useState<{ fetching: boolean; types: TemplateTypes }>({
    fetching: true,
    types: [],
  });

  const { selectedEntity, selectedProduct } = useAppSelector(state => state.entities);
  const { data: campaign, fetching: fetchingCampaigns } = useAppSelector(state => state.campaign);
  const { data: templateContent, fetching: fetchingTemplateContent } = useAppSelector(state => state.campaignTemplate);

  const fetchCampaign = () => dispatch(actionCreators.getCampaign(campaignId));
  const fetchTemplateContent = () => dispatch(actionCreators.getCampaignTemplateType(campaignId, templateType));
  const updateCampaignTemplateType = async (template: CampaignTemplateDetails) =>
    dispatch(actionCreators.updateCampaignTemplateType(campaignId, templateType, template));
  const setCampaignTemplateType = async (template: CampaignTemplateDetails) =>
    dispatch(actionCreators.setCampaignTemplates(campaignId, template));
  const fetchTemplateTypes = async () => actionCreators.getTemplateTypes();

  useEffect(() => {
    if (canReadSpecificTemplateDetails) {
      fetchTemplateTypes().then(({ data }) => setTemplateTypeState({ fetching: false, types: data }));
      if (!campaign) fetchCampaign();
      if (templateType !== 'add') fetchTemplateContent();
    }
  }, [dispatch, canReadSpecificTemplateDetails]);

  const filterTemplateTypes = () => {
    let filteredTemplateTypes: TemplateTypes = templateTypesState.types;

    if (templateType === 'add') {
      if (campaign && campaign.templates) {
        const usedTemplatesTypes = campaign.templates.map((template: CampaignTemplate) => template.type);
        filteredTemplateTypes = templateTypesState.types.filter(
          (type: any) => usedTemplatesTypes && !usedTemplatesTypes.includes(type.name)
        );
      }
    } else {
      const usedTemplatesTypes = campaign?.templates.map((template: CampaignTemplate) => template.type);

      filteredTemplateTypes = templateTypesState.types.filter(
        (type: any) => usedTemplatesTypes && usedTemplatesTypes.includes(type.name)
      );
    }

    return filteredTemplateTypes;
  };

  const submitCampaignTemplate = (data: CampaignTemplateDetails) => {
    if (templateType === 'add') {
      setCampaignTemplateType(data).then(resp => {
        const typedResp: any = resp;
        if (typedResp && typedResp.status === 200) {
          navigate(`../campaigns/overview?campaignId=${campaignId}`);
        }
      });
    } else {
      updateCampaignTemplateType(data).then(resp => {
        const typedResp: any = resp;
        if (typedResp && typedResp.status === 200) {
          navigate(`../campaigns/overview?campaignId=${campaignId}`);
        }
      });
    }
  };

  const loading =
    templateType !== 'add'
      ? !templateContent || !campaign || templateTypesState.fetching || fetchingTemplateContent || fetchingCampaigns
      : !campaign && templateTypesState.fetching && fetchingTemplateContent && !fetchingCampaigns;

  return canReadSpecificTemplateDetails ? (
    <>
      {loading ? (
        <Loading center />
      ) : (
        <TemplateForm
          onSubmit={data => submitCampaignTemplate(data)}
          templateTypes={filterTemplateTypes()}
          templateData={templateType !== 'add' ? templateContent : undefined}
          returnLink={`/${selectedEntity}/${selectedProduct}/marketing/campaigns/overview/${campaignId}`}
        />
      )}
    </>
  ) : null;
};

export default memo(LegacySpecificTemplateDetails);
