import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, CheckData, Link, ReactTableGI } from '@greenisland/components';
import {
  GetPlayersRevenueContributorsParams,
  useGetPlayersRevenueContributors,
  useSetBlacklistedHook,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { endOfDay, getUnixTime, startOfDay } from 'date-fns';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  calculateBOEndDate,
  setInvisible,
  transformBooleanYesOrNull,
  transformCurrency,
} from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../Lookup/LookupPermissions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 1fr)',
      gridGap: '15px 15px',
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  })
);

const RevenueContributors = () => {
  const canReadRevenueContribution = usePermission(OnlineCasinoPermissions.getPlayersRevenueContributors);
  return canReadRevenueContribution ? <RevenueContributorsInnerChild /> : null;
};

const RevenueContributorsInnerChild = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<GetPlayersRevenueContributorsParams | undefined>(undefined);
  const canLooupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canAddToBonusBlacklist = usePermission(OnlineCasinoPermissions.setBlacklisted);
  const canReadRevenueContribution = usePermission(OnlineCasinoPermissions.getPlayersRevenueContributors);
  const canAnswerBonusRequests = usePermission(OnlineCasinoPermissions.awardPlayersRevenueContributorsBonusRequest);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = Number(searchParams.get('startdate'));
  const endDate = Number(searchParams.get('enddate'));

  const blacklistPlayer = useSetBlacklistedHook();

  //const { data: revenueContributors, fetching } = useAppSelector(state => state.reportingPlayerRevenueContributors);
  const { data: revenueContributors, isLoading } = useGetPlayersRevenueContributors(
    {
      ...searchQuery,
    },
    {
      query: {
        enabled: canReadRevenueContribution,
      },
    }
  );
  const fetchPlayerRevenueContributors = async (startDate: number, endDate: number) =>
    setSearchQuery({ startdate: startDate, enddate: endDate });

  const setBlacklisted = async (userId: string) => {
    blacklistPlayer(userId, { blacklisted: true });
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchPlayerRevenueContributors(startDate, calculateBOEndDate(endDate));
    } else {
      const startDate = getUnixTime(startOfDay(new Date()));
      const endDate = getUnixTime(endOfDay(new Date()));
      replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
    }
  }, [searchParams]);

  const mutatedData = () => {
    if (!revenueContributors || revenueContributors.length === 0) return undefined;
    const keys = Object.keys(revenueContributors[0]);

    const headers: any = keys.map(key => {
      switch (key) {
        case 'contribution':
          return transformCurrency(key, t);
        case 'userId':
          return setInvisible(key);
        case 'isBonusBlacklisted':
          return transformBooleanYesOrNull(key, t);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    headers.push(
      { id: 'addBonusBlacklist', accessor: 'addBonusBlacklist', disableFilters: true, disableSortBy: true },
      { id: 'answerBonusRequest', accessor: 'answerBonusRequest', disableFilters: true, disableSortBy: true }
    );

    const rows: any = revenueContributors.map(item => {
      return {
        ...item,
        username: canLooupUser ? (
          <Link to={`../../../users/${item.userId}/details`}>{item.username}</Link>
        ) : (
          <span>{item.username}</span>
        ),
        addBonusBlacklist:
          !item.isBonusBlacklisted && canAddToBonusBlacklist ? (
            <Button onClick={() => setBlacklisted(item.userId)} size="medium" variant="outlined">
              {t('addBonusBlacklist')}
            </Button>
          ) : null,
        answerBonusRequest: canAnswerBonusRequests ? (
          <Link to={`${item.userId}/bonus-requests`}>{t('answerBonusRequest')}</Link>
        ) : undefined,
      };
    });

    return { headers, rows };
  };

  return (
    <div className={classes.container}>
      <DateRangePicker
        unixStartDate={Number(searchParams.get('startdate')) * 1000}
        unixEndDate={Number(searchParams.get('enddate')) * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <Card>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={isLoading}
            checkProperties={['headers', 'rows']}
            render={({ data }) => (
              <ReactTableGI columns={data.headers} data={data.rows} pagination={data.rows.length > 5} sortable />
            )}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(RevenueContributors);
