import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetPromotionalCalendarById, useUpdatePromotionalCalendar } from '@greenisland/stores';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Stack } from '@mui/material';

import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { LoyaltyRankOptionsStatus } from '../../constants';
import {
  CalendarPromotionDialogSchema,
  generatePromotionCalendarFormPayload,
  PromotionalCalendarActionMode,
  transformPromotionalCalendarDefaultEditSettings,
  useGetLoyaltyRankOptions,
  usePromotionalCalendarFormHandlers,
} from './helpers';
import PromotionalCalendarGeneralSettings from './PromotionalCalendarGeneralSettings';
import PromotionalCalendarPrizeSettings from './PromotionalCalendarPrizeSettings';

const EditPromotionalCalendar = () => {
  const { t } = useTranslation();
  const { promotionalCalendarId } = useParams();

  const methods = useForm<CalendarPromotionDialogSchema>({
    shouldFocusError: true,
    shouldUnregister: false,
    mode: 'onChange',
  });

  const { handleSubmit, reset, watch } = methods;

  const chosenMinimalLoyaltyRankRequirement = watch('minimalLoyaltyRankRequirement');

  const availableLoyaltyRankForPrizeOptions = useGetLoyaltyRankOptions(
    LoyaltyRankOptionsStatus.HIDE_INACTIVE,
    chosenMinimalLoyaltyRankRequirement
  );

  const { onSuccessHandler, onErrorHandler } = usePromotionalCalendarFormHandlers(reset);

  const {
    data: promotionalCalendar,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetPromotionalCalendarById(Number(promotionalCalendarId), {
    query: {
      enabled: !!promotionalCalendarId,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        const transformedData = transformPromotionalCalendarDefaultEditSettings(data);

        if (transformedData) {
          reset(transformedData);
        }
      },
    },
  });

  const { mutate: updatePromotionalCalendar, isLoading: isUpdatePromotionalCalendarLoading } =
    useUpdatePromotionalCalendar({
      mutation: {
        onSuccess: onSuccessHandler,
        onError: error => onErrorHandler(error),
      },
    });

  const onSubmit = (data: CalendarPromotionDialogSchema) => {
    const payload = generatePromotionCalendarFormPayload(PromotionalCalendarActionMode.EDIT, data);

    updatePromotionalCalendar({ calendarId: Number(promotionalCalendarId), data: payload });
  };

  if (isLoading || isFetching) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <ErrorState
        errorMessage={t('marketing.promotionalCalendar.permissions.fetchError')}
        retryAction={() => refetch()}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <PromotionalCalendarGeneralSettings selectedPromotionalCalendar={promotionalCalendar} />
          <PromotionalCalendarPrizeSettings
            selectedPromotionalCalendar={promotionalCalendar}
            availableLoyaltyRankOptions={availableLoyaltyRankForPrizeOptions}
          />
        </Stack>
        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            sx={{ mt: 4, px: 6, py: 2 }}
            color="primary"
            type="submit"
            variant="contained"
            loading={isUpdatePromotionalCalendarLoading}
          >
            {t('save')}
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default EditPromotionalCalendar;
