import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetGamesToday } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { GridColumns } from '@mui/x-data-grid-pro';

import { ErrorCard, NoData, StyledDataGrid } from '@greenisland-common/components/atoms';

import { formatCurrency, formatPercentage } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const Today = () => {
  const { t } = useTranslation();
  const canReadGamesToday = usePermission(OnlineCasinoPermissions.getGamesToday);
  const { data } = useGetGamesToday({ query: { enabled: canReadGamesToday } });

  const rows = useMemo(() => data?.map(row => ({ ...row, id: row.externalGameId })), [data]);

  const headers: GridColumns = useMemo(
    () => [
      { headerName: t('nameOfGame'), field: 'nameOfGame', flex: 3 },
      { headerName: t('numberOfGames'), field: 'numberOfGames', flex: 1 },
      { headerName: t('cashBets'), field: 'cashBets', valueFormatter: ({ value }) => formatCurrency(value), flex: 1 },
      { headerName: t('cashWins'), field: 'cashWins', valueFormatter: ({ value }) => formatCurrency(value), flex: 1 },
      {
        headerName: t('cashPayout'),
        field: 'cashPayout',
        valueFormatter: ({ value }) => `${formatPercentage(value)}%`,
        flex: 1,
      },
      {
        headerName: t('cashRevenue'),
        field: 'cashRevenue',
        valueFormatter: ({ value }) => formatCurrency(value),
        flex: 1,
      },
      { headerName: t('externalGameId'), field: 'externalGameId', flex: 1 },
    ],
    [t]
  );

  if (!canReadGamesToday) return <ErrorCard severity="info">{t('common.noPermissionToSeeThePage')}</ErrorCard>;
  if (!rows?.length) return <NoData />;

  return <StyledDataGrid autoHeight density="compact" columns={headers} rows={rows} />;
};

export default Today;
