import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EGameLibraryModelSchema,
  EMediaGalleryEntryTypeSchema,
  GameStudioSchema,
  getGetGameStudiosQueryKey,
  useAddGameStudio,
  useUpdateGameStudio,
} from '@greenisland/stores';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import CheckboxField from '@greenisland-common/components/molecules/CheckboxField';
import Input from '@greenisland-common/components/molecules/Input';

import SyncGameLibraryButton from '../../Components/Forms/SyncGameLibraryButton';
import TranslationsDictionaryFields, {
  TotalFieldArrayDataItem,
} from '../../Components/Forms/TranslationsDictionaryFields';
import processFieldArray from '../../Components/utils/processFieldArray';
import GameStudioImages from './GameStudioImages';

interface ExtendedGameStudioSchema extends GameStudioSchema {
  totalFieldArray?: TotalFieldArrayDataItem[];
}
interface Props {
  open: boolean;
  onClose: () => void;
  studio: GameStudioSchema | undefined;
}

const DEFAULT_CATEGORY_FIELDS: GameStudioSchema = {
  gameStudioId: '',
  enabled: false,
  isLive: false,
  logoUrl: '',
  imageUrl: '',
  gameStudioName: '',
  urlName: '',
  imageUrlTranslations: [],
  description: [],
};

const GameStudioDialog = ({ open, onClose, studio }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<GameStudioSchema>({ defaultValues: studio ?? DEFAULT_CATEGORY_FIELDS, mode: 'onChange' });
  const { handleSubmit, errors, reset, control } = methods;

  const addMutation = useAddGameStudio({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('content.games.studios.addSuccess'), { variant: 'success' });
        queryClient.invalidateQueries(getGetGameStudiosQueryKey());
        onClose();
      },
    },
  });

  const updateMutation = useUpdateGameStudio({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('content.games.studios.updateSuccess'), { variant: 'success' });
        queryClient.invalidateQueries(getGetGameStudiosQueryKey());
        onClose();
      },
    },
  });

  useEffect(() => {
    reset(studio ? studio : DEFAULT_CATEGORY_FIELDS);
  }, [reset, studio]);

  const onSubmit = handleSubmit((data: ExtendedGameStudioSchema) => {
    if (data.totalFieldArray) {
      const { descriptions, imageUrlTranslations } = processFieldArray(data.totalFieldArray);
      data.description = descriptions;
      data.imageUrlTranslations = imageUrlTranslations;
      delete data.totalFieldArray;
    }
    if (studio?.gameStudioId) {
      updateMutation.mutate({ gameStudioId: studio.gameStudioId, data });
    } else {
      addMutation.mutate({ data });
    }
    onClose();
  });

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <Box component="form" onSubmit={onSubmit}>
          <DialogTitle>
            {studio?.gameStudioId?.length ? t('content.games.studios.edit') : t('content.games.studios.add')}
            {studio?.conflictGamelib && (
              <Typography color="error">{t('conflict.gameLibId', { gameLibId: studio.gameLibId })}</Typography>
            )}
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <Box>
                <SyncGameLibraryButton
                  gameLibEntity={studio}
                  gameLibModel={EGameLibraryModelSchema.GameStudio}
                  invalidatedQueryKey={getGetGameStudiosQueryKey()}
                  onClose={onClose}
                />
              </Box>
              <Box>
                <CheckboxField name="enabled" label={t('content.games.studios.enabled')} control={control} />
                <CheckboxField name="isLive" label={t('content.games.studios.isLive')} control={control} />
              </Box>
              <Input
                required
                name="gameStudioName"
                label={t('content.games.studios.gameStudioName')}
                error={Boolean(errors.gameStudioName)}
                helperText={errors.gameStudioName?.message}
                rules={{
                  required: { value: true, message: t('content.games.studios.form.input.required') },
                  minLength: { value: 1, message: t('content.games.studios.form.gameStudioName.minLength') },
                }}
              />
              <Input
                required
                name="urlName"
                label={t('content.games.studios.urlName')}
                error={Boolean(errors.urlName)}
                helperText={errors.urlName?.message}
                rules={{
                  required: { value: true, message: t('content.games.studios.form.input.required') },
                  minLength: { value: 1, message: t('content.games.studios.form.urlName.minLength') },
                }}
              />
              <TranslationsDictionaryFields
                nameDescription="description"
                nameImageUrl="imageUrlTranslations"
                mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.GameStudioImage}
                control={control}
              />
              <GameStudioImages />
              {addMutation.isError ? <Alert severity="error">{t('content.games.studios.addError')}</Alert> : null}
              {updateMutation.isError ? <Alert severity="error">{t('content.games.studios.updateError')}</Alert> : null}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="text" onClick={onClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button variant="contained" type="submit">
              {t('save')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </FormProvider>
  );
};

export default GameStudioDialog;
