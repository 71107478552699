import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AgentTaskMetaDataSchema, AgentTaskSchema, EAgentTaskTypeSchema } from '@greenisland/stores';
import { Box, capitalize, Link, Stack, Typography } from '@mui/material';

import useFeatureFlags from '@greenisland-common/hooks/useFeatureFlags';

import { prettyPrintJson } from '@greenisland-common/helpers';

import CriticalFieldMismatchDetail from './AgentTaskDetails/CriticalFieldMismatchDetail';
import KYCRiskDetectedDetail from './AgentTaskDetails/KYCRiskDetectedDetail';

interface Props {
  data: AgentTaskSchema;
  showCustomMetadata?: boolean;
  children?: JSX.Element;
}

const AgentTaskDetail = ({ data, showCustomMetadata = false, children }: Props) => {
  const { t } = useTranslation();
  const isKycEnabled = useFeatureFlags('isKycEnabled');

  const { type, metaData, userId, description } = data;

  const getDetailMetadata = () => {
    if (type === EAgentTaskTypeSchema.KYCRiskDetected) {
      if (isKycEnabled) return <KYCRiskDetectedDetail metaData={metaData as AgentTaskMetaDataSchema} />;
      else return t('kyc.featureDisabled');
    }
    if (type === EAgentTaskTypeSchema.BGCCriticalFieldsMismatch) {
      return <CriticalFieldMismatchDetail metaData={metaData as AgentTaskMetaDataSchema} />;
    }
    if (type === EAgentTaskTypeSchema.KSAAddictionPreventionPlayingBreakCanBeCancelled) {
      return (
        <Stack my={2}>
          <Box display="flex" gap={1} mb={1}>
            <Typography variant="body2" fontWeight={700}>{`${capitalize(t('userId'))}:`}</Typography>
            <Link href={`../users/${userId}/details`} target="_blank">
              {userId}
            </Link>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="body2" fontWeight={700}>{`${capitalize(t('description'))}:`}</Typography>
            <Typography
              variant="body2"
              pr={10}
              dangerouslySetInnerHTML={{
                __html: description.replace(/\*(.*?)\*/g, '<strong style="font-weight: 600">$1</strong>'),
              }}
            />
          </Box>
        </Stack>
      );
    }
    return (
      <pre style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: prettyPrintJson(metaData, { linkUrls: true }) }} />
    );
  };

  return (
    <Box sx={{ paddingY: 1, paddingLeft: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, marginLeft: 3 }}>{children}</Box>
      {metaData || showCustomMetadata ? (
        <>
          <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
            {t('metadata')}:
          </Typography>
          {getDetailMetadata()}
        </>
      ) : (
        <Typography sx={{ marginLeft: 3 }} variant="caption">
          ({t('noMetadata')})
        </Typography>
      )}
    </Box>
  );
};

export default memo(AgentTaskDetail);
