import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faLock, faLockOpen, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigurationsInner, useGetConfigurations } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, Card, IconButton, TextField, Tooltip } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../app/hooks';
import EditConfigurationDialog from './EditConfigurationDialog';

const Variables = () => {
  const { t } = useTranslation();
  const canReadConfigurations = usePermission(OnlineCasinoPermissions.getConfigurations);
  const [filter, setFilter] = useState<string>('');
  const [dialog, setDialog] = useState<{ isOpen: boolean; configuration?: ConfigurationsInner }>({
    isOpen: false,
    configuration: undefined,
  });

  const { data: configurations, isLoading } = useGetConfigurations({ query: { enabled: canReadConfigurations } });

  const headers: GridColumns = [
    { headerName: capitalize(t('key')), field: 'key', flex: 0.5 },
    {
      headerName: capitalize(t('description')),
      field: 'description',
      flex: 1,
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>
        </Tooltip>
      ),
    },
    { headerName: capitalize(t('type')), field: 'type', flex: 0.1 },
    { headerName: capitalize(t('value')), field: 'value', flex: 0.2 },
    { headerName: capitalize(t('isLocked')), field: 'isLocked', flex: 0.1, renderCell: ({ value }) => value },
    { headerName: '', field: 'edit', flex: 0.1, renderCell: ({ value }) => value },
  ];

  const rows =
    configurations
      ?.filter(({ key, description }) => {
        return (
          key.toLowerCase().includes(filter.toLowerCase()) || description.toLowerCase().includes(filter.toLowerCase())
        );
      })
      .map((item, index) => {
        return {
          ...item,
          id: index,
          isLocked: item.isLocked ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faLockOpen} />,
          edit: (
            <Tooltip title={t('editConfiguration')}>
              <IconButton
                size="small"
                disabled={item.isLocked}
                onClick={() => setDialog({ configuration: item, isOpen: true })}
              >
                <FontAwesomeIcon icon={faPen} />
              </IconButton>
            </Tooltip>
          ),
        };
      }) ?? [];

  return canReadConfigurations ? (
    <>
      <TextField
        sx={{ marginBottom: 1 }}
        label={capitalize(t('search'))}
        variant="outlined"
        size="small"
        value={filter}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)}
      />
      <Card sx={{ padding: 0 }}>
        <StyledDataGrid loading={isLoading} density="compact" autoHeight columns={headers} rows={rows} />
      </Card>
      {dialog.configuration && (
        <EditConfigurationDialog
          configuration={dialog.configuration}
          isOpen={dialog.isOpen}
          closeDialog={() => setDialog({ isOpen: false })}
        />
      )}
    </>
  ) : null;
};

export default memo(Variables);
