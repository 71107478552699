import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, Loading } from '@greenisland/components';
import {
  ProviderConfigurationModel,
  useGetProviderConfiguration,
  useSetProviderConfiguration,
} from '@greenisland-store/provider';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import IPWhitelistDataGrid from './components/IPWhitelistDataGrid';
import StringConfigurationsDataGrid from './components/StringConfigurationsDataGrid';

const ProviderConfiguration = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [providerConfiguration, setProviderConfiguration] = useState<ProviderConfigurationModel>();
  const { providerIntegrationCode = '' } = useParams();

  const { data, isLoading, isError, isRefetching, refetch } = useGetProviderConfiguration(providerIntegrationCode, {
    query: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: data => setProviderConfiguration(data),
    },
  });

  const { mutate, ...mutation } = useSetProviderConfiguration({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('providerService.providerConfiguration.setProviderConfigurationSuccess'), {
          variant: 'success',
        });
        refetch();
      },
      onError: () => {
        enqueueSnackbar(t('providerService.providerConfiguration.setProviderConfigurationError'), {
          variant: 'error',
        });
      },
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !providerConfiguration) {
    return (
      <>
        <Typography color="error" mb={2}>
          {t('providerService.providerConfiguration.getProviderConfigurationError')}
        </Typography>
        <Button variant="outlined" size="medium" loadingState={isLoading} onClick={() => refetch()}>
          {t('providerService.providerConfiguration.retry')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Typography variant="subtitle1" mb={1}>
        {providerIntegrationCode}
      </Typography>
      <Typography variant="h6" mb={1}>
        {t('providerService.providerConfiguration.ipWhitelist')}
      </Typography>
      <IPWhitelistDataGrid
        ipWhitelist={providerConfiguration?.ipWhiteList}
        setIpWhiteList={ipWhiteList => setProviderConfiguration({ ...providerConfiguration, ipWhiteList })}
      />
      <Button
        size="medium"
        variant="outlined"
        onClick={() =>
          setProviderConfiguration({
            ...providerConfiguration,
            ipWhiteList: [...(providerConfiguration?.ipWhiteList ?? []), ''],
          })
        }
      >
        {t('providerService.providerConfiguration.addIPWhitelist')}
      </Button>
      <Typography variant="h6" mt={2} mb={1}>
        {t('providerService.providerConfiguration.stringConfigurations')}
      </Typography>
      <StringConfigurationsDataGrid
        stringConfigurations={providerConfiguration?.stringConfigurations}
        setStringConfigurations={stringConfigurations =>
          setProviderConfiguration({ ...providerConfiguration, stringConfigurations })
        }
      />
      <Box display="flex" flexDirection="row" gap={1}>
        <Button
          size="medium"
          loadingState={mutation.isLoading}
          onClick={() => {
            // The callbacks are needed to give the data-grid time to update the state for unblurred cells
            setTimeout(() => {
              setProviderConfiguration(data => {
                if (data) mutate({ providerIntegrationCode, data });
                return data;
              });
            }, 100);
          }}
        >
          {t('providerService.providerConfiguration.save')}
        </Button>
        <Button
          size="medium"
          variant="outlined"
          loadingState={isRefetching}
          onClick={() => {
            setProviderConfiguration(data);
            refetch();
          }}
        >
          {t('providerService.providerConfiguration.discard')}
        </Button>
      </Box>
    </>
  );
};

export default ProviderConfiguration;
