import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, CheckData, Link, Loading, ReactTableGI } from '@greenisland/components';
import { actionCreators, Transaction } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format, fromUnixTime } from 'date-fns';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { formatCurrency, formatDecimal } from '../../../../../../../app/helpers/transformFunctions';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridGap: '15px 15px',
    marginBottom: 15,
  },
  font: {
    fontFamily: theme.typography.fontFamily,
  },
  h5: {
    margin: 0,
    fontSize: '14px',
    fontWeight: 500,
    color: 'rgba(73, 80, 87 ,0.5)',
    textTransform: 'uppercase',
    padding: '15px !important',
  },
  paddingTop: {
    paddingTop: '15px',
  },
}));

interface AreaChartData {
  name: string;
  balance: number;
  promoBalance: number;
  label: string;
}

const SessionStatistics = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { data: transactions, fetching: loading } = useAppSelector(state => state.userTransactions);

  const fetchTransactions: (
    userId: string,
    startDate: number,
    endDate: number,
    includeGames: boolean,
    includeSports: boolean
  ) => void = async (
    userId: string,
    startDate: number,
    endDate: number,
    includeGames: boolean,
    includeSports: boolean
  ) => await dispatch(actionCreators.getUserTransactions(userId, startDate, endDate, includeGames, includeSports));

  const headers = [
    { id: 'title', accessor: 'title', disableFilters: true, disableSortBy: true },
    { id: 'value', accessor: 'value', disableFilters: true, disableSortBy: true },
  ];

  const headersStakes = [
    { Header: t('stake'), accessor: 'stake' },
    { Header: t('count'), accessor: 'count' },
  ];

  // TODO: refactor code, too many undefined variables
  const constructTransactionsData = (data: Array<Transaction>): JSX.Element | null => {
    const tempSessions: Array<Array<Transaction>> = [];
    const rowData: Array<Array<{ title: string; value: string }>> = [];
    const tempCounts: Array<Array<{ stake: number; count: number }>> = [];

    let sessionToReturn: Array<Transaction> = [];
    data
      .sort((a, b) => a.timestamp - b.timestamp)
      .forEach((transaction: Transaction, index: number) => {
        if (transactions && transactions.transactionsList && index === transactions.transactionsList.length - 1) {
          sessionToReturn.push(transaction);
          tempSessions.push(sessionToReturn);
        } else {
          if (
            transaction.label.toLowerCase() === 'wallet' &&
            transaction.type.toLowerCase() === 'deposit' &&
            transaction.balance - transaction.amount < 1 &&
            index !== 0 &&
            transactions &&
            transactions.transactionsList &&
            index < transactions.transactionsList.length - 1
          ) {
            tempSessions.push(sessionToReturn);
            sessionToReturn = [];
          }

          sessionToReturn.push(transaction);
        }
      });

    let tempRowData: Array<{ title: string; value: string }> = [];

    tempSessions.forEach((session: Array<Transaction>) => {
      const timestamp1 = session[session.length - 1].timestamp;
      const timestamp2 = session[0].timestamp;

      if (timestamp1 && timestamp2) {
        tempRowData.push({
          title: t('length'),
          value: `${Math.round((timestamp2 - timestamp1) / 60)} ${t('minutes')}`,
        });
      }

      let totalBetAmount = 0;
      let totalWinAmount = 0;

      session.forEach((transaction: Transaction) => {
        if (transaction.type.toLowerCase() === 'bet') {
          totalBetAmount += transaction.amount;
        } else if (transaction.type.toLowerCase() === 'win') {
          totalWinAmount += transaction.amount;
        }
      });

      tempRowData.push({
        title: t('payoutGames'),
        value: `${formatDecimal(Math.abs((totalWinAmount / totalBetAmount) * 100), true)} % (${formatDecimal(
          totalWinAmount,
          true
        )} / ${formatDecimal(totalBetAmount, true)})`,
      });

      rowData.push(tempRowData);

      tempRowData = [];
    });

    let countToReturn: Array<{
      stake: number;
      count: number;
    }> = [];
    tempSessions.forEach((session: Array<Transaction>) => {
      session
        .filter((transaction: Transaction) => transaction.type.toLowerCase() === 'bet')
        .forEach((transaction: Transaction) => {
          const index = countToReturn.findIndex(count => count.stake === transaction.amount);
          if (index < 0) {
            countToReturn.push({
              stake: transaction.amount,
              count: 1,
            });
          } else {
            countToReturn[index].count++;
          }
        });

      tempCounts.push(countToReturn);
      countToReturn = [];
    });

    const dataToRender = (session: Array<Transaction>): Array<AreaChartData> => {
      return session.map((transaction: Transaction) => {
        const chartData: AreaChartData = {
          name: '',
          balance: 0,
          promoBalance: 0,
          label: '',
        };
        chartData.name = `${format(fromUnixTime(transaction.timestamp), 'dd/MM/yyyy HH:mm:ss')}`;
        chartData.balance = transaction.balance;
        chartData.promoBalance = transaction.promoBalance;
        chartData.label = transaction.label;
        return chartData;
      });
    };

    const CustomizedDot = (props: any) => {
      const { cx, cy, payload } = props;

      return (
        <circle
          cx={cx}
          cy={cy}
          r="3"
          stroke={payload.label === 'Wallet' ? 'darkcyan' : theme.palette.primary.main}
          strokeWidth="1"
          fill={payload.label === 'Wallet' ? 'darkcyan' : theme.palette.primary.main}
          className="recharts-dot recharts-line-dot"
        ></circle>
      );
    };

    const CustomizedActiveDot = (props: any) => {
      const { cx, cy, payload } = props;

      return (
        <circle
          cx={cx}
          cy={cy}
          r="4"
          stroke={'#fff'}
          strokeWidth="2"
          fill={payload.label === 'Wallet' ? 'darkcyan' : theme.palette.primary.main}
          className="recharts-dot"
        ></circle>
      );
    };

    const element: JSX.Element = (
      <>
        {tempCounts.map((counts: Array<{ stake: number; count: number }>, index: number) => (
          <Card key={`sessionStatistics-${index}`}>
            <CardHeader>
              <h5 className={classes.h5}>{`${t('session')} ${index + 1}`} </h5>
            </CardHeader>
            <CardBody className={classes.font}>
              <ReactTableGI columns={headers} data={rowData[index]} noTableHeader rowColTable />
              <CheckData
                shouldRenderFallback={false}
                data={counts}
                render={({ data }) => <ReactTableGI columns={headersStakes} data={data} sortable />}
              />
              <ResponsiveContainer className={classes.paddingTop} aspect={32 / 9}>
                <LineChart data={dataToRender(tempSessions[index])}>
                  <CartesianGrid strokeDasharray="5 5" />
                  <XAxis height={50} dataKey="name" />
                  <YAxis />
                  <Tooltip
                    formatter={(value: any) => {
                      if (typeof value === 'number') return formatCurrency(value);
                      return value;
                    }}
                  />
                  <Legend />
                  <Line
                    type="linear"
                    dataKey="promoBalance"
                    stroke={theme.palette.secondary.main}
                    dot={{ fill: theme.palette.secondary.main }}
                  />
                  <Line
                    type="linear"
                    dataKey="balance"
                    stroke={theme.palette.primary.main}
                    dot={<CustomizedDot />}
                    activeDot={<CustomizedActiveDot />}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        ))}
      </>
    );

    return element;
  };

  const urlParams: any = params;
  const search = searchParams;
  const query = new URLSearchParams(search);

  const queryStartDate = query.get('startDate');
  const queryEndDate = query.get('endDate');
  const queryIncludeGames = query.get('includeGames');
  const queryIncludeSports = query.get('includeSports');

  const today = new Date();
  const defaultStartDate = Math.round(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0).getTime() / 1000
  );
  const defaultEndDate = Math.round(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59).getTime() / 1000
  );

  const queryParams = {
    startDate: queryStartDate ? parseInt(queryStartDate, 10) : defaultStartDate,
    endDate: queryEndDate ? parseInt(queryEndDate, 10) : defaultEndDate,
    includeGames: queryIncludeGames ? queryIncludeGames.toLowerCase() === 'true' : true,
    includeSports: queryIncludeSports ? queryIncludeSports.toLocaleLowerCase() === 'true' : true,
  };

  useEffect(() => {
    // TODO add proper url
    navigate('', {
      replace: true,
      state: {
        search: `?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&includeGames=${queryParams.includeGames}&includeSports=${queryParams.includeSports}`,
      },
    });

    fetchTransactions(
      urlParams.userId,
      queryParams.startDate,
      queryParams.endDate,
      queryParams.includeGames,
      queryParams.includeSports
    );
  }, []);

  return (
    <div className={classes.container}>
      <Link
        to={`../transactions?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&includeGames=${queryParams.includeGames}&includeSports=${queryParams.includeSports}`}
      >
        {t('backToTransactions')}
      </Link>
      <Card>
        <CardBody>
          {loading ? (
            <Loading center />
          ) : (
            <CheckData
              fallbackText={t('noData')}
              data={transactions?.transactionsList}
              render={({ data }) => constructTransactionsData(data)}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(SessionStatistics);
