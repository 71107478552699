import { PromotionalCalendarSchema, PromotionalCalendarUpdateSchema } from '@greenisland/stores';
import { getUnixTime } from 'date-fns';

import { convertToMediaImageSchema } from '@greenisland-common/helpers';

import {
  CalendarPromotionDialogSchema,
  PromotionalCalendarActionMode,
  PromotionalCalendarPrize,
} from './promotionalCalendarFormInterfaces';

const groupPromotionalCalendarByOrdinal = (payload: PromotionalCalendarSchema) => {
  return (payload?.prizes ?? [])?.reduce((acc, prize) => {
    const { id, dayOrdinal, activeTimestamp, endTimestamp, ...rest } = prize;

    if (!acc[dayOrdinal]) {
      acc[dayOrdinal] = {
        dayOrdinal,
        activeTimestamp,
        activeDate: new Date(activeTimestamp * 1000),
        endTimestamp,
        endDate: new Date(endTimestamp * 1000),
        ranks: [],
      };
    }

    const rank = {
      ...rest,
      id,
      images: convertToMediaImageSchema(rest?.images) || [],
    };

    acc[dayOrdinal]?.ranks?.push(rank);

    return acc;
  }, {} as { [key: number]: PromotionalCalendarPrize });
};

const transformPromotionalCalendarDefaultEditSettings = (
  payload?: PromotionalCalendarSchema
): CalendarPromotionDialogSchema | null => {
  if (!payload) {
    return null;
  }

  const groupedPrizes = groupPromotionalCalendarByOrdinal(payload);

  return {
    name: payload?.name,
    calendarType: payload?.calendarType,
    active: payload?.active,
    minimalLoyaltyRankRequirement: payload?.minimalLoyaltyRankRequirement,
    prizes: Object.values(groupedPrizes),
  };
};

const generatePromotionalCalendarPrizeFormPayload = (rawPrizesData: PromotionalCalendarPrize[]) => {
  return rawPrizesData?.flatMap(prize =>
    prize?.ranks?.map(rank => ({
      id: rank?.id,
      name: rank?.name,
      description: rank?.description,
      actionId: rank?.actionId,
      activeTimestamp: getUnixTime(prize?.activeDate || 0),
      endTimestamp: getUnixTime(prize?.endDate || 0),
      loyaltyRank: rank?.loyaltyRank,
      redirectUrl: rank?.redirectUrl,
      dayOrdinal: prize?.dayOrdinal,
      imageIds: rank?.images?.map(image => image?.id),
    }))
  );
};

const generatePromotionCalendarFormPayload = (
  mode: PromotionalCalendarActionMode,
  rawData: CalendarPromotionDialogSchema
): PromotionalCalendarUpdateSchema => {
  const payload: PromotionalCalendarUpdateSchema = {
    name: rawData?.name,
    calendarType: rawData?.calendarType,
    active: rawData?.active,
    minimalLoyaltyRankRequirement: rawData?.minimalLoyaltyRankRequirement,
  };

  const prizes = generatePromotionalCalendarPrizeFormPayload(rawData?.prizes);

  if (mode === PromotionalCalendarActionMode.CREATE) {
    payload.prizesToAdd = prizes;
  } else {
    payload.prizesToAdd = prizes?.filter(p => !p?.id);
    payload.prizesToUpdate = prizes?.filter(p => !!p?.id);
  }

  return payload;
};

export {
  generatePromotionCalendarFormPayload,
  transformPromotionalCalendarDefaultEditSettings,
  groupPromotionalCalendarByOrdinal,
};
