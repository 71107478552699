import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetUserRegistration200 } from '@greenisland/stores';
import { Card, CardContent, CardHeader, CircularProgress, Divider } from '@mui/material';

import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import RegistrationSummaryList from './RegistrationSummaryList';
import RegistrationSummaryListKsa from './RegistrationSummaryListKsa';

interface Props {
  registration?: GetUserRegistration200;
  isLoading: boolean;
}

const RegistrationSummary = ({ registration, isLoading }: Props) => {
  const { t } = useTranslation();

  const summary = useMemo(() => {
    if (registration) {
      if (registration.regulator === 'BGC') {
        return <RegistrationSummaryList registration={registration.registration} />;
      }
      if (registration.regulator === 'KSA') {
        return <RegistrationSummaryListKsa registration={registration} />;
      }
      return <ErrorState />;
    }
    return <ErrorState />;
  }, [registration]);

  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardHeader title={t('tasks.verifications.registration')} />
      <Divider />
      <CardContent>{isLoading ? <CircularProgress /> : summary}</CardContent>
    </Card>
  );
};

export default RegistrationSummary;
