import React, { memo } from 'react';

import CasinoRaces from './Components/CasinoRaces';

const GroupedActions = () => {
  return (
    <div>
      <CasinoRaces />
    </div>
  );
};

export default memo(GroupedActions);
