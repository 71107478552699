import { useTranslation } from 'react-i18next';
import { AgentTaskBadRequestContentSchema, EAgentTaskBadRequestTypeSchema, ErrorSchema } from '@greenisland/stores';
import { useSnackbar } from 'notistack';

import { AGENT_TASK_ERROR_REASONS, RESOLUTION_REASON_MAX_LENGTH } from '../Constants';

export const useAgentTaskErrorHandling = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return (error: ErrorSchema | AgentTaskBadRequestContentSchema | null) => {
    const defaultError = 'badRequestType.general';
    if (error && typeof error === 'object' && 'type' in error && error.type) {
      const errorReason = error.type as EAgentTaskBadRequestTypeSchema;
      const agentTaskError =
        errorReason && AGENT_TASK_ERROR_REASONS[errorReason]
          ? t(AGENT_TASK_ERROR_REASONS[errorReason], { max: RESOLUTION_REASON_MAX_LENGTH })
          : t(defaultError);
      enqueueSnackbar(agentTaskError, { variant: 'error' });
      return;
    }
    enqueueSnackbar(defaultError, { variant: 'error' });
  };
};
