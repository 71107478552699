import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMonitorStatusSchema, useGetIndividuals } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, capitalize, MenuItem, Select } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, Link, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../app/hooks';

const KYCRisks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [status, setStatus] = useState<EMonitorStatusSchema | 'All'>(EMonitorStatusSchema.Open);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageTokens, setPageTokens] = useState<(string | undefined)[]>([undefined]);

  const canViewIndividuals = usePermission(OnlineCasinoPermissions.getIndividuals);
  const canViewDetails = usePermission(OnlineCasinoPermissions.getMatches);

  useEffect(() => {
    setPage(0);
    setPageTokens([undefined]);
  }, [status]);

  const { data, isLoading, isError } = useGetIndividuals(
    {
      pageSize,
      ...(status !== 'All' && { status }),
      ...(pageTokens[page] && { nextToken: pageTokens[page] }),
    },
    { query: { enabled: canViewIndividuals } }
  );

  const handlePageChange = (newPage: number) => {
    if (newPage > page && newPage === pageTokens.length) {
      pageTokens.push(data?.nextToken);
      setPageTokens([...pageTokens]);
    }
    setPage(newPage);
  };

  const headers: GridColumns = [
    {
      headerName: capitalize(t('createdOn')),
      field: 'createdDateTimeISO',
      minWidth: 200,
    },
    {
      headerName: capitalize(t('name')),
      field: 'name',
      minWidth: 150,
    },
    {
      headerName: capitalize(t('userId')),
      field: 'userId',
      maxWidth: 80,
      renderCell: params => <Link to={`../../users/${params.row.userId}/details`}>{params.row.userId}</Link>,
    },
    {
      headerName: capitalize(t('dateOfBirth')),
      field: 'dob',
    },
    {
      headerName: capitalize(t('gender')),
      field: 'gender',
    },
    {
      headerName: t('kyc.headers.countries'),
      field: 'countries',
      renderCell: ({ value }) => <span>{value.join(', ')}</span>,
    },
    {
      headerName: capitalize(t('lastUpdated')),
      field: 'lastUpdatedDateTimeISO',
      minWidth: 200,
    },
    {
      headerName: t('kyc.headers.openMatches'),
      field: 'openCount',
    },
    {
      headerName: t('kyc.headers.discardedMatches'),
      field: 'discardedCount',
    },
    {
      headerName: t('kyc.headers.truePositives'),
      field: 'truePositiveCount',
    },
    {
      headerName: t('kyc.headers.falsePositives'),
      field: 'falsePositiveCount',
    },
    ...(canViewDetails
      ? [
          {
            field: 'actions',
            type: 'actions',
            minWidth: 50,
            flex: 0.2,
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEye} />}
                label="View"
                onClick={() => navigate(`../../tasks/kyc/profile/${params.row.userId}`)}
                key="view"
              />,
            ],
          } as GridColDef,
        ]
      : []),
  ];

  const rows: GridRowsProp =
    data?.monitorRecords?.map((item, i) => {
      return {
        ...item,
        id: i,
      };
    }) || [];

  return (
    <PermissionWrapper
      errorMessage={t('kyc.monitoring.errors.generic')}
      isError={isError}
      isLoading={isLoading}
      permission={OnlineCasinoPermissions.getIndividuals}
    >
      <>
        <div style={{ marginBottom: '10px' }}>
          {t('kyc.monitoring.status.filter')}{' '}
          <Select value={status} onChange={e => setStatus(e.target.value as EMonitorStatusSchema | 'All')}>
            {[EMonitorStatusSchema.Open, EMonitorStatusSchema.Closed, 'All'].map(option => (
              <MenuItem key={option} value={option}>
                {t(`kyc.monitoring.status.${option}`)}
              </MenuItem>
            ))}
          </Select>
        </div>
        <DataGridContainer>
          <StyledDataGrid
            disableSelectionOnClick
            autoHeight
            columns={headers}
            rows={rows}
            components={{
              Footer: () => (
                <>
                  <Button onClick={() => handlePageChange(page - 1)} disabled={page === 0}>
                    {t('previous')}
                  </Button>
                  <Button onClick={() => handlePageChange(page + 1)} disabled={!data?.nextToken}>
                    {t('next')}
                  </Button>
                  <Select value={pageSize} onChange={e => setPageSize(e.target.value as number)}>
                    {[10, 25, 50, 100].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ),
            }}
          />
        </DataGridContainer>
      </>
    </PermissionWrapper>
  );
};

export default KYCRisks;
