import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetVaultTokenBoostersQueryKey,
  useAddVaultTokenBooster,
  VaultTokenBoosterSchema,
} from '@greenisland/stores';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { addDays, getTime } from 'date-fns';
import { useSnackbar } from 'notistack';

import Input from '@greenisland-common/components/molecules/Input';

interface Props {
  open: boolean;
  onClose: () => void;
}

const VaultTokenBoosterDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, errors, control } = useForm<VaultTokenBoosterSchema>({
    defaultValues: {
      startDate: getTime(new Date()),
      endDate: getTime(addDays(new Date(), 1)),
      factor: 1,
    },
    mode: 'onChange',
  });
  const mutation = useAddVaultTokenBooster({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('contests.vault.tokenBooster.addSuccess'), {
          variant: 'success',
        });
        queryClient.invalidateQueries(getGetVaultTokenBoostersQueryKey());
        onClose();
      },
    },
  });

  const onSubmit = handleSubmit(data => {
    mutation.mutate({
      data: {
        ...data,
        startDate: data.startDate / 1000,
        endDate: data.endDate / 1000,
      },
    });
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{t('contests.vault.tokenBooster.add')}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Controller
              render={({ onChange, ref, value }) => (
                <DateTimePicker
                  label={t('startDate')}
                  value={value}
                  onChange={newDate => onChange(newDate?.getTime())}
                  renderInput={params => <TextField {...params} variant="standard" />}
                  inputRef={ref}
                  minDate={new Date()}
                />
              )}
              control={control}
              name={'startDate'}
              rules={{ required: true }}
            />
            <Controller
              render={({ onChange, ref, value }) => (
                <DateTimePicker
                  label={t('endDate')}
                  value={value}
                  onChange={newDate => onChange(newDate?.getTime())}
                  renderInput={params => <TextField {...params} variant="standard" />}
                  inputRef={ref}
                  minDate={new Date()}
                />
              )}
              control={control}
              name={'endDate'}
              rules={{ required: true }}
            />
            <Input
              control={control}
              name="factor"
              label={t('factor')}
              rules={{
                required: {
                  value: true,
                  message: t('contests.vault.tokenBooster.form.factor.required'),
                },
                min: 1,
              }}
              error={!!errors.factor}
              helpText={errors.factor?.message}
              type={'number'}
            />
            {mutation.isError ? <Alert severity="error">{t('contests.vault.tokenBooster.addError')}</Alert> : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button variant="text" onClick={onClose} color="secondary">
            {t('cancel')}
          </Button>
          <Button variant="contained" type="submit" disabled={mutation.isLoading}>
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default VaultTokenBoosterDialog;
