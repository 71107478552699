import { memo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EPlayingBreakBadRequestTypeSchema,
  EPlayingBreakReasonTypeV2Schema,
  getGetAllPlayingBreaksQueryKey,
  PlayingBreakSchema,
  SetPlayingBreakRequestSchema,
  useSetPlayingBreak,
} from '@greenisland/stores';
import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';
import { getUnixTime, startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';

import DateField from '@greenisland-common/components/molecules/DateInput';

import { PLAYING_BREAK_ERROR_REASONS } from '../Constants/PlayingBreakErrorReasons';

type AwardDialogProps = {
  openDialog: boolean;
  currentPlayingBreak?: PlayingBreakSchema;
  setOpenDialog: (state: boolean) => void;
  userId: number;
};

const SetPlayingBreakDialog = memo(({ openDialog, setOpenDialog, userId, currentPlayingBreak }: AwardDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const methods = useForm<SetPlayingBreakRequestSchema>({
    mode: 'onChange',
    defaultValues: {
      ...currentPlayingBreak,
      endDate: currentPlayingBreak?.endDate ? (currentPlayingBreak?.endDate ?? 0) * 1000 : null,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = methods;
  const reasonOptions = Object.keys(EPlayingBreakReasonTypeV2Schema);

  const { isLoading, mutate: setPlayingBreakMutation } = useSetPlayingBreak({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetAllPlayingBreaksQueryKey({ userId }));
        setOpenDialog(false);
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: EPlayingBreakBadRequestTypeSchema = error.type;
            const translationKey = errorReason ? PLAYING_BREAK_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const onSubmit = (data: SetPlayingBreakRequestSchema) => {
    setPlayingBreakMutation({
      userId: userId,
      data: {
        ...currentPlayingBreak,
        ...data,
        endDate: getUnixTime(data.endDate ?? Date.now()),
      },
    });
  };

  return (
    <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{capitalize(t('setPlayingBreak'))}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <DateField
                name="endDate"
                label={t('endDate')}
                rules={{
                  required: t('fieldIsRequired'),
                  validate: (value: Date) =>
                    value?.getTime() <
                    (currentPlayingBreak
                      ? (currentPlayingBreak?.endDate ?? 0) * 1000
                      : startOfDay(new Date()).getDate())
                      ? t('pastDatesArentAllowed')
                      : true,
                }}
              />
              <Controller
                name="reasonTypeV2"
                control={control}
                rules={{ required: t('fieldIsRequired') as string }}
                render={({ onChange, value }) => (
                  <TextField
                    size="small"
                    sx={{ mr: 2, minWidth: 200 }}
                    label={t('reason')}
                    select
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={Boolean(errors.reasonTypeV2)}
                    helperText={errors.reasonTypeV2?.message}
                  >
                    {reasonOptions?.map(reason => (
                      <MenuItem key={reason} value={reason}>
                        {reason}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <TextField
                label={t('common.aditionalInfo')}
                size="small"
                name="reason"
                inputRef={register({
                  required: t('fieldIsRequired'),
                })}
                error={Boolean(errors.reason)}
                helperText={errors.reason?.message}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
              {t('cancel')}
            </Button>
            <Button
              sx={{ minHeight: '36px', minWidth: '90px' }}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : t('setPlayingBreak')}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
});

SetPlayingBreakDialog.displayName = 'SetPlayingBreakDialog';

export default SetPlayingBreakDialog;
