import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { MaxDepositLimitSchema, useGetActiveMaximumDepositLimits } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { formatCurrency } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import {
  DataGridContainer,
  DateTime,
  FontAwesomeIcon,
  PermissionWrapper,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { MaxLimitType } from '../../Constants/Limits';
import MaxLimitCancelDialog from '../../MaxLimitCancelDialog';

type DepositLimitsProps = {
  userId: string;
};

const ActiveMaximumLimitsTable = ({ userId }: DepositLimitsProps) => {
  const { t } = useTranslation();

  const canReadDepositLimits = usePermission(OnlineCasinoPermissions.getActiveDepositLimitsForUser);
  const canCancelMaximumDepositLimit = usePermission(OnlineCasinoPermissions.cancelMaximumDepositLimit);

  const [selectedMaxLimit, setSelectedMaxLimit] = useState<MaxDepositLimitSchema | null>(null);

  const {
    data: activeDepositsLimits,
    isLoading,
    isError,
    error,
  } = useGetActiveMaximumDepositLimits(parseInt(userId, 10), { query: { enabled: canReadDepositLimits } });

  const headers: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      minWidth: 50,
      flex: 0.1,
      headerName: '',
      getActions: (params: GridRowParams) => [
        <>
          {canCancelMaximumDepositLimit && (
            <Tooltip title={t('cancel')} key="delete">
              <GridActionsCellItem
                label={t('cancel')}
                icon={<FontAwesomeIcon icon={faTrash} sx={{ color: 'error.main' }} />}
                onClick={() => {
                  if (params?.row) {
                    setSelectedMaxLimit(params.row);
                  }
                }}
                key="cancel"
              />
            </Tooltip>
          )}
        </>,
      ],
    },
    {
      headerName: t('startDate'),
      field: 'startDate',
      renderCell: ({ row }) => (row.startDate ? <DateTime dateTime={row.startDate} /> : <></>),
      flex: 1,
      minWidth: 160,
    },
    {
      headerName: t('expiryDate'),
      field: 'expiryDate',
      renderCell: ({ row }) => (row.expiryDate ? <DateTime dateTime={row.expiryDate} /> : <></>),
      flex: 1,
      minWidth: 160,
    },
    { headerName: t('period'), field: 'period', flex: 1, minWidth: 80 },
    {
      headerName: t('value'),
      field: 'value',
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) => (row.value ? formatCurrency(row.value) : ''),
    },
  ];

  const rows: GridRowsProp = useMemo(() => {
    return [
      ...(activeDepositsLimits?.daily ? [{ ...activeDepositsLimits?.daily, id: 0 }] : []),
      ...(activeDepositsLimits?.weekly ? [{ ...activeDepositsLimits?.weekly, id: 1 }] : []),
      ...(activeDepositsLimits?.monthly ? [{ ...activeDepositsLimits?.monthly, id: 2 }] : []),
    ];
  }, [activeDepositsLimits?.daily, activeDepositsLimits?.monthly, activeDepositsLimits?.weekly]);

  return (
    <>
      <PermissionWrapper
        errorMessage={error?.message}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getActiveDepositLimitsForUser}
      >
        <DataGridContainer>
          <StyledDataGrid columns={headers} rows={rows} hideFooter rowCount={3} />
        </DataGridContainer>
      </PermissionWrapper>
      {selectedMaxLimit ? (
        <MaxLimitCancelDialog
          open={true}
          onClose={() => setSelectedMaxLimit(null)}
          type={MaxLimitType.DEPOSIT_LIMIT}
          selectedMaxLimit={selectedMaxLimit}
        />
      ) : null}
    </>
  );
};

export default memo(ActiveMaximumLimitsTable);
