import { TFunction } from 'i18next';

export const datePickersErrorMessages = (t: TFunction) => {
  return {
    minDate: t('endDateShouldBeAfterStartDate'),
    maxDate: t('startDateShouldBeBeforeEndDate'),
    default: t('invalidDate'),
    disableFuture: t('futureDatesArentAllowed'),
  };
};
