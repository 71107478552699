import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardHeader, CheckData, ReactTableGI } from '@greenisland/components';
import {
  actionCreators,
  BonusRequestArgumentsSchema,
  PromotionParameters,
  useAwardPlayersRevenueContributorsBonusRequestHook,
  useGetPlayersRevenueContributorsBonusRequests,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch } from '@greenisland-core/store';
import { Collapse, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

import { BooleanSpan, formatCurrency, ObjectSpan } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridGap: '15px 15px',
    marginBottom: 15,
    gridTemplateAreas: `"awardClaimablePromotionTitle awardAvailablePromotionTitle" "awardClaimablePromotionContent awardAvailablePromotionContent"`,
    [theme.breakpoints.down('lg')]: {
      gridTemplateAreas: `"awardClaimablePromotionTitle" "awardClaimablePromotionContent" "awardAvailablePromotionTitle" "awardAvailablePromotionContent"`,
    },
  },
  awardClaimablePromotionTitle: {
    gridArea: 'awardClaimablePromotionTitle',
    display: 'flex',
    overflow: 'hidden',
  },
  awardClaimablePromotionContent: {
    gridArea: 'awardClaimablePromotionContent',
    display: 'flex',
    overflow: 'hidden',
  },
  awardAvailablePromotionTitle: {
    gridArea: 'awardAvailablePromotionTitle',
    display: 'flex',
    overflow: 'hidden',
  },
  awardAvailablePromotionContent: {
    gridArea: 'awardAvailablePromotionContent',
    display: 'flex',
    overflow: 'hidden',
  },
  margin: { marginRight: 10 },
  active: {
    color: 'white',
    backgroundColor: theme.palette.secondary.dark + ' !important',
  },
  promotions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: 10,
  },
  cardBody: {
    padding: 15,
    boxSizing: 'border-box',
  },
  h3: { margin: 0 },
  dropdown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
    float: 'right',
  },
  dropdownOpen: { transform: 'rotate(-180deg)' },
  dropdownClosed: { transform: 'rotate(0)' },
  objectContainer: { width: '0rem' },
}));

const BonusRequest = () => {
  const classes = styles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId = '' } = useParams();
  const [claimableValue, setClaimableValue] = useState(0);
  const [availableValue, setAvailableValue] = useState(0);
  const canReadBonusRequests = usePermission(OnlineCasinoPermissions.getPlayersRevenueContributorsBonusRequests);
  const canAwardBonusRequest = usePermission(OnlineCasinoPermissions.awardPlayersRevenueContributorsBonusRequest);
  const { data: bonusRequests, isLoading } = useGetPlayersRevenueContributorsBonusRequests(userId, {
    query: {
      enabled: canReadBonusRequests,
    },
  });

  const awardPlayerBonusRequest = useAwardPlayersRevenueContributorsBonusRequestHook();

  const awardBonusRequest = async (answeredQuestionId: number, values: BonusRequestArgumentsSchema) => {
    if (canAwardBonusRequest) {
      await awardPlayerBonusRequest(userId, answeredQuestionId.toString(), values);
    }
  };

  useEffect(() => {
    if (canReadBonusRequests) dispatch(actionCreators.getPlayerBonusRequests(userId));
  }, [dispatch, canReadBonusRequests, userId]);

  const headers = [
    { id: 'title', accessor: 'title', disableFilters: true, disableSortBy: true },
    { id: 'value', accessor: 'value', disableFilters: true, disableSortBy: true },
  ];

  const SettingsCollapse = (value: { value: any }) => {
    const classes = styles();
    const [isOpen, setIsOpen] = useState(false);
    return (
      <>
        <IconButton color="primary" aria-label="expand settings" style={{ padding: 0 }} size="large">
          <FontAwesomeIcon
            icon={faAngleDown}
            className={classnames(classes.dropdown, isOpen ? classes.dropdownOpen : classes.dropdownClosed)}
            onClick={() => setIsOpen(!isOpen)}
          />
        </IconButton>
        <Collapse in={isOpen} timeout="auto">
          <div className={classes.objectContainer}>
            <ObjectSpan value={value} />
          </div>
        </Collapse>
      </>
    );
  };
  const mutatedParameters = (promotion: PromotionParameters) => {
    const rows: any = [];
    Object.entries(promotion).forEach(item => {
      const [title, value] = item;
      switch (title) {
        case 'lastBetCanStillBePending':
        case 'openWithdrawals':
        case 'availablePromotions':
        case 'claimablePromotions':
        case 'openBets':
        case 'isBonusBlacklisted':
          rows.push({ title: t(title), value: <BooleanSpan boolean={value} t={t} /> });
          break;
        case 'settings':
          break;
        default:
          rows.push({ title: t(title), value: typeof value === 'string' ? value : formatCurrency(value) });
      }
    });
    rows.push({
      title: t('settings'),
      value: <SettingsCollapse value={promotion.settings} />,
    });
    return { headers, rows };
  };

  const handleClaimableValuesClick = (value: number) => {
    setClaimableValue(value);
  };

  const handleAvailableValuesClick = (value: number) => {
    setAvailableValue(value);
  };

  const handleClaimableExecute = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (bonusRequests)
      awardBonusRequest(bonusRequests.claimablePromotions.answeredQuestionId, { promotion: claimableValue });
  };

  const handleAvailableExecute = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (bonusRequests)
      awardBonusRequest(bonusRequests.availablePromotions.answeredQuestionId, { promotion: availableValue });
  };

  return canReadBonusRequests ? (
    <CheckData
      fallbackText={t('noData')}
      data={bonusRequests}
      isLoading={isLoading}
      checkProperties={['headers', 'rows']}
      render={({ data }) => (
        <div className={classes.container}>
          {canAwardBonusRequest && (
            <div className={classes.awardClaimablePromotionTitle}>
              <Card>
                <CardBody className={classes.cardBody}>
                  <h3 className={classes.h3}>{t('awardClaimablePromotion')}</h3>
                  {data.claimablePromotions.reason ? (
                    <h4>{`${t('reason')}: ${data.claimablePromotions.reason}`}</h4>
                  ) : (
                    data.claimablePromotions.promotions.length > 0 && (
                      <div className={classes.promotions}>
                        <h4>{t('claimablePromotionValue')}</h4>
                        <div className={classes.buttonContainer}>
                          {data.claimablePromotions.promotions.map((value, index) => (
                            <Button
                              key={index}
                              className={`${classes.margin} ${value === claimableValue && classes.active}`}
                              onClick={() => handleClaimableValuesClick(value)}
                              color="secondary"
                              variant="contained"
                            >
                              {`${value}%`}
                            </Button>
                          ))}
                        </div>
                        <Button
                          className={classes.margin}
                          variant="transparent"
                          onClick={handleClaimableExecute}
                          disabled={claimableValue === 0}
                        >
                          {t('execute')}
                        </Button>
                      </div>
                    )
                  )}
                  {!data.claimablePromotions.reason && data.claimablePromotions.promotions.length === 0 && (
                    <h4>{t('noReason')}</h4>
                  )}
                </CardBody>
              </Card>
            </div>
          )}
          <div className={classes.awardClaimablePromotionContent}>
            <Card>
              <CardHeader>{t('claimablePromotions')}</CardHeader>
              <CardBody>
                <ReactTableGI
                  columns={mutatedParameters(data.claimablePromotions.parameters).headers}
                  data={mutatedParameters(data.claimablePromotions.parameters).rows}
                  noTableHeader
                  rowColTable
                />
              </CardBody>
            </Card>
          </div>
          {canAwardBonusRequest && (
            <div className={classes.awardAvailablePromotionTitle}>
              <Card>
                <CardBody className={classes.cardBody}>
                  <h3 className={classes.h3}>{t('awardAvailablePromotion')}</h3>
                  {data.availablePromotions.reason ? (
                    <h4>{`${t('reason')}: ${data.availablePromotions.reason}`}</h4>
                  ) : (
                    data.availablePromotions.promotions.length > 0 && (
                      <div className={classes.promotions}>
                        <h4>{t('availablePromotionValue')}</h4>
                        <div className={classes.buttonContainer}>
                          {data.availablePromotions.promotions.map((value, index) => (
                            <Button
                              key={index}
                              className={`${classes.margin} ${value === claimableValue && classes.active}`}
                              onClick={() => handleAvailableValuesClick(value)}
                              color="secondary"
                              variant="contained"
                            >
                              {`${value}€`}
                            </Button>
                          ))}
                        </div>
                        <Button
                          className={classes.margin}
                          variant="transparent"
                          onClick={handleAvailableExecute}
                          disabled={availableValue === 0}
                        >
                          {t('execute')}
                        </Button>
                      </div>
                    )
                  )}
                  {!data.availablePromotions.reason && data.availablePromotions.promotions.length === 0 && (
                    <h4>{t('noReason')}</h4>
                  )}
                </CardBody>
              </Card>
            </div>
          )}
          <div className={classes.awardAvailablePromotionContent}>
            <Card>
              <CardHeader>{t('availablePromotions')}</CardHeader>
              <CardBody>
                <ReactTableGI
                  columns={mutatedParameters(data.availablePromotions.parameters).headers}
                  data={mutatedParameters(data.availablePromotions.parameters).rows}
                  noTableHeader
                  rowColTable
                />
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    />
  ) : null;
};

export default memo(BonusRequest);
