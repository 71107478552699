import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EAggregationFunctionSchema, EBoostTypeSchema, useGetBoosts } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  DataGridPagination,
  PermissionWrapper,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import {
  formatCurrency,
  getDateTimeFromUnix,
  getDurationFromSeconds,
} from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

type AggregationFunctionKeys = {
  [key in EAggregationFunctionSchema]: string;
};

const aggregationFunctionKeys: AggregationFunctionKeys = {
  [EAggregationFunctionSchema.Sum]: 'boost.global.aggregationFunction.sum',
  [EAggregationFunctionSchema.Average]: 'boost.global.aggregationFunction.average',
  [EAggregationFunctionSchema.Count]: 'boost.global.aggregationFunction.count',
  [EAggregationFunctionSchema.Max]: 'boost.global.aggregationFunction.max',
  [EAggregationFunctionSchema.Min]: 'boost.global.aggregationFunction.min',
};

const GlobalBoostsOverviewTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const canViewBoosts = usePermission(OnlineCasinoPermissions.getBoosts);

  const {
    data: globalBoosts,
    isError,
    isLoading,
  } = useGetBoosts(
    {
      boostType: EBoostTypeSchema.Global,
      page: page,
      pageSize: pageSize,
    },
    { query: { enabled: canViewBoosts } }
  );

  const headers: GridColumns = [
    {
      headerName: t('boost.table.header.name'),
      field: 'name',
      width: 180,
    },
    {
      headerName: t('boost.table.header.payoutType'),
      field: 'payoutType',
    },
    {
      headerName: t('boost.table.header.multiplier'),
      field: 'factor',
    },
    {
      headerName: t('boost.table.header.aggregationFunction'),
      field: 'aggregationFunction',
      width: 250,
      valueFormatter: params => t(aggregationFunctionKeys[params.value as EAggregationFunctionSchema]),
    },
    {
      headerName: t('boost.table.header.isLoyaltyDependent'),
      field: 'isLoyaltyDependent',
      width: 160,
      valueFormatter: params => {
        if (params.value) {
          return t('yes');
        }
        return t('no');
      },
    },
    {
      headerName: t('boost.table.header.maxPayout'),
      field: 'maxPayout',
      valueFormatter: params => formatCurrency(params.value),
    },
    {
      headerName: t('boost.table.header.totalPayout'),
      field: 'totalPayout',
      valueFormatter: params => formatCurrency(params.value),
    },
    {
      headerName: t('boost.table.header.contentFilterId'),
      field: 'contentFilterId',
      width: 150,
    },
    {
      headerName: t('boost.table.header.startDate'),
      field: 'startDate',
      width: 180,
      valueFormatter: params => getDateTimeFromUnix(params.value),
    },
    {
      headerName: t('boost.table.header.duration'),
      field: 'duration',
      valueFormatter: params => getDurationFromSeconds(params.value),
    },
    {
      headerName: t('boost.table.header.timesAwarded'),
      field: 'timesAwarded',
      width: 120,
    },
    {
      headerName: t('boost.table.header.timesActivated'),
      field: 'timesActivated',
      width: 120,
    },
  ];

  const rows: GridRowsProp =
    globalBoosts?.items?.map((item, i) => {
      return {
        ...item,
        id: i,
      };
    }) || [];

  return (
    <PermissionWrapper
      errorMessage={t('boost.global.permission.viewError')}
      isError={isError}
      isLoading={isLoading}
      permission={OnlineCasinoPermissions.getBoosts}
    >
      <DataGridContainer>
        <StyledDataGrid
          autoHeight
          density="compact"
          columns={headers}
          rows={rows}
          pageSize={pageSize}
          paginationMode="server"
          pagination
          page={globalBoosts?.pagingDetails?.currentPage}
          rowCount={globalBoosts?.pagingDetails?.totalItems}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          components={{ Pagination: DataGridPagination }}
          componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
        />
      </DataGridContainer>
    </PermissionWrapper>
  );
};

export default GlobalBoostsOverviewTable;
