import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TransferList } from '@greenisland/components';
import {
  CountryList,
  CountryListInner,
  EGameLibraryModelSchema,
  GameProviderArgumentsBlacklistedCountries,
  GameProviderSchema,
  GameStudioSchema,
  GameStudiosSchema,
  getGetGameProviderQueryKey,
} from '@greenisland/stores';
import { Box, Button, Stack, Typography } from '@mui/material';

import CheckboxField from '@greenisland-common/components/molecules/CheckboxField';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';
import Input from '@greenisland-common/components/molecules/Input';

import SyncGameLibraryButton from './Forms/SyncGameLibraryButton';
import TranslationsDictionaryFields, { TotalFieldArrayDataItem } from './Forms/TranslationsDictionaryFields';
import processFieldArray from './utils/processFieldArray';
import GameStudiosComp from './GameStudios';

interface ExtendedGameProviderSchema extends GameProviderSchema {
  totalFieldArray?: TotalFieldArrayDataItem[];
}
interface Props {
  gameProvider: GameProviderSchema;
  countries: CountryList;
  submit: (values: GameProviderSchema) => void;
  gameStudios: GameStudiosSchema;
  error: string;
}

const ProviderFormEdit = ({ gameProvider, gameStudios, countries, submit, error }: Props) => {
  const { t } = useTranslation();
  const [selectedGameStudios, setSelectedGameStudios] = useState<GameStudiosSchema>(
    gameStudios.filter((studio: GameStudioSchema) =>
      gameProvider.gameStudios.find((id: string) => id === studio.gameStudioId)
    )
  );

  const DEFAULT_VALUES: GameProviderSchema = {
    code: gameProvider.code,
    providerId: gameProvider.providerId,
    blacklistedCountries: gameProvider.blacklistedCountries,
    demoEndPointUrl: gameProvider.demoEndPointUrl,
    enabled: gameProvider.enabled,
    endPointUrl: gameProvider.endPointUrl,
    gameStudios: gameProvider.gameStudios,
    mobileDemoEndPointUrl: gameProvider.mobileDemoEndPointUrl,
    mobileEndPointUrl: gameProvider.mobileEndPointUrl,
    order: gameProvider.order,
    providerName: gameProvider.providerName,
    integrationCode: gameProvider.integrationCode,
    conflictGamelib: gameProvider.conflictGamelib,
    gameLibId: gameProvider.gameLibId,
    description: gameProvider.description,
  };

  const methods = useForm<GameProviderSchema>({ defaultValues: DEFAULT_VALUES });

  const { handleSubmit, register, control, errors } = methods;

  const getCountries = (blacklistedCountries: GameProviderArgumentsBlacklistedCountries[]): CountryList => {
    const toReturn: CountryList = [];

    blacklistedCountries.forEach((blacklistCountry: GameProviderArgumentsBlacklistedCountries) => {
      const find = countries.find(country => country.countryCode === blacklistCountry.country);
      if (find) {
        toReturn.push(find);
      }
    });

    return toReturn;
  };

  const [selectedCountries, setSelectedCountries] = useState<CountryList>(
    getCountries(gameProvider.blacklistedCountries)
  );

  const onSubmit = handleSubmit((data: ExtendedGameProviderSchema) => {
    if (data.totalFieldArray) {
      const { descriptions } = processFieldArray(data.totalFieldArray);
      data.description = descriptions;
      delete data.totalFieldArray;
    }

    const blacklisted: Array<GameProviderArgumentsBlacklistedCountries> = selectedCountries
      ? selectedCountries.map((value: CountryListInner) => ({ country: value.countryCode }))
      : [];
    if (gameProvider) {
      const newValues: GameProviderSchema = {
        ...gameProvider,
        ...data,
        blacklistedCountries: blacklisted,
        gameStudios: selectedGameStudios.map(studio => studio.gameStudioId),
      };
      submit(newValues);
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h4">{gameProvider.providerName}</Typography>
          {gameProvider?.conflictGamelib && (
            <Typography color="error">{t('conflict.gameLibId', { gameLibId: gameProvider.gameLibId })}</Typography>
          )}
          <Box>
            <SyncGameLibraryButton
              gameLibEntity={gameProvider}
              gameLibModel={EGameLibraryModelSchema.GameProvider}
              invalidatedQueryKey={getGetGameProviderQueryKey(gameProvider.providerId)}
            />
          </Box>
          <CheckboxField name="enabled" label={t('enabled')} control={control} />
          <Input label={t('endPointUrl')} fullWidth name="endPointUrl" inputRef={register()} />
          <Input label={t('demoEndPointUrl')} fullWidth name="demoEndPointUrl" inputRef={register()} />
          <Input label={t('mobileEndPointUrl')} fullWidth name="mobileEndPointUrl" inputRef={register()} />
          <Input label={t('mobileDemoEndPointUrl')} fullWidth name="mobileDemoEndPointUrl" inputRef={register()} />
          <Input
            label={t('integrationCode')}
            fullWidth
            name="integrationCode"
            inputRef={register({ required: t('fieldIsRequired') as string })}
          />
          {errors.integrationCode && <Box color="error.main">{errors.integrationCode.message}</Box>}
          <TranslationsDictionaryFields nameDescription="description" control={control} />
          {selectedCountries && (
            <TransferList
              leftTitle={t('allowedCountries')}
              rightTitle={t('blacklistedCountries')}
              allItems={countries
                ?.filter((item: CountryListInner) => !selectedCountries.includes(item))
                .map((item: CountryListInner) => ({
                  original: item,
                  name: item.countryName,
                  id: item.countryCode,
                }))}
              selectedItems={
                selectedCountries
                  ? selectedCountries.map((item: CountryListInner) => ({
                      original: item,
                      name: item.countryName,
                      id: item.countryCode,
                    }))
                  : []
              }
              onSelected={(selected: any) => {
                setSelectedCountries(selected);
              }}
            />
          )}
          {gameProvider && (
            <GameStudiosComp
              gameStudios={gameStudios}
              selectedGameStudios={selectedGameStudios}
              setSelectedGameStudios={setSelectedGameStudios}
            />
          )}
          {error && <ErrorState errorMessage={error} />}
          <Button variant="contained" type="submit">
            {t('save')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ProviderFormEdit;
