import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { TournamentStatusFilterParamParameter } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, Button, capitalize, InputBase, Paper, TextField, useTheme } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../app/hooks';

interface Props {
  setSearch: Dispatch<SetStateAction<string>>;
  tournamentStatusFilter: TournamentStatusFilterParamParameter;
  setTournamentStatusFilter: Dispatch<SetStateAction<TournamentStatusFilterParamParameter>>;
}

const MultiplierTournamentFilterBar = ({ setSearch, tournamentStatusFilter, setTournamentStatusFilter }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const canCreateMultiplierTournament = usePermission(OnlineCasinoPermissions.createMultiplierTournament);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column-reverse',
          gap: 2,
        },
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          [theme.breakpoints.up('xs')]: {
            flexDirection: 'column',
            gap: 2,
            width: '100%',
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            width: '75%',
          },
        }}
      >
        <Paper
          sx={{
            [theme.breakpoints.up('xs')]: {
              width: '100%',
              backgroundColor: 'white',
              height: 'fit-content',
            },
            [theme.breakpoints.up('md')]: {
              width: '30%',
            },
          }}
          variant="outlined"
          elevation={0}
        >
          <Box display="flex" alignItems="center" flexWrap="wrap" paddingX={2} paddingY={1.5}>
            <Box flexGrow={1}>
              <InputBase
                fullWidth
                placeholder={t('contests.multiplierTournaments.searchMultiplierTournamentInputText')}
                onChange={e => setSearch(e.target.value)}
              />
            </Box>
            <SearchIcon />
          </Box>
        </Paper>
        <Autocomplete
          sx={{
            [theme.breakpoints.up('xs')]: {
              width: '100%',
            },
            [theme.breakpoints.up('md')]: {
              width: '20%',
            },
          }}
          disablePortal
          id="filter-status-selector"
          options={Object.values(TournamentStatusFilterParamParameter)}
          defaultValue={tournamentStatusFilter}
          onChange={(_, value) => value && setTournamentStatusFilter(value)}
          renderInput={params => <TextField {...params} label={capitalize(t('status'))} />}
        />
      </Box>
      {canCreateMultiplierTournament && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            [theme.breakpoints.up('xs')]: {
              width: '100%',
            },
            [theme.breakpoints.up('md')]: {
              width: '25%',
            },
          }}
        >
          <Button
            onClick={() => navigate(`../create-multiplier-tournament`)}
            variant="contained"
            size="medium"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            sx={{
              [theme.breakpoints.up('xs')]: {
                mr: 0,
              },
              [theme.breakpoints.up('md')]: {
                mr: 2,
              },
            }}
          >
            {t('contests.multiplierTournaments.addMultiplierTournamentButtonText')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MultiplierTournamentFilterBar;
