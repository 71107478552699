import React, { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PromoToCashEventsInner, useGetPromoToCashEvents, useSetCorrectPromoToCash } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { formatCurrency } from '../../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../../app/hooks';

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
}

type FormProps = {
  promotionId: string;
};

const PromoToCashDialog = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const canReadPromoToCash = usePermission(OnlineCasinoPermissions.getPromoToCashEvents);
  const { userId = '' } = useParams();
  const {
    data: promoToCashEvents,
    isLoading,
    isError,
  } = useGetPromoToCashEvents(userId, { query: { enabled: canReadPromoToCash } });
  const promoToCashMutation = useSetCorrectPromoToCash({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        setOpen(false);
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });
  const { handleSubmit, watch, control } = useForm<FormProps>({ defaultValues: { promotionId: '' } });

  const hasSelectedPromo = watch('promotionId');

  if (isLoading)
    return (
      <Dialog fullWidth maxWidth="sm" open={true}>
        <Box sx={{ height: 210, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      </Dialog>
    );

  if (isError || !promoToCashEvents) return null;

  const onSubmit = (formData: FormProps) => {
    promoToCashMutation.mutate({ userId, data: formData });
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{capitalize(t('promoToCashCorrection'))}</DialogTitle>
        <DialogContent>
          <Controller
            name="promotionId"
            control={control}
            rules={{ required: t('fieldIsRequired') as string }}
            render={({ onChange, value }) => (
              <TextField
                size="small"
                select
                onChange={onChange}
                value={value}
                sx={{ width: '100%' }}
                label={t('promo')}
              >
                {promoToCashEvents.map((entry: PromoToCashEventsInner) => (
                  <MenuItem key={entry.eventId} value={entry.eventId}>{`${formatCurrency(entry.amount)} - ${format(
                    Math.floor(entry.timestamp * 1000),
                    'dd-MM-yyyy HH:mm'
                  )}`}</MenuItem>
                ))}
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={() => setOpen(false)}>
            {t('cancel')}
          </Button>
          <Button disabled={!hasSelectedPromo} variant="contained" color="primary" type="submit">
            {t('correct')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(PromoToCashDialog);
