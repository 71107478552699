import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  GameSessionsSummaryGamingRevenueComparisonParameter,
  GameSessionsSummaryGamingRevenueComparisonValueParameter,
  GameSessionsSummaryGroupByKeyParamParameter,
  GameSessionsSummarySortKeyParamParameter,
  SortOrderParamParameter,
} from '@greenisland/stores';
import { Box, Button, capitalize, Card, CardContent, CardHeader } from '@mui/material';
import { getUnixTime, subHours } from 'date-fns';

import {
  GameSessionsSortingType,
  GameSummaryCardVariant,
  GameSummarySearchFilters,
  SessionGameCardType,
} from '../Constants';
import GameSummariesTableData from './GameSummariesTableData';

interface Props {
  cardVariant: GameSummaryCardVariant;
  groupByKey: GameSessionsSummaryGroupByKeyParamParameter;
  sortByOrder: SortOrderParamParameter;
  gamingRevenueComparison?: GameSessionsSummaryGamingRevenueComparisonParameter;
  gamingRevenueComparisonValue?: GameSessionsSummaryGamingRevenueComparisonValueParameter;
}

const GameSummariesCard = ({
  cardVariant,
  groupByKey,
  sortByOrder,
  gamingRevenueComparison,
  gamingRevenueComparisonValue,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [gameSummariesSorting] = useState<GameSessionsSortingType>({
    groupByKey,
    sortByKey: GameSessionsSummarySortKeyParamParameter.GamingRevenue,
    sortByOrder,
    gamingRevenueComparison,
    gamingRevenueComparisonValue,
  });

  const navigateToHandler = useCallback(() => {
    const params = new URLSearchParams();
    params.set(GameSummarySearchFilters.ACTIVE_TAB, '0');
    params.set(GameSummarySearchFilters.PAGE, '0');
    params.set(GameSummarySearchFilters.START_DATE, getUnixTime(subHours(new Date(), 24)).toString());
    params.set(GameSummarySearchFilters.END_DATE, getUnixTime(new Date()).toString());
    params.set(GameSummarySearchFilters.GROUP_BY_KEY, groupByKey);
    params.set(GameSummarySearchFilters.SORT_BY_ORDER, sortByOrder);
    params.set(GameSummarySearchFilters.SORT_BY_KEY, GameSessionsSummarySortKeyParamParameter.GamingRevenue);
    params.set(
      GameSummarySearchFilters.GAMING_REVENUE_COMPARISON,
      gamingRevenueComparison || GameSessionsSummaryGamingRevenueComparisonParameter.None
    );
    if (gamingRevenueComparisonValue || gamingRevenueComparisonValue === 0) {
      params.set(GameSummarySearchFilters.GAMING_REVENUE_COMPARISON_VALUE, gamingRevenueComparisonValue.toString());
    }

    const filtersQuery = '?' + params.toString();

    navigate(`../lookup/gamesessionsoverview${filtersQuery}`);
  }, [gamingRevenueComparison, gamingRevenueComparisonValue, groupByKey, navigate, sortByOrder]);

  return (
    <Card elevation={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader
          title={capitalize(t(cardVariant))}
          sx={{
            '.MuiCardHeader-title': {
              fontSize: '14px',
            },
          }}
        />
        <Button sx={{ mr: 1 }} variant="outlined" color="primary" size="small" onClick={navigateToHandler}>
          {t('seeMore')}
        </Button>
      </Box>
      <CardContent sx={{ pt: 0 }}>
        <GameSummariesTableData
          cardType={SessionGameCardType.DASHBOARD}
          cardVariant={cardVariant}
          startDate={subHours(new Date(), 24)}
          endDate={new Date()}
          groupByKey={gameSummariesSorting.groupByKey}
          sortOrder={gameSummariesSorting.sortByOrder}
          sortKey={gameSummariesSorting.sortByKey as GameSessionsSummarySortKeyParamParameter}
          gamingRevenueComparison={gameSummariesSorting?.gamingRevenueComparison}
          gamingRevenueComparisonValue={gameSummariesSorting?.gamingRevenueComparisonValue}
        />
      </CardContent>
    </Card>
  );
};

export default GameSummariesCard;
