import { ELoyaltyUserBadRequestTypeSchema } from '@greenisland/stores';

type LoyaltyUserBadRequestTypeTranslation = {
  [key in ELoyaltyUserBadRequestTypeSchema]: string;
};

export const LOYALTY_USER_ERROR_REASONS: LoyaltyUserBadRequestTypeTranslation = {
  [ELoyaltyUserBadRequestTypeSchema.modelStateInvalid]: 'loyaltyuser.badRequestType.modelStateInvalid',
  [ELoyaltyUserBadRequestTypeSchema.invalidUserId]: 'loyaltyuser.badRequestType.invalidUserId',
  [ELoyaltyUserBadRequestTypeSchema.userNotFound]: 'loyaltyuser.badRequestType.userNotFound',
  [ELoyaltyUserBadRequestTypeSchema.accountNotFound]: 'loyaltyuser.badRequestType.accountNotFound',
  [ELoyaltyUserBadRequestTypeSchema.pointsToAwardNegative]: 'loyaltyuser.badRequestType.pointsToAwardNegative',
  [ELoyaltyUserBadRequestTypeSchema.accountNotAllowedToEnlist]: 'loyaltyuser.badRequestType.accountNotAllowedToEnlist',
  [ELoyaltyUserBadRequestTypeSchema.accountAlreadySameRank]: 'loyaltyuser.badRequestType.accountAlreadySameRank',
};
