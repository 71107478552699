import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserRegistration, UserBGCRegistrationSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Card, Stack, Tab, Tabs } from '@mui/material';

import { TabPanel } from '@greenisland-common/components/atoms';

import DepositLimitsStatus from './components/DepositLimitsStatus';
import RegistrationEventsKsaStreamTable from './components/RegistrationEventsKsaStreamTable';
import RegistrationMessagesContainer from './components/RegistrationMessagesContainer';
import { usePermission } from '../../../../../../app/hooks';
import { RegistrationEventsBgc6StreamTable, RegistrationEventsStreamTable, RegistrationSummary } from './components';

const Registration = () => {
  const { userId = '' } = useParams();
  const canReadRegistration = usePermission(OnlineCasinoPermissions.getUserRegistration);
  const { data: registration, isLoading } = useGetUserRegistration(userId ?? '', {
    query: { enabled: canReadRegistration && !!userId },
  });
  const [value, setValue] = useState(0);

  if (!canReadRegistration) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <RegistrationSummary registration={registration} isLoading={isLoading} />
      <Card sx={{ backgroundColor: 'background.default' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
            <Tab label="Messages" value={0} />
            <Tab label="Registration Stream" value={1} />
            {registration?.regulator === 'BGC' && <Tab label="BGC Article 6 Stream" value={2} />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box sx={{ height: '600px', paddingBottom: 5 }}>
            <RegistrationMessagesContainer />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ height: '600px', paddingBottom: 5 }}>
            {registration?.regulator === 'BGC' ? (
              <RegistrationEventsStreamTable token={registration?.registration.token} />
            ) : (
              <RegistrationEventsKsaStreamTable userId={userId} />
            )}
          </Box>
        </TabPanel>
        {registration?.regulator === 'BGC' && (
          <TabPanel value={value} index={2}>
            <Box sx={{ height: '600px', paddingBottom: 5 }}>
              <RegistrationEventsBgc6StreamTable userId={userId} />
            </Box>
          </TabPanel>
        )}
      </Card>
      {registration?.regulator === 'BGC' && (
        <DepositLimitsStatus userId={userId} userToken={(registration as UserBGCRegistrationSchema).token} />
      )}
    </Stack>
  );
};

export default Registration;
