import { ChangeEvent, createRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import {
  ELanguagesSchema,
  EMIMETypeSchema,
  GetAllMediaGalleryEntriesOfTypeParams,
  getGetAllMediaGalleryEntriesOfTypeQueryKey,
  MediaGalleryEntrySchema,
  MediaGalleryEntryTypeParamParameter,
  useUploadMediaGalleryEntry,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Fab } from '@mui/material';
import { useSnackbar } from 'notistack';
import { MediaGalleryList } from 'src/app/components/MediaGallery';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { fileToBase64 } from '@greenisland-common/helpers';

import { usePermission } from '../../../app/hooks';

const MediaGallery = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const imageInputRef = createRef<HTMLInputElement>();

  const canAddMediaGalleryEntries = usePermission(OnlineCasinoPermissions.uploadMediaGalleryEntry);

  const [filters, setFilters] = useState<GetAllMediaGalleryEntriesOfTypeParams>({
    mediaGalleryEntryType: MediaGalleryEntryTypeParamParameter.Template,
  });
  const [selectedMedia, setSelectedMedia] = useState<MediaGalleryEntrySchema[]>([]);

  const uploadMutation = useUploadMediaGalleryEntry({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetAllMediaGalleryEntriesOfTypeQueryKey(filters));
      },
      onError: async error => {
        enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
      },
    },
  });

  const handleUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      event.target.value = '';
      if (file) {
        uploadMutation.mutate({
          data: {
            asset: {
              content: await fileToBase64(file),
              name: file.name,
              mimeType: file.type as EMIMETypeSchema,
            },
            mediaGalleryEntryType: filters.mediaGalleryEntryType,
          },
          params: {
            language: ELanguagesSchema.EN,
          },
        });
      }
    },
    [filters.mediaGalleryEntryType, uploadMutation]
  );

  return (
    <>
      {canAddMediaGalleryEntries ? (
        <Box display="flex" justifyContent="flex-end" sx={{ mr: 3 }}>
          <Fab color="primary" aria-label="add" variant="extended" onClick={() => imageInputRef.current?.click()}>
            <FontAwesomeIcon icon={faAdd} sx={{ mr: 1 }} />
            {t('content.mediaGallery.uploadImage')}
          </Fab>
        </Box>
      ) : null}
      <Box sx={{ padding: 3 }}>
        <input ref={imageInputRef} type="file" accept="image/*" hidden onChange={handleUpload} />
        <MediaGalleryList
          mediaGalleryEntryType={filters.mediaGalleryEntryType}
          selectionEnabled
          onSelect={setSelectedMedia}
          onSelectMediaGalleryEntry={setFilters}
          selectedMedia={selectedMedia}
          isMultiSelect={true}
        />
      </Box>
    </>
  );
};

export default MediaGallery;
