import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GameSchema, useGetGameProviders, useGetGamesByQueryFilters } from '@greenisland/stores';
import { Skeleton } from '@mui/material';

import { AutoCompleteInput } from '@greenisland-common/components/atoms';

import { capitalize } from '@greenisland-common/helpers/formatters';

import { ContentFiltersPrimaryKeysType } from '../helpers/ContentFilterFormContext';

interface Props {
  type: 'included' | 'excluded';
}

const ContentFilterGames = ({ type }: Props) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { data: gameProviders, isLoading } = useGetGameProviders();
  const gameProvidersOptions = useMemo(
    () => gameProviders?.map(provider => ({ id: provider.providerId, label: provider.providerName })) ?? [],
    [gameProviders]
  );
  const watchGameProvider = form.watch(`${type}FilterGameProvider`);
  const games = useGetGamesByQueryFilters(
    watchGameProvider && {
      providerId: parseInt(watchGameProvider?.id, 10),
      resultsPerPage: 1000,
    },
    {
      query: { enabled: !!watchGameProvider?.id },
    }
  );
  const gamesOptions = useMemo(() => games?.data?.results?.map(game => ({ ...game })) ?? [], [games?.data]);
  if (isLoading) return <Skeleton variant="rounded" height={56} animation="wave" />;

  return (
    <>
      <AutoCompleteInput
        size="small"
        disableCloseOnSelect={false}
        sx={{ mb: 2 }}
        multiple={false}
        name={`${type}FilterGameProvider`}
        label={capitalize(t('addGameProvider'))}
        options={gameProvidersOptions}
        getLabel={option => option?.label ?? ''}
      />
      <AutoCompleteInput
        size="small"
        multiple={true}
        name={`${type}Filter.${ContentFiltersPrimaryKeysType.gamePKeys}`}
        label={capitalize(t('addGame'))}
        options={gamesOptions}
        getLabel={(option: GameSchema) => option?.gameName ?? ''}
        rules={{
          required: true,
          setValueAs: (games: GameSchema[]) => {
            return games?.map(game => Number(game?.gamePKey));
          },
        }}
      />
    </>
  );
};

export default ContentFilterGames;
