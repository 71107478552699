import { useTranslation } from 'react-i18next';
import { useGetBechargeBalance } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Skeleton } from '@mui/lab';
import { Box, capitalize, Stack, Typography } from '@mui/material';

import { PermissionWrapper } from '@greenisland-common/components/atoms';

import { formatCurrency } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const VouchersBalance = () => {
  const { t } = useTranslation();

  const canReadBechargeBalance = usePermission(OnlineCasinoPermissions.getBechargeBalance);

  const {
    data: bechargeBalance,
    isLoading,
    isError,
  } = useGetBechargeBalance({
    query: { enabled: canReadBechargeBalance },
  });

  if (isLoading) return <Skeleton variant="rectangular" height={32} />;

  if (!bechargeBalance?.balance) return null;

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('content.becharge.balance.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getBechargeBalance}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h5" mr={1}>
            {capitalize(t('balance'))}:
          </Typography>
          <Typography variant="h5" fontWeight={500}>
            {formatCurrency(bechargeBalance.balance, bechargeBalance?.currency)}
          </Typography>
        </Box>
      </PermissionWrapper>
    </Stack>
  );
};

export default VouchersBalance;
