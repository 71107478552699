import { useTranslation } from 'react-i18next';
import { useGetCampaign } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

import { NoData, PermissionWrapper } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../app/hooks';
import IndividualActions from './IndividualActions';
import Templates from './Templates';

interface Props {
  campaignId: string;
}

const SelectedCampaign = ({ campaignId }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const canReadCampaign = usePermission(OnlineCasinoPermissions.getCampaign);

  const {
    data: campaign,
    isLoading,
    isError,
  } = useGetCampaign(campaignId, { query: { enabled: canReadCampaign && !!campaignId } });

  if (isLoading) {
    return <CircularProgress size={40} />;
  }

  return (
    <PermissionWrapper
      errorMessage={t('marketing.campaigns.permissions.fetchError')}
      isError={isError}
      isLoading={false}
      permission={OnlineCasinoPermissions.getCampaign}
    >
      {!campaign ? (
        <NoData />
      ) : (
        <Box display="grid" gap={theme.spacing(2, 0)} gridAutoRows="min-content">
          <>
            <Typography variant="h5">{`${t('campaign')}: ${campaign.campaignName}`}</Typography>
            <IndividualActions campaignId={campaignId} individualActions={campaign.individualActions} />
            <Templates campaignId={campaignId} templates={campaign.templates} />
          </>
        </Box>
      )}
    </PermissionWrapper>
  );
};

export default SelectedCampaign;
