import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserGeneral } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Skeleton, Typography } from '@mui/material';

import { ErrorCard } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../app/hooks';
import { UserDataChips, UserDataMenu } from './Components';

interface Props {
  userId: string;
}

const UserDataPlaceholder = () => (
  <Box>
    <Box display="flex" alignItems="center">
      <Skeleton sx={{ mr: 1 }} variant="text" height={60} width={200} />
      <Skeleton sx={{ mr: 1, borderRadius: '20px' }} variant="rectangular" width={75} height={30} />
      <Skeleton sx={{ mr: 1, borderRadius: '20px' }} variant="rectangular" width={75} height={30} />
      <Skeleton sx={{ borderRadius: '20px' }} variant="rectangular" width={75} height={30} />
    </Box>
  </Box>
);

const UserData = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadUserGeneral = usePermission(OnlineCasinoPermissions.getUserGeneral);

  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useGetUserGeneral(userId, { query: { enabled: canReadUserGeneral } });

  if (isLoading) return <UserDataPlaceholder />;
  if (isError || !user) return <ErrorCard sx={{ width: 400 }}>{error?.message || t('error')}</ErrorCard>;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
            flexWrap: 'wrap',
            alignItems: 'baseline',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              mr: {
                xs: 0,
                lg: 1,
              },
              display: 'inline',
            }}
          >
            {user.username}
          </Typography>
          <UserDataChips />
        </Box>
        <UserDataMenu userId={user.userId} />
      </Box>
    </>
  );
};

export default UserData;
