import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { ErrorSchema } from '@greenisland/stores';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';
import { Game, getQueryGamesQueryKey, useAddGame } from '@greenisland-store/gameServer';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { usePermission } from '../../../app/hooks';
import GameGeneralSettings from './GameGeneralSettings';

const CreateGameForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const canCreateGame = usePermission(GameServerPermissions.addGames);
  const [gameValidation, setGameValidation] = useState(false);

  const methods = useForm<Game>({
    shouldFocusError: true,
    mode: 'onChange',
  });
  const { handleSubmit, reset } = methods;

  const { mutate: createGameMutation, isLoading } = useAddGame({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getQueryGamesQueryKey());
        enqueueSnackbar(t('success'), { variant: 'success' });
        setGameValidation(false);
        reset();
        navigate(`../games-overview`);
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  function GameValidationHandler(entry: Game) {
    if (entry.name == null || entry.gameReference == null) return false;
    return true;
  }

  const onSubmit = (formData: Game) => {
    const gameValidation = GameValidationHandler(formData);
    setGameValidation(gameValidation);

    if (!gameValidation) return;

    createGameMutation({ data: formData });
  };

  if (isLoading) return <Skeleton variant="rectangular" height={600} />;

  return canCreateGame ? (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Typography sx={{ mb: 2 }} variant="body2" fontSize="large" fontWeight="bold">
            {t('content.gameserver.game.form.addgame')}
          </Typography>
          <GameGeneralSettings />
        </Stack>
        <Button sx={{ mt: 4, px: 6, py: 2 }} type="submit" variant="contained" color="primary" disabled={isLoading}>
          {t('save')}
        </Button>
      </form>
    </FormProvider>
  ) : null;
};

export default CreateGameForm;
