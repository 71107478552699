import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  GameSessionsSortKeyParamParameter,
  GameSessionsSummaryGamingRevenueComparisonParameter,
  GameSessionsSummarySortKeyParamParameter,
  SortOrderParamParameter,
} from '@greenisland/stores';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Tab, Tabs } from '@mui/material';
import { getUnixTime, subHours, subWeeks } from 'date-fns';

import { GameSessionsSortingType, GameSummarySearchFilters, SessionGameCardType } from '../Constants';
import GameSessionsTableData from './GameSessionsTableData';

interface Props {
  cardType: SessionGameCardType;
  title: string;
}

const GameSessionCard = ({ cardType, title }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const isDashboardCard = cardType === SessionGameCardType.DASHBOARD;
  const [selectedTab, setSelectedTab] = useState(0);

  const [gameSessionsSorting, setGameSessionsSorting] = useState<GameSessionsSortingType>({
    sortByKey: GameSessionsSortKeyParamParameter.GamingRevenue,
    sortByOrder: SortOrderParamParameter.ascending,
    gamingRevenueComparison: GameSessionsSummaryGamingRevenueComparisonParameter.LessThan,
    gamingRevenueComparisonValue: 0,
  });

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    const sorting: GameSessionsSortingType = {
      sortByKey: GameSessionsSortKeyParamParameter.GamingRevenue,
      sortByOrder: SortOrderParamParameter.ascending,
    };

    if (newValue === 0 || newValue === 1) {
      sorting.sortByKey = GameSessionsSortKeyParamParameter.GamingRevenue;
      sorting.sortByOrder = newValue === 0 ? SortOrderParamParameter.ascending : SortOrderParamParameter.descending;
      sorting.gamingRevenueComparison =
        newValue === 0
          ? GameSessionsSummaryGamingRevenueComparisonParameter.LessThan
          : GameSessionsSummaryGamingRevenueComparisonParameter.GreaterThan;
      sorting.gamingRevenueComparisonValue = 0;
    } else {
      sorting.sortByKey = GameSessionsSortKeyParamParameter.Timestamp;
      sorting.sortByOrder = SortOrderParamParameter.descending;
      sorting.gamingRevenueComparison = GameSessionsSummaryGamingRevenueComparisonParameter.None;
    }

    setGameSessionsSorting(sorting);
    setSelectedTab(newValue);
  };

  const navigateToHandler = useCallback(() => {
    const searchParams = new URLSearchParams();
    searchParams.set(GameSummarySearchFilters.ACTIVE_TAB, '1');
    searchParams.set(GameSummarySearchFilters.PAGE, '0');
    searchParams.set(
      GameSummarySearchFilters.START_DATE,
      getUnixTime(isDashboardCard ? subHours(new Date(), 24) : subWeeks(new Date(), 1)).toString()
    );
    searchParams.set(GameSummarySearchFilters.END_DATE, getUnixTime(new Date()).toString());
    searchParams.set(GameSummarySearchFilters.SORT_BY_ORDER, gameSessionsSorting.sortByOrder);
    searchParams.set(GameSummarySearchFilters.SORT_BY_KEY, gameSessionsSorting.sortByKey);

    if (params?.userId) {
      searchParams.set(GameSummarySearchFilters.USER_ID, params.userId);
    }
    if (params?.gameId) {
      searchParams.set(GameSummarySearchFilters.GAME_ID, params.gameId);
    }
    searchParams.set(
      GameSummarySearchFilters.GAMING_REVENUE_COMPARISON,
      gameSessionsSorting.gamingRevenueComparison || GameSessionsSummaryGamingRevenueComparisonParameter.None
    );
    if (gameSessionsSorting?.gamingRevenueComparisonValue || gameSessionsSorting?.gamingRevenueComparisonValue === 0) {
      searchParams.set(
        GameSummarySearchFilters.GAMING_REVENUE_COMPARISON_VALUE,
        gameSessionsSorting.gamingRevenueComparisonValue.toString()
      );
    }

    const filtersQuery = '?' + searchParams.toString();

    navigate(`${isDashboardCard ? '../' : '../../'}lookup/gamesessionsoverview${filtersQuery}`);
  }, [
    gameSessionsSorting.gamingRevenueComparison,
    gameSessionsSorting.gamingRevenueComparisonValue,
    gameSessionsSorting.sortByKey,
    gameSessionsSorting.sortByOrder,
    isDashboardCard,
    navigate,
    params.gameId,
    params.userId,
  ]);

  return (
    <Card elevation={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader title={capitalize(title)} />
        <Button sx={{ mr: 1 }} variant="outlined" color="primary" size="small" onClick={navigateToHandler}>
          {t('seeMore')}
        </Button>
      </Box>
      <CardContent sx={{ pt: 0 }}>
        <Box mt={1} display="flex" justifyContent="center">
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs period">
            <Tab label={capitalize(t('gameSessions.titles.winning'))} id="tab-1" aria-controls="tab-panel-1" />
            <Tab label={capitalize(t('gameSessions.titles.losing'))} id="tab-2" aria-controls="tab-panel-2" />
            <Tab label={capitalize(t('gameSessions.titles.recent'))} id="tab-3" aria-controls="tab-panel-3" />
          </Tabs>
        </Box>
        <GameSessionsTableData
          cardType={cardType}
          userId={params?.userId || null}
          gameId={params?.gameId || null}
          startDate={isDashboardCard ? subHours(new Date(), 24) : subWeeks(new Date(), 1)}
          endDate={new Date()}
          sortOrder={gameSessionsSorting.sortByOrder}
          sortKey={gameSessionsSorting.sortByKey as GameSessionsSummarySortKeyParamParameter}
          gamingRevenueComparison={gameSessionsSorting.gamingRevenueComparison}
          gamingRevenueComparisonValue={gameSessionsSorting.gamingRevenueComparisonValue}
        />
      </CardContent>
    </Card>
  );
};

export default GameSessionCard;
