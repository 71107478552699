import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserRegistrationMessages } from '@greenisland/stores';

import { RegistrationMessagesTable } from './index';

const DEFAULT_REGISTRATION_MESSAGES_PAGESIZE = 50;

const RegistrationMessagesContainer = () => {
  const [pageSize, setPageSize] = useState<number>(DEFAULT_REGISTRATION_MESSAGES_PAGESIZE);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { userId = '' } = useParams();
  const { data, isLoading } = useGetUserRegistrationMessages(userId, {
    currentPageNumber: pageNumber,
    resultsPerPage: pageSize,
  });

  const { messages, messageCount } = data || {};

  return (
    <RegistrationMessagesTable
      messages={messages || []}
      total={messageCount || 0}
      pageNumber={pageNumber}
      pageSize={pageSize}
      changePage={setPageNumber}
      changePageSize={setPageSize}
      isLoading={isLoading}
    />
  );
};

export default RegistrationMessagesContainer;
