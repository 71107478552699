import { memo } from 'react';
import { Loading } from '@greenisland/components';
import { useAppSelector } from '@greenisland-core/store';

import HighLevelRouteContainer from '../HighLevelRouteContainer';

const LoginStatusCheck = () => {
  const { fetching } = useAppSelector(state => state.authentication);

  return fetching ? <Loading center /> : <HighLevelRouteContainer />;
};

export default memo(LoginStatusCheck);
