import { memo, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  DepositSchema,
  GetDepositsGeneralParams,
  GetWithdrawalsGeneralParams,
  useGetDepositsGeneral,
  useGetWithdrawalsGeneral,
  WithdrawalSchema,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Stack, Typography } from '@mui/material';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { DataGridContainer, DataGridPagination, StyledDataGrid } from '@greenisland-common/components/atoms';
import DateTimeSecondsField from '@greenisland-common/components/molecules/DateTimeSecondsInput';
import NumberInput from '@greenisland-common/components/molecules/NumberInput';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { usePermission } from '../../../../app/hooks';
import FlagDataDetails from '../../Lookup/Withdrawals/FlagDataDetails';
import useGetFraudDepositssHeaders from '../hooks/useGetFraudDepositsHeaders';
import useGetFraudWithdrawalsHeaders from '../hooks/useGetFraudWithdrawalsHeaders';
import SuspiciousDepositFlagsDetails from './SuspiciousDepositFlagsDetails';

interface SearchParams {
  userId: number;
  startDate?: Date;
  endDate?: Date;
}

const numberOfRowsPerPage = 7;

const FraudTransactions = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const canReadWithdrawalDetails = usePermission(OnlineCasinoPermissions.getWithdrawalDetailsV2);
  const canReadWithdrawals = usePermission(OnlineCasinoPermissions.getWithdrawalsGeneral);
  const canReadDepositDetails = usePermission(OnlineCasinoPermissions.getDepositDetails);
  const canReadDeposits = usePermission(OnlineCasinoPermissions.getDepositsGeneral);

  const [depositPage, setDepositPage] = useState(0);
  const [withdrawalPage, setWithdrawalPage] = useState(0);

  const defaultStart =
    searchParams.get('startdate') !== null
      ? new Date(searchParams.get('startdate')!)
      : new Date(Date.now() - 1000 * 60 * 60 * 24 * 7);
  const defaultEnd = searchParams.get('enddate') !== null ? new Date(searchParams.get('enddate')!) : new Date();
  const defaultUserId = searchParams.get('userid') !== null ? searchParams.get('userid') : null;

  const [searchDepositQuery, setSearchDepositQuery] = useState<GetDepositsGeneralParams | undefined>({
    start: defaultStart.getTime() / 1000,
    end: defaultEnd.getTime() / 1000,
    userId: defaultUserId ?? undefined,
  });
  const [searchWithdrawalQuery, setSearchWithdrawalQuery] = useState<GetWithdrawalsGeneralParams | undefined>({
    start: defaultStart.getTime() / 1000,
    end: defaultEnd.getTime() / 1000,
    userId: defaultUserId ?? undefined,
  });

  const methods = useForm<SearchParams>();
  const { control, handleSubmit } = methods;

  const onSubmit = (data: SearchParams) => {
    const start = data.startDate ? new Date(data.startDate).toISOString() : null;
    const end = data.endDate ? new Date(data.endDate).toISOString() : null;
    replaceUrlQueryParameters({ startdate: start, enddate: end, userid: data.userId }, setSearchParams);
    setSearchDepositQuery({
      userId: data.userId.toString(),
      start: data.startDate ? data.startDate.getTime() / 1000 : undefined,
      end: data.endDate ? data.endDate.getTime() / 1000 : undefined,
    });
    setSearchWithdrawalQuery({
      userId: data.userId.toString(),
      start: data.startDate ? data.startDate.getTime() / 1000 : undefined,
      end: data.endDate ? data.endDate.getTime() / 1000 : undefined,
    });
  };

  const { data: depositData, isLoading: areDepositsLoading } = useGetDepositsGeneral(
    {
      ...searchDepositQuery,
      fraudulent: true,
      numberOfItems: numberOfRowsPerPage,
      pageNumber: depositPage,
    },
    {
      query: {
        onError: (e: any) => enqueueSnackbar(e.Message, { variant: 'error' }),
        enabled: canReadDeposits,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    }
  );

  const { data: withdrawalData, isLoading: areWithdrawalsLoading } = useGetWithdrawalsGeneral(
    {
      ...searchWithdrawalQuery,
      fraudulent: true,
      numberOfItems: numberOfRowsPerPage,
      pageNumber: withdrawalPage,
    },
    {
      query: {
        onError: (e: any) => enqueueSnackbar(e.Message, { variant: 'error' }),
        enabled: canReadWithdrawals,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    }
  );
  const depositRows = useMemo(() => {
    return depositData?.items?.map(item => ({ ...item, id: item.pkeyInt })) ?? [];
  }, [depositData?.items]);

  const withdrawalRows = useMemo(() => {
    return withdrawalData?.items ?? [];
  }, [withdrawalData?.items]);

  const depositHeaders = useGetFraudDepositssHeaders();
  const withdrawalHeaders = useGetFraudWithdrawalsHeaders();

  const getDetailPanelContentDeposits: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: DepositSchema }) => <SuspiciousDepositFlagsDetails flags={row.suspiciousDepositFlags} />,
    []
  );

  const getDetailPanelContentWithdrawals: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: WithdrawalSchema }) => <FlagDataDetails flagData={row.flagData} />,
    []
  );

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ maxWidth: '100%', gridGap: '15px 15px', gridTemplateColumns: '1fr 1fr', marginBottom: '15px' }}
          >
            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
              <NumberInput
                minValue={1}
                name={'userId'}
                control={control}
                label={t('userId')}
                defaultValue={defaultUserId ?? ''}
              />
              <DateTimeSecondsField name={'startDate'} label={t('startDate')} defaultValue={defaultStart} />
              <DateTimeSecondsField name={'endDate'} label={t('endDate')} defaultValue={defaultEnd} />
              <Button sx={{ minWidth: '100px' }} size="large" variant="contained" type="submit">
                {t('search')}
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>

      <DataGridContainer>
        <StyledDataGrid
          initialState={{ columns: { columnVisibilityModel: { details: canReadDepositDetails } } }}
          density="compact"
          loading={areDepositsLoading}
          rows={depositRows}
          columns={depositHeaders}
          pagination
          page={depositPage}
          pageSize={numberOfRowsPerPage}
          paginationMode="server"
          onPageChange={p => {
            setDepositPage(p);
          }}
          showColumnRightBorder={false}
          disableDensitySelector
          disableSelectionOnClick
          rowCount={depositData?.pagingDetails?.totalItems ?? 0}
          components={{
            Pagination: DataGridPagination,
            Toolbar: () => <Typography>Deposits</Typography>,
          }}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContentDeposits}
        />
      </DataGridContainer>
      <DataGridContainer>
        <StyledDataGrid
          initialState={{ columns: { columnVisibilityModel: { details: canReadWithdrawalDetails } } }}
          density="compact"
          loading={areWithdrawalsLoading}
          rows={withdrawalRows}
          columns={withdrawalHeaders}
          pagination
          page={withdrawalPage}
          pageSize={numberOfRowsPerPage}
          paginationMode="server"
          onPageChange={p => {
            setWithdrawalPage(p);
          }}
          showColumnRightBorder={false}
          disableDensitySelector
          disableSelectionOnClick
          rowCount={withdrawalData?.pagingDetails?.totalItems ?? 0}
          components={{
            Pagination: DataGridPagination,
            Toolbar: () => <Typography>Withdrawals</Typography>,
          }}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContentWithdrawals}
        />
      </DataGridContainer>
    </>
  );
};

export default memo(FraudTransactions);
