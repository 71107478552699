import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FreeSpinSchema } from '@greenisland/stores/dist/api/schemas/freeSpinSchema';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  ErrorCard,
  FontAwesomeIcon,
  Link,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { getDateFromUnix, StatusSpan } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import useAccountData from '../../../hooks/useAccountData';
import { EventStreamParamsType } from '../Constants/Eventstreams';
import AwardFreeSpinsDialog from './AwardFreeSpinsDialog';
import CancelFreeSpinsDialog from './CancelFreeSpinsDialog';

type FreeSpinsProps = {
  userId: string;
};

interface FreeSpinsRowData extends FreeSpinSchema {
  id: number;
  gameName: string;
  actions?: React.ReactNode;
}

const FreeSpins = ({ userId }: FreeSpinsProps) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isCancelDialogOpen, setCancelDialog] = useState(false);
  const [freeSpins, setFreeSpins] = useState<FreeSpinSchema>();
  const canReadEventstream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canAwardFreeSpins = usePermission(OnlineCasinoPermissions.awardFreeSpins);
  const canCancelFreeSpins = usePermission(OnlineCasinoPermissions.cancelFreeSpins);
  const canReadFreeSpins = usePermission(OnlineCasinoPermissions.getUserFreeSpins);
  const { data: accountData, isLoading, isError, error, permission } = useAccountData(userId);

  const handleCancel = (freeSpinsInfo: FreeSpinSchema) => {
    setFreeSpins(freeSpinsInfo);
    setCancelDialog(true);
  };

  if (!permission) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={300} />;
  if (isError || !accountData) return <ErrorCard>{error?.message}</ErrorCard>;

  const headers: GridColumns<FreeSpinsRowData> = [
    {
      headerName: capitalize(t('createdOn')),
      field: 'createdOn',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
    },
    {
      headerName: capitalize(t('expiresOn')),
      field: 'expiresOn',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
    },
    { headerName: capitalize(t('source')), field: 'source', width: 150 },
    {
      headerName: capitalize(t('status')),
      field: 'status',
      renderCell: ({ value }) => <StatusSpan value={value} />,
      width: 140,
    },
    { headerName: capitalize(t('provider')), field: 'provider' },
    { headerName: capitalize(t('game')), field: 'gameName', width: 150 },
    { headerName: capitalize(t('amountOfSpins')), field: 'amountOfSpins' },
    { headerName: capitalize(t('stakePerSpin')), field: 'stakePerSpin', width: 130 },
    {
      headerName: '',
      field: 'actions',
      renderCell: params => {
        const canCancel = params.row.status === 'Awarded';
        return (
          <div>
            {params.row.actions}
            {canCancelFreeSpins && canCancel && (
              <Button variant="outlined" size="small" color="error" onClick={() => handleCancel(params.row)}>
                {t('lookup.freeSpins.cancelButton')}
              </Button>
            )}
          </div>
        );
      },
      align: 'left',
      width: 175,
    },
  ];

  const rows: GridRowsProp = accountData.freeSpins.map((item, i) => {
    const rowData: FreeSpinsRowData = {
      ...item,
      id: i,
      gameName: item.game?.name ?? '',
      actions: canReadEventstream ? (
        <Button
          variant="text"
          color="secondary"
          component={Link}
          to={`../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=${item.streamId.toLowerCase()}`}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ) : undefined,
    };
    return rowData;
  });

  return (
    <>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('freeSpins')} />
          <div>
            {canAwardFreeSpins && (
              <Button variant="outlined" color="secondary" size="small" onClick={() => setOpenDialog(true)}>
                {t('award')}
              </Button>
            )}
            {canReadFreeSpins && (
              <Button sx={{ ml: 2 }} variant="outlined" color="primary" size="small" component={Link} to="../freespins">
                {t('seeMore')}
              </Button>
            )}
          </div>
        </Box>
        <CardContent>
          {rows.length > 0 ? (
            <DataGridContainer>
              <StyledDataGrid autoHeight density="compact" columns={headers} rows={rows} pageSize={10} pagination />
            </DataGridContainer>
          ) : (
            <NoData />
          )}
        </CardContent>
      </Card>
      {openDialog && <AwardFreeSpinsDialog openDialog={openDialog} setOpenDialog={setOpenDialog} userId={userId} />}
      {freeSpins && (
        <CancelFreeSpinsDialog
          freeSpins={freeSpins}
          isDialogOpen={isCancelDialogOpen}
          toggleDialog={setCancelDialog}
          userId={accountData.account.accountId}
        />
      )}
    </>
  );
};

export default memo(FreeSpins);
