import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventModelSchema } from '@greenisland/stores';
import { Box, Stack, Typography } from '@mui/material';
import { prettyPrintJson } from 'pretty-print-json';

interface Props {
  event: EventModelSchema;
}

const RegistrationEventDetailPanel = ({ event }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{ justifyContent: 'justify-between', overflowY: 'hidden', overflowX: 'auto', height: '100%' }}
      direction="row"
      spacing={2}
    >
      {event?.payload ? (
        <Stack direction="column">
          <Typography>{t('tasks.verifications.registrationEvent.payload')}</Typography>
          <Box
            component="pre"
            sx={{
              overflow: 'auto',
              '& .json-string': {
                whiteSpace: 'normal',
              },
            }}
            // the typing from swagger is not correct
            dangerouslySetInnerHTML={{
              __html: prettyPrintJson.toHtml(JSON.parse(event.payload as unknown as string), { linkUrls: true }),
            }}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};

export default RegistrationEventDetailPanel;
