import { Authorization } from '@greenisland/stores';
import { ReadPermission, WritePermission } from '@greenisland-core/permissions';

// TODO need to generate current file with a similar command (”npm run permissions:onlinecasino") for game server permission. https://greenisland.atlassian.net/browse/OCPC-4389
export const GameServerPermissions = {
  getTournamentConfigurations: new ReadPermission('/tournament-configurations', Authorization.EProductType.GameServer),
  setTournamentConfigurations: new WritePermission('/tournament-configurations', Authorization.EProductType.GameServer),
  addTournamentConfigurations: new WritePermission('/tournament-configurations', Authorization.EProductType.GameServer),
  deleteTournamentConfigurations: new WritePermission(
    '/tournament-configurations',
    Authorization.EProductType.GameServer
  ),

  getTournaments: new ReadPermission('/tournaments', Authorization.EProductType.GameServer),

  getGames: new ReadPermission('/games', Authorization.EProductType.GameServer),
  setGames: new WritePermission('/games', Authorization.EProductType.GameServer),
  addGames: new WritePermission('/games', Authorization.EProductType.GameServer),
  deleteGames: new WritePermission('/games', Authorization.EProductType.GameServer),

  getJackpotConfigurations: new ReadPermission('/jackpot-configurations', Authorization.EProductType.GameServer),
  getJackpotInstances: new ReadPermission('/jackpot-instances', Authorization.EProductType.GameServer),

  getOperator: new ReadPermission('/operator', Authorization.EProductType.GameServer),
  setOperator: new WritePermission('/operator', Authorization.EProductType.GameServer),
};

export type GameServerPermissionTypes = keyof typeof GameServerPermissions;
