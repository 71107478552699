import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ESex, VerificationsSchemaIdentity } from '@greenisland/stores';
import { MenuItem, TextField } from '@mui/material';

interface Props {
  identity: VerificationsSchemaIdentity;
}

const SexField = ({ identity }: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const menuItems = Object.values(ESex).map(sex => ({ value: sex, label: t(sex) }));

  return (
    <Controller
      name="sex"
      control={control}
      defaultValue={identity.sex}
      render={({ value, onChange }) => (
        <TextField fullWidth size="small" select value={value} onChange={onChange}>
          {menuItems.map(el => (
            <MenuItem key={el.value} value={el.value}>
              {el.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default SexField;
