import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetActiveDepositLimitsForUser } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { formatCurrency } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import { DataGridContainer, DateTime, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

type DepositLimitsProps = {
  userId: string;
};

const ActiveLimitsTable = ({ userId }: DepositLimitsProps) => {
  const { t } = useTranslation();
  const canReadDepositLimits = usePermission(OnlineCasinoPermissions.getActiveDepositLimitsForUser);
  const {
    data: activeDepositsLimits,
    isLoading,
    isError,
    error,
  } = useGetActiveDepositLimitsForUser(parseInt(userId, 10), { query: { enabled: canReadDepositLimits } });

  const headers: GridColumns = [
    {
      headerName: t('startDate'),
      field: 'startDate',
      renderCell: ({ row }) => (row.startDate ? <DateTime dateTime={row.startDate} /> : <></>),
      flex: 1,
      minWidth: 160,
    },
    { headerName: t('period'), field: 'period', flex: 1, minWidth: 80 },
    {
      headerName: t('value'),
      field: 'value',
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) => (row.value ? `${formatCurrency(row.currentValue)} / ${formatCurrency(row.value)}` : ''),
    },
  ];

  const rows: GridRowsProp = useMemo(() => {
    return [
      ...(activeDepositsLimits?.dailyLimit ? [{ ...activeDepositsLimits?.dailyLimit, id: 0 }] : []),
      ...(activeDepositsLimits?.weeklyLimit ? [{ ...activeDepositsLimits?.weeklyLimit, id: 1 }] : []),
      ...(activeDepositsLimits?.monthlyLimit ? [{ ...activeDepositsLimits?.monthlyLimit, id: 2 }] : []),
    ];
  }, [activeDepositsLimits?.dailyLimit, activeDepositsLimits?.monthlyLimit, activeDepositsLimits?.weeklyLimit]);

  return (
    <>
      <PermissionWrapper
        errorMessage={error?.message}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getActiveDepositLimitsForUser}
      >
        <DataGridContainer>
          <StyledDataGrid columns={headers} rows={rows} hideFooter rowCount={3} />
        </DataGridContainer>
      </PermissionWrapper>
    </>
  );
};

export default memo(ActiveLimitsTable);
