import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ComplaintTypeParamParameter,
  EComplaintStatusSchema,
  EComplaintTypeSchema,
  GetAllComplaintTicketsParams,
} from '@greenisland/stores';
import { ComplaintStatusParamParameter } from '@greenisland/stores/dist/api/schemas/complaintStatusParamParameter';
import { useDebouncedValue } from '@lilib/hooks';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Chip, Divider, InputBase, Paper, Stack, Typography } from '@mui/material';

import FilterMenu, { FilterMenuOption } from '@greenisland-common/components/molecules/FilterMenu/FilterMenu';

const getType = (type: string): string => {
  switch (type) {
    case 'type':
      return 'tasks.complaints.type';
    case 'status':
      return 'tasks.complaints.status';
    default:
      return '';
  }
};

interface ChipData {
  id: string;
  label: string;
  type: string;
}

type Filters = Omit<GetAllComplaintTicketsParams, 'numberOfItems' | 'pageNumber'>;

interface Props {
  onChange: (filters: Filters) => void;
}

const ComplaintsFilter = ({ onChange }: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [chips, setChips] = useState<ChipData[]>([]);

  const [debouncedChips] = useDebouncedValue(chips, { wait: 500 });
  const [debouncedSearch] = useDebouncedValue(search, { wait: 500 });

  const params = useMemo(() => {
    const type = debouncedChips.filter(chip => chip.type === 'type').map(c => c.id);
    const status = debouncedChips.filter(chip => chip.type === 'status').map(c => c.id);
    let search = {};
    if (debouncedSearch.length > 0) {
      const userIdMatch = debouncedSearch.match(/user:(\d+)/);
      if (userIdMatch) {
        search = { complaintUserId: parseInt(userIdMatch[1], 10) };
      } else {
        search = { complaintTitle: debouncedSearch };
      }
    }
    return {
      ...search,
      ...(type.length > 0 ? { complaintType: type[0] as ComplaintTypeParamParameter } : {}),
      ...(status.length > 0 ? { complaintStatus: status as ComplaintStatusParamParameter } : {}),
    };
  }, [debouncedChips, debouncedSearch]);

  useEffect(() => {
    onChange(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChips(chips => chips.filter(chip => chip.id !== chipToDelete.id));
  };

  const handleSelect = (type: string, selection: FilterMenuOption & { selected?: boolean }) => {
    if (selection.selected) {
      setChips(chips => [...chips, { type, ...selection }]);
      return;
    }
    setChips(chips => chips.filter(chip => chip.id !== selection.id));
  };

  const selectedType = useMemo(() => chips.filter(chip => chip.type === 'type').map(c => c.id), [chips]);
  const selectedStatus = useMemo(() => chips.filter(chip => chip.type === 'status').map(c => c.id), [chips]);

  return (
    <Paper sx={{ backgroundColor: 'white' }} variant="outlined" elevation={0}>
      <Box display="flex" alignItems="center" flexWrap="wrap" paddingX={2} paddingY={1}>
        <SearchIcon />
        <Box ml={3} flexGrow={1}>
          <InputBase
            placeholder={t('tasks.complaints.searchPlaceholder')}
            fullWidth
            onChange={e => setSearch(e.target.value.trim())}
          />
        </Box>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" flexWrap="wrap" paddingX={2} paddingY={1}>
        {chips.length === 0 ? (
          <Typography variant="subtitle2" textTransform="initial">
            {t('tasks.complaints.noFilter')}
          </Typography>
        ) : (
          <Stack component="div" direction="row" flexWrap="wrap" gap={1}>
            {chips.map(c => (
              <Chip
                key={c.id}
                label={
                  <span>
                    <strong>{t(getType(c.type))}:</strong> {c.label}
                  </span>
                }
                variant="outlined"
                onDelete={handleDelete(c)}
              />
            ))}
          </Stack>
        )}
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" flexWrap="wrap" padding={1}>
        <FilterMenu
          label={t('tasks.complaints.type')}
          options={
            Object.values(EComplaintTypeSchema)
              .map(t => ({ id: t, label: t }))
              .sort((a, b) => {
                return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' });
              }) ?? []
          }
          selected={selectedType}
          onClick={selected => handleSelect('type', selected)}
        />
        <FilterMenu
          label={t('tasks.complaints.status')}
          options={
            Object.values(EComplaintStatusSchema)
              .map(t => ({ id: t, label: t }))
              .sort((a, b) => {
                return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' });
              }) ?? []
          }
          selected={selectedStatus}
          onClick={selected => handleSelect('status', selected)}
          multiple
        />
      </Box>
    </Paper>
  );
};

export default ComplaintsFilter;
