import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  ETransactionsBadRequestTypeSchema,
  TransactionV2Schema,
  useAdvancedUserSearchV2Hook,
  useGetTransactionsGeneral,
} from '@greenisland/stores';
import { GetTransactionsGeneralParams } from '@greenisland/stores/dist/api/schemas/getTransactionsGeneralParams';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { GridColumns, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { DataGridContainer, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { formatCurrency } from '@greenisland-common/helpers/formatters';

import { getDateTimeFromUnix } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import {
  TransactionParamsType,
  TRANSACTIONS_ERROR_REASONS,
} from '../Components/DetailsComponents/Constants/Transactions';
import useGetTransactionFiltersQuery from '../hooks/useGetTransactionFiltersQuery';
import TransactionFilters from './TransactionFilters';

type Row = TransactionV2Schema;

const PAGE = 0;
const PAGE_SIZE = 25;

const Transactions = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const updateSearchParamsHandler = useUpdateSearchParams();
  const advancedUserSearchV2 = useAdvancedUserSearchV2Hook();
  const canLookupUser = usePermission(OnlineCasinoPermissions.advancedUserSearchV2);

  const {
    startDateQuery,
    endDateQuery,
    minAmountQuery,
    maxAmountQuery,
    minPromoAmountQuery,
    maxPromoAmountQuery,
    userIdQuery,
    accountIdQuery,
    transactionIdQuery,
    gameProviderNameQuery,
    gameIdQuery,
    gameNameQuery,
    typeQuery,
    sortByQuery,
    isAscendingQuery,
    pageQuery,
    pageSizeQuery,
  } = useGetTransactionFiltersQuery();

  const params = useMemo(
    () => ({
      ...(startDateQuery && startDateQuery?.length > 0 ? { start: Number(startDateQuery) } : {}),
      ...(endDateQuery && endDateQuery?.length > 0 ? { end: Number(endDateQuery) } : {}),
      ...(typeQuery && typeQuery?.length > 0 ? { type: typeQuery } : {}),
      ...(userIdQuery && userIdQuery?.length > 0 ? { userId: userIdQuery } : {}),
      ...(accountIdQuery && accountIdQuery?.length > 0 ? { accountId: accountIdQuery } : {}),
      ...(gameProviderNameQuery && gameProviderNameQuery?.length > 0
        ? { gameProviderName: gameProviderNameQuery }
        : {}),
      ...(gameIdQuery && gameIdQuery?.length > 0 ? { gameId: gameIdQuery } : {}),
      ...(gameNameQuery && gameNameQuery?.length > 0 ? { gameName: gameNameQuery } : {}),
      ...(transactionIdQuery && transactionIdQuery?.length > 0 ? { txId: Number(transactionIdQuery) } : {}),
      ...(minAmountQuery && minAmountQuery?.length > 0 ? { minAmount: Number(minAmountQuery) } : {}),
      ...(maxAmountQuery && maxAmountQuery?.length > 0 ? { maxAmount: Number(maxAmountQuery) } : {}),
      ...(minPromoAmountQuery && minPromoAmountQuery?.length > 0
        ? { minPromoAmount: Number(minPromoAmountQuery) }
        : {}),
      ...(maxPromoAmountQuery && maxPromoAmountQuery?.length > 0
        ? { maxPromoAmount: Number(maxPromoAmountQuery) }
        : {}),
      ...(sortByQuery && sortByQuery?.length > 0 ? { sortBy: sortByQuery } : {}),
      ...(isAscendingQuery && isAscendingQuery?.length > 0 ? { ascending: isAscendingQuery } : {}),
      pageNumber: pageQuery ? Number(pageQuery) : 0,
      numberOfItems: pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE,
    }),
    [
      accountIdQuery,
      endDateQuery,
      gameIdQuery,
      gameNameQuery,
      gameProviderNameQuery,
      isAscendingQuery,
      maxAmountQuery,
      maxPromoAmountQuery,
      minAmountQuery,
      minPromoAmountQuery,
      pageQuery,
      pageSizeQuery,
      sortByQuery,
      startDateQuery,
      transactionIdQuery,
      typeQuery,
      userIdQuery,
    ]
  );

  const {
    data: transactions,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetTransactionsGeneral(params as GetTransactionsGeneralParams, {
    query: {
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const translationKey =
              TRANSACTIONS_ERROR_REASONS[error?.type as ETransactionsBadRequestTypeSchema] ?? 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  useEffect(() => {
    if (searchParams?.toString()?.length) {
      refetch();
    }
  }, [pageQuery, pageSizeQuery, isAscendingQuery, sortByQuery]);

  const columns = useMemo<GridColumns<TransactionV2Schema>>(() => {
    const handleAccountIdClick = (accountId: number) => {
      advancedUserSearchV2({ accountId: accountId }).then(res =>
        navigate(`../../users/${res.results?.at(0)?.userId}/details`)
      );
    };

    return transactions?.items?.at(0)
      ? Object.keys(transactions?.items[0])?.map(key => {
          switch (key) {
            case 'timestamp':
              return {
                headerName: t('timestamp'),
                field: 'timestamp',
                type: 'date',
                flex: 0.1,
                minWidth: 150,
                description: t('theRequestTime'),
                headerAlign: 'center',
                align: 'center',
                renderCell: params => {
                  const timestamp = params.value;
                  if (timestamp) {
                    const timestampDay = getDateTimeFromUnix(timestamp).split(' ')[0];
                    const timestampHour = getDateTimeFromUnix(timestamp).split(' ')[1];
                    return (
                      <Box>
                        <Typography variant="body2" fontFamily="inherit">
                          {timestampDay}
                        </Typography>
                        <Typography variant="body2" color="GrayText" fontFamily="inherit" lineHeight={1}>
                          {timestampHour}
                        </Typography>
                      </Box>
                    );
                  }
                  return <Typography variant="body2">-</Typography>;
                },
              };
            case 'accountId':
              return {
                headerName: t(key),
                field: key,
                flex: 0.1,
                minWidth: 150,
                renderCell: (params: GridRenderCellParams<string, TransactionV2Schema>) => {
                  return canLookupUser ? (
                    <span
                      onClick={() => handleAccountIdClick(parseInt(params.row.accountId ?? ''))}
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    >
                      {params.row.accountId}
                    </span>
                  ) : (
                    <span>{params.row.accountId}</span>
                  );
                },
                headerAlign: 'center',
                align: 'center',
              };
            case 'promoAmount':
            case 'promoBalance':
            case 'amount':
            case 'balance':
              return {
                headerName: t(key),
                field: key,
                type: 'number',
                valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
                flex: 0.1,
                minWidth: 150,
                headerAlign: 'center',
                align: 'center',
              };
            case 'description':
              return {
                headerName: t('description'),
                field: 'description',
                flex: 0.2,
                minWidth: 250,
                renderCell: ({ value }) => (
                  <Tooltip title={value}>
                    <Typography
                      variant="body2"
                      fontFamily="inherit"
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {value}
                    </Typography>
                  </Tooltip>
                ),
              };
            default:
              return {
                headerName: t(key),
                field: key,
                flex: 0.1,
                minWidth: 150,
                headerAlign: 'center',
                align: 'center',
              };
          }
        })
      : [];
  }, [transactions?.items, advancedUserSearchV2, navigate, t, canLookupUser, i18n.language]);

  const rows = useMemo(
    () =>
      transactions?.items?.map<Row>(transaction => ({
        ...transaction,
      })) ?? [],
    [transactions?.items]
  );

  return (
    <Stack spacing={2}>
      <TransactionFilters fetchTransactionsHandler={refetch} />
      <PermissionWrapper
        errorMessage={t('lookup.transactions.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getTransactionsGeneral}
      >
        <DataGridContainer>
          <LimitedDataGrid
            density="compact"
            autoHeight
            loading={isLoading || isFetching}
            rows={rows}
            columns={columns}
            pagination
            page={pageQuery ? Number(pageQuery) : PAGE}
            pageSize={pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE}
            paginationMode="server"
            onPageChange={page => updateSearchParamsHandler(TransactionParamsType.PAGE, page.toString())}
            componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            onPageSizeChange={pageSize =>
              updateSearchParamsHandler(TransactionParamsType.PAGE_SIZE, pageSize.toString())
            }
            rowCount={transactions?.pagingDetails?.totalItems ?? 0}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </Stack>
  );
};

export default Transactions;
