import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, CheckData, Link, ReactTableGI } from '@greenisland/components';
import { useGetOnlineDepositsForUser } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getUnixTime, startOfDay } from 'date-fns';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  setInvisible,
  transformBoolean,
  transformCurrency,
  transformUnixDate,
} from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { transformToCSVDeposits } from '../AccountData/helpers/TransformToCSV';

const depositStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridGap: '15px 15px',
      marginBottom: 15,
    },
    green: { color: 'green' },
    red: { color: 'red' },
    orange: { color: 'orange' },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  })
);

const Deposits = () => {
  const canReadUserDeposits = usePermission(OnlineCasinoPermissions.getOnlineDepositsForUser);
  return canReadUserDeposits ? <DepositsInnerChild /> : null;
};

const DepositsInnerChild = () => {
  const { t } = useTranslation();
  const classes = depositStyles();
  const { userId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const canReadDepositDetails = usePermission(OnlineCasinoPermissions.getDepositDetails);
  const canReadUserEventstream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canReadUserDeposits = usePermission(OnlineCasinoPermissions.getOnlineDepositsForUser);

  const startDate = searchParams.get('startdate')
    ? Number(searchParams.get('startdate'))
    : getUnixTime(startOfDay(new Date()));
  const endDate = searchParams.get('enddate') ? Number(searchParams.get('enddate')) : getUnixTime(new Date());

  const { data: deposits, isLoading } = useGetOnlineDepositsForUser(
    parseInt(userId),
    {
      start: startDate,
      end: endDate,
      top: 1000,
    },
    { query: { enabled: canReadUserDeposits } }
  );

  const calculateStatusColor = (state: string): string | undefined => {
    if (state.toLowerCase() === 'declined') return classes.red;
    if (state.toLowerCase() === 'started') return classes.orange;
    if (state.toLowerCase() === 'completedmanually') return classes.green;
  };

  const mutatedData = () => {
    if (!deposits || deposits.length === 0) return undefined;

    const keys = Object.keys(deposits[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'userId':
        case 'depositId':
          return setInvisible(key);
        case 'timestamp':
          return transformUnixDate(key, t, true);
        case 'username':
          return setInvisible(key);
        case 'amount':
          return transformCurrency(key, t);
        case 'status':
          return {
            Cell: ({ value }: any) => <span className={calculateStatusColor(value)}>{value}</span>,
            Header: t(key),
            accessor: key,
          };
        case 'depositBonus':
          return { Header: t(key), accessor: key, disableSortBy: true };
        case 'onAccount':
          return transformBoolean(key, t);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    headers.push({ id: 'details', accessor: 'details', disableFilter: true, disableSortBy: true });

    const rows = deposits.map(item => {
      return {
        ...item,
        details: canReadDepositDetails ? <Link to={`${item.depositId}`}>{t('details')}</Link> : undefined,
        depositBonus:
          canReadUserEventstream && item.depositBonus ? (
            <Link to={`../../../reporting/events/stream?streamid=user/${item.userId}`}>{t('stream')}</Link>
          ) : (
            <span>{item.depositBonus ? t('yes') : t('no')}</span>
          ),
      };
    });

    return { headers, rows };
  };

  const download = (content: string, fileName: string, mimeType: string) => {
    const a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (URL && 'download' in a) {
      a.href = URL.createObjectURL(new Blob([content], { type: mimeType }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
  };

  const handleDownload = () => {
    if (deposits) {
      const csv = transformToCSVDeposits(deposits);
      if (csv) {
        download(csv, `deposits_${userId}_${startDate}_${endDate}.csv`, 'text/csv;encoding:utf-8');
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        <DateRangePicker
          unixStartDate={Number(searchParams.get('startdate')) * 1000}
          unixEndDate={Number(searchParams.get('enddate')) * 1000}
          onChange={(startDate, endDate) => {
            replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
          }}
        />
      </div>
      <div className={classes.container}>
        <div>
          <Button disabled={!deposits || !deposits?.length} variant="outlined" size="small" onClick={handleDownload}>
            {t('exportToCSV')}
          </Button>
        </div>
        <Card>
          <CardBody>
            <CheckData
              fallbackText={t('noData')}
              data={mutatedData()}
              isLoading={isLoading}
              checkProperties={['headers', 'rows']}
              render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default memo(Deposits);
