import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AcurisIndividualProfilePepEntriesSchema } from '@greenisland/stores';
import { Typography } from '@mui/material';

import KYCPEPEntries from './KYCPEPEntries';

interface Props {
  pepEntries: AcurisIndividualProfilePepEntriesSchema | undefined;
}

const KYCPEPTable = ({ pepEntries }: Props) => {
  const { t } = useTranslation();

  if (!pepEntries || (pepEntries?.current?.length === 0 && pepEntries?.former?.length === 0)) {
    return null;
  }

  return (
    <>
      <Typography variant="h5">
        {t('kyc.resource.pepEntries')}
        {pepEntries.pepTier &&
          pepEntries.pepTier !== 'Unknown' &&
          ` - ${t('kyc.resource.tier')}: ${pepEntries.pepTier}`}
      </Typography>
      {pepEntries?.current && pepEntries?.current?.length > 0 && (
        <>
          <Typography>{t('kyc.resource.current')}</Typography>
          <KYCPEPEntries pepEntries={pepEntries.current}></KYCPEPEntries>
        </>
      )}
      {pepEntries?.former && pepEntries?.former?.length > 0 && (
        <>
          <Typography>{t('kyc.resource.former')}</Typography>
          <KYCPEPEntries pepEntries={pepEntries.former}></KYCPEPEntries>
        </>
      )}
    </>
  );
};

export default memo(KYCPEPTable);
