import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getGetGamesByQueryFiltersQueryKey, useUpdateGamesV2Order } from '@greenisland/stores';
import { useSnackbar } from 'notistack';

export default function useUpdateGamesOrder() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useUpdateGamesV2Order({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('content.games.games.orderUpdated'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('content.games.games.orderError'), { variant: 'error' });
      },
      onSettled: () => queryClient.invalidateQueries(getGetGamesByQueryFiltersQueryKey()),
    },
  });
}
