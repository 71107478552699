import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@greenisland/components';
import { Box } from '@mui/material';

import MarkAsSafeDialog from '../../../../Lookup/Components/DetailsComponents/Deposits/MarkAsSafeWithCredentialsDialog';

interface Props {
  userId: string;
  depositId: string;
}

const SuspiciousDepositAction = ({ userId, depositId }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Button size="small" error onClick={() => setIsOpen(true)} variant="contained">
        {t('deposits.actions.markAsSafe')}
      </Button>
      <MarkAsSafeDialog userId={userId} isOpen={isOpen} onClose={() => setIsOpen(false)} depositId={depositId} />
    </Box>
  );
};

export default memo(SuspiciousDepositAction);
