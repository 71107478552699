import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { getGetPaymentMethodsQueryKey, useGetPaymentMethod, useUpdatePaymentMethod } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useSnackbar } from 'notistack';

import { usePermission } from '../../../../../../app/hooks';
import PaymentMethodForm from './PaymentMethodForm';

const EditPaymentMethod = () => {
  const { t } = useTranslation();
  const { paymentMethodId }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const canEditPaymentMethod = usePermission(OnlineCasinoPermissions.updatePaymentMethod);

  const { data: paymentMethod, isLoading: paymentMethodIsLoading } = useGetPaymentMethod(paymentMethodId);

  const { mutate: updatePaymentMethod, isLoading: updateIsLoading } = useUpdatePaymentMethod({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetPaymentMethodsQueryKey());
        navigate('../payment-methods');
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  return (
    <PaymentMethodForm
      canSubmitForm={canEditPaymentMethod}
      loading={updateIsLoading || paymentMethodIsLoading}
      defaultPaymentMethod={paymentMethod}
      handleSubmitForm={data => updatePaymentMethod({ paymentMethodId: paymentMethodId, data })}
    />
  );
};

export default memo(EditPaymentMethod);
