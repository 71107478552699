import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  ErrorSchema,
  getGetDepositLimitsThatRequireManualApprovalQueryKey,
  useApproveDepositLimitUpdateRequestManually,
  useDeclineDepositLimitUpdateRequestManually,
  UserLimitUpdateRequestManualDecisionArgumentsSchema,
  UserLimitUpdateRequestWithReferenceSchema,
} from '@greenisland/stores';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon, SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

interface UserLimitApprovalFormSchema {
  decision: string;
  additionalData: string;
}

enum DecisionType {
  Approved = 'Approved',
  Declined = 'Declined',
}

interface Props {
  onClose: () => void;
  chosenApprovalDecisionRequest: UserLimitUpdateRequestWithReferenceSchema;
}

const UserLimitsApprovalDecisionFormDialog = ({ onClose, chosenApprovalDecisionRequest }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const methods = useForm<UserLimitApprovalFormSchema>({
    shouldFocusError: true,
    mode: 'onChange',
  });

  const { handleSubmit, control, reset } = methods;

  const onSuccessHandler = () => {
    queryClient.invalidateQueries(getGetDepositLimitsThatRequireManualApprovalQueryKey());
    enqueueSnackbar(t('success'), { variant: 'success' });
    onClose();
    reset();
  };

  const onErrorHandler = (error: ErrorSchema) => {
    enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
  };

  const {
    mutate: approveDepositLimitUpdateRequestManually,
    isLoading: isApproveDepositLimitUpdateRequestManuallyLoading,
  } = useApproveDepositLimitUpdateRequestManually({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: (error: ErrorSchema) => onErrorHandler(error),
    },
  });

  const {
    mutate: declineDepositLimitUpdateRequestManually,
    isLoading: isDeclineDepositLimitUpdateRequestManuallyLoading,
  } = useDeclineDepositLimitUpdateRequestManually({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: (error: ErrorSchema) => onErrorHandler(error),
    },
  });

  const onSubmit = (data: UserLimitApprovalFormSchema) => {
    const payload: UserLimitUpdateRequestManualDecisionArgumentsSchema = {
      additionalData: data?.additionalData,
      reference: chosenApprovalDecisionRequest?.reference,
    };

    if (chosenApprovalDecisionRequest?.depositLimitUpdateRequest?.id) {
      if (data?.decision === DecisionType.Approved) {
        return approveDepositLimitUpdateRequestManually({
          requestId: chosenApprovalDecisionRequest?.depositLimitUpdateRequest?.id,
          data: payload,
        });
      } else {
        return declineDepositLimitUpdateRequestManually({
          requestId: chosenApprovalDecisionRequest?.depositLimitUpdateRequest?.id,
          data: payload,
        });
      }
    }
  };

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle>{t('tasks.userLimits.titles.approvalDecision')}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Box display="flex" flexDirection="column">
                <SelectInput
                  size="small"
                  name="decision"
                  label={capitalize(t('common.decision'))}
                  rules={{ required: t('fieldIsRequired') }}
                  options={[`${DecisionType.Approved}`, `${DecisionType.Declined}`].map(key => ({
                    value: key,
                    label: capitalize(t(key)),
                  }))}
                  defaultValue={`${DecisionType.Approved}`}
                  required={true}
                  clearSelect={true}
                  sx={{ mb: 2 }}
                />
                <Input
                  multiline
                  size="small"
                  label={capitalize(t('tasks.userLimits.titles.comments'))}
                  control={control}
                  type="text"
                  name="additionalData"
                  defaultValue=""
                  rules={{
                    required: t('fieldIsRequired'),
                    maxLength: {
                      value: 255,
                      message: t('common.descriptionMaxLengthError'),
                    },
                  }}
                  minRows={5}
                  required={true}
                  sx={{ mb: 2 }}
                />
              </Box>
            </Stack>
            <DialogActions>
              <Button variant="text" onClick={onClose} color="secondary">
                {t('cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                loading={
                  isApproveDepositLimitUpdateRequestManuallyLoading || isDeclineDepositLimitUpdateRequestManuallyLoading
                }
              >
                {t('common.submit')}
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default UserLimitsApprovalDecisionFormDialog;
