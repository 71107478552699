import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateTestUserRequestSchema } from '@greenisland/stores';
import { Box, capitalize, Checkbox, FormControlLabel } from '@mui/material';
import { getUnixTime } from 'date-fns';

import { DATE_INPUT_FORMAT, DATE_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';
import DateTimeField from '@greenisland-common/components/molecules/DateTimeInput';

import CollapseSectionWrapper from './CollapseSectionWrapper';

const TestUserBirthdateSettings = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<CreateTestUserRequestSchema>();

  const [isBirthdayChosen, setIsBirthdayChosen] = useState(false);

  useEffect(() => {
    if (!isBirthdayChosen) {
      setValue('birthDate', null);
    }
  }, [isBirthdayChosen, setValue]);

  return (
    <CollapseSectionWrapper title={t('birthdate')}>
      <Box>
        <FormControlLabel
          control={<Checkbox color="primary" size="medium" />}
          onChange={(_, value) => setIsBirthdayChosen(value)}
          label={t('common.chooseBirthdate')}
          checked={isBirthdayChosen}
          sx={{ '.MuiTypography-root': { fontWeight: isBirthdayChosen ? 600 : 400 }, mb: 2 }}
        />
        <DateTimeField
          name="birthDate"
          label={capitalize(t('birthdate'))}
          rules={
            isBirthdayChosen
              ? { required: t('fieldIsRequired'), valueAsNumber: true, setValueAs: value => getUnixTime(value) }
              : {}
          }
          disabled={!isBirthdayChosen}
          defaultValue={null}
          inputFormat={DATE_INPUT_FORMAT}
          mask={DATE_INPUT_MASK}
        />
      </Box>
    </CollapseSectionWrapper>
  );
};

export default TestUserBirthdateSettings;
