import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EAgentTaskCategorySchema } from '@greenisland/stores';

import CheckboxField from '@greenisland-common/components/molecules/CheckboxField';

import AgentTasksTableData from './AgentTasksTableData';

const AGENT_TASK_CATEGORIES_KEY = 'agentTaskCategoriesKey';

type FormValues = {
  [key in keyof typeof EAgentTaskCategorySchema]: boolean;
};

const AgentTasksCategoryView = () => {
  const categories = Object.keys(EAgentTaskCategorySchema) as Array<keyof typeof EAgentTaskCategorySchema>;
  const savedCategories = localStorage.getItem(AGENT_TASK_CATEGORIES_KEY);
  const defaultValues = savedCategories
    ? JSON.parse(savedCategories)
    : categories.reduce((acc, category) => {
        return { ...acc, [category]: true };
      }, {} as FormValues);

  const { control, watch } = useForm<FormValues>({
    defaultValues,
  });
  const watchedValues = watch();

  useEffect(() => {
    localStorage.setItem(AGENT_TASK_CATEGORIES_KEY, JSON.stringify(watchedValues));
  }, [watchedValues]);

  return (
    <>
      {categories.map((category, index) => (
        <CheckboxField name={category} label={category} control={control} key={index} />
      ))}
      {Object.keys(watchedValues).map((value, index) =>
        watchedValues[value as keyof FormValues] ? (
          <div key={index}>
            <b> {value} </b>
            <AgentTasksTableData categories={[value as EAgentTaskCategorySchema]} defaultPageSize={10} />
          </div>
        ) : null
      )}
    </>
  );
};

export default memo(AgentTasksCategoryView);
