import '../../../../../../common/helpers/prettyPrintJson/prettyPrintCss.css';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader, CheckData, Link, ReactTableGI } from '@greenisland/components';
import {
  EWithdrawalStatusSchema,
  getGetWithdrawalDetailsV2QueryKey,
  useCancelFailedWithdrawalV2,
  useCompleteWithdrawalManuallyV2,
  useDeclineWithdrawalV2,
  useFailWithdrawalV2,
  useGetWithdrawalDetailsV2,
  useProcessWithdrawalsV2,
  useRetryWithdrawalV2,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Stack, Tab, Tabs } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import { TabPanel } from '@greenisland-common/components/atoms';

import { prettyPrintJson } from '@greenisland-common/helpers';

import { formatCurrency, transformUnixDate } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../LookupPermissions';
import DeclineWithdrawalDialog from './DeclineWithdrawalDialog';
import classes from './WithdrawalDetails.module.css';

const WithdrawalDetails = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { userId = '', withdrawalId = '' } = useParams();
  const [stream, setStream] = useState<number>(0);
  const [declineDialogOpen, setDeclineDialogOpen] = useState<boolean>(false);
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canCompleteWithdrawal = usePermission(OnlineCasinoPermissions.completeWithdrawalManuallyV2);
  const canFailWithdrawal = usePermission(OnlineCasinoPermissions.failWithdrawalV2);
  const canDeclineWithdrawal = usePermission(OnlineCasinoPermissions.declineWithdrawalV2);
  const canCancelWithdrawal = usePermission(OnlineCasinoPermissions.cancelFailedWithdrawalV2);
  const canProcessWithdrawal = usePermission(OnlineCasinoPermissions.processWithdrawalsV2);
  const canRetryWithdrawal = usePermission(OnlineCasinoPermissions.retryWithdrawalV2);
  const canReadWithdrawalDetails = usePermission(OnlineCasinoPermissions.getWithdrawalDetailsV2);
  const { data: details, isLoading: isLoadingDetails } = useGetWithdrawalDetailsV2(parseInt(withdrawalId), undefined, {
    query: {
      enabled: canReadWithdrawalDetails,
      onError: () => {
        enqueueSnackbar(t('witdrawals.errors.loadingDetails'), { variant: 'error' });
      },
    },
  });
  const { mutate: completeManually, isLoading: isCompletingManually } = useCompleteWithdrawalManuallyV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.completedManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.completingManually'), { variant: 'error' });
      },
    },
  });
  const { mutate: failManually, isLoading: isFailingManually } = useFailWithdrawalV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.failedManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.failedManually'), { variant: 'error' });
      },
    },
  });
  const { mutate: decline, isLoading: isDeclining } = useDeclineWithdrawalV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.declined'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.decline'), { variant: 'error' });
      },
    },
  });
  const { mutate: cancel, isLoading: isCancelling } = useCancelFailedWithdrawalV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.cancelled'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.cancel'), { variant: 'error' });
      },
    },
  });
  const { mutate: process, isLoading: isProcessing } = useProcessWithdrawalsV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.processed'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.processing'), { variant: 'error' });
      },
    },
  });
  const { mutate: retry, isLoading: isRetrying } = useRetryWithdrawalV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.retried'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.retrying'), { variant: 'error' });
      },
    },
  });

  const isLoading =
    isCompletingManually || isDeclining || isCancelling || isProcessing || isRetrying || isFailingManually;

  const getPrettyPrintValue = (value: string) => {
    try {
      return JSON.parse(value);
    } catch (e: any) {
      return value;
    }
  };

  const mutatedMessageData = useMemo(() => {
    if (!details?.messages || details.messages.length === 0) return undefined;
    const keys = Object.keys(details.messages[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'payload':
          return {
            Header: t(key),
            accessor: key,
            Cell: ({ value }: any) => {
              return (
                <pre
                  dangerouslySetInnerHTML={{ __html: prettyPrintJson(getPrettyPrintValue(value), { linkUrls: true }) }}
                />
              );
            },
            disableSortBy: true,
          };
        case 'timestamp':
          return transformUnixDate(key, t, true);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    const rows = details.messages.map(item => ({ ...item }));
    return { headers, rows };
  }, [details, t]);

  const mutatedEventStreamData = useMemo(() => {
    if (!details?.events || details.events.length === 0) return undefined;
    const keys = Object.keys(details.events[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'payload':
          return {
            Header: t(key),
            accessor: key,
            Cell: ({ value }: any) => {
              return (
                <pre
                  dangerouslySetInnerHTML={{ __html: prettyPrintJson(getPrettyPrintValue(value), { linkUrls: true }) }}
                />
              );
            },
            disableSortBy: true,
          };
        case 'timestamp':
          return transformUnixDate(key, t, true);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    const rows = details.events.map(item => ({ ...item }));
    return { headers, rows };
  }, [details, t]);

  const handleCompleteManually = () => {
    confirm({
      title: t('withdrawals.confirmations.complete.title'),
      description: t('withdrawals.confirmations.complete.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => completeManually({ withdrawalId: parseInt(withdrawalId) }));
  };

  const handleFailManually = () => {
    confirm({
      title: t('withdrawals.confirmations.fail.title'),
      description: t('withdrawals.confirmations.fail.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => failManually({ withdrawalId: parseInt(withdrawalId) }));
  };

  const handleDecline = (reason: string) => {
    decline({ withdrawalId: parseInt(withdrawalId), data: reason });
  };

  const handleCancel = () => {
    confirm({
      title: t('withdrawals.confirmations.cancel.title'),
      description: t('withdrawals.confirmations.cancel.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => cancel({ withdrawalId: parseInt(withdrawalId) }));
  };

  const handleProcess = () => {
    confirm({
      title: t('withdrawals.confirmations.process.title'),
      description: t('withdrawals.confirmations.process.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => process({ data: [parseInt(withdrawalId)] }));
  };

  const handleRetry = () => {
    confirm({
      title: t('withdrawals.confirmations.retry.title'),
      description: t('withdrawals.confirmations.retry.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => retry({ withdrawalId: parseInt(withdrawalId) }));
  };

  const completeManuallyStatuses: EWithdrawalStatusSchema[] = [
    EWithdrawalStatusSchema.ProcessingFailed,
    EWithdrawalStatusSchema.Approved,
  ];
  const showCompleteManuallyButton =
    canCompleteWithdrawal && details?.status && completeManuallyStatuses.includes(details.status);

  const failManuallyStatuses: EWithdrawalStatusSchema[] = [
    EWithdrawalStatusSchema.Approved,
    EWithdrawalStatusSchema.Pended,
  ];
  const showFailManuallyButton = canFailWithdrawal && details?.status && failManuallyStatuses.includes(details.status);

  const declineStatuses: EWithdrawalStatusSchema[] = [EWithdrawalStatusSchema.New];
  const showDeclineButton = canDeclineWithdrawal && details?.status && declineStatuses.includes(details.status);

  const cancelStatuses: EWithdrawalStatusSchema[] = [EWithdrawalStatusSchema.ProcessingFailed];
  const showCancelButton = canCancelWithdrawal && details?.status && cancelStatuses.includes(details.status);

  const processStatuses: EWithdrawalStatusSchema[] = [EWithdrawalStatusSchema.New];
  const showProcessButton = canProcessWithdrawal && details?.status && processStatuses.includes(details.status);

  const retryStatuses: EWithdrawalStatusSchema[] = [EWithdrawalStatusSchema.ProcessingFailed];
  const showRetryButton = canRetryWithdrawal && details?.status && retryStatuses.includes(details.status);

  return (
    <div className={classes.container}>
      <Card>
        <CardHeader>{t('details')}</CardHeader>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={details}
            isLoading={isLoadingDetails}
            render={({ data }) => (
              <div className={classes.details}>
                <div>
                  <div className={classes.account}>
                    {canLookupUser ? (
                      <Link to={`../../users/${userId}/details`}>
                        {t('User')} - {userId}
                      </Link>
                    ) : (
                      <span>{userId}</span>
                    )}
                  </div>
                  <div className={classes.subDetails}>
                    {t('withdrawalId')} - {withdrawalId}
                  </div>
                </div>
                <div>
                  <div>{formatCurrency(data.amount)}</div>
                  <div>{details?.destination}</div>
                </div>
                <div>
                  <div>{t('status')}:</div>
                  <b>{data.status}</b>
                </div>
                <div>
                  <div className={classes.centered}>{t('withdrawals.actions.title')}:</div>
                  <Stack direction="column" spacing={1} p={1}>
                    {showCompleteManuallyButton && (
                      <Button
                        onClick={handleCompleteManually}
                        variant="contained"
                        className={classes.actionButton}
                        disabled={isLoading}
                      >
                        {t('withdrawals.actions.complete')}
                      </Button>
                    )}
                    {showFailManuallyButton && (
                      <Button
                        onClick={handleFailManually}
                        variant="contained"
                        className={classes.actionButton}
                        disabled={isLoading}
                        color="error"
                      >
                        {t('withdrawals.actions.fail')}
                      </Button>
                    )}
                    {showDeclineButton && (
                      <Button
                        onClick={() => setDeclineDialogOpen(true)}
                        variant="contained"
                        className={classes.actionButton}
                        disabled={isLoading}
                        color="error"
                      >
                        {t('withdrawals.actions.decline')}
                      </Button>
                    )}
                    {declineDialogOpen && (
                      <DeclineWithdrawalDialog close={() => setDeclineDialogOpen(false)} submit={handleDecline} />
                    )}
                    {showCancelButton && (
                      <Button
                        onClick={handleCancel}
                        variant="contained"
                        className={classes.actionButton}
                        disabled={isLoading}
                        color="error"
                      >
                        {t('withdrawals.actions.cancel')}
                      </Button>
                    )}
                    {showProcessButton && (
                      <Button
                        onClick={handleProcess}
                        variant="contained"
                        className={classes.actionButton}
                        disabled={isLoading}
                      >
                        {t('withdrawals.actions.approve')}
                      </Button>
                    )}
                    {showRetryButton && (
                      <Button
                        onClick={handleRetry}
                        variant="contained"
                        className={classes.actionButton}
                        disabled={isLoading}
                      >
                        {t('withdrawals.actions.retry')}
                      </Button>
                    )}
                  </Stack>
                </div>
              </div>
            )}
          />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={stream} onChange={(event, newValue) => setStream(newValue)}>
              <Tab label="Messages" value={0} />
              <Tab label="Events" value={1} />
            </Tabs>
          </Box>
          <TabPanel value={stream} index={0}>
            <CheckData
              fallbackText={t('noMessages')}
              data={mutatedMessageData}
              checkProperties={['headers', 'rows']}
              render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
            />
          </TabPanel>
          <TabPanel value={stream} index={1}>
            <CheckData
              fallbackText={t('noEvents')}
              data={mutatedEventStreamData}
              checkProperties={['headers', 'rows']}
              render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
            />
          </TabPanel>
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(WithdrawalDetails);
