import { memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  EGameTypeSchema,
  GameArgumentsV2Schema,
  getGetGameV2QueryKey,
  useAddGameV2,
  useGetGameCategories,
  useGetGameProvider,
  useGetGameStudios,
  useGetGameThemes,
  useGetGameV2,
  useUpdateGameV2,
} from '@greenisland/stores';
import { LoadingButton } from '@mui/lab';
import { Box, InputLabel, Skeleton, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import { AutoCompleteInput, FormError, SelectInput } from '@greenisland-common/components/atoms';
import CheckboxField from '@greenisland-common/components/molecules/CheckboxField';
import DateInput from '@greenisland-common/components/molecules/DateInput';
import Input from '@greenisland-common/components/molecules/Input';

import { defaultGameFormValue, GameForm, gameFormToRequest, getDefaultGameFormValue } from './helpers/GameFormContext';
import { ADD_GAME_ID } from '../../Games/constants';
import processFieldArray from '../utils/processFieldArray';
import useGetTranslationSchemaLocale from '../utils/useGetTranslationSchemaLocale';
import EditGameActions from './EditGameActions';
import EditGameImages from './EditGameImages';
import TranslationsDictionaryFields, { TotalFieldArrayDataItem } from './TranslationsDictionaryFields';

interface ExtendedGameForm extends GameForm {
  totalFieldArray?: TotalFieldArrayDataItem[];
}

const EditGame = () => {
  const { t } = useTranslation();
  const { gameId, providerId }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: game, isLoading: gameIsLoading } = useGetGameV2(gameId, {
    query: { enabled: Boolean(gameId !== ADD_GAME_ID) },
  });

  const methods = useForm<GameForm>({ defaultValues: defaultGameFormValue, shouldFocusError: true });
  const { control, handleSubmit, reset, formState } = methods;
  const getTranslation = useGetTranslationSchemaLocale();

  const { data: gameCategories, isLoading: gameCategoriesIsLoading } = useGetGameCategories();
  const { data: gameThemes, isLoading: gameThemesIsLoading } = useGetGameThemes();
  const { data: gameStudios, isLoading: gameStudioIsLoading } = useGetGameStudios();
  const { data: gameProvider, isLoading: providerIsLoading } = useGetGameProvider(providerId);

  useEffect(() => {
    if (game && gameThemes && gameCategories && gameProvider) {
      reset({
        ...getDefaultGameFormValue(game, gameCategories, gameThemes),
        providerName: gameProvider.providerName,
        providerFk: parseInt(gameProvider?.providerId),
      });
    }
  }, [game, reset, gameStudios, gameCategories, gameThemes, gameProvider]);

  const filteredGameStudios = useMemo(
    () => gameStudios?.filter(studio => gameProvider?.gameStudios.includes(studio.gameStudioId)),
    [gameStudios, gameProvider?.gameStudios]
  );

  const onSubmit = handleSubmit((formData: ExtendedGameForm) => {
    const data: GameArgumentsV2Schema = gameFormToRequest(formData, gameProvider);
    if (formData.totalFieldArray) {
      const { descriptions } = processFieldArray(formData.totalFieldArray);
      data.description = descriptions;
      delete formData.totalFieldArray;
    }
    if (!gameId || gameId === ADD_GAME_ID) addGame({ data });
    else updateGame({ gamePKey: gameId, data });
    queryClient.invalidateQueries(getGetGameV2QueryKey(gameId));
    navigate(`../games${search}`);
  });

  const { mutate: updateGame, isLoading: updateIsLoading } = useUpdateGameV2({
    mutation: {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries(getGetGameV2QueryKey(data.gamePKey));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
    },
  });

  const { mutate: addGame, isLoading: addIsLoading } = useAddGameV2({
    mutation: {
      onSuccess: (data: any, variables: any) => {
        reset(getDefaultGameFormValue({ ...data, ...variables }, gameCategories ?? [], gameThemes ?? []), {
          touched: false,
          isDirty: false,
        });
        enqueueSnackbar(t('success'), { variant: 'success' });
        const array = location.pathname.split('/');
        array.pop();
        navigate([...array, data.gamePKey].join('/'));
        reset(getDefaultGameFormValue(data, gameCategories ?? [], gameThemes ?? []));
      },
    },
  });

  if (gameIsLoading || gameThemesIsLoading || gameCategoriesIsLoading || providerIsLoading || gameStudioIsLoading)
    return <Skeleton variant="rectangular" height={600} />;

  return (
    <FormProvider {...methods}>
      {game && <EditGameActions game={game} />}
      <form onSubmit={onSubmit}>
        <Stack spacing={2}>
          <CheckboxField label={t('enabled')} name={t('enabled')} control={control} />
          <Box display="flex" flexWrap="wrap">
            <CheckboxField label={t('featured')} name="featured" control={control} />
            <CheckboxField label={t('exclusive')} name="exclusive" control={control} />
            <CheckboxField label={t('isLive')} name="isLive" control={control} />
            <CheckboxField label={t('hasJackpot')} name="hasJackpot" control={control} />
          </Box>
          <Input label={t('provider')} name="providerName" disabled />
          <SelectInput
            name={'gameStudioFk'}
            label={t('gameStudio') + ' *'}
            disabled={gameProvider?.gameStudios.length === 0}
            rules={{ required: t('fieldIsRequired') }}
            options={
              filteredGameStudios?.map(({ gameStudioName, gameStudioId }) => ({
                label: gameStudioName,
                value: gameStudioId,
              })) ?? []
            }
          />
          {game?.gameConfigurationsV2 && game?.gameConfigurationsV2?.length > 0 && (
            <SelectInput
              name={'activeGameConfigurationId'}
              label={t('gameConfigurations')}
              options={game?.gameConfigurationsV2?.map(({ pkey, name }) => ({ label: name, value: pkey })) ?? []}
            />
          )}
          <SelectInput
            name={'gameType'}
            label={t('gameType') + ' *'}
            rules={{ required: t('fieldIsRequired') }}
            options={
              Object.keys(EGameTypeSchema)
                .filter(x => x != EGameTypeSchema.Other)
                .map(key => ({ label: t(key), value: key })) ?? []
            }
          />
          <Input
            label={t('gameName')}
            control={control}
            required
            name="gameName"
            rules={{ required: t('fieldIsRequired') }}
          />
          <InputLabel htmlFor="addDescription">{t('description')}</InputLabel>
          <TranslationsDictionaryFields nameDescription="description" control={control} />
          <Input
            label={t('gameUrlName')}
            name="urlName"
            required
            rules={{
              required: t('fieldIsRequired'),
              pattern: { value: /^[a-zA-Z0-9_-]*$/, message: t('invalidPattern', { pattern: '[a-zA-Z0-9_-]' }) },
            }}
          />
          <DateInput name="releaseDate" label={t('releaseDate') + ' *'} />
          {!gameCategoriesIsLoading && (
            <AutoCompleteInput
              multiple
              name="gameCategories"
              label={t('categories')}
              options={gameCategories ?? []}
              getLabel={({ codeNameTranslations, codeName }) => getTranslation(codeNameTranslations) ?? codeName ?? ''}
            />
          )}
          {!gameThemesIsLoading && (
            <AutoCompleteInput
              multiple
              name="gameThemes"
              label={t('themes')}
              options={gameThemes ?? []}
              getLabel={({ nameTranslations, name }) => getTranslation(nameTranslations) ?? name ?? ''}
            />
          )}
          <EditGameImages />
          <Stack>
            <Box display="flex" gap={1}>
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                loading={updateIsLoading || addIsLoading}
                disabled={!formState.isDirty}
              >
                {t('save')}
              </LoadingButton>
            </Box>
            <FormError error={Object.keys(formState.errors).length > 0} message={t('common.formErrors')} />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default memo(EditGame);
