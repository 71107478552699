/**
 * TODO: Improve feature flagging
 *
 * These hardcoded values are a temporary solution.
 * Feature flags should be configurable from the cms, cloud or any other platform.
 * Thereafter, they should be loaded either at build or runtime.
 * Ideally, there also is a developer/debug menu where these can be toggled manually.
 */

const featureFlagsDev = {
  messagingService: false, // Switch this to true to get access to external layout & notifications routes
};

const featureFlagsProd = {
  messagingService: false,
};

const host = window.location.host;
const featureFlags =
  host === 'backofficedev.greenisland.be' || host === 'localhost:3000' ? featureFlagsDev : featureFlagsProd;

export default featureFlags;
