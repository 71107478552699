import { useMemo } from 'react';
import {
  EPaymentMethodSchema,
  EPaymentProviderSchema,
  EPaymentServiceVersionSchema,
  PaymentMethodConfigurationsSchema,
} from '@greenisland/stores';

export default function useFilteredPaymentsServiceVersion(
  selectedMethod: EPaymentMethodSchema,
  selectedProvider: EPaymentProviderSchema,
  paymentMethodConfigs: PaymentMethodConfigurationsSchema | undefined
) {
  return useMemo(() => {
    const versions = [];

    if (
      paymentMethodConfigs?.portalConfigurations
        .filter(config => config.method === selectedMethod)
        .some(config => config.possibleProviders.map(pp => pp.provider).includes(selectedProvider))
    ) {
      versions.push(EPaymentServiceVersionSchema.Portal);
    }
    if (
      paymentMethodConfigs?.v1Configurations
        .filter(config => config.method === selectedMethod)
        .some(config => config.possibleProviders.map(pp => pp.provider).includes(selectedProvider))
    ) {
      versions.push(EPaymentServiceVersionSchema.PaymentsV1);
    }
    if (
      paymentMethodConfigs?.v2Configurations
        .filter(config => config.method === selectedMethod)
        .some(config => config.possibleProviders.map(pp => pp.provider).includes(selectedProvider))
    ) {
      versions.push(EPaymentServiceVersionSchema.PaymentsV2);
    }

    return versions;
  }, [paymentMethodConfigs, selectedMethod, selectedProvider]);
}
