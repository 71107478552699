import { EPlayingBreakBadRequestTypeSchema } from '@greenisland/stores';

type PlayingBreakErrorReasons = {
  [key in EPlayingBreakBadRequestTypeSchema]: string;
};

export const PLAYING_BREAK_ERROR_REASONS: PlayingBreakErrorReasons = {
  [EPlayingBreakBadRequestTypeSchema.noActivePlayingBreak]: 'settings.playingBreak.errors.noActivePlayingBreak',
  [EPlayingBreakBadRequestTypeSchema.stricterPlayingBreakAlreadyPresent]:
    'settings.playingBreak.errors.stricterPlayingBreakAlreadyPresent',
  [EPlayingBreakBadRequestTypeSchema.cooldownLowerThanMinimumPlayingBreak]:
    'settings.playingBreak.errors.cooldownLowerThanMinimumPlayingBreak',
  [EPlayingBreakBadRequestTypeSchema.cancellationAlreadyRequested]:
    'settings.playingBreak.errors.cancellationAlreadyRequested',
  [EPlayingBreakBadRequestTypeSchema.endDateInPast]: 'settings.playingBreak.errors.endDateInPast',
  [EPlayingBreakBadRequestTypeSchema.alreadyShorterThanCancellationWaitPeriod]:
    'settings.playingBreak.errors.alreadyShorterThanCancellationWaitPeriod',
  [EPlayingBreakBadRequestTypeSchema.invalidUserId]: 'settings.playingBreak.errors.invalidUserId',
  [EPlayingBreakBadRequestTypeSchema.regulatorNotAllowedToCancel]:
    'settings.playingBreak.errors.regulatorNotAllowedToCancel',
};
