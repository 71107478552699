import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EPermissionLevelSchema, EProductTypeSchema, useGetAgentRoleDetails } from '@greenisland/stores';
import { AuthorizationPermissions } from '@greenisland-core/permissions';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPagination, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../../../app/hooks';

const RoleDetails = () => {
  const { t } = useTranslation();
  const canReadAgentRolesDetails = usePermission(AuthorizationPermissions.getAgentRoleDetails);
  const { agentRoleId = '' } = useParams();
  const {
    data: agentRole,
    isLoading,
    isError,
    refetch,
  } = useGetAgentRoleDetails(agentRoleId, { query: { enabled: canReadAgentRolesDetails } });

  const columns: GridColumns = useMemo(
    () => [
      {
        headerName: t('productType'),
        field: 'productType',
        type: 'singleSelect',
        valueOptions: [
          EProductTypeSchema.Casino,
          EProductTypeSchema.OnlineCasino,
          EProductTypeSchema.GameServer,
          EProductTypeSchema.Authorization,
          EProductTypeSchema.PaymentService,
        ],
        minWidth: 150,
        flex: 1,
      },
      {
        headerName: t('path'),
        field: 'path',
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: t('level'),
        field: 'level',
        type: 'singleSelect',
        valueOptions: [EPermissionLevelSchema.Read, EPermissionLevelSchema.Write],
        minWidth: 100,
        flex: 0.2,
      },
    ],
    [t]
  );

  const rows: GridRowsProp = useMemo(
    () =>
      agentRole?.permissions?.map(permission => {
        return { ...permission, id: permission.permissionId };
      }) || [],
    [agentRole]
  );

  return canReadAgentRolesDetails ? (
    <Stack spacing={2}>
      <div>
        <Typography variant="h4">{agentRole?.roleName}</Typography>
      </div>
      {!isError ? (
        <DataGridContainer>
          <StyledDataGrid
            density="compact"
            autoHeight
            loading={isLoading}
            rows={rows}
            columns={columns}
            rowsPerPageOptions={[25, 50, 100, 200]}
            pagination
            disableDensitySelector
            disableSelectionOnClick
            disableChildrenSorting
            disableChildrenFiltering
            disableColumnResize
            disableColumnReorder
            isRowSelectable={() => false}
            components={{
              Pagination: DataGridPagination,
              LoadingOverlay: LinearProgress,
            }}
            componentsProps={{
              pagination: { rowsPerPageOptions: [25, 50, 100, 200] },
            }}
          />
        </DataGridContainer>
      ) : (
        <ErrorState errorMessage={t('settings.management.agentRoles.loadingError')} retryAction={() => refetch()} />
      )}
    </Stack>
  ) : null;
};

export default memo(RoleDetails);
