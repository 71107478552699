import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, CheckData, ReactTableGI } from '@greenisland/components';
import { useGetBonusesAwardedToday } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import makeStyles from '@mui/styles/makeStyles';

import { transformCurrency } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridGap: '16px 16px',
  },
});

const AwardedToday = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const canReadBonusesAwardedToday = usePermission(OnlineCasinoPermissions.getBonusesAwardedToday);
  const { data: awardedToday, isLoading } = useGetBonusesAwardedToday({
    query: {
      enabled: canReadBonusesAwardedToday,
    },
  });

  const mutatedData = (data: any) => {
    if (!data || data.length === 0) return undefined;
    const keys = Object.keys(data[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'sum':
          return transformCurrency(key, t);
        default:
          return { Header: t(key), accessor: key };
      }
    });
    const rows = data.map((item: any) => ({ ...item }));
    return { headers, rows };
  };

  return canReadBonusesAwardedToday ? (
    <div className={classes.container}>
      <Card>
        <CardHeader>{t('availablePromotions')}</CardHeader>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData(awardedToday?.availablePromotions)}
            isLoading={isLoading}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>{t('freeSpins')}</CardHeader>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData(awardedToday?.freeSpins)}
            isLoading={isLoading}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>{t('claimablePromotion')}</CardHeader>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData(awardedToday?.claimablePromotions)}
            isLoading={isLoading}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};

export default memo(AwardedToday);
