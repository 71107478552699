import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ESuspiciousDepositFlagSchema } from '@greenisland/stores';
import { Box, Typography } from '@mui/material';

interface Props {
  flags?: ESuspiciousDepositFlagSchema[];
}

const SuspiciousDepositFlagsDetails = ({ flags }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ paddingY: 1, paddingLeft: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, marginLeft: 3, marginBottom: 3 }}>
        <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
          {t('suspiciousdepositflags')}
        </Typography>
      </Box>
      {flags?.map((e, i) => {
        return (
          <div key={i}>
            <span>
              <b>{e}</b>
            </span>
            <br></br>
          </div>
        );
      })}
    </Box>
  );
};

export default memo(SuspiciousDepositFlagsDetails);
