import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  EPhoneNumberBadRequestTypeSchema,
  getGetUserGeneralQueryKey,
  useGetUserGeneral,
  useUpdatePhoneNumber,
} from '@greenisland/stores';
import { UpdatePhoneNumberRequestSchema } from '@greenisland/stores/dist/api/schemas';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  capitalize,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { PHONE_NUMBER_REGEX } from '@greenisland-common/components/atoms/Constants';

import { PHONE_NUMBER_ERROR_REASONS } from './Constants/PhoneNumberErrorReasons';

interface Props {
  onClose: () => void;
}
const EditPhoneNumberDialog = ({ onClose }: Props) => {
  const [validationError, setValidationError] = useState('');
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: user } = useGetUserGeneral(userId);

  const methods = useForm<UpdatePhoneNumberRequestSchema>({
    defaultValues: {
      phoneNumber: user?.phonenumber || '',
    },
  });

  const { handleSubmit, register } = methods;

  const validatePhoneNumber = (phoneNumber: string) => {
    if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
      setValidationError(t('validation.phoneNumber.invalidFormat'));
    } else {
      setValidationError('');
    }
  };

  const { mutate: updatePhoneNumberMutation, isLoading } = useUpdatePhoneNumber({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetUserGeneralQueryKey(userId));
        onClose();
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: EPhoneNumberBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason ? PHONE_NUMBER_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const onSubmit = (data: UpdatePhoneNumberRequestSchema) => {
    updatePhoneNumberMutation({ userId: parseInt(userId), data: data });
  };

  return (
    <Dialog maxWidth="xs" open onClose={onClose} fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>{capitalize(t('editPhoneNumber'))}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <TextField
              fullWidth
              name="phoneNumber"
              label={capitalize(t('phonenumber'))}
              inputRef={register({ required: capitalize(t('phonenumber')) })}
              error={!!validationError}
              helperText={validationError}
              onChange={e => {
                validatePhoneNumber(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={onClose}>
              {t('cancel')}
            </Button>
            <LoadingButton
              sx={{ minHeight: '36px', minWidth: '90px' }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? <CircularProgress size={20} /> : t('save')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
export default EditPhoneNumberDialog;
