import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CheckData, Link, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import { setInvisible, transformUnixDate } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../Lookup/LookupPermissions';

const VerificationsMain = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canReadVerifications = usePermission(OnlineCasinoPermissions.getVerifications);
  const canReadVerificationDetails = usePermission(OnlineCasinoPermissions.getUserVerifications);
  const { data: verifications, fetching } = useAppSelector(state => state.verifications);

  useEffect(() => {
    if (canReadVerifications) dispatch(actionCreators.getVerifications());
  }, [dispatch, canReadVerifications]);

  const mutatedData = () => {
    if (!verifications || verifications.length === 0) return undefined;
    const keys = Object.keys(verifications[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'modifiedOn':
          return transformUnixDate(key, t);
        case 'userId':
          return setInvisible(key);
        case 'username':
          return {
            Header: t(key),
            accessor: key,
            Cell: (entries: any) => {
              const { userId, username } = entries.row.values;
              return canLookupUser ? (
                <Link to={`../../users/${userId}/details`}>{username}</Link>
              ) : (
                <span>{username}</span>
              );
            },
          };
        default:
          return { Header: t(key), accessor: key };
      }
    });

    headers.push({ id: 'details', accessor: 'details', disableFilters: true, disableSortBy: true });

    const rows = verifications
      .filter(
        verification => verification.reason === 'RegistrationFraud' || verification.reason === 'RegistrationBonus'
      )
      .map(item => {
        return {
          ...item,
          details: canReadVerificationDetails ? (
            <Link to={`../../users/${item.userId}/verifications`}>{t('details')}</Link>
          ) : undefined,
        };
      });

    return { headers, rows };
  };

  return canReadVerifications ? (
    <Card>
      <CardBody>
        <CheckData
          fallbackText={t('noData')}
          data={mutatedData()}
          isLoading={fetching}
          checkProperties={['headers', 'rows']}
          render={({ data }) => (
            <ReactTableGI columns={data.headers} data={data.rows} pagination={data.rows.length > 5} sortable />
          )}
        />
      </CardBody>
    </Card>
  ) : null;
};

export default memo(VerificationsMain);
