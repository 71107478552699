import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd, faPencil } from '@fortawesome/free-solid-svg-icons';
import { LoyaltyShopsSchemaItem, useGetAllLoyaltyShopItems } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  LinearProgress,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import LoyaltyShopDialog from './Components/LoyaltyShopDialog';
import { formatCurrency } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

const StyledImage = styled('img')<{ imageIsLoaded?: boolean }>(({ imageIsLoaded }) => ({
  height: '100%',
  widht: 'auto',
  opacity: 0,
  transition: 'opacity 0.3s linear',

  ...(imageIsLoaded && {
    opacity: 1,
  }),
}));

type Row = LoyaltyShopsSchemaItem;

const PAGE_SIZE = 25;

type ImageProps = {
  image: string;
  title: string;
};

const LoyaltyShopItemImage = ({ image, title }: ImageProps) => {
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  return (
    <Box sx={{ height: 80, position: 'relative', p: 1 }}>
      {!imageIsLoaded && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <StyledImage imageIsLoaded={imageIsLoaded} onLoad={() => setImageIsLoaded(true)} src={image} alt={title} />
    </Box>
  );
};

const LoyaltyShopMain = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const canReadLoyaltyShops = usePermission(OnlineCasinoPermissions.getAllLoyaltyShopItems);
  const canAddLoyaltyShop = usePermission([
    OnlineCasinoPermissions.addLoyaltyShopItem,
    OnlineCasinoPermissions.getCampaignActions,
  ]);
  const canEditLoyaltyShop = usePermission([
    OnlineCasinoPermissions.updateLoyaltyShopItemV2,
    OnlineCasinoPermissions.getCampaignActions,
  ]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [isLoyaltyShopDialogOpen, setIsLoyaltyShopDialogOpen] = useState(false);
  const [chosenLoyaltyShopItem, setChosenLoyaltyShopItem] = useState<Row | null>(null);

  const {
    data: loyaltyShopItems,
    isLoading,
    isError,
  } = useGetAllLoyaltyShopItems({ visibleOnly: false }, { query: { enabled: canReadLoyaltyShops } });

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'loyaltyShopId',
        headerName: capitalize(t('id')),
        sortable: false,
        minWidth: 50,
        flex: 0.05,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'visible',
        headerName: capitalize(t('visible')),
        type: 'boolean',
        flex: 0.05,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'title',
        headerName: capitalize(t('name')),
        sortable: false,
        minWidth: 150,
        flex: 0.15,
        renderCell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <Box>
                <Typography variant="body2">{value || '-'}</Typography>
              </Box>
            </Tooltip>
          );
        },
      },
      {
        field: 'imageUrl',
        headerName: capitalize(t('imageUrl')),
        sortable: false,
        minWidth: 150,
        flex: 0.1,
        renderCell: ({ row }) => {
          return <LoyaltyShopItemImage image={row.imageUrl} title={row.title} />;
        },
      },
      {
        field: 'price',
        headerName: capitalize(t('price')),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{formatCurrency(value) || '-'}</Typography>;
        },
      },
      {
        field: 'fulfillmentAction',
        headerName: capitalize(t('fulfillmentAction')),
        sortable: false,
        minWidth: 150,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'fulfillmentItem',
        headerName: capitalize(t('fulfillmentItem')),
        sortable: false,
        minWidth: 150,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'loyaltyDependentMaxPurchaseQuantity',
        headerName: capitalize(t('loyalty.loyaltyShop.titles.loyaltyDependentMaxPurchaseQuantity')),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          if (value) {
            return (
              <Tooltip title={JSON.stringify(value, null, 2)}>
                <Box>{JSON.stringify(value, null, 2)}</Box>
              </Tooltip>
            );
          }
          return '-';
        },
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 80,
        flex: 0.05,
        headerName: '',
        getActions: ({ row }: GridRowParams<Row>) => [
          <>
            {canEditLoyaltyShop && (
              <Tooltip title={t('edit')} key="details">
                <Box>
                  <GridActionsCellItem
                    label={t('edit')}
                    icon={<FontAwesomeIcon icon={faPencil} />}
                    onClick={() => {
                      if (row) {
                        setChosenLoyaltyShopItem(row);
                        setIsLoyaltyShopDialogOpen(true);
                      }
                    }}
                    key="edit"
                  />
                </Box>
              </Tooltip>
            )}
          </>,
        ],
      },
    ],
    [canEditLoyaltyShop, t]
  );

  const rows = useMemo(
    () =>
      loyaltyShopItems?.map<Row>((loyaltyShopItem, index) => ({
        ...loyaltyShopItem,
        id: index,
      })) ?? [],
    [loyaltyShopItems]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('loyaltymanagement.error.getAllLoyaltyShopItems')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getAllLoyaltyShopItems}
      >
        <>
          {canAddLoyaltyShop && (
            <Box>
              <Button
                variant="contained"
                onClick={() => setIsLoyaltyShopDialogOpen(true)}
                size="medium"
                startIcon={<FontAwesomeIcon icon={faAdd} />}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    mr: 0,
                  },
                  [theme.breakpoints.up('md')]: {
                    mr: 2,
                  },
                }}
              >
                {capitalize(t('addItem'))}
              </Button>
            </Box>
          )}
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={100}
              loading={isLoading}
              columns={columns}
              rows={rows}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              components={{
                LoadingOverlay: LinearProgress,
                Footer: () => (
                  <>
                    <DataGridCustomPagination
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[10, 25, 50, 100, 200]}
                      count={loyaltyShopItems?.length ? Math.ceil(loyaltyShopItems?.length / pageSize) : 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      paginationMode="client"
                    />
                  </>
                ),
              }}
            />
          </DataGridContainer>
          {isLoyaltyShopDialogOpen && (
            <LoyaltyShopDialog
              open={true}
              onClose={() => {
                setIsLoyaltyShopDialogOpen(false);
                setChosenLoyaltyShopItem(null);
              }}
              loyaltyShopItems={loyaltyShopItems}
              chosenLoyaltyShopItem={chosenLoyaltyShopItem}
            />
          )}
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default LoyaltyShopMain;
