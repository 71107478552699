import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TransferList } from '@greenisland/components';
import { GameStudioSchema, GameStudiosSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, IconButton, Tooltip } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../app/hooks';
import GameStudioDialog from './GameStudioDialog';

interface Props {
  gameStudios: GameStudiosSchema;
  selectedGameStudios: GameStudiosSchema;
  setSelectedGameStudios: (studios: GameStudiosSchema) => void;
}

const DEFAULT_GAME_STUDIO = {
  isLive: true,
  gameStudioId: '',
  gameStudioName: '',
  logoUrl: '',
  urlName: '',
  imageUrl: '',
  enabled: false,
};

const GameStudiosComp = ({ gameStudios, selectedGameStudios, setSelectedGameStudios }: Props) => {
  const { t } = useTranslation();
  const canAddGameStudio = usePermission(OnlineCasinoPermissions.addGameStudio);
  const canEditGameStudio = usePermission(OnlineCasinoPermissions.updateGameStudio);

  const [gameStudioDialog, setGameStudioDialog] = useState<{ open: boolean; gameStudio: GameStudioSchema }>({
    open: false,
    gameStudio: DEFAULT_GAME_STUDIO,
  });

  const openCloseDialog = (state: boolean) => {
    setGameStudioDialog({ ...gameStudioDialog, open: state });
  };

  const handleAddGameStudio = () => {
    setGameStudioDialog({
      open: true,
      gameStudio: DEFAULT_GAME_STUDIO,
    });
  };

  const handleEditGameStudio = (item: GameStudioSchema) => {
    setGameStudioDialog({
      open: true,
      gameStudio: {
        isLive: item.isLive,
        gameStudioId: item.gameStudioId,
        gameStudioName: item.gameStudioName,
        logoUrl: item.logoUrl,
        urlName: item.urlName,
        imageUrl: item.imageUrl,
        gameLibId: item.gameLibId,
        enabled: item.enabled,
        imageUrlTranslations: item.imageUrlTranslations,
        gameStudioNameTranslations: item.gameStudioNameTranslations,
      },
    });
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        {canAddGameStudio ? (
          <Button variant="text" onClick={handleAddGameStudio} startIcon={<FontAwesomeIcon icon={faPlus} />}>
            {t('addGameStudio')}
          </Button>
        ) : null}
      </div>
      <TransferList
        leftTitle={'availableGameStudios'}
        rightTitle={'selectedGameStudios'}
        allItems={gameStudios.map(item => ({
          original: item,
          name: item.gameStudioName,
          id: item.gameStudioId,
          actionElement: canEditGameStudio ? (
            <Tooltip title={t('edit')}>
              <IconButton size="small" onClick={() => handleEditGameStudio(item)}>
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
            </Tooltip>
          ) : undefined,
        }))}
        selectedItems={
          selectedGameStudios.map(item => ({
            original: item,
            name: item.gameStudioName,
            id: item.gameStudioId,
            actionElement: canEditGameStudio ? (
              <Tooltip title={t('edit')}>
                <IconButton size="small" onClick={() => handleEditGameStudio(item)}>
                  <FontAwesomeIcon icon={faEdit} />
                </IconButton>
              </Tooltip>
            ) : undefined,
          })) || []
        }
        onSelected={selected => setSelectedGameStudios(selected as GameStudiosSchema)}
      />
      {gameStudioDialog.open && (
        <GameStudioDialog
          open={gameStudioDialog.open}
          setOpen={openCloseDialog}
          gameStudio={gameStudioDialog.gameStudio}
        />
      )}
    </>
  );
};

export default memo(GameStudiosComp);
