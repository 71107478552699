import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetLoginSessionLimitUpdateRequests,
  UserLimitV2UpdateRequestStateParamParameter,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Tooltip } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, DateTime, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

import { getDurationFromSeconds, StatusSpan } from '../../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../../app/hooks';

interface DepositRequestsProps {
  userId: string;
  compact?: boolean;
  showOnlyQueuedRequests?: boolean;
  startDate?: Date;
  endDate?: Date;
}

const LimitRequestsTable = ({
  userId,
  compact = false,
  startDate,
  endDate,
  showOnlyQueuedRequests = false,
}: DepositRequestsProps) => {
  const { t } = useTranslation();
  const pageSize = compact ? 5 : 25;
  const canReadSessionRequests = usePermission(OnlineCasinoPermissions.getLoginSessionLimitUpdateRequests);
  const [page, setPage] = useState(0);
  const {
    data: sessionLimitRequests,
    isLoading,
    isError,
    error,
  } = useGetLoginSessionLimitUpdateRequests(
    {
      userId: parseInt(userId, 10),
      page,
      pageSize: pageSize,
      ...(showOnlyQueuedRequests ? { state: UserLimitV2UpdateRequestStateParamParameter.queued } : {}),
      ...(startDate ? { createdAfter: (startDate.getTime() ?? 0) / 1000 } : {}),
      ...(endDate ? { createdBefore: (endDate.getTime() ?? 0) / 1000 } : {}),
    },
    { query: { enabled: canReadSessionRequests } }
  );

  const initialState = compact
    ? {
        columns: {
          columnVisibilityModel: {
            dateProcessed: false,
            id: false,
            requestType: false,
            requestState: false,
            decision: false,
            userLimitId: false,
            additionalData: false,
            requestSource: false,
          },
        },
      }
    : {};

  const headers: GridColumns = [
    {
      headerName: t('common.requestDate'),
      field: 'created',
      renderCell: ({ row }) => (row.created ? <DateTime dateTime={row.created} /> : <></>),
      flex: 1,
      minWidth: 160,
    },
    {
      headerName: t('common.processedDate'),
      field: 'dateProcessed',
      renderCell: ({ row }) => (row.dateProcessed ? <DateTime dateTime={row.dateProcessed} /> : <></>),
      flex: 1,
      minWidth: 160,
    },
    { headerName: t('id'), field: 'id', flex: 0.5, minWidth: 100 },
    {
      headerName: t('value'),
      field: 'value',
      valueFormatter: ({ value }) => value && getDurationFromSeconds(value),
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: t('source'),
      field: 'requestSource',
      flex: 1,
      minWidth: 80,
    },
    {
      headerName: t('common.requestType'),
      field: 'requestType',
      flex: 1,
      minWidth: 110,
    },
    {
      headerName: t('common.requestState'),
      field: 'requestState',
      renderCell: ({ value }) => <StatusSpan value={value} />,
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: t('decision'),
      field: 'decision',
      flex: 1,
      renderCell: ({ value }) => <StatusSpan value={value} />,
      minWidth: 100,
    },
    {
      headerName: t('depositLimit.limitId'),
      field: 'userLimitId',
      flex: 1,
      minWidth: 80,
    },
    {
      headerName: t('common.additionalInfo'),
      field: 'additionalData',
      flex: 1,
      minWidth: 250,
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>
        </Tooltip>
      ),
    },
  ];

  const rows: GridRowsProp =
    sessionLimitRequests?.loginSessionLimitUpdateRequests?.map(row => ({
      ...row,
      ...row.approvalDecision,
    })) ?? [];

  return (
    <>
      <PermissionWrapper
        errorMessage={error?.message}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getLoginSessionLimitUpdateRequests}
      >
        <DataGridContainer>
          <StyledDataGrid
            initialState={initialState}
            columns={headers}
            rows={rows}
            hideFooter={compact}
            page={sessionLimitRequests?.pagingDetails?.currentPage}
            rowCount={sessionLimitRequests?.pagingDetails?.totalItems}
            paginationMode="server"
            pageSize={pageSize}
            onPageChange={setPage}
            pagination
          />
        </DataGridContainer>
      </PermissionWrapper>
    </>
  );
};

export default LimitRequestsTable;
