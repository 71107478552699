import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
  EInterventionControllerBadRequestTypeSchema,
  EInterventionStatusSchema,
  ErrorSchema,
  getGetInterventionQueryKey,
  getGetInterventionsForUserQueryKey,
  getGetInterventionsQueryKey,
  InterventionControllerBadRequestContentSchema,
  useCloseIntervention as useCloseInterventionQuery,
  useCompleteIntervention as useCompleteInterventionQuery,
  useGetIntervention,
  useOpenIntervention as useOpenInterventionQuery,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, CardHeader, Stack, Tooltip, Typography } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { usePermission } from 'src/app/hooks';

import {
  DataGridPlain,
  DataGridPlainHeader,
  FontAwesomeIcon,
  Link,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';

import { INTERVENTION_ERROR_REASONS } from './Constants/InterventionErrorReasons';
import InterventionStatusField from './InterventionStatusField';
import {
  WRITE_INTERVENTION_CLOSE_PERMISSION,
  WRITE_INTERVENTION_COMPLETE_PERMISSION,
  WRITE_INTERVENTION_OPEN_PERMISSION,
} from './permissions';

const { Closed, Completed, InProgress, New } = EInterventionStatusSchema;

const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    renderCell: ({ value }) => <DataGridPlainHeader value={value} />,
    width: 200,
  },
  { headerName: '', field: 'value', renderCell: ({ value }) => value, flex: 1 },
];

const Intervention = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const queryClient = useQueryClient();

  const interventionId = Number.parseInt(params.interventionId ?? '');
  const canReadIntervention = usePermission(OnlineCasinoPermissions.getIntervention);
  const canCloseIntervention = usePermission(WRITE_INTERVENTION_CLOSE_PERMISSION);
  const canCompleteIntervention = usePermission(WRITE_INTERVENTION_COMPLETE_PERMISSION);
  const canOpenIntervention = usePermission(WRITE_INTERVENTION_OPEN_PERMISSION);

  const onSuccessHandler = (id: number, userId: number) => {
    queryClient.invalidateQueries(getGetInterventionsQueryKey());
    queryClient.invalidateQueries(getGetInterventionsForUserQueryKey(userId));
    queryClient.invalidateQueries(getGetInterventionQueryKey(id));
    enqueueSnackbar(t('success'), { variant: 'success' });
  };

  const onErrorHandler = (error: InterventionControllerBadRequestContentSchema | ErrorSchema) => {
    if (error) {
      if ('type' in error && error.type) {
        const errorReason: EInterventionControllerBadRequestTypeSchema | undefined = error.type;
        const translationKey = errorReason ? INTERVENTION_ERROR_REASONS[errorReason] : 'somethingWentWrong';
        enqueueSnackbar(t(translationKey), { variant: 'error' });
      } else {
        enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
      }
    }
  };

  const { mutate: closeIntervention, isLoading: isLoadingClose } = useCloseInterventionQuery({
    mutation: {
      onSuccess: ({ id, userId }) => onSuccessHandler(id, userId),
      onError: error => onErrorHandler(error),
    },
  });
  const { mutate: completeIntervention, isLoading: isLoadingComplete } = useCompleteInterventionQuery({
    mutation: {
      onSuccess: ({ id, userId }) => onSuccessHandler(id, userId),
      onError: error => onErrorHandler(error),
    },
  });
  const { mutate: openIntervention, isLoading: isLoadingOpen } = useOpenInterventionQuery({
    mutation: {
      onSuccess: ({ id, userId }) => onSuccessHandler(id, userId),
      onError: error => onErrorHandler(error),
    },
  });

  const { data, isLoading, isError } = useGetIntervention(interventionId, { query: { enabled: canReadIntervention } });

  const isLoadingMutation = isLoadingClose || isLoadingComplete || isLoadingOpen;

  const rows: GridRowsProp = [
    { id: 1, title: t('tasks.interventions.id'), value: data?.id },
    {
      id: 2,
      title: t('tasks.interventions.manualActionRequired'),
      value: data?.manualActionRequired ? t(`${data?.manualActionRequired}`) : '',
    },
    {
      id: 3,
      title: t('tasks.interventions.userId'),
      value: <Link to={`../../users/${data?.userId}/details`}>{data?.userId}</Link>,
    },
    {
      id: 4,
      title: t('tasks.interventions.status'),
      value: data?.status ? <InterventionStatusField value={data?.status} /> : '',
    },
    { id: 5, title: t('tasks.interventions.interventionType'), value: data?.interventionType },
    { id: 6, title: t('tasks.interventions.interventionCause'), value: data?.interventionCause },
    { id: 7, title: t('tasks.interventions.interventionActionType'), value: data?.interventionActionType },
    { id: 8, title: t('tasks.interventions.interventionChannelType'), value: data?.interventionChannelType },
    { id: 9, title: t('tasks.interventions.sourceType'), value: data?.sourceType },
    { id: 10, title: t('tasks.interventions.sourceValue'), value: data?.sourceValue },
    { id: 11, title: t('tasks.interventions.createdBy'), value: data?.createdBy },
    {
      id: 12,
      title: t('tasks.interventions.created'),
      value: data?.created ? format(new Date(data?.created * 1000), 'dd-MM-yyyy') : '',
    },
    { id: 13, title: t('tasks.interventions.modifiedBy'), value: data?.modifiedBy },
    {
      id: 14,
      title: t('tasks.interventions.modified'),
      value: data?.modified ? format(new Date(data?.modified * 1000), 'dd-MM-yyyy') : '',
    },
    {
      id: 15,
      title: t('tasks.interventions.startDate'),
      value: data?.startDate ? format(new Date(data?.startDate * 1000), 'dd-MM-yyyy') : '',
    },
    {
      id: 16,
      title: t('tasks.interventions.endDate'),
      value: data?.endDate ? format(new Date(data?.endDate * 1000), 'dd-MM-yyyy') : '',
    },
    {
      id: 17,
      title: t('tasks.interventions.additionalInformation'),
      value: (
        <Tooltip title={data?.additionalInformation} arrow>
          <Box component="p" textOverflow="ellipsis" overflow="hidden" width="100%">
            {data?.additionalInformation}
          </Box>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Card>
        <CardHeader
          action={
            <Stack spacing={1} direction="row">
              {canOpenIntervention ? (
                <LoadingButton
                  disabled={isLoadingMutation || data?.status !== New}
                  loading={isLoadingOpen}
                  variant="contained"
                  size="small"
                  onClick={() => openIntervention({ interventionId })}
                >
                  {t('tasks.interventions.open')}
                </LoadingButton>
              ) : null}
              {canCompleteIntervention ? (
                <LoadingButton
                  disabled={isLoadingMutation || data?.status !== InProgress}
                  variant="contained"
                  size="small"
                  loading={isLoadingComplete}
                  onClick={() => completeIntervention({ interventionId })}
                >
                  {t('tasks.interventions.complete')}
                </LoadingButton>
              ) : null}
              {canCloseIntervention ? (
                <LoadingButton
                  disabled={isLoadingMutation || (data?.status !== Completed && data?.status !== Closed)}
                  variant="contained"
                  size="small"
                  loading={isLoadingClose}
                  onClick={() => closeIntervention({ interventionId })}
                >
                  {t('tasks.interventions.close')}
                </LoadingButton>
              ) : null}
            </Stack>
          }
          title={`${t('tasks.interventions.intervention')}: #${interventionId}`}
          subheader={
            <>
              {data?.status ? <InterventionStatusField value={data?.status} /> : null}
              {data?.manualActionRequired ? (
                <Typography sx={{ color: 'error.main' }}>
                  <FontAwesomeIcon sx={{ marginRight: 1, color: 'error.main' }} icon={faExclamationCircle} />
                  {t('tasks.interventions.manualActionRequired')}
                </Typography>
              ) : null}
            </>
          }
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: ['flex-start', 'center'],
            gap: [2, 0],
          }}
        />
        <CardContent>
          <PermissionWrapper
            errorMessage={t('tasks.interventions.permissions.fetchError')}
            isError={isError}
            isLoading={false}
            permission={OnlineCasinoPermissions.getIntervention}
          >
            <DataGridPlain columns={headers} rows={rows} loading={isLoading} />
          </PermissionWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default Intervention;
