import { memo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, CheckData, DatePicker, ReactTableGI } from '@greenisland/components';
import {
  EVerificationStatus,
  getGetUserVerificationsQueryKey,
  queryClient,
  useDeleteUserVerificationsSolvencyDocument,
  useUpdateUserVerificationsSolvency,
  useUpdateUserVerificationsSolvencyVerificationStatus,
  useUploadUserVerificationsSolvencyDocument,
  VerificationSolvencySchema,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import FileUploadDialog from '@greenisland-common/components/molecules/FileUpload/FileUploadDialog';

import { datePickersErrorMessages } from '../../../../../../app/helpers/errorMessages';
import { getStatusColorClass } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import DeclineVerificationDialog from './DeclineVerificationDialog';

interface Props {
  solvency: VerificationSolvencySchema;
}

const styles = makeStyles(() => ({
  marginLeft: { marginLeft: 10 },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  helperTextChanges: {
    marginTop: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
  },
}));

interface FormProps {
  validUntil: Date;
}

const order = ['verificationStatus', 'solvencyDocument', 'validUntil'];

const Solvency = ({ solvency }: Props) => {
  const classes = styles();
  const { t } = useTranslation();
  const canUpdateSolvencyVerification = usePermission(
    OnlineCasinoPermissions.updateUserVerificationsSolvencyVerificationStatus
  );
  const { userId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [openDialog, setOpenDialog] = useState(false);
  const { handleSubmit, formState, control } = useForm<FormProps>({
    defaultValues: { validUntil: new Date(solvency.validUntil) },
  });
  const { isDirty } = formState;

  const mutation = {
    onSuccess: async () => {
      queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: async () => {
      enqueueSnackbar(t('error'), { variant: 'error' });
    },
  };

  const { mutate: updateVerificationStatus } = useUpdateUserVerificationsSolvencyVerificationStatus({ mutation });
  const { mutate: updateVerification } = useUpdateUserVerificationsSolvency({ mutation });
  const { mutate: uploadDocument } = useUploadUserVerificationsSolvencyDocument({ mutation });
  const { mutate: deleteDocument } = useDeleteUserVerificationsSolvencyDocument({ mutation });

  const mutatedData = () => {
    if (!solvency) return undefined;

    const { solvencyDocumentMetadata, ...rest } = solvency;

    const keys = Object.keys(rest).sort(function (a, b) {
      return order.indexOf(b) - order.indexOf(a);
    });

    const headers: any = keys.map(key => {
      switch (key) {
        case 'verificationStatus':
          return {
            Header: t('verificationStatus.title'),
            accessor: key,
            Cell: ({ value }: any) => (
              <div className={classes.flex}>
                <Box sx={getStatusColorClass(value)}>{t(value)}</Box>
              </div>
            ),
          };
        case 'solvencyDocument':
          return {
            Header: t(key),
            accessor: key,
            Cell: ({ value }: any) => (
              <Box display="flex" gap={1}>
                <FileUploadDialog
                  id="bankAccountVerification"
                  requirements={{
                    size: 5,
                  }}
                  defaultFile={value ? value : undefined}
                  saveFile={(file: any) => uploadDocument({ userId, data: file })}
                  deleteFile={() => deleteDocument({ userId })}
                  metadata={solvencyDocumentMetadata}
                />
                {solvencyDocumentMetadata && (
                  <Button size="small" onClick={() => deleteDocument({ userId })} color="error" variant="outlined">
                    {t('remove')}
                  </Button>
                )}
              </Box>
            ),
          };
        case 'validUntil':
          return {
            Header: t(key),
            accessor: key,
            Cell: (
              <Box minWidth={120}>
                <Controller
                  render={({ value, onChange }) => (
                    <DatePicker
                      disablePast
                      value={value}
                      onChange={onChange}
                      customErrorMessages={datePickersErrorMessages(t)}
                    />
                  )}
                  fullWidth
                  control={control}
                  name={'validUntil'}
                />
              </Box>
            ),
          };
        default:
          return { Header: t(key), accessor: key };
      }
    });

    const rows = [solvency];

    return { headers, rows };
  };

  const submit = (data: FormProps) => {
    const validUntilFormatted = format(data.validUntil, 'yyyy-MM-dd');
    updateVerification({ userId, data: { validUntil: validUntilFormatted } });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Card>
        <CardHeader>{t('solvency')}</CardHeader>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            checkProperties={['headers', 'rows']}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} />}
          />
          <DeclineVerificationDialog
            open={openDialog}
            setOpen={setOpenDialog}
            action={data => updateVerificationStatus({ userId, data })}
            solvency={solvency}
          />
        </CardBody>
        <CardFooter className={classes.cardFooter}>
          {canUpdateSolvencyVerification && (
            <>
              <Button disabled={!isDirty} type="submit">
                {t('save')}
              </Button>
              {solvency?.verificationStatus === EVerificationStatus.WaitingForApproval && (
                <>
                  <Box display="flex" gap={1}>
                    <Button
                      disabled={isDirty}
                      variant="outlined"
                      onClick={() =>
                        updateVerificationStatus({
                          userId,
                          data: { verificationStatus: EVerificationStatus.Verified },
                        })
                      }
                    >
                      {t('verify')}
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => setOpenDialog(true)}>
                      {t('decline')}
                    </Button>
                  </Box>
                  {isDirty && <span className={classes.helperTextChanges}>{t('saveChangesDirty')}</span>}
                </>
              )}
            </>
          )}
        </CardFooter>
      </Card>
    </form>
  );
};

export default memo(Solvency);
