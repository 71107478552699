import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, Card, CardBody, CheckData, EIcons, Inputfield, Tooltip } from '@greenisland/components';
import { actionCreators, UserNote, UserNotes as IUserNotes } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, fromUnixTime } from 'date-fns';

import { usePermission } from '../../../../../../../../app/hooks';
import DeleteUserNoteDialog from './DeleteUserNoteDialog';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    paddingLeft: 16,
  },
  container: {
    display: 'grid',
    gridGap: 16,
    marginBottom: 16,
  },
  actions: { padding: '16px 24px' },
  innerContainer: {
    gridGap: '24px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(49%, 2fr))',
  },
  credits: {
    color: '#707070', // TODO: theme.palette.text.hint,
    size: theme.typography.caption.fontSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  delete: {
    margin: '8px 16px',
    minWidth: 36,
    minHeight: 36,
  },
  listItemRoot: { alignItems: 'unset' },
  addButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paragraph: {
    maxWidth: '90%',
    marginBottom: 8,
  },
  buttonContainer: { display: 'flex' },
}));

const UserNotes = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId = '' } = useParams();
  const { data: userNotes, fetching } = useAppSelector(state => state.userNotes);
  const canReadUserNotes = usePermission(OnlineCasinoPermissions.getUserNotes);
  const [deleteUserNoteDialog, setDeleteUserNoteDialog] = useState({
    open: false,
    note: { noteId: '0', message: '', author: '', timestamp: 0 },
  });

  useEffect(() => {
    if (canReadUserNotes) dispatch(actionCreators.getUserNotes(userId));
  }, [dispatch, canReadUserNotes, userId]);

  const NotesList = ({ userNotes }: { userNotes: IUserNotes }) => (
    <List className={classes.innerContainer}>
      {userNotes.notes
        .sort((a: UserNote, b: UserNote) => a.timestamp - b.timestamp)
        .map((note, key) => {
          return (
            <ListItem key={key} classes={{ root: classes.listItemRoot }}>
              <ListItemText
                primary={<div className={classes.paragraph}>{note.message}</div>}
                secondary={
                  <div className={classes.credits}>
                    <div>
                      <div>
                        {`${t('postedBy')}`}: {note.author}
                      </div>
                      <div>
                        {`${t('createdOn')}`}: {format(fromUnixTime(note.timestamp), 'dd/MM/yyyy HH:mm')}
                      </div>
                    </div>
                    <Tooltip content={t('delete')}>
                      <Button
                        rounded
                        variant="outlined"
                        error
                        size="medium"
                        onClick={() => setDeleteUserNoteDialog({ open: true, note: note })}
                        justIcon={EIcons.faTrash}
                        className={classes.delete}
                      />
                    </Tooltip>
                  </div>
                }
              />
            </ListItem>
          );
        })}
    </List>
  );

  const openCloseDeleteUserNote = (state: boolean) => {
    setDeleteUserNoteDialog({ ...deleteUserNoteDialog, open: state });
  };

  return canReadUserNotes ? (
    <div className={classes.container}>
      <AddUserNoteDialog />
      <Card>
        <CardBody padding>
          <CheckData
            fallbackText={t('noData')}
            data={userNotes}
            isLoading={fetching}
            checkProperties={['notes']}
            render={({ data }) => (
              <>
                <span className={classes.title}>{data.username}</span>
                <NotesList userNotes={data} />
              </>
            )}
          />
        </CardBody>
      </Card>
      <DeleteUserNoteDialog
        open={deleteUserNoteDialog.open}
        setOpen={openCloseDeleteUserNote}
        userID={userId}
        note={deleteUserNoteDialog.note}
      />
    </div>
  ) : null;
};

const AddUserNoteDialog = memo(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId = '' } = useParams();
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, errors } = useForm<{ message: string }>();
  const canAddUserNotes = usePermission(OnlineCasinoPermissions.addUserNote);

  const setAddUserNote = (message: string) => dispatch(actionCreators.addUserNote(userId, message));

  const handleAddMessage = (data: any) => {
    const { message } = data;
    setOpen(false);
    if (message.length) setAddUserNote(message);
  };

  return canAddUserNotes ? (
    <>
      <div className={classes.buttonContainer}>
        <Button startIcon={EIcons.faPlus} variant="transparent" onClick={() => setOpen(true)}>
          {t('addUserNote')}
        </Button>
      </div>
      {open && (
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={handleSubmit(handleAddMessage)}>
            <DialogTitle id="form-dialog-title">{t('addUserNote')}</DialogTitle>
            <DialogContent>
              <Inputfield
                multiLine
                name="message"
                inputRef={register({ required: true })}
                label={t('message')}
                fullWidth
                error={!!errors.message}
              />
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button variant="transparent" onClick={() => setOpen(false)} color="secondary">
                {t('cancel')}
              </Button>
              <Button disabled={!!errors.message} type="submit">
                {t('add')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  ) : null;
});

AddUserNoteDialog.displayName = 'AddUserNoteDialog';

export default memo(UserNotes);
