import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { GameServer } from '@greenisland/stores';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';
import {
  QueryParticipationParams,
  QueryTournamentsParams,
  TournamentState,
  useQueryParticipation,
  useQueryTournaments,
} from '@greenisland-store/gameServer';
import { Autocomplete, Box, capitalize, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import { formatCurrency, getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

import { DataGridContainer, DataGridPagination, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../app/hooks';

const PAGE_SIZE = 25;

type Row = GameServer.TournamentParticipation;

const TournamentDetails = () => {
  const { tournamentInstanceId = 0 } = useParams();

  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const canReadTournament = usePermission(GameServerPermissions.getTournaments);

  const params: QueryParticipationParams = {
    tournamentId: Number(tournamentInstanceId),
    pageSize: pageSize,
    page: page + 1,
  };

  const {
    data: tournaments,
    isLoading,
    isError,
  } = useQueryParticipation(params, { query: { enabled: canReadTournament, keepPreviousData: true } });

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'id',
        headerName: t('content.gameserver.tournamentparticipation.id'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'score',
        headerName: t('content.gameserver.tournamentparticipation.score'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'state',
        headerName: t('content.gameserver.tournamentparticipation.state'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      tournaments?.entries?.map<Row>(tournament => ({
        ...tournament,
      })) ?? [],
    [tournaments?.entries]
  );

  return (
    <Stack spacing={2}>
      {!isError ? (
        <>
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={tournaments?.totalNumberOfEntries}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              disableDensitySelector
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableChildrenSorting
              disableChildrenFiltering
              disableMultipleColumnsSorting
              disableColumnResize
              disableColumnReorder
              isRowSelectable={() => false}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      ) : (
        <ErrorState errorMessage="error" />
      )}
    </Stack>
  );
};

export default TournamentDetails;
