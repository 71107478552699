import { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  AgentTaskResolveArgumentsSchema,
  getGetAgentTasksQueryKey,
  useResolveOpenAgentTaskV2,
} from '@greenisland/stores';
import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useAgentTaskErrorHandling } from './Hooks/useAgentTaskErrorHandling';
import { RESOLUTION_REASON_MAX_LENGTH } from './Constants';

interface Props {
  open: boolean;
  setOpen: Dispatch<boolean>;
  agentTaskId: string;
}

const ResolveAgentTaskDialog = ({ open, setOpen, agentTaskId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const handleError = useAgentTaskErrorHandling();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open) setLoading(false);
  }, [open]);

  const resolveTaskMutation = useResolveOpenAgentTaskV2({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetAgentTasksQueryKey());
        enqueueSnackbar(capitalize(t('success')), { variant: 'success' });
        setOpen(false);
      },
      onError: error => {
        handleError(error);
        setLoading(false);
      },
    },
  });

  const { register, errors, handleSubmit } = useForm<AgentTaskResolveArgumentsSchema>();

  const onSubmit = (data: AgentTaskResolveArgumentsSchema) => {
    setLoading(true);
    resolveTaskMutation.mutate({ agentTaskId, data });
  };

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{capitalize(t('tasks.agenttask.resolve.manually'))}</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            fullWidth
            multiline
            name="resolutionReason"
            label={t('tasks.agenttask.resolve.reason')}
            type="text"
            inputRef={register({
              required: {
                value: true,
                message: capitalize(t('fieldIsRequired')),
              },
              maxLength: {
                value: RESOLUTION_REASON_MAX_LENGTH,
                message: t('tasks.agenttask.resolve.reasonMaxLengthError', { max: RESOLUTION_REASON_MAX_LENGTH }),
              },
            })}
            error={!!errors.resolutionReason}
            helperText={errors.resolutionReason ? errors.resolutionReason.message : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="error" variant="outlined">
            {t('close')}
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {t('resolve')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ResolveAgentTaskDialog;
