import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ComplaintTicketSchema, EComplaintResponseTypeSchema, EComplaintStatusSchema } from '@greenisland/stores';
import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

import { Link } from '@greenisland-common/components/atoms';

const ComplaintSummaryStats = ({ complaint }: { complaint: ComplaintTicketSchema }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const complaintReplies = useMemo(
    () =>
      complaint.complaintEntries
        .filter(c => c.responseType === EComplaintResponseTypeSchema.FollowUp)
        .sort((e1, e2) => e2.date - e1.date),
    [complaint?.complaintEntries]
  );

  const stats = useMemo(
    () => [
      {
        title: t('tasks.complaints.startDate'),
        value: getDateTimeFromUnix(complaint.startDate),
      },
      {
        title: t(
          complaint.status !== EComplaintStatusSchema.Closed ? 'tasks.complaints.update' : 'tasks.complaints.endDate'
        ),
        value:
          complaint.status === EComplaintStatusSchema.Closed && complaint.endDate
            ? getDateTimeFromUnix(complaint.endDate)
            : getDateTimeFromUnix(complaintReplies.length > 0 ? complaintReplies[0].date : complaint.startDate),
      },
      {
        title: t('tasks.complaints.userId'),
        value: <Link to={`../../users/${complaint.userId}/details`}>{complaint.userId}</Link>,
      },
      {
        title: t('tasks.complaints.type'),
        value: complaint.type,
      },
      {
        title: t('tasks.complaints.replies'),
        value: complaintReplies.length,
      },
    ],
    [complaint, complaintReplies, t]
  );

  return (
    <Box component="div" borderRadius={1} padding={2} bgcolor="#F5F6F9" width="100%">
      {!isMobile ? (
        <Stack
          spacing={5}
          direction="row"
          width="100%"
          justifyContent="space-between"
          divider={<Divider orientation="vertical" flexItem />}
        >
          {stats.map((s, i) => (
            <Box key={i} display="flex" flexDirection="column">
              <Typography fontWeight={600} variant="body2" color="GrayText">
                {s.title}
              </Typography>
              <Typography fontWeight={600} variant="body2">
                {s.value}
              </Typography>
            </Box>
          ))}
        </Stack>
      ) : (
        <Grid container spacing={2}>
          {stats.map(s => (
            <>
              <Grid xs={4}>
                <Typography fontWeight={600} variant="body2" color="GrayText">
                  {s.title}
                </Typography>
              </Grid>
              <Grid xs={8}>
                <Typography fontWeight={600} variant="body2">
                  {s.value}
                </Typography>
              </Grid>
            </>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default ComplaintSummaryStats;
