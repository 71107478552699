import { useTranslation } from 'react-i18next';
import {
  ELoyaltyRankUsersBadRequestTypeSchema,
  ErrorSchema,
  LoyaltyRankUsersBadRequestContentSchema,
} from '@greenisland/stores';

import { LOYALTY_RANK_USERS_ERROR_REASONS } from '../Constants';

export const useLoyaltyRankUsersErrorString = () => {
  const { t } = useTranslation();

  return (error: ErrorSchema | LoyaltyRankUsersBadRequestContentSchema | null) => {
    const defaultError = 'badRequestType.general';
    if (error && typeof error === 'object' && 'type' in error && error.type) {
      const errorReason = error.type as ELoyaltyRankUsersBadRequestTypeSchema;
      return errorReason && LOYALTY_RANK_USERS_ERROR_REASONS[errorReason]
        ? t(LOYALTY_RANK_USERS_ERROR_REASONS[errorReason])
        : t(defaultError);
    }
    return t(defaultError);
  };
};
