import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, CheckData, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { setInvisible, transformCurrency } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 1fr)',
      gridGap: '15px 15px',
      marginBottom: 15,
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    bold: { fontWeight: 'bold' },
  })
);

const CasinoTransactions = () => {
  const canReadCasinoTransactions = usePermission(OnlineCasinoPermissions.getCasinoTransactions);
  return canReadCasinoTransactions ? <CasinoTransactionsInner /> : null;
};

const CasinoTransactionsInner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: casinoTransactions, fetching } = useAppSelector(state => state.reportingFinancialCasinoTransactions);
  const fetchFincancialCasinoTransactions = async (startDate: number, endDate: number) =>
    dispatch(actionCreators.getFinancialCasinoTransactions(startDate, endDate));

  const mutatedData = () => {
    if (!casinoTransactions || casinoTransactions.length === 0) return undefined;
    const keys = Object.keys(casinoTransactions[0]);

    const headers: any = [{ Header: t('casino'), accessor: 'casino' }];

    keys.forEach(key => {
      switch (key) {
        case 'deposits':
        case 'withdrawals':
        case 'difference':
          headers.push(transformCurrency(key, t));
          break;
        case 'enterprisename':
        case 'location':
          headers.push(setInvisible(key));
          break;
        default:
          headers.push({ Header: t(key), accessor: key });
      }
    });

    let totalDeposits = 0;
    let totalWithdrawals = 0;
    let totalDifference = 0;

    const rows: any = casinoTransactions.map(item => {
      totalDeposits += item.deposits;
      totalWithdrawals += item.withdrawals;
      totalDifference += item.difference;
      return {
        ...item,
        casino: <span>{`${item.location} (${item.enterprisename})`}</span>,
      };
    });

    rows.push({
      deposits: totalDeposits,
      withdrawals: totalWithdrawals,
      difference: totalDifference,
      casino: <span className={classes.bold}>{t('totals')}</span>,
    });

    return { headers, rows };
  };

  return (
    <div className={classes.container}>
      <DateRangePicker
        unixStartDate={Number(searchParams.get('startdate')) * 1000}
        unixEndDate={Number(searchParams.get('enddate')) * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
          fetchFincancialCasinoTransactions(startDate, endDate);
        }}
      />
      <Card>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(CasinoTransactions);
