import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { SortOrderParamParameter, useGetLoyaltyRankUsers } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, Card, CardContent } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { formatDecimal } from 'src/app/helpers/transformFunctions';

import {
  DataGridContainer,
  DataGridPagination,
  Link,
  PermissionWrapper,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import Select from '@greenisland-common/components/atoms/Select';

import { usePermission } from '../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../Lookup/LookupPermissions';
import { useLoyaltyRankUsersErrorString } from '../Hooks/useLoyaltyRankUsersErrorString';

const DEFAULT_LOYALTY_RANK_USERS_PAGESIZE = 25;

const RankUsers = () => {
  const { t } = useTranslation();
  const { rank }: any = useParams();
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LOYALTY_RANK_USERS_PAGESIZE);
  const [page, setPage] = useState<number>(0);
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canReadLoyaltyRankUsers = usePermission(OnlineCasinoPermissions.getLoyaltyRankUsers);
  const [sortOrder, setSortOrder] = useState<SortOrderParamParameter>(SortOrderParamParameter.descending);

  const getErrorString = useLoyaltyRankUsersErrorString();

  const {
    data: pagedLoyaltyUsers,
    isLoading,
    isError,
    error,
  } = useGetLoyaltyRankUsers(
    rank,
    {
      page,
      pageSize,
      sortOrder,
    },
    { query: { enabled: canReadLoyaltyRankUsers } }
  );

  const headers: GridColumns = [
    {
      headerName: capitalize(t('username')),
      field: 'username',
      renderCell: ({ row }) =>
        canLookupUser ? <Link to={`../../users/${row.userId}/details`}>{row.username}</Link> : undefined,
      flex: 1,
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      headerName: capitalize(t('statusPoints')),
      field: 'statusPoints',
      renderCell: ({ value }) => formatDecimal(value, true),
      flex: 1,
      minWidth: 20,
      filterable: false,
      sortable: false,
    },
  ];
  const rows: GridRowsProp = pagedLoyaltyUsers?.items
    ? [
        ...pagedLoyaltyUsers.items.map((item, i) => ({
          ...item,
          id: i,
        })),
      ]
    : [];

  return (
    <>
      <Select
        id="sortOrder"
        label={capitalize(t('sortOrder'))}
        value={sortOrder}
        onChange={event => setSortOrder(event.target.value as SortOrderParamParameter)}
        options={Object.values(SortOrderParamParameter)}
      />
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <PermissionWrapper
            errorMessage={getErrorString(error)}
            isError={isError}
            isLoading={false}
            permission={OnlineCasinoPermissions.getLoyaltyStatusPointsTx}
          >
            <DataGridContainer>
              <StyledDataGrid
                autoHeight
                density="compact"
                columns={headers}
                rows={rows}
                pageSize={pageSize}
                loading={isLoading}
                paginationMode="server"
                pagination
                page={pagedLoyaltyUsers?.pagingDetails?.currentPage}
                rowCount={pagedLoyaltyUsers?.pagingDetails?.totalItems}
                onPageChange={setPage}
                onPageSizeChange={setPageSize}
                components={{ Pagination: DataGridPagination }}
                componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
              />
            </DataGridContainer>
          </PermissionWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default memo(RankUsers);
