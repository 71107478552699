import { ETransactionsBadRequestTypeSchema } from '@greenisland/stores';

export enum TransactionParamsType {
  START_DATE = 'start',
  END_DATE = 'end',
  MIN_AMOUNT = 'minAmount',
  MAX_AMOUNT = 'maxAmount',
  MIN_PROMO_AMOUNT = 'minpromoamount',
  MAX_PROMO_AMOUNT = 'maxpromoamount',
  USER_ID = 'userId',
  ACCOUNT_ID = 'accountId',
  TRANSACTION_ID = 'txid',
  GAME_PROVIDER_NAME = 'gameProviderName',
  GAME_ID = 'gameid',
  GAME_NAME = 'gamename',
  TYPE = 'type',
  SORT_BY = 'sortby',
  ASCENDING = 'ascending',
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
}

type TransactionsErrorReasons = {
  [key in ETransactionsBadRequestTypeSchema]: string;
};

export const TRANSACTIONS_ERROR_REASONS: TransactionsErrorReasons = {
  [ETransactionsBadRequestTypeSchema.insufficientFiltering]: 'lookup.transactions.errors.insufficientFiltering',
};

export enum AnnualReportParamType {
  USER_ID = 'userId',
}
