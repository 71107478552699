import { Outlet } from 'react-router';
import { faPoll } from '@fortawesome/free-solid-svg-icons';
import { ERegulatorSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import AnnualReport from 'src/pages/OnlineCasino/Reporting/Player/AnnualReport/AnnualReport';
import TransactionsOverview from 'src/pages/OnlineCasino/Reporting/Transactions/TransactionsOverview/TransactionsOverview';

import ActiveBonuses from '../../pages/OnlineCasino/Reporting/Bonuses/ActiveBonuses/ActiveBonuses';
import AwardedToday from '../../pages/OnlineCasino/Reporting/Bonuses/AwardedToday/AwardedToday';
import BonusBlacklist from '../../pages/OnlineCasino/Reporting/Bonuses/BonusBlacklist/BonusBlacklist';
import CashedPromos from '../../pages/OnlineCasino/Reporting/Bonuses/CashedPromos/CashedPromos';
import DepositsOverview from '../../pages/OnlineCasino/Reporting/Deposits/DepositsOverview/DepositsOverview';
import DepositsSummary from '../../pages/OnlineCasino/Reporting/Deposits/DepositsSummary/DepositsSummary';
import EventsStream from '../../pages/OnlineCasino/Reporting/Events/Components/EventsStream';
import EventsMain from '../../pages/OnlineCasino/Reporting/Events/Events';
import Accounting from '../../pages/OnlineCasino/Reporting/Financial/Accounting/Accounting';
import AccountingInvoiceTemplate from '../../pages/OnlineCasino/Reporting/Financial/Accounting/components/FinancialAccountingInvoice';
import CasinoTransactions from '../../pages/OnlineCasino/Reporting/Financial/CasinoTransactions/CasinoTransactions';
import Exports from '../../pages/OnlineCasino/Reporting/Financial/Exports/Exports';
import GameProviderGames from '../../pages/OnlineCasino/Reporting/Financial/GameProviders/DetailsComponents/GameProviderGames';
import GameProviders from '../../pages/OnlineCasino/Reporting/Financial/GameProviders/GameProviders';
import PopularGames from '../../pages/OnlineCasino/Reporting/Games/PopularGames/PopularGames';
import Today from '../../pages/OnlineCasino/Reporting/Games/Today/Today';
import Blocked from '../../pages/OnlineCasino/Reporting/Player/Blocked/Blocked';
import PlayerActivity from '../../pages/OnlineCasino/Reporting/Player/PlayerActivity/PlayerActivity';
import PlayerOverview from '../../pages/OnlineCasino/Reporting/Player/PlayerOverview/PlayerOverview';
import BonusRequest from '../../pages/OnlineCasino/Reporting/Player/RevenueContributors/Components/BonusRequest';
import RevenueContributors from '../../pages/OnlineCasino/Reporting/Player/RevenueContributors/RevenueContributors';
import TopTransactions from '../../pages/OnlineCasino/Reporting/Player/TopTransactions/TopTransactions';
import WithdrawalsOverview from '../../pages/OnlineCasino/Reporting/Withdrawals/WithdrawalsOverview/WithdrawalsOverview';
import WithdrawalsSummary from '../../pages/OnlineCasino/Reporting/Withdrawals/WithdrawalsSummary/WithdrawalsSummary';
import { BaseRoute } from '../RouteInterfaces';

const financialViews: Array<BaseRoute> = [
  {
    path: 'accounting',
    name: 'Accounting',
    element: Accounting,
    mini: 'A',
    permissions: OnlineCasinoPermissions.getAccountingV2,
  },
  {
    path: 'accounting/invoice',
    name: 'Invoice',
    element: AccountingInvoiceTemplate,
    hiddenLink: true,
    permissions: OnlineCasinoPermissions.getAccountingInvoiceV2,
  },
  {
    path: 'game-providers',
    name: 'Game Providers',
    element: GameProviders,
    mini: 'GP',
    permissions: OnlineCasinoPermissions.getGameProvidersReport,
    featureFlag: 'isGamesEnabled',
  },
  {
    path: 'provider-games',
    name: 'Games',
    element: GameProviderGames,
    permissions: OnlineCasinoPermissions.getGameProviderGames,
    featureFlag: 'isGamesEnabled',
  },
  {
    path: 'casino-transactions',
    name: ' Transactions per casino',
    element: CasinoTransactions,
    mini: 'TPC',
    permissions: OnlineCasinoPermissions.getCasinoTransactions,
    featureFlag: 'isCasinoTransactionsEnabled',
  },

  {
    path: 'exports',
    name: 'Exports',
    element: Exports,
    mini: 'E',
    permissions: [OnlineCasinoPermissions.getDailyResults, OnlineCasinoPermissions.getWeeklyResults],
  },
];

const withdrawalViews: Array<BaseRoute> = [
  {
    path: 'overview',
    name: 'Overview',
    element: WithdrawalsOverview,
    mini: 'WO',
    permissions: OnlineCasinoPermissions.getWithdrawalsGeneral,
  },
  {
    path: 'summary',
    name: 'Summary',
    element: WithdrawalsSummary,
    mini: 'WS',
    permissions: OnlineCasinoPermissions.getWithdrawalsSummary,
  },
];

const transactionViews: Array<BaseRoute> = [
  {
    path: 'overview',
    name: 'Overview',
    element: TransactionsOverview,
    mini: 'TO',
    permissions: OnlineCasinoPermissions.getTransactionsGeneral,
  },
  {
    path: 'top',
    name: 'Top Transactions',
    element: TopTransactions,
    mini: 'TT',
    permissions: OnlineCasinoPermissions.getPlayerTopTransactions,
  },
];

const depositViews: Array<BaseRoute> = [
  {
    path: 'overview',
    name: 'Overview',
    element: DepositsOverview,
    mini: 'DO',
    permissions: OnlineCasinoPermissions.getDepositsGeneral,
  },
  {
    path: 'summary',
    name: 'Summary',
    element: DepositsSummary,
    mini: 'DS',
    permissions: OnlineCasinoPermissions.getDepositsSummary,
  },
];

const playerViews: Array<BaseRoute> = [
  {
    path: 'overview',
    name: 'Overview',
    element: PlayerOverview,
    mini: 'O',
    permissions: OnlineCasinoPermissions.getPlayerOverview,
  },
  {
    path: 'annual-report',
    name: 'Annual Report',
    element: AnnualReport,
    mini: 'A',
    showOnlyForSpecificTenant: ERegulatorSchema.DGOJ,
    permissions: [OnlineCasinoPermissions.getAnnualUserReport, OnlineCasinoPermissions.getOrCreateAnnualUserReport],
  },
  {
    path: 'revenue-contributors',
    name: 'Revenue Contributors',
    element: RevenueContributors,
    mini: 'RV',
    permissions: OnlineCasinoPermissions.getPlayersRevenueContributors,
  },
  {
    path: 'revenue-contributors/:userId/bonus-requests',
    name: 'Bonus Request',
    element: BonusRequest,
    hiddenLink: true,
    permissions: OnlineCasinoPermissions.getPlayersRevenueContributorsBonusRequests,
  },
  {
    path: 'player-activity',
    name: 'Player Activity',
    element: PlayerActivity,
    mini: 'PA',
    permissions: OnlineCasinoPermissions.getPlayersActivity,
  },
  {
    path: 'blocked',
    name: 'Blocked',
    element: Blocked,
    mini: 'B',
    permissions: OnlineCasinoPermissions.getPlayersBlocked,
  },
];

const gamesViews: Array<BaseRoute> = [
  {
    path: 'today',
    name: 'Today',
    element: Today,
    mini: 'T',
    permissions: OnlineCasinoPermissions.getGamesToday,
    featureFlag: 'isGamesEnabled',
  },
  {
    path: 'popular',
    name: 'Popular',
    element: PopularGames,
    mini: 'P',
    permissions: OnlineCasinoPermissions.getGamesPopularity,
    featureFlag: 'isGamesEnabled',
  },
];

const bonusViews: Array<BaseRoute> = [
  {
    path: 'active',
    name: 'Active',
    element: ActiveBonuses,
    mini: 'A',
    permissions: OnlineCasinoPermissions.getBonusesActive,
    featureFlag: 'isBonusesEnabled',
  },
  {
    path: 'awarded-today',
    name: 'Awarded Today',
    element: AwardedToday,
    mini: 'AT',
    permissions: OnlineCasinoPermissions.getBonusesAwardedToday,
    featureFlag: 'isBonusesEnabled',
  },
  {
    path: 'cashed-promos',
    name: 'Cashed Promos',
    element: CashedPromos,
    mini: 'C',
    permissions: OnlineCasinoPermissions.getBonusesCashedPromos,
    featureFlag: 'isBonusesEnabled',
  },
  {
    path: 'blacklist',
    name: 'Blacklist',
    element: BonusBlacklist,
    mini: 'B',
    permissions: OnlineCasinoPermissions.getBonusesBlacklist,
    featureFlag: 'isBonusesEnabled',
  },
];

const eventsViews: Array<BaseRoute> = [
  {
    path: 'events',
    name: 'Events',
    collapse: false,
    element: EventsMain,
    mini: 'E',
    permissions: OnlineCasinoPermissions.getEvents,
  },
  {
    path: 'events/stream',
    name: 'Eventstream',
    element: EventsStream,
    hiddenLink: true,
    permissions: [
      OnlineCasinoPermissions.getPagedEventstreamByStreamId,
      OnlineCasinoPermissions.getEventstreamEventname,
    ],
  },
];

export const reportingRoutes: Array<BaseRoute> = [
  {
    path: 'reporting',
    name: 'Reporting',
    icon: faPoll,
    collapse: true,
    element: Outlet,
    permissions: [
      OnlineCasinoPermissions.getCasinoTransactions,
      OnlineCasinoPermissions.getAccountingInvoiceV2,
      OnlineCasinoPermissions.getAccountingV2,
      OnlineCasinoPermissions.getGameProvidersReport,
      OnlineCasinoPermissions.getGameProviderGames,
      OnlineCasinoPermissions.getWithdrawalsSummary,
      OnlineCasinoPermissions.getDailyResults,
      OnlineCasinoPermissions.getWeeklyResults,
      OnlineCasinoPermissions.getPlayerOverview,
      OnlineCasinoPermissions.getPlayersRevenueContributors,
      OnlineCasinoPermissions.getPlayersRevenueContributorsBonusRequests,
      OnlineCasinoPermissions.getPlayerActivity,
      OnlineCasinoPermissions.getPlayersBlocked,
      OnlineCasinoPermissions.getPlayerTopTransactions,
      OnlineCasinoPermissions.getGamesToday,
      OnlineCasinoPermissions.getGamesPopularity,
      OnlineCasinoPermissions.getBonusesActive,
      OnlineCasinoPermissions.getBonusesAwardedToday,
      OnlineCasinoPermissions.getBonusesCashedPromos,
      OnlineCasinoPermissions.getBonusesBlacklist,
      OnlineCasinoPermissions.getEvents,
      OnlineCasinoPermissions.getPagedEventstreamByStreamId,
      OnlineCasinoPermissions.getEventstreamEventname,
    ],
    children: [
      {
        path: 'financial',
        name: 'Financial',
        collapse: true,
        element: Outlet,
        children: financialViews,
        mini: 'F',
        permissions: [
          OnlineCasinoPermissions.getCasinoTransactions,
          OnlineCasinoPermissions.getAccountingInvoiceV2,
          OnlineCasinoPermissions.getAccountingV2,
          OnlineCasinoPermissions.getGameProvidersReport,
          OnlineCasinoPermissions.getGameProviderGames,
          OnlineCasinoPermissions.getOnlineDeposits,
        ],
      },
      {
        path: 'player',
        name: 'Player',
        collapse: true,
        element: Outlet,
        children: playerViews,
        mini: 'P',
        permissions: [
          OnlineCasinoPermissions.getPlayerOverview,
          OnlineCasinoPermissions.getPlayersRevenueContributors,
          OnlineCasinoPermissions.getPlayersRevenueContributorsBonusRequests,
          OnlineCasinoPermissions.getPlayersActivity,
          OnlineCasinoPermissions.getPlayersBlocked,
          OnlineCasinoPermissions.getPlayerTopTransactions,
        ],
      },
      {
        path: 'games',
        name: 'Games',
        collapse: true,
        children: gamesViews,
        element: Outlet,
        mini: 'G',
        permissions: [OnlineCasinoPermissions.getGamesToday, OnlineCasinoPermissions.getGamesPopularity],
        featureFlag: 'isGamesEnabled',
      },
      {
        path: 'bonuses',
        name: 'Bonuses',
        collapse: true,
        children: bonusViews,
        element: Outlet,
        mini: 'B',
        permissions: [
          OnlineCasinoPermissions.getBonusesActive,
          OnlineCasinoPermissions.getBonusesAwardedToday,
          OnlineCasinoPermissions.getBonusesCashedPromos,
          OnlineCasinoPermissions.getBonusesBlacklist,
        ],
        featureFlag: 'isBonusesEnabled',
      },
      {
        path: 'deposits',
        name: 'Deposits',
        collapse: true,
        element: Outlet,
        children: depositViews,
        mini: 'D',
        permissions: [OnlineCasinoPermissions.getOnlineDeposits, OnlineCasinoPermissions.getDepositsSummary],
      },
      {
        path: 'withdrawals',
        name: 'Withdrawals',
        collapse: true,
        element: Outlet,
        children: withdrawalViews,
        mini: 'W',
        permissions: [OnlineCasinoPermissions.getWithdrawalsSummary],
      },
      {
        path: 'transactions',
        name: 'Transactions',
        collapse: true,
        element: Outlet,
        children: transactionViews,
        mini: 'T',
        permissions: [OnlineCasinoPermissions.getTransactionsGeneral, OnlineCasinoPermissions.getPlayerTopTransactions],
      },
      ...eventsViews,
    ],
  },
];

export default reportingRoutes;
