import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Inputfield } from '@greenisland/components';
import { actionCreators, WithdrawalsInner } from '@greenisland/stores';
import { useAppDispatch } from '@greenisland-core/store';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface Props {
  withdrawal: WithdrawalsInner;
}

type FormData = {
  reason: string;
};

export const InCasinoCancelWithdrawalDialog = ({ withdrawal }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleSubmit, register, errors } = useForm<FormData>();
  const [isOpen, setIsOpen] = useState(false);

  const cancelWithdrawal = useCallback(
    ({ reason }: FormData) => {
      dispatch(actionCreators.cancelWithdrawalInCasino(withdrawal.withdrawalId, withdrawal.username, reason));
      setIsOpen(false);
    },
    [dispatch, setIsOpen, withdrawal]
  );

  return (
    <>
      <Button onClick={() => setIsOpen(isOpen => !isOpen)} variant="outlined" size="medium" color="error">
        {t('cancel')}
      </Button>
      <Dialog
        fullWidth
        open={isOpen}
        keepMounted
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit(cancelWithdrawal)}>
          <DialogTitle id="alert-dialog-title">{t('confirm')}</DialogTitle>
          <DialogContent>
            <Inputfield
              error={!!errors.reason}
              fullWidth
              name="reason"
              label={t('reason')}
              inputRef={register({ required: 'required' })}
            />
          </DialogContent>
          <DialogActions sx={{ py: 2, px: 3 }}>
            <Button color="secondary" variant="text" onClick={() => setIsOpen(false)}>
              {t('close')}
            </Button>
            <Button color="error" variant="contained" type="submit">
              {t('decline')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default InCasinoCancelWithdrawalDialog;
