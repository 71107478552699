import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardFooter, Inputfield, Select } from '@greenisland/components';
import { actionCreators, Casino, EWithdrawProvider, handleFailureFromApp } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { formatCurrency } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    formContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(49%, 1fr))',
      gridGap: '15px 15px',
    },
    cardFooter: { margin: 0 },
    actions: { padding: '16px 24px' },
  })
);

type FormData = {
  userId: string;
  amountofWithdrawal: number;
  destination: EWithdrawProvider;
  walletData: string;
};

const WithdrawManually: FunctionComponent<any> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const canWithdrawManually = usePermission(OnlineCasinoPermissions.withdrawManually);
  const { register, handleSubmit, errors, getValues, watch, control, setValue } = useForm<FormData>({
    defaultValues: {
      destination: EWithdrawProvider.BankAccount,
      userId: userId,
      amountofWithdrawal: 0,
      walletData: '',
    },
  });
  const getCasinoList = () => dispatch(actionCreators.getCasinoList());
  const { data: casinoList } = useAppSelector(state => state.casinos);

  const [openDialog, setOpenDialog] = useState<FormData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCasinoList();
    watch();
    setValue('destination', EWithdrawProvider.BankAccount);
  }, []);

  const values = getValues();

  const onOpenDialog = async (data: FormData) => {
    setOpenDialog(data);
  };

  return canWithdrawManually ? (
    <>
      <div className={classes.cardContainer}>
        <Card>
          <CardBody padding>
            <form>
              <div className={classes.formContainer}>
                <Inputfield
                  label={t('userId')}
                  defaultValue={userId}
                  type={'text'}
                  fullWidth
                  inputRef={register({ required: true })}
                  name="userId"
                  error={!!errors.userId}
                  helperText={errors.userId ? t('userIdRequired') : ''}
                />
                <Inputfield
                  label={t('amountOfWithdrawal')}
                  fullWidth
                  type={'number'}
                  inputRef={register({ validate: value => value > 0, required: true })}
                  name="amountofWithdrawal"
                  startIcon={<FontAwesomeIcon icon={faEuroSign} />}
                  defaultValue={0}
                  error={!!errors.amountofWithdrawal}
                  helperText={errors.amountofWithdrawal ? t('amountValidationMessageZero') : ''}
                />
                <Controller
                  as={
                    <Select
                      fullWidth
                      label={t('destination')}
                      selectedValue={values.destination}
                      menuItems={Object.values(EWithdrawProvider).map(provider => ({
                        value: provider,
                        label: t(provider),
                      }))}
                    />
                  }
                  rules={{ required: true }}
                  control={control}
                  onChange={([selected]: any) => selected}
                  defaultValue={values.destination}
                  name="destination"
                />
                {values.destination === EWithdrawProvider.Casino ? (
                  <Controller
                    as={
                      <Select
                        fullWidth
                        label={t('casino')}
                        selectedValue={values.walletData}
                        menuItems={
                          casinoList
                            ? casinoList.map(casino => ({ value: casino.casinoId, label: casino.name }))
                            : [{ value: 'placeholder', label: t('placeholder') }]
                        }
                      />
                    }
                    rules={{ required: true }}
                    control={control}
                    onChange={([selected]: any) => selected}
                    defaultValue={values.walletData}
                    name="walletData"
                  />
                ) : (
                  <Inputfield
                    label={values.destination === EWithdrawProvider.BankAccount ? 'IBAN' : t('walletId')}
                    fullWidth
                    type={'text'}
                    inputRef={register({ required: true })}
                    name="walletData"
                    error={!!errors.walletData}
                    helperText={errors.walletData ? t('fieldIsRequired') : ''}
                  />
                )}
              </div>
            </form>
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button
              size="large"
              color="primary"
              fullWidth
              onClick={handleSubmit(onOpenDialog)}
              loadingState={isLoading}
              disabled={isLoading}
            >
              {t('withdraw')}
            </Button>
          </CardFooter>
        </Card>
      </div>
      {openDialog && (
        <ConfirmDialog
          casinoList={casinoList}
          data={openDialog}
          setOpenDialog={setOpenDialog}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  ) : null;
};

const ConfirmDialog = (props: {
  data: FormData;
  setOpenDialog: any;
  setIsLoading: any;
  casinoList: Casino[] | undefined;
}) => {
  const { data, setOpenDialog, setIsLoading, casinoList } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    setIsLoading(true);
    if (data) {
      try {
        await actionCreators.withdrawManually({ ...data, amountofWithdrawal: Number(data.amountofWithdrawal) });
        dispatch(actionCreators.setSuccessAlert(t('success')));
      } catch (error) {
        handleFailureFromApp(error, dispatch);
      } finally {
        setOpenDialog(false);
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog open={!!props.data}>
      <DialogTitle>{t('confirmWithdrawal')}</DialogTitle>
      <DialogContent>
        <div>
          <div>
            {t('userId')}: {data?.userId}
          </div>
          <div>
            {t('amountOfWithdrawal')}: {data && formatCurrency(data.amountofWithdrawal)}
          </div>
          <div>
            {t('destination')}: {data?.destination}
          </div>
          <div>
            {data?.destination === EWithdrawProvider.BankAccount && `IBAN: ${data.walletData}`}
            {data?.destination === EWithdrawProvider.Casino &&
              `${t('casino')}: ${casinoList?.find(casino => casino.casinoId === data.walletData)?.name}`}
            {data?.destination !== EWithdrawProvider.BankAccount &&
              data?.destination !== EWithdrawProvider.Casino &&
              `${t('walletId')}: ${data.walletData}`}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="transparent" onClick={() => setOpenDialog(false)} color="secondary">
          {t('cancel')}
        </Button>
        <Button variant="contained" type="submit" color="primary" onClick={() => onSubmit()}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(WithdrawManually);
