import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, CheckData, Link, ReactTablePaginated } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getUnixTime } from 'date-fns';

import { setInvisible, transformDefault } from '../../app/helpers/transformFunctions';

const gameServerStyles = makeStyles((theme: Theme) =>
  createStyles({
    periods: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },
    button: {
      cursor: 'pointer',
      backgroundColor: 'white',
      fontSize: theme.typography.button.fontSize,
      fontWeight: theme.typography.button.fontWeight,
      letterSpacing: theme.typography.button.letterSpacing,
      textTransform: theme.typography.button.textTransform,
      color: theme.palette.text.primary,
      padding: '16px 24px',
      border: 0,
      whiteSpace: 'nowrap',
      '&:focus': {
        outline: 'none',
      },
    },
  })
);

const GameServer = () => {
  const classes = gameServerStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState('PT12H');
  const { data: players, fetching } = useAppSelector(state => state.activePlayers);

  const fetchPlayers = (queryParameters: { start: string; page?: number; pageSize?: number }) =>
    dispatch(actionCreators.getActivePlayers(queryParameters));

  useEffect(() => {
    fetchPlayers({ start: period, page: 1 });
  }, [period]);

  const mutatedData = () => {
    if (!players || !players.entries || !players.entries.length) return undefined;
    const keys = Object.keys(players.entries[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'id':
        case 'externalId':
          return setInvisible(key);
        case 'displayName':
          return {
            Header: t(key),
            accessor: key,
            Cell: (options: any) => (
              <Link to={`../players/${options.row.values.externalId}/details`}>{options.row.values.displayName}</Link>
            ),
          };
        default:
          return transformDefault(key, t);
      }
    });

    headers.push({ id: 'gamerounds', accessor: 'gamerounds', disableFilters: true, disableSortBy: true });

    const rows: any = players.entries.map(item => {
      return {
        ...item,
        gamerounds: (
          <Link
            to={`../players/${item.externalId}/gamerounds?startdate=${getUnixTime(
              new Date(new Date().getTime() - 2 * 60 * 60 * 1000)
            )}&enddate=${getUnixTime(new Date())}`}
          >
            {t('gamerounds')}
          </Link>
        ),
      };
    });

    return {
      headers: headers,
      rows: rows,
    };
  };

  const fetchNext = (props: any) => {
    const { pageSize, pageIndex } = props;
    if (players && pageIndex + 1 !== players?.currentPage) {
      fetchPlayers({ start: period, page: pageIndex + 1, pageSize: pageSize });
    }
  };

  return (
    <Card>
      <CardHeader>{t('activePlayers')}</CardHeader>
      <CardBody>
        <div className={classes.periods}>
          <button
            style={{ backgroundColor: period === 'PT12H' ? 'rgb(245,245,245)' : 'white' }}
            className={classes.button}
            onClick={() => setPeriod('PT12H')}
          >
            {t('period12hours')}
          </button>
          <button
            style={{ backgroundColor: period === 'PT6H' ? 'rgb(245,245,245)' : 'white' }}
            className={classes.button}
            onClick={() => setPeriod('PT6H')}
          >
            {t('period6hours')}
          </button>
          <button
            style={{ backgroundColor: period === 'PT1H' ? 'rgb(245,245,245)' : 'white' }}
            className={classes.button}
            onClick={() => setPeriod('PT1H')}
          >
            {t('period1hour')}
          </button>
          <button
            style={{ backgroundColor: period === 'PT15M' ? 'rgb(245,245,245)' : 'white' }}
            className={classes.button}
            onClick={() => setPeriod('PT15M')}
          >
            {t('period15minutes')}
          </button>
        </div>
        <CheckData
          data={mutatedData()}
          fallbackText={t('noData')}
          isLoading={fetching}
          checkProperties={['headers', 'rows']}
          render={({ data }) => (
            <ReactTablePaginated
              columns={data.headers}
              data={data.rows}
              fetchData={(props: any) => fetchNext(props)}
              pageCount={players && players.totalNumberOfPages ? players.totalNumberOfPages : 1}
              pageNumber={players && players.currentPage ? players.currentPage - 1 : 0}
              loading={fetching}
              pageSize={10}
            />
          )}
        />
      </CardBody>
    </Card>
  );
};

export default memo(GameServer);
