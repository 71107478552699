import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button, Card, CardBody, CardHeader, EIcons, ReactTableGI } from '@greenisland/components';

import TableCellActions from '@greenisland-common/components/molecules/TableCellActions';

const emailGroups = [
  { id: '0001', name: 'GIAdmins', emailAddresses: ['a.giadmins@greenisland.be', 'b.giadmins@greenisland.be'] },
  { id: '0002', name: 'GIFrontend', emailAddresses: ['a.gifrontend@greenisland.be', 'b.giadmins@greenisland.be'] },
  { id: '0003', name: 'GISupport', emailAddresses: ['a.gisupport@greenisland.be'] },
  { id: '0004', name: 'GILegal', emailAddresses: ['a.gilegal@greenisland.be', 'b.gilegal@greenisland.be'] },
  { id: '0005', name: 'GIMarketing', emailAddresses: ['a.gimarketing@greenisland.be', 'b.gimarketing@greenisland.be'] },
];

const EmailGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useMemo(() => {
    return emailGroups.map(emailGroup => {
      return {
        ...emailGroup,
        emailAddressesAmount: emailGroup.emailAddresses.length,
        actions: <TableCellActions onEdit={() => navigate(`./edit/${emailGroup.id}`)} onDelete={() => undefined} />,
      };
    });
  }, []);

  return (
    <>
      <Button startIcon={EIcons.faPlus} onClick={() => navigate('./add')}>
        {t('messaging.notificationGroups.addEmailGroup')}
      </Button>
      <Card>
        <CardHeader></CardHeader>
        <CardBody>
          <ReactTableGI
            columns={[
              { Header: '#', accessor: 'emailAddressesAmount', width: 5 },
              { Header: 'name', accessor: 'name' },
              { accessor: 'actions' },
            ]}
            data={data}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default EmailGroups;
