import { Authorization } from '@greenisland/stores';
import { Permission } from '@greenisland-types/authorization';

export class ReadPermission {
  readonly level = Authorization.EPermissionLevel.Read;
  constructor(readonly path: string, readonly productType = Authorization.EProductType.OnlineCasino) {}

  toString() {
    return this.path;
  }
}

export class WritePermission {
  readonly level = Authorization.EPermissionLevel.Write;
  constructor(readonly path: string, readonly productType = Authorization.EProductType.OnlineCasino) {}

  toString() {
    return this.path;
  }
}

export const hasPermission = (currentAgent: Authorization.AgentDetails, permission: Permission) =>
  currentAgent.permissions.some(
    agentPermission =>
      agentPermission.path === permission.path &&
      agentPermission.level === permission.level &&
      agentPermission.productType === permission.productType
  );

export const canAccess = (
  currentAgent: Authorization.AgentDetails,
  permissions: Permission | Permission[],
  strict: boolean
) => {
  if (currentAgent.isAdmin) return true;
  if (Array.isArray(permissions)) {
    return !permissions.length || strict
      ? permissions.every(permission => hasPermission(currentAgent, permission))
      : permissions.some(permission => hasPermission(currentAgent, permission));
  } else {
    return hasPermission(currentAgent, permissions);
  }
};
