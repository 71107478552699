import React from 'react';
import { useTranslation } from 'react-i18next';
import { EVerificationStatus, EVerificationStatusSchema } from '@greenisland/stores';
import { Box } from '@mui/material';

const classes = {
  warning: { color: 'orange' },
  success: { color: 'green' },
  error: { color: 'red' },
  blue: { color: 'blue' },
  noWrap: { whiteSpace: 'nowrap' },
};

interface Props {
  status?: EVerificationStatusSchema;
}

const VerificationStatusField = ({ status }: Props) => {
  const { t } = useTranslation();
  const verifiedOptions: EVerificationStatusSchema[] = [
    EVerificationStatusSchema.Verified,
    EVerificationStatusSchema.VerifiedByDeposit,
    EVerificationStatusSchema.VerifiedByItsMe,
  ];

  return status ? (
    <Box
      sx={{
        whiteSpace: 'nowrap',
        ...(verifiedOptions.includes(status) && classes.success),
        ...(status === EVerificationStatusSchema.Unverified && classes.warning),
        ...(status === EVerificationStatusSchema.WaitingForApproval && classes.blue),
        ...((status === EVerificationStatusSchema.Declined || status === EVerificationStatusSchema.NeedsVerification) &&
          classes.error),
      }}
    >
      {t(status)}
    </Box>
  ) : null;
};

export default VerificationStatusField;
