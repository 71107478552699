import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, CheckData, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { transformCurrency } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridGap: '15px 15px',
      marginBottom: 15,
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  })
);

const CashedPromos = () => {
  const canReadCashedPromos = usePermission(OnlineCasinoPermissions.getBonusesCashedPromos);
  return canReadCashedPromos ? <CashedPromosInnerChild /> : null;
};

const CashedPromosInnerChild = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: cashedPromos, fetching } = useAppSelector(state => state.reportingBonusesCashedPromos);
  const dispatch = useAppDispatch();
  const fetchCashedPromos = async (startDate: number, endDate: number) =>
    dispatch(actionCreators.getBonusesCashedPromos(startDate, endDate));

  const mutatedData = () => {
    if (!cashedPromos || cashedPromos.length === 0) return undefined;

    const keys = Object.keys(cashedPromos[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'totalCashedPromoAmount':
          return transformCurrency(key, t);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    const rows = cashedPromos.map(item => ({ ...item }));
    return { headers, rows };
  };

  return (
    <>
      <div className={classes.container}>
        <DateRangePicker
          unixStartDate={Number(searchParams.get('startdate')) * 1000}
          unixEndDate={Number(searchParams.get('enddate')) * 1000}
          onChange={(startDate, endDate) => {
            replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
            fetchCashedPromos(startDate, endDate);
          }}
        />
      </div>
      <div className={classes.container}>
        <Card>
          <CardBody>
            <CheckData
              fallbackText={t('noData')}
              data={mutatedData()}
              isLoading={fetching}
              checkProperties={['headers', 'rows']}
              render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default memo(CashedPromos);
