import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingGamesSchema } from '@greenisland/stores';
import { capitalize, Card, CardContent, CardHeader } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { DataGridContainer, StyledDataGrid } from '@greenisland-common/components/atoms';

import { useFeatureFlags } from '@greenisland-common/hooks';

import { formatCurrency, formatPercentage, PercentageSpan } from '../../../../../../app/helpers/transformFunctions';

interface Props {
  header: string;
  games?: AccountingGamesSchema;
}

const renderCashAndPromoCells = (params: GridRenderCellParams) => {
  const title = params.colDef.field;
  const value = params.row[title];

  switch (params.row.key) {
    case 'payout':
      return <PercentageSpan value={formatPercentage(value, { fixedTo: 2 })} />;
    case 'revenue':
      return <strong>{formatCurrency(value)}</strong>;
    default:
      return formatCurrency(value);
  }
};

const AccountingGames = ({ header, games }: Props) => {
  const { t } = useTranslation();
  const isTournamentsEnabled = useFeatureFlags('isTournamentsEnabled');
  const isBonusesEnabled = useFeatureFlags('isBonusesEnabled');

  const headers: GridColumns = useMemo(
    () => [
      {
        headerName: '',
        field: 'title',
        minWidth: 250,
        flex: 2,
        renderCell: params => {
          const title = capitalize(t(params.row.key));
          return params.row.key === 'revenue' ? <strong>{title}</strong> : title;
        },
      },
      {
        headerName: t('cash'),
        field: 'cash',
        renderCell: params => renderCashAndPromoCells(params),
        minWidth: 110,
        flex: 1,
      },
      {
        headerName: t('promo'),
        field: 'promo',
        renderCell: params => renderCashAndPromoCells(params),
        minWidth: 110,
        flex: 1,
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      [
        { id: 0, key: 'bet', ...games?.bet },
        isBonusesEnabled && { id: 1, key: 'promoToCash', ...games?.promoToCash },
        isTournamentsEnabled && { id: 2, key: 'tournamentRegister', ...games?.tournamentRegister },
        { id: 3, key: 'win', ...games?.win },
        isTournamentsEnabled && { id: 4, key: 'tournamentPayout', ...games?.tournamentPayout },
        isBonusesEnabled && { id: 5, key: 'freeSpinningsPayout', ...games?.freeSpinningsPayout },
        isBonusesEnabled && { id: 6, key: 'gameProviderCampaignPayout', ...games?.gameProviderCampaignPayout },
        { id: 7, key: 'undoBet', ...games?.undoBet },
        { id: 8, key: 'payout', ...games?.payout },
        { id: 9, key: 'revenue', ...games?.revenue },
      ].filter(Boolean),
    [isBonusesEnabled, isTournamentsEnabled, games]
  );

  return (
    <Card sx={{ pl: 0, pr: 0, mb: 2, maxWidth: 700 }}>
      <CardHeader title={header} />
      <CardContent>
        <DataGridContainer>
          <StyledDataGrid
            hideFooter
            disableColumnFilter
            disableColumnReorder
            disableColumnMenu
            disableColumnResize
            columns={headers}
            rows={rows}
          />
        </DataGridContainer>
      </CardContent>
    </Card>
  );
};

export default AccountingGames;
