import { Controller, get, RegisterOptions, useFormContext } from 'react-hook-form';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseTextFieldProps, MenuItem, SxProps, TextField, Theme } from '@mui/material';

interface Props {
  name: string;
  label: string;
  size?: BaseTextFieldProps['size'];
  options: { value: any; label: string; disabled?: boolean }[];
  disabled?: boolean;
  required?: boolean;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  defaultValue?: string;
  sx?: SxProps<Theme> | undefined;
  clearSelect?: boolean;
}

const SelectInput = ({
  name,
  label,
  size = 'medium',
  rules,
  options,
  disabled = false,
  required = false,
  defaultValue,
  sx,
  clearSelect = false,
}: Props) => {
  const { control, errors, setValue } = useFormContext();

  const handleClearClick = () => {
    setValue(name, defaultValue);
  };

  return (
    <Controller
      defaultValue={defaultValue ?? ''}
      render={({ onChange, value }) => (
        <TextField
          sx={sx}
          fullWidth
          size={size}
          label={label}
          value={value}
          select
          onChange={onChange}
          error={Boolean(get(errors, name))}
          helperText={get(errors, name)?.message}
          disabled={disabled}
          required={required}
          InputProps={{
            endAdornment: clearSelect && (
              <FontAwesomeIcon
                onClick={handleClearClick}
                style={{
                  visibility: value ? 'visible' : 'hidden',
                  marginRight: '20px',
                  cursor: 'pointer',
                }}
                icon={faCircleXmark}
              />
            ),
          }}
        >
          {options?.map(({ label, value, disabled = false }, index) => (
            <MenuItem key={index} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      )}
      rules={rules}
      control={control}
      name={name}
    />
  );
};

export default SelectInput;
