import { memo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CheckData,
  EIcons,
  Inputfield,
  ReactTableGI,
  Select,
} from '@greenisland/components';
import {
  getGetIpWhitelistQueryKey,
  IPWhitelistInner,
  IPWhitelistInnerSchema,
  useAddIpWhitelist,
  useGetIpWhitelist,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';

import { usePermission } from '../../../../app/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'grid',
      gridGap: 16,
    },
    actions: { padding: '16px 24px' },
    link: {
      whiteSpace: 'nowrap',
      '&:first-of-type svg': {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down('xl')]: {
        marginLeft: 15,
        '& h6': { display: 'none' },
      },
    },
  })
);

enum EWhitelistTypes {
  Fraud = 'Fraud',
}

const IPWhitelist = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const canReadIPWhitelist = usePermission(OnlineCasinoPermissions.getIpWhitelist);
  const { data: IPWhitelist, isLoading: fetching } = useGetIpWhitelist({ query: { enabled: canReadIPWhitelist } });
  const { mutate: addIPWhitelist } = useAddIpWhitelist({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetIpWhitelistQueryKey());
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });
  const { register, handleSubmit, errors, control } = useForm<IPWhitelistInner>();

  const mutatedData = () => {
    if (!IPWhitelist || IPWhitelist.length === 0) return undefined;
    const keys = Object.keys(IPWhitelist[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        default:
          return { Header: t(key), accessor: key };
      }
    });

    return { headers, rows: IPWhitelist };
  };

  const onSubmit = (data: IPWhitelistInnerSchema) => {
    addIPWhitelist({ data: data });
    setIsOpen(false);
  };

  return canReadIPWhitelist ? (
    <>
      <Card>
        <CardHeader
          linkChildren={
            <Button onClick={() => setIsOpen(true)} startIcon={EIcons.faPlus}>
              {t('addIp')}
            </Button>
          }
        />
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => (
              <ReactTableGI columns={data.headers} data={data.rows} pagination={data.rows.length > 5} sortable />
            )}
          />
        </CardBody>
      </Card>
      <Dialog fullWidth open={isOpen} onClose={() => setIsOpen(false)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{t('addToIPWhitelist')}</DialogTitle>
          <DialogContent className={classes.content}>
            <Inputfield
              name="name"
              label={t('name')}
              type={'text'}
              inputRef={register({ required: true })}
              error={!!errors.name}
            />
            <Inputfield
              name="ip"
              label={t('ip')}
              type={'text'}
              inputRef={register({ required: true })}
              error={!!errors.ip}
            />
            <Controller
              render={props => {
                return (
                  <Select
                    label={t('type')}
                    selectedValue={props.value}
                    onChange={e => props.onChange(e)}
                    error={!!errors.type}
                    menuItems={Object.values(EWhitelistTypes).map(action => ({ label: action, value: action }))}
                  />
                );
              }}
              control={control}
              rules={{ required: true }}
              defaultValue={EWhitelistTypes.Fraud}
              name={'type'}
            />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button color="secondary" variant="transparent" onClick={() => setIsOpen(false)}>
              {t('cancel')}
            </Button>
            <Button type="submit">{t('add')}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  ) : null;
};

export default memo(IPWhitelist);
