import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { EVerificationStatus, EVerificationStatusSchema, EVoucherStatusSchema } from '@greenisland/stores';
import { Box, SxProps } from '@mui/material';
import { format, formatDuration, fromUnixTime, getUnixTime, intervalToDuration, startOfDay } from 'date-fns';

import { capitalize } from '@greenisland-common/helpers/formatters';

const classes = {
  red: {
    color: 'red',
  },
  green: {
    color: 'green',
  },
  orange: {
    color: 'orange',
  },
  blue: {
    color: 'blue',
  },
  greyItalic: {
    color: 'grey',
    fontStyle: 'ITALIC',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
};

export const getStatusColorClass = (state?: string) => {
  if (!state) return;
  const stateLC = state.toLowerCase();

  if (
    stateLC === 'canceled' ||
    stateLC === 'cancelled' ||
    stateLC === 'revoked' ||
    stateLC === 'awardattempted' ||
    stateLC === 'declinerequested' ||
    stateLC === 'declined' ||
    stateLC === 'processingfailed' ||
    stateLC === 'failed' ||
    stateLC === 'used' ||
    stateLC === 'refused' ||
    stateLC === 'disputelost' ||
    stateLC === 'blocked' ||
    state === EVoucherStatusSchema.NotOrdered ||
    state === EVerificationStatus.NeedsVerification ||
    state === EVerificationStatus.Declined
  )
    return classes.red;
  if (
    stateLC === 'expired' ||
    stateLC === 'pending' ||
    stateLC === 'queued' ||
    stateLC === 'new' ||
    stateLC === 'pended' ||
    stateLC === 'started' ||
    stateLC === 'chargedback' ||
    stateLC === 'inprocess' ||
    stateLC === 'suspicious' ||
    stateLC === 'tobechecked' ||
    state === EVerificationStatus.Unverified ||
    state === EVoucherStatusSchema.Expired
  )
    return classes.orange;
  if (
    stateLC === 'activated' ||
    stateLC === 'accepted' ||
    stateLC === 'active' ||
    stateLC === 'approved' ||
    stateLC === 'processed' ||
    stateLC === 'completedmanually' ||
    stateLC === 'validated' ||
    stateLC === 'completed' ||
    stateLC === 'claimed' ||
    stateLC === 'finished' ||
    stateLC === 'available' ||
    stateLC === 'disputewon' ||
    stateLC === 'refunded' ||
    stateLC === 'done' ||
    stateLC === 'allowed' ||
    stateLC === 'safe' ||
    state === EVerificationStatusSchema.Verified ||
    state === EVerificationStatusSchema.VerifiedByItsMe ||
    state === EVerificationStatusSchema.VerifiedByDeposit
  )
    return classes.green;

  if (state === EVerificationStatus.WaitingForApproval) return classes.blue;
  if (stateLC === 'cancelrequested') return classes.greyItalic;
};

export const getPercentageColorClass = (value: number): SxProps => {
  if (value >= 95) return classes.green;
  if (value < 85) return classes.red;
  else return classes.orange;
};

export const getBooleanColorClass = (status: boolean): SxProps => {
  if (status) return classes.green;
  else return classes.red;
};

export const formatCurrency = (amount: number, currency?: string) => {
  return new Intl.NumberFormat('nl-BE', {
    style: 'currency',
    currency: currency ? currency : 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const formatDecimal = (amount: number, fractionDigits?: boolean) => {
  return new Intl.NumberFormat(
    'nl-BE',
    fractionDigits ? { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 } : { style: 'decimal' }
  ).format(amount);
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    //Check if the input is of correct
    const match = cleaned.match(/^(32|)?(\d{3})(\d{2})(\d{2})(\d{2})$/);
    const matchBel = cleaned.match(/(\d{4})(\d{2})(\d{2})(\d{2})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      const intlCode = match[1] ? '+32 ' : '';
      return (
        <span style={{ whiteSpace: 'nowrap' }}>
          {[intlCode, match[2], ' ', match[3], ' ', match[4], ' ', match[5]].join('')}
        </span>
      );
    }
    if (matchBel) {
      return (
        <span style={{ whiteSpace: 'nowrap' }}>
          {[matchBel[1], ' ', matchBel[2], ' ', matchBel[3], ' ', matchBel[4]].join('')}
        </span>
      );
    }

    return null;
  }
  return null;
};

export const formatPercentage = (
  percentage: number,
  options?: {
    fixedTo?: number;
  }
) => {
  const number = percentage * 100;
  return number.toFixed(options?.fixedTo ? options.fixedTo : 2);
};

export const formatBankAccountNumber = (bankAccountNumber: string) => {
  if (bankAccountNumber) {
    return bankAccountNumber.replace(/(.{4})(\d{4})(\d{4})(\d)/, '$1 $2 $3 $4');
  }
  return '';
};

export const getDateTime = (date: Date, seconds = true) => {
  return format(date, `dd/MM/yyyy HH:mm${seconds ? ':ss' : ''}`);
};

export const getDateTimeFromUnix = (date: number, seconds = true) => {
  return format(fromUnixTime(date), `dd/MM/yyyy HH:mm${seconds ? ':ss' : ''}`);
};

export const getDurationFromSeconds = (
  value: number,
  format: string[] = ['days', 'hours', 'minutes'],
  zero = false,
  delimiter?: string
) => {
  const duration = intervalToDuration({ start: 0, end: value * 1000 });
  const regex = /\d+\s[a-z]/g;

  return formatDuration(duration, { format: format, zero, delimiter })
    .match(regex)
    ?.map(s => s.replace(' ', ''))
    .join(delimiter || '');
};

export const getDateFromUnix = (date: number) => {
  return format(fromUnixTime(date), `dd/MM/yyyy`);
};

export const transformObject = (key: string, t: (key: string) => string) => {
  return {
    Header: t(key),
    accessor: key,
    Cell: ({ value }: any) => {
      return value ? <ObjectSpan value={value} /> : null;
    },
  };
};

export const BooleanSpan = ({
  boolean,
  invert,
  t,
}: {
  boolean: boolean;
  invert?: boolean;
  t?: (key: string) => string;
}) => {
  let value = boolean.toString();
  if (t) {
    value = t(boolean.toString());
  }
  return (
    <Box component="span" sx={getBooleanColorClass(invert ? !boolean : boolean)}>
      {value}
    </Box>
  );
};

export const PercentageSpan = ({ value, colored }: { value: string; colored?: boolean }) => {
  return (
    <Box display="flex" alignItems="center" sx={colored ? getPercentageColorClass(Number(value)) : null}>
      {value.toString()} %
    </Box>
  );
};

export const StatusSpan = ({ value }: { value: string }) => {
  const { t } = useTranslation();
  return (
    <Box
      component="span"
      display="flex"
      alignItems="center"
      sx={{ ...getStatusColorClass(value), whitespace: 'nowrap' }}
    >
      {capitalize(t(value))}
    </Box>
  );
};

export const VerificationStatusSpan = ({ value }: { value: string }) => {
  const { t } = useTranslation();
  return (
    <Box component="span" sx={{ ...getStatusColorClass(value), whitespace: 'nowrap' }}>
      {capitalize(t('verificationStatus.' + value))}
    </Box>
  );
};

export const ObjectSpan = ({ value }: { value: any }) => {
  if (typeof value === 'string') {
    const object = JSON.parse(value);
    return <pre>{JSON.stringify(object, undefined, 4)}</pre>;
  } else {
    return <pre>{JSON.stringify(value, undefined, 4)}</pre>;
  }
};

export const NoWrapSpan = ({ value }: { value: any }) => {
  return <Box sx={{ whiteSpace: 'nowrap' }}>{value}</Box>;
};

export const transformStatus = (key: string, t: (key: string) => string) => {
  return {
    Header: t(key),
    accessor: key,
    Cell: ({ value }: any) => {
      return <StatusSpan value={value} />;
    },
  };
};

export const transformType = (key: string, t: (key: string) => string, noWrap?: boolean) => {
  return {
    Header: t('Type'),
    accessor: key,
    Cell: ({ value }: any) => {
      return noWrap ? <NoWrapSpan value={value ? value : ''} /> : value;
    },
  };
};

export const transformDefault = (key: string, t: (key: string) => string, noWrap?: boolean) => {
  return {
    Header: t(key),
    accessor: key,
    Cell: ({ value }: any) => {
      return noWrap ? <NoWrapSpan value={value ? value : ''} /> : value;
    },
  };
};

export const transformBooleanYesOrNull = (key: string, t: (key: string) => string, invert?: boolean) => {
  return {
    Header: t(key),
    accessor: key,
    Cell: ({ value }: any) => {
      const properValue = value ? value : false;
      return <BooleanSpan invert={invert} boolean={properValue} />;
    },
    sortType: (a: any, b: any) => {
      return a.original[key] - b.original[key];
    },
  };
};

export const transformBoolean = (key: string, t: (key: string) => string, invert?: boolean) => {
  return {
    Header: t(key),
    accessor: key,
    Cell: ({ value }: any) => {
      if (value !== undefined && value !== '') {
        return <BooleanSpan t={t} invert={invert} boolean={value} />;
      } else {
        return t(value);
      }
    },
    sortType: (a: any, b: any) => {
      return a.original[key] - b.original[key];
    },
  };
};

export const transformPercentage = (
  key: string,
  t: (key: string) => string,
  options?: { colored?: boolean; timesHundred?: boolean; fixedTo?: number }
) => {
  return {
    Header: t(key),
    accessor: key,
    sortType: 'basic',
    Cell: ({ value }: any) => (
      <PercentageSpan value={formatPercentage(value, { ...options })} colored={options?.colored} />
    ),
  };
};

export const transformUnixDate = (
  key: string,
  t: (key: string) => string,
  seconds?: boolean
): Column & { sortType: string; filter: string } => {
  return {
    Header: t(key),
    accessor: key,
    sortType: 'basic',
    filter: 'timestamp',
    Cell: ({ value }: any) => {
      return value > 0 ? <span style={{ whiteSpace: 'nowrap' }}>{getDateTimeFromUnix(value, seconds)}</span> : null;
    },
  };
};

export const transformCurrency = (key: string, t: (key: string) => string, header?: string) => {
  return {
    Header: t(header ?? key),
    accessor: key,
    sortType: 'basic',
    Cell: ({ value }: any) => {
      return formatCurrency(value);
    },
  };
};

export const transformDecimal = (key: string, t: (key: string) => string, fractionDigits?: boolean) => {
  return {
    Header: t(key),
    accessor: key,
    sortType: 'basic',
    Cell: ({ value }: any) => {
      if (fractionDigits) {
        return formatDecimal(value, true);
      } else return formatDecimal(value);
    },
  };
};

export const setInvisible = (key: string) => {
  return {
    id: key,
    accessor: key,
    isVisible: false,
  };
};

/**
 * @description make sure that you already calculated the endOfDay/endOfMonth
 * @param date
 */
export const calculateBOEndDate = (date: number) => {
  const newDate = new Date(date * 1000);
  newDate.setDate(newDate.getDate() + 1);
  return getUnixTime(startOfDay(newDate));
};
