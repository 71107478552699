import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  EPlayingBreakReasonTypeV2Schema,
  EPlayingBreakSourceSchema,
  ESortOrderSchema,
  GetAllPlayingBreaksParams,
  PlayingBreakSchema,
  useGetAllPlayingBreaks,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, LinearProgress, Stack, Tooltip } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import { usePermission } from 'src/app/hooks';

import {
  DataGridContainer,
  DataGridPagination,
  DateTime,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';
import Select from '@greenisland-common/components/atoms/Select';

import { StatusSpan } from '../../../../../../app/helpers/transformFunctions';
import useGetPlayingBreakFilterOptions, {
  DEFAULT_FILTER_TYPE,
  FILTER_TYPE,
  NO_REASON_FILTER_TYPE,
} from '../../../hooks/useGetPlayingBreakFilterOptions';
import CancelPlayingBreakDialog from './CancelPlayingBreakDialog';
import PlayingBreakDetail from './PlayingBreakDetail';
import SetPlayingBreakDialog from './SetPlayingBreakDialog';

const PAGE_SIZE = 25;

const PlayingBreak = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();

  const filterByReasonOptions = useGetPlayingBreakFilterOptions(FILTER_TYPE.REASON);
  const filterBySourceOptions = useGetPlayingBreakFilterOptions(FILTER_TYPE.SOURCE);

  const [page, setPage] = useState(0);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [filterByReason, setFilterByReason] = useState(filterByReasonOptions[0]);
  const [filterBySource, setFilterBySource] = useState(filterBySourceOptions[0]);
  const [sortOrder, setSortOrder] = useState<ESortOrderSchema>(ESortOrderSchema.descending);

  const canReadPlayingBreak = usePermission(OnlineCasinoPermissions.getAllPlayingBreaks);
  const canSetPlayingBreak = usePermission(OnlineCasinoPermissions.cancelPlayingBreak);
  const canDeletePlayingBreak = usePermission(OnlineCasinoPermissions.cancelPlayingBreak);

  const isCurrentPlayingBreak = ({ endDate }: PlayingBreakSchema) => {
    return Date.now() / 1000 < (endDate ?? -1);
  };

  const { data, isLoading, isError } = useGetAllPlayingBreaks(
    {
      userId: parseInt(userId),
      currentPageNumber: page,
      resultsPerPage: PAGE_SIZE,
      endDateSortOrder: sortOrder,
      ...(filterByReason !== DEFAULT_FILTER_TYPE
        ? { type: filterByReason === NO_REASON_FILTER_TYPE ? EPlayingBreakReasonTypeV2Schema.none : filterByReason }
        : {}),
      ...(filterBySource !== DEFAULT_FILTER_TYPE ? { source: filterBySource } : {}),
    } as GetAllPlayingBreaksParams,
    {
      query: {
        enabled: canReadPlayingBreak,
      },
    }
  );

  const headers: GridColumns = [
    {
      headerName: capitalize(t('startDate')),
      field: 'startDate',
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => <DateTime dateTime={row.startDate ?? 0} />,
    },
    {
      headerName: capitalize(t('endDate')),
      field: 'endDate',
      flex: 1,
      minWidth: 200,
      renderCell: ({ value }) => value,
    },
    {
      headerName: capitalize(t('reason')),
      field: 'reasonTypeV2',
      flex: 1,
      minWidth: 200,
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{capitalize(value)}</span>
        </Tooltip>
      ),
    },
    {
      headerName: capitalize(t('source')),
      field: 'source',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => capitalize(value),
    },
    {
      headerName: capitalize(t('cancelationStatus')),
      field: 'cancelationStatus',
      renderCell: ({ value }) => value,
      width: 150,
    },
    { headerName: '', field: 'actions', renderCell: ({ value }) => value, align: 'right', flex: 1 },
  ];

  const rows =
    data?.items?.map((item, i) => {
      return {
        ...item,
        reasonTypeV2: `${item?.reasonTypeV2} ${item?.reason ? `(${item.reason})` : ''}`,
        endDate: (
          <>
            <Stack direction="row">
              {item?.originalEndDate && item?.endDate !== item.originalEndDate && (
                <Box sx={{ marginRight: 1, textDecoration: 'line-through' }}>
                  {<DateTime dateTime={item.originalEndDate ?? 0} />}
                </Box>
              )}
              {<DateTime dateTime={item.endDate ?? 0} />}
            </Stack>
          </>
        ),
        cancelationStatus: (
          <StatusSpan
            value={
              item?.cancellationRequests && item?.cancellationRequests?.length > 0
                ? item?.cancellationRequests?.sort(
                    (prev, next) =>
                      (next?.approvalDecision?.decisionDate ?? -1) - (prev?.approvalDecision?.decisionDate ?? -1)
                  )[0]?.status
                : 'none'
            }
          />
        ),
        id: i,
        actions: (
          <>
            {canDeletePlayingBreak && (item?.endDate ?? 0) > Date.now() / 1000 && (
              <Button variant="text" color="error" onClick={() => setOpenCancelDialog(true)}>
                <FontAwesomeIcon icon={faTimes} sx={{ marginRight: 1 }} /> {t('cancel')}
              </Button>
            )}
          </>
        ),
      };
    }) || [];

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('settings.playingBreak.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getAllPlayingBreaks}
      >
        <>
          <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
            <Select
              id={t('filterByReason')}
              label={capitalize(t('filterByReason'))}
              value={filterByReason}
              onChange={event => setFilterByReason(event.target.value as EPlayingBreakReasonTypeV2Schema)}
              options={filterByReasonOptions}
            />
            <Select
              id={t('filterBySource')}
              label={capitalize(t('filterBySource'))}
              value={filterBySource}
              onChange={event => setFilterBySource(event.target.value as EPlayingBreakSourceSchema)}
              options={filterBySourceOptions}
            />
            <Select
              id={t('sortByEndDate')}
              label={capitalize(t('sortByEndDate'))}
              value={sortOrder}
              onChange={event => setSortOrder(event.target.value as ESortOrderSchema)}
              options={Object.values(ESortOrderSchema)}
            />
            {canSetPlayingBreak && (
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button variant="outlined" color="secondary" size="medium" onClick={() => setOpenAddDialog(true)}>
                  {t('setPlayingBreak')}
                </Button>
              </Box>
            )}
          </Box>
          <DataGridContainer>
            <LimitedDataGrid
              columns={headers}
              rows={rows}
              autoHeight
              loading={isLoading}
              getDetailPanelContent={({ row }) => <PlayingBreakDetail playingBreak={row as PlayingBreakSchema} />}
              getDetailPanelHeight={() => 'auto'}
              pagination
              paginationMode="server"
              pageSize={PAGE_SIZE}
              onPageChange={(page: number) => {
                if (!isLoading) {
                  setPage(page);
                }
              }}
              rowCount={data?.pagingDetails?.totalItems}
              page={data?.pagingDetails?.currentPage}
              components={{
                Pagination: DataGridPagination,
                LoadingOverlay: LinearProgress,
              }}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      </PermissionWrapper>
      {openAddDialog && (
        <SetPlayingBreakDialog
          openDialog={openAddDialog}
          setOpenDialog={setOpenAddDialog}
          userId={parseInt(userId)}
          currentPlayingBreak={data?.items?.find(isCurrentPlayingBreak)}
        />
      )}
      {openCancelDialog && (
        <CancelPlayingBreakDialog
          openDialog={openCancelDialog}
          setOpenDialog={setOpenCancelDialog}
          userId={parseInt(userId)}
        />
      )}
    </Stack>
  );
};

export default PlayingBreak;
