import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { faCheckCircle, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, CheckData, ReactTableGI } from '@greenisland/components';
import {
  AgentPermissionsSchema,
  AgentRoleSchema,
  AgentSchema,
  useGetAgentDetails,
  useResetAgentPassword,
} from '@greenisland/stores';
import { AuthorizationPermissions } from '@greenisland-core/permissions';
import { Box } from '@mui/material';

import ConfirmDialog from '../../../../../app/components/ConfirmDialog';
import { BooleanSpan, setInvisible } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import NestedRoles from './NestedRoles';

const AgentDetails = () => {
  const { t } = useTranslation();
  const { agentId = '' } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const canReadAgentDetails = usePermission(AuthorizationPermissions.getAgentDetails);

  const { data: agent, isLoading: fetching } = useGetAgentDetails(agentId, { query: { enabled: canReadAgentDetails } });
  const { mutate: resetPassword } = useResetAgentPassword();

  const mutatePermissions = (permissions: AgentPermissionsSchema) => {
    if (permissions.length === 0) return undefined;
    const keys = Object.keys(permissions[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'permissionId':
          return setInvisible(key);
        default:
          return { Header: t(key), accessor: key };
      }
    });
    return { headers, rows: permissions };
  };

  const headers = [
    { id: 'title', accessor: 'title', disableFilters: true, disableSortBy: true },
    { id: 'value', accessor: 'value', disableFilters: true, disableSortBy: true },
  ];

  const mutatedData = (agent: AgentSchema) => {
    const rows: any = [];
    Object.entries(agent).forEach(item => {
      const [title, value] = item;
      if (title === 'email' || title === 'name' || title === 'isAdmin' || title === 'phone') {
        rows.push({
          title: t(title),
          value: typeof value === 'boolean' ? <BooleanSpan boolean={value} /> : value ? value : '/',
        });
      }
    });
    return { headers, rows };
  };

  return (
    <>
      <CheckData
        fallbackText={t('noData')}
        data={agent}
        isLoading={fetching}
        render={({ data }) => (
          <Card>
            <CardBody padding>
              {agent?.isAdmin ? (
                <Box sx={{ marginBottom: '16px', paddingBottom: '8px', color: 'green' }}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  {t('isSuperAdmin')}
                </Box>
              ) : null}
              {agent?.isLocked ? (
                <Box sx={{ marginBottom: '16px', paddingBottom: '8px', color: 'red' }}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  {t('locked').toUpperCase()}
                </Box>
              ) : null}
              <ReactTableGI columns={mutatedData(data).headers} data={mutatedData(data).rows} noTableHeader />
              <CheckData
                fallbackText={t('noPermissions')}
                data={data.permissions && mutatePermissions(data.permissions)}
                checkProperties={['headers', 'rows']}
                render={({ data }) => (
                  <Box sx={{ margin: '0.8rem', fontWeight: 'normal' }}>
                    <h5>{t('permissions')}</h5>
                    <Box sx={{ margin: '0.8rem', background: 'white' }}>
                      <ReactTableGI columns={data.headers} data={data.rows} sortable />
                    </Box>
                  </Box>
                )}
              />
              <CheckData
                shouldRenderFallback={false}
                data={data.agentRoles}
                render={({ data }) => (
                  <Box sx={{ margin: '0.8rem', fontWeight: 'normal' }}>
                    <h5>{t('roles')}</h5>
                    {data.map((role: AgentRoleSchema) => {
                      return <NestedRoles key={role.roleId} isSubRole level={1} agentRole={role} />;
                    })}
                  </Box>
                )}
              />
            </CardBody>
          </Card>
        )}
      />
      <ConfirmDialog
        title={t('resetPassword')}
        content={`${t('confirmResetPassword')}: ${agent?.email}`}
        isOpen={isOpen}
        setIsOpen={(open: boolean) => setIsOpen(open)}
        onConfirm={() => {
          if (agent) resetPassword({ agentId: agentId });
        }}
      />
    </>
  );
};

export default memo(AgentDetails);
