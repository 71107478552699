import { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PromotionalCalendarSchema } from '@greenisland/stores';
import { Box, Button, capitalize, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';
import { DATETIME_INPUT_FORMAT, DATETIME_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';
import Input from '@greenisland-common/components/molecules/Input';

import { useGetSortedAutocompleteCampaignActions } from '@greenisland-common/hooks/useGetSortedAutocompleteCampaignActions';

import { defaultPromotionalCalendarPrizeSettingValues, FORM_FIELD_PRIZES_KEY } from '../../constants';
import {
  CalendarPromotionDialogSchema,
  PromotionalCalendarLoyaltyRankOption,
  transformPromotionalCalendarDefaultEditSettings,
} from './helpers';
import PromotionalCalendarRankSettings from './PromotionalCalendarRankSettings';

interface Props {
  selectedPromotionalCalendar?: PromotionalCalendarSchema;
  availableLoyaltyRankOptions: PromotionalCalendarLoyaltyRankOption[];
}

const PromotionalCalendarPrizeSettings = ({ selectedPromotionalCalendar, availableLoyaltyRankOptions }: Props) => {
  const { t } = useTranslation();
  const { control, watch, setValue, getValues } = useFormContext<CalendarPromotionDialogSchema>();
  const { fields, remove } = useFieldArray({
    control,
    name: FORM_FIELD_PRIZES_KEY,
  });
  const { actions, isLoading: isLoadingActions } = useGetSortedAutocompleteCampaignActions();

  const prizes = watch('prizes');
  const chosenMinimalLoyaltyRankRequirement = watch('minimalLoyaltyRankRequirement');

  const [isActiveCardSettings, setIsActiveCardSettings] = useState(false);

  useEffect(() => {
    if (fields?.length === 0) {
      setIsActiveCardSettings(false);
    } else {
      setIsActiveCardSettings(true);
    }
  }, [fields?.length]);

  const addPrizeSectionSettingsHandler = useCallback(() => {
    if (!isActiveCardSettings) {
      setIsActiveCardSettings(true);
    }

    if (prizes?.length >= 1) {
      const lastElementIndex = prizes.length - 1;
      const { dayOrdinal, activeTimestamp, endTimestamp, activeDate, endDate, ranks } = prizes[lastElementIndex];

      const ranksWithoutIds = ranks?.map(rank => ({
        ...rank,
        id: undefined,
      }));

      return setValue(FORM_FIELD_PRIZES_KEY, [
        ...getValues().prizes,
        {
          dayOrdinal: dayOrdinal + 1,
          activeTimestamp,
          endTimestamp,
          activeDate,
          endDate,
          ranks: ranksWithoutIds,
        },
      ]);
    }

    return setValue(FORM_FIELD_PRIZES_KEY, [defaultPromotionalCalendarPrizeSettingValues]);
  }, [getValues, isActiveCardSettings, prizes, setValue]);

  const isRemoveButtonForPrizeActive = useCallback(
    (prize: any, prizeIndex: number) => {
      const groupedByOrdinalData = transformPromotionalCalendarDefaultEditSettings(selectedPromotionalCalendar);
      const dayOrdinals = groupedByOrdinalData?.prizes?.map(prize => prize?.dayOrdinal);

      if (!selectedPromotionalCalendar || !dayOrdinals?.includes(prize.dayOrdinal)) {
        return (
          <FontAwesomeIcon
            icon={faTrash}
            sx={{ marginLeft: 1, color: 'error.main', cursor: 'pointer' }}
            onClick={() => remove(prizeIndex)}
          />
        );
      }

      return null;
    },
    [remove, selectedPromotionalCalendar]
  );

  return (
    <>
      <Typography fontWeight={500} variant="body1" fontSize="large" sx={{ pt: 1, mb: 1 }}>
        {capitalize(t('marketing.promotionalCalendar.titles.prizeSettings'))}
      </Typography>
      <Stack spacing={2} position="relative">
        {isActiveCardSettings ? (
          <Box display="flex" flexDirection="column">
            {fields?.map((entry, index) => {
              return (
                <Box
                  key={entry.id}
                  px={4}
                  py={1}
                  mb={2}
                  sx={{
                    border: '1px solid #eee',
                    borderRadius: '4px',
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Controller
                      name={''}
                      control={control}
                      defaultValue={''}
                      render={() => (
                        <Typography id={entry?.id} fontWeight={600} variant="body2" fontSize="large" my={2}>
                          {`${t('marketing.promotionalCalendar.titles.prize')} ${index + 1}`}
                        </Typography>
                      )}
                    />
                    {isRemoveButtonForPrizeActive(entry, index)}
                  </Box>
                  <Box>
                    <Input
                      fullWidth
                      size="small"
                      label={capitalize(t('marketing.promotionalCalendar.titles.dayOrdinal'))}
                      control={control}
                      type="number"
                      name={`prizes[${index}].dayOrdinal`}
                      sx={{ mb: 2 }}
                      required
                      rules={{
                        min: 1,
                        required: t('fieldIsRequired'),
                        valueAsNumber: true,
                      }}
                      InputProps={{
                        inputProps: { min: 1, step: 'any' },
                      }}
                      defaultValue={entry?.dayOrdinal}
                    />
                    <Controller
                      control={control}
                      name={`prizes[${index}].activeDate`}
                      defaultValue={entry?.activeDate}
                      rules={{ required: t('fieldIsRequired') }}
                      render={({ onChange, value }) => (
                        <DateTimePicker
                          label={capitalize(t('startDate'))}
                          value={value}
                          onChange={value => onChange(value)}
                          renderInput={params => (
                            <TextField {...params} size="small" sx={{ mb: 2 }} required={true} fullWidth />
                          )}
                          inputFormat={DATETIME_INPUT_FORMAT}
                          mask={DATETIME_INPUT_MASK}
                          maxDate={prizes[index]?.endDate}
                        />
                      )}
                    />
                    <Controller
                      name={`prizes[${index}].endDate`}
                      defaultValue={entry?.endDate}
                      control={control}
                      rules={{ required: t('fieldIsRequired') }}
                      render={({ onChange, value }) => (
                        <DateTimePicker
                          label={capitalize(t('endDate'))}
                          value={value}
                          onChange={value => onChange(value)}
                          renderInput={params => (
                            <TextField size="small" sx={{ mb: 2 }} required={true} fullWidth {...params} />
                          )}
                          inputFormat={DATETIME_INPUT_FORMAT}
                          mask={DATETIME_INPUT_MASK}
                          minDate={prizes[index]?.activeDate}
                        />
                      )}
                    />
                  </Box>
                  <PromotionalCalendarRankSettings
                    ordinalIndex={index}
                    selectedPromotionalCalendar={selectedPromotionalCalendar}
                    isLoadingActions={isLoadingActions}
                    actionOptions={actions}
                    availableLoyaltyRankOptions={availableLoyaltyRankOptions}
                  />
                </Box>
              );
            })}
          </Box>
        ) : null}
        <Button
          onClick={addPrizeSectionSettingsHandler}
          variant="contained"
          size="medium"
          startIcon={<FontAwesomeIcon icon={faAdd} />}
          sx={{ width: 'fit-content' }}
          disabled={!chosenMinimalLoyaltyRankRequirement}
        >
          {t('marketing.promotionalCalendar.titles.prize')}
        </Button>
      </Stack>
    </>
  );
};

export default PromotionalCalendarPrizeSettings;
