import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody } from '@greenisland/components';
import { handleFailureFromApp, useGetDailyResultsHook, useGetWeeklyResultsHook } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getUnixTime } from 'date-fns';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { downloadBase64File } from '@greenisland-common/helpers';

import { usePermission } from '../../../../../app/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 1fr)',
      gridGap: '15px 15px',
      marginBottom: 15,
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  })
);

const Export = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loadingDaily, setLoadingDaily] = useState(false);
  const [loadingWeekly, setLoadingWeekly] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const canReadDailyResults = usePermission(OnlineCasinoPermissions.getDailyResults);
  const canReadWeeklyResults = usePermission(OnlineCasinoPermissions.getWeeklyResults);

  const getDailyResults = useGetDailyResultsHook();
  const getWeeklyResults = useGetWeeklyResultsHook();

  const downloadDaily = async () => {
    setLoadingDaily(true);
    try {
      const data = await getDailyResults({
        startdate: getUnixTime(startDate),
        enddate: getUnixTime(endDate),
      });
      downloadBase64File(data);
    } catch (error) {
      handleFailureFromApp(error, dispatch);
    } finally {
      setLoadingDaily(false);
    }
  };

  const downloadWeekly = async () => {
    setLoadingWeekly(true);
    try {
      const data = await getWeeklyResults({
        startdate: getUnixTime(startDate),
      });
      downloadBase64File(data);
    } catch (error) {
      handleFailureFromApp(error, dispatch);
    } finally {
      setLoadingWeekly(false);
    }
  };

  return canReadDailyResults || canReadWeeklyResults ? (
    <div className={classes.container}>
      <Card className={classes.cardContainer}>
        <CardBody padding>
          <div className={classes.container}>
            <DateRangePicker
              onChange={(startDate, endDate) => {
                setStartDate(new Date(startDate * 1000));
                setEndDate(new Date(endDate * 1000));
              }}
            />
            {canReadDailyResults && (
              <Button size="large" color="primary" loadingState={loadingDaily} onClick={downloadDaily} type="button">
                {t('downloadDailyResults')}
              </Button>
            )}
            {canReadWeeklyResults && (
              <Button size="large" color="primary" loadingState={loadingWeekly} onClick={downloadWeekly} type="button">
                {t('downloadWeeklyResults')}
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  ) : null;
};

export default memo(Export);
