import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { faAdd, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GameSchema,
  GameSortingTypeParameter,
  useGetGameProvider,
  useGetGamesByQueryFilters,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useDebouncedValue } from '@lilib/hooks';
import { Button, LinearProgress, Stack, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../../../app/hooks';
import ImageCell from '../Components/ImageCell';
import { GameFilterType } from '../Games/constants';
import GamesFilter from '../Games/GamesFilter';
import useGetGameFiltersQuery from '../Games/hooks/useGetGameFiltersQuery';
import useUpdateGameFilter from '../Games/hooks/useUpdateGameFilter';

const PAGE_SIZE = 25;

type Row = GameSchema & { id: string | undefined };

const { addGameV2, updateGameV2, getGameStudios, getGameCategories } = OnlineCasinoPermissions;

const GamesList = () => {
  const { t } = useTranslation();
  const { providerId }: any = useParams();
  const navigate = useNavigate();
  const canReadGames = usePermission(OnlineCasinoPermissions.getGamesByQueryFilters);
  const canAddGame = usePermission([addGameV2, getGameStudios, getGameCategories]);
  const canEditGame = usePermission([updateGameV2, getGameStudios, getGameCategories]);
  const {
    sortByQuery,
    searchQuery,
    themesQuery,
    categoriesQuery,
    studiosQuery,
    additionalFiltersQuery,
    pageQuery,
    pageSizeQuery,
  } = useGetGameFiltersQuery();
  const updateFilterHandler = useUpdateGameFilter();
  const [debouncedSearch] = useDebouncedValue(searchQuery, { wait: 500 });
  const { data: provider } = useGetGameProvider(providerId);

  const additionalFiltersParams = additionalFiltersQuery?.split(',');

  const {
    data: games,
    isLoading,
    isError,
    refetch,
  } = useGetGamesByQueryFilters(
    {
      providerId,
      ...(debouncedSearch && debouncedSearch?.length > 0 ? { searchString: debouncedSearch } : {}),
      ...{ sorting: (sortByQuery as GameSortingTypeParameter) || GameSortingTypeParameter.releaseDate },
      ...(themesQuery && themesQuery.length > 0 ? { themesQuery } : {}),
      ...(studiosQuery && studiosQuery.length > 0 ? { studiosQuery } : {}),
      ...(categoriesQuery && categoriesQuery.length > 0 ? { categoriesQuery } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.FEATURED) ? { filterFeatured: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.ENABLED) ? { filterEnabled: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.NEW) ? { filterNew: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.POPULAR) ? { filterPopular: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.LIVE) ? { filterLive: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.HAS_JACKPOT) ? { filterHasJackpot: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.EXCLUSIVE) ? { filterExclusive: true } : {}),
      resultsPerPage: pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE,
      currentPageNumber: pageQuery ? Number(pageQuery) : 0,
    },
    { query: { enabled: canReadGames && !!providerId } }
  );

  const rows = useMemo(() => games?.results.map<Row>(game => ({ ...game, id: game.gameId })) ?? [], [games]);

  const columns = useMemo<GridColumns<Row>>(
    () => [
      { field: 'gameId', headerName: t('content.games.games.id'), flex: 0.3, sortable: false },
      { field: 'gameName', headerName: t('content.games.games.gameName'), flex: 1, sortable: false, minWidth: 150 },
      {
        field: 'providerName',
        headerName: t('content.games.games.providerName'),
        flex: 1,
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'gameImages.thumbnail',
        headerName: t('content.games.games.thumbnail'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.gameImages.thumbnail} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'gameImages.background',
        headerName: t('content.games.games.background'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.gameImages.background} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'gameImages.featured',
        headerName: t('content.games.games.featuredImg'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.gameImages.featured} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'gameImages.criteoAdrollBig',
        headerName: t('content.games.games.criteoAdrollBig'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.gameImages.criteoAdrollBig} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'gameImages.criteoAdrollSmall',
        headerName: t('content.games.games.criteoAdrollSmall'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => (
          <ImageCell value={params.row.gameImages.criteoAdrollSmall} />
        ),
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'featured',
        headerName: t('content.games.games.featured'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'enabled',
        headerName: t('content.games.games.enabled'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 100,
      },
      ...(canEditGame
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<Row>) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  label="Edit"
                  onClick={() => navigate(`../providers/${params.row.providerFk}/games/${params.row.gameId}`)}
                  key="edit"
                />,
              ],
              flex: 0.5,
            },
          ]
        : []),
    ],
    [canEditGame, navigate, t]
  );
  return canReadGames ? (
    <Stack spacing={2}>
      {provider?.providerName ? <Typography variant="h4">{provider.providerName}</Typography> : null}
      {canAddGame ? (
        <div>
          <Button
            variant="text"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            onClick={() => navigate(`../providers/${providerId}/games/add`)}
          >
            {t('addGame')}
          </Button>
        </div>
      ) : null}
      <GamesFilter />
      {!isError ? (
        <DataGridContainer>
          <StyledDataGrid
            density="compact"
            autoHeight
            loading={isLoading}
            rows={rows}
            rowCount={games?.pagingDetails?.totalItems}
            columns={columns}
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            pagination
            page={games?.pagingDetails?.currentPage}
            pageSize={pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE}
            paginationMode="server"
            onPageChange={newPage => {
              if (!isLoading) {
                updateFilterHandler(GameFilterType.PAGE, newPage.toString());
              }
            }}
            onPageSizeChange={pageSize => updateFilterHandler(GameFilterType.PAGE_SIZE, pageSize.toString())}
            disableDensitySelector
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableChildrenSorting
            disableChildrenFiltering
            disableMultipleColumnsSorting
            disableColumnResize
            disableColumnReorder
            isRowSelectable={() => false}
            components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
          />
        </DataGridContainer>
      ) : (
        <ErrorState errorMessage={t('content.games.providers.gamesLoadingError')} retryAction={() => refetch()} />
      )}
    </Stack>
  ) : null;
};

export default memo(GamesList);
