import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  faCaretDown,
  faFileArchive,
  faFileInvoice,
  faFileInvoiceDollar,
  faGift,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetBasicAccountData } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material';

import { useFeatureFlags } from '@greenisland-common/hooks';

import { usePermission } from '../../../../../../../app/hooks';
import { EventStreamParamsType } from '../../Constants/Eventstreams';

interface Props {
  userId: string;
}

const EventstreamsMenu = ({ userId }: Props) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'lookup.userDetailsMenu' });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
  const isOpen = Boolean(anchorEl);
  const canReadEventstreamById = usePermission(OnlineCasinoPermissions.getPagedEventstreamByStreamId);
  const canReadBasicAccountData = usePermission(OnlineCasinoPermissions.getBasicAccountData);
  const isLoyaltyEnabled = useFeatureFlags('isLoyaltyEnabled');
  const { data: accountData } = useGetBasicAccountData(userId, {
    query: { enabled: canReadBasicAccountData },
  });

  return (
    <>
      <Button
        sx={{ display: 'flex', alignItems: 'flex-start' }}
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        endIcon={<FontAwesomeIcon icon={faCaretDown} />}
        size="small"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        {t('eventstreams')}
      </Button>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {canReadEventstreamById && (
          <MenuItem
            onClick={() =>
              userId && navigate(`../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=user/${userId}`)
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faFileArchive} />
            </ListItemIcon>
            {t('userEventstream')}
          </MenuItem>
        )}
        {canReadEventstreamById && (
          <MenuItem
            onClick={() =>
              userId && navigate(`../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=cashuser/${userId}`)
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
            </ListItemIcon>
            {t('cashUserEventstream')}
          </MenuItem>
        )}
        {canReadEventstreamById && (
          <MenuItem
            onClick={() =>
              accountData?.account?.accountId &&
              navigate(
                `../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=account/${accountData?.account?.accountId}`
              )
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faFileInvoice} />
            </ListItemIcon>
            {t('accountEventstream')}
          </MenuItem>
        )}
        {canReadEventstreamById && isLoyaltyEnabled && (
          <MenuItem
            onClick={() =>
              accountData?.account?.accountId &&
              navigate(
                `../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=loyaltyv2/${accountData?.account?.accountId}`
              )
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faGift} />
            </ListItemIcon>
            {t('loyaltyEventstream')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default EventstreamsMenu;
