import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@greenisland/components';
import { actionCreators, IEntityState } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { List, ListItem, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      '& h1': {
        color: theme.palette.primary.main,
        fontWeight: 900,
      },
      '& h4': {
        textAlign: 'center',
      },
    },
    listItem: {
      display: 'flex',
      justifyContent: 'center',
    },
    helpTextContainer: {
      padding: 20,
      boxSizing: 'border-box',
    },
  })
);

const Oops = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const agentEntities = useAppSelector(state =>
    state.authentication.data ? state.authentication.data?.startupAgent?.entities : []
  );
  const setProduct: (ids: IEntityState) => void = async (ids: IEntityState) => dispatch(actionCreators.setProduct(ids));
  const visitLink = (entity: string, product: string) =>
    setProduct({ selectedEntity: entity, selectedProduct: product });

  return (
    <div className={classes.container}>
      <h1>OOPS!</h1>
      <div className={classes.helpTextContainer}>
        <h4>{t('oopsError')}</h4>
        <h4>{t('oopsEntityAccess')}</h4>
      </div>
      {agentEntities &&
        agentEntities.map(({ entityName, products }, entityKey) => (
          <Fragment key={entityKey}>
            <h5>{entityName}</h5>
            <List>
              {products.map((product, key) => {
                const { productName, productType } = product;
                return (
                  <ListItem key={key} className={classes.listItem}>
                    <div onClick={() => visitLink(entityName, productName)}>
                      <Link to={`/${entityName}/${productName}`}>
                        {productName} - {productType}
                      </Link>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          </Fragment>
        ))}
    </div>
  );
};

export default Oops;
