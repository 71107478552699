import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAccountingV2 } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Card, CardContent, CircularProgress, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { usePermission } from '../../../../../app/hooks';
import { AccountingGames, Cashflow, Refunds } from './components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      width: '100%',
      [theme.breakpoints.up('sm')]: { width: 'calc(100% / 2)' },
      [theme.breakpoints.up('lg')]: { width: 'calc(100% / 3)' },
      [theme.breakpoints.up('xl')]: { width: 'calc(100% / 4)' },
    },
  })
);

const Accounting = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const canReadAccounting = usePermission(OnlineCasinoPermissions.getAccountingV2);
  const canReadAccountingInvoice = usePermission(OnlineCasinoPermissions.getAccountingInvoiceV2);
  const date = searchParams.get('date') || format(new Date(), 'yyyy-MM-dd');
  const { data, isLoading } = useGetAccountingV2({ date }, { query: { enabled: canReadAccounting } });

  return (
    <div>
      <div className={classes.cardContainer}>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <DatePicker
              label={t('yearAndMonth')}
              value={new Date(date)}
              maxDate={new Date()}
              views={['month', 'year']}
              onChange={date => {
                if (date) {
                  replaceUrlQueryParameters({ date: format(date, 'yyyy-MM-dd') }, setSearchParams);
                }
              }}
              renderInput={params => <TextField {...params} helperText={null} />}
            />
            {canReadAccountingInvoice && (
              <Button sx={{ mt: 2 }} fullWidth variant="contained" onClick={() => navigate(`invoice?date=${date}`)}>
                {t('invoice')}
              </Button>
            )}
          </CardContent>
        </Card>
      </div>

      {isLoading || !data ? (
        <CircularProgress sx={{ mt: 4, ml: 23 }} />
      ) : (
        <>
          <Cashflow cashflow={data.cashflow} />
          <Refunds refunds={data.refunds} refundsTestUser={data.refundsTestUser} />
          <AccountingGames header={t('games')} games={data.games} />
          <AccountingGames header={t('gamesTestUser')} games={data.gamesTestUser} />
        </>
      )}
    </div>
  );
};

export default Accounting;
