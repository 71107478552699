import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetGameCategories } from '@greenisland/stores';
import { GameCategoriesSchema } from '@greenisland/stores/dist/api/schemas/gameCategoriesSchema';
import { Skeleton } from '@mui/material';

import { AutoCompleteInput } from '@greenisland-common/components/atoms';

import { capitalize } from '@greenisland-common/helpers/formatters';

import { ContentFiltersPrimaryKeysType } from '../helpers/ContentFilterFormContext';

interface Props {
  type: 'included' | 'excluded';
}

const ContentFilterCategoryProviders = ({ type }: Props) => {
  const { t } = useTranslation();
  const { data: categories, isLoading } = useGetGameCategories();
  const categoriesOptions = useMemo(() => categories?.map(category => ({ ...category })) ?? [], [categories]);

  if (isLoading) return <Skeleton variant="rounded" height={56} animation="wave" />;

  return (
    <AutoCompleteInput
      size="small"
      multiple={true}
      name={`${type}Filter.${ContentFiltersPrimaryKeysType.gameCategoryPKeys}`}
      label={capitalize(t('content.games.categories.add'))}
      options={categoriesOptions}
      getLabel={option => option?.codeName ?? ''}
      rules={{
        required: true,
        setValueAs: (categories: GameCategoriesSchema) => {
          return categories?.map(category => Number(category?.id));
        },
      }}
    />
  );
};

export default ContentFilterCategoryProviders;
