import { ECreateTestUserBadRequestTypeSchema } from '@greenisland/stores';

type AddTestUserErrorReasons = {
  [key in ECreateTestUserBadRequestTypeSchema]: string;
};
export const ADD_TEST_USER_ERROR_REASONS: AddTestUserErrorReasons = {
  [ECreateTestUserBadRequestTypeSchema.AuthProviderUnknown]: 'lookup.users.errors.authProviderUnknown',
  [ECreateTestUserBadRequestTypeSchema.EmailAlreadyTaken]: 'lookup.users.errors.emailAlreadyTaken',
  [ECreateTestUserBadRequestTypeSchema.ModelStateInvalid]: 'lookup.users.errors.modelStateInvalid',
  [ECreateTestUserBadRequestTypeSchema.WeakPassword]: 'lookup.users.errors.weakPassword',
};
