import { Outlet } from 'react-router';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { ERegulatorSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import Intervention from 'src/pages/OnlineCasino/ResponsibleGaming/Interventions/Intervention';
import Interventions from 'src/pages/OnlineCasino/ResponsibleGaming/Interventions/Interventions';

import ReportToCruks from '../../pages/OnlineCasino/ResponsibleGaming/ReportToCruks/ReportToCruks';
import ResponsibleGamingTestResponses from '../../pages/OnlineCasino/ResponsibleGaming/ResponsibleGamingTestResponses/ResponsibleGamingTestResponses';
import { BadgeType } from '../components/BadgeCounter';
import { BaseRoute } from '../RouteInterfaces';

const interventionsRoutes: Array<BaseRoute> = [
  {
    path: 'interventions',
    name: 'Interventions',
    element: Interventions,
    permissions: OnlineCasinoPermissions.getInterventions,
    mini: 'I',
  },
  {
    path: 'interventions/:interventionId',
    name: 'Intervention',
    element: Intervention,
    permissions: OnlineCasinoPermissions.getIntervention,
    mini: 'I',
    hiddenLink: true,
  },
  {
    path: 'report-to-cruks',
    name: 'Report To CRUKS',
    element: ReportToCruks,
    permissions: OnlineCasinoPermissions.getReportToCruksUsers,
    mini: 'RC',
    showOnlyForSpecificTenant: ERegulatorSchema.KSA,
    badgeType: BadgeType.REPORT_CRUKS,
  },
  {
    path: 'test-responses',
    name: 'Test Responses',
    element: ResponsibleGamingTestResponses,
    mini: 'RGTR',
    permissions: OnlineCasinoPermissions.retrieveDgojResponsibleGamingTestResponses,
    showOnlyForSpecificTenant: ERegulatorSchema.DGOJ,
  },
];

export const ResponsibleGamingRoutes: Array<BaseRoute> = [
  {
    path: 'responsible-gaming',
    name: 'Responsible Gaming',
    icon: faBell,
    collapse: true,
    permissions: [OnlineCasinoPermissions.getInterventionNeeded],
    element: Outlet,
    children: [...interventionsRoutes],
  },
];

export default ResponsibleGamingRoutes;
