import React, { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, CheckData, Inputfield, Link, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getUnixTime } from 'date-fns';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { setInvisible, transformCurrency, transformUnixDate } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridGap: '15px 15px',
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  })
);

type FormData = {
  threshold: number;
};

const TopTransactions = () => {
  const canReadTopTransactions = usePermission(OnlineCasinoPermissions.getPlayerTopTransactions);
  return canReadTopTransactions ? <TopTransactionsInnerChild /> : null;
};

const TopTransactionsInnerChild = () => {
  const { t } = useTranslation();
  const classes = styles();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const queryThreshold = Number(searchParams.get('threshold'));
  const [disabled, setDisabled] = useState(false);

  const { register, handleSubmit, errors, watch } = useForm<FormData>({
    defaultValues: {
      threshold: queryThreshold > 0 ? queryThreshold : 1000,
    },
  });
  const fetchTopTransactions = (startDate: number, endDate: number, threshold: number, txType?: string) =>
    dispatch(actionCreators.getPlayerTopTransactions(startDate, endDate, threshold, txType));
  const { data: topTransactions, fetching } = useAppSelector(state => state.reportingPlayerTopTransactions);

  const threshold = watch('threshold');

  const onSubmit = (data: FormData) => {
    const startDate = getUnixTime(Number(searchParams.get('startdate')));
    const endDate = getUnixTime(Number(searchParams.get('enddate')));
    replaceUrlQueryParameters({ startDate, endDate, threshold: data.threshold }, setSearchParams);
  };

  const mutatedData = () => {
    if (!topTransactions || topTransactions.length === 0) return undefined;
    const keys = Object.keys(topTransactions[0]);

    const headers: any = keys.map(key => {
      switch (key) {
        case 'username':
          return {
            Header: t(key),
            accessor: key,
            Cell: (row: any): any => {
              const userId = row.row.values.userId;
              const username = row.row.values.username;
              return <Link to={`../../../users/${userId}/details`}>{username}</Link>;
            },
          };
        case 'timestamp':
          return transformUnixDate(key, t, true);
        case 'cash':
        case 'bonus':
          return transformCurrency(key, t);
        case 'userId':
          return setInvisible(key);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    return { headers, rows: topTransactions };
  };

  return (
    <div className={classes.container}>
      <Card className={classes.cardContainer}>
        <CardBody padding>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
            <DateRangePicker
              unixStartDate={Number(searchParams.get('startdate')) * 1000}
              unixEndDate={Number(searchParams.get('enddate')) * 1000}
              onChange={(startDate, endDate) => {
                replaceUrlQueryParameters({ startDate, endDate, threshold }, setSearchParams);
                fetchTopTransactions(startDate, endDate, queryThreshold);
              }}
            />
            <Inputfield
              label={t('threshold')}
              fullWidth
              error={!!errors['threshold']}
              name={'threshold'}
              inputRef={register({ required: true })}
            />
            <Button size="large" color="primary" disabled={disabled} type="submit">
              {t('search')}
            </Button>
          </form>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => (
              <ReactTableGI columns={data.headers} data={data.rows} pagination={data.rows.length > 5} sortable />
            )}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(TopTransactions);
