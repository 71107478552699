import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetBoosterPacks } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize } from '@mui/material';

import { PermissionWrapper, SelectInput } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../../app/hooks';
import { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const BoosterPackForm = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<IndividualActionForm>();
  const enabledBoosterPack = watch('enabledBoosterPack');
  const canReadBoosterPacks = usePermission(OnlineCasinoPermissions.getBoosterPacks);

  const {
    data: boosterPacks,
    isLoading,
    isError,
  } = useGetBoosterPacks(
    {},
    {
      query: { enabled: canReadBoosterPacks },
    }
  );

  const boosterPackOptions = useMemo(() => {
    return (
      boosterPacks?.items
        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
        ?.map(entry => ({ label: entry.name, value: Number(entry.id) })) ?? []
    );
  }, [boosterPacks?.items]);

  return (
    <IndividualActionFormListItem
      enabled={enabledBoosterPack}
      name="enabledBoosterPack"
      listItemText={capitalize(t('marketing.boosterPacks.titles.boosterPack'))}
    >
      <PermissionWrapper
        errorMessage={t('marketing.boosterPacks.permissions.fetchError')}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getBoosterPacks}
      >
        <SelectInput
          name={`boosterPackId`}
          label={t('marketing.boosterPacks.titles.selectBoosterPack')}
          options={boosterPackOptions}
        />
      </PermissionWrapper>
    </IndividualActionFormListItem>
  );
};

export default BoosterPackForm;
