import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CashflowPropertiesSchema, EUserLimitV2TypeSchema, useGetCashflow } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, Card, CardContent, CardHeader, LinearProgress, SxProps } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { endOfToday, getUnixTime, startOfToday, subDays } from 'date-fns';
import { useTenantContext } from 'src/app/context/TenantContext';

import { DataGridContainer, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';

import {
  formatCurrency,
  formatPercentage,
  getDateFromUnix,
  PercentageSpan,
} from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

type Row = CashflowPropertiesSchema;

const PAGE_SIZE = 5;

interface Props {
  sx?: SxProps;
}

const Cashflow = ({ sx }: Props) => {
  const { t } = useTranslation();
  const canReadCashflow = usePermission(OnlineCasinoPermissions.getCashflow);

  const startDate = useMemo(() => getUnixTime(subDays(startOfToday(), 32)), []);
  const endDate = useMemo(() => getUnixTime(endOfToday()), []);

  const {
    data: cashflow,
    isLoading,
    isError,
  } = useGetCashflow(
    {
      startDate,
      endDate,
    },
    {
      query: {
        enabled: canReadCashflow,
      },
    }
  );
  const tenant = useTenantContext();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const headers: GridColumns<Row> = [
    {
      headerName: capitalize(t('date')),
      field: 'date',
      minWidth: 100,
      flex: 0.1,
      valueFormatter: params => getDateFromUnix(params.value),
    },
    {
      headerName: capitalize(t('deposits.title')),
      field: 'deposits',
      minWidth: 120,
      flex: 0.2,
      valueFormatter: params => formatCurrency(params.value),
    },
    {
      headerName: capitalize(t('withdrawals.title')),
      field: 'withdrawals',
      minWidth: 120,
      flex: 0.2,
      valueFormatter: params => formatCurrency(params.value),
    },
    ...(tenant?.limitConfigurations?.enabledLimitTypes?.includes(EUserLimitV2TypeSchema.BalanceLimit)
      ? [
          {
            headerName: capitalize(t('automaticWithdrawals')),
            field: 'automaticWithdrawals',
            minWidth: 120,
            flex: 0.1,
            valueFormatter: (params: any) => formatCurrency(params.value),
          },
        ]
      : []),
    {
      headerName: capitalize(t('revenue')),
      field: 'revenue',
      minWidth: 120,
      flex: 0.2,
      valueFormatter: params => formatCurrency(params.value),
    },
    {
      headerName: capitalize(t('hold')),
      field: 'hold',
      minWidth: 100,
      flex: 0.1,
      renderCell: params => <PercentageSpan value={formatPercentage(params.value)} />,
    },
  ];

  const sortCashflowDataHandler = useCallback((data?: CashflowPropertiesSchema) => {
    if (!data) return [];

    return data?.sort((a, b) => {
      if (b?.date === undefined) return 0;
      if (a?.date === undefined) return 0;
      return b?.date - a?.date;
    });
  }, []);

  const rows: GridRowsProp = useMemo(() => {
    const sortedCashflowData = sortCashflowDataHandler(cashflow);

    return (
      sortedCashflowData?.map((item, i) => {
        return {
          ...item,
          id: i,
        };
      }) ?? []
    );
  }, [cashflow, sortCashflowDataHandler]);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  return (
    <Card sx={sx}>
      <CardHeader title={t('cashflow')} />
      <CardContent>
        <PermissionWrapper
          errorMessage={t('cashflowCard.fetchError')}
          isError={isError}
          isLoading={false}
          permission={OnlineCasinoPermissions.getCashflow}
        >
          <DataGridContainer>
            <StyledDataGrid
              autoHeight
              density="compact"
              columns={headers}
              rows={rows}
              pagination
              loading={isLoading}
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              components={{
                LoadingOverlay: LinearProgress,
                Footer: () => (
                  <>
                    <DataGridCustomPagination
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[]}
                      count={rows?.length ? Math.ceil(rows.length / pageSize) : 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      paginationMode="client"
                    />
                  </>
                ),
              }}
              hideFooterRowCount={true}
            />
          </DataGridContainer>
        </PermissionWrapper>
      </CardContent>
    </Card>
  );
};

export default memo(Cashflow);
