import { DepositsSchema, Transaction, WithdrawalRequests } from '@greenisland/stores';
import { format } from 'date-fns';

import { formatDecimal } from '../../../../../../../app/helpers/transformFunctions';

export const transformToCSV = (data: Array<Transaction>): string => {
  const csvString = [
    ['transactionId', 'timestamp', 'type', 'game', 'promoAmount', 'promoBalance', 'amount', 'balance', 'label'],
    ...data.map(item => {
      return [
        item.transactionId,
        format(item.timestamp * 1000, 'dd/MM/yyyy HH:mm'),
        item.type,
        item.game,
        formatDecimal(item.promoAmount, true),
        formatDecimal(item.promoBalance, true),
        formatDecimal(item.amount, true),
        formatDecimal(item.balance, true),
        item.label,
      ];
    }),
  ]
    .map(e => e.join(';'))
    .join('\n');

  return csvString;
};

export const transformToCSVDeposits = (data: DepositsSchema): string => {
  const csvString = [
    [
      'depositId',
      'timestamp',
      'username',
      'amount',
      'provider',
      'paymentMethod',
      'status',
      'depositBonus',
      'onAccount',
    ],
    ...data.map(item => {
      return [
        item.depositId,
        format(item.timestamp * 1000, 'dd/MM/yyyy HH:mm'),
        item.username,
        formatDecimal(item.amount, true),
        item.provider,
        item.paymentMethod,
        item.status,
        item.depositBonus,
        item.onAccount,
      ];
    }),
  ]
    .map(e => e.join(';'))
    .join('\n');

  return csvString;
};

export const transformToCSVWithdrawals = (data: WithdrawalRequests): string => {
  const csvString = [
    ['createdOn', 'amount', 'balance', 'destination', 'status'],
    ...data.map(item => {
      return [
        format(item.createdOn * 1000, 'dd/MM/yyyy HH:mm'),
        formatDecimal(item.amount, true),
        formatDecimal(item.balance, true),
        item.destination,
        item.status,
      ];
    }),
  ]
    .map(e => e.join(';'))
    .join('\n');

  return csvString;
};
