import React, { useState } from 'react';
import { Checkbox, Inputfield, Select } from '@greenisland/components';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TextEditor from '@greenisland-common/components/molecules/TextEditor/TextEditor';

const languages = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'Dutch', value: 'nl' },
];

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const EmailNotificationTemplate = () => {
  const classes = useStyles();
  const [language, setLanguage] = useState('en');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [isActivated, setActivated] = useState(false);

  return (
    <>
      <Select
        label={'Language'}
        selectedValue={language}
        onChange={setLanguage}
        menuItems={languages}
        className={classes.input}
      />
      <Inputfield
        required
        label={'Title'}
        placeholder={'Title'}
        value={title}
        onChange={e => setTitle(e.target.value)}
        className={classes.input}
      />
      <TextEditor
        required
        label={'Message body'}
        value={message}
        onEditorChange={e => setMessage(e)}
        className={classes.input}
      />
      <Checkbox label="Is Channel Activated" checked={isActivated} onChange={e => setActivated(e.target.checked)} />
    </>
  );
};

export default EmailNotificationTemplate;
