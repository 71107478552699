import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Loading } from '@greenisland/components';
import { AdvancedUserSearchV2Params, useListCountries } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { AutoCompleteInput } from '@greenisland-common/components/atoms';
import SwitchInput from '@greenisland-common/components/atoms/SwitchInput';
import Input from '@greenisland-common/components/molecules/Input';

import useFormPersist from '@greenisland-common/hooks/useFormPersist';

import { usePermission } from '../../../../app/hooks';

const searchUserStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridGap: '15px 15px',
      gridTemplateColumns: '1fr 1fr',
      marginBottom: 15,
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },
    innerNames: {
      display: 'grid',
      gridGap: '15px 15px',
      gridTemplateColumns: '1fr 1fr',
      gridColumnStart: 1,
      gridColumnEnd: 3,
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridColumnStart: 'auto',
        gridColumnEnd: 'auto',
      },
    },
    innerAdress: {
      display: 'grid',
      gridGap: '15px 15px',
      gridTemplateColumns: '1fr 1fr',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
  })
);

interface Props {
  onSubmit: (values: AdvancedUserSearchV2Params) => void;
  isLoading: boolean;
}

const SearchUser = ({ onSubmit }: Props) => {
  const classes = searchUserStyles();
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      country: { countryCode: '', countryName: '' },
      includeClosedUsers: false,
    },
  });

  const { handleSubmit, watch, setValue, control } = methods;
  const onHandleSubmit = (data: any) => {
    const { includeClosedUsers, country, ...rest } = data;
    const isClosed = includeClosedUsers ? undefined : false;
    const processedData = {
      ...rest,
      country: country?.countryCode,
      isClosed,
    };
    onSubmit(processedData);
  };
  const canSearch = usePermission(OnlineCasinoPermissions.advancedUserSearchV2);
  const { data: countries, isLoading } = useListCountries();

  useFormPersist('searchUser', { watch, setValue });

  if (isLoading) return <Loading center />;

  return countries ? (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <div className={classes.container}>
          <div className={classes.innerNames}>
            <Input label={t('username')} name="username" control={control} />
            <Input label={t('displayname')} name="displayname" control={control} />
          </div>
          <Input label={t('firstName')} name="firstName" control={control} />
          <Input label={t('lastName')} name="lastName" control={control} />
          {canSearch && <Input label={t('email')} name="email" control={control} />}
          <Input label={t('language')} name="language" control={control} />
          <div className={classes.innerAdress}>
            <Input label={t('street')} name="street" control={control} />
            <Input label={t('houseNumber')} name="houseNumber" control={control} />
          </div>
          <Input label={t('nationalNumber')} name="nationalNumber" control={control} />
          <div className={classes.innerAdress}>
            <AutoCompleteInput
              name="country"
              multiple={false}
              disableCloseOnSelect={false}
              label={t('country')}
              options={countries ?? []}
              getLabel={(option: any) => (option.countryName ? option.countryName : '')}
            />

            <Input label={t('zipCode')} name="zipCode" control={control} />
          </div>
          <Input label={t('passportNumber')} name="passportNumber" control={control} />
          <div className={classes.innerNames}>
            <Input label={t('phonenumber')} name="phoneNumber" control={control} />
            <Input label={t('auth0UserId')} name="auth0UserId" control={control} />
          </div>
          <div className={classes.innerNames}>
            <Input label={t('userId')} name="userId" control={control} />
            <Input label={t('accountId')} name="accountId" control={control} />
          </div>
          <div>
            <SwitchInput
              label={t('lookup.search.includeClosedUsers')}
              name={'includeClosedUsers'}
              sx={{ gap: '10px' }}
            />
          </div>
        </div>
        <LoadingButton loading={isLoading} type="submit" variant="contained" sx={{ mb: 3 }}>
          {t('search')}
        </LoadingButton>
      </form>
    </FormProvider>
  ) : (
    <Typography color="error">{t('errorFetchingCountries')}</Typography>
  );
};

export default SearchUser;
