import { EPhoneNumberBadRequestTypeSchema } from '@greenisland/stores';

type PhoneNumberErrorReasons = {
  [key in EPhoneNumberBadRequestTypeSchema]: string;
};

export const PHONE_NUMBER_ERROR_REASONS: PhoneNumberErrorReasons = {
  [EPhoneNumberBadRequestTypeSchema.InvalidPhoneNumber]: 'phoneNumberError.invalidPhoneNumber',
  [EPhoneNumberBadRequestTypeSchema.PhoneNumberAlreadyExists]: 'phoneNumberError.phoneNumberAlreadyExists',
  [EPhoneNumberBadRequestTypeSchema.UserNotFound]: 'phoneNumberError.userNotFound',
};
