import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { faCheckCircle, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
  AgentDetailsSchema,
  AgentPermissionsSchema,
  AgentRolesSchema,
  ErrorSchema,
  getGetAgentDetailsQueryKey,
  getGetListAgentsQueryKey,
  useAddAgent,
  useUpdateAgent,
} from '@greenisland/stores';
import { AuthorizationPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import { Box, capitalize, Stack, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';

import { PermissionWrapper } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';
import TransferList from '@greenisland-common/components/organisms/TransferList';

import {
  AgentFormData,
  formatPermissionAgentData,
  formatRoleAgentData,
  generateAgentFormData,
  generateAgentItems,
} from '../helpers/AgentFormContext';
import AgentIconInfo from './AgentIconInfo';

interface Props {
  agentId?: string;
  agentRoles?: AgentRolesSchema;
  agentPermissions?: AgentPermissionsSchema;
  agent?: AgentDetailsSchema;
}

const SetAgentForm = ({ agentId, agentRoles, agentPermissions, agent }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const methods = useForm<AgentFormData>({
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      permissions: [],
      agentRoles: [],
    },
  });

  const { handleSubmit, errors, control, watch, getValues, setValue, reset, register } = methods;

  const onSuccess = (message: string) => {
    enqueueSnackbar(t(message), { variant: 'success' });
    queryClient.invalidateQueries(getGetListAgentsQueryKey());

    if (agentId) {
      queryClient.invalidateQueries(getGetAgentDetailsQueryKey(agentId));
    }

    navigate(`../agents`);
  };

  const onError = (error: ErrorSchema) => {
    enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
  };

  const {
    mutate: addAgent,
    isLoading: isAddAgentLoading,
    isError: isAddAgentError,
  } = useAddAgent({
    mutation: {
      onSuccess: () => onSuccess(t('settings.management.agents.titles.addedSuccessfully')),
      onError,
    },
  });

  const {
    mutate: updateAgent,
    isLoading: isUpdateAgentLoading,
    isError: isUpdateAgentError,
  } = useUpdateAgent({
    mutation: {
      onSuccess: () => onSuccess(t('settings.management.agents.titles.updatedSuccessfully')),
      onError,
    },
  });

  const watchPermissionsData = watch('permissions');
  const watchAgentRolesData = watch('agentRoles');

  useEffect(() => {
    if (agent?.permissions) {
      setValue('permissions', generateAgentItems(agent?.permissions, formatPermissionAgentData));
    }
  }, [agent?.permissions, setValue]);

  useEffect(() => {
    if (agent?.agentRoles) {
      setValue('agentRoles', generateAgentItems(agent?.agentRoles, formatRoleAgentData));
    }
  }, [agent?.agentRoles, setValue]);

  const onSubmit = (formData: AgentFormData) => {
    const data = generateAgentFormData(formData, agent, watchPermissionsData, watchAgentRolesData);

    if (!agentId) return addAgent({ data });

    return updateAgent({
      agentId: agentId ?? '',
      data,
    });
  };

  return (
    <Box mt={4}>
      <PermissionWrapper
        errorMessage={t('settings.management.agents.permissions.fetchError')}
        isError={isAddAgentError || isUpdateAgentError}
        isLoading={false}
        permission={agentId ? AuthorizationPermissions.updateAgent : AuthorizationPermissions.addAgent}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <AgentIconInfo
                enabled={agent?.isAdmin}
                title={t('isSuperAdmin')}
                icon={faCheckCircle}
                iconColor="green"
              />
              <AgentIconInfo enabled={agent?.isLocked} title={t('locked')} icon={faCircleExclamation} iconColor="red" />
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, 1fr)',
                  gridGap: '15px 15px',
                  marginBottom: '16px',
                }}
              >
                <Stack
                  sx={{
                    [theme.breakpoints.up('xs')]: {
                      display: 'grid',
                      gridTemplateColumns: 'repeat(1, 1fr)',
                      gridGap: '15px 15px',
                      marginBottom: '16px',
                    },
                    [theme.breakpoints.up('md')]: {
                      gridTemplateColumns: 'repeat(2, 1fr)',
                    },
                  }}
                >
                  <Input
                    key={agent?.email}
                    size="small"
                    label={capitalize(t('email'))}
                    disabled={agent != null && agentId != null}
                    type="email"
                    control={control}
                    name="email"
                    defaultValue={agent?.email || ''}
                    error={!!errors.email}
                    placeholder="test@example.com"
                    required
                    rules={{ required: t('fieldIsRequired') }}
                  />
                  <Input
                    key={agent?.name}
                    size="small"
                    label={capitalize(t('name'))}
                    type="text"
                    control={control}
                    name="name"
                    defaultValue={agent?.name || ''}
                    error={!!errors.name}
                    placeholder="John Doe"
                    required
                    rules={{ required: t('fieldIsRequired') }}
                  />
                  <Input
                    key={agent?.phone}
                    size="small"
                    label={capitalize(t('phone'))}
                    type="text"
                    control={control}
                    name="phone"
                    defaultValue={agent?.phone || ''}
                    error={!!errors.phone}
                    placeholder="+32471234567"
                    required
                    rules={{ required: t('fieldIsRequired') }}
                  />
                </Stack>
                <Controller
                  control={control}
                  name="permissions"
                  render={({ name }) => {
                    return (
                      <TransferList
                        control={control}
                        name={name}
                        leftTitle={t('availablePermissions')}
                        rightTitle={t('selectedPermissions')}
                        fallbackText={t('noPermissionsFound')}
                        allItems={generateAgentItems(agentPermissions, formatPermissionAgentData)}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="agentRoles"
                  render={({ name }) => {
                    return (
                      <TransferList
                        control={control}
                        name={name}
                        leftTitle={t('availableRoles')}
                        rightTitle={t('selectedRoles')}
                        fallbackText={t('noRolesFound')}
                        allItems={generateAgentItems(agentRoles, formatRoleAgentData)}
                      />
                    );
                  }}
                />
              </Box>
            </Stack>
            <LoadingButton
              loading={isAddAgentLoading || isUpdateAgentLoading}
              sx={{ mt: 4, px: 6, py: 2 }}
              color="primary"
              type="submit"
              variant="contained"
              disabled={isAddAgentLoading || isUpdateAgentLoading}
            >
              {t('save')}
            </LoadingButton>
          </form>
        </FormProvider>
      </PermissionWrapper>
    </Box>
  );
};

export default SetAgentForm;
