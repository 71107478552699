import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import {
  ErrorSchema,
  getGetVaultConfigurationsQueryKey,
  useActivateVaultConfiguration,
  useDeactivateVaultConfiguration,
  useGetVaultConfigurations,
  VaultConfigurationSchema,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, LinearProgress, Stack, Typography } from '@mui/material';
import { GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import {
  CheckboxIcon,
  DataGridContainer,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { usePermission } from '../../../../../app/hooks';
import VaultConfigurationRevealDigitDialog from './VaultConfigurationRevealDigitDialog';
import VaultConfigurationsDialog from './VaultConfigurationsDialog';

type Row = VaultConfigurationSchema;

const PAGE_SIZE = 25;

const VaultConfigurations = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const canReadVaultConfigurations = usePermission(OnlineCasinoPermissions.getVaultConfigurations);
  const canAddVaultConfiguration = usePermission(OnlineCasinoPermissions.addVaultConfiguration);
  const canActivateVaultConfiguration = usePermission(OnlineCasinoPermissions.activateVaultConfiguration);
  const canDeactivateVaultConfiguration = usePermission(OnlineCasinoPermissions.deactivateVaultConfiguration);
  const canRevealVaultDigit = usePermission(OnlineCasinoPermissions.revealVaultDigit);

  const [isAddConfigurationDialogOpen, setIsAddConfigurationDialogOpen] = useState(false);
  const [revealedVaultDigitId, setRevealedVaultDigitId] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const {
    data: vaultConfigurations,
    isLoading: isVaultConfigurationsLoading,
    isFetching: isVaultConfigurationsFetching,
    isError,
  } = useGetVaultConfigurations({
    query: { enabled: canReadVaultConfigurations },
  });

  const onSuccessHandler = () => {
    queryClient.invalidateQueries(getGetVaultConfigurationsQueryKey());
    enqueueSnackbar(t('success'), { variant: 'success' });
  };

  const onErrorHandler = (error: ErrorSchema) => {
    enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
  };

  const { mutate: activateVaultConfiguration } = useActivateVaultConfiguration({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: onErrorHandler,
    },
  });

  const { mutate: deactivateVaultConfiguration } = useDeactivateVaultConfiguration({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: onErrorHandler,
    },
  });

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'id',
        headerName: capitalize(t('id')),
        sortable: true,
        flex: 0.02,
        minWidth: 80,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'active',
        headerName: capitalize(t('active')),
        sortable: false,
        flex: 0.05,
        minWidth: 50,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
      {
        field: 'level',
        headerName: capitalize(t('level')),
        sortable: true,
        flex: 0.05,
        minWidth: 100,
        renderCell: params => {
          return <Typography variant="body2">{params.value || '-'}</Typography>;
        },
      },
      {
        field: 'amount',
        headerName: capitalize(t('amount')),
        sortable: true,
        flex: 0.05,
        minWidth: 100,
        renderCell: params => {
          return <Typography variant="body2">{params.value || '-'}</Typography>;
        },
      },
      {
        field: 'codeLength',
        headerName: capitalize(t('codeLength')),
        sortable: true,
        flex: 0.05,
        minWidth: 100,
        renderCell: params => {
          return <Typography variant="body2">{params.value || '-'}</Typography>;
        },
      },
      {
        field: 'numTokensRequired',
        headerName: capitalize(t('numTokensRequired')),
        sortable: true,
        flex: 0.05,
        minWidth: 100,
        renderCell: params => {
          return <Typography variant="body2">{params.value || '-'}</Typography>;
        },
      },
      {
        field: 'numberOfRevealedDigits',
        headerName: capitalize(t('numberOfRevealedDigits')),
        sortable: true,
        flex: 0.05,
        minWidth: 100,
        renderCell: params => {
          return <Typography variant="body2">{params.value || 0}</Typography>;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 260,
        flex: 0.1,
        align: 'right',
        headerName: capitalize(t('actions')),
        getActions: (params: GridRowParams<Row>) => {
          const title = params?.row?.active ? 'deactivate' : 'activate';
          const hasPermission = params?.row?.active ? canDeactivateVaultConfiguration : canActivateVaultConfiguration;

          if (!hasPermission) return null;

          return [
            <Box key="details">
              {canRevealVaultDigit && params?.row?.active && params?.row?.codeLength > 4 && (
                <Button
                  sx={{ minWidth: '120px', mr: 1 }}
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={() => setRevealedVaultDigitId(params?.row?.id)}
                >
                  {capitalize(t('contests.vault.titles.revealDigit'))}
                </Button>
              )}
              <Button
                sx={{ minWidth: '120px' }}
                variant="contained"
                size="small"
                color={params?.row?.active ? 'error' : 'success'}
                onClick={() =>
                  params?.row?.active
                    ? deactivateVaultConfiguration({ vaultConfigId: params?.row?.id })
                    : activateVaultConfiguration({ vaultConfigId: params?.row?.id })
                }
              >
                {capitalize(t(title))}
              </Button>
            </Box>,
          ];
        },
      },
    ],
    [
      activateVaultConfiguration,
      canActivateVaultConfiguration,
      canDeactivateVaultConfiguration,
      canRevealVaultDigit,
      deactivateVaultConfiguration,
      t,
    ]
  );

  const rows = useMemo(
    () =>
      vaultConfigurations?.map<Row>(vaultConfiguration => ({
        ...vaultConfiguration,
      })) ?? [],
    [vaultConfigurations]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  return (
    <PermissionWrapper
      errorMessage={t('contests.vault.errors.fetchError')}
      isError={isError}
      isLoading={false}
      permission={OnlineCasinoPermissions.getVaultConfigurations}
    >
      <>
        <Stack spacing={2}>
          {canAddVaultConfiguration && (
            <Box>
              <Button
                onClick={() => setIsAddConfigurationDialogOpen(true)}
                variant="contained"
                size="medium"
                startIcon={<FontAwesomeIcon icon={faAdd} />}
              >
                {capitalize(t('addConfiguration'))}
              </Button>
            </Box>
          )}
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isVaultConfigurationsLoading || isVaultConfigurationsFetching}
              columns={columns}
              rows={rows}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              components={{
                LoadingOverlay: LinearProgress,
                Footer: () => (
                  <>
                    <DataGridCustomPagination
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[10, 25, 50, 100, 200]}
                      count={vaultConfigurations?.length ? Math.ceil(vaultConfigurations?.length / pageSize) : 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      paginationMode="client"
                    />
                  </>
                ),
              }}
            />
          </DataGridContainer>
        </Stack>
        {isAddConfigurationDialogOpen && (
          <VaultConfigurationsDialog open={true} onClose={() => setIsAddConfigurationDialogOpen(false)} />
        )}
        {revealedVaultDigitId && (
          <VaultConfigurationRevealDigitDialog
            open={true}
            onClose={() => setRevealedVaultDigitId(null)}
            revealedVaultDigitId={revealedVaultDigitId}
          />
        )}
      </>
    </PermissionWrapper>
  );
};

export default VaultConfigurations;
