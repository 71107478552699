import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  GameProviderGamesDetailsSchema,
  GameProvidersReportDetailsSchemaPlatform,
  GameProvidersReportDetailsSchemaProvider,
} from '@greenisland/stores';
import {
  Box,
  Button,
  capitalize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { endOfMonth, getUnixTime, startOfMonth } from 'date-fns';

import { formatCurrency } from '../../../../../../app/helpers/transformFunctions';
import { GameProviderSearchFilters } from './GameProviderDateFilters';

interface Props<T> {
  title: string;
  details: T;
  provider?: string;
}

type ProviderDetails =
  | GameProviderGamesDetailsSchema
  | GameProvidersReportDetailsSchemaPlatform
  | GameProvidersReportDetailsSchemaProvider;

const GameProviderDetailsTableData = <T extends ProviderDetails>({ title, details, provider }: Props<T>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get(GameProviderSearchFilters.START_DATE);
  const endDate = searchParams.get(GameProviderSearchFilters.END_DATE);

  const navigateToHandler = useCallback(() => {
    const params = new URLSearchParams();
    if (provider) {
      params.set(GameProviderSearchFilters.PROVIDER, provider);
      params.set(
        GameProviderSearchFilters.START_DATE,
        String(startDate ? startDate : getUnixTime(startOfMonth(new Date())))
      );
      params.set(GameProviderSearchFilters.END_DATE, String(endDate ? endDate : getUnixTime(endOfMonth(new Date()))));
    }

    const filtersQuery = '?' + params.toString();

    navigate(`../provider-games/${filtersQuery}`);
  }, [endDate, navigate, provider, startDate]);

  return (
    <Box mb={8}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" fontWeight={500} fontSize="large">
          {capitalize(title)}
        </Typography>
        {provider && (
          <Typography variant="h5" fontSize="medium">
            <Button variant="contained" onClick={navigateToHandler}>
              {t('detailsPerGame')}
            </Button>
          </Typography>
        )}
      </Box>
      <TableContainer sx={{ mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 200 }}></TableCell>
              <TableCell>
                <Typography variant="body2" fontWeight={700}>
                  {capitalize(t('cash'))}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" fontWeight={700}>
                  {capitalize(t('promo'))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(details).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  <Typography variant="body2" fontWeight={500}>
                    {capitalize(t(key))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{formatCurrency(value.cash)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{formatCurrency(value.promo)}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GameProviderDetailsTableData;
