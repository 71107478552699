import { useGetAllAccountData, useGetBasicAccountData } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';

import { usePermission } from '../../../../app/hooks';

const useAccountData = (userId: string) => {
  const canReadBasicAccountData = usePermission(OnlineCasinoPermissions.getBasicAccountData);
  const canReadAllAccountData = usePermission(OnlineCasinoPermissions.getAllAccountData);

  const permission = canReadAllAccountData || canReadBasicAccountData;

  const {
    data: allAccountData,
    isLoading: allAccountDataLoading,
    isError: allAccountDataIsError,
    error: allAccountDataError,
  } = useGetAllAccountData(userId, {
    query: { enabled: canReadAllAccountData },
  });
  const {
    data: basicAccountData,
    isLoading: basicAccountDataLoading,
    isError: basicAccountDataIsError,
    error: basicAccountDataError,
  } = useGetBasicAccountData(userId, {
    query: { enabled: canReadBasicAccountData && !canReadAllAccountData },
  });

  const isLoading = basicAccountDataLoading || allAccountDataLoading;
  const data = canReadAllAccountData ? allAccountData : basicAccountData;
  const isError = basicAccountDataIsError || allAccountDataIsError;
  const error = basicAccountDataError || allAccountDataError;

  return { data, isLoading, isError, error, permission };
};

export default useAccountData;
