import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ESortOrderSchema,
  GetReportToCruksUsersParams,
  ReportToCruksUserSchema,
  SortOrderParamParameter,
  useGetReportToCruksUsers,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, LinearProgress, Stack, Typography, useTheme } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPagination, Link, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';
import Select from '@greenisland-common/components/atoms/Select';

import { formatDateTime } from '@greenisland-common/helpers';

import { usePermission } from '../../../../app/hooks';
import ReportToCruksDialog from './ReportToCruksDialog';

const PAGE_SIZE = 25;

const ReportToCruks = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const canReadReportToCruksUsers = usePermission(OnlineCasinoPermissions.getReportToCruksUsers);
  const canResolveReportToCRUKS = usePermission(OnlineCasinoPermissions.resolveReportToCRUKS);

  const overdueFilterOptions = {
    none: t('none'),
    overdue: t('responsibleGaming.reportToCruks.titles.overdue'),
    not_yet_due: t('responsibleGaming.reportToCruks.titles.notYetDue'),
  };

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const [chosenReportItem, setChosenReportItem] = useState<ReportToCruksUserSchema | null>(null);
  const [overdueFilterOption, setOverdueFilterOption] = useState(overdueFilterOptions.none);
  const [sortBy, setSortBy] = useState<SortOrderParamParameter>(SortOrderParamParameter.ascending);

  const params = useMemo((): GetReportToCruksUsersParams => {
    return {
      page,
      pageSize,
      sortOrderTimeToReport: Object.values(SortOrderParamParameter).includes(sortBy)
        ? sortBy
        : SortOrderParamParameter.unknown,
      ...(overdueFilterOption !== overdueFilterOptions.none
        ? { overdue: overdueFilterOption === overdueFilterOptions.overdue }
        : {}),
    };
  }, [overdueFilterOption, overdueFilterOptions.none, overdueFilterOptions.overdue, page, pageSize, sortBy]);

  const {
    data: reports,
    isLoading,
    isError,
  } = useGetReportToCruksUsers(
    {
      ...params,
    },
    {
      query: { enabled: canReadReportToCruksUsers },
    }
  );

  const columns = useMemo<GridColumns<ReportToCruksUserSchema>>(
    () => [
      {
        field: 'riskCommunicatedDate',
        headerName: capitalize(t('responsibleGaming.reportToCruks.titles.riskCommunicatedDate')),
        flex: 0.1,
        renderCell: ({ value }) => {
          const formattedDate = formatDateTime(new Date(value * 1000), true);
          return (
            <Box>
              <Typography variant="body2">{formattedDate.split(' ')[0]}</Typography>
              <Typography variant="body2" color="GrayText">
                {formattedDate.split(' ')[1]}
              </Typography>
            </Box>
          );
        },
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'userId',
        headerName: capitalize(t('userId')),
        flex: 0.1,
        renderCell: ({ value }) => (
          <Link target="_blank" to={`../../users/${value}/details`}>
            {value}
          </Link>
        ),
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'replyDueDate',
        headerName: capitalize(t('responsibleGaming.reportToCruks.titles.replyDueDate')),
        flex: 0.1,
        renderCell: params => {
          const formattedDate = formatDateTime(new Date(params.row.replyDueDate * 1000), true);
          return (
            <Box sx={{ color: params.row.overdue ? theme.palette.error.main : theme.palette.text.primary }}>
              <Typography variant="body2">{formattedDate.split(' ')[0]}</Typography>
              <Typography variant="body2" color={!params.row.overdue ? 'GrayText' : ''}>
                {formattedDate.split(' ')[1]}
              </Typography>
            </Box>
          );
        },
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 150,
        flex: 0.1,
        headerName: capitalize(t('actions')),
        renderCell: params => {
          const btnTitle = params.row.overdue
            ? 'responsibleGaming.reportToCruks.titles.submitForm'
            : 'responsibleGaming.reportToCruks.titles.userReplied';
          const btnColor = params.row.overdue ? theme.palette.error.main : theme.palette.success.main;

          return (
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                setChosenReportItem(params.row);
              }}
              sx={{ color: btnColor, borderColor: btnColor }}
              disabled={!canResolveReportToCRUKS}
            >
              {capitalize(t(btnTitle))}
            </Button>
          );
        },
      },
    ],
    [canResolveReportToCRUKS, t, theme.palette.error.main, theme.palette.success.main, theme.palette.text.primary]
  );

  const rows = useMemo(
    () =>
      reports?.items?.map<ReportToCruksUserSchema>((report, index) => ({
        id: index,
        ...report,
      })) ?? [],
    [reports?.items]
  );

  const sortByDueDateOptions = Object.keys(SortOrderParamParameter)
    .toString()
    .replace(SortOrderParamParameter.unknown, 'none')
    .split(',');

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('responsibleGaming.reportToCruks.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getReportToCruksUsers}
      >
        <>
          <Box
            display="flex"
            gap={2}
            justifyContent="flex-end"
            sx={{
              [theme.breakpoints.up('xs')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            }}
          >
            <Select
              id={t('common.filterOn')}
              label={capitalize(t('common.filterOn'))}
              value={overdueFilterOption}
              onChange={event => setOverdueFilterOption(event.target.value)}
              options={Object.values(overdueFilterOptions)}
            />
            <Select
              id={t('responsibleGaming.reportToCruks.titles.sortByDueDate')}
              label={capitalize(t('responsibleGaming.reportToCruks.titles.sortByDueDate'))}
              value={sortBy}
              onChange={event => setSortBy(event.target.value as ESortOrderSchema)}
              options={sortByDueDateOptions}
            />
          </Box>
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={70}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={reports?.pagingDetails?.totalItems}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(page: number) => {
                if (!isLoading) {
                  setPage(page);
                }
              }}
              onPageSizeChange={setPageSize}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
          {chosenReportItem && canResolveReportToCRUKS && (
            <ReportToCruksDialog
              chosenReportItem={chosenReportItem}
              setChosenReportItem={setChosenReportItem}
              params={params}
            />
          )}
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default ReportToCruks;
