import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMediaGalleryEntryTypeSchema } from '@greenisland/stores';
import { capitalize, TextField, Typography } from '@mui/material';

import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';
import SelectInputDurationField from '@greenisland-common/components/organisms/SelectInputDurationField';

import { ContentFilterButton } from '../../../../../../Content/ContentFilter/components';
import { LoyaltyDependentIconType } from '../../../../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import IndividualActionFormListItem from '../../components/IndividualActionFormListItem';
import { IndividualActionForm } from '../../context';
import BonusLoyaltyDependantSection from '../components/BonusLoyaltyDependantSection';
import {
  BonusLoyaltyDependentTypeEnum,
  useBonusDepositLoyaltyDependentOptions,
  useDepositBonusDurationValues,
} from '../helpers/DepositBonusFormContext';

const PlaythroughBonusForm = () => {
  const { t } = useTranslation();
  const durationOptions = useDepositBonusDurationValues();

  const limitFields = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.LIMIT,
    'playthroughBonusLoyaltyDependentAmountField'
  );
  const playthroughFields = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.PLAYTHROUGH,
    'playthroughBonusLoyaltyDependentPlaythroughField'
  );

  const { control, register, watch, setValue, errors } = useFormContext<IndividualActionForm>();

  register('playthroughBonusAmountLoyaltyDependentValue');
  register('playthroughBonusPlaythroughLoyaltyDependentValue');

  const isEnabledPlaythrough = watch('enabledPlaythrough');

  const [isAmountLoyaltyDependent, setIsAmountLoyaltyDependent] = useState(
    !!watch('playthroughBonusAmountIsLoyaltyDependent')
  );
  const [isPlaythroughLoyaltyDependent, setIsPlaythroughLoyaltyDependent] = useState(
    !!watch('playthroughBonusPlaythroughIsLoyaltyDependent')
  );

  const [playthroughExpirationDuration, setPlaythroughExpirationDuration] = useState(durationOptions[0]);

  useEffect(() => {
    if (!isEnabledPlaythrough) {
      setIsAmountLoyaltyDependent(false);
      setIsPlaythroughLoyaltyDependent(false);
    }
  }, [isEnabledPlaythrough]);

  useEffect(() => {
    if (playthroughExpirationDuration) {
      register('playthroughBonusExpirationDurationSelectOption');
      setValue('playthroughBonusExpirationDurationSelectOption', playthroughExpirationDuration.value);
    }
  }, [playthroughExpirationDuration, register, setValue]);

  const handleLoyaltyDependentChange = (event: ChangeEvent<HTMLInputElement>, type: BonusLoyaltyDependentTypeEnum) => {
    if (type === BonusLoyaltyDependentTypeEnum.LIMIT) {
      setIsAmountLoyaltyDependent(event.target.checked);
      setValue('playthroughBonusAmountIsLoyaltyDependent', event.target.checked);
    } else {
      setIsPlaythroughLoyaltyDependent(event.target.checked);
      setValue('playthroughBonusPlaythroughIsLoyaltyDependent', event.target.checked);
    }
  };

  return (
    <IndividualActionFormListItem
      enabled={isEnabledPlaythrough}
      name="enabledPlaythrough"
      listItemText={t('playthroughBonus.mainTitle')}
    >
      <TextField
        size="small"
        key="playthroughBonusName"
        name="playthroughBonusName"
        helperText={
          errors['playthroughBonusName']
            ? capitalize(t('playthroughBonus.helpText.name.error'))
            : capitalize(t('playthroughBonus.helpText.name.default'))
        }
        error={Boolean(errors['playthroughBonusName'])}
        disabled={!isEnabledPlaythrough}
        inputRef={register({ required: isEnabledPlaythrough, maxLength: 256 })}
        type="text"
        required={isEnabledPlaythrough}
        label={capitalize(t('playthroughBonus.fields.name'))}
      />
      <Typography fontWeight={600}>{capitalize(t('playthroughBonus.subtitle.bonusSettings'))}</Typography>
      <BonusLoyaltyDependantSection
        enabledDeposit={isEnabledPlaythrough}
        switchControlKey="playthroughBonusAmountIsLoyaltyDependent"
        helpTextKey="playthroughBonus"
        name="playthroughBonusAmountLoyaltyDependentValue"
        isExtendedLoyaltyDependentMode={isAmountLoyaltyDependent}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.LIMIT}
        mainLabel={t('playthroughBonus.fields.amountIsLoyaltyDependent')}
        inputLabel={t('playthroughBonus.fields.amount')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.EURO}
        loyaltyDependentOptions={limitFields}
      />
      <BonusLoyaltyDependantSection
        enabledDeposit={isEnabledPlaythrough}
        switchControlKey="playthroughBonusPlaythroughIsLoyaltyDependent"
        helpTextKey="playthroughBonus"
        name="playthroughBonusPlaythroughLoyaltyDependentValue"
        isExtendedLoyaltyDependentMode={isPlaythroughLoyaltyDependent}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.PLAYTHROUGH}
        mainLabel={t('depositBonus.fields.playthroughIsLoyaltyDependent')}
        inputLabel={t('depositBonus.fields.playthrough')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.BONUS}
        loyaltyDependentOptions={playthroughFields}
      />
      <Typography fontWeight={600}>{capitalize(t('playthroughBonus.subtitle.contentSettings'))}</Typography>
      <ContentFilterButton control={control} name="playthroughBonusContentFilterId" />
      <Typography fontWeight={600}>{capitalize(t('playthroughBonus.subtitle.imageSettings'))}</Typography>
      <MediaFieldsWithPreview
        control={control}
        size="small"
        key="playthroughBonusImages"
        name="playthroughBonusImages"
        error={Boolean(errors['playthroughBonusImages'])}
        type="text"
        required={false}
        label={capitalize(t('addImages'))}
        mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.BonusImage}
        isMultiSelect={true}
      />
      <Typography fontWeight={600}>{capitalize(t('playthroughBonus.subtitle.timeSettings'))}</Typography>
      <SelectInputDurationField
        size="small"
        key="playthroughBonusExpirationDuration"
        name="playthroughBonusExpirationDuration"
        helperText={capitalize(t('playthroughBonus.helpText.expirationDuration.default'))}
        inputRef={register({ required: isEnabledPlaythrough, min: 0, setValueAs: value => parseInt(value) })}
        inputProps={{ min: 0 }}
        error={Boolean(errors['playthroughBonusExpirationDuration'])}
        disabled={!isEnabledPlaythrough}
        type="number"
        required={isEnabledPlaythrough}
        label={capitalize(t('playthroughBonus.fields.expirationDuration'))}
        chosenValue={playthroughExpirationDuration}
        options={durationOptions}
        onChange={setPlaythroughExpirationDuration}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
    </IndividualActionFormListItem>
  );
};

export default PlaythroughBonusForm;
