import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { GameProviderGamesSchemaItem, useGetGameProviderGames, useGetGameProviders } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  Autocomplete,
  Box,
  capitalize,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGridProProps, GridColumns } from '@mui/x-data-grid-pro';
import { endOfMonth, getUnixTime, startOfMonth } from 'date-fns';

import { DataGridContainer, PermissionWrapper } from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { formatCurrency, formatDecimal, formatPercentage } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import GameProviderDateFilters, { GameProviderSearchFilters } from './GameProviderDateFilters';
import GameProviderDetailsTableData from './GameProviderDetailsTableData';

type Row = GameProviderGamesSchemaItem & { id: number };

const PAGE_SIZE = 25;

const GameProviderGames = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const canReadProviderGames = usePermission(OnlineCasinoPermissions.getGameProviderGames);
  const { data: gameProviders } = useGetGameProviders();

  const [searchParams, setSearchParams] = useSearchParams();
  const providerQuery = searchParams.get(GameProviderSearchFilters.PROVIDER) || '';
  const startDateQuery = searchParams.get(GameProviderSearchFilters.START_DATE);
  const endDateQuery = searchParams.get(GameProviderSearchFilters.END_DATE);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const chosenProvider = useMemo(
    () => gameProviders?.find(provider => provider?.providerName === providerQuery),
    [gameProviders, providerQuery]
  );

  const updateQueryParamsHandler = useCallback(
    (key: string, value: string) => {
      searchParams.set(key, value);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  const {
    data: gameProviderGames,
    isLoading,
    isError,
  } = useGetGameProviderGames(
    providerQuery,
    {
      startdate: Number(startDateQuery),
      enddate: Number(endDateQuery),
    },
    {
      query: {
        enabled: canReadProviderGames && !!providerQuery && !!startDateQuery && !!endDateQuery,
      },
    }
  );

  useEffect(() => {
    if (!startDateQuery && !endDateQuery) {
      updateQueryParamsHandler(GameProviderSearchFilters.START_DATE, String(getUnixTime(startOfMonth(new Date()))));
      updateQueryParamsHandler(GameProviderSearchFilters.END_DATE, String(getUnixTime(endOfMonth(new Date()))));
    }
  }, [endDateQuery, startDateQuery]);

  useEffect(() => {
    if (!providerQuery && gameProviders?.length) {
      updateQueryParamsHandler(GameProviderSearchFilters.PROVIDER, gameProviders[0].providerName);
    }
  }, [gameProviders, providerQuery]);

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'name',
        headerName: capitalize(t('name')),
        sortable: false,
        minWidth: 220,
        flex: 0.15,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{row?.name}</Typography>;
        },
      },
      {
        field: 'games',
        headerName: capitalize(t('games')),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{formatDecimal(row?.games)}</Typography>;
        },
      },
      {
        field: 'cashRevenue',
        headerName: capitalize(t('cashRevenue')),
        sortable: false,
        minWidth: 130,
        flex: 0.08,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{formatCurrency(row?.cashRevenue)}</Typography>;
        },
      },
      {
        field: 'cashPayout',
        headerName: capitalize(t('cashPayout')),
        sortable: false,
        minWidth: 130,
        flex: 0.08,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{`${formatPercentage(row?.cashPayout, { fixedTo: 2 })}%`}</Typography>;
        },
      },
      {
        field: 'promoRevenue',
        headerName: capitalize(t('promoRevenue')),
        sortable: false,
        minWidth: 130,
        flex: 0.08,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{formatCurrency(row?.promoRevenue)}</Typography>;
        },
      },
      {
        field: 'promoPayout',
        headerName: capitalize(t('promoPayout')),
        sortable: false,
        minWidth: 100,
        flex: 0.08,
        renderCell: ({ row }: { row: Row }) => {
          return <Typography variant="body2">{`${formatPercentage(row?.promoPayout, { fixedTo: 2 })}%`}</Typography>;
        },
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      gameProviderGames?.map<GameProviderGamesSchemaItem>((gameProvider, index) => ({
        id: index,
        ...gameProvider,
      })) ?? [],
    [gameProviderGames]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: Row }) => {
      if (row?.details) {
        return (
          <Box sx={{ m: 2 }}>
            <Card elevation={1} sx={{ px: 2, mb: 4 }}>
              <CardContent>
                <GameProviderDetailsTableData
                  title={t('reporting.financial.gameProviders.titles.providerGameDetails')}
                  details={row.details}
                />
              </CardContent>
            </Card>
          </Box>
        );
      }
    },
    [t]
  );

  return (
    <Box mt={4}>
      <Box
        display="flex"
        gap={2}
        sx={{
          [theme.breakpoints.up('xs')]: {
            flexDirection: 'column',
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
        }}
      >
        <GameProviderDateFilters />
        <Autocomplete
          sx={{ width: '230px' }}
          multiple={false}
          options={gameProviders || []}
          getOptionLabel={option => option?.providerName}
          renderInput={params => <TextField {...params} size="small" label={capitalize(t('gameprovider'))} />}
          value={chosenProvider || null}
          onChange={(e, provider) =>
            updateQueryParamsHandler(GameProviderSearchFilters.PROVIDER, provider?.providerName || '')
          }
        />
      </Box>
      <Stack spacing={2} mt={2}>
        <PermissionWrapper
          errorMessage={t('reporting.financial.providersGames.permissions.fetchError')}
          isError={isError}
          isLoading={false}
          permission={OnlineCasinoPermissions.getGameProviderGames}
        >
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              columns={columns}
              rows={rows}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              components={{
                LoadingOverlay: LinearProgress,
                Footer: () => (
                  <>
                    <DataGridCustomPagination
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[10, 25, 50, 100, 200]}
                      count={gameProviderGames?.length ? Math.ceil(gameProviderGames.length / pageSize) : 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      paginationMode="client"
                    />
                  </>
                ),
              }}
            />
          </DataGridContainer>
        </PermissionWrapper>
      </Stack>
    </Box>
  );
};

export default GameProviderGames;
