import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDepositLimits } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Tooltip } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { formatCurrency, StatusSpan } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import { DataGridContainer, DateTime, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

type DepositLimitsProps = {
  userId: string;
  activePage?: number;
  startDate?: Date;
  endDate?: Date;
};

const PAGE_SIZE = 25;

const LimitsTable = ({ userId, activePage = 0, startDate, endDate }: DepositLimitsProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(activePage);
  const canReadDepositLimits = usePermission(OnlineCasinoPermissions.getDepositLimits);
  const {
    data: depositsLimits,
    isLoading,
    isError,
    error,
  } = useGetDepositLimits(
    {
      userId: parseInt(userId, 10),
      page,
      pageSize: PAGE_SIZE,
      ...(startDate ? { limitsStartedAfter: (startDate.getTime() ?? 0) / 1000 } : {}),
      ...(endDate ? { limitsStartedBefore: (endDate.getTime() ?? 0) / 1000 } : {}),
    },
    { query: { enabled: canReadDepositLimits } }
  );

  const headers: GridColumns = [
    {
      headerName: t('startDate'),
      field: 'startDate',
      renderCell: ({ row }) => <DateTime dateTime={row.startDate ?? 0} />,
      flex: 1,
      minWidth: 160,
    },
    {
      headerName: t('endDate'),
      field: 'endDate',
      renderCell: ({ row }) => <DateTime dateTime={row.endDate ?? 0} />,
      flex: 1,
      minWidth: 160,
    },
    { headerName: t('id'), field: 'id', flex: 0.5, minWidth: 100 },
    { headerName: t('period'), field: 'period', flex: 1, minWidth: 80 },
    {
      headerName: t('value'),
      field: 'value',
      flex: 1,
      minWidth: 100,
      valueGetter: ({ row }) => formatCurrency(row.value),
    },
    {
      headerName: t('source'),
      field: 'source',
      flex: 1,
      minWidth: 80,
    },
    {
      headerName: t('status'),
      field: 'status',
      flex: 1,
      minWidth: 100,
      renderCell: ({ value }) => <StatusSpan value={value} />,
    },
    {
      headerName: t('common.additionalInfo'),
      field: 'additionalData',
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>
        </Tooltip>
      ),
      flex: 1,
      minWidth: 250,
    },
  ];

  const rows: GridRowsProp = depositsLimits?.depositLimits?.map(row => ({ ...row })) ?? [];

  return (
    <>
      <PermissionWrapper
        errorMessage={error?.message}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getDepositLimits}
      >
        <DataGridContainer>
          <StyledDataGrid
            columns={headers}
            rows={rows}
            page={depositsLimits?.pagingDetails?.currentPage}
            rowCount={depositsLimits?.pagingDetails?.totalItems}
            pageSize={PAGE_SIZE}
            onPageChange={setPage}
            paginationMode="server"
            pagination
          />
        </DataGridContainer>
      </PermissionWrapper>
    </>
  );
};

export default memo(LimitsTable);
