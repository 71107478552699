import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ErrorSchema, FreeSpinSchema, getGetAllAccountDataQueryKey, useCancelFreeSpins } from '@greenisland/stores';
import { Button, capitalize, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';

type CancelDialogProps = {
  isDialogOpen: boolean;
  toggleDialog: (state: boolean) => void;
  freeSpins: FreeSpinSchema;
  userId: string;
};

const CancelFreeSpinsDialog = ({ isDialogOpen, toggleDialog, freeSpins, userId }: CancelDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { handleSubmit } = useForm<FormData>({
    mode: 'onChange',
  });

  const { mutate, isLoading } = useCancelFreeSpins({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        toggleDialog(false);
        await queryClient.invalidateQueries(getGetAllAccountDataQueryKey(userId));
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(t(error.message ? error.message : 'error'), { variant: 'error' });
      },
    },
  });

  const onSubmit = () => {
    if (freeSpins?.freeSpinId) {
      mutate({ freeSpinsId: freeSpins.freeSpinId });
    } else {
      enqueueSnackbar(t('lookup.freeSpins.error.noFreeSpinsId'), { variant: 'error' });
    }
  };

  return (
    <Dialog fullWidth open={isDialogOpen} onClose={() => toggleDialog(false)}>
      <DialogTitle>{capitalize(t('lookup.freeSpins.cancel'))}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {t('lookup.freeSpins.cancelMessage', {
            spins_amount: freeSpins.amountOfSpins,
            game_name: freeSpins.game?.name,
            provider: freeSpins.provider,
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => toggleDialog(false)}>
            {t('No')}
          </Button>
          <Button type="submit" variant="contained" color="error" disabled={isLoading}>
            {isLoading ? <CircularProgress size={20} /> : t('Yes')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CancelFreeSpinsDialog;
