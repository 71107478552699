import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { EWithdrawalTypeSchema, useRetryWithdrawalV2, WithdrawalsInner } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { usePermission } from 'src/app/hooks';

import { FontAwesomeIcon, Link } from '@greenisland-common/components/atoms';

import DeclineDialog from './DeclineDialog';

interface Props {
  row: WithdrawalsInner & { id: string | undefined };
  destinationName: EWithdrawalTypeSchema;
}

const ActionCell = ({ row, destinationName }: Props) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState('');
  const canReadEventStream = usePermission(OnlineCasinoPermissions.getEventstreamEventname);
  const canRetryWithdrawal = usePermission(OnlineCasinoPermissions.retryWithdrawalV2);
  const canDeclineWithdrawal = usePermission(OnlineCasinoPermissions.declineWithdrawalV2);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: retryWithdrawal, isLoading: isRetryingWithdrawals } = useRetryWithdrawalV2({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('success', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('error', { variant: 'error' });
      },
    },
  });

  return (
    <>
      <Stack direction="row" spacing={1}>
        {canReadEventStream ? (
          <Link to={`../../../users/${row.userId}/withdrawals/${row.withdrawalId}`} underline="none">
            <Button key="canReadEventStream" variant="text">
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </Link>
        ) : null}
        {canRetryWithdrawal && row.status.toLowerCase() === 'processingfailed' ? (
          <Button
            key="retry"
            variant="contained"
            color="secondary"
            onClick={() => retryWithdrawal({ withdrawalId: parseInt(row.withdrawalId) })}
          >
            {t('retry')}
          </Button>
        ) : null}
        {canDeclineWithdrawal ? (
          <Button key="decline" variant="contained" color="error" onClick={() => setDialogOpen(row.withdrawalId)}>
            {t('decline')}
          </Button>
        ) : null}
      </Stack>
      {dialogOpen != '' && (
        <DeclineDialog withdrawalId={dialogOpen} destinationName={destinationName} close={() => setDialogOpen('')} />
      )}
    </>
  );
};

export default ActionCell;
