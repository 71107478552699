import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { EUserStatusSchema, getGetUserGeneralQueryKey, UserGeneralSchema, useSetUserStatus } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useSnackbar } from 'notistack';

import GenericField from './GenericField';

interface Props {
  user: UserGeneralSchema;
}

const LockedField = ({ user }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const updateMutation = useSetUserStatus({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: async () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { isLockedStatus, testUser } = user;
  const lockUserTitle = testUser ? t('lookup.verification.lockTestUserTitle') : t('lookup.verification.lockUser');
  const title = isLockedStatus?.isLocked ? t('lookup.verification.unlockUser') : lockUserTitle;

  const handleLockUser = (reason?: string) => {
    updateMutation.mutate({ userId: user.userId, data: { status: EUserStatusSchema.Locked, reason } });
  };

  return (
    <GenericField
      withReason
      value={isLockedStatus?.isLocked}
      error={isLockedStatus?.isLocked}
      disabled={testUser && !isLockedStatus?.isLocked}
      tooltip={title}
      description={
        isLockedStatus?.isLocked ? t('lookup.verification.unlockDescription') : t('lookup.verification.lockDescription')
      }
      togglePermissions={OnlineCasinoPermissions.setUserStatus}
      onToggle={handleLockUser}
    />
  );
};

export default LockedField;
