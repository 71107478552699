import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlagDataSchema } from '@greenisland/stores';
import { Box, Typography } from '@mui/material';

interface Props {
  flagData?: FlagDataSchema;
}

const FlagDataDetails = ({ flagData }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ paddingY: 1, paddingLeft: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, marginLeft: 3, marginBottom: 3 }}>
        <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
          {t('flagdata')}
        </Typography>
      </Box>
      {flagData?.data?.map((e, i) => {
        return (
          <div key={i}>
            <span>
              <b>{e.reason}</b>
            </span>
            <span>{e.info?.value ? ': ' + e.info?.value : ''}</span>
            <br></br>
            {e.info?.matchedInfo?.map((e, i) => {
              return (
                <span key={i}>
                  {e}
                  <br></br>
                </span>
              );
            })}
            <br></br>
          </div>
        );
      })}
    </Box>
  );
};

export default memo(FlagDataDetails);
