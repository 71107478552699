import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EUserLimitV2UpdateRequestTypeSchema,
  getGetActiveBalanceLimitForUserQueryKey,
  UpdateBalanceLimitRequestSchema,
  useRequestBalanceLimitUpdate,
} from '@greenisland/stores';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, MenuItem, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

interface Props {
  userId: number;
  onClose: () => void;
}

const RequestNewLimitForm = ({ userId, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const methods = useForm<UpdateBalanceLimitRequestSchema>({
    defaultValues: { updateRequestType: 'update' },
    mode: 'onChange',
  });
  const { register, handleSubmit, errors, control, watch } = methods;

  const { mutate, isLoading } = useRequestBalanceLimitUpdate({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetActiveBalanceLimitForUserQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
        onClose();
      },
      onError: error => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const typeOptions = Object.keys(EUserLimitV2UpdateRequestTypeSchema);

  const onSubmit = (data: UpdateBalanceLimitRequestSchema) => {
    mutate({ data: { ...data, userId } });
  };

  return (
    <Box pt={1}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <Controller
              name="updateRequestType"
              control={control}
              rules={{ required: t('fieldIsRequired') }}
              defaultValue={''}
              render={({ value, ...rest }) => (
                <TextField
                  {...rest}
                  size="small"
                  sx={{ mr: 2, minWidth: 200 }}
                  label={t('type')}
                  select
                  fullWidth
                  value={value ?? ''}
                  error={!!errors.updateRequestType}
                  helperText={errors.updateRequestType?.message}
                >
                  {typeOptions?.map(type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <TextField
              label={t('value')}
              disabled={watch('updateRequestType') === EUserLimitV2UpdateRequestTypeSchema.cancel}
              name="value"
              type="number"
              size="small"
              fullWidth
              inputRef={register({
                required:
                  watch('updateRequestType') !== EUserLimitV2UpdateRequestTypeSchema.cancel && t('fieldIsRequired'),
                valueAsNumber: true,
                min: 0,
              })}
              error={!!errors.value}
            />
          </Stack>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={onClose}>
              {t('cancel')}
            </Button>
            <LoadingButton type="submit" variant="contained" color="primary" loading={isLoading}>
              {t('save')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Box>
  );
};

export default RequestNewLimitForm;
