import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserLimitUpdateRequestWithReferenceSchema } from '@greenisland/stores';
import { Box, capitalize, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import AddictionMonitor from '../../Lookup/Components/DetailsComponents/AddictionMonitor/AddictionMonitor';
import ResponsibleGamingTestResponsesCard from '../../ResponsibleGaming/ResponsibleGamingTestResponses/ResponsibleGamingTestResponsesCard';

interface Props {
  chosenRequestDetails: UserLimitUpdateRequestWithReferenceSchema;
  onClose: () => void;
}

const UserLimitsRequestDetailsDialog = ({ chosenRequestDetails, onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const { depositLimitUpdateRequest, reference } = chosenRequestDetails;

  return (
    <Draggable cancel="p" {...(isLg && { bounds: 'body' })}>
      <Box
        className="box"
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
            position: 'absolute',
            backgroundColor: theme.palette.background.default,
            pt: 2,
            px: 2,
            zIndex: 9999,
            cursor: 'grab',
            borderRadius: '10px',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
          },
          [theme.breakpoints.up('md')]: {
            width: 'fit-content',
            minWidth: '30%',
            pt: 3,
            px: 3,
          },
        }}
      >
        <Stack component="p" className="no-cursor" spacing={2} sx={{ cursor: 'initial' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 16,
              top: 8,
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
          <Typography
            fontWeight={500}
            variant="body2"
            pb={2}
            sx={{
              [theme.breakpoints.up('xs')]: {
                fontSize: 'large',
              },
              [theme.breakpoints.up('md')]: {
                fontSize: 'x-large',
              },
            }}
          >
            {t('tasks.userLimits.titles.depositLimitRequestIdContext', {
              id: depositLimitUpdateRequest?.userId,
            })}
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            {depositLimitUpdateRequest?.userId && (
              <AddictionMonitor userId={depositLimitUpdateRequest?.userId?.toString()} showSetManuallyButton={false} />
            )}
            {reference?.referenceId ? (
              <ResponsibleGamingTestResponsesCard
                showSeeMoreButton={false}
                customPrimaryKey={reference?.referenceId?.toString()}
                customUserId={depositLimitUpdateRequest?.userId?.toString()}
              />
            ) : (
              <Typography fontWeight={500} variant="body2" fontSize="large" color={theme.palette.error.dark} py={2}>
                {capitalize(t('tasks.userLimits.titles.noLinkedResponse'))}
              </Typography>
            )}
          </Box>
        </Stack>
      </Box>
    </Draggable>
  );
};

export default UserLimitsRequestDetailsDialog;
