import { useTranslation } from 'react-i18next';
import { BasicInfoStatisticsSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, useTheme } from '@mui/material';

import { PermissionWrapper } from '@greenisland-common/components/atoms';

import { DashboardCardHeight, DashboardCardWidth } from '../../hooks';
import { StatisticsCardVariant } from './Constants';
import StatisticsCard from './StatisticsCard';

export const StatisticsCardSections = [
  {
    key: 'registrationStatistics' as keyof BasicInfoStatisticsSchema,
    cardVariant: StatisticsCardVariant.REGISTRATION,
  },
  {
    key: 'uniqueLoginStatistics' as keyof BasicInfoStatisticsSchema,
    cardVariant: StatisticsCardVariant.UNIQUE_LOGIN,
  },
  {
    key: 'activeAccountStatistics' as keyof BasicInfoStatisticsSchema,
    cardVariant: StatisticsCardVariant.ACTIVE_ACCOUNT,
  },
  {
    key: 'totalBetStatistics' as keyof BasicInfoStatisticsSchema,
    cardVariant: StatisticsCardVariant.TOTAL_BET,
  },
];

const StatisticsCardWrapper = ({
  basicInfoStatistics,
  isLoading,
  isError,
  section,
}: {
  basicInfoStatistics: BasicInfoStatisticsSchema | undefined;
  isLoading: boolean;
  isError: boolean;
  section: typeof StatisticsCardSections;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      width={DashboardCardWidth.FULL}
      gap={1}
      sx={{
        height: '100%',
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
          height: DashboardCardHeight.XSMALL,
        },
        '.MuiPaper-root, .MuiSkeleton-root': {
          width: DashboardCardWidth.FULL,
          height: `${DashboardCardHeight.TINY} !important`,
          [theme.breakpoints.up('lg')]: {
            height: `${DashboardCardHeight.XSMALL} !important`,
          },
        },
      }}
    >
      {section.map(({ key, cardVariant }) => (
        <PermissionWrapper
          key={key}
          errorMessage={t('statistics.fetchError')}
          isError={isError}
          isLoading={isLoading}
          permission={OnlineCasinoPermissions.getBasicInfo}
        >
          {basicInfoStatistics ? (
            <StatisticsCard cardVariant={cardVariant} statistics={basicInfoStatistics[key]} />
          ) : (
            <></>
          )}
        </PermissionWrapper>
      ))}
    </Box>
  );
};

export default StatisticsCardWrapper;
