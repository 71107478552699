import { useMemo } from 'react';
import { useGetCampaignActions } from '@greenisland/stores';

interface CampaignActionsResponse {
  isLoading: boolean;
  actions: {
    label: string;
    value: string;
  }[];
}

export const useGetSortedAutocompleteCampaignActions = (): CampaignActionsResponse => {
  const { data: actions, isLoading } = useGetCampaignActions();

  const formattedActions = useMemo(() => {
    return (
      actions
        ?.sort((a, b) => a.actionName.localeCompare(b.actionName))
        ?.map(entry => ({ label: entry.actionName, value: entry.actionId })) ?? []
    );
  }, [actions]);

  return {
    isLoading: isLoading,
    actions: formattedActions,
  };
};
