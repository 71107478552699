import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerificationsWalletSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Popper, Typography } from '@mui/material';

import { usePermission } from '../../../../../../app/hooks';
import VerificationStatusField from './VerificationStatusField';

interface Props {
  wallets?: VerificationsWalletSchema[];
}

const WalletList = ({ wallets }: Props) => {
  const { t } = useTranslation();
  const canReadUserVerification = usePermission(OnlineCasinoPermissions.getUserVerification);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'wallet-popper' : undefined;

  return canReadUserVerification ? (
    <>
      <Box
        component="span"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {wallets?.length ? (
          <Typography variant="body2">{`${wallets.length} ${t('wallets')}`}</Typography>
        ) : (
          <p>{t('noWallets')}</p>
        )}
        {wallets?.length ? (
          <Button aria-describedby={id} variant="text" onClick={handleClick}>
            {open ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
          </Button>
        ) : null}
      </Box>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box component="ul" sx={{ listStyle: 'none', p: 2, backgroundColor: 'background.paper', boxShadow: 2 }}>
          {wallets?.map(
            ({ paymentMethod, walletEmail, walletPKey, verificationStatus, archived }) =>
              !archived && (
                <Box component="li" sx={{ display: 'flex', justifyContent: 'space-between' }} key={walletPKey}>
                  <Box
                    component="span"
                    sx={{ whiteSpace: 'nowrap', mr: '15px' }}
                  >{`${paymentMethod} ${walletEmail}`}</Box>
                  <VerificationStatusField status={verificationStatus} />
                </Box>
              )
          )}
        </Box>
      </Popper>
    </>
  ) : null;
};

export default WalletList;
