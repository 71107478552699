import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateTestUserRequestSchema } from '@greenisland/stores';
import { Box, capitalize, Checkbox, FormControlLabel } from '@mui/material';

import { Checkbox as FormControlCheckbox } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import CollapseSectionWrapper from './CollapseSectionWrapper';

const TestUserEmailSettings = () => {
  const { t } = useTranslation();

  const { control, watch, setValue } = useFormContext<CreateTestUserRequestSchema>();

  const isEmailVerified = watch('email.emailVerified');
  const [isEmailChosen, setIsEmailChosen] = useState(false);

  useEffect(() => {
    if (!isEmailChosen) {
      setValue('email.email', '');
      setValue('email.emailVerified', false);
    }
  }, [isEmailChosen, setValue]);

  return (
    <CollapseSectionWrapper title={t('email')}>
      <Box>
        <FormControlLabel
          control={<Checkbox color="primary" size="medium" />}
          onChange={(_, value) => setIsEmailChosen(value)}
          label={t('common.chooseEmail')}
          checked={isEmailChosen}
          sx={{ '.MuiTypography-root': { fontWeight: isEmailChosen ? 600 : 400 } }}
        />
        <Box ml={4}>
          <Input
            fullWidth
            size="small"
            label={capitalize(t('email'))}
            control={control}
            type="email"
            name="email.email"
            required={isEmailChosen}
            disabled={!isEmailChosen}
            rules={
              isEmailChosen
                ? {
                    required: t('fieldIsRequired'),
                  }
                : {}
            }
            sx={{ my: 2 }}
          />
          <FormControlCheckbox
            label={t('common.emailVerified')}
            name="email.emailVerified"
            sx={{ '.MuiTypography-root': { fontWeight: isEmailVerified ? 600 : 400 } }}
            disabled={!isEmailChosen}
          />
        </Box>
      </Box>
    </CollapseSectionWrapper>
  );
};

export default TestUserEmailSettings;
