import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useGetAddictionCategory } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  DataGridPlain,
  DataGridPlainHeader,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';

import ClassificationResultField from './components/ClassificationResultField';
import SetManuallyDialog from './components/SetManuallyDialog';
import { getDateFromUnix, getDateTimeFromUnix } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import PgsiDialog from '../Pgsi/PgsiDialog';
import PgsiResultField from '../PgsiResultField';
import AddictionFormulaDialog from './AddictionFormulaDialog';

interface Props {
  userId: string;
  showSetManuallyButton?: boolean;
}

const AddictionMonitor = ({ userId, showSetManuallyButton = true }: Props) => {
  const { t } = useTranslation();

  const canReadAddictionMonitor = usePermission(OnlineCasinoPermissions.getAddictionCategory);
  const canWriteAddictionMonitor = usePermission(OnlineCasinoPermissions.setAddictionCategoryManually);

  const [pgsiDialog, setPgsiDialog] = useState(false);
  const [addictionFormulaDialog, setAddictionFormulaDialog] = useState(false);
  const [setManuallyDialog, setSetManuallyDialog] = useState(false);

  const { data, isLoading, isError } = useGetAddictionCategory(userId, {
    query: { enabled: canReadAddictionMonitor },
  });

  const headers: GridColumns = [
    {
      headerName: '',
      field: 'title',
      renderCell: ({ value }) => (
        <DataGridPlainHeader
          sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          value={value}
        />
      ),
      flex: 0.5,
    },
    { headerName: '', field: 'value', renderCell: ({ value }) => value, flex: 0.5 },
  ];

  const rows: GridRowsProp = [
    {
      id: 1,
      title: t('addictionMonitor.classification'),
      value: data?.classification ? <ClassificationResultField value={data?.classification} /> : '-',
    },
    {
      id: 2,
      title: t('addictionMonitor.source'),
      value: data?.source,
    },
    {
      id: 3,
      title: t('addictionMonitor.endDate'),
      value: data?.expiresAt && data?.expiresAt > 0 ? getDateFromUnix(data?.expiresAt) : '-',
    },
    {
      id: 4,
      title: t('addictionMonitor.pgsi'),
      value:
        data?.pgsiTest && data?.pgsiTest?.PGSIClassification ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <PgsiResultField value={data?.pgsiTest?.PGSIClassification} />
            <IconButton size="small" color="secondary" sx={{ ml: 2 }} onClick={() => setPgsiDialog(true)}>
              <FontAwesomeIcon icon={faEye} />
            </IconButton>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      id: 5,
      title: t('addictionMonitor.formula.addiction'),
      value: data?.addictionFormula ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {data?.addictionFormula?.classificationResult ? (
            <Box mr={2} component="span">
              <ClassificationResultField value={data?.addictionFormula?.classificationResult} />
            </Box>
          ) : null}
          <IconButton size="small" color="secondary" onClick={() => setAddictionFormulaDialog(true)}>
            <FontAwesomeIcon icon={faEye} />
          </IconButton>
        </Box>
      ) : (
        '-'
      ),
    },
    {
      id: 6,
      title: t('addictionMonitor.lastUpdated'),
      value: data?.startsAt && data?.startsAt > 0 ? getDateTimeFromUnix(data?.startsAt, true) : '-',
    },
  ];

  return (
    <>
      <Card sx={{ pl: 0, pr: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('addictionMonitor.AddictionMonitor')} />
          {canWriteAddictionMonitor && showSetManuallyButton && (
            <Button
              sx={{ mr: 2 }}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => setSetManuallyDialog(true)}
            >
              {t('addictionMonitor.setManually')}
            </Button>
          )}
        </Box>
        <CardContent sx={{ pl: 0, pr: 0 }}>
          <PermissionWrapper
            errorMessage={t('addictionMonitor.errors.fetchError')}
            isError={isError}
            isLoading={false}
            permission={OnlineCasinoPermissions.getAddictionCategory}
          >
            <DataGridContainer>
              <DataGridPlain
                density="compact"
                autoHeight
                rowHeight={50}
                loading={isLoading}
                rows={rows}
                columns={headers}
              />
            </DataGridContainer>
          </PermissionWrapper>
        </CardContent>
      </Card>
      {canWriteAddictionMonitor && setManuallyDialog && (
        <SetManuallyDialog open={setManuallyDialog} setOpen={setSetManuallyDialog} userId={userId} />
      )}
      {pgsiDialog && data?.pgsiTest && (
        <PgsiDialog openDialog={pgsiDialog} setOpenDialog={setPgsiDialog} test={data.pgsiTest} />
      )}
      {addictionFormulaDialog && data?.addictionFormula && (
        <AddictionFormulaDialog
          openDialog={addictionFormulaDialog}
          setOpenDialog={setAddictionFormulaDialog}
          formula={data.addictionFormula}
        />
      )}
    </>
  );
};

export default AddictionMonitor;
