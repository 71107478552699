import { EMaxLimitBadRequestTypeSchema } from '@greenisland/stores';

type MaxLimitErrorReasons = {
  [key in EMaxLimitBadRequestTypeSchema]: string;
};

export const MAX_LIMIT_ERROR_REASONS: MaxLimitErrorReasons = {
  [EMaxLimitBadRequestTypeSchema.MaxLimitAlreadyExpired]: 'settings.maxLimits.errors.maxLimitAlreadyExpired',
  [EMaxLimitBadRequestTypeSchema.MaxLimitAlreadyCancelled]: 'settings.maxLimits.errors.maxLimitAlreadyCancelled',
  [EMaxLimitBadRequestTypeSchema.MaxLimitNotFound]: 'settings.maxLimits.errors.maxLimitNotFound',
  [EMaxLimitBadRequestTypeSchema.CancellationReasonEmpty]: 'settings.maxLimits.errors.cancellationReasonEmpty',
  [EMaxLimitBadRequestTypeSchema.CancelledByEmpty]: 'settings.maxLimits.errors.cancelledByEmpty',
};
