import { useFormContext } from 'react-hook-form';
import { EMediaGalleryEntryTypeSchema } from '@greenisland/stores';
import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';

import MediaField from '@greenisland-common/components/molecules/MediaField';

const EditPaymentMethodImages = () => {
  const { control } = useFormContext();

  return (
    <>
      <Typography variant="h4">{t('paymentMethodImages')}</Typography>
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap" justifyContent="space-around">
        <MediaField
          control={control}
          name={'logoUrl'}
          label={t('paymentMethods.logo')}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.PaymentMethodLogo}
        />
        <MediaField
          control={control}
          name={'logoGreyUrl'}
          label={t('paymentMethods.logoGrey')}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.PaymentMethodLogo}
        />
        <MediaField
          control={control}
          name={'logoWhiteUrl'}
          label={t('paymentMethods.logoWhite')}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.PaymentMethodLogo}
        />
        <MediaField
          control={control}
          name={'logoBlackUrl'}
          label={t('paymentMethods.logoBlack')}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.PaymentMethodLogo}
        />
        <MediaField
          control={control}
          name={'logoLightUrl'}
          label={t('paymentMethods.logoColorLight')}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.PaymentMethodLogo}
        />
        <MediaField
          control={control}
          name={'logoDarkUrl'}
          label={t('paymentMethods.logoColorDark')}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.PaymentMethodLogo}
        />
      </Stack>
    </>
  );
};

export default EditPaymentMethodImages;
