import { memo, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetWithdrawalsGeneralParams, useGetWithdrawalsGeneral, WithdrawalSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { DataGridProProps } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPagination, StyledDataGrid } from '@greenisland-common/components/atoms';

import { numberOfRowsPerPage } from '../../../../app/constants/defaultvalues';
import { usePermission } from '../../../../app/hooks';
import CodeDialog from '../Components/DetailsComponents/CodeDialog';
import SearchWithdrawal from '../Components/SearchWithdrawal';
import useGetWithdrawalsHeaders from '../hooks/useGetWithdrawalsHeaders';
import FlagDataDetails from './FlagDataDetails';

const LookupWithdrawals = () => {
  const [hasDetails, setHasDetails] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const canReadEventStream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canReadWithdrawals = usePermission(OnlineCasinoPermissions.getWithdrawalsGeneral);

  const [searchQuery, setSearchQuery] = useState<GetWithdrawalsGeneralParams | undefined>(undefined);
  const [page, setPage] = useState(0);

  const { data: withdrawalsData, isLoading } = useGetWithdrawalsGeneral(
    { ...searchQuery, numberOfItems: numberOfRowsPerPage, pageNumber: page },
    {
      query: {
        enabled: Boolean(searchQuery) && canReadWithdrawals,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    }
  );

  const { columns, pinCode, setPinCode } = useGetWithdrawalsHeaders(hasDetails);

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: WithdrawalSchema }) => <FlagDataDetails flagData={row.flagData} />,
    []
  );

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  return (
    <>
      <SearchWithdrawal
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        setSearchQuery={setSearchQuery}
        hasDetails={hasDetails}
        setHasDetails={setHasDetails}
      />

      <DataGridContainer>
        <StyledDataGrid
          initialState={{ columns: { columnVisibilityModel: { stream: canReadEventStream } } }}
          density="compact"
          loading={isLoading}
          rows={withdrawalsData?.items ?? []}
          columns={columns}
          pagination
          page={page}
          pageSize={numberOfRowsPerPage}
          paginationMode="server"
          onPageChange={p => setPage(p)}
          showColumnRightBorder={false}
          disableDensitySelector
          disableSelectionOnClick
          rowCount={withdrawalsData?.pagingDetails?.totalItems ?? 0}
          components={{ Pagination: DataGridPagination }}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
        />
        <CodeDialog isOpen={Boolean(pinCode)} onClose={() => setPinCode(null)} code={pinCode || ''} />
      </DataGridContainer>
    </>
  );
};

export default memo(LookupWithdrawals);
