import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Inputfield } from '@greenisland/components';
import { Campaign } from '@greenisland/stores';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(() => ({
  content: {
    display: 'grid',
    gridGap: 16,
  },
  actions: {
    padding: '16px 24px',
  },
}));

interface Props {
  campaign?: Campaign;
  dialogOpen: boolean;
  handleClose: () => void;
  save: (data: any) => void;
  campaignsOverview: any;
}

const AddEditCampaignDialog = ({ campaign, dialogOpen, handleClose, save, campaignsOverview }: Props) => {
  const { t } = useTranslation();
  const classes = styles();
  const { register, handleSubmit, errors, reset, getValues } = useForm<Campaign>();

  useEffect(() => {
    if (campaign) {
      reset({ ...campaign });
    } else {
      reset({
        campaignId: '',
        campaignName: '',
        year: new Date().getFullYear().toString(),
        theme: '',
      });
    }
  }, [campaign]);

  const handleSave = (data: any) => {
    save({ ...data, campaignId: campaign ? campaign.campaignId : data.campaignId });
    handleClose();
  };

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={dialogOpen}>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle id="customized-dialog-title">{t('addCampaign')}</DialogTitle>
        <DialogContent className={classes.content}>
          <Inputfield
            label={t('campaignId')}
            name="campaignId"
            defaultValue={getValues('campaignId')}
            inputRef={register({
              required: true,
              validate: {
                unique: (value: string) => {
                  if (campaign) return true;
                  return campaignsOverview
                    .map((value: Campaign) => value.campaignId)
                    .find((id: string) => id === value);
                },
              },
            })}
            error={errors.campaignId ? true : false}
            helperText={
              errors.campaignId ? (errors.campaignId.type === 'unique' ? t('notUnique') : t('required')) : undefined
            }
            disabled={campaign ? true : false}
          />
          <Inputfield
            label={t('campaignName')}
            name="campaignName"
            inputRef={register({ required: true })}
            error={errors.campaignName ? true : false}
            helperText={errors.campaignName && t('required')}
          />
          <Inputfield
            label={t('year')}
            name="year"
            inputRef={register({ required: true })}
            error={errors.year ? true : false}
            helperText={errors.year && t('required')}
            defaultValue={new Date().getFullYear().toString()}
          />
          <Inputfield
            label={t('theme')}
            name="theme"
            inputRef={register({ required: true })}
            error={errors.theme ? true : false}
            helperText={errors.theme && t('required')}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose} color="secondary" variant="transparent">
            {t('close')}
          </Button>
          <Button type="submit" color="primary">
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(AddEditCampaignDialog);
