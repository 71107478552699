import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EKillSwitchErrorTypeSchema,
  ErrorSchema,
  getGetCloudwatchAlarmActionsEnabledQueryKey,
  useGetCloudwatchAlarmActionsEnabled,
  useSetCloudwatchAlarmActionsEnabled,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { alpha, Box, Button, capitalize, Card, CardContent, Chip, Skeleton, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';

import { PermissionWrapper } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../../../app/hooks';
import { KILL_SWITCH_ERROR_REASONS } from '../../Constants/KillswitchErrorReasons';
import KillSwitchHeaderPopoverContent from './KillSwitchHeaderPopoverContent';
import KillSwitchWarningDialog from './KillSwitchWarningDialog';

const KillSwitchTriggerField = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const canGetCloudwatchAlarmMissingState = usePermission(OnlineCasinoPermissions.getCloudwatchAlarmActionsEnabled);
  const canSetCloudwatchAlarmMissingState = usePermission(OnlineCasinoPermissions.setCloudwatchAlarmActionsEnabled);

  const [showWarningDialog, setShowWarningDialog] = useState(false);

  const {
    data: cloudwatchAlarmState,
    isLoading: isCloudwatchAlarmStateLoading,
    isError: isCloudwatchAlarmStateError,
    error: cloudwatchAlarmStateError,
  } = useGetCloudwatchAlarmActionsEnabled({ query: { enabled: canGetCloudwatchAlarmMissingState } });

  const { mutate: setCloudwatchAlarmState, isLoading: isSetCloudwatchAlarmStateLoading } =
    useSetCloudwatchAlarmActionsEnabled({
      mutation: {
        onSuccess: async () => {
          queryClient.invalidateQueries(getGetCloudwatchAlarmActionsEnabledQueryKey());
          enqueueSnackbar(t('success'), { variant: 'success' });
        },
        onError: (error: ErrorSchema) => {
          enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
        },
      },
    });

  const areActionsEnabled = cloudwatchAlarmState?.actionsEnabled === true;

  const renderStylesHandler = useCallback(
    (isEnabled: boolean) => {
      return {
        backgroundColor: alpha(isEnabled ? theme.palette.success.light : theme.palette.error.light, 0.5),
        color: isEnabled ? theme.palette.success.dark : theme.palette.error.dark,
        fontWeight: 500,
        mr: 2,
        minWidth: 120,
        height: 50,
        fontSize: 'large',
        borderRadius: 10,
        border: `2px solid ${isEnabled ? theme.palette.success.dark : theme.palette.error.dark}`,
        textTransform: 'capitalize',
      };
    },
    [theme.palette.error.dark, theme.palette.error.light, theme.palette.success.dark, theme.palette.success.light]
  );

  const handleSwitchSubmit = () => {
    setCloudwatchAlarmState({ params: { actionsEnabled: !areActionsEnabled } });
    setShowWarningDialog(false);
  };

  if (isCloudwatchAlarmStateLoading || isSetCloudwatchAlarmStateLoading)
    return <Skeleton variant="rectangular" height={140} />;

  if (isCloudwatchAlarmStateError) {
    if ('type' in cloudwatchAlarmStateError && cloudwatchAlarmStateError.type) {
      const errorReason: EKillSwitchErrorTypeSchema | undefined = cloudwatchAlarmStateError.type;
      const translationKey = errorReason ? KILL_SWITCH_ERROR_REASONS[errorReason] : 'somethingWentWrong';
      return <ErrorState errorMessage={t(translationKey)} />;
    } else {
      return <ErrorState errorMessage={t('somethingWentWrong')} />;
    }
  }

  return (
    <PermissionWrapper
      errorMessage={t('settings.killswitch.permissions.fetchAlarmError')}
      isError={isCloudwatchAlarmStateError}
      isLoading={false}
      permission={OnlineCasinoPermissions.getCloudwatchAlarmActionsEnabled}
    >
      <>
        <Card sx={{ borderRadius: 5 }}>
          <KillSwitchHeaderPopoverContent
            title={t('settings.killswitch.fields.triggerTitle')}
            message="settings.killswitch.triggerActionsInfo"
          />
          <CardContent>
            <Box my={1}>
              <Chip
                label={
                  areActionsEnabled
                    ? capitalize(t('settings.killswitch.state.armed'))
                    : capitalize(t('settings.killswitch.state.disarmed'))
                }
                color="error"
                sx={() => renderStylesHandler(areActionsEnabled)}
              />
              <Button
                variant="outlined"
                disabled={!canSetCloudwatchAlarmMissingState}
                onClick={() => setShowWarningDialog(true)}
                sx={{
                  ...renderStylesHandler(!areActionsEnabled),
                  backgroundColor: 'initial',
                  borderRadius: 2,
                  '&:hover': {
                    backgroundColor: alpha(
                      areActionsEnabled ? theme.palette.error.light : theme.palette.success.light,
                      0.5
                    ),
                    border: `2px solid ${areActionsEnabled ? theme.palette.error.dark : theme.palette.success.dark}`,
                  },
                }}
              >
                {!areActionsEnabled ? t('settings.killswitch.state.arm') : t('settings.killswitch.state.disarm')}
              </Button>
            </Box>
          </CardContent>
        </Card>
        {showWarningDialog && (
          <KillSwitchWarningDialog
            open={showWarningDialog}
            onClose={() => {
              setShowWarningDialog(false);
            }}
            onSubmit={handleSwitchSubmit}
            description={
              areActionsEnabled
                ? t('settings.killswitch.warnings.disableActions')
                : t('settings.killswitch.warnings.enableActions')
            }
          />
        )}
      </>
    </PermissionWrapper>
  );
};

export default KillSwitchTriggerField;
