import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { getGetPaymentMethodQueryKey, useCreatePaymentMethodLimits } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useSnackbar } from 'notistack';

import { usePermission } from '../../../../../../app/hooks';
import PaymentMethodLimitsForm from './PaymentMethodLimitsForm';

const AddPaymentMethodLimits = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { paymentMethodId }: any = useParams();
  const queryClient = useQueryClient();
  const canAddPaymentMethodLimits = usePermission(OnlineCasinoPermissions.createPaymentMethodLimits);

  const { mutate: addPaymentMethodLimits, isLoading: addIsLoading } = useCreatePaymentMethodLimits({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        navigate(`../payment-methods/${paymentMethodId}`);
        queryClient.invalidateQueries(getGetPaymentMethodQueryKey(paymentMethodId));
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  return (
    <PaymentMethodLimitsForm
      canSubmitForm={canAddPaymentMethodLimits}
      loading={addIsLoading}
      handleSubmitForm={data => addPaymentMethodLimits({ paymentMethodId, data })}
    />
  );
};

export default memo(AddPaymentMethodLimits);
