import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Link, Loading, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import {
  setInvisible,
  transformCurrency,
  transformObject,
  transformStatus,
  transformUnixDate,
} from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../Lookup/LookupPermissions';
import InCasinoCancelWithdrawalDialog from './InCasinoCancelWithdrawalDialog';

const InCasino = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const canReadInCasinoWithdrawals = usePermission(OnlineCasinoPermissions.getWithdrawalsInCasino);
  const canReadLoyaltyRank = usePermission(OnlineCasinoPermissions.getLoyaltyRankUsers);
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canCancelWithdrawal = usePermission(OnlineCasinoPermissions.cancelWithdrawalInCasino);
  const { data: withdrawals, fetching } = useAppSelector(state => state.withdrawalsInCasino);

  useEffect(() => {
    if (canReadInCasinoWithdrawals) dispatch(actionCreators.getWithdrawalsInCasino());
  }, [dispatch, canReadInCasinoWithdrawals]);

  const { headers, rows } = useMemo(() => {
    let headers: any = [];
    let rows: any = [];

    if (withdrawals?.length) {
      const keys = Object.keys(withdrawals[0]);
      headers = keys.map(key => {
        switch (key) {
          case 'flagData':
            return transformObject(key, t);
          case 'requestedOn':
            return transformUnixDate(key, t);
          case 'amountOfWithdrawal':
          case 'netDeposit':
          case 'balanceAfterWithdrawal':
            return transformCurrency(key, t);
          case 'status':
          case 'identityVerification':
          case 'domicileVerification':
          case 'bankAccountVerification':
            return transformStatus(key, t);
          case 'userId':
          case 'withdrawalId':
            return setInvisible(key);
          default:
            return { Header: t(key), accessor: key };
        }
      });

      headers.push({ id: 'cancel', accessor: 'cancel', disableFilters: true, disableSortBy: true });

      rows = withdrawals.map(item => {
        return {
          ...item,
          userLoyalty: canReadLoyaltyRank ? (
            <Link to={`../../loyalty/ranks/${item.userLoyalty}`}>{item.userLoyalty}</Link>
          ) : (
            <span>{item.userLoyalty}</span>
          ),
          username: canLookupUser ? (
            <Link to={`../../users/${item.userId}/details`}>{item.username}</Link>
          ) : (
            <span>{item.username}</span>
          ),
          cancel: canCancelWithdrawal ? <InCasinoCancelWithdrawalDialog withdrawal={item} /> : undefined,
        };
      });
    }

    return { headers, rows };
  }, [t, withdrawals, canCancelWithdrawal, canLookupUser, canReadLoyaltyRank]);

  if (fetching) return <Loading center />;
  if (!canReadInCasinoWithdrawals || !rows.length) return t('noData');

  return (
    <Card>
      <CardBody>
        <ReactTableGI columns={headers} data={rows} sortable filterable pagination />
      </CardBody>
    </Card>
  );
};

export default InCasino;
