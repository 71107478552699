import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardBody } from '@greenisland/components';
import { ComplaintTicketSchema, EComplaintResponseTypeSchema, EComplaintStatusSchema } from '@greenisland/stores';
import { Box, Button, Card, CardHeader, Stack, Typography } from '@mui/material';

import CloseComplaintDialog from './CloseComplaintDialog';
import ComplaintStatusField, { getComplaintStatusColor } from './ComplaintsStatusField';
import ComplaintSummaryStats from './ComplaintSummaryStats';
import EditComplaintDialog from './EditComplaintDialog';

const ComplaintSummary = ({ complaint }: { complaint: ComplaintTicketSchema }) => {
  const { t } = useTranslation();
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const complaintDescription = useMemo(
    () => complaint.complaintEntries.filter(c => c.responseType === EComplaintResponseTypeSchema.Initial)[0].content,
    [complaint?.complaintEntries]
  );

  const complaintReasonDescription = useMemo(() => {
    const c = complaint.complaintEntries.filter(c => c.responseType === EComplaintResponseTypeSchema.Closure);
    if (c.length > 0) {
      return c[0].content;
    }
    return undefined;
  }, [complaint?.complaintEntries]);

  const status = useMemo(() => {
    if (complaint.status === EComplaintStatusSchema.Closed && complaint.closureReason) {
      return <ComplaintStatusField value={complaint.closureReason} />;
    }
    return <ComplaintStatusField value={complaint.status} />;
  }, [complaint]);

  return (
    <>
      <Card>
        <CardHeader
          action={
            complaint.status !== EComplaintStatusSchema.Closed ? (
              <Stack spacing={1} direction="row">
                <Button color="secondary" variant="outlined" size="small" onClick={() => setIsEditDialogOpen(true)}>
                  {t('tasks.complaints.edit')}
                </Button>
                <Button color="primary" variant="contained" size="small" onClick={() => setIsCloseDialogOpen(true)}>
                  {t('tasks.complaints.closeComplaint')}
                </Button>
              </Stack>
            ) : undefined
          }
          title={complaint.title}
          subheader={
            <Box fontWeight={500} textTransform="uppercase">
              {status}
            </Box>
          }
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: ['flex-start', 'center'],
            gap: [2, 0],
            '& .MuiCardHeader-title': {
              wordBreak: 'break-word',
            },
          }}
        />
        <CardBody padding>
          <Stack spacing={2}>
            <ComplaintSummaryStats complaint={complaint} />
            {complaint.status === EComplaintStatusSchema.Closed && complaint.closureReason ? (
              <Box
                component="div"
                borderRadius={1}
                padding={2}
                bgcolor={`${getComplaintStatusColor(complaint.closureReason)}1a`}
                width="100%"
                color={getComplaintStatusColor(complaint.closureReason)}
              >
                <Typography fontWeight={600}>{t('tasks.complaints.closureReason')}</Typography>
                <Typography sx={{ wordBreak: 'break-word' }}>{complaintReasonDescription}</Typography>
              </Box>
            ) : null}
            <Typography sx={{ wordBreak: 'break-word' }}>{complaintDescription}</Typography>
          </Stack>
        </CardBody>
      </Card>
      {isCloseDialogOpen ? (
        <CloseComplaintDialog
          complaintId={complaint.id}
          complaintTitle={complaint.title}
          setOpen={setIsCloseDialogOpen}
          open={isCloseDialogOpen}
        />
      ) : null}
      {isEditDialogOpen ? (
        <EditComplaintDialog
          complaintId={complaint.id}
          complaintTitle={complaint.title}
          complaintType={complaint.type}
          complaintUserId={complaint.userId}
          setOpen={setIsEditDialogOpen}
          open={isEditDialogOpen}
        />
      ) : null}
    </>
  );
};

export default ComplaintSummary;
