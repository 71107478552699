import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, CheckData, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getUnixTime, startOfDay } from 'date-fns';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { datePickersErrorMessages } from '../../../../../../app/helpers/errorMessages';
import {
  transformDecimal,
  transformDefault,
  transformUnixDate,
} from '../../../../../../app/helpers/transformFunctions';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 1fr)',
      gridGap: 16,
      marginBottom: 16,
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  })
);

const VaultTransactions = () => (
  // TODO: add permissions
  <VaultTransactionsChild />
);

const VaultTransactionsChild = () => {
  const classes = styles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const getVaultTransactions = async (startDate?: number, endDate?: number) =>
    dispatch(actionCreators.getVaultTransactions(userId as string, startDate, endDate));
  const { data: vaultTransactions, fetching } = useAppSelector(state => state.userVaultTransactions);

  const mutatedData = () => {
    if (!vaultTransactions || vaultTransactions.length === 0) return undefined;
    const keys = Object.keys(vaultTransactions[0]);

    const headers: any = keys.map(key => {
      switch (key) {
        case 'timestamp':
          return transformUnixDate(key, t, true);
        case 'amount':
          return transformDecimal(key, t, false);
        default:
          return transformDefault(key, t, true);
      }
    });

    return { headers, rows: vaultTransactions };
  };

  return (
    <div className={classes.container}>
      <DateRangePicker
        unixStartDate={Number(searchParams.get('startdate')) * 1000}
        unixEndDate={Number(searchParams.get('enddate')) * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
          getVaultTransactions(startDate, endDate);
        }}
      />
      <Card>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} pagination sortable />}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(VaultTransactions);
