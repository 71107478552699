import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EGameLibraryModelSchema,
  GameCategorySchema,
  GameProviderSchema,
  GameSchema,
  GameStudioSchema,
  GameThemeSchema,
  useGameLibrarySync,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Sync } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import ConfirmDialog from 'src/app/components/ConfirmDialog';
import { usePermission } from 'src/app/hooks';

interface SyncGameLibraryButtonProps {
  gameLibEntity?: GameStudioSchema | GameSchema | GameThemeSchema | GameCategorySchema | GameProviderSchema;
  gameLibModel: EGameLibraryModelSchema;
  disabled?: boolean;
  invalidatedQueryKey?: string[];
  onClose?: () => void;
}

const SyncGameLibraryButton: React.FC<SyncGameLibraryButtonProps> = ({
  gameLibEntity,
  gameLibModel,
  disabled,
  invalidatedQueryKey,
  onClose,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const canSync = usePermission(OnlineCasinoPermissions.gameLibrarySync);
  const { mutate, isLoading } = useGameLibrarySync({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('editGames.syncGameLibrarySuccess'), { variant: 'success' });
        queryClient.invalidateQueries(invalidatedQueryKey);
      },
      onError: async () => enqueueSnackbar(t('editGames.syncGameLibraryError'), { variant: 'error' }),
    },
  });

  const gameLibEntityId =
    (gameLibEntity as GameCategorySchema)?.id ??
    (gameLibEntity as GameThemeSchema)?.gameThemeId ??
    (gameLibEntity as GameProviderSchema)?.providerId ??
    (gameLibEntity as GameStudioSchema)?.gameStudioId ??
    (gameLibEntity as GameSchema)?.gameId;

  const [isDialogOpen, setDialogOpen] = useState(false);

  const syncGameLibrary = useCallback(() => {
    mutate({ data: { model: gameLibModel, PKey: Number(gameLibEntityId) } });
    setDialogOpen(false);
    onClose?.();
  }, [mutate, gameLibModel, gameLibEntityId, onClose]);

  return (
    <>
      {canSync && gameLibEntity?.gameLibId != null && (
        <LoadingButton
          loading={isLoading}
          disabled={disabled ?? false}
          color={gameLibEntity?.conflictGamelib ? 'error' : 'primary'}
          variant="outlined"
          startIcon={<Sync />}
          onClick={() => setDialogOpen(true)}
        >
          {t('editGames.syncGameLibrary.buttonTitle')}
        </LoadingButton>
      )}
      <ConfirmDialog
        title={t('editGames.syncGameLibrary.confirmationTitle')}
        content={`${t('editGames.syncGameLibrary.confirmationDescription')} ${
          gameLibEntity?.conflictGamelib ? t('conflict.gameLibId', { gameLibId: gameLibEntity.gameLibId }) : ''
        }`}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
        onConfirm={syncGameLibrary}
      />
    </>
  );
};

export default SyncGameLibraryButton;
