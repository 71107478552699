import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { EPromotionalCalendarBadRequestTypeSchema, getGetPromotionalCalendarsQueryKey } from '@greenisland/stores';
import { ERankSchema } from '@greenisland/stores/dist/api/schemas/eRankSchema';
import { useSnackbar } from 'notistack';

import { LoyaltyRankOptionsStatus, promotionalCalendarLoyaltyRankOptions } from '../../../constants';
import { PROMOTIONAL_CALENDAR_ERROR_REASONS } from '../../../constants/PromotionalCalendarErrorReasons';

const usePromotionalCalendarFormHandlers = (reset: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onSuccessHandler = useCallback(() => {
    queryClient.invalidateQueries(getGetPromotionalCalendarsQueryKey());
    enqueueSnackbar(t('success'), { variant: 'success' });
    navigate('../');
    reset();
  }, [queryClient, enqueueSnackbar, t, navigate, reset]);

  const onErrorHandler = useCallback(
    error => {
      if (error) {
        const errorReason = error?.type;
        const translationKey =
          PROMOTIONAL_CALENDAR_ERROR_REASONS[errorReason as EPromotionalCalendarBadRequestTypeSchema] ??
          'somethingWentWrong';
        enqueueSnackbar(t(translationKey), { variant: 'error' });
      }
    },
    [enqueueSnackbar, t]
  );

  return { onSuccessHandler, onErrorHandler };
};

const useGetLoyaltyRankOptions = (
  status: LoyaltyRankOptionsStatus,
  chosenMinimalLoyaltyRankRequirement?: ERankSchema
) => {
  return useMemo(() => {
    if (!chosenMinimalLoyaltyRankRequirement) {
      return promotionalCalendarLoyaltyRankOptions;
    }

    const chosenRankIndex = promotionalCalendarLoyaltyRankOptions?.findIndex(
      option => option?.value === chosenMinimalLoyaltyRankRequirement
    );

    switch (status) {
      case LoyaltyRankOptionsStatus.DISABLE_INACTIVE:
        return promotionalCalendarLoyaltyRankOptions.map((option, index) => ({
          ...option,
          disabled: index > chosenRankIndex,
        }));
      case LoyaltyRankOptionsStatus.HIDE_INACTIVE:
        return promotionalCalendarLoyaltyRankOptions.slice(chosenRankIndex);
      default:
        return promotionalCalendarLoyaltyRankOptions;
    }
  }, [chosenMinimalLoyaltyRankRequirement, status]);
};

export { usePromotionalCalendarFormHandlers, useGetLoyaltyRankOptions };
