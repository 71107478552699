import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckData, ReactTableGI } from '@greenisland/components';
import { AgentRoleSchema } from '@greenisland/stores';
import { Collapse, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'classnames';

import { setInvisible } from '../../../../../app/helpers/transformFunctions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    roleName: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    noMargin: {
      margin: 0,
    },
    margin: {
      margin: '0.8rem',
    },
    collapse: {
      borderTop: '1px solid rgba(0,0,0,0.1)',
    },
    greyBackground: {
      background: 'rgba(0,0,0,0.05) !important',
    },
    whiteBackground: {
      background: 'white',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginRight: '0.8rem',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    invisible: {
      display: 'none',
    },
    tableContainer: {
      // margin: '0.8rem',
      background: 'white',
    },
    noBold: {
      fontWeight: 'normal',
    },
  })
);

type NestedRolesProps = {
  agentRole: AgentRoleSchema;
  isSubRole?: boolean;
  level?: number;
};

const NestedRoles = ({ agentRole, isSubRole = false, level = 0 }: NestedRolesProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(!isSubRole);
  const mutatePermissions = () => {
    if (!agentRole.permissions || agentRole.permissions.length === 0) return undefined;
    const keys = Object.keys(agentRole.permissions[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'permissionId':
          return setInvisible(key);
        default:
          return {
            Header: t(key),
            accessor: key,
          };
      }
    });
    return {
      headers: headers,
      rows: agentRole.permissions,
    };
  };
  return (
    <>
      {isSubRole && (
        <span
          className={clsx(classes.roleName, classes.whiteBackground, {
            [classes.greyBackground]: level % 2,
          })}
          onClick={() => {
            if (isSubRole) setExpanded(expanded => !expanded);
          }}
        >
          <h4 className={clsx(classes.noBold, classes.margin)}>{agentRole.roleName}</h4>

          <FontAwesomeIcon
            icon={faAngleDown}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
              [classes.invisible]: !isSubRole,
            })}
          />
        </span>
      )}

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        className={clsx(classes.whiteBackground, {
          [classes.greyBackground]: level % 2,
          [classes.collapse]: isSubRole,
        })}
      >
        <CheckData
          fallbackText={t('noPermissions')}
          data={mutatePermissions()}
          checkProperties={['headers', 'rows']}
          render={({ data }) => (
            <>
              <h5 className={clsx(classes.margin, classes.noBold)}>{t('permissions')}</h5>
              <div className={classes.tableContainer}>
                <ReactTableGI columns={data.headers} data={data.rows} sortable />
              </div>
            </>
          )}
        />
        <CheckData
          shouldRenderFallback={false}
          data={agentRole.agentRoles}
          render={({ data }) => (
            <>
              <h5 className={clsx(classes.margin, classes.noBold)}>{t('roles')}</h5>
              {data.map(role => {
                return <NestedRoles key={role.roleId} isSubRole level={level + 1} agentRole={role} />;
              })}
            </>
          )}
        />
      </Collapse>
    </>
  );
};

export default memo(NestedRoles);
