import { ErrorSchema, LoyaltyUserBadRequestContentSchema } from '@greenisland/stores';
import { useSnackbar } from 'notistack';

import { useGetLoyaltyUserErrorString } from './useGetLoyaltyUserErrorString';

export const useLoyaltyUserErrorHandling = () => {
  const { enqueueSnackbar } = useSnackbar();
  const getErrorString = useGetLoyaltyUserErrorString();

  return (error: ErrorSchema | LoyaltyUserBadRequestContentSchema | null) => {
    const errorMessage = getErrorString(error);
    enqueueSnackbar(errorMessage, { variant: 'error' });
  };
};
