import React, { createRef, FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import TagsInput from 'react-tagsinput';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  DateTimePicker,
  EIcons,
  Inputfield,
} from '@greenisland/components';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { datePickersErrorMessages } from '../../../../../../../app/helpers/errorMessages';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'none',
    },
    margin: {
      marginBottom: '20px',
    },
    marginDense: {
      marginBottom: '10px',
    },
    image: {
      width: '100%',
      marginBottom: '40px',
    },
    //TagsInput
    tagInput: {
      marginBottom: '17px',
      display: 'inline-block',
      padding: '4px 6px',
      '& .react-tagsinput-tag a::before': {
        fontFamily: 'Material Icons',
        content: "'close'",
        padding: '0px 2px',
        color: '#fff',
        visibility: 'hidden',
        verticalAlign: 'middle',
      },
      '& .react-tagsinput-tag a': {
        cursor: 'pointer',
        position: 'absolute',
        backgroundColor: 'transparent',
      },
      '& .react-tagsinput-tag:hover a:before': {
        visibility: 'visible',
      },
      '& .react-tagsinput-tag': {
        cursor: 'pointer',
        margin: '5px 3px',
        position: 'relative',
        padding: '3px 10px',
        borderRadius: '12px',
        color: '#fff',
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.primary.main,
        transition: 'all 300ms ease 0s',
        /*-webkit-transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;*/
      },
      '& .react-tagsinput-tag:hover': {
        paddingRight: '22px',
      },
      '& input': {
        width: 'auto',
        verticalAlign: 'middle',
        background: 'transparent',
        border: 0,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        outline: 'none',
        padding: '3px',
      },
      '& input::placeholder': {
        color: '#AAAAAA',
      },
      '& > span:first-child': {
        verticalAlign: 'middle',
      },
    },
    inputLabel: {
      paddingTop: '17px',
      fontSize: '14px',
      color: '#AAAAAA',
    },
  })
);

interface State {
  [name: string]: any;
}

const CasinoRacesDetails: FunctionComponent<any> = () => {
  const classes = styles();
  const t = useTranslation().t;

  let { raceId }: any = useParams();
  if (raceId !== 'add') {
    raceId = parseInt(raceId, 10);
  }

  const [values, setValues] = useState<State>({});
  const [checked, setChecked] = useState({ displayToPublic: false });
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(new Date());

  const [contributingGames, setContributingGames] = useState(['Pizza', 'Pasta', 'Parmesan']);
  const [excludingGames, setExcludingGames] = useState<Array<string>>([]);
  const [contributingProviders, setContributingProviders] = useState<Array<string>>([]);

  const handleContributingGames = (tags: string[]) => {
    setContributingGames(tags);
  };

  const handleExcludingGames = (tags: string[]) => {
    setExcludingGames(tags);
  };

  const handleContributingProviders = (tags: string[]) => {
    setContributingProviders(tags);
  };

  //TODO https://github.com/mui-org/material-ui-pickers/issues/1861

  function handleStartDateChange(date: Date | null | unknown) {
    setSelectedStartDate(date as Date);
  }

  function handleEndDateChange(date: Date | null | unknown) {
    setSelectedEndDate(date as Date);
  }

  const handleChange = (name: keyof State) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [name]: event.currentTarget.value });
  };

  const handleToggle = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ ...checked, [name]: event.target.checked });
  };

  const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  //createRef for input elements
  const textInput = createRef<HTMLInputElement>();
  const textInput2 = createRef<HTMLInputElement>();
  const textInput3 = createRef<HTMLInputElement>();

  //handleClick for buttons that trigger focus on input element
  function handleContributingGamesClick() {
    if (textInput.current) {
      textInput.current.focus();
    }
  }

  function handleExcludingGamesClick() {
    if (textInput2.current) {
      textInput2.current.focus();
    }
  }

  function handleContributingProvidersClick() {
    if (textInput3.current) {
      textInput3.current.focus();
    }
  }

  //Layouts for TagsInput components
  const contributingGamesLayout = (tagComponents: React.ReactElement[], inputComponent: React.ReactElement) => {
    return (
      <span>
        {tagComponents}
        <Button justIcon={EIcons.faPlus} size="small" color="primary" onClick={handleContributingGamesClick} />
        {inputComponent}
      </span>
    );
  };

  const excludingGamesLayout = (tagComponents: React.ReactElement[], inputComponent: React.ReactElement) => {
    return (
      <span>
        {tagComponents}
        <Button justIcon={EIcons.faPlus} size="small" color="primary" onClick={handleExcludingGamesClick} />
        {inputComponent}
      </span>
    );
  };

  const contributingProvidersLayout = (tagComponents: React.ReactElement[], inputComponent: React.ReactElement) => {
    return (
      <span>
        {tagComponents}
        <Button justIcon={EIcons.faPlus} size="small" color="primary" onClick={handleContributingProvidersClick} />
        {inputComponent}
      </span>
    );
  };

  const [images, setImages] = useState<State>({});

  const handleFileChange = (name: keyof State) => (event: React.ChangeEvent<any>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader> | React.ChangeEvent<any>) => {
        setImages({ ...images, [name]: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <>
      <Card>
        <CardHeader>{`${raceId === 'add' ? t(raceId) + 'CasinoRace' : t('editCasinoRace')}`}</CardHeader>
        <CardBody>
          <Checkbox
            label={t('displayToPublic')}
            color="primary"
            checked={checked.displayToPublic}
            onChange={handleToggle('displayToPublic')}
            value={'displayToPublic'}
          />
          <div>
            <div>
              <Inputfield label={t('raceName')} fullWidth onChange={handleChange('name')} value={values.name} />
            </div>
            <div>
              <Inputfield
                label={t('imageName')}
                fullWidth
                onChange={handleChange('imageName')}
                value={values.imageName}
              />
            </div>
          </div>
          <div>
            <div>
              <Inputfield
                label={t('descriptionKey')}
                helperText="Add in translations portal - if empty default is used"
                fullWidth
                onChange={handleChange('description')}
                value={values.description}
              />
            </div>
            <div>
              <Inputfield label={t('settings')} fullWidth onChange={handleChange('settings')} value={values.settings} />
            </div>
          </div>
          <div>
            <div>
              <Inputfield
                label={t('prizePool')}
                fullWidth
                onChange={handleChange('prizePool')}
                value={values.prizePool}
              />
            </div>
            <div>
              <Inputfield
                label={t('fixedPrice')}
                fullWidth
                type="number"
                onChange={handleChange('fixedPrice')}
                value={values.fixedPrice}
              />
            </div>
          </div>
          <div>
            <div>
              <DateTimePicker
                ampm={false}
                label="Start"
                disableFuture
                format="dd/MM/yyyy HH:mm"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                fullWidth
                customErrorMessages={datePickersErrorMessages(t)}
              />
            </div>
            <div>
              <DateTimePicker
                ampm={false}
                label="End"
                disableFuture
                format="dd/MM/yyyy HH:mm"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                fullWidth
                customErrorMessages={datePickersErrorMessages(t)}
              />
            </div>
          </div>
          {/* TODO: fix */}
          {/* <Inputlabel className={classes.inputLabel}>{t("contributingGames")}</Inputlabel> */}
          <TagsInput
            className={classes.tagInput}
            value={contributingGames}
            onChange={handleContributingGames}
            inputProps={{
              placeholder: `${t('addAGame')}`,
              ref: textInput,
            }}
            renderLayout={contributingGamesLayout}
          />
          {/* TODO: fix */}
          {/* <InputLabel className={classes.inputLabel}>{t("excludingGames")}</InputLabel> */}
          <TagsInput
            className={classes.tagInput}
            value={excludingGames}
            onChange={handleExcludingGames}
            inputProps={{
              placeholder: `${t('excludeAGame')}`,
              ref: textInput2,
            }}
            renderLayout={excludingGamesLayout}
          />
          {/* TODO: fix */}
          {/* <InputLabel className={classes.inputLabel}>{t("contributingProviders")}</InputLabel> */}
          <TagsInput
            className={classes.tagInput}
            value={contributingProviders}
            onChange={handleContributingProviders}
            inputProps={{
              placeholder: `${t('addAProvider')}`,
              ref: textInput3,
            }}
            renderLayout={contributingProvidersLayout}
          />
          <Button color="primary" onClick={handleSubmit}>
            {t('save')}
          </Button>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>{`${raceId === 'add' ? t(raceId) + 'Images' : t('editImages')}`}</CardHeader>
        <CardBody>
          <p>{t('thumbnail')} (480x280)</p>
          <input
            accept="image/*"
            className={classes.input}
            id="button-file-thumbnail"
            type="file"
            onChange={handleFileChange('thumbnail')}
          />
          <label htmlFor="button-file-thumbnail">
            <Button className={classes.margin} color="primary" variant="outlined">
              {t('uploadThumbnail')}
            </Button>
          </label>
          <div>
            <div>
              <img className={classes.image} src={images.thumbnail} alt="" />
            </div>
          </div>
          <p>{t('widgetBackground')} (1850x350)</p>
          <input
            accept="image/*"
            className={classes.input}
            id="button-file-background"
            type="file"
            onChange={handleFileChange('background')}
          />
          <label htmlFor="button-file-background">
            <Button className={classes.margin} color="primary" variant="outlined">
              {t('uploadBackground')}
            </Button>
          </label>
          <div>
            <div>
              <img className={classes.image} src={images.background} alt="" />
            </div>
          </div>
          <p>{t('winnerEmail')} (600x250)</p>
          <p>{t('EN')}</p>
          <input
            accept="image/*"
            className={classes.input}
            id="button-file-email-en"
            type="file"
            onChange={handleFileChange('winnerEmailEn')}
          />
          <label htmlFor="button-file-email-en">
            <Button className={classes.marginDense} color="primary" variant="outlined">
              {t('uploadWinnerEmailEn')}
            </Button>
          </label>
          <div>
            <div>
              <img className={classes.image} src={images.winnerEmailEn} alt="" />
            </div>
          </div>
          <p>{t('FR')}</p>
          <input
            accept="image/*"
            className={classes.input}
            id="button-file-email-fr"
            type="file"
            onChange={handleFileChange('winnerEmailFr')}
          />
          <label htmlFor="button-file-email-fr">
            <Button className={classes.marginDense} color="primary" variant="outlined">
              {t('uploadWinnerEmailFr')}
            </Button>
          </label>
          <div>
            <div>
              <img className={classes.image} src={images.winnerEmailFr} alt="" />
            </div>
          </div>
          <p>{t('NL')}</p>
          <input
            accept="image/*"
            className={classes.input}
            id="button-file-email-nl"
            type="file"
            onChange={handleFileChange('winnerEmailNl')}
          />
          <label htmlFor="button-file-email-nl">
            <Button className={classes.marginDense} color="primary" variant="outlined">
              {t('uploadWinnerEmailNl')}
            </Button>
          </label>
          <div>
            <div>
              <img className={classes.image} src={images.winnerEmailNl} alt="" />
            </div>
          </div>
          <Button color="primary" onClick={handleSubmit}>
            {t('save')}
          </Button>
        </CardBody>
      </Card>
    </>
  );
};

export default memo(CasinoRacesDetails);
