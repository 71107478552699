import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import { Card, CardBody, CardHeader, EIcons, Link, ReactTableGI, Tooltip } from '@greenisland/components';
import { CampaignTemplate } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';

import { usePermission } from '../../../../../../app/hooks';

interface Props {
  campaignId: string;
  templates: Array<CampaignTemplate>;
}

const Templates = ({ campaignId, templates }: Props) => {
  const { t } = useTranslation();
  const canEditTemplate = usePermission(OnlineCasinoPermissions.updateCampaignTemplate);
  const canAddTemplate = usePermission([
    OnlineCasinoPermissions.addCampaignTemplate,
    OnlineCasinoPermissions.getTemplateTypes,
  ]);

  const headers: Column[] = [
    { Header: String(t('type')), accessor: 'type' },
    { id: 'edit', accessor: 'edit', disableSortBy: true } as Column,
  ];

  const rows = useMemo(() => {
    return templates.map(template => ({
      ...template,
      edit: canEditTemplate ? (
        <Tooltip content={t('editTemplate')}>
          <Link
            button
            buttonSize="small"
            buttonVariant="transparent"
            icon={EIcons.faPen}
            to={`${campaignId}/template/${template.type}`}
          />
        </Tooltip>
      ) : undefined,
    }));
  }, [templates]);

  return (
    <Card>
      <CardHeader
        linkChildren={
          canAddTemplate ? (
            <Link
              to={{ pathname: `${campaignId}/template/add`, state: { name: 'Campaign Template' } }}
              button
              icon={EIcons.faPlus}
            >
              {t('addTemplate')}
            </Link>
          ) : undefined
        }
      >
        {t('templates')}
      </CardHeader>
      <CardBody>{rows.length ? <ReactTableGI columns={headers} data={rows} sortable /> : t('noData')}</CardBody>
    </Card>
  );
};

export default Templates;
