import { useTranslation } from 'react-i18next';
import { useGetDepositsAndWithdrawals } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Card, CardContent, CardHeader } from '@mui/material';

import { DataGridContainer, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

import { useGetRows, usePermission } from '../../../../../app/hooks';
import { headers } from './constants';

const PromotionOverview = () => {
  const { t } = useTranslation();
  const canReadDepositsAndWithdrawals = usePermission(OnlineCasinoPermissions.getDepositsAndWithdrawals);
  const {
    data: depositsAndWithdrawals,
    isLoading,
    isError,
  } = useGetDepositsAndWithdrawals({ query: { enabled: canReadDepositsAndWithdrawals } });
  const promotionOverviewRows = useGetRows(depositsAndWithdrawals?.promotionOverview);

  return (
    <Card>
      <CardHeader title={t('promotionOverview')} />
      <CardContent>
        <PermissionWrapper
          errorMessage={t('promotionOverviewCard.fetchError')}
          isError={isError}
          isLoading={false}
          permission={OnlineCasinoPermissions.getDepositsAndWithdrawals}
        >
          <DataGridContainer>
            <StyledDataGrid
              sx={{ '.MuiDataGrid-columnHeaders': { display: 'none', border: 'none' } }}
              headerHeight={0}
              hideFooter
              autoHeight
              density="compact"
              columns={headers}
              loading={isLoading}
              rows={promotionOverviewRows}
            />
          </DataGridContainer>
        </PermissionWrapper>
      </CardContent>
    </Card>
  );
};

export default PromotionOverview;
