import { Outlet } from 'react-router';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import ContentFilter from 'src/pages/OnlineCasino/Content/ContentFilter/ContentFilter';
import EditGame from 'src/pages/OnlineCasino/Content/Games/Components/Forms/EditGame';
import EditGameConfigurations from 'src/pages/OnlineCasino/Content/Games/Components/Forms/EditGameConfigurations';
import EditGameLobby from 'src/pages/OnlineCasino/Content/Games/Lobbies/components/EditGameLobby';
import Lobbies from 'src/pages/OnlineCasino/Content/Games/Lobbies/Lobbies';
import MediaGallery from 'src/pages/OnlineCasino/Content/MediaGallery';

import VouchersBalanceTransactionsHistory from '../../pages/OnlineCasino/Content/Becharge/VouchersBalanceTransactionsHistory/VouchersBalanceTransactionsHistory';
import VouchersProducts from '../../pages/OnlineCasino/Content/Becharge/VouchersProducts/VouchersProducts';
import Categories from '../../pages/OnlineCasino/Content/Games/Categories/Categories';
import ProviderDetails from '../../pages/OnlineCasino/Content/Games/Components/ProviderDetails';
import Games from '../../pages/OnlineCasino/Content/Games/Games/Games';
import GamesList from '../../pages/OnlineCasino/Content/Games/Providers/GamesList';
import Providers from '../../pages/OnlineCasino/Content/Games/Providers/Providers';
import Studios from '../../pages/OnlineCasino/Content/Games/Studios/Studios';
import Themes from '../../pages/OnlineCasino/Content/Games/Themes/Themes';
import { BaseRoute } from '../RouteInterfaces';

const gamesViews: Array<BaseRoute> = [
  {
    name: 'Games',
    path: 'games',
    element: Games,
    mini: 'G',
    permissions: OnlineCasinoPermissions.getGamesByQueryFilters,
    featureFlag: 'isGamesEnabled',
  },
  {
    name: 'Providers',
    path: 'providers',
    element: Providers,
    mini: 'P',
    permissions: OnlineCasinoPermissions.getGameProviders,
    featureFlag: 'isGamesEnabled',
  },
  {
    name: 'Edit provider',
    path: 'providers/:providerId',
    element: ProviderDetails,
    hiddenLink: true,
    permissions: [OnlineCasinoPermissions.getGameProvider, OnlineCasinoPermissions.updateGameProvider],
    featureFlag: 'isGamesEnabled',
  },
  {
    path: 'providers/:providerId/games',
    name: 'Provider games',
    element: GamesList,
    hiddenLink: true,
    permissions: OnlineCasinoPermissions.getGamesByQueryFilters,
    featureFlag: 'isGamesEnabled',
  },
  {
    path: 'providers/:providerId/games/:gameId',
    name: 'Games Details',
    element: EditGame,
    hiddenLink: true,
    permissions: [OnlineCasinoPermissions.getGameCategories, OnlineCasinoPermissions.getGameV2],
    featureFlag: 'isGamesEnabled',
  },
  {
    path: 'providers/:providerId/games/:gameId/configurations',
    name: 'Game configuration',
    element: EditGameConfigurations,
    hiddenLink: true,
    permissions: [OnlineCasinoPermissions.getGameCategories, OnlineCasinoPermissions.getGameV2],
    featureFlag: 'isGamesEnabled',
  },
  {
    name: 'Themes',
    path: 'themes',
    element: Themes,
    mini: 'T',
    permissions: OnlineCasinoPermissions.getGameThemes,
    featureFlag: 'isGamesEnabled',
  },
  {
    name: 'Categories',
    path: 'categories',
    element: Categories,
    mini: 'C',
    permissions: OnlineCasinoPermissions.getGameCategories,
    featureFlag: 'isGamesEnabled',
  },
  {
    name: 'Studios',
    path: 'studios',
    element: Studios,
    mini: 'S',
    permissions: OnlineCasinoPermissions.getGameStudios,
    featureFlag: 'isGamesEnabled',
  },
  {
    name: 'Lobbies',
    path: 'lobbies',
    element: Lobbies,
    mini: 'L',
    permissions: OnlineCasinoPermissions.getGameLobbies,
    featureFlag: 'isGamesEnabled',
  },
  {
    path: 'lobbies/:lobbyId',
    name: "Lobby's Details",
    element: EditGameLobby,
    hiddenLink: true,
    permissions: [
      OnlineCasinoPermissions.getGameLobby,
      OnlineCasinoPermissions.addGameLobby,
      OnlineCasinoPermissions.updateGameLobby,
    ],
    featureFlag: 'isGamesEnabled',
  },
];

export const contentRoutes: Array<BaseRoute> = [
  {
    name: 'Content',
    path: 'content',
    collapse: true,
    element: Outlet,
    icon: faClone,
    permissions: [
      OnlineCasinoPermissions.getGameProviders,
      OnlineCasinoPermissions.getGameCategories,
      OnlineCasinoPermissions.getGameThemes,
      OnlineCasinoPermissions.getGameStudios,
      OnlineCasinoPermissions.getAllMediaGalleryEntriesOfType,
      OnlineCasinoPermissions.uploadMediaGalleryEntry,
      OnlineCasinoPermissions.deleteMediaGalleryEntry,
    ],
    children: [
      {
        name: 'Games',
        path: 'games',
        collapse: true,
        element: Outlet,
        permissions: [
          OnlineCasinoPermissions.getGameProviders,
          OnlineCasinoPermissions.getGameCategories,
          OnlineCasinoPermissions.getGameThemes,
          OnlineCasinoPermissions.getGameStudios,
        ],
        children: gamesViews,
        featureFlag: 'isGamesEnabled',
      },
      {
        name: 'Becharge',
        path: 'becharge',
        collapse: true,
        element: Outlet,
        permissions: [
          OnlineCasinoPermissions.getBechargeBalance,
          OnlineCasinoPermissions.getBechargeProducts,
          OnlineCasinoPermissions.getBechargeOrderDetails,
          OnlineCasinoPermissions.getTransactionHistory,
        ],
        children: [
          {
            path: 'voucher-products',
            name: 'Voucher Products',
            element: VouchersProducts,
            mini: 'VP',
            permissions: [OnlineCasinoPermissions.getBechargeProducts],
          },
          {
            path: 'voucher-transactions',
            name: 'Voucher Transactions',
            element: VouchersBalanceTransactionsHistory,
            mini: 'VT',
            permissions: [
              OnlineCasinoPermissions.getBechargeBalance,
              OnlineCasinoPermissions.getTransactionHistory,
              OnlineCasinoPermissions.getBechargeOrderDetails,
            ],
          },
        ],
      },
      {
        name: 'Media Gallery',
        path: 'media-gallery',
        element: MediaGallery,
        mini: 'MG',
        permissions: [OnlineCasinoPermissions.getAllMediaGalleryEntriesOfType],
      },
      {
        name: 'Content Filter',
        path: 'content-filter',
        element: ContentFilter,
        mini: 'CF',
        permissions: [OnlineCasinoPermissions.getContentFilters],
      },
    ],
  },
];

export default contentRoutes;
