import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getGetUserGeneralQueryKey, useEnableIsTestUser, UserGeneralSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

import useAccountData from '../../../../hooks/useAccountData';
import GenericField from './GenericField';

interface Props {
  user: UserGeneralSchema;
}

const TestUserField = ({ user }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: accountData, isLoading } = useAccountData(user.userId);
  const enableTestUserEdit = accountData && accountData.account.balance === 0;

  const updateMutation = useEnableIsTestUser({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: async () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size={20} />
      </Box>
    );
  }

  const handleMutation = () => updateMutation.mutate({ userId: user.userId });

  return (
    <GenericField
      reasonValue="test user"
      reasonLabel={t('lookup.verification.testUserConfirmLabel')}
      disabled={user.testUser || !enableTestUserEdit}
      value={user.testUser}
      tooltip={!enableTestUserEdit ? t('testUserBalanceShouldBe0') : t('testUser')}
      description={t('lookup.verification.enableTestUserDescription')}
      togglePermissions={OnlineCasinoPermissions.setUserStatus}
      onToggle={handleMutation}
    />
  );
};

export default TestUserField;
