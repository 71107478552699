import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, CheckData, Link, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Box } from '@mui/material';

import { getStatusColorClass, setInvisible } from '../../../../../app/helpers/transformFunctions';

const DomicileHistory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId = '' } = useParams();
  const { data: domicileHistory, fetching } = useAppSelector(state => state.userDomicileHistory);

  useEffect(() => {
    dispatch(actionCreators.getUserDomicileHistory(userId));
  }, [dispatch]);

  const mutatedData = () => {
    if (!domicileHistory || domicileHistory.length === 0) return undefined;
    const keys = Object.keys(domicileHistory[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'domicileId':
          return setInvisible(key);
        case 'verificationStatus':
          return {
            Header: t(key),
            accessor: key,
            Cell: ({ value }: any) => {
              return <Box sx={getStatusColorClass(value)}>{value}</Box>;
            },
          };
        case 'domicileDocument':
          return {
            id: key,
            accessor: key,
            Cell: (item: any) => {
              const { value } = item;
              return (
                <a href={value.name} download={value.name}>
                  {value.name}
                </a>
              );
            },
          };
        default:
          return { Header: t(key), accessor: key };
      }
    });

    return { headers, rows: domicileHistory };
  };

  return (
    <Card>
      <CardHeader>
        <div>
          <Link to={`verifications`}>{t('backToVerificationDetails')}</Link>
        </div>
      </CardHeader>
      <CardBody>
        <CheckData
          fallbackText={t('noData')}
          data={mutatedData()}
          isLoading={fetching}
          render={({ data }: any) => <ReactTableGI columns={data.headers} data={data.rows} />}
        />
      </CardBody>
    </Card>
  );
};

export default memo(DomicileHistory);
