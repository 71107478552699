import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AcurisIndividualProfileSanEntriesSchema } from '@greenisland/stores';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

interface Props {
  sanEntries: AcurisIndividualProfileSanEntriesSchema | undefined;
}

const KYCSANTable = ({ sanEntries }: Props) => {
  const { t } = useTranslation();

  const renderSanctionEntry = (entry: string, key: number) => {
    const sanData = JSON.parse(entry);

    return (
      <TableRow key={key}>
        <TableCell>{sanData.measures.join(', ')}</TableCell>
        <TableCell>
          {sanData.regime.body} - {sanData.regime.name}
        </TableCell>
        <TableCell>
          {sanData.events &&
            sanData.events.map((event: { type?: string; dateIso?: string; evidenceIds?: string[] }, index: number) => (
              <span key={index}>
                {event.type} on {event.dateIso} ({t('kyc.resource.evidenceId')}: {event.evidenceIds?.join(', ')})
                {index < sanData.events.length - 1 && <br />}
              </span>
            ))}
        </TableCell>
      </TableRow>
    );
  };

  const renderSanctionEntries = (entries: string[]) => {
    return (
      <TableContainer style={{ marginBottom: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('kyc.headers.measures')}</TableCell>
              <TableCell>{t('kyc.headers.regime')}</TableCell>
              <TableCell>{t('date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{entries.map((entry: string, index: number) => renderSanctionEntry(entry, index))} </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (!sanEntries || (sanEntries?.current?.length === 0 && sanEntries?.former?.length === 0)) {
    return null;
  }

  return (
    <>
      <Typography variant="h5">{t('kyc.resource.sanEntries')}</Typography>
      {sanEntries?.current && sanEntries?.current?.length > 0 && (
        <>
          <Typography>{t('kyc.resource.current')}</Typography>
          {renderSanctionEntries(sanEntries.current || [])}
        </>
      )}
      {sanEntries?.former && sanEntries?.former?.length > 0 && (
        <>
          <Typography>{t('kyc.resource.former')}</Typography>
          {renderSanctionEntries(sanEntries.former || [])}
        </>
      )}
    </>
  );
};

export default memo(KYCSANTable);
