import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EPGSIClassificationSchema, SelfTestSchema } from '@greenisland/stores';
import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { getDateFromUnix } from 'src/app/helpers/transformFunctions';

import PgsiResultField from '../PgsiResultField';

const PgsiSummaryStats = ({ test }: { test: SelfTestSchema }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const stats = useMemo(
    () => [
      {
        title: t('pgsi.passDate'),
        value: test.created ? getDateFromUnix(test.created) : '/',
      },
      {
        title: t('pgsi.totalScore'),
        value: test.totalScore,
      },
      {
        title: t('pgsi.reason'),
        value: test.reason,
      },
      {
        title: t('pgsi.classification'),
        value: <PgsiResultField value={test.PGSIClassification as EPGSIClassificationSchema} />,
      },
    ],
    [t, test]
  );

  return (
    <Box component="div" width="100%" mb={3} px={3} bgcolor="white">
      {!isMobile ? (
        <Stack
          spacing={5}
          direction="row"
          width="100%"
          justifyContent="space-between"
          divider={<Divider orientation="vertical" flexItem />}
        >
          {stats.map((s, i) => (
            <Box key={i} display="flex" flexDirection="column">
              <Typography fontWeight={600} variant="body2" color="GrayText">
                {s.title}
              </Typography>
              <Typography fontWeight={600} variant="body2">
                {s.value}
              </Typography>
            </Box>
          ))}
        </Stack>
      ) : (
        <Grid container spacing={2}>
          {stats.map((s, i) => (
            <>
              <Grid xs={4} key={`${i}-title`}>
                <Typography fontWeight={600} variant="body2" color="GrayText">
                  {s.title}
                </Typography>
              </Grid>
              <Grid xs={8} key={`${i}-value`}>
                <Typography fontWeight={600} variant="body2">
                  {s.value}
                </Typography>
              </Grid>
            </>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default PgsiSummaryStats;
