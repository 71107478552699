import { Suspense, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Loading } from '@greenisland/components';
import { API, ApiContext, store } from '@greenisland/stores';
import { CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';

import AlertContainer from '@greenisland-common/components/molecules/AlertContainer/AlertContainer';

import TokenExpiredContainer from './app/components/TokenExpiredContainer/TokenExpiredContainer';
import { setupI18n } from './app/i18n';
import { GIGlobalCss } from './app/theme';
import LoginStatusCheck from './routes/components/LoginStatusCheck';
import config from './config';
LicenseInfo.setLicenseKey(
  '9cefa37ead05201a0212499b96b6dedcTz03MTYzMixFPTE3MjIxNTUzNzUwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false },
    mutations: { retry: false },
  },
});

function App() {
  const [configuredApi, setConfiguredApi] = useState(false);

  useEffect(() => {
    setupI18n();
    API.setConfig = config;
    setConfiguredApi(true);
  }, []);

  return configuredApi ? (
    <Provider store={store}>
      <ApiContext.Provider value={config.cognito.API_ENDPOINT}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <LoginStatusCheck />
            <GIGlobalCss />
            <CssBaseline />
            <Suspense fallback={null}>
              <TokenExpiredContainer />
            </Suspense>
            <AlertContainer />
          </BrowserRouter>
          {process.env.NODE_ENV !== 'production' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
        </QueryClientProvider>
      </ApiContext.Provider>
    </Provider>
  ) : (
    <Loading center />
  );
}

export default App;
