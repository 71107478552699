import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CheckData, EIcons, ReactTableGI, Tooltip } from '@greenisland/components';
import { actionCreators, BackofficeApi, Base64File, handleFailureFromApp } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import { downloadBase64FileDeprecated as downloadBase64File } from '@greenisland-common/helpers';

import {
  setInvisible,
  transformDecimal,
  transformDefault,
  transformStatus,
  transformUnixDate,
} from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

type DownloadButtonProps = {
  withdrawalId: string;
};

const DownloadButton = ({ withdrawalId }: DownloadButtonProps) => {
  const [file, setFile] = useState<{ data?: Base64File; fetching: boolean }>({
    fetching: false,
    data: undefined,
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleClick = async () => {
    setFile(previousState => ({ ...previousState, fetching: true }));
    try {
      const { data } = await BackofficeApi.calls.getWithdrawalsExportFile(withdrawalId);
      downloadBase64File(data);
      setFile({ data: data, fetching: false });
    } catch (e) {
      setFile(previousState => ({ ...previousState, fetching: false }));
      handleFailureFromApp(e, dispatch);
    }
  };

  return (
    <Tooltip content={t('download')}>
      <Button
        justIcon={EIcons.faDownload}
        loadingState={file.fetching}
        onClick={handleClick}
        disabled={file.fetching}
        rounded
        variant="transparent"
        size="medium"
        color="secondary"
      />
    </Tooltip>
  );
};

const WithdrawalsExports = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const canDownloadExport = usePermission(OnlineCasinoPermissions.processWithdrawalsExport);
  const withdrawalsExports = useAppSelector(state => state.withdrawalsExports.data);
  const loading = useAppSelector(state => state.withdrawalsExports.fetching);
  const processWithdrawalsExport = (exportId: string) => dispatch(actionCreators.processWithdrawalsExport(exportId));

  useEffect(() => {
    dispatch(actionCreators.getWithdrawalsExports());
  }, [dispatch]);

  const mutatedData = () => {
    if (!withdrawalsExports || withdrawalsExports.length === 0) return undefined;
    const keys = Object.keys(withdrawalsExports[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'createdOn':
          return transformUnixDate(key, t);
        case 'nrOfTransactions':
          return transformDecimal(key, t);
        case 'status':
          return transformStatus(key, t);
        case 'exportId':
          return setInvisible(key);
        default:
          return transformDefault(key, t);
      }
    });

    headers.push({ id: t('process'), accessor: 'process', disableFilters: true, disableSortBy: true });
    headers.push({ id: t('download'), accessor: 'download', disableFilters: true, disableSortBy: true });

    return {
      headers,
      rows: withdrawalsExports.map(item => ({
        ...item,
        process:
          item.status === 'New' ? (
            <Button size="small" onClick={() => processWithdrawalsExport(item.exportId)}>
              {t('process')}
            </Button>
          ) : null,
        download: canDownloadExport ? <DownloadButton withdrawalId={item.exportId} /> : null,
      })),
    };
  };

  return (
    <CheckData
      fallbackText={t('noData')}
      data={mutatedData()}
      isLoading={loading}
      checkProperties={['headers', 'rows']}
      render={({ data }) => (
        <Card>
          <CardBody>
            <ReactTableGI columns={data.headers} data={data.rows} sortable filterable pagination />
          </CardBody>
        </Card>
      )}
    />
  );
};

export default memo(WithdrawalsExports);
