import React from 'react';
import {
  EComplaintClosureReasonResultSchema,
  EComplaintClosureReasonSchema,
  EComplaintStatusSchema,
} from '@greenisland/stores';
import { Box } from '@mui/material';

interface Props {
  value: EComplaintStatusSchema | EComplaintClosureReasonSchema | EComplaintClosureReasonResultSchema;
}

export function getComplaintStatusColor(
  value: EComplaintStatusSchema | EComplaintClosureReasonSchema | EComplaintClosureReasonResultSchema
) {
  if (value === EComplaintStatusSchema.New || value === EComplaintClosureReasonSchema.CustumerRepaid) {
    return '#434343';
  } else if (
    value === EComplaintStatusSchema.AwaitingCustomer ||
    value === EComplaintClosureReasonSchema.QuestionAnswered
  ) {
    return '#DD7A1F';
  } else if (value === EComplaintStatusSchema.AwaitingBackoffice) {
    return '#EA262A';
  } else if (value === EComplaintStatusSchema.Closed || value === EComplaintClosureReasonSchema.ProblemResolved) {
    return '#549041';
  }
}

const ComplaintStatusField = ({ value }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        color: getComplaintStatusColor(value),
      }}
    >
      {value}
    </Box>
  );
};

export default ComplaintStatusField;
