import { memo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ApproveCancelPlayingBreakRequestSchema,
  EPlayingBreakBadRequestTypeSchema,
  getGetPlayingBreakQueryKey,
  useCancelPlayingBreakV2,
} from '@greenisland/stores';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTenantContext } from 'src/app/context/TenantContext';

import { PLAYING_BREAK_ERROR_REASONS } from '../Constants/PlayingBreakErrorReasons';

export type CancelType = 'normal' | 'force';

interface AwardDialogProps {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  userId: number;
}

const CancelPlayingBreakDialog = memo(({ openDialog, setOpenDialog, userId }: AwardDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { playingBreakConfigurations } = useTenantContext();
  const methods = useForm<ApproveCancelPlayingBreakRequestSchema>({
    defaultValues: {
      cooldownDays: playingBreakConfigurations.timeoutDays,
      decisionReason: '',
    },
  });
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const cooldownValues = [
    {
      value: 0,
      label: t('playingbreak.cancelImmediately'),
    },
    ...(playingBreakConfigurations.timeoutDays !== 0
      ? [
          {
            value: playingBreakConfigurations.timeoutDays,
            label: t('playingbreak.cancelInDays', { days: playingBreakConfigurations.timeoutDays }),
          },
        ]
      : []),
  ];

  const { mutate: cancelPlayingBreakMutation, isLoading } = useCancelPlayingBreakV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetPlayingBreakQueryKey(userId));
        setOpenDialog(false);
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: EPlayingBreakBadRequestTypeSchema = error.type;
            const translationKey = errorReason ? PLAYING_BREAK_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const onSubmit = (data: ApproveCancelPlayingBreakRequestSchema) => {
    cancelPlayingBreakMutation({ data, userId });
  };

  return (
    <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>{capitalize(t('playingbreak.cancel'))}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack direction="column" gap={1}>
              <Controller
                name="cooldownDays"
                rules={{ required: t('fieldIsRequired') }}
                control={control}
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextField
                    select
                    fullWidth
                    label={t('playingbreak.cooldownDays')}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors['cooldownDays'])}
                    helperText={errors['cooldownDays']?.message}
                  >
                    {cooldownValues.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {t(label)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <TextField
                name="decisionReason"
                label={t('reason')}
                error={Boolean(errors['decisionReason'])}
                helperText={errors['decisionReason']?.message}
                inputRef={register({ required: t('fieldIsRequired') })}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
              {t('cancel')}
            </Button>
            <LoadingButton variant="contained" color="error" type="submit" disabled={isLoading} loading={isLoading}>
              {t('proceed')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
});

export default CancelPlayingBreakDialog;
