import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EGameLibraryModelSchema,
  ELanguagesSchema,
  EMediaGalleryEntryTypeSchema,
  GameCategorySchema,
  getGetGameCategoriesQueryKey,
  useAddGameCategory,
  useUpdateGameCategory,
} from '@greenisland/stores';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import CheckboxField from '@greenisland-common/components/molecules/CheckboxField';
import Input from '@greenisland-common/components/molecules/Input';

import SyncGameLibraryButton from '../../Components/Forms/SyncGameLibraryButton';
import TranslationsDictionaryFields, {
  TotalFieldArrayDataItem,
} from '../../Components/Forms/TranslationsDictionaryFields';
import processFieldArray from '../../Components/utils/processFieldArray';

interface ExtendedGameCategorySchema extends GameCategorySchema {
  totalFieldArray?: TotalFieldArrayDataItem[];
}

interface Props {
  open: boolean;

  onClose: () => void;
  category: GameCategorySchema | undefined;
}

const DEFAULT_CATEGORY_FIELDS: GameCategorySchema = {
  id: '',
  code: '',
  codeName: '',
  order: 0,
  enabledInLobby: false,
  isLive: false,
  imageUrl: '',
  description: [],
  codeNameTranslations: [],
  imageUrlTranslations: [],
};

const GameCategoryDialog = ({ open, onClose, category }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<GameCategorySchema>({
    defaultValues: category ? category : DEFAULT_CATEGORY_FIELDS,
    mode: 'onChange',
  });
  const { handleSubmit, setValue, register, errors, watch, control, reset } = methods;
  const watchName = watch('totalFieldArray') as TotalFieldArrayDataItem[];

  const addMutation = useAddGameCategory({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('content.games.categories.addSuccess'), {
          variant: 'success',
        });
        queryClient.invalidateQueries(getGetGameCategoriesQueryKey());
        onClose();
      },
    },
  });

  const updateMutation = useUpdateGameCategory({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('content.games.categories.updateSuccess'), {
          variant: 'success',
        });
        queryClient.invalidateQueries(getGetGameCategoriesQueryKey());
        onClose();
      },
    },
  });

  const isEditMode = !!category?.id;

  useEffect(() => {
    if (watchName && watchName.length > 0) {
      const value =
        watchName.find(item => item.language === ELanguagesSchema.EN)?.codeNameContent ||
        watchName[0].codeNameContent ||
        '';

      if (!isEditMode) {
        setValue('code', value.trim().replace(/\s/g, '').toUpperCase());
      }
      setValue('codeName', value);
    }
  }, [setValue, watchName, isEditMode]);

  useEffect(() => {
    reset(category ? category : DEFAULT_CATEGORY_FIELDS);
  }, [reset, category]);

  const onSubmit = handleSubmit((data: ExtendedGameCategorySchema) => {
    if (data.totalFieldArray) {
      const { descriptions, nameTranslations, imageUrlTranslations } = processFieldArray(data.totalFieldArray);
      data.description = descriptions;
      data.codeNameTranslations = nameTranslations;
      data.imageUrlTranslations = imageUrlTranslations;
      delete data.totalFieldArray;
    }
    if (category?.id) {
      updateMutation.mutate({ gameCategoryId: category.id, data });
    } else {
      addMutation.mutate({ data });
    }
    onClose();
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={onSubmit}>
          <DialogTitle>
            {isEditMode ? t('content.games.categories.edit') : t('content.games.categories.add')}
            {category?.conflictGamelib && (
              <Typography color="error">{t('conflict.gameLibId', { gameLibId: category.gameLibId })}</Typography>
            )}
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <Box>
                <SyncGameLibraryButton
                  gameLibEntity={category}
                  gameLibModel={EGameLibraryModelSchema.GameCategory}
                  invalidatedQueryKey={getGetGameCategoriesQueryKey()}
                  onClose={onClose}
                />
              </Box>
              <CheckboxField name="isLive" label={t('content.games.categories.isLive')} control={control} />
              <Input control={control} name="code" label={t('code')} disabled />
              <Input
                name="codeName"
                label={t('content.games.categories.codeName')}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('content.games.categories.form.input.required'),
                  },
                  minLength: {
                    value: 1,
                    message: t('content.games.categories.form.codeName.minLength'),
                  },
                }}
                error={Boolean(errors.codeName)}
                helperText={errors.codeName?.message}
                required
                disabled
              />
              <Input
                name="order"
                label={t('sortOrder')}
                inputRef={register({
                  required: {
                    value: true,
                    message: t('content.games.categories.form.input.required'),
                  },
                  min: {
                    value: 0,
                    message: t('content.games.categories.form.sortOrder.min'),
                  },
                  validate: value => !isNaN(parseInt(value)),
                  valueAsNumber: true,
                })}
                error={Boolean(errors.order)}
                helperText={errors.order?.message}
              />
              <TranslationsDictionaryFields
                nameDescription="description"
                nameCodeName="codeNameTranslations"
                nameImageUrl="imageUrlTranslations"
                mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.GameCategoryImage}
                control={control}
              />
              <CheckboxField
                name="enabledInLobby"
                label={t('content.games.categories.enabledInLobby')}
                control={control}
              />
              {addMutation.isError ? <Alert severity="error">{t('content.games.categories.addError')}</Alert> : null}
              {updateMutation.isError ? (
                <Alert severity="error">{t('content.games.categories.updateError')}</Alert>
              ) : null}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="text" onClick={onClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button variant="contained" type="submit">
              {t('save')}
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  );
};

export default GameCategoryDialog;
