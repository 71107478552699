import React, { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  DepositBonusArguments,
  getGetUserDepositBonusesQueryKey,
  useAwardDepositBonus,
  useGetCampaignsOverview,
} from '@greenisland/stores';
import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

type AwardDialogProps = {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  userId: string;
};

const AwardDepositBonusesDialog = memo(({ openDialog, setOpenDialog, userId }: AwardDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: campaigns, isLoading, isError } = useGetCampaignsOverview();
  const { register, handleSubmit, errors, control, getValues } = useForm<DepositBonusArguments>({
    mode: 'onChange',
  });
  const awardMutation = useAwardDepositBonus({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserDepositBonusesQueryKey(userId));
        setOpenDialog(false);
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  if (isLoading)
    return (
      <Dialog fullWidth open={true}>
        <Box
          sx={{
            height: 443,
            minWidth: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Dialog>
    );
  if (isError || !campaigns) return null;

  const onSubmit = (data: DepositBonusArguments) => {
    awardMutation.mutate({
      userId,
      data: {
        ...data,
        maximumDepositAmount: data.maximumDepositAmount ? Number(data.maximumDepositAmount) : undefined,
        playthrough: data.playthrough ? Number(data.playthrough) : undefined,
        depositPercentage: Number(data.depositPercentage),
        expiryDate: getUnixTime(new Date(data.expiryDate)),
      },
    });
  };

  return (
    <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{capitalize(t('awardDepositBonus'))}</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            fullWidth
            name="description"
            label={t('description')}
            type={'text'}
            inputRef={register({
              required: t('fieldIsRequired') as string,
            })}
            error={!!errors.description}
          />
          <TextField
            name="playthrough"
            size="small"
            fullWidth
            sx={{ mt: 2 }}
            error={!!errors.playthrough}
            label={t('playthrough')}
            helperText={t('emptyBasedOnRank')}
            type={'number'}
            inputRef={register({
              required: t('fieldIsRequired') as string,
              validate: value => value >= 0,
            })}
          />
          <TextField
            name="depositPercentage"
            size="small"
            fullWidth
            sx={{ mt: 1 }}
            error={!!errors.depositPercentage}
            label={t('depositPercentage')}
            helperText={
              (getValues('depositPercentage') <= 0 ||
                getValues('depositPercentage') > 100 ||
                errors.depositPercentage) &&
              t('invalidPercentage')
            }
            type={'number'}
            inputRef={register({
              required: t('fieldIsRequired') as string,
              validate: value => value > 0 && value <= 100,
            })}
          />
          <Box sx={{ mt: 1 }} />
          <TextField
            size="small"
            sx={{ mr: 2, width: `calc(50% - 8px)` }}
            inputRef={register({
              validate: value => value > 0,
            })}
            error={!!errors.maximumDepositAmount}
            label={t('maximumDepositAmount')}
            helperText={t('emptyBasedOnRank')}
            type={'number'}
            name="maximumDepositAmount"
          />
          <Controller
            render={({ onChange, value }) => (
              <DateTimePicker
                disablePast
                label={t('expiryDate')}
                value={value}
                onChange={value => onChange(value.toISOString())}
                renderInput={params => <TextField size="small" sx={{ width: `calc(50% - 8px)` }} {...params} />}
                inputFormat="dd/MM/yyyy HH:mm"
                mask="__/__/____ __:__"
              />
            )}
            control={control}
            defaultValue={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString()}
            name="expiryDate"
          />
          <Controller
            control={control}
            name="campaignId"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                size="small"
                select
                fullWidth
                sx={{ mt: 1 }}
                label={t('campaign')}
                disabled={campaigns && campaigns.length <= 0}
                helperText={campaigns && campaigns.length <= 0 ? t('noCampaigns') : ''}
                error={!!errors.campaignId}
                value={value}
                onChange={onChange}
              >
                {campaigns
                  ? campaigns.map(campaign => (
                      <MenuItem key={campaign.campaignId} value={campaign.campaignId}>
                        {`${campaign.campaignName} ${campaign.theme && ` (${campaign.theme})`} ${
                          campaign.year && ` (${campaign.year})`
                        }`}
                      </MenuItem>
                    ))
                  : []}
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ minHeight: '36px', minWidth: '90px' }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={awardMutation.isLoading}
          >
            {awardMutation.isLoading ? <CircularProgress size={20} /> : t('award')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

AwardDepositBonusesDialog.displayName = 'AwardDepositBonusesDialog';

export default AwardDepositBonusesDialog;
