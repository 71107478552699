import React, { memo } from 'react';
import { Authorization, IEntityState } from '@greenisland/stores';

import { BaseRouting } from './RouteInterfaces';

interface Props extends BaseRouting {
  entitySelected: IEntityState;
  agentEntities?: Authorization.Entities;
}

function LayoutContainer(props: Props) {
  const Layout = props.layout;

  return <Layout {...props} />;
}

export default memo(LayoutContainer);
