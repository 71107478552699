import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetUserGeneral } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useDebouncedValue } from '@lilib/hooks';
import { Box, Button, capitalize, Card, CardContent, CircularProgress } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import UserInfoField from 'src/app/components/UserInfoField';
import { formatCurrency } from 'src/app/helpers/transformFunctions';
import { AnnualReportParamType } from 'src/pages/OnlineCasino/Lookup/Components/DetailsComponents/Constants/Transactions';

import { DataGridContainer, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';
import Select from '@greenisland-common/components/atoms/Select';
import TextFieldWithLoader from '@greenisland-common/components/molecules/TextFieldWithLoader';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { useAnnualReport } from './hooks/useAnnualReport';
import { usePermission } from '../../../../../app/hooks';

const AnnualReport = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const canReadUserGeneral = usePermission(OnlineCasinoPermissions.getUserGeneral);

  const [searchParams] = useSearchParams();
  const userIdQuery = searchParams.get(AnnualReportParamType.USER_ID);
  const [userId, setUserId] = useState(userIdQuery || '');

  const updateSearchParamsHandler = useUpdateSearchParams();
  useEffect(() => {
    updateSearchParamsHandler(AnnualReportParamType.USER_ID, userId);
  }, [userId, updateSearchParamsHandler]);

  const [debouncedSearch] = useDebouncedValue(userId, { wait: 500 });
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useGetUserGeneral(debouncedSearch.length > 0 ? debouncedSearch : '', {
    query: { enabled: canReadUserGeneral && !!debouncedSearch },
  });

  const endYear = new Date().getFullYear();
  const startYear = user ? new Date(user.registrationDate * 1000).getFullYear() : endYear;
  const [selectedYear, setSelectedYear] = useState(endYear);

  const yearsList = useMemo(() => {
    const validStartYear = Math.min(startYear, endYear);
    const validEndYear = Math.max(startYear, endYear);
    return Array.from({ length: validEndYear - validStartYear + 1 }, (_, i) => validEndYear - i);
  }, [startYear, endYear]);

  useEffect(() => {
    const validStartYear = Math.min(startYear, endYear);
    const validEndYear = Math.max(startYear, endYear);
    if (selectedYear > validEndYear || selectedYear < validStartYear) setSelectedYear(validEndYear);
  }, [startYear, endYear]);

  const { tableData, isPolling, viewReport, downloadReport } = useAnnualReport(user, selectedYear);

  const handleAction = (action: () => void) => {
    if (!userId) {
      enqueueSnackbar(capitalize(t('userIdRequired')), { variant: 'error' });
      return;
    }
    if (!user) {
      enqueueSnackbar(capitalize(t('common.userNotFound')), { variant: 'error' });
      return;
    }
    action();
  };

  const headers: GridColumns = [
    { headerName: capitalize(t('year')), field: 'year', flex: 1, minWidth: 20 },
    {
      headerName: capitalize(t('initialBalance')),
      field: 'initialBalance',
      valueFormatter: params => formatCurrency(params.value),
      flex: 2,
      minWidth: 20,
    },
    {
      headerName: capitalize(t('finalBalance')),
      field: 'finalBalance',
      valueFormatter: params => formatCurrency(params.value),
      flex: 2,
      minWidth: 20,
    },
    {
      headerName: capitalize(t('totalDeposits')),
      field: 'totalDeposits',
      valueFormatter: params => formatCurrency(params.value),
      flex: 2,
      minWidth: 20,
    },
    {
      headerName: capitalize(t('totalWithdrawals')),
      field: 'totalWithdrawals',
      valueFormatter: params => formatCurrency(params.value),
      flex: 2,
      minWidth: 20,
    },
    {
      headerName: capitalize(t('bets')),
      field: 'totalStake',
      valueFormatter: params => formatCurrency(params.value),
      flex: 2,
      minWidth: 20,
    },
    {
      headerName: capitalize(t('earnings')),
      field: 'totalWin',
      valueFormatter: params => formatCurrency(params.value),
      flex: 2,
      minWidth: 20,
    },
    {
      headerName: capitalize(t('bonuses')),
      field: 'totalBonusesReceived',
      valueFormatter: params => formatCurrency(params.value),
      flex: 2,
      minWidth: 20,
    },
  ];

  const rows: GridRowsProp = tableData.map((report, i) => {
    return {
      ...report,
      id: i,
    };
  });

  return (
    <Card>
      <CardContent>
        <PermissionWrapper isError={false} isLoading={false} permission={OnlineCasinoPermissions.getUserGeneral}>
          <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2}>
            <Box mt={2} mb={2}>
              <TextFieldWithLoader
                label="userId"
                value={userId}
                setValue={setUserId}
                data={user}
                isLoading={isUserLoading}
                isError={isUserError}
                disabled={isPolling}
              />
            </Box>
            {user && (
              <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2} mt={1}>
                <Box>
                  <UserInfoField label="displayname" value={user?.displayName} />
                  <UserInfoField label="email" value={user?.email} />
                </Box>
                <Box>
                  <UserInfoField label="nickname" value={user?.username} />
                  <UserInfoField label="phonenumber" value={user?.phonenumber} />
                </Box>
              </Box>
            )}
          </Box>
        </PermissionWrapper>
        <PermissionWrapper
          isError={false}
          isLoading={false}
          permission={OnlineCasinoPermissions.getOrCreateAnnualUserReport}
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2} mt={2}>
            <Select
              id="year"
              label={capitalize(t('year'))}
              value={selectedYear}
              onChange={event => setSelectedYear(Number(event.target.value))}
              options={yearsList.map(y => y.toString())}
              sx={{ maxWidth: '180px' }}
              disabled={isPolling}
            />
            <Button onClick={() => handleAction(viewReport)} variant="contained" disabled={isPolling}>
              {t('viewReport')}
            </Button>
            <Button
              onClick={() => handleAction(downloadReport)}
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faDownload} />}
              disabled={isPolling}
            >
              {t('downloadReport')}
            </Button>
            {isPolling && <CircularProgress color="info" size={20} />}
          </Box>
        </PermissionWrapper>
      </CardContent>
      <PermissionWrapper isError={false} isLoading={false} permission={OnlineCasinoPermissions.getAnnualUserReport}>
        <CardContent>
          <DataGridContainer>
            <LimitedDataGrid autoHeight columns={headers} rows={rows} hideFooter />
          </DataGridContainer>
        </CardContent>
      </PermissionWrapper>
    </Card>
  );
};

export default memo(AnnualReport);
