import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AcurisIndividualProfileSchema } from '@greenisland/stores';
import { Card, CardHeader, Collapse, IconButton } from '@mui/material';

import KYCPrettyPrintedData from '../KYCPrettyPrintedData';

interface Props {
  data: AcurisIndividualProfileSchema | undefined;
}

const KYCOtherDetailsCard = ({ data }: Props) => {
  const [otherExpanded, setOtherExpanded] = useState(false);
  const { t } = useTranslation();

  if (
    (!data?.pepByAssociationEntries || data?.pepByAssociationEntries.length === 0) &&
    (!data?.identifiers || data?.identifiers.length === 0) &&
    !data?.deletionReason &&
    (!data?.notes || data?.notes.length === 0)
  ) {
    return null;
  }

  return (
    <Card style={{ padding: '20px', marginBottom: '20px', marginTop: '20px' }}>
      <CardHeader
        title="Other"
        action={
          <IconButton aria-expanded={otherExpanded} onClick={() => setOtherExpanded(!otherExpanded)}>
            <FontAwesomeIcon icon={otherExpanded ? faCaretUp : faCaretDown} />
          </IconButton>
        }
        style={{ paddingLeft: '0px' }}
      />
      <Collapse in={otherExpanded} timeout="auto" unmountOnExit>
        <KYCPrettyPrintedData
          title={t('kyc.resource.pepAssociateEntries')}
          data={data?.pepByAssociationEntries}
        ></KYCPrettyPrintedData>
        <KYCPrettyPrintedData title={t('kyc.resource.identifiers')} data={data?.identifiers}></KYCPrettyPrintedData>
        <KYCPrettyPrintedData
          title={t('kyc.resource.deletionReason')}
          data={data?.deletionReason}
        ></KYCPrettyPrintedData>
        <KYCPrettyPrintedData title={t('kyc.resource.notes')} data={data?.notes}></KYCPrettyPrintedData>
      </Collapse>
    </Card>
  );
};

export default memo(KYCOtherDetailsCard);
