import * as React from 'react';
import { FraudReasonWithInfoSchema } from '@greenisland/stores';
import { Box } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import { ColorCell } from '@greenisland-common/components/atoms';

import { getFraudReasonColor } from '../../../../../app/helpers/getStatusColors';

interface FlagDataCellProps {
  data?: FraudReasonWithInfoSchema[];
  id: string;
}

const FlagDataCell = ({ data, id }: FlagDataCellProps) => {
  const apiRef = useGridApiContext();

  const getColorScore = (color: string | undefined): number => {
    if (color == 'red') return -2;
    if (color == 'orange') return -1;
    if (color == 'green') return 1;
    return 0;
  };

  const sortedFlagReasons = data?.sort((a, b) => {
    return getColorScore(getFraudReasonColor(a.reason)?.color) - getColorScore(getFraudReasonColor(b.reason)?.color);
  });

  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => apiRef.current.toggleDetailPanel(id)}
    >
      {sortedFlagReasons?.map((e, i) => {
        return (
          <Box key={i}>
            <ColorCell key={i} sx={getFraudReasonColor(e.reason) ?? {}} value={e.reason ?? ''} />
            <br />
          </Box>
        );
      })}
    </Box>
  );
};

export default React.memo(FlagDataCell);
