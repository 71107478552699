import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, EIcons, Link } from '@greenisland/components';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridGap: 15,
    },
    addText: {
      display: 'inline-block',
      paddingLeft: '15px',
      paddingTop: 0,
      fontSize: '0.9em',
      fontWeight: 400,
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
    },
  })
);

const CasinoRacesMain: FunctionComponent<any> = () => {
  const t = useTranslation().t;
  const classes = useStyles();

  let { actionId }: any = useParams();
  if (actionId !== 'add') {
    actionId = parseInt(actionId, 10);
  }

  return (
    <div className={classes.container}>
      <Link to={`${actionId}/races/add`}>
        <Button color="primary" variant="contained" justIcon={EIcons.faPlus} rounded />
        <h5 className={classes.addText}>{t('addCasinoRace')}</h5>
      </Link>
    </div>
  );
};

export default memo(CasinoRacesMain);
