import { useTranslation } from 'react-i18next';
import { ELoyaltyUserBadRequestTypeSchema, ErrorSchema, LoyaltyUserBadRequestContentSchema } from '@greenisland/stores';

import { LOYALTY_USER_ERROR_REASONS } from '../Constants';

export const useGetLoyaltyUserErrorString = () => {
  const { t } = useTranslation();

  return (error: ErrorSchema | LoyaltyUserBadRequestContentSchema | null): string => {
    const defaultError = 'badRequestType.general';
    if (error && typeof error === 'object' && 'type' in error && error.type) {
      const errorReason = error.type as ELoyaltyUserBadRequestTypeSchema;
      return errorReason && LOYALTY_USER_ERROR_REASONS[errorReason]
        ? t(LOYALTY_USER_ERROR_REASONS[errorReason])
        : t(defaultError);
    }
    return t(defaultError);
  };
};
