import { ImageSchema, ImagesSchema } from '@greenisland/stores';

export const convertToMediaImageSchema = (images?: ImagesSchema): ImageSchema[] => {
  if (!images) return [];

  return (
    images?.imageItems?.map(imageItem => ({
      ...imageItem,
      path: imageItem?.imageSourcePath,
    })) || []
  );
};
