import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getGetLoyaltyBoostersQueryKey,
  LoyaltyBoosterSchema,
  useDeleteLoyaltyBooster,
  useGetLoyaltyBoosters,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Stack } from '@mui/material';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { DataGridContainer, Link, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';
import ConfirmDialog from '@greenisland-common/components/organisms/ConfirmDialog';

import { formatDateTime } from '@greenisland-common/helpers';

import { usePermission } from '../../../../app/hooks';
import { LoyaltyBoostersDialog } from './components';

type Row = LoyaltyBoosterSchema & { id: string | undefined };

const LoyaltyBoostersPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const canReadLoyaltiesTokenBooster = usePermission(OnlineCasinoPermissions.getLoyaltyBoosters);
  const canAddLoyaltiesTokenBooster = usePermission(OnlineCasinoPermissions.addLoyaltyBooster);
  const canDeleteLoyaltiesTokenBooster = usePermission(OnlineCasinoPermissions.deleteLoyaltyBooster);
  const canEditGames = usePermission([
    OnlineCasinoPermissions.getGameCategories,
    OnlineCasinoPermissions.getGameV2,
    OnlineCasinoPermissions.updateGameV2,
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<{ isOpen: boolean; boosterId?: string }>({
    isOpen: false,
  });

  const { data: loyaltyBoosters, isLoading, isError, refetch } = useGetLoyaltyBoosters();
  const mutation = useDeleteLoyaltyBooster({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('marketing.loyaltyBooster.deleteSuccess'), {
          variant: 'success',
        });
        queryClient.invalidateQueries(getGetLoyaltyBoostersQueryKey());
        setDeleteConfirmationDialog({ isOpen: false });
      },

      onError: async () => {
        enqueueSnackbar(t('marketing.loyaltyBooster.deleteError'), {
          variant: 'error',
        });
      },
    },
  });

  const rows = useMemo(
    () => loyaltyBoosters?.map(booster => ({ ...booster, id: booster.boosterId })) ?? [],
    [loyaltyBoosters]
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        headerName: t('startDate'),
        field: 'startDate',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        minWidth: 180,
        flex: 1,
      },
      {
        headerName: t('endDate'),
        field: 'endDate',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        minWidth: 180,
        flex: 1,
      },
      {
        headerName: `${t('factor')} (%)`,
        field: 'factor',
        type: 'number',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && value * 100,
        minWidth: 180,
        flex: 0.5,
      },
      {
        headerName: t('game'),
        field: 'gameName',
        minWidth: 180,
        flex: 1,
        renderCell: ({ row }: GridRenderCellParams<string, Row>) =>
          canEditGames ? (
            <Link to={`../../content/games/providers/${row.gameProviderId}/games/${row.gameId}`}>{row.gameName}</Link>
          ) : (
            <span>{row.gameName}</span>
          ),
      },
      ...(canDeleteLoyaltiesTokenBooster
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<Row>) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  label="Delete"
                  onClick={() => setDeleteConfirmationDialog({ isOpen: true, boosterId: params.id as string })}
                  key="delete"
                />,
              ],
            },
          ]
        : []),
    ],
    [canEditGames, canDeleteLoyaltiesTokenBooster, t]
  );

  const handleConfirm = () => {
    if (deleteConfirmationDialog.boosterId) {
      mutation.mutate({ boosterId: deleteConfirmationDialog.boosterId });
    }
  };

  return canReadLoyaltiesTokenBooster ? (
    <>
      <Stack spacing={2}>
        {canAddLoyaltiesTokenBooster ? (
          <Box>
            <Button
              variant="contained"
              size="medium"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => setIsOpen(true)}
            >
              {t('scheduleBooster')}
            </Button>
          </Box>
        ) : undefined}

        {!isError ? (
          <DataGridContainer>
            <StyledDataGrid density="compact" autoHeight loading={isLoading} rows={rows} columns={columns} />
          </DataGridContainer>
        ) : (
          <ErrorState errorMessage={t('marketing.loyaltyBooster.loadingError')} retryAction={() => refetch()} />
        )}
      </Stack>
      <ConfirmDialog
        title={t('marketing.loyaltyBooster.deleteConfirmation.title')}
        description={t('marketing.loyaltyBooster.deleteConfirmation.description')}
        isOpen={deleteConfirmationDialog.isOpen}
        onCancel={() => setDeleteConfirmationDialog({ ...deleteConfirmationDialog, isOpen: false })}
        onConfirm={handleConfirm}
        confirmText={t('yes')}
        cancelText={t('cancel')}
      />
      {canAddLoyaltiesTokenBooster && isOpen ? (
        <LoyaltyBoostersDialog
          onClose={() => {
            setIsOpen(false);
          }}
          open={isOpen}
        />
      ) : null}
    </>
  ) : null;
};

export default LoyaltyBoostersPage;
