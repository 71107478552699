import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@greenisland/components';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const styling = makeStyles({
  actions: {
    padding: '16px 24px',
  },
});

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  config: number;
  deleteConfig: (index: number) => void;
}

const DeleteConfigurationDialog = (props: Props) => {
  const { open, setOpen, config, deleteConfig } = props;
  const classes = styling();
  const { t } = useTranslation();

  const confirmDeleteUserNote = () => {
    deleteConfig(config);
    setOpen(false);
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t('deleteConfiguration')}</DialogTitle>
      <DialogContent>{`${t('confirmDeleteConfiguration')}`}</DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="secondary" variant="transparent" onClick={() => setOpen(false)}>
          {t('no')}
        </Button>
        <Button onClick={() => confirmDeleteUserNote()} error>
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DeleteConfigurationDialog);
