import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import type { Column } from 'react-table';
import { faAdd, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactTableGI } from '@greenisland/components';
import { CampaignAction } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from '@mui/material';

import { usePermission } from '../../../../../../app/hooks';
import EnabledTypesCollapse from './EnabledTypesCollapse';
import ProcessActionDialog from './ProcessActionDialog';

interface Props {
  campaignId: string;
  individualActions: CampaignAction[];
}

const IndividualActions = ({ campaignId, individualActions }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canAddIndividualAction = usePermission(OnlineCasinoPermissions.addIndividualAction);
  const canEditIndividualAction = usePermission(OnlineCasinoPermissions.updateIndividualAction);
  const canProcessOne = usePermission(OnlineCasinoPermissions.triggerIndividualAction);
  const canProcessMultiple = usePermission(
    [
      OnlineCasinoPermissions.triggerIndividualActionAllAccounts,
      OnlineCasinoPermissions.triggerIndividualActionAllClosedAccounts,
    ],
    { strict: false }
  );

  const [selectedIndividualAction, setSelectedIndividualAction] = useState<CampaignAction | undefined>(undefined);
  const [isProcessMultiple, setIsProcessMultiple] = useState(false);

  const columns: Array<Column & { disableSortBy?: boolean }> = [
    { Header: t('name'), accessor: 'name', Cell: ({ value }) => <Typography noWrap>{value}</Typography> },
    { Header: t('enabledTypes'), accessor: 'enabledTypes', disableSortBy: true },
    { id: 'actions', accessor: 'actions', disableSortBy: true },
  ];

  const data = useMemo(() => {
    return individualActions.map((item: CampaignAction) => {
      return {
        ...item,
        enabledTypes: <EnabledTypesCollapse enabledTypes={item.enabledTypes} />,
        actions: (
          <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
            {canEditIndividualAction && (
              <Tooltip title={t('editAction')}>
                <IconButton size="small" onClick={() => navigate(`${campaignId}/actions/individual/${item.actionId}`)}>
                  <FontAwesomeIcon size="sm" icon={faPen} />
                </IconButton>
              </Tooltip>
            )}
            {canProcessOne && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => setSelectedIndividualAction(item)}
              >
                <Typography fontSize="small" noWrap>
                  {t('processForOne')}
                </Typography>
              </Button>
            )}
            {canProcessMultiple && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => {
                  setIsProcessMultiple(true);
                  setSelectedIndividualAction(item);
                }}
              >
                <Typography fontSize="small" noWrap>
                  {t('processForMultiple')}
                </Typography>
              </Button>
            )}
          </Box>
        ),
      };
    });
  }, [individualActions, canEditIndividualAction, t, canProcessOne, canProcessMultiple, navigate, campaignId]);

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" fontSize="x-large">
                {capitalize(t('individualActions'))}
              </Typography>
              {canAddIndividualAction ? (
                <Button
                  variant="contained"
                  size="medium"
                  startIcon={<FontAwesomeIcon icon={faAdd} />}
                  onClick={() => navigate(`${campaignId}/actions/individual/add`)}
                >
                  {capitalize(t('addAction'))}
                </Button>
              ) : undefined}
            </Box>
          }
        />
        <CardContent>{data.length ? <ReactTableGI columns={columns} data={data} sortable /> : t('noData')}</CardContent>
      </Card>
      {selectedIndividualAction && (
        <ProcessActionDialog
          isProcessMultiple={isProcessMultiple}
          selectedIndividualAction={selectedIndividualAction}
          campaignId={campaignId}
          onClose={() => {
            setSelectedIndividualAction(undefined);
            setIsProcessMultiple(false);
          }}
        />
      )}
    </>
  );
};

export default IndividualActions;
