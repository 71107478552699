import React from 'react';
import { useTranslation } from 'react-i18next';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { AddictionFormulaSchema } from '@greenisland/stores';
import { capitalize, Dialog, DialogTitle, IconButton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

import { DataGridContainer, DataGridPlain, FontAwesomeIcon } from '@greenisland-common/components/atoms';

import ClassificationResultField from './components/ClassificationResultField';

interface AddictionFormulaDialogProps {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  formula: AddictionFormulaSchema;
}

const AddictionFormulaDialog = ({ openDialog, setOpenDialog, formula }: AddictionFormulaDialogProps) => {
  const { t } = useTranslation();

  const headers: GridColumns = [
    {
      headerName: '',
      field: 'id',
      flex: 0.05,
    },
    {
      headerName: '',
      field: 'title',
      flex: 1,
    },
    { headerName: '', field: 'value', flex: 0.2 },
  ];

  const rows: GridRowsProp = [
    {
      id: 1,
      title: t('addictionMonitor.formula.numberOfDepositLimitChanges'),
      value: formula.numberOfDepositLimitChanges,
    },
    { id: 2, title: t('addictionMonitor.formula.averageStakePerBetPerDay'), value: formula.averageStakePerBetPerDay },
    { id: 3, title: t('addictionMonitor.formula.averageNumberOfBetsPerDay'), value: formula.averageNumberOfBetsPerDay },
    {
      id: 4,
      title: t('addictionMonitor.formula.numberOfCancelledWithdrawals'),
      value: formula.numberOfCancelledWithdrawals,
    },
    {
      id: 5,
      title: t('addictionMonitor.formula.proportionOfDepositDaysToBettingDays'),
      value: formula.proportionOfDepositDaysToBettingDays,
    },
    {
      id: 6,
      title: t('addictionMonitor.formula.numberOfDaysPlayedInPastYear'),
      value: formula.numberOfDaysPlayedInPastYear,
    },
    { id: 7, title: t('addictionMonitor.formula.userAge'), value: formula.userAge },
    { id: 8, title: t('addictionMonitor.formula.accountAge'), value: formula.accountAge },
    {
      id: 9,
      title: t('addictionMonitor.formula.classificationResult'),
      value: formula.classificationResult ? <ClassificationResultField value={formula.classificationResult} /> : '-',
    },
    { id: 9, title: t('addictionMonitor.formula.created'), value: getDateTimeFromUnix(formula.created, true) },
  ];

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="lg">
      <DialogTitle sx={{ backgroundColor: 'white' }}>
        {capitalize(t('addictionMonitor.formula.title'))}
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DataGridContainer sx={{ pb: 3 }}>
        <DataGridPlain
          sx={{
            '& .MuiDataGrid-cellContent': { whiteSpace: 'normal' },
          }}
          getRowHeight={() => 'auto'}
          columns={headers}
          rows={rows}
        />
      </DataGridContainer>
    </Dialog>
  );
};

export default AddictionFormulaDialog;
