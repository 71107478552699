import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetPagedEventstreamByEventname } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import usePermission from 'src/app/hooks/usePermission';

import EventsStreamTable from './EventsStreamTable';

const EventsStreamByEventName = ({ eventName }: { eventName: string }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [searchParams] = useSearchParams();
  const hasPermission = usePermission(OnlineCasinoPermissions.getPagedEventstreamByEventname);
  const { data, isLoading, isError } = useGetPagedEventstreamByEventname(
    {
      eventName: eventName,
      page: page,
      pageSize: pageSize,
      start: searchParams.get('startdate') ? Number(searchParams.get('startdate')) : undefined,
      end: searchParams.get('enddate') ? Number(searchParams.get('enddate')) : undefined,
    },
    {
      query: {
        enabled: !!eventName && hasPermission,
        keepPreviousData: true,
      },
    }
  );

  return (
    <EventsStreamTable
      data={data}
      isLoading={isLoading}
      isError={isError}
      permission={OnlineCasinoPermissions.getPagedEventstreamByEventname}
      page={page}
      pageSize={pageSize}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
    />
  );
};

export default EventsStreamByEventName;
