import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetUserVerificationsQueryKey,
  useDeleteUserVerificationsBankAccountDocument,
  UserDocumentMetadataSchema,
  useUploadUserVerificationsBankAccountDocument,
} from '@greenisland/stores';
import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import FileUploadDialog from '@greenisland-common/components/molecules/FileUpload/FileUploadDialog';

interface Props {
  userId: string;
  bankAccountId: string;
  metadata?: UserDocumentMetadataSchema;
}

const UploadBankAccountVerificationField = ({ userId, bankAccountId, metadata }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const mutationObject = {
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  };

  const { mutate: uploadDocument } = useUploadUserVerificationsBankAccountDocument(mutationObject);
  const { mutate: deleteDocument } = useDeleteUserVerificationsBankAccountDocument(mutationObject);

  return (
    <Box display="flex" gap={1}>
      <FileUploadDialog
        hidePreviewButton={true}
        id={bankAccountId}
        requirements={{ size: 5, mimeTypes: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'] }}
        saveFile={file => uploadDocument({ userId, bankAccountId, data: file })}
        deleteFile={() => deleteDocument({ userId, bankAccountId })}
        metadata={metadata}
      />
      {metadata && (
        <Button variant="outlined" color="error" size="small" onClick={() => deleteDocument({ userId, bankAccountId })}>
          {t('remove')}
        </Button>
      )}
    </Box>
  );
};

export default UploadBankAccountVerificationField;
