import { useMemo } from 'react';
import { EPaymentMethodSchema, EPaymentProviderSchema, PaymentMethodConfigurationsSchema } from '@greenisland/stores';

export default function useFilteredPaymentMethods(
  selectedProvider: EPaymentProviderSchema,
  paymentMethodConfigs: PaymentMethodConfigurationsSchema | undefined
) {
  return useMemo(() => {
    const allPaymentMethods = Object.values(EPaymentMethodSchema);
    if (!selectedProvider) {
      return allPaymentMethods;
    }
    return allPaymentMethods?.filter(
      method =>
        paymentMethodConfigs?.portalConfigurations
          .filter(config => config.method === method)
          .some(config => config.possibleProviders.map(pp => pp.provider).includes(selectedProvider)) ||
        paymentMethodConfigs?.v1Configurations
          .filter(config => config.method === method)
          .some(config => config.possibleProviders.map(pp => pp.provider).includes(selectedProvider)) ||
        paymentMethodConfigs?.v2Configurations
          .filter(config => config.method === method)
          .some(config => config.possibleProviders.map(pp => pp.provider).includes(selectedProvider))
    );
  }, [paymentMethodConfigs, selectedProvider]);
}
