import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, EIcons, Tooltip } from '@greenisland/components';
import { actionCreators, Campaign, CampaignsOverview } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch } from '@greenisland-core/store';
import { Collapse, List, ListItem, ListItemText, ListSubheader } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { usePermission } from '../../../../../../app/hooks';
import AddEditCampaignDialog from './AddEditCampaignDialog';

const useStyles = makeStyles(() => ({
  nested: {
    paddingLeft: 20,
    paddingRight: 10,
    '& span': {
      fontWeight: 500,
    },
  },
  nestedTwo: {
    paddingLeft: 40,
    paddingRight: 10,
  },
  collapseTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  yearListItem: {
    paddingRight: '10px',
    paddingLeft: 10,
    boxSizing: 'border-box',
  },
}));

interface Props {
  campaignsOverview: CampaignsOverview;
  campaignId?: string | null;
  onSelectCampaign: (campaignId: string) => void;
}

const CampaignsList = ({ campaignsOverview, campaignId, onSelectCampaign }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const canAddCampaign = usePermission(OnlineCasinoPermissions.addCampaign);
  const canEditCampaign = usePermission(OnlineCasinoPermissions.updateCampaign);
  const [open, setOpen] = useState<{ [name: string]: boolean }>({ menu: true });
  const [dialogOpen, setDialogOpen] = useState<{ open: boolean; campaign: Campaign | undefined }>({
    open: false,
    campaign: undefined,
  });

  const handleClickOpen = () => setDialogOpen({ open: true, campaign: undefined });
  const handleClose = () => setDialogOpen({ open: false, campaign: undefined });
  const handleEditOpen = (campaign: Campaign) => setDialogOpen({ open: true, campaign: campaign });

  const handleSave = (data: any) => {
    if (dialogOpen.campaign) {
      dispatch(actionCreators.updateCampaign(data.campaignId, data));
    } else {
      dispatch(actionCreators.addCampaign(data));
    }
  };

  useEffect(() => {
    campaignId &&
      campaignsOverview &&
      campaignsOverview.forEach((campaign: Campaign) => {
        if (campaign.campaignId === campaignId) {
          setOpen({
            ...open,
            [`year${campaign.year}`]: true,
            [`theme${campaign.theme !== '' ? campaign.theme : 'NoTheme'}${campaign.year}`]: true,
          });
        }
      });
  }, [campaignsOverview]);

  const handleCollapseClick = (name: string) => {
    setOpen({ ...open, [name]: !open[name] });
  };

  const groupBy = (list: Array<any>, keyGetter: (kentry: any) => any) => {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };
  const groupedByYear: Map<string, Array<Campaign>> = groupBy(campaignsOverview, (entry: Campaign) => entry.year);

  const RenderChildElements = () => {
    const Elements: any = [];

    const sorted = new Map([...groupedByYear.entries()].sort().reverse());

    sorted.forEach((group: Array<Campaign>, year: string) => {
      Elements.push(
        <Fragment key={year}>
          <ListItem
            className={classes.yearListItem}
            disableGutters
            button
            onClick={() => handleCollapseClick(`year${year}`)}
          >
            <ListItemText primary={year} />
            {open[`year${year}`] ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
          </ListItem>
          <Collapse in={open[`year${year}`]} timeout="auto">
            <RenderCollapseItems group={group} year={year} />
          </Collapse>
        </Fragment>
      );
    });

    return Elements;
  };

  const RenderCollapseItems = ({ group, year }: { group: Array<Campaign>; year: string }) => {
    const CollapseItems: any = [];
    const groupedByTheme = groupBy(group, (entry: Campaign) => entry.theme);

    const sorted = new Map([...groupedByTheme.entries()].sort());

    sorted.forEach((group: Array<Campaign>, theme: string) => {
      CollapseItems.push(
        <Fragment key={`collapseyear${year}${theme}`}>
          <ListItem
            disableGutters
            button
            className={classes.nested}
            onClick={() => handleCollapseClick(`theme${theme !== '' ? theme : 'NoTheme'}${year}`)}
          >
            <ListItemText primary={theme !== '' ? theme : t('noTheme')} />
            {open[`theme${theme !== '' ? theme : 'NoTheme'}${year}`] ? (
              <FontAwesomeIcon icon={faCaretUp} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} />
            )}
          </ListItem>
          <Collapse in={open[`theme${theme !== '' ? theme : 'NoTheme'}${year}`]} timeout="auto">
            {group.sort(compareString).map(campaign => {
              return (
                <ListItem
                  key={campaign.campaignId}
                  button
                  className={classes.nestedTwo}
                  selected={campaignId === campaign.campaignId}
                  onClick={() => onSelectCampaign(campaign.campaignId)}
                >
                  <ListItemText primary={campaign.campaignName} />
                  {canEditCampaign && (
                    <Tooltip content={t('editCampaign')}>
                      <Button
                        size="medium"
                        variant="transparent"
                        color="secondary"
                        rounded
                        justIcon={EIcons.faPen}
                        onClick={event => {
                          event.stopPropagation();
                          handleEditOpen(campaign);
                        }}
                      />
                    </Tooltip>
                  )}
                </ListItem>
              );
            })}
          </Collapse>
        </Fragment>
      );
    });

    return CollapseItems;
  };

  const compareString = (a: Campaign, b: Campaign) => {
    // Use toUpperCase() to ignore character casing
    const labelA = a.campaignName.toUpperCase();
    const labelB = b.campaignName.toUpperCase();

    let comparison = 0;
    if (labelA > labelB) {
      comparison = 1;
    } else if (labelA < labelB) {
      comparison = -1;
    }
    return comparison;
  };

  return (
    <>
      <List
        component="nav"
        aria-label="main"
        subheader={
          <ListSubheader color={'inherit'} component="div" disableGutters disableSticky>
            <div className={classes.collapseTitleContainer}>
              {t('campaigns')}
              {canAddCampaign && (
                <Tooltip content={t('addCampaign')}>
                  <Button size="medium" onClick={handleClickOpen} color="secondary" rounded justIcon={EIcons.faPlus} />
                </Tooltip>
              )}
            </div>
          </ListSubheader>
        }
      >
        <RenderChildElements />
      </List>
      {dialogOpen.open && (
        <AddEditCampaignDialog
          campaign={dialogOpen.campaign}
          campaignsOverview={campaignsOverview}
          dialogOpen={dialogOpen.open}
          handleClose={handleClose}
          save={handleSave}
        />
      )}
    </>
  );
};

export default CampaignsList;
