import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Card, CardBody, CheckData, Link, ReactTableGI } from '@greenisland/components';
import {
  getGetUserFreeSpinsQueryKey,
  GetUserFreeSpinsParams,
  queryClient,
  useGetUserFreeSpins,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getUnixTime, startOfDay } from 'date-fns';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import {
  setInvisible,
  transformCurrency,
  transformDecimal,
  transformDefault,
  transformStatus,
  transformType,
  transformUnixDate,
} from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { EventStreamParamsType } from '../Constants/Eventstreams';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 1fr)',
      gridGap: '15px 15px',
      marginBottom: 15,
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  })
);

const FreeSpinsPage = () => {
  const canReadFreeSpins = usePermission(OnlineCasinoPermissions.getUserFreeSpins);
  return canReadFreeSpins ? <FreeSpinsPageInnerChild /> : null;
};

const FreeSpinsPageInnerChild = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const [searchParams] = useSearchParams();

  const thirtyDaysBeforeToday = startOfDay(new Date().setDate(new Date().getDate() - 30));
  const thirtyDaysAfterToday = startOfDay(new Date().setDate(new Date().getDate() + 30));

  const [startDate, setStartDate] = useState<number>(
    Boolean(searchParams.get('startdate')) === true
      ? Number(searchParams.get('startdate')) * 1000
      : getUnixTime(thirtyDaysBeforeToday)
  );
  const [endDate, setEndDate] = useState<number>(
    Boolean(searchParams.get('enddate')) === true
      ? Number(searchParams.get('enddate')) * 1000
      : getUnixTime(thirtyDaysAfterToday)
  );

  const queryParams: GetUserFreeSpinsParams = { start: startDate, end: endDate };
  const { data: freeSpins, isLoading: freeSpinsLoading } = useGetUserFreeSpins(userId, queryParams);

  const mutatedData = () => {
    if (freeSpinsLoading || !freeSpins || freeSpins.length === 0) return undefined;
    const keys = Object.keys(freeSpins[0]);

    // TODO check if data is displayed correct
    const headers: any = keys.map(key => {
      switch (key) {
        case 'expiresOn':
        case 'createdOn':
          return transformUnixDate(key, t);
        case 'status':
          return transformStatus(key, t);
        case 'streamId':
          return setInvisible(key);
        case 'stakePerSpin':
          return transformCurrency(key, t);
        case 'amountOfSpins':
          return transformDecimal(key, t);
        case 'game':
          return transformDefault(key, t, true);
        case 'description':
          return setInvisible(key);
        case 'freeSpinsType':
          return transformType(key, t);
        case 'freeSpinsGameConfigurationFK':
          return setInvisible(key);
        default:
          return transformDefault(key, t);
      }
    });

    headers.push({ id: 'history', accessor: 'history', disableFilters: true, disableSortBy: true });

    const rows = freeSpins.map(item => {
      return {
        ...item,
        game: item.game?.name ?? '',
        history: (
          <Link to={`../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=${item.streamId.toLowerCase()}`}>
            {t('history')}
          </Link>
        ),
      };
    });

    return { headers, rows };
  };

  return (
    <div className={classes.container}>
      <DateRangePicker
        unixStartDate={startDate * 1000}
        unixEndDate={endDate * 1000}
        onChange={(start, end) => {
          queryClient.invalidateQueries(getGetUserFreeSpinsQueryKey(userId, queryParams));
          setStartDate(start);
          setEndDate(end);
        }}
      />
      <Card>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={freeSpinsLoading}
            checkProperties={['headers', 'rows']}
            render={({ data }) => (
              <ReactTableGI columns={data.headers} data={data.rows} pagination={data.rows.length > 5} sortable />
            )}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(FreeSpinsPage);
