import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  CreateMultiplierTournamentSchema,
  ErrorSchema,
  getGetMultiplierTournamentWithFiltersQueryKey,
  ImageSchema,
  MultiplierTournamentSchema,
  TournamentStatusFilterParamParameter,
  useUpdateMultiplierTournament,
} from '@greenisland/stores';
import { Button, capitalize, Dialog, DialogActions, IconButton, Skeleton, Stack } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { isPrizePoolEntryValid } from './helpers/MultiplierTournamentFormContext';
import MultiplierTournamentBetSettings from './MultiplierTournamentFormSections/MultiplierTournamentBetSettings';
import MultiplierTournamentContentFilterSettings from './MultiplierTournamentFormSections/MultiplierTournamentContentFilterSettings';
import MultiplierTournamentGeneralSettings from './MultiplierTournamentFormSections/MultiplierTournamentGeneralSettings';
import MultiplierTournamentPrizePoolSettings from './MultiplierTournamentFormSections/MultiplierTournamentPrizePoolSettings';

interface FilterParams {
  page: number;
  pageSize: number;
  tournamentStatusFilter: TournamentStatusFilterParamParameter;
  nameLikeFilter?: string;
}

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedMultiplierTournament: MultiplierTournamentSchema;
  setSelectedMultiplierTournament: Dispatch<SetStateAction<MultiplierTournamentSchema | null>>;
  filterParams: FilterParams;
}

const EditMultiplierTournament = ({
  open,
  setOpen,
  selectedMultiplierTournament,
  setSelectedMultiplierTournament,
  filterParams,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [prizePoolEntriesValidation, setPrizePoolEntriesValidation] = useState(true);

  const methods = useForm<
    MultiplierTournamentSchema & {
      startDate: number | null;
      endDate: number | null;
    }
  >({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      startDate: selectedMultiplierTournament?.start || null,
      endDate: selectedMultiplierTournament?.end || null,
      prizePool: selectedMultiplierTournament?.prizePool,
    },
  });

  const { handleSubmit } = methods;

  const { mutate: updateMultiplierTournament, isLoading } = useUpdateMultiplierTournament({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetMultiplierTournamentWithFiltersQueryKey({ ...filterParams }));
        enqueueSnackbar(t('success'), { variant: 'success' });
        onClose();
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const onClose = () => {
    setOpen(false);
    setSelectedMultiplierTournament(null);
  };

  const onSubmit = (
    formData: CreateMultiplierTournamentSchema & {
      startDate: number | null;
      endDate: number | null;
      images: ImageSchema[] | null;
      bannerImages: ImageSchema[] | null;
    }
  ) => {
    const validatePrizePoolEntries = isPrizePoolEntryValid(formData?.prizePool);
    setPrizePoolEntriesValidation(validatePrizePoolEntries);

    if (!validatePrizePoolEntries) {
      return;
    }

    const data = {
      ...formData,
      start: getUnixTime(formData?.startDate ?? new Date()),
      end: getUnixTime(formData?.endDate ?? new Date()),
      imageIds: formData?.images?.map(image => image?.id),
      bannerImageIds: formData?.bannerImages?.map(image => image?.id),
    };

    updateMultiplierTournament({ tournamentId: selectedMultiplierTournament.id, data });
  };

  if (isLoading) return <Skeleton variant="rectangular" height={600} />;

  return (
    <Dialog fullWidth open={open} maxWidth="lg" onClose={onClose}>
      <DialogTitle>{capitalize(t('contests.multiplierTournaments.editMultiplierTournamentButtonText'))}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <MultiplierTournamentGeneralSettings
                multiplierTournament={selectedMultiplierTournament}
                isCreateMode={false}
              />
              <MultiplierTournamentPrizePoolSettings prizePoolEntriesValidation={prizePoolEntriesValidation} />
              <MultiplierTournamentBetSettings
                multiplierTournament={selectedMultiplierTournament}
                isCreateMode={false}
              />
              <MultiplierTournamentContentFilterSettings
                multiplierTournament={selectedMultiplierTournament}
                isCreateMode={false}
              />
            </Stack>
            <DialogActions sx={{ px: 3, py: 2 }}>
              <Button variant="outlined" onClick={onClose}>
                {t('close')}
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {t('save')}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default EditMultiplierTournament;
