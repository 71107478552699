import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EMediaGalleryEntryTypeSchema,
  FreeSpinsCampaignSchema,
  getGetFreeSpinsCampaignsQueryKey,
  queryClient,
  useGetFreeSpinsCampaigns,
} from '@greenisland/stores';
import { Autocomplete, capitalize, CircularProgress, TextField, Typography } from '@mui/material';
import { formatCurrency } from 'src/app/helpers/transformFunctions';

import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';

import { uniq } from '@greenisland-common/helpers';

import { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const FreeSpinsForm = () => {
  const { t } = useTranslation();
  const { isLoading: fetching, data: freeSpinsCampaigns } = useGetFreeSpinsCampaigns();
  const { register, watch, setValue, control, errors } = useFormContext<IndividualActionForm>();
  const { enabledFreeSpins, freeSpinsCampaign } = watch(['enabledFreeSpins', 'freeSpinsCampaign']);
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [selectedGame, setSelectedGame] = useState<string | null>(null);
  const [selectedAmountOfSpins, setSelectedAmountOfSpins] = useState<string | null>(null);
  const [selectedStake, setSelectedStake] = useState<string | null>(null);

  if (enabledFreeSpins && !freeSpinsCampaigns?.length)
    queryClient.invalidateQueries(getGetFreeSpinsCampaignsQueryKey());

  useEffect(() => {
    if (freeSpinsCampaign) {
      setSelectedProvider(freeSpinsCampaign.provider?.name ?? null);
      setSelectedAmountOfSpins(freeSpinsCampaign.amountOfSpins?.toString() ?? null);
      setSelectedStake(freeSpinsCampaign.stake?.toString() ?? null);
      setSelectedGame(freeSpinsCampaign.game?.name ?? null);
    }
  }, [freeSpinsCampaign]);

  useEffect(() => {
    if (!enabledFreeSpins) {
      setValue('expiresInDaysFreeSpins', '');
    }
  }, [setValue, enabledFreeSpins]);

  const campaigns: FreeSpinsCampaignSchema[] = useMemo(() => {
    const filteredCampaigns =
      freeSpinsCampaigns?.filter(campaign => {
        return (
          (!selectedProvider || campaign.provider?.name === selectedProvider) &&
          (!selectedGame || campaign?.game?.name === selectedGame) &&
          (!selectedAmountOfSpins || campaign.amountOfSpins?.toString() === selectedAmountOfSpins) &&
          (!selectedStake || campaign.stake?.toString() === selectedStake) &&
          (!freeSpinsCampaign || campaign.name === freeSpinsCampaign)
        );
      }) ?? [];
    return uniq(
      filteredCampaigns.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? '')),
      campaign => campaign.name
    );
  }, [freeSpinsCampaigns, freeSpinsCampaign, selectedProvider, selectedGame, selectedAmountOfSpins, selectedStake]);

  const filteredProviders =
    freeSpinsCampaign === null
      ? campaigns.map(({ provider }) => provider?.name ?? '').filter(Boolean)
      : [freeSpinsCampaign.provider?.name ?? ''];
  const sortedProviders = uniq(filteredProviders.sort((a, b) => a.localeCompare(b)));

  const filteredGames =
    freeSpinsCampaign === null
      ? campaigns.flatMap(({ game }) => game?.name ?? '').filter(Boolean)
      : [freeSpinsCampaign.game?.name ?? ''];
  const sortedGames = uniq(filteredGames.sort((a, b) => a.localeCompare(b)));

  const filteredAmountOfSpins =
    freeSpinsCampaign === null
      ? campaigns.map(({ amountOfSpins }) => amountOfSpins ?? 0).filter(Boolean)
      : [freeSpinsCampaign.amountOfSpins ?? 0];
  const sortedAmountOfSpins = uniq(filteredAmountOfSpins.sort((a, b) => a - b)).map(String);

  const filteredStakes =
    freeSpinsCampaign === null
      ? campaigns.map(({ stake }) => stake ?? 0).filter(Boolean)
      : [freeSpinsCampaign.stake ?? 0];
  const sortedStakes = uniq(filteredStakes.sort((a, b) => a - b)).map(String);

  return (
    <IndividualActionFormListItem enabled={enabledFreeSpins} name="enabledFreeSpins" listItemText={t('freeSpins')}>
      {fetching || !freeSpinsCampaigns ? (
        <CircularProgress />
      ) : (
        <>
          <Autocomplete
            value={selectedProvider}
            onChange={(_event, value) => setSelectedProvider(value)}
            options={sortedProviders}
            renderInput={params => <TextField {...params} size="small" label={t('provider')} />}
          />
          <Autocomplete
            value={selectedGame}
            onChange={(_event, value) => setSelectedGame(value)}
            options={sortedGames}
            renderInput={params => <TextField {...params} size="small" label={t('game')} />}
          />
          <Autocomplete
            value={selectedAmountOfSpins}
            onChange={(_event, value) => setSelectedAmountOfSpins(value)}
            options={sortedAmountOfSpins}
            renderInput={params => <TextField {...params} size="small" label={t('amountOfSpins')} />}
          />
          <Autocomplete
            value={selectedStake}
            onChange={(_event, value) => setSelectedStake(value)}
            options={sortedStakes}
            renderInput={params => <TextField {...params} size="small" label={t('stake')} />}
            getOptionLabel={(stake: string) => formatCurrency(parseFloat(stake))}
          />
          <Controller
            name="freeSpinsCampaign"
            render={({ value, onChange }) => (
              <Autocomplete
                value={value}
                onChange={(_event, value) => onChange(value)}
                options={campaigns}
                getOptionLabel={(campaign: FreeSpinsCampaignSchema) => String(campaign.name)}
                renderInput={params => <TextField {...params} size="small" label={t('freeSpinCampaign')} />}
              />
            )}
          />
          <TextField
            name="expiresInDaysFreeSpins"
            inputRef={register({ min: 1 })}
            label={t('expiresInDays')}
            type="number"
            size="small"
          />
          <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.imageSettings'))}</Typography>
          <MediaFieldsWithPreview
            control={control}
            size="small"
            key="freeSpinsImages"
            name="freeSpinsImages"
            error={Boolean(errors['freeSpinsImages'])}
            type="text"
            required={false}
            label={capitalize(t('addImages'))}
            mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.Template}
            isMultiSelect={true}
          />
        </>
      )}
    </IndividualActionFormListItem>
  );
};

export default FreeSpinsForm;
