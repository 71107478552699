import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EAggregationFunctionSchema,
  EBoostTypeSchema,
  EMediaGalleryEntryTypeSchema,
  EPayoutTypeSchema,
} from '@greenisland/stores';
import { Autocomplete, capitalize, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';

import DateTimeInput from '@greenisland-common/components/molecules/DateTimeInput';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';
import SelectInputDurationField from '@greenisland-common/components/organisms/SelectInputDurationField';

import { useBoostDurationValues, useBoostLoyaltyDependentOptions } from './helpers/BoostFormContext';
import useFeatureFlags from '../../../../../../../common/hooks/useFeatureFlags';
import { ContentFilterButton } from '../../../../../Content/ContentFilter/components';
import EarlyMaxPayoutSection from '../../../../Boosts/components/EarlyMaxPayoutSection';
import LoyaltyDependantMaxPayoutSection from '../../../../Boosts/components/LoyaltyDependantMaxPayoutSection';
import { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const BoostForm = () => {
  const { t } = useTranslation();
  const { register, watch, setValue, errors, control } = useFormContext<IndividualActionForm>();
  const boostIsEnabled = useFeatureFlags('isBoostEnabled');
  const loyaltyDependentOptions = useBoostLoyaltyDependentOptions();
  const durationOptions = useBoostDurationValues();

  const enabledBoost: boolean = watch('boostEnabled') && boostIsEnabled;
  const boostType: EBoostTypeSchema = watch('boostType');
  const boostMaxPayout = watch('boostMaxPayout');
  const loyaltyDependentMaxPayout = watch('loyaltyDependentMaxPayout');

  const [boostTypeState, setBoostTypeState] = useState<EBoostTypeSchema | null>(boostType || 'FixedTime');
  const [isLoyaltyDependent, setIsLoyaltyDependent] = useState(!!loyaltyDependentMaxPayout);
  const [boostDuration, setBoostDuration] = useState(durationOptions[0]);
  const [boostExpirationDuration, setBoostExpirationDuration] = useState(durationOptions[0]);

  useEffect(() => {
    if (!enabledBoost) {
      setValue('boostDuration', '');
    }
  }, [setValue, enabledBoost]);

  useEffect(() => {
    if (boostDuration) {
      register('boostDurationSelectOption');
      setValue('boostDurationSelectOption', boostDuration.value);
    }
  }, [boostDuration, register, setValue]);

  useEffect(() => {
    if (boostExpirationDuration) {
      register('boostExpirationDurationSelectOption');
      setValue('boostExpirationDurationSelectOption', boostExpirationDuration.value);
    }
  }, [boostExpirationDuration, register, setValue]);

  const handleOnChange = (event: MouseEvent<HTMLElement>, newBoostTypeState: EBoostTypeSchema) => {
    setBoostTypeState(newBoostTypeState);
  };

  const handleLoyaltyDependentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoyaltyDependent(event.target.checked);
    setValue('boostIsLoyaltyDependent', event.target.checked);
  };

  const getBoostStartDateField = () => {
    return (
      <DateTimeInput
        name={'boostStartDate'}
        label={capitalize(t('boost.startDate'))}
        rules={{ required: enabledBoost }}
      />
    );
  };

  const getBoostDurationField = () => {
    return (
      <SelectInputDurationField
        size="small"
        key="boostDuration"
        name="boostDuration"
        inputRef={register({ required: enabledBoost, min: 1, setValueAs: value => parseInt(value) })}
        inputProps={{ min: 1 }}
        label={capitalize(t('boost.duration'))}
        error={Boolean(errors['boostDuration'])}
        type="number"
        disabled={!enabledBoost}
        required={enabledBoost}
        chosenValue={boostDuration}
        options={durationOptions}
        onChange={setBoostDuration}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
    );
  };

  const getBoostExpirationField = () => {
    return (
      <SelectInputDurationField
        size="small"
        key="boostExpirationDuration"
        name="boostExpirationDuration"
        inputRef={register({ required: enabledBoost, min: 1, setValueAs: value => parseInt(value) })}
        inputProps={{ min: 1 }}
        label={capitalize(t('boost.expirationDuration'))}
        error={Boolean(errors['boostExpirationDuration'])}
        type="number"
        disabled={!enabledBoost}
        required={enabledBoost}
        chosenValue={boostExpirationDuration}
        options={durationOptions}
        onChange={setBoostExpirationDuration}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
    );
  };

  const getBoostTypeForm = () => {
    switch (boostType) {
      case 'FixedTime':
        return (
          <>
            {getBoostStartDateField()}
            {getBoostDurationField()}
          </>
        );
      case 'SelfActivated':
        return (
          <>
            {getBoostDurationField()}
            {getBoostExpirationField()}
          </>
        );
      case 'Immediate':
        return <>{getBoostDurationField()}</>;
      default:
        return <></>;
    }
  };

  return (
    <>
      <IndividualActionFormListItem
        enabled={enabledBoost}
        name="boostEnabled"
        listItemText={capitalize(t('boost.action.name'))}
      >
        <Controller
          name={'boostType'}
          control={control}
          defaultValue={'FixedTime'}
          render={({ name }) => {
            return (
              <ToggleButtonGroup
                value={boostTypeState}
                exclusive
                onChange={(e, v: string) => {
                  handleOnChange(e, v as EBoostTypeSchema);
                  setValue(name, v);
                }}
                aria-label={t('boost.type.toggle')}
              >
                <ToggleButton value="FixedTime" aria-label={t('boost.type.fixedtime')}>
                  {capitalize(t('boost.type.fixedtime'))}
                </ToggleButton>
                <ToggleButton value="SelfActivated" aria-label={t('boost.type.selfactivated')}>
                  {capitalize(t('boost.type.selfactivated'))}
                </ToggleButton>
                <ToggleButton value="Immediate" aria-label={t('boost.type.immediate')}>
                  {capitalize(t('boost.type.immediate'))}
                </ToggleButton>
              </ToggleButtonGroup>
            );
          }}
        />
        <TextField
          size="small"
          key="boostName"
          name="boostName"
          helperText={
            errors['boostName']
              ? capitalize(t('boost.helpText.name.error'))
              : capitalize(t('boost.helpText.name.default'))
          }
          error={Boolean(errors['boostName'])}
          disabled={!enabledBoost}
          inputRef={register({ required: enabledBoost, maxLength: 256 })}
          type="text"
          required={enabledBoost}
          label={capitalize(t('boost.name'))}
        />
        <p>{capitalize(t('boost.subtitle.payoutSettings'))}</p>
        <Autocomplete
          size="small"
          disablePortal
          id="aggregation-function-selector"
          options={Object.values([EAggregationFunctionSchema.Max, EAggregationFunctionSchema.Sum])}
          defaultValue={watch('boostAggregationFunction') || undefined}
          renderInput={params => (
            <TextField
              {...params}
              inputRef={register({ required: enabledBoost })}
              label={capitalize(t('boost.aggregationFunction'))}
              required={enabledBoost}
              name="boostAggregationFunction"
              key="boostAggregationFunction"
            />
          )}
        />
        <TextField
          size="small"
          key="boostFactor"
          name="boostFactor"
          helperText={
            errors['boostFactor']
              ? capitalize(t('boost.helpText.factor.error'))
              : capitalize(t('boost.helpText.factor.default'))
          }
          error={Boolean(errors['boostFactor'])}
          disabled={!enabledBoost}
          type="text"
          InputProps={{ endAdornment: <InputAdornment position={'end'}>%</InputAdornment> }}
          required={enabledBoost}
          inputRef={register({ required: enabledBoost, min: 1 })}
          label={capitalize(t('boost.factor'))}
        />
        <Autocomplete
          size="small"
          disablePortal
          id="payout-type-selector"
          options={Object.values(EPayoutTypeSchema)}
          defaultValue={watch('boostPayoutType') || undefined}
          renderInput={params => (
            <TextField
              {...params}
              inputRef={register({ required: enabledBoost })}
              label={capitalize(t('boost.payoutType'))}
              required={enabledBoost}
              name={'boostPayoutType'}
              key={'boostPayoutType'}
            />
          )}
        />
        <LoyaltyDependantMaxPayoutSection
          isLoyaltyDependentActive={isLoyaltyDependent}
          isLoyaltyDependentRequired={enabledBoost && !boostMaxPayout}
          isLoyaltyDependentDisabled={!enabledBoost}
          loyaltyDependentName="boostIsLoyaltyDependent"
          changeLoyaltyDependentHandler={handleLoyaltyDependentChange}
          loyaltyDependentOptions={loyaltyDependentOptions}
          maxPayoutName="boostMaxPayout"
          isMaxPayoutDisabled={!enabledBoost || isLoyaltyDependent}
          isMaxPayoutRequired={enabledBoost && !isLoyaltyDependent}
        />
        <EarlyMaxPayoutSection name="boostsEarlyMaxPayoutEnabled" />
        <p>{capitalize(t('boost.subtitle.contentSettings'))}</p>
        <ContentFilterButton control={control} name="boostContentFilterId" />
        <p>{capitalize(t('boost.imageSettings'))}</p>
        <MediaFieldsWithPreview
          control={control}
          size="small"
          key="boostImages"
          name="boostImages"
          error={Boolean(errors['boostImages'])}
          type="text"
          required={false}
          label={capitalize(t('addImages'))}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.BoostImage}
          isMultiSelect={true}
        />
        <p>{capitalize(t('boost.subtitle.timeSettings'))}</p>
        {getBoostTypeForm()}
      </IndividualActionFormListItem>
    </>
  );
};

export default BoostForm;
