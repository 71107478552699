import { EAgentTaskBadRequestTypeSchema } from '@greenisland/stores';

type AgentTaskBadRequestTypeTranslation = {
  [key in EAgentTaskBadRequestTypeSchema]: string;
};

export const AGENT_TASK_ERROR_REASONS: AgentTaskBadRequestTypeTranslation = {
  [EAgentTaskBadRequestTypeSchema.agentTaskDoesNotExist]:
    'tasks.agenttask.resolve.badRequestType.agentTaskDoesNotExist',
  [EAgentTaskBadRequestTypeSchema.invalidAgentTaskId]: 'tasks.agenttask.resolve.badRequestType.invalidAgentTaskId',
  [EAgentTaskBadRequestTypeSchema.noResolutionReasonProvided]:
    'tasks.agenttask.resolve.badRequestType.noResolutionReasonProvided',
  [EAgentTaskBadRequestTypeSchema.resolutionReasonTooLong]:
    'tasks.agenttask.resolve.badRequestType.resolutionReasonTooLong',
  [EAgentTaskBadRequestTypeSchema.agentTaskNotManuallyResolvable]:
    'tasks.agenttask.resolve.badRequestType.agentTaskNotManuallyResolvable',
};

export const RESOLUTION_REASON_MAX_LENGTH = 250;
