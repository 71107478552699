import { AgentDetailsSchema, AgentPermissionSchema, AgentRoleSchema, UpdateAgentSchema } from '@greenisland/stores';

export type AgentFormData = {
  name: string;
  email: string;
  phone: string;
  isLocked: boolean;
  permissions: AgentItem[];
  agentRoles: AgentItem[];
};

export interface AgentItem {
  id: string;
  name: string;
  detailsLink?: string;
}

export const generateAgentItems = <T>(items: T[] | undefined, formatFn: (item: T) => AgentItem) => {
  if (!items) return [];
  return items?.map(formatFn);
};

export const formatPermissionAgentData = (item: AgentPermissionSchema): AgentItem => ({
  id: item?.permissionId,
  name: `${item?.level} ${item?.path}`,
});

export const formatRoleAgentData = (item: AgentRoleSchema): AgentItem => ({
  id: item?.roleId,
  name: item?.roleName,
  detailsLink: `../../roles/${item?.roleId}`,
});

export function generateAgentFormData(
  formData: AgentFormData,
  agentData: AgentDetailsSchema | undefined,
  selectedPermissions: AgentItem[],
  selectedRoles: AgentItem[]
): UpdateAgentSchema {
  const permissionIds = selectedPermissions?.map(perm => perm?.id);
  const agentRoleIds = selectedRoles?.map(role => role?.id);

  return {
    email: formData.email || agentData?.email || '',
    username: formData.email || agentData?.username || '',
    phone: formData.phone || agentData?.phone || '',
    name: formData.name || agentData?.name || '',
    permissionIds: permissionIds,
    agentRoleIds: agentRoleIds,
  };
}
