import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetCampaignsOverview } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Card, CardContent, CircularProgress, useTheme } from '@mui/material';

import { NoData, PermissionWrapper } from '@greenisland-common/components/atoms';

import DefaultTemplates from './../Templates/LegacyDefaultTemplates';
import CampaignsList from './Components/CampaignsList';
import SelectedCampaign from './Components/SelectedCampaign';
import { usePermission } from '../../../../../app/hooks';

const LegacyCampaignsOverview = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const canReadCampaigns = usePermission(OnlineCasinoPermissions.getCampaignsOverview);

  const selectedCampaignId = searchParams.get('campaignId');

  const { data: campaigns, isLoading, isError } = useGetCampaignsOverview({}, { query: { enabled: canReadCampaigns } });

  if (isLoading) {
    return <CircularProgress size={40} />;
  }

  return (
    <PermissionWrapper
      errorMessage={t('marketing.campaigns.permissions.fetchError')}
      isError={isError}
      isLoading={false}
      permission={OnlineCasinoPermissions.getCampaignsOverview}
    >
      {!campaigns ? (
        <NoData />
      ) : (
        <Box display="grid" gap={theme.spacing(2, 2)}>
          <Box
            display="grid"
            gap={theme.spacing(2, 2)}
            sx={{
              gridTemplateColumns: '1fr 3fr',
              [theme.breakpoints.down('xl')]: {
                gridTemplateColumns: '1fr 2fr',
              },
              [theme.breakpoints.down('lg')]: {
                gridTemplateColumns: '1fr',
              },
            }}
          >
            <Card>
              <CardContent>
                <CampaignsList
                  campaignId={selectedCampaignId}
                  campaignsOverview={campaigns}
                  onSelectCampaign={campaignId => setSearchParams({ campaignId })}
                />
              </CardContent>
            </Card>
            {selectedCampaignId && <SelectedCampaign campaignId={selectedCampaignId} />}
          </Box>
          <DefaultTemplates />
        </Box>
      )}
    </PermissionWrapper>
  );
};

export default LegacyCampaignsOverview;
