import { memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faAdd, faPencil } from '@fortawesome/free-solid-svg-icons';
import {
  EClosedLoopRestrictionSchema,
  EClosedLoopTypeSchema,
  EPaymentMethodSchema,
  EPaymentProviderSchema,
  EPaymentServiceVersionSchema,
  PaymentMethodDataSchema,
  PaymentMethodSchema,
  useGetPossibleConfigurations,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import usePermission from 'src/app/hooks/usePermission';

import { Checkbox, FontAwesomeIcon, FormError, SelectInput } from '@greenisland-common/components/atoms';
import SwitchInput from '@greenisland-common/components/atoms/SwitchInput/SwitchInput';
import Input from '@greenisland-common/components/molecules/Input';

import {
  defaultPaymentMethodFormValue,
  getDefaultPaymentMethodFormValue,
  PaymentMethodFormSchema,
} from './helpers/PaymentMethodFormContext';
import useFilteredPaymentMethods from '../../hooks/useFilteredPaymentMethods';
import useFilteredPaymentProviders from '../../hooks/useFilteredPaymentProviders';
import useFilteredPaymentsServiceVersion from '../../hooks/useFilteredPaymentsServiceVersions';
import usePaymentsAllowed from '../../hooks/usePaymentsAllowed';
import EditPaymentMethodImages from './EditPaymentMethodImages';
import classes from './PaymentMethodForm.module.css';

export interface PaymentMethodFormProps {
  canSubmitForm: boolean;
  loading: boolean;
  defaultPaymentMethod?: PaymentMethodSchema;
  handleSubmitForm: (data: PaymentMethodDataSchema) => void;
}

const PaymentMethodForm = ({
  canSubmitForm,
  loading,
  defaultPaymentMethod,
  handleSubmitForm,
}: PaymentMethodFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canAddPaymentMethodLimits = usePermission(OnlineCasinoPermissions.createPaymentMethodLimits);
  const canEditPaymentMethodLimits = usePermission(OnlineCasinoPermissions.updatePaymentMethodLimits);

  const methods = useForm<PaymentMethodFormSchema>({
    defaultValues: defaultPaymentMethodFormValue,
    shouldFocusError: true,
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isDirty, errors },
  } = methods;

  const closedLoopType = useMemo(() => {
    return watch('closedLoopType') as EClosedLoopTypeSchema;
  }, [watch]);
  const closedLoopDepositDescription = useMemo(() => {
    return t(`paymentMethods.closedLoopType.deposit.${closedLoopType}`);
  }, [t, closedLoopType]);

  const withdrawalsEnabled = useMemo(() => {
    return watch('withdrawalsEnabled', false);
  }, [watch]);
  const depositsEnabled = useMemo(() => {
    return watch('depositsEnabled', false);
  }, [watch]);

  const selectedMethod = watch('method') as EPaymentMethodSchema;
  const selectedProvider = watch('provider') as EPaymentProviderSchema;
  const selectedVersion = watch('paymentServiceVersion') as EPaymentServiceVersionSchema;

  const { data: paymentMethodConfigs, isLoading: configIsLoading } = useGetPossibleConfigurations();

  const filteredPaymentProviders = useFilteredPaymentProviders(selectedMethod, paymentMethodConfigs);
  const filteredPaymentMethods = useFilteredPaymentMethods(selectedProvider, paymentMethodConfigs);
  const filteredPaymentsServices = useFilteredPaymentsServiceVersion(
    selectedMethod,
    selectedProvider,
    paymentMethodConfigs
  );
  const { withdrawalsAllowed, depositsAllowed } = usePaymentsAllowed(
    selectedMethod,
    selectedProvider,
    selectedVersion,
    paymentMethodConfigs
  );

  const closedLoopTypes = Object.values(EClosedLoopTypeSchema);
  const closedLoopRestrictions = Object.values(EClosedLoopRestrictionSchema);

  useEffect(() => {
    if (defaultPaymentMethod) {
      reset(getDefaultPaymentMethodFormValue(defaultPaymentMethod as PaymentMethodSchema));
    }
  }, [defaultPaymentMethod, reset]);

  useEffect(() => {
    const withdrawalsEnabled = watch('withdrawalsEnabled', false);
    if (withdrawalsEnabled && !withdrawalsAllowed) {
      setValue('withdrawalsEnabled', false);
    }
  }, [withdrawalsAllowed, watch, setValue]);

  useEffect(() => {
    const depositsEnabled = watch('depositsEnabled', false);
    if (depositsEnabled && !depositsAllowed) {
      setValue('depositsEnabled', false);
    }
  }, [depositsAllowed, watch, setValue]);

  useEffect(() => {
    if (!withdrawalsEnabled || !depositsEnabled) {
      setValue('closedLoop', false);
    }
  }, [withdrawalsEnabled, depositsEnabled, setValue]);

  const paymentMethodFormToRequest = (formData: PaymentMethodFormSchema): PaymentMethodDataSchema => ({
    method: formData.method as EPaymentMethodSchema,
    provider: formData.provider as EPaymentProviderSchema,
    depositsEnabled: formData.depositsEnabled,
    withdrawalsEnabled: formData.withdrawalsEnabled,
    closedLoop: formData.closedLoop,
    closedLoopType: formData.closedLoopType,
    closedLoopRestriction: formData.closedLoopRestriction,
    paymentServiceVersion: formData.paymentServiceVersion as EPaymentServiceVersionSchema,
    testUserOnly: !formData.enabled,
    repeatable: formData.repeatable,
    amlTurnoverRequired: formData.amlTurnoverRequired,
    integrationVersion: formData.serviceVersion as number,
    description: formData.description,
    logoUrl: formData.logoUrl,
    logoGreyUrl: formData.logoGreyUrl,
    logoWhiteUrl: formData.logoWhiteUrl,
    logoBlackUrl: formData.logoBlackUrl,
    logoLightUrl: formData.logoLightUrl,
    logoDarkUrl: formData.logoDarkUrl,
  });

  const onSubmit = (formData: PaymentMethodFormSchema) => {
    const data: PaymentMethodDataSchema = paymentMethodFormToRequest(formData);
    if (canSubmitForm) handleSubmitForm(data);
  };

  if (configIsLoading) return <Skeleton variant="rectangular" height={600} />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <div>
            <SwitchInput
              label={t('paymentMethods.enabled')}
              name={'enabled'}
              sx={{ width: 'fit-content' }}
              defaultValue={false}
            />
            {defaultPaymentMethod && (
              <>
                {defaultPaymentMethod && !defaultPaymentMethod.limits && canAddPaymentMethodLimits && (
                  <Button
                    variant="text"
                    startIcon={<FontAwesomeIcon icon={faAdd} />}
                    onClick={() => navigate(`./limits`)}
                  >
                    {t('paymentMethods.addLimits')}
                  </Button>
                )}
                {defaultPaymentMethod && defaultPaymentMethod.limits && canEditPaymentMethodLimits && (
                  <Button
                    variant="text"
                    startIcon={<FontAwesomeIcon icon={faPencil} />}
                    onClick={() => navigate(`./limits/edit`)}
                  >
                    {t('paymentMethods.editLimits')}
                  </Button>
                )}
              </>
            )}
          </div>
          <SelectInput
            name={'provider'}
            label={t('paymentMethods.provider')}
            rules={{ required: t('fieldIsRequired') }}
            clearSelect
            options={
              filteredPaymentProviders.map(provider => ({
                value: provider,
                label: provider,
              })) ?? []
            }
            disabled={Boolean(defaultPaymentMethod)}
          />
          <SelectInput
            name={'method'}
            label={t('paymentMethods.single')}
            rules={{ required: t('fieldIsRequired') }}
            clearSelect
            options={
              filteredPaymentMethods.map(method => ({
                value: method,
                label: method,
              })) ?? []
            }
            disabled={Boolean(defaultPaymentMethod)}
          />
          <SelectInput
            name={'paymentServiceVersion'}
            label={t('paymentMethods.paymentsServiceVersion')}
            rules={{ required: t('fieldIsRequired') }}
            disabled={Boolean(defaultPaymentMethod) || !(watch('method') && watch('provider'))}
            options={
              filteredPaymentsServices.map(version => ({
                value: version,
                label: version,
              })) ?? []
            }
          />
          <Input label={t('paymentMethods.description')} control={control} name="description" />
          <Box display="flex" flexWrap="wrap">
            <Checkbox label={t('paymentMethods.depositsEnabled')} name="depositsEnabled" disabled={!depositsAllowed} />
            <Checkbox
              label={t('paymentMethods.withdrawalsEnabled')}
              name="withdrawalsEnabled"
              disabled={!withdrawalsAllowed}
            />
          </Box>
          {depositsEnabled && (
            <>
              <Typography variant="h4">{t('paymentMethods.depositsEnabled')}</Typography>
              <Checkbox label={t('paymentMethods.amlTurnoverRequired')} name="amlTurnoverRequired" />
              {withdrawalsEnabled && (
                <>
                  <Typography className={classes.disabledTextField}>{closedLoopDepositDescription}</Typography>
                </>
              )}
            </>
          )}
          {withdrawalsEnabled && <Typography variant="h4">{t('paymentMethods.withdrawalsEnabled')}</Typography>}
          {(depositsEnabled || withdrawalsEnabled) && (
            <SelectInput
              name={'closedLoopType'}
              label={t(`paymentMethods.closedLoopType.${withdrawalsEnabled ? 'withdrawal' : 'deposit'}.label`)}
              rules={{ required: t('fieldIsRequired') }}
              options={
                closedLoopTypes.map(type => ({
                  value: type,
                  label: t(`paymentMethods.closedLoopType.${withdrawalsEnabled ? 'withdrawal' : 'deposit'}.${type}`),
                })) ?? []
              }
            />
          )}
          {withdrawalsEnabled && (
            <SelectInput
              name={'closedLoopRestriction'}
              label={t('paymentMethods.closedLoopRestriction.label')}
              rules={{ required: t('fieldIsRequired') }}
              options={
                closedLoopRestrictions.map(restriction => ({
                  value: restriction,
                  label: t(`paymentMethods.closedLoopRestriction.${restriction}`),
                })) ?? []
              }
              disabled={!withdrawalsAllowed}
            />
          )}
          <EditPaymentMethodImages />
          <Stack>
            <Box display="flex" gap={1}>
              <LoadingButton color="primary" type="submit" variant="contained" loading={loading} disabled={!isDirty}>
                {t('save')}
              </LoadingButton>
            </Box>
            <FormError error={Object.keys(errors).length > 0} message={t('common.formErrors')} />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default memo(PaymentMethodForm);
