import { Authorization } from '@greenisland/stores';
import { ReadPermission, WritePermission } from '@greenisland-core/permissions';

export const OnlineCasinoPermissions = {
  getAddictionCategory: new ReadPermission('/addiction/$userid/category', Authorization.EProductType.OnlineCasino),
  setAddictionCategoryManually: new WritePermission(
    '/addiction/$userid/category',
    Authorization.EProductType.OnlineCasino
  ),
  getAddictionThresholdExceededUsers: new ReadPermission(
    '/addiction/thresholdexceededusers/$type',
    Authorization.EProductType.OnlineCasino
  ),
  getAllGameLibraryMergeConflicts: new ReadPermission(
    '/gamelibrary/conflicts',
    Authorization.EProductType.OnlineCasino
  ),
  getGameLibraryLastRuns: new ReadPermission('/gamelibrary/lastruns', Authorization.EProductType.OnlineCasino),
  gameLibrarySync: new WritePermission('/gamelibrary/sync', Authorization.EProductType.OnlineCasino),
  gameLibrarySyncAll: new WritePermission('/gamelibrary/sync/all', Authorization.EProductType.OnlineCasino),
  getMultiplierTournamentWithFilters: new ReadPermission(
    '/multipliertournaments',
    Authorization.EProductType.OnlineCasino
  ),
  createMultiplierTournament: new WritePermission('/multipliertournaments', Authorization.EProductType.OnlineCasino),
  getMultiplierTournament: new ReadPermission(
    '/multipliertournaments/$tournamentid',
    Authorization.EProductType.OnlineCasino
  ),
  updateMultiplierTournament: new WritePermission(
    '/multipliertournaments/$tournamentid',
    Authorization.EProductType.OnlineCasino
  ),
  getAllComplaintTickets: new ReadPermission('/complaints', Authorization.EProductType.OnlineCasino),
  createComplaintTicket: new WritePermission('/complaints', Authorization.EProductType.OnlineCasino),
  getComplaintTicket: new ReadPermission('/complaints/$complaintticketid', Authorization.EProductType.OnlineCasino),
  updateComplaintTicket: new WritePermission('/complaints/$complaintticketid', Authorization.EProductType.OnlineCasino),
  archiveComplaintTicket: new WritePermission(
    '/complaints/$complaintticketid',
    Authorization.EProductType.OnlineCasino
  ),
  addComplaintTicketEntry: new WritePermission(
    '/complaints/$complaintticketid',
    Authorization.EProductType.OnlineCasino
  ),
  closeComplaintTicket: new WritePermission(
    '/complaints/$complaintticketid/close',
    Authorization.EProductType.OnlineCasino
  ),
  updateComplaintEntry: new WritePermission(
    '/complaints/$complaintticketid/$complaintentryid',
    Authorization.EProductType.OnlineCasino
  ),
  archiveComplaintEntry: new WritePermission(
    '/complaints/$complaintticketid/$complaintentryid',
    Authorization.EProductType.OnlineCasino
  ),
  getOperatorJackpotConfigs: new ReadPermission('/operator-jackpots/configs', Authorization.EProductType.OnlineCasino),
  createOperatorJackpotConfig: new WritePermission(
    '/operator-jackpots/configs',
    Authorization.EProductType.OnlineCasino
  ),
  getOperatorJackpotConfig: new ReadPermission(
    '/operator-jackpots/configs/$configid',
    Authorization.EProductType.OnlineCasino
  ),
  updateOperatorJackpotConfig: new WritePermission(
    '/operator-jackpots/configs/$configid',
    Authorization.EProductType.OnlineCasino
  ),
  archiveOperatorJackpotConfig: new WritePermission(
    '/operator-jackpots/configs/$configid',
    Authorization.EProductType.OnlineCasino
  ),
  launchOperatorJackpotConfig: new WritePermission(
    '/operator-jackpots/configs/$configid/launch',
    Authorization.EProductType.OnlineCasino
  ),
  getOperatorJackpotInstances: new ReadPermission(
    '/operator-jackpots/instances',
    Authorization.EProductType.OnlineCasino
  ),
  getOperatorJackpotInstance: new ReadPermission(
    '/operator-jackpots/instances/$instanceid',
    Authorization.EProductType.OnlineCasino
  ),
  deleteOperatorJackpotInstance: new WritePermission(
    '/operator-jackpots/instances/$instanceid',
    Authorization.EProductType.OnlineCasino
  ),
  getUserRegistration: new ReadPermission('/regulator/$userid/registration', Authorization.EProductType.OnlineCasino),
  resolveCriticalFieldsMismatch: new WritePermission(
    '/regulator/$userid/critical-fields/mismatch/resolve',
    Authorization.EProductType.OnlineCasino
  ),
  getCriticalFieldsMismatch: new ReadPermission(
    '/regulator/$userid/critical-fields/mismatch',
    Authorization.EProductType.OnlineCasino
  ),
  getUserRegistrationMessages: new ReadPermission(
    '/regulator/$userid/registrationmessages',
    Authorization.EProductType.OnlineCasino
  ),
  addUserRegistrationActionKey: new WritePermission(
    '/regulator/$userid/registration/$actionkey',
    Authorization.EProductType.OnlineCasino
  ),
  scheduleCanExceedRequest: new WritePermission(
    '/regulator/$userid/article6/schedulecanexceed',
    Authorization.EProductType.OnlineCasino
  ),
  requestCanExceedForUser: new WritePermission(
    '/regulator/$userid/article6/requestcanexceed',
    Authorization.EProductType.OnlineCasino
  ),
  deleteRequestCanExceedForUser: new WritePermission(
    '/regulator/$userid/article6/requestcanexceed',
    Authorization.EProductType.OnlineCasino
  ),
  deleteRequestCanExceedForToken: new WritePermission(
    '/regulator/$userid/request-can-exceed/$token',
    Authorization.EProductType.OnlineCasino
  ),
  getExceedDefaultWeeklyDepositLimitRequests: new ReadPermission(
    '/regulator/$userid/article6/exceedrequests',
    Authorization.EProductType.OnlineCasino
  ),
  resetExceedDefaultWeeklyDepositLimitRequests: new WritePermission(
    '/regulator/$userid/article6/exceedrequests/reset',
    Authorization.EProductType.OnlineCasino
  ),
  getScheduledCanExceedRequests: new ReadPermission(
    '/regulator/$userid/article6/scheduledcanexceedrequests',
    Authorization.EProductType.OnlineCasino
  ),
  getLimitStatusForTokenForUser: new WritePermission(
    '/regulator/$userid/article6/limitstatus',
    Authorization.EProductType.OnlineCasino
  ),
  getCruksDisturbanceOverview: new ReadPermission(
    '/regulator/cruks/disturbance/overview',
    Authorization.EProductType.OnlineCasino
  ),
  getCruksDisturbanceAffectedUsers: new ReadPermission(
    '/regulator/cruks/disturbance/$disturbanceid/users',
    Authorization.EProductType.OnlineCasino
  ),
  createCDBCorrectionCommand: new WritePermission('/regulator/cdb/correction', Authorization.EProductType.OnlineCasino),
  getCDBRecord: new ReadPermission('/regulator/cdb/record/$recordid', Authorization.EProductType.OnlineCasino),
  getLastActivitySeconds: new ReadPermission('/reporting/lastactivityseconds', Authorization.EProductType.OnlineCasino),
  getEvents: new ReadPermission('/events', Authorization.EProductType.OnlineCasino),
  getEventstreamStreamId: new ReadPermission('/events/streamid', Authorization.EProductType.OnlineCasino),
  getPagedEventstreamByStreamId: new ReadPermission('/events/v2/streamid', Authorization.EProductType.OnlineCasino),
  getEventstreamEventname: new ReadPermission('/events/eventname/$eventname', Authorization.EProductType.OnlineCasino),
  getPagedEventstreamByEventname: new ReadPermission('/events/v2/eventname', Authorization.EProductType.OnlineCasino),
  getUserGeneral: new ReadPermission('/users/$userid/general', Authorization.EProductType.OnlineCasino),
  checkPepSanctionList: new WritePermission('/users/$userid/general', Authorization.EProductType.OnlineCasino),
  updatePhoneNumber: new WritePermission('/users/$userid/phone-number', Authorization.EProductType.OnlineCasino),
  setBlacklisted: new WritePermission('/users/$userid/general/blacklisted', Authorization.EProductType.OnlineCasino),
  createTestUser: new WritePermission('/users/testuser', Authorization.EProductType.OnlineCasino),
  addToBonusBlacklist: new WritePermission('/blacklist/bonus/$userid', Authorization.EProductType.OnlineCasino),
  removeFromBonusBlacklist: new WritePermission('/blacklist/bonus/$userid', Authorization.EProductType.OnlineCasino),
  addToMarketingBlacklist: new WritePermission('/blacklist/marketing/$userid', Authorization.EProductType.OnlineCasino),
  removeFromMarketingBlacklist: new WritePermission(
    '/blacklist/marketing/$userid',
    Authorization.EProductType.OnlineCasino
  ),
  getWhitelistedCredentials: new ReadPermission(
    '/fraud/payments/users/$userid/whitelist/$whitelisttype',
    Authorization.EProductType.OnlineCasino
  ),
  whitelistCredentials: new WritePermission(
    '/fraud/payments/users/$userid/whitelist/$whitelisttype',
    Authorization.EProductType.OnlineCasino
  ),
  removeCredentialsFromWhitelist: new WritePermission(
    '/fraud/payments/users/$userid/whitelist/$whitelisttype',
    Authorization.EProductType.OnlineCasino
  ),
  markDepositSafe: new WritePermission(
    '/fraud/payments/deposits/$depositid/safe',
    Authorization.EProductType.OnlineCasino
  ),
  acknowledgeUnsafeDeposit: new WritePermission(
    '/fraud/payments/deposits/$depositid/acknowledge',
    Authorization.EProductType.OnlineCasino
  ),
  setUserStatus: new WritePermission('/users/$userid/status', Authorization.EProductType.OnlineCasino),
  disableSelfExclusion: new WritePermission(
    '/users/$userid/disableselfexclusion',
    Authorization.EProductType.OnlineCasino
  ),
  enableIsTestUser: new WritePermission('/users/$userid/enableistestuser', Authorization.EProductType.OnlineCasino),
  setUserEmail: new WritePermission('/users/$userid/email', Authorization.EProductType.OnlineCasino),
  sendUserEmailVerification: new WritePermission(
    '/users/$userid/emailverification',
    Authorization.EProductType.OnlineCasino
  ),
  setAwardWelcomeBonusPack: new WritePermission(
    '/users/$userid/awardwelcomebonuspack',
    Authorization.EProductType.OnlineCasino
  ),
  getPromoToCashEvents: new ReadPermission(
    '/users/$userid/correctpromotocash',
    Authorization.EProductType.OnlineCasino
  ),
  setCorrectPromoToCash: new WritePermission(
    '/users/$userid/correctpromotocash',
    Authorization.EProductType.OnlineCasino
  ),
  getUserNotes: new ReadPermission('/users/$userid/notes', Authorization.EProductType.OnlineCasino),
  addUserNote: new WritePermission('/users/$userid/notes', Authorization.EProductType.OnlineCasino),
  deleteUserNote: new WritePermission('/users/$userid/notes/$noteid', Authorization.EProductType.OnlineCasino),
  getUserLinkedAccounts: new ReadPermission('/users/$userid/linkedaccounts', Authorization.EProductType.OnlineCasino),
  getUserLoginSession: new ReadPermission('/users/$userid/loginsession', Authorization.EProductType.OnlineCasino),
  getUserPreferences: new ReadPermission('/users/$userid/preferences', Authorization.EProductType.OnlineCasino),
  getUserServiceContractApprovals: new ReadPermission(
    '/users/$userid/servicecontractapprovals',
    Authorization.EProductType.OnlineCasino
  ),
  getBasicAccountData: new ReadPermission('/users/$userid/basicaccountdata', Authorization.EProductType.OnlineCasino),
  getAllAccountData: new ReadPermission('/users/$userid/allaccountdata', Authorization.EProductType.OnlineCasino),
  getUserAccountStatistics: new ReadPermission(
    '/users/$userid/account/statistics',
    Authorization.EProductType.OnlineCasino
  ),
  setRefund: new WritePermission('/users/$userid/account/refund', Authorization.EProductType.OnlineCasino),
  getGamesActivity: new ReadPermission('/users/$userid/gamesactivity', Authorization.EProductType.OnlineCasino),
  getUserIdentity: new ReadPermission('/users/$userid/identity', Authorization.EProductType.OnlineCasino),
  getWithdrawLimits: new ReadPermission('/users/$userid/withdrawlimits', Authorization.EProductType.OnlineCasino),
  addWithdrawLimit: new WritePermission('/users/$userid/withdrawlimits', Authorization.EProductType.OnlineCasino),
  updateWithdrawLimit: new WritePermission(
    '/users/$userid/withdrawlimits/$withdrawlimitid',
    Authorization.EProductType.OnlineCasino
  ),
  deleteWithdrawLimit: new WritePermission(
    '/users/$userid/withdrawlimits/$withdrawlimitid',
    Authorization.EProductType.OnlineCasino
  ),
  exportWithdrawalsAndDepositsHistory: new ReadPermission(
    '/users/$userid/withdrawalsanddeposits',
    Authorization.EProductType.OnlineCasino
  ),
  getUserVerification: new ReadPermission('/users/$userid/verification', Authorization.EProductType.OnlineCasino),
  getUserVerifications: new ReadPermission('/users/$userid/verifications', Authorization.EProductType.OnlineCasino),
  postCommunicateVerificationNeeded: new WritePermission(
    '/users/$userid/verifications/communicate',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsIdentity: new WritePermission(
    '/users/$userid/verifications/identity',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsIdentityVerificationStatus: new WritePermission(
    '/users/$userid/verifications/identity/verificationstatus',
    Authorization.EProductType.OnlineCasino
  ),
  resetStatusToVerificationNeeded: new WritePermission(
    '/users/$userid/verifications/identity/reset-to-verification-needed',
    Authorization.EProductType.OnlineCasino
  ),
  uploadUserVerificationsIdentityFile: new WritePermission(
    '/users/$userid/verifications/identity/$filetype',
    Authorization.EProductType.OnlineCasino
  ),
  deleteUserVerificationsIdentityFile: new WritePermission(
    '/users/$userid/verifications/identity/$filetype',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsWalletVerificationStatus: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/verificationstatus',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsBankAccountStatus: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountid/verificationstatus',
    Authorization.EProductType.OnlineCasino
  ),
  uploadUserVerificationsBankAccountDocument: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountid/bankaccountdocument',
    Authorization.EProductType.OnlineCasino
  ),
  deleteUserVerificationsBankAccountDocument: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountid/bankaccountdocument',
    Authorization.EProductType.OnlineCasino
  ),
  archiveUserBankAccountVerification: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountnumber/archive',
    Authorization.EProductType.OnlineCasino
  ),
  unarchiveUserBankAccountVerification: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountid/unarchive',
    Authorization.EProductType.OnlineCasino
  ),
  uploadUserVerificationsWalletDocument: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/walletdocument',
    Authorization.EProductType.OnlineCasino
  ),
  deleteUserVerificationsWalletDocument: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/walletdocument',
    Authorization.EProductType.OnlineCasino
  ),
  archiveUserWalletVerification: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/archive',
    Authorization.EProductType.OnlineCasino
  ),
  unarchiveUserWalletVerification: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/unarchive',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsDomicile: new WritePermission(
    '/users/$userid/verifications/domicile',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsDomicileVerificationStatus: new WritePermission(
    '/users/$userid/verifications/domicile/verificationstatus',
    Authorization.EProductType.OnlineCasino
  ),
  uploadUserVerificationsDomicileDocument: new WritePermission(
    '/users/$userid/verifications/domicile/domiciledocument',
    Authorization.EProductType.OnlineCasino
  ),
  deleteUserVerificationsDomicileDocument: new WritePermission(
    '/users/$userid/verifications/domicile/domiciledocument',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsSolvency: new WritePermission(
    '/users/$userid/verifications/solvency',
    Authorization.EProductType.OnlineCasino
  ),
  approveFinancialVerification: new WritePermission(
    '/verifications/user/$userid/financial/approve',
    Authorization.EProductType.OnlineCasino
  ),
  declineFinancialVerification: new WritePermission(
    '/verifications/user/$userid/financial/decline',
    Authorization.EProductType.OnlineCasino
  ),
  setFinancialVerificationToNeedsVerification: new WritePermission(
    '/verifications/user/$userid/financial/needs-verification',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsFinancial: new WritePermission(
    '/users/$userid/verifications/financial',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsSolvencyVerificationStatus: new WritePermission(
    '/users/$userid/verifications/solvency/verificationstatus',
    Authorization.EProductType.OnlineCasino
  ),
  updateUserVerificationsFinancialVerificationStatus: new WritePermission(
    '/users/$userid/verifications/financial/verificationstatus',
    Authorization.EProductType.OnlineCasino
  ),
  uploadUserVerificationsSolvencyDocument: new WritePermission(
    '/users/$userid/verifications/solvency/solvencydocument',
    Authorization.EProductType.OnlineCasino
  ),
  deleteUserVerificationsSolvencyDocument: new WritePermission(
    '/users/$userid/verifications/solvency/solvencydocument',
    Authorization.EProductType.OnlineCasino
  ),
  uploadUserVerificationsFinancialDocument: new WritePermission(
    '/users/$userid/verifications/financial/financialdocument',
    Authorization.EProductType.OnlineCasino
  ),
  getUserVouchers: new ReadPermission('/users/$userid/vouchers', Authorization.EProductType.OnlineCasino),
  getUserVoucherCode: new ReadPermission(
    '/users/$userid/vouchers/$voucherid/code',
    Authorization.EProductType.OnlineCasino
  ),
  getUserVoucherSensitiveInfo: new ReadPermission(
    '/users/$userid/vouchers/$voucherid/$vouchertype/sensitive',
    Authorization.EProductType.OnlineCasino
  ),
  getUserCruksRegistration: new ReadPermission(
    '/users/$userid/cruksregistration',
    Authorization.EProductType.OnlineCasino
  ),
  getUserCruksMessages: new ReadPermission('/user/$userid/cruksmessages', Authorization.EProductType.OnlineCasino),
  clearAmlDepositTurnoverRequirements: new WritePermission(
    '/users/$userid/account/clearaml',
    Authorization.EProductType.OnlineCasino
  ),
  getUserAvailableBonuses: new ReadPermission(
    '/users/$userid/account/availablebonuses',
    Authorization.EProductType.OnlineCasino
  ),
  awardAvailableBonus: new WritePermission(
    '/users/$userid/account/availablebonuses',
    Authorization.EProductType.OnlineCasino
  ),
  getUserDepositBonuses: new ReadPermission(
    '/users/$userid/account/depositbonuses',
    Authorization.EProductType.OnlineCasino
  ),
  awardDepositBonus: new WritePermission(
    '/users/$userid/account/depositbonuses',
    Authorization.EProductType.OnlineCasino
  ),
  getUserFreeSpins: new ReadPermission('/users/$userid/account/freespins', Authorization.EProductType.OnlineCasino),
  awardFreeSpins: new WritePermission('/users/$userid/account/freespins', Authorization.EProductType.OnlineCasino),
  getAccountTransactions: new ReadPermission(
    '/users/$userid/account/transactions',
    Authorization.EProductType.OnlineCasino
  ),
  cancelGameRound: new WritePermission('/users/$userid/cancelround/$txid', Authorization.EProductType.OnlineCasino),
  getUserWithdrawalRequests: new ReadPermission(
    '/users/$userid/account/withdrawalrequests',
    Authorization.EProductType.OnlineCasino
  ),
  getUserVaultTokenBalance: new ReadPermission('/users/$userid/vault/balance', Authorization.EProductType.OnlineCasino),
  getUserVaultTransactions: new ReadPermission(
    '/users/$userid/vault/transactions',
    Authorization.EProductType.OnlineCasino
  ),
  listCountries: new ReadPermission('/users/countries', Authorization.EProductType.OnlineCasino),
  quickUserSearch: new ReadPermission('/users/search', Authorization.EProductType.OnlineCasino),
  advancedUserSearch: new WritePermission('/users/search', Authorization.EProductType.OnlineCasino),
  advancedUserSearchV2: new ReadPermission('/users/search/advanced', Authorization.EProductType.OnlineCasino),
  automaticWithdrawalPermission: new WritePermission(
    '/users/$userid/manualwithdrawalflag',
    Authorization.EProductType.OnlineCasino
  ),
  resetCheckinAttempts: new WritePermission('/checkin/attempts/reset', Authorization.EProductType.OnlineCasino),
  getSelfTests: new ReadPermission('/pgsi/selftest', Authorization.EProductType.OnlineCasino),
  getBasicInfo: new ReadPermission('/reporting/basicinfo', Authorization.EProductType.OnlineCasino),
  getBonusesActive: new ReadPermission('/reporting/bonuses/active', Authorization.EProductType.OnlineCasino),
  getBonusesAwardedToday: new ReadPermission(
    '/reporting/bonuses/awardedtoday',
    Authorization.EProductType.OnlineCasino
  ),
  getBonusesBlacklist: new ReadPermission('/reporting/bonuses/blacklist', Authorization.EProductType.OnlineCasino),
  getBonusesCashedPromos: new ReadPermission(
    '/reporting/bonuses/cashedpromos',
    Authorization.EProductType.OnlineCasino
  ),
  getActivity: new ReadPermission('/reporting/casinoactivity', Authorization.EProductType.OnlineCasino),
  getPlayerActivity: new ReadPermission('/reporting/casinoplayeractivity', Authorization.EProductType.OnlineCasino),
  getAccountingV2: new ReadPermission('/v2/reporting/financial/accounting', Authorization.EProductType.OnlineCasino),
  getAccountingInvoiceV2: new ReadPermission(
    '/v2/reporting/financial/accounting/invoice',
    Authorization.EProductType.OnlineCasino
  ),
  getAccounting: new ReadPermission('/reporting/financial/accounting', Authorization.EProductType.OnlineCasino),
  getAccountingInvoice: new ReadPermission(
    '/reporting/financial/accounting/invoices',
    Authorization.EProductType.OnlineCasino
  ),
  getCasinoTransactions: new ReadPermission(
    '/reporting/financial/casinotransactions',
    Authorization.EProductType.OnlineCasino
  ),
  getGameProvidersReport: new ReadPermission(
    '/reporting/financial/gameproviders',
    Authorization.EProductType.OnlineCasino
  ),
  getGameProviderGames: new ReadPermission(
    '/reporting/financial/gameproviders/$gameprovider',
    Authorization.EProductType.OnlineCasino
  ),
  getDailyResults: new ReadPermission('/reporting/financial/dailyresults', Authorization.EProductType.OnlineCasino),
  getWeeklyResults: new ReadPermission('/reporting/financial/weeklyresults', Authorization.EProductType.OnlineCasino),
  getGamesPopularity: new ReadPermission('/reporting/games/popular', Authorization.EProductType.OnlineCasino),
  getGamesToday: new ReadPermission('/reporting/games/today', Authorization.EProductType.OnlineCasino),
  getPlayerTopTransactions: new ReadPermission(
    '/reporting/player/toptransactions',
    Authorization.EProductType.OnlineCasino
  ),
  getPlayersBlocked: new ReadPermission('/reporting/player/blocked', Authorization.EProductType.OnlineCasino),
  getPlayersActivity: new ReadPermission('/reporting/player/playeractivity', Authorization.EProductType.OnlineCasino),
  getPlayersRevenueContributors: new ReadPermission(
    '/reporting/player/revenuecontributors',
    Authorization.EProductType.OnlineCasino
  ),
  getPlayersRevenueContributorsBonusRequests: new ReadPermission(
    '/reporting/player/revenuecontributors/$userid/bonusrequests',
    Authorization.EProductType.OnlineCasino
  ),
  awardPlayersRevenueContributorsBonusRequest: new WritePermission(
    '/reporting/player/revenuecontributors/$userid/bonusrequests/$answeredquestionid',
    Authorization.EProductType.OnlineCasino
  ),
  getPlayerOverview: new ReadPermission('/reporting/player/overview', Authorization.EProductType.OnlineCasino),
  getEventCounts: new ReadPermission('/reporting/events', Authorization.EProductType.OnlineCasino),
  getPagedHistoricEventstreamByEventname: new ReadPermission(
    '/reporting/events/eventname',
    Authorization.EProductType.OnlineCasino
  ),
  getPagedHistoricEventstreamByStreamId: new ReadPermission(
    '/reporting/events/streamid',
    Authorization.EProductType.OnlineCasino
  ),
  getRevenue: new ReadPermission('/tx/revenue', Authorization.EProductType.OnlineCasino),
  getTransactionsGeneral: new ReadPermission('/tx', Authorization.EProductType.OnlineCasino),
  getAccountBalancesConsistency: new ReadPermission(
    '/tx/accountbalancesconsistency',
    Authorization.EProductType.OnlineCasino
  ),
  getCashflow: new ReadPermission('/tx/cashflow', Authorization.EProductType.OnlineCasino),
  getDepositsGeneral: new ReadPermission('/deposits', Authorization.EProductType.OnlineCasino),
  getOnlineDeposits: new ReadPermission('/deposits/onlinedeposits', Authorization.EProductType.OnlineCasino),
  getOnlineDepositsForUser: new ReadPermission(
    '/deposits/onlinedeposits/$userid',
    Authorization.EProductType.OnlineCasino
  ),
  depositManually: new WritePermission('/deposits/manually', Authorization.EProductType.OnlineCasino),
  refundDepositManually: new WritePermission('/deposits/refundmanually', Authorization.EProductType.OnlineCasino),
  chargeBackDepositManually: new WritePermission(
    '/deposits/$depositid/chargebackmanually',
    Authorization.EProductType.OnlineCasino
  ),
  confirmDepositDisputeLost: new WritePermission(
    '/deposits/$depositid/confirmdisputelost',
    Authorization.EProductType.OnlineCasino
  ),
  confirmDepositDisputeWon: new WritePermission(
    '/deposits/$depositid/confirmdisputewon',
    Authorization.EProductType.OnlineCasino
  ),
  failDeposit: new WritePermission('/deposits/$depositid/fail', Authorization.EProductType.OnlineCasino),
  getOnlineDepositStatuses: new ReadPermission(
    '/deposits/onlinedeposits/statuses',
    Authorization.EProductType.OnlineCasino
  ),
  getDepositsSummary: new ReadPermission('/deposits/summary', Authorization.EProductType.OnlineCasino),
  completeDepositManually: new WritePermission(
    '/deposits/$userid/complete/$depositid',
    Authorization.EProductType.OnlineCasino
  ),
  getDepositDetails: new ReadPermission(
    '/deposits/$userid/details/$depositid',
    Authorization.EProductType.OnlineCasino
  ),
  getPaymentMethods: new ReadPermission('/paymentmethods', Authorization.EProductType.OnlineCasino),
  createPaymentMethod: new WritePermission('/paymentmethods', Authorization.EProductType.OnlineCasino),
  getPossibleConfigurations: new ReadPermission('/paymentmethods/config', Authorization.EProductType.OnlineCasino),
  getPaymentMethod: new ReadPermission('/paymentmethods/$paymentmethodid', Authorization.EProductType.OnlineCasino),
  updatePaymentMethod: new WritePermission('/paymentmethods/$paymentmethodid', Authorization.EProductType.OnlineCasino),
  updatePaymentMethodSortOrder: new WritePermission(
    '/paymentmethods/$paymentmethodid/sortorder',
    Authorization.EProductType.OnlineCasino
  ),
  createPaymentMethodLimits: new WritePermission(
    '/paymentmethods/$paymentmethodid/limits',
    Authorization.EProductType.OnlineCasino
  ),
  updatePaymentMethodLimits: new WritePermission(
    '/paymentmethods/$paymentmethodid/limits/$limitid',
    Authorization.EProductType.OnlineCasino
  ),
  getDepositsAndWithdrawals: new ReadPermission('/tx/depositsandwithdrawals', Authorization.EProductType.OnlineCasino),
  getTopInOutToday: new ReadPermission('/tx/topinouttoday', Authorization.EProductType.OnlineCasino),
  getTopWinLoseToday: new ReadPermission('/tx/topwinlosetoday', Authorization.EProductType.OnlineCasino),
  getTopWins: new ReadPermission('/tx/top-wins', Authorization.EProductType.OnlineCasino),
  awardContestQuestion: new WritePermission('/contests/awardcontestquestion', Authorization.EProductType.OnlineCasino),
  getVaultDetails: new ReadPermission('/contests/vault/$vaultid', Authorization.EProductType.OnlineCasino),
  getVaultConfigurations: new ReadPermission('/contests/vaultconfiguration', Authorization.EProductType.OnlineCasino),
  addVaultConfiguration: new WritePermission('/contests/vaultconfiguration', Authorization.EProductType.OnlineCasino),
  activateVaultConfiguration: new WritePermission(
    '/contests/vaultconfiguration/$vaultconfigid/activate',
    Authorization.EProductType.OnlineCasino
  ),
  deactivateVaultConfiguration: new WritePermission(
    '/contests/vaultconfiguration/$vaultconfigid/deactivate',
    Authorization.EProductType.OnlineCasino
  ),
  getVaultTokenBoosters: new ReadPermission('/contests/vaulttokenbooster', Authorization.EProductType.OnlineCasino),
  addVaultTokenBooster: new WritePermission('/contests/vaulttokenbooster', Authorization.EProductType.OnlineCasino),
  deactivateTokenBooster: new WritePermission(
    '/contests/vaulttokenbooster/$boosterid',
    Authorization.EProductType.OnlineCasino
  ),
  getWithdrawalsGeneral: new ReadPermission('/withdrawals', Authorization.EProductType.OnlineCasino),
  getWithdrawalsByType: new ReadPermission('/withdrawals/$type', Authorization.EProductType.OnlineCasino),
  getWithdrawalsBankAccount: new ReadPermission('/withdrawals/bankaccount', Authorization.EProductType.OnlineCasino),
  getWithdrawalsCasino: new ReadPermission('/withdrawals/casino', Authorization.EProductType.OnlineCasino),
  getWithdrawalsNetteller: new ReadPermission('/withdrawals/netteller', Authorization.EProductType.OnlineCasino),
  getWithdrawalsSkrill: new ReadPermission('/withdrawals/skrill', Authorization.EProductType.OnlineCasino),
  getWithdrawalsPaypal: new ReadPermission('/withdrawals/paypal', Authorization.EProductType.OnlineCasino),
  getWithdrawalsTrustly: new ReadPermission('/withdrawal/trustly', Authorization.EProductType.OnlineCasino),
  getProcessingFailedWithdrawals: new ReadPermission(
    '/withdrawals/$type/processingfailed',
    Authorization.EProductType.OnlineCasino
  ),
  getProcFailedBank: new ReadPermission(
    '/withdrawals/bankaccount/processingfailed',
    Authorization.EProductType.OnlineCasino
  ),
  getProcFailedCas: new ReadPermission('/withdrawals/casino/processingfailed', Authorization.EProductType.OnlineCasino),
  getProcFailedNet: new ReadPermission(
    '/withdrawals/netteller/processingfailed',
    Authorization.EProductType.OnlineCasino
  ),
  getProcFailedSkrill: new ReadPermission(
    '/withdrawals/skrill/processingfailed',
    Authorization.EProductType.OnlineCasino
  ),
  getProcFailedPaypal: new ReadPermission(
    '/withdrawals/paypal/processingfailed',
    Authorization.EProductType.OnlineCasino
  ),
  getProcFailedTrustly: new ReadPermission(
    '/withdrawals/trustly/processingfailed',
    Authorization.EProductType.OnlineCasino
  ),
  exportWithdrawals: new WritePermission('/withdrawals/$type/export', Authorization.EProductType.OnlineCasino),
  declineWithdrawal: new WritePermission(
    '/withdrawals/$type/$withdrawalid/decline',
    Authorization.EProductType.OnlineCasino
  ),
  retryWithdrawal: new WritePermission(
    '/withdrawals/$type/$withdrawalid/retry',
    Authorization.EProductType.OnlineCasino
  ),
  setManualWithdrawal: new WritePermission(
    '/withdrawals/$type/$withdrawalid/manual',
    Authorization.EProductType.OnlineCasino
  ),
  getWithdrawalsExports: new ReadPermission('/withdrawals/exports', Authorization.EProductType.OnlineCasino),
  getWithdrawalsExportFile: new ReadPermission(
    '/withdrawals/exports/$withdrawalid',
    Authorization.EProductType.OnlineCasino
  ),
  processWithdrawalsExport: new WritePermission(
    '/withdrawals/exports/process',
    Authorization.EProductType.OnlineCasino
  ),
  getWithdrawalsInCasino: new ReadPermission('/withdrawals/incasino', Authorization.EProductType.OnlineCasino),
  cancelWithdrawalInCasino: new WritePermission(
    '/withdrawals/incasino/$withdrawalid/cancel',
    Authorization.EProductType.OnlineCasino
  ),
  withdrawManually: new WritePermission('/withdrawals/manually', Authorization.EProductType.OnlineCasino),
  getWithdrawalsSummary: new ReadPermission('/withdrawals/summary', Authorization.EProductType.OnlineCasino),
  getWithdrawalDetailsV2: new ReadPermission('/v2/withdrawals/$withdrawalid', Authorization.EProductType.OnlineCasino),
  getWithdrawalPinCode: new ReadPermission(
    '/v2/withdrawals/$withdrawalid/pincode',
    Authorization.EProductType.OnlineCasino
  ),
  declineWithdrawalV2: new WritePermission(
    '/v2/withdrawals/$withdrawalid/decline',
    Authorization.EProductType.OnlineCasino
  ),
  completeWithdrawalManuallyV2: new WritePermission(
    '/v2/withdrawals/$withdrawalid/completemanually',
    Authorization.EProductType.OnlineCasino
  ),
  retryWithdrawalV2: new WritePermission(
    '/v2/withdrawals/$withdrawalid/retry',
    Authorization.EProductType.OnlineCasino
  ),
  processWithdrawalsV2: new WritePermission('/v2/withdrawals/process', Authorization.EProductType.OnlineCasino),
  getRequestedWithdrawalsV2: new ReadPermission(
    '/v2/withdrawals/requested/$type',
    Authorization.EProductType.OnlineCasino
  ),
  cancelFailedWithdrawalV2: new WritePermission(
    '/v2/withdrawals/$withdrawalid/cancel',
    Authorization.EProductType.OnlineCasino
  ),
  failWithdrawalV2: new WritePermission('/v2/withdrawals/$withdrawalid/fail', Authorization.EProductType.OnlineCasino),
  getFreeSpinsCampaigns: new ReadPermission(
    '/marketing/campaigns/freespincampaigns',
    Authorization.EProductType.OnlineCasino
  ),
  addFreeSpinsCampaign: new WritePermission(
    '/marketing/campaigns/freespincampaigns',
    Authorization.EProductType.OnlineCasino
  ),
  getFreeSpinsCampaignsV2: new ReadPermission(
    '/marketing/campaigns/v2/$providerid/freespincampaigns',
    Authorization.EProductType.OnlineCasino
  ),
  getFreeSpinsGameConfigurationsV2: new ReadPermission(
    '/marketing/campaigns/v2/freespinconfigurations/$providerid',
    Authorization.EProductType.OnlineCasino
  ),
  getCampaignsOverview: new ReadPermission('/marketing/campaigns', Authorization.EProductType.OnlineCasino),
  addCampaign: new WritePermission('/marketing/campaigns', Authorization.EProductType.OnlineCasino),
  getCampaign: new ReadPermission('/marketing/campaigns/$campaignid', Authorization.EProductType.OnlineCasino),
  updateCampaign: new WritePermission('/marketing/campaigns/$campaignid', Authorization.EProductType.OnlineCasino),
  getIndividualAction: new ReadPermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid',
    Authorization.EProductType.OnlineCasino
  ),
  updateIndividualAction: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid',
    Authorization.EProductType.OnlineCasino
  ),
  triggerIndividualAction: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid/process',
    Authorization.EProductType.OnlineCasino
  ),
  triggerIndividualActionAllAccounts: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid/processall',
    Authorization.EProductType.OnlineCasino
  ),
  triggerIndividualActionAllClosedAccounts: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid/processallclosed',
    Authorization.EProductType.OnlineCasino
  ),
  addIndividualAction: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual',
    Authorization.EProductType.OnlineCasino
  ),
  getCampaignActions: new ReadPermission('/marketing/campaigns/actions', Authorization.EProductType.OnlineCasino),
  getTemplateTypes: new ReadPermission('/marketing/campaigns/templatetypes', Authorization.EProductType.OnlineCasino),
  getDefaultTemplates: new ReadPermission('/marketing/campaigns/templates', Authorization.EProductType.OnlineCasino),
  addDefaultTemplate: new WritePermission('/marketing/campaigns/templates', Authorization.EProductType.OnlineCasino),
  getDefaultCampaignTemplate: new ReadPermission(
    '/marketing/campaigns/templates/$templatetype',
    Authorization.EProductType.OnlineCasino
  ),
  updateDefaultCampaignTemplate: new WritePermission(
    '/marketing/campaigns/templates/$templatetype',
    Authorization.EProductType.OnlineCasino
  ),
  deleteDefaultTemplate: new WritePermission(
    '/marketing/campaigns/templates/$templatetype',
    Authorization.EProductType.OnlineCasino
  ),
  addCampaignTemplate: new WritePermission(
    '/marketing/campaigns/$campaignid/templates',
    Authorization.EProductType.OnlineCasino
  ),
  getCampaignTemplate: new ReadPermission(
    '/marketing/campaigns/$campaignid/templates/$templatetype',
    Authorization.EProductType.OnlineCasino
  ),
  updateCampaignTemplate: new WritePermission(
    '/marketing/campaigns/$campaignid/templates/$templatetype',
    Authorization.EProductType.OnlineCasino
  ),
  deleteActionTemplate: new WritePermission(
    '/marketing/campaigns/$campaignid/templates/$templatetype',
    Authorization.EProductType.OnlineCasino
  ),
  getDocumentById: new ReadPermission('/content/document/$documentid', Authorization.EProductType.OnlineCasino),
  getGamesByQueryFilters: new ReadPermission('/content/games', Authorization.EProductType.OnlineCasino),
  updateGamesV2Order: new WritePermission('/content/gamesv2/order/$gamepkey', Authorization.EProductType.OnlineCasino),
  getGameCategories: new ReadPermission('/content/gamecategories', Authorization.EProductType.OnlineCasino),
  addGameCategory: new WritePermission('/content/gamecategories', Authorization.EProductType.OnlineCasino),
  getGameCategory: new ReadPermission(
    '/content/gamecategories/$gamecategoryid',
    Authorization.EProductType.OnlineCasino
  ),
  updateGameCategory: new WritePermission(
    '/content/gamecategories/$gamecategoryid',
    Authorization.EProductType.OnlineCasino
  ),
  removeGameCategory: new WritePermission(
    '/content/gamecategories/$gamecategoryid',
    Authorization.EProductType.OnlineCasino
  ),
  getGameProviders: new ReadPermission('/content/providers', Authorization.EProductType.OnlineCasino),
  addGameProvider: new WritePermission('/content/providers', Authorization.EProductType.OnlineCasino),
  getGameProvider: new ReadPermission('/content/providers/$providerid', Authorization.EProductType.OnlineCasino),
  updateGameProvider: new WritePermission('/content/providers/$providerid', Authorization.EProductType.OnlineCasino),
  getGameStudios: new ReadPermission('/content/gamestudios', Authorization.EProductType.OnlineCasino),
  addGameStudio: new WritePermission('/content/gamestudios', Authorization.EProductType.OnlineCasino),
  getGameStudio: new ReadPermission('/content/gamestudios/$gamestudioid', Authorization.EProductType.OnlineCasino),
  updateGameStudio: new WritePermission('/content/gamestudios/$gamestudioid', Authorization.EProductType.OnlineCasino),
  getGameThemes: new ReadPermission('/content/gamethemes', Authorization.EProductType.OnlineCasino),
  addGameTheme: new WritePermission('/content/gamethemes', Authorization.EProductType.OnlineCasino),
  getGameTheme: new ReadPermission('/content/gamethemes/$gamethemeid', Authorization.EProductType.OnlineCasino),
  updateGameTheme: new WritePermission('/content/gamethemes/$gamethemeid', Authorization.EProductType.OnlineCasino),
  deleteGameTheme: new WritePermission('/content/gamethemes/$gamethemeid', Authorization.EProductType.OnlineCasino),
  getGamesByQueryFiltersV2: new ReadPermission('/games', Authorization.EProductType.OnlineCasino),
  addGameV2: new WritePermission('/games', Authorization.EProductType.OnlineCasino),
  getGameV2: new ReadPermission('/games/$gamepkey', Authorization.EProductType.OnlineCasino),
  updateGameV2: new WritePermission('/games/$gamepkey', Authorization.EProductType.OnlineCasino),
  addGameConfiguration: new WritePermission('/games/$gamepkey/configurations', Authorization.EProductType.OnlineCasino),
  updateGameConfiguration: new WritePermission(
    '/games/$gamepkey/configurations/$gameconfigurationid',
    Authorization.EProductType.OnlineCasino
  ),
  getGameLobbies: new ReadPermission('/gamelobbies', Authorization.EProductType.OnlineCasino),
  addGameLobby: new WritePermission('/gamelobbies', Authorization.EProductType.OnlineCasino),
  getGameLobby: new ReadPermission('/gamelobbies/$gamelobbyid', Authorization.EProductType.OnlineCasino),
  updateGameLobby: new WritePermission('/gamelobbies/$gamelobbyid', Authorization.EProductType.OnlineCasino),
  archiveGameConfiguration: new WritePermission(
    '/games/$gamepkey/configurations/$gameconfigurationid/archive',
    Authorization.EProductType.OnlineCasino
  ),
  unarchiveGameConfiguration: new WritePermission(
    '/games/$gamepkey/configurations/$gameconfigurationid/unarchive',
    Authorization.EProductType.OnlineCasino
  ),
  doCacheBuster: new WritePermission('/content/cachebusters/$cachebustertype', Authorization.EProductType.OnlineCasino),
  setServiceContractVersion: new WritePermission(
    '/content/servicecontracts/version',
    Authorization.EProductType.OnlineCasino
  ),
  getAllMediaGalleryEntriesOfType: new ReadPermission('/content/mediagallery', Authorization.EProductType.OnlineCasino),
  uploadMediaGalleryEntry: new WritePermission('/content/mediagallery', Authorization.EProductType.OnlineCasino),
  deleteMediaGalleryEntry: new WritePermission(
    '/content/mediagallery/$mediagalleryentryid',
    Authorization.EProductType.OnlineCasino
  ),
  getContentFilters: new ReadPermission('/content/filters', Authorization.EProductType.OnlineCasino),
  createContentFilter: new WritePermission('/content/filters', Authorization.EProductType.OnlineCasino),
  getContentFilterById: new ReadPermission(
    '/content/filters/$contentfilterid',
    Authorization.EProductType.OnlineCasino
  ),
  updateContentFilter: new WritePermission(
    '/content/filters/$contentfilterid',
    Authorization.EProductType.OnlineCasino
  ),
  archiveContentFilter: new WritePermission(
    '/content/filters/$contentfilterid/archive',
    Authorization.EProductType.OnlineCasino
  ),
  getAgentTasks: new ReadPermission('/tasks', Authorization.EProductType.OnlineCasino),
  getInterventionNeeded: new ReadPermission('/tasks/interventions', Authorization.EProductType.OnlineCasino),
  getOpenAgentTasks: new ReadPermission('/tasks/open', Authorization.EProductType.OnlineCasino),
  resolveOpenAgentTask: new WritePermission('/tasks/$agenttaskid/resolve', Authorization.EProductType.OnlineCasino),
  resolveOpenAgentTaskV2: new WritePermission('/tasks/$agenttaskid/resolvev2', Authorization.EProductType.OnlineCasino),
  assignAgentTask: new WritePermission('/tasks/$agenttaskid/assign', Authorization.EProductType.OnlineCasino),
  unassignAgentTask: new WritePermission('/tasks/$agenttaskid/unassign', Authorization.EProductType.OnlineCasino),
  solveIntervention: new WritePermission(
    '/tasks/interventions/$interventionid/solve',
    Authorization.EProductType.OnlineCasino
  ),
  getVerifications: new ReadPermission('/tasks/verifications', Authorization.EProductType.OnlineCasino),
  getStartupSettings: new ReadPermission('/settings/startup', Authorization.EProductType.OnlineCasino),
  getIpWhitelist: new ReadPermission('/settings/fraud/ipwhitelist', Authorization.EProductType.OnlineCasino),
  addIpWhitelist: new WritePermission('/settings/fraud/ipwhitelist', Authorization.EProductType.OnlineCasino),
  getCasinos: new ReadPermission('/settings/casinos', Authorization.EProductType.OnlineCasino),
  getConfigurations: new ReadPermission('/settings/configurations', Authorization.EProductType.OnlineCasino),
  updateConfiguration: new WritePermission(
    '/settings/configurations/$configurationid',
    Authorization.EProductType.OnlineCasino
  ),
  getBonusRequestSimulation: new ReadPermission(
    '/settings/bonusrequestsimulation',
    Authorization.EProductType.OnlineCasino
  ),
  revealVaultDigit: new WritePermission(
    '/settings/revealvaultdigit/$vaultconfigid',
    Authorization.EProductType.OnlineCasino
  ),
  getAllCountries: new ReadPermission('/settings/countries', Authorization.EProductType.OnlineCasino),
  getPromotionNameConfigurations: new ReadPermission(
    '/settings/promotions/name',
    Authorization.EProductType.OnlineCasino
  ),
  updatePromotionNameConfigurations: new WritePermission(
    '/settings/promotions/name',
    Authorization.EProductType.OnlineCasino
  ),
  getDepositLimits: new ReadPermission('/depositlimits', Authorization.EProductType.OnlineCasino),
  getDepositLimitUpdateRequests: new ReadPermission('/depositlimits/requests', Authorization.EProductType.OnlineCasino),
  requestDepositLimitUpdate: new WritePermission('/depositlimits/requests', Authorization.EProductType.OnlineCasino),
  getDepositLimitsThatRequireManualApproval: new ReadPermission(
    '/depositlimits/requests/requires-manual-approval',
    Authorization.EProductType.OnlineCasino
  ),
  getActiveDepositLimitsForUser: new ReadPermission(
    '/depositlimits/active/$userid',
    Authorization.EProductType.OnlineCasino
  ),
  approveDepositLimitUpdateRequestManually: new WritePermission(
    '/depositlimits/requests/$requestid/approve',
    Authorization.EProductType.OnlineCasino
  ),
  declineDepositLimitUpdateRequestManually: new WritePermission(
    '/depositlimits/requests/$requestid/decline',
    Authorization.EProductType.OnlineCasino
  ),
  setDepositLimitUpdateRequestToRequireManualApproval: new WritePermission(
    '/depositlimits/requests/$requestid/requiresmanualapproval',
    Authorization.EProductType.OnlineCasino
  ),
  setMaximumDepositLimit: new WritePermission('/depositlimits/$userid/max', Authorization.EProductType.OnlineCasino),
  getMaximumDepositLimits: new ReadPermission('/depositlimits/$userid/max', Authorization.EProductType.OnlineCasino),
  cancelMaximumDepositLimit: new WritePermission(
    '/depositlimits/$userid/max/cancel',
    Authorization.EProductType.OnlineCasino
  ),
  getActiveMaximumDepositLimits: new ReadPermission(
    '/depositlimits/$userid/max/active',
    Authorization.EProductType.OnlineCasino
  ),
  getLoginSessionLimits: new ReadPermission('/loginsessionlimits', Authorization.EProductType.OnlineCasino),
  getLoginSessionLimitUpdateRequests: new ReadPermission(
    '/loginsessionlimits/requests',
    Authorization.EProductType.OnlineCasino
  ),
  requestLoginSessionLimitUpdate: new WritePermission(
    '/loginsessionlimits/requests',
    Authorization.EProductType.OnlineCasino
  ),
  getActiveLoginSessionLimitsForUser: new ReadPermission(
    '/loginsessionlimits/active/$userid',
    Authorization.EProductType.OnlineCasino
  ),
  approveLoginSessionLimitUpdateRequest: new WritePermission(
    '/loginsessionlimits/requests/$requestid/approve',
    Authorization.EProductType.OnlineCasino
  ),
  declineLoginSessionLimitUpdateRequest: new WritePermission(
    '/loginsessionlimits/requests/$requestid/decline',
    Authorization.EProductType.OnlineCasino
  ),
  setLoginSessionLimitUpdateRequestToRequireManualApproval: new WritePermission(
    '/loginsessionlimits/requests/$requestid/requiresmanualapproval',
    Authorization.EProductType.OnlineCasino
  ),
  setMaximumLoginSessionLimit: new WritePermission(
    '/loginsessionlimits/$userid/max',
    Authorization.EProductType.OnlineCasino
  ),
  getMaximumLoginSessionLimits: new ReadPermission(
    '/loginsessionlimits/$userid/max',
    Authorization.EProductType.OnlineCasino
  ),
  cancelMaximumLoginSessionLimit: new WritePermission(
    '/loginsessionlimits/$userid/max/cancel',
    Authorization.EProductType.OnlineCasino
  ),
  getActiveMaximumLoginSessionLimits: new ReadPermission(
    '/loginsessionlimits/$userid/max/active',
    Authorization.EProductType.OnlineCasino
  ),
  requestBalanceLimitUpdate: new WritePermission('/balancelimits/requests', Authorization.EProductType.OnlineCasino),
  getBalanceLimitUpdateRequests: new ReadPermission('/balancelimits/requests', Authorization.EProductType.OnlineCasino),
  getBalanceLimits: new ReadPermission('/balancelimits', Authorization.EProductType.OnlineCasino),
  getActiveBalanceLimitForUser: new ReadPermission(
    '/balancelimits/active/$userid',
    Authorization.EProductType.OnlineCasino
  ),
  approveBalanceLimitUpdateRequestManually: new WritePermission(
    '/balancelimits/requests/$requestid/approve',
    Authorization.EProductType.OnlineCasino
  ),
  declineBalanceLimitUpdateRequestManually: new WritePermission(
    '/balancelimits/requests/$requestid/decline',
    Authorization.EProductType.OnlineCasino
  ),
  setBalanceLimitUpdateRequestToRequireManualApproval: new WritePermission(
    '/balancelimits/requests/$requestid/requiresmanualapproval',
    Authorization.EProductType.OnlineCasino
  ),
  setMaximumBalanceLimit: new WritePermission('/balancelimits/$userid/max', Authorization.EProductType.OnlineCasino),
  getMaximumBalanceLimits: new ReadPermission('/balancelimits/$userid/max', Authorization.EProductType.OnlineCasino),
  cancelMaximumBalanceLimit: new WritePermission(
    '/balancelimits/$userid/max/cancel',
    Authorization.EProductType.OnlineCasino
  ),
  getActiveMaximumBalanceLimit: new ReadPermission(
    '/balancelimits/$userid/max/active',
    Authorization.EProductType.OnlineCasino
  ),
  orderBechargeVoucher: new WritePermission(
    '/vouchers/becharge/$bechargevoucherid/order',
    Authorization.EProductType.OnlineCasino
  ),
  getBechargeBalance: new ReadPermission('/vouchers/becharge/balance', Authorization.EProductType.OnlineCasino),
  getBechargeOrderDetails: new ReadPermission(
    '/vouchers/becharge/$bechargevoucherorderreference/details',
    Authorization.EProductType.OnlineCasino
  ),
  getBechargeProducts: new ReadPermission('/vouchers/becharge/products', Authorization.EProductType.OnlineCasino),
  getTransactionHistory: new ReadPermission('/vouchers/becharge/transactions', Authorization.EProductType.OnlineCasino),
  sendSystemEmail: new WritePermission('/systememail/send', Authorization.EProductType.OnlineCasino),
  getUserLoyaltyDetails: new ReadPermission('/loyalty/users/v2/$userid', Authorization.EProductType.OnlineCasino),
  awardLoyaltyPointsToUser: new WritePermission('/loyalty/users/v2/$userid', Authorization.EProductType.OnlineCasino),
  getLoyaltySpendablePointsTx: new ReadPermission(
    '/loyalty/users/v2/$userid/tx/spendable',
    Authorization.EProductType.OnlineCasino
  ),
  getLoyaltyStatusPointsTx: new ReadPermission(
    '/loyalty/users/v2/$userid/tx/status',
    Authorization.EProductType.OnlineCasino
  ),
  setLoyaltyRank: new WritePermission('/loyalty/users/v2/$userid/rank', Authorization.EProductType.OnlineCasino),
  getLoyaltyRankDistributions: new ReadPermission(
    '/loyalty/reporting/v2/ranks',
    Authorization.EProductType.OnlineCasino
  ),
  getLoyaltyRankUsers: new ReadPermission(
    '/loyalty/reporting/v2/ranks/$rankname',
    Authorization.EProductType.OnlineCasino
  ),
  getLoyaltyTopSavers: new ReadPermission('/loyalty/reporting/v2/top-savers', Authorization.EProductType.OnlineCasino),
  getLoyaltyTotalBalances: new ReadPermission(
    '/loyalty/reporting/v2/total-balances',
    Authorization.EProductType.OnlineCasino
  ),
  getLoyaltyBoosters: new ReadPermission('/loyalty/boosters', Authorization.EProductType.OnlineCasino),
  deleteLoyaltyBooster: new WritePermission('/loyalty/boosters/$boosterid', Authorization.EProductType.OnlineCasino),
  getAllLoyaltyShopItems: new ReadPermission('/loyalty/v2/shop', Authorization.EProductType.OnlineCasino),
  addLoyaltyShopItem: new WritePermission('/loyalty/v2/shop', Authorization.EProductType.OnlineCasino),
  updateLoyaltyShopItemV2: new WritePermission(
    '/loyalty/v2/shop/$loyaltyshopitemid',
    Authorization.EProductType.OnlineCasino
  ),
  addLoyaltyBooster: new WritePermission(
    '/loyalty/boosters/providers/$providerid/games/$gameid',
    Authorization.EProductType.OnlineCasino
  ),
  cancel: new WritePermission('/selfexclusions/$userid/cancel', Authorization.EProductType.OnlineCasino),
  getAllPlayingBreaks: new ReadPermission('/playingbreaks', Authorization.EProductType.OnlineCasino),
  getPlayingBreak: new ReadPermission('/playingbreaks/$userid', Authorization.EProductType.OnlineCasino),
  setPlayingBreak: new WritePermission('/playingbreaks/$userid', Authorization.EProductType.OnlineCasino),
  cancelPlayingBreak: new WritePermission('/playingbreaks/$userid', Authorization.EProductType.OnlineCasino),
  cancelPlayingBreakV2: new WritePermission('/playingbreaks/$userid/cancel', Authorization.EProductType.OnlineCasino),
  declinePlayingBreakCancellationRequest: new WritePermission(
    '/playingbreaks/$userid/cancel/decline',
    Authorization.EProductType.OnlineCasino
  ),
  forceCancelPlayingBreak: new WritePermission('/playingbreaks/$userid/force', Authorization.EProductType.OnlineCasino),
  concludeInvestigation: new WritePermission(
    '/playingbreaks/investigations/$userid/conclude',
    Authorization.EProductType.OnlineCasino
  ),
  getPromotionalCalendars: new ReadPermission('/promotional-calendars', Authorization.EProductType.OnlineCasino),
  addPromotionalCalendar: new WritePermission('/promotional-calendars', Authorization.EProductType.OnlineCasino),
  getPromotionalCalendarById: new ReadPermission(
    '/promotional-calendars/$calendarid',
    Authorization.EProductType.OnlineCasino
  ),
  updatePromotionalCalendar: new WritePermission(
    '/promotional-calendars/$calendarid',
    Authorization.EProductType.OnlineCasino
  ),
  getBoosterPacks: new ReadPermission('/booster-packs', Authorization.EProductType.OnlineCasino),
  addBoosterPack: new WritePermission('/booster-packs', Authorization.EProductType.OnlineCasino),
  updateBoosterPack: new WritePermission('/booster-packs/$boosterpackid', Authorization.EProductType.OnlineCasino),
  getBoosterPackCards: new ReadPermission('/booster-packs/cards', Authorization.EProductType.OnlineCasino),
  addBoosterPackCard: new WritePermission('/booster-packs/cards', Authorization.EProductType.OnlineCasino),
  updateBoosterPackCard: new WritePermission(
    '/booster-packs/cards/$boosterpackcardid',
    Authorization.EProductType.OnlineCasino
  ),
  getInterventions: new ReadPermission('/interventions', Authorization.EProductType.OnlineCasino),
  createInterventionForUser: new WritePermission(
    '/interventions/user/$userid',
    Authorization.EProductType.OnlineCasino
  ),
  getInterventionsForUser: new ReadPermission('/interventions/user/$userid', Authorization.EProductType.OnlineCasino),
  completeIntervention: new WritePermission(
    '/interventions/$interventionid/complete',
    Authorization.EProductType.OnlineCasino
  ),
  openIntervention: new WritePermission('/interventions/$interventionid/open', Authorization.EProductType.OnlineCasino),
  closeIntervention: new WritePermission(
    '/interventions/$interventionid/close',
    Authorization.EProductType.OnlineCasino
  ),
  getIntervention: new ReadPermission('/interventions/$interventionid', Authorization.EProductType.OnlineCasino),
  getGameSessions: new ReadPermission('/gamesessions', Authorization.EProductType.OnlineCasino),
  getGameSessionsActivity: new ReadPermission(
    '/gamesessions/activity/$userid',
    Authorization.EProductType.OnlineCasino
  ),
  getGameSessionSummaries: new ReadPermission('/gamesessions/summary', Authorization.EProductType.OnlineCasino),
  getMatches: new ReadPermission('/acuris/monitor/matches/$userid', Authorization.EProductType.OnlineCasino),
  addMatchReviews: new WritePermission(
    '/acuris/monitor/matches/$userid/reviews',
    Authorization.EProductType.OnlineCasino
  ),
  getMatchReviews: new ReadPermission(
    '/acuris/monitor/matches/$userid/reviews/$qrcode',
    Authorization.EProductType.OnlineCasino
  ),
  getIndividuals: new ReadPermission('/acuris/monitor/individuals', Authorization.EProductType.OnlineCasino),
  depseudonymizeString: new WritePermission('/cryptography/depseudonymize', Authorization.EProductType.OnlineCasino),
  depseudonymizeUid: new WritePermission('/cryptography/depseudonymize/uid', Authorization.EProductType.OnlineCasino),
  cancelWOKRecord: new WritePermission('/regulator/cdb/wok/cancel-record', Authorization.EProductType.OnlineCasino),
  sendWOKPausedDeliveryQueueEntries: new WritePermission(
    '/regulator/cdb/wok/send-paused-delivery',
    Authorization.EProductType.OnlineCasino
  ),
  sendTAXPausedDeliveryQueueEntries: new WritePermission(
    '/regulator/cdb/tax/send-paused-delivery',
    Authorization.EProductType.OnlineCasino
  ),
  getCasinosV2: new ReadPermission('/casinos', Authorization.EProductType.OnlineCasino),
  createCasino: new WritePermission('/casinos', Authorization.EProductType.OnlineCasino),
  archiveCasino: new WritePermission('/casinos/$casinoid/archive', Authorization.EProductType.OnlineCasino),
  updateCasino: new WritePermission('/casinos/$casinoid', Authorization.EProductType.OnlineCasino),
  uploadMediaGalleryEntryV2: new WritePermission('/mediagallery', Authorization.EProductType.OnlineCasino),
  retrieveMediaGalleryAssets: new ReadPermission('/mediagallery', Authorization.EProductType.OnlineCasino),
  retrieveValidationSettings: new ReadPermission(
    '/mediagallery/validationsettings/$mediagalleryentrytype',
    Authorization.EProductType.OnlineCasino
  ),
  retrieveMediaGalleryEntry: new ReadPermission(
    '/mediagallery/$mediagalleryentryid',
    Authorization.EProductType.OnlineCasino
  ),
  syncBlurredVersionGameImages: new WritePermission(
    '/mediagallery/blur/sync-gameimages',
    Authorization.EProductType.OnlineCasino
  ),
  profileLookup: new ReadPermission('/acuris/profile/$resourceid', Authorization.EProductType.OnlineCasino),
  resolveDetectedRisk: new WritePermission(
    '/acuris/risk/$agenttaskid/resolve',
    Authorization.EProductType.OnlineCasino
  ),
  getBoosts: new ReadPermission('/boosts', Authorization.EProductType.OnlineCasino),
  createGlobalBoost: new WritePermission('/boosts/global', Authorization.EProductType.OnlineCasino),
  getBoostUserStatesForUser: new ReadPermission('/boosts/user-states/$userid', Authorization.EProductType.OnlineCasino),
  createServiceContract: new WritePermission('/service-contracts', Authorization.EProductType.OnlineCasino),
  getServiceContracts: new ReadPermission('/service-contracts', Authorization.EProductType.OnlineCasino),
  getServiceContract: new ReadPermission('/service-contracts/$contractid', Authorization.EProductType.OnlineCasino),
  addVersionToServiceContract: new WritePermission(
    '/service-contracts/$contractid',
    Authorization.EProductType.OnlineCasino
  ),
  getServiceContractsForUser: new ReadPermission(
    '/service-contracts/user/$userid',
    Authorization.EProductType.OnlineCasino
  ),
  getServiceContractForUser: new ReadPermission(
    '/service-contracts/$contractid/user/$userid',
    Authorization.EProductType.OnlineCasino
  ),
  getCloudwatchAlarmActionsEnabled: new ReadPermission(
    '/killswitch/cloudwatchalarm/actions-enabled',
    Authorization.EProductType.OnlineCasino
  ),
  setCloudwatchAlarmActionsEnabled: new WritePermission(
    '/killswitch/cloudwatchalarm/actions-enabled',
    Authorization.EProductType.OnlineCasino
  ),
  getKillSwitchState: new ReadPermission('/killswitch', Authorization.EProductType.OnlineCasino),
  setKillSwitchState: new WritePermission('/killswitch', Authorization.EProductType.OnlineCasino),
  cancelFreeSpins: new WritePermission('/freespins/cancel/$freespinsid', Authorization.EProductType.OnlineCasino),
  updateFreeSpinsGameConfiguration: new WritePermission(
    '/freespins/configurations/$gameid/update',
    Authorization.EProductType.OnlineCasino
  ),
  syncEmailTemplates: new WritePermission('/trustpilot/sync-email-templates', Authorization.EProductType.OnlineCasino),
  getStoredEmailTemplates: new ReadPermission('/trustpilot/email-templates', Authorization.EProductType.OnlineCasino),
  retrieveDgojResponsibleGamingTestResponses: new ReadPermission(
    '/responsible-gaming-test/dgoj',
    Authorization.EProductType.OnlineCasino
  ),
  getReportToCruksUsers: new ReadPermission('/regulator/cruks/report-users', Authorization.EProductType.OnlineCasino),
  resolveReportToCRUKS: new WritePermission(
    '/regulator/cruks/report-users/$userid/resolve',
    Authorization.EProductType.OnlineCasino
  ),
  getAnnualUserReport: new ReadPermission(
    '/users/$userid/annual-report/$year',
    Authorization.EProductType.OnlineCasino
  ),
  getOrCreateAnnualUserReport: new WritePermission(
    '/users/$userid/annual-report/$year',
    Authorization.EProductType.OnlineCasino
  ),
};

export type OnlineCasinoPermissionTypes = keyof typeof OnlineCasinoPermissions;
