import { useMemo } from 'react';
import { EPaymentMethodSchema, EPaymentProviderSchema, PaymentMethodConfigurationsSchema } from '@greenisland/stores';

export default function useFilteredPaymentProviders(
  selectedMethod: EPaymentMethodSchema,
  paymentMethodConfigs: PaymentMethodConfigurationsSchema | undefined
) {
  return useMemo(() => {
    const allPaymentProviders = Object.values(EPaymentProviderSchema).filter(
      pp =>
        pp !== EPaymentProviderSchema.Green_Island &&
        pp !== EPaymentProviderSchema.StarCasino &&
        pp !== EPaymentProviderSchema.HiPayWallet
    ); // these are deprecated
    if (!selectedMethod) {
      return allPaymentProviders;
    }
    return allPaymentProviders?.filter(
      provider =>
        paymentMethodConfigs?.portalConfigurations
          .filter(config => config.method === selectedMethod)
          .some(config => config.possibleProviders.map(pp => pp.provider).includes(provider)) ||
        paymentMethodConfigs?.v1Configurations
          .filter(config => config.method === selectedMethod)
          .some(config => config.possibleProviders.map(pp => pp.provider).includes(provider)) ||
        paymentMethodConfigs?.v2Configurations
          .filter(config => config.method === selectedMethod)
          .some(config => config.possibleProviders.map(pp => pp.provider).includes(provider))
    );
  }, [paymentMethodConfigs, selectedMethod]);
}
