import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ErrorSchema,
  ETrustpilotErrorTypeSchema,
  TrustpilotEmailSyncBadRequestContentSchema,
  TrustpilotEmailTemplateSchema,
  TrustpilotInternalServerErrorContentSchema,
  useGetStoredEmailTemplates,
  useSyncEmailTemplates,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Sync } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, capitalize, Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { CheckboxIcon, DataGridContainer, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { TRUSTPILOT_ERROR_REASONS } from './Constants/TrustpilotErrorReasons';
import { usePermission } from '../../../../app/hooks';

const PAGE_SIZE = 25;

type Row = TrustpilotEmailTemplateSchema;

const Trustpilot = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const canReadEmailTemplates = usePermission(OnlineCasinoPermissions.getStoredEmailTemplates);
  const canSyncEmailTemplates = usePermission(OnlineCasinoPermissions.syncEmailTemplates);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [includeDefault, setIncludeDefault] = useState(false);

  const {
    data: templates,
    isLoading: isTemplatesLoading,
    isFetching,
    isError,
    refetch,
  } = useGetStoredEmailTemplates(
    {
      page,
      pageSize,
      includeDefault,
    },
    {
      query: { enabled: canReadEmailTemplates },
    }
  );

  const { mutate: syncEmailTemplates, isLoading } = useSyncEmailTemplates({
    mutation: {
      onSuccess: async () => {
        refetch();
        enqueueSnackbar(t('marketing.trustpilot.allEmailSync'), { variant: 'success' });
      },
      onError: (
        error: ErrorSchema | TrustpilotEmailSyncBadRequestContentSchema | TrustpilotInternalServerErrorContentSchema
      ) => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: ETrustpilotErrorTypeSchema | undefined = error.type;
            const translationKey = errorReason ? TRUSTPILOT_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else if ('invalidLanguage' in error && error?.invalidLanguage?.length) {
            enqueueSnackbar(
              <Trans
                i18nKey={t('marketing.trustpilot.incorrectTemplatesConfigurationError', {
                  templates: error?.invalidLanguage?.map(lang => lang).join(', '),
                })}
                components={{
                  br: <br />,
                }}
              />,
              {
                variant: 'error',
              }
            );
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'name',
        headerName: capitalize(t('name')),
        sortable: false,
        minWidth: 220,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },

      {
        field: 'isDefault',
        headerName: t('common.isDefaultTemplate'),
        type: 'boolean',
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'language',
        headerName: capitalize(t('language')),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'templateId',
        headerName: capitalize(t('common.templateId')),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      templates?.items?.map<TrustpilotEmailTemplateSchema>((template, index) => ({
        ...template,
        id: index,
      })) ?? [],
    [templates?.items]
  );

  return (
    <Stack spacing={2} mt={6}>
      <PermissionWrapper
        errorMessage={t('marketing.trustpilot.errors.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getStoredEmailTemplates}
      >
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontWeight={500} variant="body2" fontSize="x-large">
              {t('marketing.trustpilot.titles.storedTemplates')}
            </Typography>
            <Box>
              <FormControlLabel
                control={<Checkbox color="primary" size="small" />}
                value={includeDefault}
                onChange={(_, value) => setIncludeDefault(value)}
                label={t('common.showDefaultTemplates')}
                sx={{ '.MuiTypography-root': { fontWeight: includeDefault ? 600 : 400, mr: 2 } }}
              />
              {canSyncEmailTemplates && (
                <Tooltip title={t('marketing.trustpilot.description')} arrow placement="top">
                  <LoadingButton
                    variant="outlined"
                    onClick={() => syncEmailTemplates()}
                    loading={isLoading}
                    color="primary"
                    startIcon={<Sync />}
                  >
                    {t('marketing.trustpilot.titles.syncEmailTemplates')}
                  </LoadingButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={70}
              loading={isTemplatesLoading || isFetching}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={templates?.pagingDetails?.totalItems}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(page: number) => {
                if (!isLoading && !isFetching) {
                  setPage(page);
                }
              }}
              onPageSizeChange={setPageSize}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default Trustpilot;
