import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetUserVerificationsQueryKey,
  UserDocumentMetadataSchema,
  useUploadUserVerificationsFinancialDocument,
} from '@greenisland/stores';
import { useSnackbar } from 'notistack';

import { FileUploadProps } from '@greenisland-common/components/molecules/FileUpload/FileUpload';
import FileUploadDialog from '@greenisland-common/components/molecules/FileUpload/FileUploadDialog';

interface Props extends FileUploadProps {
  userId: string;
  metadata?: UserDocumentMetadataSchema;
}

const UploadFinancialVerificationField = ({ userId, defaultFile, metadata }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: uploadDocument } = useUploadUserVerificationsFinancialDocument({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  return (
    <FileUploadDialog
      id={userId}
      requirements={{ size: 5, mimeTypes: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'] }}
      saveFile={file => uploadDocument({ userId, data: file })}
      defaultFile={defaultFile}
      metadata={metadata}
    />
  );
};

export default UploadFinancialVerificationField;
