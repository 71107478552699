import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationMessageSchema } from '@greenisland/stores';
import { Box, Stack, Typography } from '@mui/material';
import { prettyPrintJson } from 'pretty-print-json';

interface Props {
  registrationMessage: RegistrationMessageSchema;
}

const RegistrationMessageDetailPanel = ({ registrationMessage }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{ justifyContent: 'justify-between', overflowY: 'hidden', overflowX: 'auto', height: '100%' }}
      direction="row"
      spacing={2}
    >
      {registrationMessage?.request ? (
        <Stack direction="column">
          <Typography>{t('tasks.verifications.registrationMessage.request')}</Typography>
          <Box
            component="pre"
            sx={{
              overflow: 'auto',
              '& .json-string': {
                whiteSpace: 'normal',
              },
            }}
            dangerouslySetInnerHTML={{
              __html: prettyPrintJson.toHtml(JSON.parse(registrationMessage.request), { linkUrls: true }),
            }}
          />
        </Stack>
      ) : null}
      {registrationMessage?.response ? (
        <Stack direction="column">
          <Typography>{t('tasks.verifications.registrationMessage.response')}</Typography>
          <Box
            component="pre"
            sx={{
              overflow: 'auto',
              '& .json-string': {
                whiteSpace: 'normal',
              },
            }}
            dangerouslySetInnerHTML={{
              __html: prettyPrintJson.toHtml(JSON.parse(registrationMessage.response), { linkUrls: true }),
            }}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};

export default RegistrationMessageDetailPanel;
