import '../../../../../../common/helpers/prettyPrintJson/prettyPrintCss.css';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader, CheckData, Link, ReactTableGI } from '@greenisland/components';
import {
  EDepositStatusSchema,
  EPaymentMethodSchema,
  EPaymentProviderSchema,
  getGetDepositDetailsQueryKey,
  useChargeBackDepositManually,
  useCompleteDepositManually,
  useConfirmDepositDisputeLost,
  useConfirmDepositDisputeWon,
  useFailDeposit,
  useGetDepositDetails,
  useMarkDepositSafe,
  useRefundDepositManually,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Stack, Tab, Tabs, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import { TabPanel } from '@greenisland-common/components/atoms';

import { prettyPrintJson } from '@greenisland-common/helpers';

import { formatCurrency, transformUnixDate } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../LookupPermissions';
import MarkAsSafeWithCrendetialsDialog from './MarkAsSafeWithCredentialsDialog';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    marginLeft: 24,
  },
  container: {
    display: 'grid',
    gridGap: '16px 16px',
    marginBottom: 16,
  },
  nickname: {
    fontSize: theme.typography.h5.fontSize,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontWeight: theme.typography.h5.fontWeight,
  },
  details: {
    fontSize: theme.typography.body1.fontSize,
    letterSpacing: theme.typography.body1.letterSpacing,
    fontWeight: theme.typography.body1.fontWeight,
    display: 'grid',
    margin: '32px 16px',
    gridGap: 32,
    textTransform: 'capitalize',
    gridTemplateColumns: 'auto auto auto auto',
    alignItems: 'start',
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  subDetails: {
    fontSize: theme.typography.caption.fontSize,
    letterSpacing: theme.typography.caption.letterSpacing,
    fontWeight: theme.typography.caption.fontWeight,
    fontStyle: 'italic',
  },
  actionButton: {
    alignSelf: 'flex-start',
    justifySelf: 'flex-start',
    width: '100%',
  },
  centered: {
    textAlign: 'center',
  },
  confirmButton: {
    variant: 'contained',
  },
  actions: {
    padding: '16px 24px',
  },
}));

const UserDepositDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const [isAddOpen, setIsAddOpen] = useState(false);

  const { userId = '', depositId = '' } = useParams();
  const [stream, setStream] = useState<number>(0);
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canCompleteDeposit = usePermission(OnlineCasinoPermissions.completeDepositManually);
  const canRefundDeposit = usePermission(OnlineCasinoPermissions.refundDepositManually);
  const canReadUserDepositDetails = usePermission(OnlineCasinoPermissions.getDepositDetails);
  const canConfirmDisputeWon = usePermission(OnlineCasinoPermissions.confirmDepositDisputeWon);
  const canConfirmDisputeLost = usePermission(OnlineCasinoPermissions.confirmDepositDisputeLost);
  const canChargeBackManually = usePermission(OnlineCasinoPermissions.chargeBackDepositManually);
  const canFailManually = usePermission(OnlineCasinoPermissions.failDeposit);
  const canMarkSafe = usePermission(OnlineCasinoPermissions.markDepositSafe);
  const { data: depositDetails, isLoading: isLoadingDepositDetails } = useGetDepositDetails(
    userId,
    depositId,
    undefined,
    {
      query: {
        enabled: canReadUserDepositDetails,
        onError: () => {
          enqueueSnackbar(t('deposits.errors.loadingDetails'), { variant: 'error' });
        },
      },
    }
  );
  const { mutate: completeManually, isLoading: isCompletingManually } = useCompleteDepositManually({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.completedManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.completingManually'), { variant: 'error' });
      },
    },
  });
  const { mutate: refundManually, isLoading: isRefundingManually } = useRefundDepositManually({
    mutation: {
      onSuccess: data => {
        if (data.success) {
          queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
          enqueueSnackbar(t('deposits.refundedManually'), { variant: 'success' });
        } else {
          enqueueSnackbar(`${t('deposits.errors.refundingManually')}: ${data.errorReason}`, { variant: 'error' });
        }
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.refundingManually'), { variant: 'error' });
      },
    },
  });
  const { mutate: confirmDisputeWon, isLoading: isConfirmingDisputeWon } = useConfirmDepositDisputeWon({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.confirmedDisputeWon'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.confirmingDisputeWon'), { variant: 'error' });
      },
    },
  });
  const { mutate: confirmDisputeLost, isLoading: isConfirmingDisputeLost } = useConfirmDepositDisputeLost({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.confirmedDisputeLost'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.confirmingDisputeLost'), { variant: 'error' });
      },
    },
  });
  const { mutate: chargeBackManually, isLoading: isChargingBackManually } = useChargeBackDepositManually({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.chargeBackManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.chargingBackManually'), { variant: 'error' });
      },
    },
  });
  const { isLoading: isMarkingSafe } = useMarkDepositSafe({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.markAsSafe'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.markAsSafe'), { variant: 'error' });
      },
    },
  });
  const { mutate: failManually, isLoading: isFailingManually } = useFailDeposit({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.failManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.failManually'), { variant: 'error' });
      },
    },
  });

  const isLoading =
    isLoadingDepositDetails ||
    isCompletingManually ||
    isRefundingManually ||
    isConfirmingDisputeWon ||
    isConfirmingDisputeLost ||
    isChargingBackManually ||
    isFailingManually ||
    isMarkingSafe;

  const getPrettyPrintValue = (value: string) => {
    try {
      return JSON.parse(value);
    } catch (e: any) {
      return value;
    }
  };
  const mutatedMessageData = useMemo(() => {
    if (!depositDetails?.messages || depositDetails.messages.length === 0) return undefined;
    const keys = Object.keys(depositDetails.messages[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'payload':
          return {
            Header: t(key),
            accessor: key,
            Cell: ({ value }: any) => {
              return (
                <pre
                  dangerouslySetInnerHTML={{ __html: prettyPrintJson(getPrettyPrintValue(value), { linkUrls: true }) }}
                />
              );
            },
            disableSortBy: true,
          };
        case 'timestamp':
          return transformUnixDate(key, t, true);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    const rows = depositDetails.messages.map(item => ({ ...item }));
    return { headers, rows };
  }, [depositDetails, t]);

  const mutatedEventStreamData = useMemo(() => {
    if (!depositDetails?.events || depositDetails.events.length === 0) return undefined;
    const keys = Object.keys(depositDetails.events[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'payload':
          return {
            Header: t(key),
            accessor: key,
            Cell: ({ value }: any) => {
              return (
                <pre
                  dangerouslySetInnerHTML={{ __html: prettyPrintJson(getPrettyPrintValue(value), { linkUrls: true }) }}
                />
              );
            },
            disableSortBy: true,
          };
        case 'timestamp':
          return transformUnixDate(key, t, true);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    const rows = depositDetails.events.map(item => ({ ...item }));
    return { headers, rows };
  }, [depositDetails, t]);

  const handleCompleteManually = () => {
    confirm({
      title: t('deposits.confirmations.complete.title'),
      description: t('deposits.confirmations.complete.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => completeManually({ userId, depositId }));
  };

  const handleRefundManually = (
    amount: number,
    paymentMethod: EPaymentMethodSchema,
    provider: EPaymentProviderSchema
  ) => {
    confirm({
      title: t('deposits.confirmations.refund.title'),
      description: t('deposits.confirmations.refund.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() =>
      refundManually({
        data: {
          amount: amount,
          currency: 'EUR',
          paymentMethod: paymentMethod,
          provider: provider,
          depositId,
        },
      })
    );
  };

  const handleChargeBackManually = () => {
    confirm({
      title: t('deposits.confirmations.chargeback.title'),
      description: t('deposits.confirmations.chargeback.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => chargeBackManually({ depositId }));
  };

  const handleDisputeWon = () => {
    confirm({
      title: t('deposits.confirmations.dispute.title'),
      description: t('deposits.confirmations.dispute.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => confirmDisputeWon({ depositId }));
  };

  const handleDisputeLost = () => {
    confirm({
      title: t('deposits.confirmations.dispute.title'),
      description: t('deposits.confirmations.dispute.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => confirmDisputeLost({ depositId }));
  };

  const handleFailManually = () => {
    confirm({
      title: t('deposits.confirmations.fail.title'),
      description: t('deposits.confirmations.fail.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => failManually({ depositId }));
  };

  const completeManuallyStatuses: EDepositStatusSchema[] = [
    EDepositStatusSchema.Started,
    EDepositStatusSchema.Pending,
    EDepositStatusSchema.Failed,
    EDepositStatusSchema.Declined,
    EDepositStatusSchema.Refused,
  ];
  const showCompleteManuallyButton =
    canCompleteDeposit && depositDetails?.status && completeManuallyStatuses.includes(depositDetails.status);

  const refundManuallyStatuses: EDepositStatusSchema[] = [EDepositStatusSchema.Refused];
  const showRefundManuallyButton =
    canRefundDeposit && depositDetails?.status && refundManuallyStatuses.includes(depositDetails.status);

  const disputeStatuses: EDepositStatusSchema[] = [
    EDepositStatusSchema.ChargedBack,
    EDepositStatusSchema.ChargedBackManually,
  ];
  const showConfirmDisputeWonButton =
    canConfirmDisputeWon && depositDetails?.status && disputeStatuses.includes(depositDetails.status);
  const showConfirmDisputeLostButton =
    canConfirmDisputeLost && depositDetails?.status && disputeStatuses.includes(depositDetails.status);

  const chargeBackManuallyStatuses: EDepositStatusSchema[] = [
    EDepositStatusSchema.Completed,
    EDepositStatusSchema.ManuallyCompleted,
  ];
  const showChargeBackManuallyButton =
    canChargeBackManually && depositDetails?.status && chargeBackManuallyStatuses.includes(depositDetails.status);
  const showMarkAsSafeButton = canMarkSafe && depositDetails?.suspiciousDepositFlags;
  const failManuallyStatuses: EDepositStatusSchema[] = [EDepositStatusSchema.Pending];
  const showFailManuallyButton =
    canFailManually && depositDetails?.status && failManuallyStatuses.includes(depositDetails.status);

  return (
    <div className={classes.container}>
      <Card>
        <CardHeader>{t('details')}</CardHeader>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={depositDetails}
            isLoading={isLoading}
            render={({ data }) => (
              <div className={classes.details}>
                <div>
                  <div className={classes.nickname}>
                    {canLookupUser ? (
                      <Link to={`../../users/${userId}/details`}>{data.nickname}</Link>
                    ) : (
                      <span>{data.nickname}</span>
                    )}
                  </div>
                  <div className={classes.subDetails}>
                    {t('depositId')} - {depositId}
                  </div>
                </div>
                <div>
                  <div>{formatCurrency(data.amount)}</div>
                  <div>
                    {data.provider} - {data.paymentMethod}
                  </div>
                </div>
                <div>
                  <div>{t('status')}:</div>
                  <b>{data.status}</b>
                  {data.suspiciousDepositFlags && (
                    <div>
                      <div>{t('suspisicousFlags')}:</div>
                      {data.suspiciousDepositFlags.map((e, i) => (
                        <div key={i}>
                          <b>{e}</b>
                          <br />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <div className={classes.centered}>{t('deposits.actions.title')}:</div>
                  <Stack direction="column" spacing={1} p={1}>
                    {showCompleteManuallyButton && (
                      <Button onClick={handleCompleteManually} variant="contained" className={classes.actionButton}>
                        {t('deposits.actions.complete')}
                      </Button>
                    )}
                    {showRefundManuallyButton && (
                      <Button
                        onClick={() =>
                          handleRefundManually(
                            data.amount,
                            data.paymentMethod as EPaymentMethodSchema,
                            data.provider as EPaymentProviderSchema
                          )
                        }
                        variant="contained"
                        className={classes.actionButton}
                      >
                        {t('deposits.actions.refund')}
                      </Button>
                    )}
                    {showConfirmDisputeWonButton && (
                      <Button onClick={handleDisputeWon} variant="contained" className={classes.actionButton}>
                        {t('deposits.actions.confirmDisputeWon')}
                      </Button>
                    )}
                    {showConfirmDisputeLostButton && (
                      <Button
                        onClick={handleDisputeLost}
                        variant="contained"
                        color="error"
                        className={classes.actionButton}
                      >
                        {t('deposits.actions.confirmDisputeLost')}
                      </Button>
                    )}
                    {showChargeBackManuallyButton && (
                      <Button onClick={handleChargeBackManually} variant="contained" className={classes.actionButton}>
                        {t('deposits.actions.chargeBackManually')}
                      </Button>
                    )}
                    {showMarkAsSafeButton && (
                      <Button onClick={() => setIsAddOpen(true)} variant="contained" className={classes.actionButton}>
                        {t('deposits.actions.markAsSafe')}
                      </Button>
                    )}
                    {showFailManuallyButton && (
                      <Button onClick={handleFailManually} variant="contained" className={classes.actionButton}>
                        {t('deposits.actions.failManually')}
                      </Button>
                    )}
                    <MarkAsSafeWithCrendetialsDialog
                      userId={userId}
                      depositId={depositId}
                      isOpen={isAddOpen}
                      onClose={() => setIsAddOpen(false)}
                    />
                    {!showCompleteManuallyButton &&
                      !showRefundManuallyButton &&
                      !showConfirmDisputeWonButton &&
                      !showConfirmDisputeLostButton &&
                      !showChargeBackManuallyButton &&
                      !showMarkAsSafeButton && (
                        <div className={classes.centered}>
                          <div className={classes.subDetails}>{t('deposits.actions.noActionsRequired')}</div>
                        </div>
                      )}
                  </Stack>
                </div>
              </div>
            )}
          />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={stream} onChange={(event, newValue) => setStream(newValue)}>
              <Tab label="Messages" value={0} />
              <Tab label="Events" value={1} />
            </Tabs>
          </Box>
          <TabPanel value={stream} index={0}>
            <CheckData
              fallbackText={t('noMessages')}
              data={mutatedMessageData}
              checkProperties={['headers', 'rows']}
              render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
            />
          </TabPanel>
          <TabPanel value={stream} index={1}>
            <CheckData
              fallbackText={t('noEvents')}
              data={mutatedEventStreamData}
              checkProperties={['headers', 'rows']}
              render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
            />
          </TabPanel>
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(UserDepositDetails);
