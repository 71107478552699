import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { getGetPaymentMethodQueryKey, useGetPaymentMethod, useUpdatePaymentMethodLimits } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useSnackbar } from 'notistack';

import { usePermission } from '../../../../../../app/hooks';
import PaymentMethodLimitsForm from './PaymentMethodLimitsForm';

const EditPaymentMethodLimits = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { paymentMethodId }: any = useParams();
  const queryClient = useQueryClient();
  const canAddPaymentMethodLimits = usePermission(OnlineCasinoPermissions.createPaymentMethodLimits);

  const { data: paymentMethod, isLoading: paymentMethodIsLoading } = useGetPaymentMethod(paymentMethodId);

  const limitId = useMemo(() => {
    if (paymentMethod && paymentMethod.limits) return paymentMethod.limits.id ?? 0;
    return 0;
  }, [paymentMethod]);

  const { mutate: updatePaymentMethodLimits, isLoading: updateIsLoading } = useUpdatePaymentMethodLimits({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        navigate(`../payment-methods/${paymentMethodId}`);
        queryClient.invalidateQueries(getGetPaymentMethodQueryKey(paymentMethodId));
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  return (
    <PaymentMethodLimitsForm
      canSubmitForm={canAddPaymentMethodLimits}
      loading={paymentMethodIsLoading || updateIsLoading}
      defaultLimits={paymentMethod?.limits}
      handleSubmitForm={data => updatePaymentMethodLimits({ paymentMethodId, limitId, data })}
    />
  );
};

export default memo(EditPaymentMethodLimits);
