import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { GameProvidersSchemaItem, useGetGameProviders } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, capitalize } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';

import { PermissionWrapper } from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { usePermission } from '../../../../app/hooks';

const PAGE_SIZE = 25;

const FreeSpinsCampaigns = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canReadGameProviders = usePermission(OnlineCasinoPermissions.getGameProviders);

  const { data, isLoading, isError } = useGetGameProviders({ query: { enabled: canReadGameProviders } });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const columns = useMemo<GridColumns<GameProvidersSchemaItem>>(
    () => [
      { field: 'providerName', headerName: capitalize(t('provider')), flex: 1 },
      {
        field: 'actions',
        headerName: capitalize(t('actions')),
        renderCell: ({ row }) => (
          <Button variant="outlined" size="small" onClick={() => navigate(`./${row?.providerId}`)}>
            {t('campaigns')}
          </Button>
        ),
        minWidth: 120,
      },
    ],
    [t, navigate]
  );

  const rows = useMemo(
    () =>
      data
        ?.filter(provider => provider.hasFreeSpins)
        ?.sort((a, b) => a?.providerName?.localeCompare(b?.providerName))
        ?.map(provider => ({ ...provider, id: provider.providerId })) ?? [],
    [data]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  return (
    <PermissionWrapper
      errorMessage={t('marketing.freeSpinsCampaigns.errors.fetchGameProvidersError')}
      isError={isError}
      isLoading={false}
      permission={OnlineCasinoPermissions.getGameProviders}
    >
      <LimitedDataGrid
        density="compact"
        autoHeight
        rowHeight={60}
        loading={isLoading}
        columns={columns}
        rows={rows}
        pagination
        page={page}
        pageSize={pageSize}
        paginationMode="client"
        components={{
          Footer: () => (
            <DataGridCustomPagination
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              count={data?.length ? Math.ceil(data.length / pageSize) : 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              paginationMode="client"
            />
          ),
        }}
      />
    </PermissionWrapper>
  );
};

export default memo(FreeSpinsCampaigns);
