import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import {
  ErrorSchema,
  getGetMultiplierTournamentWithFiltersQueryKey,
  useCreateMultiplierTournament,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Skeleton, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import { PermissionWrapper } from '@greenisland-common/components/atoms';

import {
  createMultiplierTournamentFormDefaultValues,
  CreateMultiplierTournamentSchemaForm,
  generateCreateMultiplierTournamentData,
  isPrizePoolEntryValid,
} from './helpers/MultiplierTournamentFormContext';
import MultiplierTournamentBetSettings from './MultiplierTournamentFormSections/MultiplierTournamentBetSettings';
import MultiplierTournamentContentFilterSettings from './MultiplierTournamentFormSections/MultiplierTournamentContentFilterSettings';
import MultiplierTournamentGeneralSettings from './MultiplierTournamentFormSections/MultiplierTournamentGeneralSettings';
import MultiplierTournamentPrizePoolSettings from './MultiplierTournamentFormSections/MultiplierTournamentPrizePoolSettings';

const CreateMultiplierTournament = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [prizePoolEntriesValidation, setPrizePoolEntriesValidation] = useState(true);

  const methods = useForm<CreateMultiplierTournamentSchemaForm>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: createMultiplierTournamentFormDefaultValues,
  });

  const { handleSubmit, reset } = methods;

  const {
    mutate: createMultiplierTournament,
    isLoading,
    isError,
  } = useCreateMultiplierTournament({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetMultiplierTournamentWithFiltersQueryKey());
        enqueueSnackbar(t('success'), { variant: 'success' });
        reset();
        navigate(`../multiplier-tournaments`);
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const onSubmit = (formData: CreateMultiplierTournamentSchemaForm) => {
    const validatePrizePoolEntries = isPrizePoolEntryValid(formData?.prizePool);
    setPrizePoolEntriesValidation(validatePrizePoolEntries);

    if (!validatePrizePoolEntries) {
      return;
    }

    const data = generateCreateMultiplierTournamentData(formData);
    createMultiplierTournament({ data });
  };

  if (isLoading) return <Skeleton variant="rectangular" height={600} />;

  return (
    <PermissionWrapper
      errorMessage={'contests.multiplierTournaments.permissions.createError'}
      isError={isError}
      isLoading={false}
      permission={OnlineCasinoPermissions.createMultiplierTournament}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <MultiplierTournamentGeneralSettings />
            <MultiplierTournamentPrizePoolSettings prizePoolEntriesValidation={prizePoolEntriesValidation} />
            <MultiplierTournamentBetSettings />
            <MultiplierTournamentContentFilterSettings />
          </Stack>
          <Button sx={{ mt: 4, px: 6, py: 2 }} type="submit" variant="contained" color="primary" disabled={isLoading}>
            {t('save')}
          </Button>
        </form>
      </FormProvider>
    </PermissionWrapper>
  );
};

export default CreateMultiplierTournament;
