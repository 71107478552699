import { memo, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  EPaymentCredentialWhitelistTypeSchema,
  getGetWhitelistedCredentialsQueryKey,
  useGetWhitelistedCredentials,
  useRemoveCredentialsFromWhitelist,
  useWhitelistCredentials,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import TabMenu, { Tab } from 'src/routes/components/TabMenu';

import { DataGridContainer, FontAwesomeIcon, SelectInput, StyledDataGrid } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import ConfirmDialog from '../../../../../../app/components/ConfirmDialog/ConfirmDialog';
import { usePermission } from '../../../../../../app/hooks';
import classes from './WhitelistedCredentials.module.css';

type WhitelistedCredentialsProps = {
  userId: string;
};

interface Credential {
  id?: number;
  value: string;
  type: EPaymentCredentialWhitelistTypeSchema;
}

const WhitelistedCredentialsCard = ({ userId }: WhitelistedCredentialsProps) => {
  const { t } = useTranslation();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [credential, setCredential] = useState<Credential>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<Credential>();
  const { register, handleSubmit, errors, control } = methods;

  const canReadWhiteListedCreds = usePermission(OnlineCasinoPermissions.getWhitelistedCredentials);
  const canAddWhiteListedCreds = usePermission(OnlineCasinoPermissions.whitelistCredentials);
  const canRemoveWhiteListedCreds = usePermission(OnlineCasinoPermissions.removeCredentialsFromWhitelist);

  const { data: cardData } = useGetWhitelistedCredentials(parseInt(userId), 'cardHolderName', {
    query: {
      enabled: canReadWhiteListedCreds,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });
  const { data: emailData } = useGetWhitelistedCredentials(parseInt(userId), 'email', {
    query: {
      enabled: canReadWhiteListedCreds,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });
  const { data: ibanData } = useGetWhitelistedCredentials(parseInt(userId), 'iban', {
    query: {
      enabled: canReadWhiteListedCreds,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });
  const { mutate: addWhitelistCredentials } = useWhitelistCredentials({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        invalideGetWhitelistedCredentials();
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });
  const { mutate: removeWhitelistCrendetials } = useRemoveCredentialsFromWhitelist({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        invalideGetWhitelistedCredentials();
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const invalideGetWhitelistedCredentials = () => {
    queryClient.invalidateQueries(getGetWhitelistedCredentialsQueryKey(parseInt(userId), 'iban'));
    queryClient.invalidateQueries(getGetWhitelistedCredentialsQueryKey(parseInt(userId), 'cardHolderName'));
    queryClient.invalidateQueries(getGetWhitelistedCredentialsQueryKey(parseInt(userId), 'email'));
  };

  const onAddCredential = (data: Credential) => {
    addWhitelistCredentials({ userId: parseInt(userId), whitelistType: data.type, data: [data.value] });
    setIsAddOpen(false);
  };

  const onRemoveCredential = (data?: Credential) => {
    if (data) {
      removeWhitelistCrendetials({ userId: parseInt(userId), whitelistType: data.type, data: [data.value] });
    }
  };

  const data = useMemo(() => {
    const result: Credential[] = [];
    cardData?.forEach(e => result.push({ id: result.length, value: e, type: 'cardHolderName' }));
    ibanData?.forEach(e => result.push({ id: result.length, value: e, type: 'iban' }));
    emailData?.forEach(e => result.push({ id: result.length, value: e, type: 'email' }));
    return result;
  }, [ibanData, emailData, cardData]);

  const columns = useMemo<GridColumns<Credential>>(() => {
    const headers: GridColumns<Credential> = [
      {
        headerName: t('value'),
        field: 'value',
        type: 'string',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('type'),
        field: 'type',
        type: 'string',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
    ];
    const deleteHeader = {
      field: 'actions',
      type: 'actions',
      minWidth: 50,
      maxWidth: 50,
      getActions: (params: GridRowParams<Credential>) => [
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faTrash} />}
          label={t('edit')}
          onClick={() => {
            setCredential(params.row);
            setIsRemoveOpen(true);
          }}
          key="remove"
        />,
      ],
    };

    return canRemoveWhiteListedCreds ? [...headers, deleteHeader] : headers;
  }, [t, canReadWhiteListedCreds, canRemoveWhiteListedCreds]);

  const tabs: Tab[] = useMemo(() => {
    return Object.values(EPaymentCredentialWhitelistTypeSchema).map(value => ({
      title: t(value),
      content: (
        <DataGridContainer>
          <StyledDataGrid
            density="compact"
            rows={data.filter(e => e.type == value)}
            columns={columns}
            showColumnRightBorder={false}
            disableDensitySelector
            disableSelectionOnClick
            hideFooterRowCount
            hideFooter
          />
        </DataGridContainer>
      ),
    }));
  }, [t, data]);

  return (
    <>
      <Card>
        <Box display="flex" justifyContent="space-between">
          <CardHeader title={t('whitelistedCredentialsCard.title')} />
          <Box display="flex" gap={1} flexWrap="wrap" justifyContent="flex-end">
            {canAddWhiteListedCreds && (
              <Button size="small" variant="contained" onClick={() => setIsAddOpen(true)}>
                {t('addWhitelistCredentialButton')}
              </Button>
            )}
          </Box>
        </Box>
        <CardContent>
          <TabMenu tabs={tabs} />
        </CardContent>
      </Card>
      <Dialog fullWidth open={isAddOpen} onClose={() => setIsAddOpen(false)}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onAddCredential)}>
            <DialogTitle>{t('addWhitelistCredential')}</DialogTitle>
            <DialogContent className={classes.content}>
              <Input
                name="value"
                label={t('value')}
                type={'text'}
                required
                error={!!errors.value}
                rules={{ min: 1 }}
                defaultValue={''}
              />
              <SelectInput
                name="type"
                label={t('type')}
                options={Object.values(EPaymentCredentialWhitelistTypeSchema).map(action => ({
                  label: action,
                  value: action,
                }))}
                defaultValue={EPaymentCredentialWhitelistTypeSchema.cardHolderName}
              />
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button color="secondary" variant="text" onClick={() => setIsAddOpen(false)}>
                {t('cancel')}
              </Button>
              <Button variant="contained" type="submit">
                {t('add')}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      <ConfirmDialog
        title={t('removeWhitelistCredential.confirmationTitle')}
        content={t('removeWhitelistCredential.confirmationDescription')}
        isOpen={isRemoveOpen}
        setIsOpen={setIsRemoveOpen}
        onConfirm={() => {
          onRemoveCredential(credential);
          setCredential(undefined);
        }}
      />
    </>
  );
};

export default memo(WhitelistedCredentialsCard);
