import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EAgentTaskTypeSchema } from '@greenisland/stores';
import { Box, capitalize, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';

import AgentTasksTableData from './AgentTasksTableData';

const AGENT_TASK_TYPE_KEY = 'agentTaskTypeKey';

const AgentTasksGlobalView = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const [chosenTypeFilter, setChosenTypeFilter] = useState<EAgentTaskTypeSchema | undefined>(() => {
    const filterTypeLocalStorage = localStorage.getItem(AGENT_TASK_TYPE_KEY) as EAgentTaskTypeSchema;
    return filterTypeLocalStorage || undefined;
  });

  const navigateToHandler = useCallback(
    (type?: EAgentTaskTypeSchema) => {
      if (!type) return navigate(`${pathname}`, { state: { name: 'Agent Tasks' } });
      return navigate(`${pathname}?type=${type}`, { state: { name: 'Agent Tasks' } });
    },
    [navigate, pathname]
  );

  const applyFilterHandler = useCallback(
    (type: EAgentTaskTypeSchema) => {
      localStorage.setItem(AGENT_TASK_TYPE_KEY, type);
      setChosenTypeFilter(type);
      navigateToHandler(type);
    },
    [navigateToHandler]
  );

  const resetLocalStorageAndFilterHandler = () => {
    localStorage.removeItem(AGENT_TASK_TYPE_KEY);
    setChosenTypeFilter(undefined);
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const typeFromParams = params.get('type') as EAgentTaskTypeSchema | null;

    if (typeFromParams) {
      localStorage.setItem(AGENT_TASK_TYPE_KEY, typeFromParams);
      setChosenTypeFilter(typeFromParams);
    } else if (chosenTypeFilter) {
      applyFilterHandler(chosenTypeFilter);
    } else {
      resetLocalStorageAndFilterHandler();
    }
  }, [chosenTypeFilter, applyFilterHandler, pathname, search]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <FormControl sx={{ mb: 2, minWidth: 180 }} size="small">
          <InputLabel id="filterByType">{capitalize(t('filterByType'))}</InputLabel>
          <Select
            id="filterByType"
            value={chosenTypeFilter || ''}
            label={capitalize(t('filterByType'))}
            onChange={event => navigateToHandler(event.target.value as EAgentTaskTypeSchema)}
            IconComponent={
              chosenTypeFilter
                ? () => (
                    <IconButton
                      sx={{ mr: 1 }}
                      size="small"
                      onClick={() => {
                        resetLocalStorageAndFilterHandler();
                        navigateToHandler();
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </IconButton>
                  )
                : undefined
            }
          >
            {Object.keys(EAgentTaskTypeSchema).map(key => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <AgentTasksTableData types={chosenTypeFilter ? [chosenTypeFilter] : undefined} />
    </>
  );
};

export default memo(AgentTasksGlobalView);
