import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMediaGalleryEntryTypeSchema } from '@greenisland/stores';
import { capitalize, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';

import DateTimeInput from '@greenisland-common/components/molecules/DateTimeInput/DateTimeInput';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';
import SelectInputDurationField from '@greenisland-common/components/organisms/SelectInputDurationField';

import { ContentFilterButton } from '../../../../../../Content/ContentFilter/components';
import { LoyaltyDependentIconType } from '../../../../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import IndividualActionFormListItem from '../../components/IndividualActionFormListItem';
import type { IndividualActionForm } from '../../context';
import BonusLoyaltyDependantSection from '../components/BonusLoyaltyDependantSection';
import {
  BonusLoyaltyDependentTypeEnum,
  useBonusDepositLoyaltyDependentOptions,
  useDepositBonusDurationValues,
} from '../helpers/DepositBonusFormContext';

const DepositBonusForm = () => {
  const { t } = useTranslation();
  const durationOptions = useDepositBonusDurationValues();

  const percentageFields = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.PERCENTAGE,
    'loyaltyDependentPercentageField'
  );
  const limitFields = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.LIMIT,
    'loyaltyDependentLimitField'
  );
  const playthroughFields = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.PLAYTHROUGH,
    'loyaltyDependentPlaythroughField'
  );

  const { control, register, watch, setValue, errors } = useFormContext<IndividualActionForm>();

  register('percentageLoyaltyDependentValue');
  register('limitLoyaltyDependentValue');
  register('playthroughLoyaltyDependentValue');

  const isEnabledDeposit = watch('enabledDeposit');

  const [isPercentageLoyaltyDependent, setIsPercentageLoyaltyDependent] = useState(
    !!watch('percentageIsLoyaltyDependent')
  );
  const [isLimitLoyaltyDependent, setIsLimitLoyaltyDependent] = useState(!!watch('limitIsLoyaltyDependent'));
  const [isPlaythroughLoyaltyDependent, setIsPlaythroughLoyaltyDependent] = useState(
    !!watch('playthroughIsLoyaltyDependent')
  );
  const [isAbsoluteExpiryDate, setIsAbsoluteExpiryDate] = useState(!!watch('depositBonusAbsoluteExpiryDate'));

  const [depositExpirationDuration, setDepositExpirationDuration] = useState(durationOptions[0]);
  const [playthroughExpirationDuration, setPlaythroughExpirationDuration] = useState(durationOptions[0]);

  useEffect(() => {
    if (!isEnabledDeposit) {
      setIsPercentageLoyaltyDependent(false);
      setIsLimitLoyaltyDependent(false);
      setIsPlaythroughLoyaltyDependent(false);
    }
  }, [isEnabledDeposit]);

  useEffect(() => {
    if (depositExpirationDuration) {
      register('depositBonusExpirationDurationSelectOption');
      setValue('depositBonusExpirationDurationSelectOption', depositExpirationDuration.value);
    }
    if (playthroughExpirationDuration) {
      register('depositBonusPlaythroughExpirationDurationSelectOption');
      setValue('depositBonusPlaythroughExpirationDurationSelectOption', playthroughExpirationDuration.value);
    }
  }, [depositExpirationDuration, playthroughExpirationDuration, register, setValue]);

  const handleLoyaltyDependentChange = (event: ChangeEvent<HTMLInputElement>, type: BonusLoyaltyDependentTypeEnum) => {
    if (type === BonusLoyaltyDependentTypeEnum.PERCENTAGE) {
      setIsPercentageLoyaltyDependent(event.target.checked);
      setValue('percentageIsLoyaltyDependent', event.target.checked);
    } else if (type === BonusLoyaltyDependentTypeEnum.LIMIT) {
      setIsLimitLoyaltyDependent(event.target.checked);
      setValue('limitIsLoyaltyDependent', event.target.checked);
    } else {
      setIsPlaythroughLoyaltyDependent(event.target.checked);
      setValue('playthroughIsLoyaltyDependent', event.target.checked);
    }
  };

  return (
    <IndividualActionFormListItem
      enabled={isEnabledDeposit}
      name="enabledDeposit"
      listItemText={t('depositBonus.mainTitle')}
    >
      <TextField
        size="small"
        key="depositBonusName"
        name="depositBonusName"
        helperText={
          errors['depositBonusName']
            ? capitalize(t('depositBonus.helpText.name.error'))
            : capitalize(t('depositBonus.helpText.name.default'))
        }
        error={Boolean(errors['depositBonusName'])}
        disabled={!isEnabledDeposit}
        inputRef={register({ required: isEnabledDeposit, maxLength: 256 })}
        type="text"
        required={isEnabledDeposit}
        label={capitalize(t('depositBonus.fields.name'))}
      />
      <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.bonusSettings'))}</Typography>
      <BonusLoyaltyDependantSection
        enabledDeposit={isEnabledDeposit}
        switchControlKey="percentageIsLoyaltyDependent"
        helpTextKey="depositBonus"
        name="percentageLoyaltyDependentValue"
        isExtendedLoyaltyDependentMode={isPercentageLoyaltyDependent}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.PERCENTAGE}
        mainLabel={t('depositBonus.fields.percentageIsLoyaltyDependent')}
        inputLabel={t('depositBonus.fields.percentage')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.PERCENTAGE}
        loyaltyDependentOptions={percentageFields}
      />
      <BonusLoyaltyDependantSection
        enabledDeposit={isEnabledDeposit}
        switchControlKey="limitIsLoyaltyDependent"
        helpTextKey="depositBonus"
        name="limitLoyaltyDependentValue"
        isExtendedLoyaltyDependentMode={isLimitLoyaltyDependent}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.LIMIT}
        mainLabel={t('depositBonus.fields.limitIsLoyaltyDependent')}
        inputLabel={t('depositBonus.fields.limit')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.EURO}
        loyaltyDependentOptions={limitFields}
      />
      <BonusLoyaltyDependantSection
        enabledDeposit={isEnabledDeposit}
        switchControlKey="playthroughIsLoyaltyDependent"
        helpTextKey="depositBonus"
        name="playthroughLoyaltyDependentValue"
        isExtendedLoyaltyDependentMode={isPlaythroughLoyaltyDependent}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.PLAYTHROUGH}
        mainLabel={t('depositBonus.fields.playthroughIsLoyaltyDependent')}
        inputLabel={t('depositBonus.fields.playthrough')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.BONUS}
        loyaltyDependentOptions={playthroughFields}
      />
      <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.contentSettings'))}</Typography>
      <ContentFilterButton control={control} name="depositBonusContentFilterId" />
      <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.imageSettings'))}</Typography>
      <MediaFieldsWithPreview
        control={control}
        size="small"
        key="depositBonusImages"
        name="depositBonusImages"
        error={Boolean(errors['depositBonusImages'])}
        type="text"
        required={false}
        label={capitalize(t('addImages'))}
        mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.BonusImage}
        isMultiSelect={true}
      />
      <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.timeSettings'))}</Typography>
      <SelectInputDurationField
        size="small"
        key="depositBonusExpirationDuration"
        name="depositBonusExpirationDuration"
        helperText={capitalize(t('depositBonus.helpText.depositExpirationDuration.default'))}
        inputRef={register({ required: isEnabledDeposit, min: 0, setValueAs: value => parseInt(value) })}
        inputProps={{ min: 0 }}
        error={Boolean(errors['depositBonusExpirationDuration'])}
        disabled={!isEnabledDeposit}
        type="number"
        required={isEnabledDeposit}
        label={capitalize(t('depositBonus.fields.depositExpirationDuration'))}
        chosenValue={depositExpirationDuration}
        options={durationOptions}
        onChange={setDepositExpirationDuration}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
      <SelectInputDurationField
        size="small"
        key="depositBonusPlaythroughExpirationDuration"
        name="depositBonusPlaythroughExpirationDuration"
        helperText={capitalize(t('depositBonus.helpText.playthroughExpirationDuration.default'))}
        inputRef={register({ required: isEnabledDeposit, min: 0, setValueAs: value => parseInt(value) })}
        inputProps={{ min: 0 }}
        error={Boolean(errors['depositBonusPlaythroughExpirationDuration'])}
        disabled={!isEnabledDeposit}
        type="number"
        required={isEnabledDeposit}
        label={capitalize(t('depositBonus.fields.playthroughExpirationDuration'))}
        chosenValue={playthroughExpirationDuration}
        options={durationOptions}
        onChange={setPlaythroughExpirationDuration}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
      <FormControlLabel
        control={<Checkbox color="primary" size="small" />}
        onChange={(_, value) => setIsAbsoluteExpiryDate(value)}
        label={t('depositBonus.fields.addAbsoluteExpiryDate')}
        checked={isAbsoluteExpiryDate}
        sx={{
          width: 'fit-content',
          '.MuiTypography-root': { fontWeight: isAbsoluteExpiryDate ? 600 : 400 },
        }}
      />
      {isAbsoluteExpiryDate && (
        <DateTimeInput
          name="depositBonusAbsoluteExpiryDate"
          label={capitalize(t('depositBonus.fields.absoluteExpiryDate'))}
          disabled={!isEnabledDeposit}
          required={isAbsoluteExpiryDate}
        />
      )}
    </IndividualActionFormListItem>
  );
};

export default DepositBonusForm;
