import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  ErrorSchema,
  FreeSpinArgumentsSchema,
  FreeSpinArgumentsSchemaGame,
  FreeSpinsGameConfigurationSchema,
  GameSchema,
  getGetFreeSpinsCampaignsV2QueryKey,
  getGetFreeSpinsGameConfigurationsV2QueryKey,
  useAddFreeSpinsCampaign,
  useGetFreeSpinsGameConfigurationsV2,
  useGetGameProvider,
  useGetGamesByQueryFilters,
  useUpdateFreeSpinsGameConfiguration,
} from '@greenisland/stores';
import { useDebouncedValue } from '@lilib/hooks';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import FreeSpinsConfigurationsFields from './FreeSpinsConfigurationsFields';

interface FreeSpinsCampaignPayload extends Omit<FreeSpinArgumentsSchema, 'game' | 'freeSpinsGameConfigurationFK'> {
  game: GameSchema;
  freeSpinsGameConfigurationFK: FreeSpinsGameConfigurationSchema;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const FreeSpinsCampaignDialog = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { providerId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: gameProvider, isLoading: isGameProviderLoading } = useGetGameProvider(providerId);

  const isProviderConfigured = !!gameProvider?.hasFreeSpinsConfiguration;

  const [gameSelectorValue, onGameSelectorValueChanged] = useState('');
  const debouncedGameSelectorValue = useDebouncedValue(gameSelectorValue, { wait: 200 });

  const {
    data: freeSpinsConfigurations,
    isLoading: areFreeSpinsConfigurationsLoading,
    isRefetching: areFreeSpinsConfigurationsRefetching,
  } = useGetFreeSpinsGameConfigurationsV2(providerId, {
    query: { enabled: isProviderConfigured },
  });

  const { data: games, isLoading: areGamesLoading } = useGetGamesByQueryFilters(
    {
      providerId: parseInt(providerId),
      searchString: debouncedGameSelectorValue[0],
    },
    {
      query: { enabled: !!providerId },
    }
  );

  const { mutate: addFreeSpinsCampaign, isLoading: isAddFreeCampaignsLoading } = useAddFreeSpinsCampaign({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetFreeSpinsCampaignsV2QueryKey(providerId));
        onClose();
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const { mutate: updateFreeSpinsCampaign, isLoading: isUpdateFreeCampaignsLoading } =
    useUpdateFreeSpinsGameConfiguration({
      mutation: {
        onSuccess: () => {
          enqueueSnackbar(t('success'), { variant: 'success' });
          queryClient.invalidateQueries(getGetFreeSpinsGameConfigurationsV2QueryKey(providerId));
        },
        onError: (error: ErrorSchema) => {
          enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
        },
      },
    });

  const methods = useForm<FreeSpinsCampaignPayload>({
    mode: 'onChange',
    shouldFocusError: true,
  });

  const { control, handleSubmit, register, errors } = methods;

  const onSubmit = (data: FreeSpinsCampaignPayload) => {
    const chosenGame: FreeSpinArgumentsSchemaGame | undefined =
      data?.game && data?.game?.gamePKey
        ? {
            id: data?.game?.gamePKey,
            name: data?.game?.gameName,
          }
        : undefined;

    const payload = {
      description: data.description,
      provider: providerId,
      game: chosenGame,
      amountOfSpins: data?.amountOfSpins,
      stakePerSpin: isProviderConfigured ? data?.freeSpinsGameConfigurationFK?.stakePerSpin : data.stakePerSpin,
      freeSpinsGameConfigurationFK: isProviderConfigured
        ? data?.freeSpinsGameConfigurationFK?.configurationId
        : undefined,
    };

    return addFreeSpinsCampaign({
      data: payload,
    });
  };

  return (
    <Dialog fullWidth open={isOpen}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{capitalize(t('addFreeSpinsCampaign'))}</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              rows={3}
              size="small"
              type="text"
              label={t('description')}
              name="description"
              inputRef={register({ required: true })}
              error={Boolean(errors.description)}
            />
            <Controller
              name="game"
              control={control}
              render={({ onChange, value }) => (
                <Autocomplete
                  sx={{ mt: 2 }}
                  id="game"
                  loading={areGamesLoading}
                  getOptionLabel={option => option.gameName}
                  isOptionEqualToValue={(option, value) => option === value}
                  options={games?.results ?? []}
                  renderInput={params => <TextField label={t('availableGames')} {...params} size="small" />}
                  onChange={(_, value) => onChange(value)}
                  onInputChange={(_, value) => onGameSelectorValueChanged(value)}
                  value={value || null}
                />
              )}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              size="small"
              type="text"
              label={t('amountOfSpins')}
              name="amountOfSpins"
              inputRef={register({
                required: true,
                valueAsNumber: true,
                pattern: { value: /^[0-9]+$/, message: t('invalidPattern', { pattern: '[0-9]' }) },
                min: { value: 1, message: t('amountValidationMessageZero') },
              })}
              error={Boolean(errors.amountOfSpins)}
            />
            <FreeSpinsConfigurationsFields
              isGameProviderLoading={isGameProviderLoading}
              isProviderConfigured={isProviderConfigured}
              freeSpinsConfigurations={freeSpinsConfigurations}
              areFreeSpinsConfigurationsLoading={areFreeSpinsConfigurationsLoading}
              areFreeSpinsConfigurationsRefetching={areFreeSpinsConfigurationsRefetching}
              updateFreeSpinsCampaign={updateFreeSpinsCampaign}
              isUpdateFreeCampaignsLoading={isUpdateFreeCampaignsLoading}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary" size="small">
              {t('close')}
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              size="small"
              loading={isAddFreeCampaignsLoading}
              disabled={
                isGameProviderLoading ||
                isAddFreeCampaignsLoading ||
                areFreeSpinsConfigurationsLoading ||
                isUpdateFreeCampaignsLoading ||
                areFreeSpinsConfigurationsRefetching
              }
            >
              {t('save')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default FreeSpinsCampaignDialog;
