import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CountryList } from '@greenisland/stores';
import { Autocomplete, TextField } from '@mui/material';

interface Props {
  countries: CountryList;
}

const IdentificationCountryOfOriginField = ({ countries }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="identificationCountryOfOrigin"
      control={control}
      render={props => {
        return (
          <Autocomplete
            fullWidth
            options={countries}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option: any) => (option.countryName ? option.countryName : '')}
            value={props.value}
            onChange={(e, value) => props.onChange(value)}
            renderInput={({ size, ...params }) => <TextField size="small" {...params} />}
          />
        );
      }}
    />
  );
};

export default IdentificationCountryOfOriginField;
