import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ERegulatorControllerBadRequestTypeSchema,
  RegulatorControllerBadRequestContentSchema,
  useGetUserRegistration,
  UserBGCRegistrationSchemaAllOf,
  UserCruksRegistrationSchema,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Skeleton, Tooltip } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPlain, ErrorCard, Link, NoData } from '@greenisland-common/components/atoms';

import GenericField from './fields/GenericField';
import { usePermission } from '../../../../../../app/hooks';
import { EpisBlockedGamesField, EpisBlockedSportsField } from './fields';

interface Props {
  userId: string;
}

const Regulator = ({ userId }: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState<ERegulatorControllerBadRequestTypeSchema | undefined>(undefined);

  const canReadRegistration = usePermission(OnlineCasinoPermissions.getUserRegistration);
  const { data, isLoading, isError } = useGetUserRegistration(userId, {
    query: {
      enabled: canReadRegistration,
      onError: async (error: Response) => {
        const errorResp: RegulatorControllerBadRequestContentSchema = await error.json();
        setError(errorResp.type);
      },
    },
  });

  const headers: GridColumns = [
    {
      headerName: '',
      field: 'title',
      renderCell: ({ value }) => (
        <Box color="array.headers" component="span">
          {capitalize(t(value))}
        </Box>
      ),
      flex: 1,
      minWidth: 130,
    },
    { headerName: '', field: 'value', renderCell: params => params.value, flex: 1, minWidth: 130 },
  ];

  const rows: GridRowsProp = useMemo(() => {
    if (!data) {
      return [];
    }
    if (data.regulator === 'BGC') {
      const registration = data.registration as UserBGCRegistrationSchemaAllOf;
      const response: {
        id: number;
        title: string;
        value: JSX.Element | string;
      }[] = [
        {
          id: 0,
          title: t('episBlockedSports'),
          value: <EpisBlockedSportsField registration={data.registration} />,
        },
        {
          id: 1,
          title: t('episBlockedGames'),
          value: <EpisBlockedGamesField registration={data.registration} />,
        },
        {
          id: 2,
          title: t('token'),
          value: registration.token ? (
            <Box component="span" textOverflow="ellipsis" overflow="hidden">
              {registration.token}
            </Box>
          ) : (
            '-'
          ),
        },
        {
          id: 3,
          title: t('playerState'),
          value: registration.playerState ? registration.playerState : '-',
        },
      ];
      return response;
    }
    if (data.regulator === 'KSA') {
      const dataKsa = data as UserCruksRegistrationSchema;
      const response: {
        id: number;
        title: string;
        value: JSX.Element | string;
      }[] = [
        {
          id: 0,
          title: t('allowedGames'),
          value: <GenericField value={dataKsa.registration.allowedGames} error={!dataKsa.registration.allowedGames} />,
        },
        {
          id: 1,
          title: t('allowedSports'),
          value: (
            <GenericField value={dataKsa.registration.allowedSports} error={!dataKsa.registration.allowedSports} />
          ),
        },
        {
          id: 2,
          title: t('crukscode'),
          value: dataKsa.cruksCode ? (
            <Tooltip title={dataKsa.cruksCode as string} arrow>
              <Box component="p" textOverflow="ellipsis" overflow="hidden" width="100%">
                {dataKsa.cruksCode}
              </Box>
            </Tooltip>
          ) : (
            '-'
          ),
        },
        {
          id: 3,
          title: t('registeredInCruks'),
          value: <GenericField value={dataKsa.isRegisteredInCruks} error={dataKsa.isRegisteredInCruks} />,
        },
      ];

      return response;
    }
    return [];
  }, [data, t]);

  if (!canReadRegistration) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={323} />;
  if (isError) return <ErrorCard sx={{ height: 323 }}>t(`regulator.${error}`)</ErrorCard>;

  return (
    <Card sx={{ pl: 0, pr: 0 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader title={t('regulator.title')} />
        {canReadRegistration && (
          <Button sx={{ mr: 2 }} size="small" variant="outlined" component={Link} to={`../registration`}>
            {t('seeMore')}
          </Button>
        )}
      </Box>
      <CardContent sx={{ pl: 0, pr: 0 }}>
        {rows.length > 0 ? (
          <DataGridContainer>
            <DataGridPlain columns={headers} rows={rows} />
          </DataGridContainer>
        ) : (
          <NoData />
        )}
      </CardContent>
    </Card>
  );
};

export default Regulator;
