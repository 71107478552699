import {
  EGameTypeSchema,
  GameArgumentsV2Schema,
  GameCategoriesSchema,
  GameProviderSchema,
  GameSchema,
  GameThemesSchema,
  TranslationsSchema,
} from '@greenisland/stores';

export interface GameForm
  extends Pick<
    GameArgumentsV2Schema,
    | 'gameName'
    | 'providerName'
    | 'gameStudioName'
    | 'urlName'
    | 'enabled'
    | 'featured'
    | 'exclusive'
    | 'isLive'
    | 'hasJackpot'
    | 'description'
    | 'backgroundUrl'
    | 'thumbnailSquareUrl'
    | 'thumbnailPortraitUrl'
    | 'thumbnailLandscapeUrl'
  > {
  providerFk: number | null;
  gameStudioFk: number | null;
  releaseDate: number | null;
  gameCategories: GameCategoriesSchema;
  gameThemes: GameThemesSchema;
  description: TranslationsSchema;
  activeGameConfigurationId: number | null;
  gameType: EGameTypeSchema | string;
}

export const defaultGameFormValue: GameForm = {
  gameName: '',
  providerFk: null,
  providerName: '',
  gameStudioFk: null,
  gameStudioName: '',
  urlName: '',
  enabled: false,
  featured: false,
  exclusive: false,
  releaseDate: null,
  isLive: false,
  hasJackpot: false,
  gameCategories: [],
  gameThemes: [],
  description: [],
  gameType: '',
  activeGameConfigurationId: null,
};

export const getDefaultGameFormValue = (
  game: GameSchema,
  gameCategories: GameCategoriesSchema,
  gameThemes: GameThemesSchema
): GameForm => ({
  ...game,
  providerFk: game.providerFk ? parseInt(game.providerFk) : null,
  providerName: game.providerName,
  gameStudioFk: game.gameStudioFk ? parseInt(game.gameStudioFk) : null,
  gameStudioName: '',
  releaseDate: game.releaseDate * 1000,
  isLive: game.isLive ?? false,
  hasJackpot: game.hasJackpot ?? false,
  gameCategories: gameCategories.reduce((acc, curr) => {
    if (game.gameCategoryIds?.includes(parseInt(curr.id))) return [...acc, curr];
    else return acc;
  }, [] as GameCategoriesSchema),
  gameThemes: gameThemes.reduce((acc, curr) => {
    if (game.gameThemeIds?.includes(parseInt(curr.gameThemeId ?? ''))) return [...acc, curr];
    else return acc;
  }, [] as GameThemesSchema),
  description: game.description ?? [],
  gameType: game.gameType ?? '',
  activeGameConfigurationId: game.activeGameConfiguration?.pkey ?? null,
  backgroundUrl: game.gameImages?.background,
  thumbnailSquareUrl: game.gameImages?.thumbnailSquare,
  thumbnailPortraitUrl: game.gameImages?.thumbnailPortrait,
  thumbnailLandscapeUrl: game.gameImages?.thumbnailLandscape,
});

export const gameFormToRequest = (
  { ...formData }: GameForm,
  gameProvider?: GameProviderSchema
): GameArgumentsV2Schema => ({
  ...formData,
  providerName: gameProvider?.providerName ?? '',
  gameStudioFk: formData.gameStudioFk ?? -1,
  providerFk: parseInt(gameProvider?.providerId ?? ''),
  gameStudioName: '',
  releaseDate: (formData.releaseDate ?? 0) / 1000,
  operatorId: 'A',
  gameType: formData.gameType as EGameTypeSchema,
  gameCategories: formData.gameCategories?.map(({ id }) => parseInt(id)) ?? [],
  gameThemes: formData.gameThemes?.map(({ gameThemeId }) => parseInt(gameThemeId ?? '')) ?? [],
  activeGameConfigurationId: formData.activeGameConfigurationId ?? undefined,
  description: formData.description,
});
