import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Checkbox } from '@greenisland/components';
import {
  ErrorSchema,
  getGetOpenAgentTasksQueryKey,
  ResolveDetectedRiskMutationBody,
  useResolveDetectedRisk,
} from '@greenisland/stores';
import { LoadingButton } from '@mui/lab';
import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

interface ResolveKYCRiskDialogProps {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  agentTaskId: string;
}

const ResolveKYCRiskDialog = memo(({ openDialog, setOpenDialog, agentTaskId }: ResolveKYCRiskDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { handleSubmit, register, errors } = useForm<ResolveDetectedRiskMutationBody>();

  const { mutate: resolveDetectedRiskMutation, isLoading } = useResolveDetectedRisk({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetOpenAgentTasksQueryKey());
        setOpenDialog(false);
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    },
  });
  const onSubmit = (data: ResolveDetectedRiskMutationBody) => {
    resolveDetectedRiskMutation({ data, agentTaskId });
  };

  return (
    <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>{capitalize(t('kyc.headers.resolveDetectedRisk'))}</DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Checkbox label={t('kyc.suspend')} color="primary" ref={register()} name="block" />
          <TextField
            fullWidth
            name="reason"
            label={t('reason')}
            inputRef={register({ required: t('reason') })}
            error={!!errors.reason}
            helperText={errors.reason?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
            {t('cancel')}
          </Button>
          <LoadingButton variant="contained" color="error" type="submit" disabled={isLoading}>
            {t('proceed')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default ResolveKYCRiskDialog;
