import React, { ComponentProps } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, TextField } from '@mui/material';

interface Props {
  listItems: string[];
  onChange: (listItems: string[]) => void;
  type?: ComponentProps<typeof TextField>['type'];
}

const EditableList = ({ listItems, onChange, type }: Props) => {
  return (
    <>
      {[...listItems, ''].map((listItem, index, array) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            type={type}
            tabIndex={index + 1}
            sx={{ display: 'flex' }}
            value={listItem}
            onChange={e => {
              const newListItems = index === listItems.length ? array : listItems;
              onChange(newListItems.map((value, i) => (index === i ? e.target.value : value)));
            }}
          />
          <IconButton
            color="error"
            sx={index === listItems.length ? { visibility: 'hidden' } : undefined}
            onClick={() => onChange(listItems.filter((_, i) => index !== i))}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Box>
      ))}
    </>
  );
};

export default EditableList;
