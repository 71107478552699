import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMediaGalleryEntryTypeSchema } from '@greenisland/stores';
import { Grid, Typography } from '@mui/material';

import MediaField from '@greenisland-common/components/molecules/MediaField/MediaField';

const images = {
  backgroundImage: EMediaGalleryEntryTypeSchema.GameBackground,
  thumbnailSquare: EMediaGalleryEntryTypeSchema.GameThumbnailSquare,
  thumbnailLandscape: EMediaGalleryEntryTypeSchema.GameThumbnailLandscape,
  thumbnailPortrait: EMediaGalleryEntryTypeSchema.GameThumbnailPortrait,
} as const;

const GameLobbyImages = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <>
      <Typography variant="h4">{t('content.games.gameLobbies.lobbyImages')}</Typography>
      <Grid container spacing={2}>
        {Object.entries(images).map(([name, type]) => (
          <Grid item xs={12} sm={6} key={name} display="flex" flexDirection="column" alignItems="center">
            <h6>{t(`content.games.gameLobbies.${name}`)}</h6>
            <MediaField control={control} name={`${name}Url`} mediaGalleryEntryType={type} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default GameLobbyImages;
