import { ECheckinControllerBadRequestTypeSchema } from '@greenisland/stores';

type ResetScanAttemptErrorReasons = {
  [key in ECheckinControllerBadRequestTypeSchema]: string;
};

export const RESET_SCAN_ATTEMPT__ERROR_REASONS: ResetScanAttemptErrorReasons = {
  [ECheckinControllerBadRequestTypeSchema.invalidUserId]: 'tasks.verifications.checkin.errors.invalidUserId',
  [ECheckinControllerBadRequestTypeSchema.invalidRequestBody]: 'tasks.verifications.checkin.errors.invalidRequestBody',
};
