import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { faBoxArchive, faLock, faLockOpen, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  AgentSchema,
  ErrorSchema,
  getGetListAgentsQueryKey,
  useDeleteAgent,
  useGetListAgents,
  useLockAgent,
  useUnlockAgent,
} from '@greenisland/stores';
import { AuthorizationPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import {
  CheckboxIcon,
  DataGridContainer,
  FontAwesomeIcon,
  Link,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import ConfirmDialog from '../../../../app/components/ConfirmDialog';
import { usePermission } from '../../../../app/hooks';

const Agents = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const canReadAgents = usePermission(AuthorizationPermissions.getListAgents);
  const canAddAgents = usePermission(AuthorizationPermissions.addAgent);
  const canReadAgentDetails = usePermission(AuthorizationPermissions.getAgentDetails);
  const canToggleLock = usePermission(AuthorizationPermissions.lockAgent);
  const canWriteAgent = usePermission(AuthorizationPermissions.updateAgent);
  const { data: agents, isLoading: isAgentsLoading, isError } = useGetListAgents({ query: { enabled: canReadAgents } });

  const [dialog, setDialog] = useState<{ isOpen: boolean; agentId?: string }>({ isOpen: false, agentId: undefined });

  const onSuccess = (message: string) => {
    queryClient.invalidateQueries(getGetListAgentsQueryKey());
    enqueueSnackbar(t(message), { variant: 'success' });
  };

  const onError = (error: ErrorSchema) => {
    enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
  };

  const { mutate: unlockAgent } = useUnlockAgent({
    mutation: {
      onSuccess: () => onSuccess(t('settings.management.agents.titles.unlockedSuccessfully')),
      onError,
    },
  });
  const { mutate: lockAgent } = useLockAgent({
    mutation: {
      onSuccess: () => onSuccess(t('settings.management.agents.titles.lockedSuccessfully')),
      onError,
    },
  });
  const { mutate: archiveAgent } = useDeleteAgent({
    mutation: {
      onSuccess: () => onSuccess(t('settings.management.agents.titles.archivedSuccessfully')),
      onError,
    },
  });

  const handleArchive = (agentId: string) => setDialog({ isOpen: true, agentId });

  const toggleLock = useCallback(
    (agentId: string, locked: boolean) => {
      locked ? unlockAgent({ agentId: agentId }) : lockAgent({ agentId: agentId });
    },
    [lockAgent, unlockAgent]
  );

  const columns = useMemo<GridColumns<AgentSchema>>(
    () => [
      {
        field: 'userId',
        headerName: capitalize(t('userId')),
        renderCell: params => <Typography variant="body2">{params.value}</Typography>,
        minWidth: 350,
        flex: 0.2,
      },
      {
        field: 'username',
        headerName: capitalize(t('username')),
        renderCell: params => <Typography variant="body2">{params.value}</Typography>,
        minWidth: 350,
        flex: 0.2,
      },
      {
        field: 'name',
        headerName: capitalize(t('name')),
        renderCell: params => <Typography variant="body2">{params.value}</Typography>,
        minWidth: 250,
        flex: 0.1,
      },
      {
        field: 'email',
        headerName: capitalize(t('email')),
        renderCell: params => <Typography variant="body2">{params.value}</Typography>,
        minWidth: 300,
        flex: 0.1,
      },
      {
        field: 'phone',
        headerName: capitalize(t('phone')),
        renderCell: params => <Typography variant="body2">{params.value}</Typography>,
        minWidth: 150,
        flex: 0.1,
      },
      {
        field: 'isAdmin',
        headerName: capitalize(t('isAdmin')),
        type: 'boolean',
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 130,
      },
      {
        field: 'isLocked',
        headerName: capitalize(t('isLocked')),
        type: 'boolean',
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 130,
      },
      {
        field: 'details',
        headerName: capitalize(t('details')),
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          if (canReadAgentDetails) {
            return <Link to={{ pathname: `${params.row.userId}` }}>{t('details')}</Link>;
          }
        },
        sortable: false,
        minWidth: 100,
      },
      {
        type: 'actions',
        headerName: 'actions',
        field: 'actions',
        minWidth: 200,
        flex: 0.2,
        getActions: params => [
          <Stack key="actions" direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Box sx={{ minWidth: '30px' }}>
              {canWriteAgent && (
                <Link to={`edit/${params.row.userId}`}>
                  <Tooltip title={t('edit')} key="details">
                    <GridActionsCellItem
                      title={t('edit')}
                      label={t('edit')}
                      icon={<FontAwesomeIcon icon={faPen} />}
                      key="edit"
                      size="medium"
                    />
                  </Tooltip>
                </Link>
              )}
            </Box>
            <Box sx={{ minWidth: '30px' }}>
              {canToggleLock && (
                <Tooltip title={params.row.isLocked ? t('locked') : t('unlocked')} key="details">
                  <GridActionsCellItem
                    title={params.row.isLocked ? t('locked') : t('unlocked')}
                    label={params.row.isLocked ? t('locked') : t('unlocked')}
                    icon={<FontAwesomeIcon icon={params.row.isLocked ? faLock : faLockOpen} />}
                    onClick={() => toggleLock(params.row.userId, !!params.row.isLocked)}
                    key="isLocked"
                    size="medium"
                    color="secondary"
                  />
                </Tooltip>
              )}
            </Box>
            <Box sx={{ minWidth: '30px' }}>
              {canWriteAgent && params.row.isLocked && (
                <Tooltip title={t('archive')} key="details">
                  <GridActionsCellItem
                    title={t('archive')}
                    label={t('archive')}
                    icon={<FontAwesomeIcon icon={faBoxArchive} sx={{ color: 'warning.main' }} />}
                    onClick={() => handleArchive(params.row.userId)}
                    key="archive"
                    size="medium"
                    color="error"
                  />
                </Tooltip>
              )}
            </Box>
          </Stack>,
        ],
      },
    ],
    [canReadAgentDetails, canToggleLock, canWriteAgent, t, toggleLock]
  );

  const rows = useMemo(
    () =>
      agents?.map(agent => {
        return { ...agent, id: agent.userId };
      }) || [],
    [agents]
  );

  if (isAgentsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {canAddAgents && (
        <Box mb={2}>
          <Button
            onClick={() => navigate(`add`)}
            variant="contained"
            size="medium"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            {t('addAgent')}
          </Button>
        </Box>
      )}
      <PermissionWrapper
        errorMessage={t('settings.management.agents.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={AuthorizationPermissions.getListAgents}
      >
        <DataGridContainer>
          <LimitedDataGrid density="compact" autoHeight loading={isAgentsLoading} rows={rows} columns={columns} />
        </DataGridContainer>
      </PermissionWrapper>
      <ConfirmDialog
        title={t('archiveAgent')}
        content={t('confirmArchiveAgentContent')}
        isOpen={dialog.isOpen}
        setIsOpen={(open: boolean) => setDialog({ ...dialog, isOpen: open })}
        onConfirm={() => archiveAgent({ agentId: dialog.agentId ?? '', params: { hard: false } })}
      />
    </>
  );
};

export default Agents;
