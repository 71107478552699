import { memo, MouseEvent, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  CountryList,
  ERegulatorSchema,
  EVerificationStatus,
  getGetUserVerificationsQueryKey,
  useDeleteUserVerificationsIdentityFile,
  useGetUserGeneral,
  useUpdateUserVerificationsIdentity,
  useUpdateUserVerificationsIdentityVerificationStatus,
  VerificationsSchemaIdentity,
} from '@greenisland/stores';
import { VerificationStatusArgumentsSchema } from '@greenisland/stores/dist/api/schemas';
import { VerificationsIdentityArgumentsSchema } from '@greenisland/stores/dist/api/schemas/verificationsIdentityArgumentsSchema';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Popper } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { DataGridPlain, DataGridPlainHeader, FontAwesomeIcon, FormError } from '@greenisland-common/components/atoms';
import { RowContainer } from '@greenisland-common/components/atoms/DataGridPlain/DataGridPlain';

import BirthdateField from './Fields/BirthdateField';
import GenericTextField from './Fields/GenericTextField';
import IdentificationCountryOfOriginField from './Fields/IdentificationCountryOfOriginField';
import IdentificationDateField from './Fields/IdentificationDateField';
import IdentificationNumberField from './Fields/IdentificationNumberField';
import NationalityField from './Fields/NationalityField';
import SexField from './Fields/SexField';
import UpdateVerificationStatusField from './Fields/UpdateVerificationStatusField';
import UploadIdentityFileField from './Fields/UploadIdentityFileField';
import { useTenantContext } from '../../../../../../../app/context/TenantContext';
import { usePermission } from '../../../../../../../app/hooks';
import useFeatureFlags from '../../../../../../../common/hooks/useFeatureFlags';
import ResetScanAttemptsFormDialog from '../../ResetScanAttemptsFormDialog';
import DeclineVerificationDialog from '../DeclineVerificationDialog';
import CheckList from './CheckList';
import useIdentityDefaultValues, { FormProps } from './useIdentityDefaultValues';

type IdentityProps = {
  identity: VerificationsSchemaIdentity;
  countries: CountryList;
};

const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    renderCell: ({ value }) => <DataGridPlainHeader value={value} />,
    width: 220,
  },
  { headerName: '', field: 'value', renderCell: ({ value }) => <RowContainer>{value}</RowContainer>, flex: 1 },
];

const Identity = ({ identity, countries }: IdentityProps) => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const tenant = useTenantContext();
  const canReadUserGeneral = usePermission(OnlineCasinoPermissions.getUserGeneral);
  const canWriteIdentityVerification = usePermission(OnlineCasinoPermissions.updateUserVerificationsIdentity);
  const canWriteIdentityVerificationStatus = usePermission(
    OnlineCasinoPermissions.updateUserVerificationsIdentityVerificationStatus
  );
  const isCheckinEnabled = useFeatureFlags('isCheckinEnabled');

  const [isResetScanAttemptsDialogOpen, setIsResetScanAttemptsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const mutation = {
    onSuccess: async () => {
      queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: async () => {
      enqueueSnackbar(t('error'), { variant: 'error' });
    },
  };

  const updateVerificationStatusMutation = useUpdateUserVerificationsIdentityVerificationStatus({
    mutation,
  });

  const { mutate: deleteVerificationFile } = useDeleteUserVerificationsIdentityFile({
    mutation,
  });

  const updateVerificationStatus = (values: VerificationStatusArgumentsSchema) => {
    updateVerificationStatusMutation.mutate({ userId, data: values });
  };

  const methods = useIdentityDefaultValues({ countries, identity });

  const { data: user } = useGetUserGeneral(userId, { query: { enabled: canReadUserGeneral && isCheckinEnabled } });

  const {
    mutate: updateIdentityVerification,
    data: updateIdentityVerificationResult,
    isLoading: isIdentityVerificationLoading,
  } = useUpdateUserVerificationsIdentity({
    mutation: {
      onSuccess: async data => {
        if (!data.error) {
          await queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
          enqueueSnackbar(t('success'), { variant: 'success' });
        } else {
          data.error.nonEditableRegistrationFields?.forEach(value => {
            switch (value) {
              case 'FirstName':
                return methods.setError('firstName', {});
              case 'LastName':
                return methods.setError('lastName', {});
              case 'BirthDate':
                return methods.setError('birthdate', {});
            }
          });
        }
      },
      onError: async () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const open = Boolean(anchorEl);
  const id = open ? 'checklist-popper' : undefined;
  const handleCheckListClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(anchorEl && event.currentTarget);

  const onSubmit = async (data: FormProps) => {
    const birthdate = format(data.birthdate, 'yyyy-MM-dd');
    const identificationExpiryDate = format(data.identificationExpiryDate, 'yyyy-MM-dd');
    const identificationCardDateOfIssue = data.identificationCardDateOfIssue
      ? format(new Date(data.identificationCardDateOfIssue), 'yyyy-MM-dd')
      : undefined;

    const transformedData: VerificationsIdentityArgumentsSchema = {
      ...data,
      birthdate,
      identificationExpiryDate,
      nationality: data.nationality.countryCode,
      identificationCountryOfOrigin: data.identificationCountryOfOrigin.countryCode,
      lastNamePrefix: data.lastNamePrefix || undefined,
      identificationCardNumber: data.identificationCardNumber || null,
      identificationCardPlaceOfIssue: data.identificationCardPlaceOfIssue || null,
      identificationCardDateOfIssue,
    };

    await updateIdentityVerification({ userId, data: transformedData });
    methods.reset(data);
  };

  const rows: GridRowsProp = [
    {
      id: 1,
      title: t('verificationStatus.title'),
      value: (
        <UpdateVerificationStatusField
          userId={userId}
          status={identity}
          updateStatus={updateVerificationStatusMutation}
        />
      ),
    },
    { id: 2, title: t('market'), value: identity.market },
    { id: 3, title: t('firstName'), value: <GenericTextField name="firstName" defaultValue={identity.firstName} /> },
    ...(tenant?.regulatorV2 === ERegulatorSchema.KSA
      ? [
          {
            id: 4,
            title: t('lastNamePrefix'),
            value: (
              <GenericTextField name="lastNamePrefix" defaultValue={identity.lastNamePrefix || ''} isRequired={false} />
            ),
          },
        ]
      : []),
    { id: 5, title: t('lastName'), value: <GenericTextField name="lastName" defaultValue={identity.lastName} /> },
    { id: 6, title: t('birthdate'), value: <BirthdateField identity={identity} /> },
    {
      id: 7,
      title: t('birthplace'),
      value: <GenericTextField name="birthplace" defaultValue={identity.birthplace} />,
    },
    { id: 8, title: t('nationality'), value: <NationalityField countries={countries} /> },
    { id: 9, title: t('sex'), value: <SexField identity={identity} /> },
    { id: 10, title: t('identificationNumber'), value: <IdentificationNumberField identity={identity} /> },
    {
      id: 11,
      title: t('identificationCountryOfOrigin'),
      value: <IdentificationCountryOfOriginField countries={countries} />,
    },
    {
      id: 12,
      title: t('identificationExpiryDate'),
      value: (
        <IdentificationDateField name="identificationExpiryDate" defaultValue={identity.identificationExpiryDate} />
      ),
    },
    ...(tenant?.regulatorV2 === ERegulatorSchema.KSA
      ? [
          {
            id: 13,
            title: t('identificationCardNumber'),
            value: (
              <GenericTextField
                name="identificationCardNumber"
                defaultValue={identity.identificationCardNumber || ''}
                isRequired={true}
              />
            ),
          },
        ]
      : []),
    ...(tenant?.regulatorV2 === ERegulatorSchema.KSA
      ? [
          {
            id: 14,
            title: t('identificationCardPlaceOfIssue'),
            value: (
              <GenericTextField
                name="identificationCardPlaceOfIssue"
                defaultValue={identity.identificationCardPlaceOfIssue || ''}
                isRequired={true}
              />
            ),
          },
        ]
      : []),
    ...(tenant?.regulatorV2 === ERegulatorSchema.KSA
      ? [
          {
            id: 15,
            title: t('identificationCardDateOfIssue'),
            value: (
              <IdentificationDateField
                name="identificationCardDateOfIssue"
                defaultValue={identity.identificationCardDateOfIssue || ''}
              />
            ),
          },
        ]
      : []),
    {
      id: 16,
      title: t('idFront'),
      value: (
        <Box display="flex" gap={1}>
          <UploadIdentityFileField
            id="idFront"
            userId={userId}
            fileType="idfront"
            metadata={identity?.idFrontDocumentMetadata}
          />
          {identity?.idFrontDocumentMetadata && (
            <Button
              size="small"
              onClick={() => deleteVerificationFile({ fileType: 'idfront', userId })}
              color="error"
              variant="outlined"
            >
              {t('remove')}
            </Button>
          )}
        </Box>
      ),
    },
    {
      id: 17,
      title: t('idBack'),
      value: (
        <Box display="flex" gap={1}>
          <UploadIdentityFileField
            id="idBack"
            userId={userId}
            fileType="idback"
            metadata={identity?.idBackDocumentMetadata}
          />
          {identity?.idBackDocumentMetadata && (
            <Button
              size="small"
              onClick={() => deleteVerificationFile({ fileType: 'idback', userId })}
              color="error"
              variant="outlined"
            >
              {t('remove')}
            </Button>
          )}
        </Box>
      ),
    },
    {
      id: 18,
      title: t('passport'),
      value: (
        <Box display="flex" gap={1}>
          <UploadIdentityFileField
            id="passport"
            userId={userId}
            fileType="passport"
            metadata={identity?.passportDocumentMetadata}
          />
          {identity?.passportDocumentMetadata && (
            <Button
              size="small"
              onClick={() => deleteVerificationFile({ fileType: 'passport', userId })}
              color="error"
              variant="outlined"
            >
              {t('remove')}
            </Button>
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card sx={{ pl: 0, pr: 0, mt: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <CardHeader title={t('identity')} />
                <Button onClick={handleCheckListClick} variant="text" aria-describedby={id}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Button>
                <Popper id={id} open={open} anchorEl={anchorEl}>
                  <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', padding: 0 }}>
                    <CheckList />
                  </Box>
                </Popper>
              </Box>
              {isCheckinEnabled && (
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ mr: 2 }}
                  onClick={() => setIsResetScanAttemptsDialogOpen(true)}
                >
                  {t('tasks.verifications.checkin.titles.resetScanAttempts')}
                </Button>
              )}
            </Box>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <DataGridPlain columns={headers} rows={rows} getRowHeight={() => 'auto'} />
              <FormError
                sx={{ ml: 2, mr: 2 }}
                placeholderSx={{ minHeight: 0 }}
                error={Boolean(updateIdentityVerificationResult?.error)}
                message={t('lookup.verification.identity.notAllowedToUpdateError', {
                  regulator: identity.market,
                  fields: updateIdentityVerificationResult?.error?.nonEditableRegistrationFields?.join(', '),
                })}
              />
            </CardContent>
            <CardActions>
              {canWriteIdentityVerification && (
                <Button
                  disabled={!methods.formState.isDirty}
                  color="primary"
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  {isIdentityVerificationLoading ? <CircularProgress size={20} /> : t('save')}
                </Button>
              )}
              {identity.verificationStatus === EVerificationStatus.WaitingForApproval &&
                canWriteIdentityVerificationStatus && (
                  <>
                    <Button
                      disabled={methods.formState.isDirty}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => updateVerificationStatus({ verificationStatus: EVerificationStatus.Verified })}
                    >
                      {updateVerificationStatusMutation.isLoading ? <CircularProgress size={20} /> : t('verify')}
                    </Button>
                    <Button variant="outlined" color="error" size="small" onClick={() => setOpenDialog(true)}>
                      {t('decline')}
                    </Button>
                    {methods.formState.isDirty && <Box sx={{ ml: 2 }}>{t('saveChangesDirty')}</Box>}
                  </>
                )}
            </CardActions>
          </Card>
          <DeclineVerificationDialog
            open={openDialog}
            setOpen={setOpenDialog}
            action={updateVerificationStatus}
            identity={identity}
          />
        </form>
      </FormProvider>
      <ResetScanAttemptsFormDialog
        onClose={() => {
          setIsResetScanAttemptsDialogOpen(false);
        }}
        open={isResetScanAttemptsDialogOpen}
        userId={user?.userId}
        email={user?.email}
      />
    </>
  );
};

export default memo(Identity);
