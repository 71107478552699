import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, CheckData, Loading, ReactTableGI } from '@greenisland/components';
import { actionCreators, GraphDataPoint } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import makeStyles from '@mui/styles/makeStyles';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import {
  formatCurrency,
  formatDecimal,
  getDateTimeFromUnix,
  transformCurrency,
  transformDecimal,
} from '../../../../../../../app/helpers/transformFunctions';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridGap: '15px 15px',
    marginBottom: 15,
  },
});

const AccountStatistics = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const dispatch = useAppDispatch();
  const { data: userAccountStatistics, fetching } = useAppSelector(state => state.userAccountStatistics);

  useEffect(() => {
    dispatch(actionCreators.getUserAccountStatistics(userId));
  }, [dispatch, userId]);

  const rowColHeaders = [
    { id: 'title', accessor: 'title', disableFilters: true, disableSortBy: true },
    { id: 'value', accessor: 'value', disableFilters: true, disableSortBy: true },
  ];

  const mutatedBankWithdrawalSummary = () => {
    if (!userAccountStatistics) return undefined;
    const rows = Object.entries(userAccountStatistics.bankWithdrawalSummary).map(item => {
      const [title, value] = item;
      switch (title) {
        case 'nbOfProcessedWithdrawals':
        case 'nbOfCancelledWithdrawals':
          return { title: t(title), value: formatDecimal(value) };
        case 'withdrawalCancelPercentage':
          return {
            title: t(title),
            value: `${formatDecimal(Math.round((value + Number.EPSILON) * 100) / 100)} %`,
          };
        default:
          return { title: t(title), value: formatCurrency(value as number) };
      }
    });
    return { rowColHeaders, rows };
  };

  const mutatedDepositsSummary = () => {
    if (!userAccountStatistics) return undefined;
    const rows: any = [];
    Object.entries(userAccountStatistics.depositsSummary).forEach(item => {
      const [title, value] = item;
      switch (title) {
        case 'payoutOverTime':
          rows.push({
            title: t(title),
            value: `${formatDecimal(Math.round((value + Number.EPSILON) * 100) / 100)} %`,
          });
          break;
        case 'cashOutRatio':
          rows.push({
            title: t(title),
            value: `${formatDecimal(Math.round((value + Number.EPSILON) * 100) / 100)} %`,
          });
          break;
        case 'cashedBonus':
        case 'averageDeposits':
          rows.push({ title: t(title), value: formatCurrency(value as number) });
          break;
        case 'nbOfDeposits':
        case 'maxDeposits':
        case 'minDeposits':
          rows.push({ title: t(title), value: formatDecimal(value as number) });
          break;
      }
    });
    return { rowColHeaders, rows };
  };

  const mutatedPromoSummary = () => {
    if (!userAccountStatistics) return undefined;
    const rows = Object.entries(userAccountStatistics.promoSummary).map(item => {
      const [title, value] = item;
      switch (title) {
        case 'totalAmountActivatedPromos':
        case 'totalAmountPromoToCash':
          return { title: t(title), value: formatCurrency(value) };
        default:
          return { title: t(title), value: formatDecimal(value) };
      }
    });
    return { rowColHeaders, rows };
  };

  const mutatedTransactionsLifetime = () => {
    if (!userAccountStatistics || userAccountStatistics.transactionsLifetime.length === 0) return undefined;
    const entries = Object.entries(userAccountStatistics.transactionsLifetime[0]);
    const headers: any = entries.map(([key]) => {
      switch (key) {
        case 'cash':
        case 'promo':
          return transformCurrency(key, t);
        case 'count':
          return transformDecimal(key, t);
        default:
          return { Header: t(key), accessor: key };
      }
    });
    const rows = userAccountStatistics.transactionsLifetime;

    return { headers, rows };
  };

  const constructTransactionsData = (data: Array<GraphDataPoint>) => {
    if (!data || data.length === 0) return null;

    const dataToRender = data.map(entry => {
      const chartData = { date: '', netDeposit: 0 };
      chartData.date = getDateTimeFromUnix(entry.date);
      chartData.netDeposit = entry.value;
      return chartData;
    });

    return (
      <Card>
        <CardHeader>{t('netDeposits')}</CardHeader>
        <CardBody>
          <ResponsiveContainer width="100%" minHeight={300}>
            <LineChart data={dataToRender}>
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis height={50} dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="linear" dataKey="netDeposit" stroke="#2bcf6d" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </CardBody>
      </Card>
    );
  };

  return (
    <div className={classes.container}>
      {fetching ? (
        <Loading center />
      ) : (
        <>
          {userAccountStatistics &&
            userAccountStatistics.depositsSummary.netDepositOverTime &&
            constructTransactionsData(userAccountStatistics.depositsSummary.netDepositOverTime)}
          <Card>
            <CardHeader>{t('depositsSummary')}</CardHeader>
            <CardBody>
              <CheckData
                fallbackText={t('noData')}
                data={mutatedDepositsSummary()}
                checkProperties={['headers', 'rows']}
                render={({ data }) => (
                  <ReactTableGI columns={data.rowColHeaders} data={data.rows} rowColTable noTableHeader />
                )}
              />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>{t('bankWithdrawalSummary')}</CardHeader>
            <CardBody>
              <CheckData
                fallbackText={t('noData')}
                data={mutatedBankWithdrawalSummary()}
                checkProperties={['headers', 'rows']}
                render={({ data }) => (
                  <ReactTableGI columns={data.rowColHeaders} data={data.rows} rowColTable noTableHeader />
                )}
              />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>{t('promoSummary')}</CardHeader>
            <CardBody>
              <CheckData
                fallbackText={t('noData')}
                data={mutatedPromoSummary()}
                checkProperties={['headers', 'rows']}
                render={({ data }) => (
                  <ReactTableGI columns={data.rowColHeaders} data={data.rows} rowColTable noTableHeader />
                )}
              />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>{t('transactionsLifetime')}</CardHeader>
            <CardBody>
              <CheckData
                fallbackText={t('noData')}
                data={mutatedTransactionsLifetime()}
                checkProperties={['headers', 'rows']}
                render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable />}
              />
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default memo(AccountStatistics);
