import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Card, CardBody, CheckData, Link, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  formatCurrency,
  NoWrapSpan,
  setInvisible,
  transformDefault,
  transformStatus,
  transformUnixDate,
} from '../../../../../../app/helpers/transformFunctions';
import { EventStreamParamsType } from '../Constants/Eventstreams';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 1fr)',
      gridGap: '15px 15px',
      marginBottom: 15,
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    bold: {
      fontWeight: 'bold',
    },
  })
);

const DepositBonusesPage = () => {
  // TODO: add permission
  return <DepositBonusesPageInnerChild />;
};

const DepositBonusesPageInnerChild = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchDepositBonuses = async (startDate: number, endDate: number) =>
    dispatch(actionCreators.getUserDepositBonuses(userId as string, startDate, endDate));
  const { data: depositBonuses, fetching } = useAppSelector(state => state.userDepositBonuses);

  const mutatedData = () => {
    if (!depositBonuses || depositBonuses.length === 0) return undefined;
    const keys = Object.keys(depositBonuses[0]);

    const headers: any = keys.map(key => {
      switch (key) {
        case 'expiresOn':
        case 'createdOn':
          return transformUnixDate(key, t);
        case 'status':
          return transformStatus(key, t);
        case 'limit':
        case 'percentage':
        case 'streamId':
        case 'description':
          return setInvisible(key);
        case 'conditions':
          return transformDefault(key, t, true);
        default:
          return transformDefault(key, t);
      }
    });

    headers.push(
      {
        Header: t('conditions'),
        accessor: 'conditions',
      },
      {
        id: 'history',
        accessor: 'history',
        disableFilters: true,
        disableSortBy: true,
      }
    );

    const rows = depositBonuses.map(item => {
      return {
        ...item,
        conditions: <NoWrapSpan value={`${item.percentage}% tot ${formatCurrency(item.limit)}`} />,
        history: (
          <Link
            to={`../../../lookup/eventstreams?${
              EventStreamParamsType.STREAM_ID
            }=claimablepromotion/${item.streamId.toLowerCase()}`}
          >
            {t('history')}
          </Link>
        ),
      };
    });

    return { headers, rows };
  };

  return (
    <div className={classes.container}>
      <DateRangePicker
        unixStartDate={Number(searchParams.get('startdate')) * 1000}
        unixEndDate={Number(searchParams.get('enddate')) * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
          fetchDepositBonuses(startDate, endDate);
        }}
      />
      <Card>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => (
              <ReactTableGI columns={data.headers} data={data.rows} pagination={data.rows.length > 5} sortable />
            )}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(DepositBonusesPage);
