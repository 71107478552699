import { EInterventionControllerBadRequestTypeSchema } from '@greenisland/stores';

type InterventionErrorReasons = {
  [key in EInterventionControllerBadRequestTypeSchema]: string;
};

export const INTERVENTION_ERROR_REASONS: InterventionErrorReasons = {
  [EInterventionControllerBadRequestTypeSchema.InvalidInterventionId]:
    'tasks.interventions.errors.invalidInterventionId',
  [EInterventionControllerBadRequestTypeSchema.UserNotFound]: 'tasks.interventions.errors.userNotFound',
  [EInterventionControllerBadRequestTypeSchema.InterventionNotFound]: 'tasks.interventions.errors.interventionNotFound',
  [EInterventionControllerBadRequestTypeSchema.InvalidUserId]: 'tasks.interventions.errors.invalidUserId',
  [EInterventionControllerBadRequestTypeSchema.InvalidStateChange]: 'tasks.interventions.errors.invalidStateChange',
};
