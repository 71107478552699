import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Tooltip } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

type OnClick = ComponentProps<typeof Button>['onClick'];

interface Props {
  /**
   * Check if the user have the permission to read
   */
  canRead?: boolean;
  /**
   * Check if the user have the permission to edit
   */
  canEdit?: boolean;
  /**
   * Check if the user have the permission to delete
   */
  canDelete?: boolean;
  /**
   * Function to run when read button is clicked
   */
  onRead?: OnClick;
  /**
   * Function to run when edit button is clicked
   */
  onEdit?: OnClick;
  /**
   * Function to run when delete button is clicked
   */
  onDelete?: OnClick;
  /**
   * Custom text for read tooltip
   */
  readTooltip?: string;
  /**
   * Custom text for edit tooltip
   */
  editTooltip?: string;
  /**
   * Custom text for delete tooltip
   */
  deleteTooltip?: string;
}

const TableCellActions = ({
  canRead = true,
  canEdit = true,
  canDelete = true,
  onRead,
  onEdit,
  onDelete,
  readTooltip,
  editTooltip,
  deleteTooltip,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      {canRead && onRead && (
        <Tooltip title={readTooltip || (t('details') as string)}>
          <Button color="secondary" variant="text" onClick={onRead} disableRipple>
            <FontAwesomeIcon sx={{ width: 15 }} icon={faEye} />
          </Button>
        </Tooltip>
      )}
      {canEdit && onEdit && (
        <Tooltip title={editTooltip || (t('edit') as string)}>
          <Button variant="text" onClick={onEdit}>
            <FontAwesomeIcon sx={{ width: 15 }} icon={faPen} />
          </Button>
        </Tooltip>
      )}
      {canDelete && onDelete && (
        <Tooltip title={deleteTooltip || (t('delete') as string)}>
          <Button sx={{ color: 'error.main' }} variant="text" onClick={onDelete}>
            <FontAwesomeIcon sx={{ width: 15 }} icon={faTrash} />
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

export default TableCellActions;
