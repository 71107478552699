import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { GameServer } from '@greenisland/stores';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';
import { QueryTournamentsParams, TournamentState, useQueryTournaments } from '@greenisland-store/gameServer';
import { Autocomplete, Box, capitalize, LinearProgress, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { formatCurrency, getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

import {
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../app/hooks';

const PAGE_SIZE = 25;

type Row = GameServer.Tournament;

const Tournaments = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [tournamentStatusFilter, setTournamentStatusFilter] = useState<TournamentState | null>(null);
  const state = Object.keys(TournamentState);
  const canReadTournament = usePermission(GameServerPermissions.getTournaments);

  const params: QueryTournamentsParams = { pageSize: pageSize, page: page + 1 };
  if (tournamentStatusFilter != null) params.tournamentState = tournamentStatusFilter;

  const {
    data: tournaments,
    isLoading,
    isError,
  } = useQueryTournaments(params, { query: { enabled: canReadTournament, keepPreviousData: true } });

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'name',
        headerName: t('content.gameserver.tournament.name'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'gameReference',
        headerName: t('content.gameserver.tournament.gameReference'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        headerName: capitalize(t('content.gameserver.tournament.validFrom')),
        field: 'validityFrom',
        renderCell: params => {
          const formattedFromDate = params.row.startDateTime;
          if (formattedFromDate) {
            const formattedDaysDate = getDateTimeFromUnix(formattedFromDate).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(formattedFromDate).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
        flex: 0.2,
      },
      {
        headerName: capitalize(t('content.gameserver.tournament.validTo')),
        field: 'validityTo',
        renderCell: params => {
          const formattedToDate = params.row.endDateTime;
          if (formattedToDate) {
            const formattedDaysDate = getDateTimeFromUnix(formattedToDate).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(formattedToDate).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
        flex: 0.2,
      },
      {
        field: 'buyInPrice',
        headerName: t('content.gameserver.tournament.buyInPrice'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return formatCurrency(params.row.buyInPrice.amount / 100, params.row.buyInPrice.currency);
        },
      },
      {
        field: 'rebuyPrice',
        headerName: t('content.gameserver.tournament.rebuyPrice'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return formatCurrency((params.row.rebuyPrice?.amount ?? 0) / 100, params.row.rebuyPrice?.currency);
        },
      },
      {
        field: 'state',
        headerName: t('content.gameserver.tournament.state'),
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
        sortable: false,
        minWidth: 250,
      },
      {
        field: 'payoutState',
        headerName: t('content.gameserver.tournament.payoutState'),
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
        sortable: false,
        minWidth: 250,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 150,
        flex: 0.1,
        headerName: t('content.gameserver.tournament.actions'),
        getActions: (params: GridRowParams<Row>) => [
          <>
            {canReadTournament && (
              <Tooltip title={t('content.gameserver.tournament.details')} key="details">
                <>
                  <GridActionsCellItem
                    title={t('content.gameserver.tournament.details')}
                    label={t('content.gameserver.tournament.details')}
                    icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                    onClick={() => {
                      if (params?.row?.id) {
                        navigate('./../' + params?.row?.id, { replace: true });
                      }
                    }}
                    key="details"
                  />
                </>
              </Tooltip>
            )}
          </>,
        ],
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      tournaments?.entries?.map<Row>(tournament => ({
        ...tournament,
      })) ?? [],
    [tournaments?.entries]
  );

  return (
    <Stack spacing={2}>
      {!isError ? (
        <>
          <Autocomplete
            id="tournamentStateFilter"
            options={state}
            getOptionLabel={option => option.toString()}
            renderInput={params => (
              <TextField {...params} size="small" label={t('content.gameserver.tournament.state')} />
            )}
            value={tournamentStatusFilter}
            onChange={(e, value) => {
              setTournamentStatusFilter(value as TournamentState);
            }}
          />
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={tournaments?.totalNumberOfEntries}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              disableDensitySelector
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableChildrenSorting
              disableChildrenFiltering
              disableMultipleColumnsSorting
              disableColumnResize
              disableColumnReorder
              isRowSelectable={() => false}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      ) : (
        <ErrorState errorMessage="error" />
      )}
    </Stack>
  );
};

export default Tournaments;
