import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { EDepositSortingTypeSchema, EPortalDepositStatusSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getUnixTime, subHours } from 'date-fns';

import { DATETIME_INPUT_FORMAT, DATETIME_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';
import Select from '@greenisland-common/components/atoms/Select';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { usePermission } from '../../../../app/hooks';
import { DepositsParamsType, isCheckedValidationHandler } from '../Components/DetailsComponents/Constants/Deposits';
import useGetDepositFiltersQuery from '../hooks/useGetDepositFiltersQuery';
import DepositFilterTextField from './DepositFilterTextField';

interface Props {
  fetchDepositsHandler: any;
}

const DepositFilters = ({ fetchDepositsHandler }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const updateSearchParamsHandler = useUpdateSearchParams();
  const {
    startDateQuery,
    endDateQuery,
    hasDetailsQuery,
    isAscendingQuery,
    sortByQuery,
    statusQuery,
    methodQuery,
    providerQuery,
    ibanQuery,
    walletQuery,
    ipAddressQuery,
    minAmountQuery,
    maxAmountQuery,
    referenceQuery,
    depositIdQuery,
    accountIdQuery,
    userIdQuery,
  } = useGetDepositFiltersQuery();
  const canReadDepositDetails = usePermission(OnlineCasinoPermissions.getDepositDetails);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ maxWidth: '100%', gridGap: '15px 15px', gridTemplateColumns: '1fr 1fr', marginBottom: '15px' }}
    >
      <Stack direction="row" spacing={1}>
        <DateTimePicker
          label={t('startDate')}
          onChange={date => {
            updateSearchParamsHandler(
              DepositsParamsType.START_DATE,
              String(getUnixTime(date ? Number(date) : subHours(new Date(), 24)))
            );
          }}
          value={startDateQuery ? new Date(Number(startDateQuery) * 1000) : null}
          renderInput={params => <TextField size="small" sx={{ mb: 2 }} fullWidth {...params} />}
          inputFormat={DATETIME_INPUT_FORMAT}
          mask={DATETIME_INPUT_MASK}
          maxDate={endDateQuery ? new Date(Number(endDateQuery) * 1000) : null}
        />
        <DateTimePicker
          label={t('endDate')}
          onChange={date => {
            updateSearchParamsHandler(
              DepositsParamsType.END_DATE,
              String(getUnixTime(date ? Number(date) : new Date()))
            );
          }}
          value={endDateQuery ? new Date(Number(endDateQuery) * 1000) : null}
          renderInput={params => <TextField size="small" sx={{ mb: 2 }} fullWidth {...params} />}
          inputFormat={DATETIME_INPUT_FORMAT}
          mask={DATETIME_INPUT_MASK}
          maxDate={new Date()}
          minDate={startDateQuery ? new Date(Number(startDateQuery) * 1000) : undefined}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <DepositFilterTextField
          label="minimumAmount"
          value={minAmountQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(DepositsParamsType.MIN_AMOUNT, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.MIN_AMOUNT, '')}
        />
        <DepositFilterTextField
          label="maximumAmount"
          value={maxAmountQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(DepositsParamsType.MAX_AMOUNT, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.MAX_AMOUNT, '')}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <DepositFilterTextField
          label="userId"
          value={userIdQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(DepositsParamsType.USER_ID, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.USER_ID, '')}
        />
        <DepositFilterTextField
          label="accountId"
          value={accountIdQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(DepositsParamsType.ACCOUNT_ID, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.ACCOUNT_ID, '')}
        />
        <DepositFilterTextField
          label="depositId"
          value={depositIdQuery}
          type="number"
          onChange={event => updateSearchParamsHandler(DepositsParamsType.DEPOSIT_ID, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.DEPOSIT_ID, '')}
        />
        <DepositFilterTextField
          label="reference"
          value={referenceQuery}
          onChange={event => updateSearchParamsHandler(DepositsParamsType.REFERENCE, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.REFERENCE, '')}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <DepositFilterTextField
          label="iban"
          value={ibanQuery}
          onChange={event => updateSearchParamsHandler(DepositsParamsType.IBAN, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.IBAN, '')}
        />
        <DepositFilterTextField
          label="wallet"
          value={walletQuery}
          onChange={event => updateSearchParamsHandler(DepositsParamsType.WALLET, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.WALLET, '')}
        />
        <DepositFilterTextField
          label="ipAddress"
          value={ipAddressQuery}
          onChange={event => updateSearchParamsHandler(DepositsParamsType.IP_ADDRESS, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.IP_ADDRESS, '')}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Select
          id={DepositsParamsType.STATUS}
          label={capitalize(t('status'))}
          value={statusQuery}
          onChange={event => {
            if (event.target.value) {
              updateSearchParamsHandler(DepositsParamsType.STATUS, event.target.value.toString());
            }
          }}
          options={Object.values(EPortalDepositStatusSchema)}
        />
        <DepositFilterTextField
          label="provider"
          value={providerQuery}
          onChange={event => updateSearchParamsHandler(DepositsParamsType.PROVIDER, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.PROVIDER, '')}
        />
        <DepositFilterTextField
          label="method"
          value={methodQuery}
          onChange={event => updateSearchParamsHandler(DepositsParamsType.METHOD, event.target.value)}
          onRemove={() => updateSearchParamsHandler(DepositsParamsType.METHOD, '')}
        />
      </Stack>
      <Stack direction="row" spacing={1} sx={{ width: 350 }} justifyContent="start" alignItems="center" display="flex">
        <Select
          id={DepositsParamsType.SORT_BY}
          label={capitalize(t('sortBy'))}
          value={sortByQuery}
          onChange={event => {
            if (event.target.value) {
              updateSearchParamsHandler(DepositsParamsType.SORT_BY, event.target.value.toString());
            }
          }}
          options={Object.values(EDepositSortingTypeSchema)}
        />
        <FormControlLabel
          control={<Checkbox color="primary" size="medium" />}
          onChange={(_, value) => updateSearchParamsHandler(DepositsParamsType.ASCENDING, value.toString())}
          label={t('ascending')}
          checked={isCheckedValidationHandler(isAscendingQuery)}
        />
      </Stack>
      <Stack direction="row" spacing={1} sx={{ width: 500 }} justifyContent="start" alignItems="start">
        <Button size="medium" variant="contained" onClick={fetchDepositsHandler}>
          {t('search')}
        </Button>
        <Button size="medium" variant="contained" color="warning" onClick={() => navigate(pathname, { replace: true })}>
          {t('resetFilters')}
        </Button>
        <FormControlLabel
          disabled={!canReadDepositDetails}
          control={<Checkbox color="primary" size="medium" />}
          onChange={(_, value) => updateSearchParamsHandler(DepositsParamsType.HAS_DETAILS, value.toString())}
          label={t('details')}
          checked={isCheckedValidationHandler(hasDetailsQuery)}
        />
      </Stack>
    </Box>
  );
};

export default DepositFilters;
