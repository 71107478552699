import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '@greenisland/components';
import { Box } from '@mui/material';

const LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'nl-BE', label: 'Nederlands' },
  { value: 'fr-BE', label: 'Francais' },
];

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();

  const handleSimple = (value: any) => {
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
  };

  return (
    <Box display="flex" gap={1} alignItems="center">
      <FontAwesomeIcon icon={faGlobe} size="lg" />
      <Select
        fullWidth
        label=""
        placeholder={t('chooseLanguage')}
        selectedValue={i18n.language}
        onChange={handleSimple}
        menuItems={LANGUAGES}
      />
    </Box>
  );
};

export default memo(LanguageSelector);
