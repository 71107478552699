import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EBonusType,
  FreeSpinsCampaignSchema,
  FreeSpinsCampaignsSchema,
  getGetAllAccountDataQueryKey,
  useAwardFreeSpins,
  useGetCampaignsOverview,
  useGetFreeSpinsCampaigns,
} from '@greenisland/stores';
import { FreeSpinsBonusArgumentsSchema } from '@greenisland/stores/dist/api/schemas/freeSpinsBonusArgumentsSchema';
import { FreeSpinsCampaignProvider } from '@greenisland/stores/dist/stores/swaggerinterfaces/freeSpinsCampaignProvider';
import {
  Autocomplete,
  Box,
  Button,
  capitalize,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

import { formatCurrency } from '../../../../../../app/helpers/transformFunctions';

type AwardDialogProps = {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  userId: string;
};

type FreeSpinsCampaignNameAndIdObject = {
  id: string;
  name: string;
};

type FreeSpinsCampaignSchemaGame = {
  id?: string;
  name?: string;
  mobile?: boolean;
  desktop?: boolean;
  mobileExternalGameId?: string;
  desktopExternalGameId?: string;
};

type FormData = {
  provider: FreeSpinsCampaignProvider;
  game: FreeSpinsCampaignSchemaGame;
  amountOfSpins: number;
  stakePerSpin: number;
  description: string;
  expiryDate: number | Date;
  campaignId?: string;
};

const sortByName = (a: { name?: string }, b: { name?: string }) => {
  if (a.name && b.name) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }
  return 0;
};
const sortByNumber = (a: number, b: number) => a - b;

// Get uniques providers, games, amountOfSpins and stakes for autocomplete values
// The returned options will change depending on filteredCampaigns
const searchProviders = (freeSpinsCampaigns?: FreeSpinsCampaignsSchema) => {
  if (!freeSpinsCampaigns) return [];
  const uniqueProviders = [
    ...new Map(
      freeSpinsCampaigns.map((freeSpinCampaign: FreeSpinsCampaignSchema) => [
        freeSpinCampaign.provider?.id,
        freeSpinCampaign,
      ])
    ).values(),
  ].map((freeSpinsCampaign: FreeSpinsCampaignSchema) => Object.assign({}, freeSpinsCampaign.provider));

  return uniqueProviders.sort(sortByName);
};

const searchGames = (freeSpinsCampaigns?: FreeSpinsCampaignsSchema): FreeSpinsCampaignNameAndIdObject[] => {
  if (!freeSpinsCampaigns) return [];
  const games: Array<FreeSpinsCampaignNameAndIdObject> = [];
  freeSpinsCampaigns.map(campaign => {
    const gamePresent = games.find((indGame: any) => campaign.game?.id === indGame.id);
    const gameNameDuplicate = games.find((indGame: any) => campaign.game?.name === indGame.name);
    if (campaign.game?.id && !gamePresent) {
      games.push({
        id: campaign.game?.id,
        name: campaign.game?.name ? (!gameNameDuplicate ? campaign.game?.name : `${campaign.game?.name}2`) : 'no name',
      });
    }
  });

  return games.sort(sortByName);
};

const searchAmountOfSpins = (freeSpinsCampaigns?: FreeSpinsCampaignsSchema): Array<number> => {
  if (!freeSpinsCampaigns) return [];
  const amounts: Array<number> = [];
  freeSpinsCampaigns.map(campaign => {
    if (campaign.amountOfSpins) {
      amounts.push(campaign.amountOfSpins);
    }
  });
  const uniqueAmountOfSpins: Array<number> = [...new Set(amounts)];
  return uniqueAmountOfSpins.sort(sortByNumber);
};
const searchStakes = (freeSpinsCampaigns?: FreeSpinsCampaignsSchema) => {
  if (!freeSpinsCampaigns) return [];
  const amounts: Array<number> = [];
  freeSpinsCampaigns.forEach(campaign => {
    if (campaign.stake) {
      amounts.push(campaign.stake);
    }
  });
  const uniqueStakes: Array<number> = [...new Set(amounts)];
  return uniqueStakes.sort(sortByNumber);
};

// Equality checks between freeSpinsCampaigns and the inputs values. The campaign must include the input value
const freeSpinCampaignProviderEqualsGivenProvider = (
  freeSpinsCampaign: FreeSpinsCampaignSchema,
  provider?: FreeSpinsCampaignProvider
): boolean => {
  const providerId = provider?.id;
  if (providerId && providerId.length > 0) {
    return freeSpinsCampaign.provider?.id === providerId;
  }
  return false;
};
const freeSpinCampaignContainsGivenGame = (
  freeSpinsCampaign: FreeSpinsCampaignSchema,
  game: FreeSpinsCampaignSchemaGame
) => {
  const gameId = game?.id;
  return Boolean(freeSpinsCampaign.game?.id) && freeSpinsCampaign.game?.id === gameId;
};
const freeSpinCampaignAmountOfSpinsEqualsGivenAmountOfSpins = (
  freeSpinsCampaign: FreeSpinsCampaignSchema,
  amountOfSpins?: number
) => {
  return amountOfSpins && freeSpinsCampaign.amountOfSpins === amountOfSpins;
};
const freeSpinCampaignStakeEqualsGivenStake = (freeSpinsCampaign: FreeSpinsCampaignSchema, stake?: number) => {
  return stake && freeSpinsCampaign.stake === stake;
};

/**
 * Return the freeSpinsCampaigns that match our form values
 * @param {FreeSpinsCampaignsSchema} freeSpinsCampaigns - all the freeSpinsCampaigns returned by react-query
 * @param {Omit<FormData, 'expiryDate' | 'description' | 'sourceType' | 'sourceValue'>} formValues - our form input values grouped in an object
 * @return {FreeSpinsCampaignsSchema} - The filtered campaigns that match our form values
 */
const filterCampaigns = (
  freeSpinsCampaigns: FreeSpinsCampaignsSchema,
  formValues: Omit<FormData, 'expiryDate' | 'description' | 'sourceType' | 'sourceValue'>
): FreeSpinsCampaignsSchema => {
  const filteredCampaigns: FreeSpinsCampaignsSchema = [];
  const { provider, game, amountOfSpins, stakePerSpin } = formValues;

  freeSpinsCampaigns?.map((freeSpinsCampaign: FreeSpinsCampaignSchema) => {
    const formData = {
      provider: provider ? freeSpinCampaignProviderEqualsGivenProvider(freeSpinsCampaign, provider) : undefined,
      game: game ? freeSpinCampaignContainsGivenGame(freeSpinsCampaign, game) : undefined,
      amountOfSpins: amountOfSpins
        ? freeSpinCampaignAmountOfSpinsEqualsGivenAmountOfSpins(freeSpinsCampaign, amountOfSpins)
        : undefined,
      stake: stakePerSpin ? freeSpinCampaignStakeEqualsGivenStake(freeSpinsCampaign, stakePerSpin) : undefined,
    };

    const isAllTrue = Object.values(formData)
      .filter(value => value !== undefined)
      .every(value => value === true);

    if (isAllTrue) {
      filteredCampaigns.push(freeSpinsCampaign);
    }
  });

  return filteredCampaigns?.length > 0 ? filteredCampaigns : [];
};

const AwardFreeSpinsDialog = ({ openDialog, setOpenDialog, userId }: AwardDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [filteredCampaigns, setFilteredCampaigns] = useState<FreeSpinsCampaignsSchema>([]);
  const {
    data: marketingCampaigns,
    isLoading: marketingCampaignsIsLoading,
    isError: marketingCampaignsIsError,
  } = useGetCampaignsOverview({ bonusType: EBonusType.Freespins });

  const {
    data: freeSpinsCampaigns,
    isLoading: freeSpinCampaignsIsLoading,
    isError: freeSpinCampaignsIsError,
  } = useGetFreeSpinsCampaigns();

  const awardFreeSpinsMutation = useAwardFreeSpins({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetAllAccountDataQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
        setOpenDialog(false);
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { register, handleSubmit, errors, control, watch } = useForm<FormData>({
    mode: 'onChange',
  });

  const watchProviderInput = watch('provider');
  const watchGamesInput = watch('game');
  const watchAmountInput = watch('amountOfSpins');
  const watchStakeInput = watch('stakePerSpin');

  useEffect(() => {
    if (freeSpinsCampaigns) {
      const filtered = filterCampaigns(freeSpinsCampaigns, {
        provider: watchProviderInput,
        game: watchGamesInput,
        amountOfSpins: watchAmountInput,
        stakePerSpin: watchStakeInput,
      });

      setFilteredCampaigns(filtered);
    }
  }, [watchProviderInput, watchGamesInput, watchAmountInput, watchStakeInput, freeSpinsCampaigns]);

  const isLoading = marketingCampaignsIsLoading || freeSpinCampaignsIsLoading;
  const isError = marketingCampaignsIsError || freeSpinCampaignsIsError;

  if (isLoading)
    return (
      <Dialog fullWidth open={true}>
        <Box
          sx={{
            height: 480,
            minWidth: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Dialog>
    );

  if (isError || !freeSpinsCampaigns || !marketingCampaigns) return null;

  const providers = searchProviders(filteredCampaigns);
  const games: FreeSpinsCampaignNameAndIdObject[] = searchGames(filteredCampaigns);
  const amountOfSpins: number[] = searchAmountOfSpins(filteredCampaigns);
  const stakes = searchStakes(filteredCampaigns);

  const onSubmit = (data: FormData) => {
    if (filteredCampaigns && filteredCampaigns.length === 1 && filteredCampaigns[0].campaignId) {
      const formattedData: FreeSpinsBonusArgumentsSchema = {
        freeSpinsCampaignId: filteredCampaigns[0].campaignId,
        description: data.description,
        expiryDate: getUnixTime(new Date(data.expiryDate)),
        campaignId: data.campaignId,
      };

      awardFreeSpinsMutation.mutate({ userId, data: formattedData });
    }
  };

  return (
    <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>{capitalize(t('awardFreeSpin'))}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="provider"
            control={control}
            defaultValue={null}
            rules={{ required: t('fieldIsRequired') as string }}
            render={({ onChange, value }) => (
              <Autocomplete
                id="ac-providers"
                options={providers}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={({ size, ...params }) => (
                  <TextField label={t('provider') + '*'} size="small" error={!!errors.provider} {...params} />
                )}
                onChange={(e, value) => {
                  onChange(value);
                }}
                value={value}
              />
            )}
          />
          <Controller
            name="game"
            control={control}
            defaultValue={null}
            rules={{ required: t('fieldIsRequired') as string }}
            render={({ onChange, value }) => (
              <Autocomplete
                sx={{ mt: 2 }}
                id="ac-games"
                options={games}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={({ size, ...params }) => (
                  <TextField label={t('game') + '*'} size="small" error={!!errors.game} {...params} />
                )}
                onChange={(e, value) => {
                  onChange(value);
                }}
                value={value}
              />
            )}
          />
          <Controller
            name="amountOfSpins"
            control={control}
            defaultValue={null}
            rules={{ required: t('fieldIsRequired') as string }}
            render={({ onChange, value }) => (
              <Autocomplete
                sx={{ mt: 2 }}
                id="ac-amount-of-spins"
                getOptionLabel={(option: number) => option.toString()}
                isOptionEqualToValue={(option, value) => option === value}
                options={amountOfSpins}
                renderInput={({ size, ...params }) => (
                  <TextField label={t('amountOfSpins') + '*'} error={!!errors.amountOfSpins} size="small" {...params} />
                )}
                onChange={(e, value) => {
                  onChange(value);
                }}
                value={value}
              />
            )}
          />
          <Controller
            name="stakePerSpin"
            control={control}
            defaultValue={null}
            rules={{ required: t('fieldIsRequired') as string }}
            render={({ onChange, value }) => (
              <Autocomplete
                sx={{ mt: 2 }}
                id="ac-stake-per-spin"
                getOptionLabel={option => formatCurrency(option)}
                isOptionEqualToValue={(option, value) => option === value}
                options={stakes}
                renderInput={({ size, ...params }) => (
                  <TextField label={t('stake') + '*'} error={!!errors.stakePerSpin} size="small" {...params} />
                )}
                onChange={(e, value) => {
                  onChange(value);
                }}
                value={value}
              />
            )}
          />
          <Box display="flex" alignItems="center" mt={2}>
            <Box width="40%">
              <Controller
                rules={{ required: t('fieldIsRequired') as string }}
                render={({ onChange, value }) => (
                  <DateTimePicker
                    disablePast
                    label={t('expiryDate') + '*'}
                    value={value}
                    onChange={(value: Date | null) => onChange(value?.toISOString() ?? new Date())}
                    renderInput={params => <TextField sx={{ pr: 1 }} fullWidth size="small" {...params} />}
                    inputFormat="dd/MM/yyyy HH:mm"
                    mask="__/__/____ __:__"
                  />
                )}
                control={control}
                defaultValue={new Date().setDate(new Date().getDate() + 30)}
                name="expiryDate"
              />
            </Box>
            <Box width="60%">
              <Controller
                control={control}
                name="campaignId"
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextField
                    size="small"
                    sx={{ pl: 1 }}
                    select
                    fullWidth
                    label={t('campaign')}
                    disabled={marketingCampaigns && marketingCampaigns.length <= 0}
                    helperText={marketingCampaigns && marketingCampaigns.length <= 0 ? t('noCampaigns') : ''}
                    error={!!errors.campaignId}
                    value={value}
                    onChange={onChange}
                  >
                    {marketingCampaigns.map(campaign => (
                      <MenuItem key={campaign.campaignId} value={campaign.campaignId}>
                        {`${campaign.campaignName} ${campaign.theme && ` (${campaign.theme})`} ${
                          campaign.year && ` (${campaign.year})`
                        }`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>
          </Box>
          <TextField
            sx={{ mt: 2, mb: 2 }}
            fullWidth
            size="small"
            name="description"
            label={t('description')}
            required
            type={'text'}
            inputRef={register({ required: t('fieldIsRequired') as string, minLength: 1 })}
            error={!!errors.description}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ minHeight: '36px', minWidth: '90px' }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={awardFreeSpinsMutation.isLoading}
          >
            {awardFreeSpinsMutation.isLoading ? <CircularProgress size={20} /> : t('award')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AwardFreeSpinsDialog;
