import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CountryListSchemaItem, CreateTestUserRequestSchema, useGetAllCountries } from '@greenisland/stores';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { AutoCompleteInput } from '@greenisland-common/components/atoms';

import CollapseSectionWrapper from './CollapseSectionWrapper';

const TestUserCountryOfOriginSettings = () => {
  const { t } = useTranslation();
  const { data: countries, isLoading } = useGetAllCountries({});

  const { setValue } = useFormContext<CreateTestUserRequestSchema>();

  const [isCountryOfOriginChosen, setIsCountryOfOriginChosen] = useState(false);

  useEffect(() => {
    if (!isCountryOfOriginChosen) {
      setValue('identificationCountryOfOrigin', null);
    }
  }, [isCountryOfOriginChosen, setValue]);

  return (
    <CollapseSectionWrapper title={t('identificationCountryOfOrigin')}>
      <Box>
        <FormControlLabel
          control={<Checkbox color="primary" size="medium" />}
          onChange={(_, value) => setIsCountryOfOriginChosen(value)}
          label={t('common.chooseIdentificationCountryOfOrigin')}
          checked={isCountryOfOriginChosen}
          sx={{ '.MuiTypography-root': { fontWeight: isCountryOfOriginChosen ? 600 : 400 }, mb: 2 }}
        />
        <AutoCompleteInput
          size="small"
          name="identificationCountryOfOrigin"
          label={t('country')}
          options={countries || []}
          getLabel={option => option?.countryName ?? ''}
          multiple={false}
          loading={isLoading}
          rules={{
            required: isCountryOfOriginChosen,
            setValueAs: (country: CountryListSchemaItem) => country?.countryCode,
          }}
          disabled={!isCountryOfOriginChosen}
          defaultValue={null}
          required={isCountryOfOriginChosen}
          disableCloseOnSelect={false}
        />
      </Box>
    </CollapseSectionWrapper>
  );
};

export default TestUserCountryOfOriginSettings;
