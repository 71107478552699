import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddPromotionalCalendar } from '@greenisland/stores';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';

import { defaultPromotionalCalendarSettings, LoyaltyRankOptionsStatus } from '../../constants';
import {
  CalendarPromotionDialogSchema,
  generatePromotionCalendarFormPayload,
  PromotionalCalendarActionMode,
  useGetLoyaltyRankOptions,
  usePromotionalCalendarFormHandlers,
} from './helpers';
import PromotionalCalendarGeneralSettings from './PromotionalCalendarGeneralSettings';
import PromotionalCalendarPrizeSettings from './PromotionalCalendarPrizeSettings';

const CreatePromotionalCalendar = () => {
  const { t } = useTranslation();

  const methods = useForm<CalendarPromotionDialogSchema>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: defaultPromotionalCalendarSettings,
  });

  const { handleSubmit, reset, watch } = methods;

  const chosenMinimalLoyaltyRankRequirement = watch('minimalLoyaltyRankRequirement');

  const availableLoyaltyRankForPrizeOptions = useGetLoyaltyRankOptions(
    LoyaltyRankOptionsStatus.HIDE_INACTIVE,
    chosenMinimalLoyaltyRankRequirement
  );

  const { onSuccessHandler, onErrorHandler } = usePromotionalCalendarFormHandlers(reset);

  const { mutate: addPromotionalCalendar, isLoading: isAddPromotionalCalendarLoading } = useAddPromotionalCalendar({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: error => onErrorHandler(error),
    },
  });

  const onSubmit = (data: CalendarPromotionDialogSchema) => {
    const payload = generatePromotionCalendarFormPayload(PromotionalCalendarActionMode.CREATE, data);

    addPromotionalCalendar({ data: payload });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <PromotionalCalendarGeneralSettings />
          <PromotionalCalendarPrizeSettings availableLoyaltyRankOptions={availableLoyaltyRankForPrizeOptions} />
        </Stack>
        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            sx={{ mt: 4, px: 6, py: 2 }}
            color="primary"
            type="submit"
            variant="contained"
            loading={isAddPromotionalCalendarLoading}
          >
            {t('save')}
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default CreatePromotionalCalendar;
