import { memo, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EDepositMethodSchema,
  EPaymentProviderSchema,
  GetDepositsSummaryParams,
  useGetDepositsSummary,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import { Card, Stack } from '@mui/material';
import { GridColumns, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { numberOfRowsPerPage } from 'src/app/constants/defaultvalues';

import {
  DataGridContainer,
  DataGridPagination,
  ErrorCard,
  Link,
  SelectInput,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import DateInput from '@greenisland-common/components/molecules/DateInput';
import Input from '@greenisland-common/components/molecules/Input';

import { formatCurrency } from '@greenisland-common/helpers';

import { removeUndefinedProperties } from '../../../../../app/helpers/functions';
import { usePermission } from '../../../../../app/hooks';
import i18n from '../../../../../app/i18n';

const DepositsSummary = () => {
  const canReadDepositsSummary = usePermission(OnlineCasinoPermissions.getDepositsSummary);
  const canLookupUser = usePermission(OnlineCasinoPermissions.advancedUserSearchV2);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    defaultValues: {
      descending: true,
      start: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).getTime(),
      end: new Date().getTime(),
      lowerThanThreshold: false,
      depositMethod: undefined,
      paymentProvider: undefined,
      threshold: 0,
    },
  });
  const { handleSubmit, control } = methods;
  const [searchQuery, setSearchQuery] = useState<GetDepositsSummaryParams | undefined>(undefined);
  const [page, setPage] = useState(0);

  const { data: summary, isLoading } = useGetDepositsSummary(
    {
      ...searchQuery,
      numberOfItems: numberOfRowsPerPage,
      pageNumber: page,
    },
    {
      query: {
        enabled: Boolean(searchQuery) && canReadDepositsSummary,
        onSuccess: () => {
          enqueueSnackbar(t('success'), {
            variant: 'success',
          });
        },
      },
    }
  );

  const paymentMethods = Object.values(EDepositMethodSchema);
  const paymentProviders = Object.values(EPaymentProviderSchema);

  const thresholds = [
    {
      label: t('aboveThreshold'),
      value: false,
    },
    {
      label: t('underThreshold'),
      value: true,
    },
  ];

  const sortings = [
    {
      label: t('descending'),
      value: true,
    },
    {
      label: t('ascending'),
      value: false,
    },
  ];

  const data = useMemo(() => {
    if (summary) {
      return summary.items.map(sum => {
        return { id: sum.userId, ...sum };
      });
    }
    return [];
  }, [summary]);

  const headers: GridColumns = data[0]
    ? Object.keys(data[0]).map(key => {
        switch (key) {
          case 'accountId':
            return {
              headerName: t(`summary.${key}`),
              field: key,
              minWidth: 150,
              flex: 1,
              renderCell: (params: GridRenderCellParams<string>) => {
                return canLookupUser ? (
                  <Link underline="hover" to={`../../../users/${params.row.userId}/details`}>
                    {params.row.accountId}
                  </Link>
                ) : (
                  <span>{params.row.accountId}</span>
                );
              },
              headerAlign: 'center',
              align: 'center',
            };
          case 'count':
            return {
              headerName: t(`summary.deposits.${key}`),
              field: key,
              minWidth: 150,
              flex: 1,
              type: 'number',
              headerAlign: 'center',
              align: 'center',
            };
          case 'total':
          case 'maximum':
          case 'minimum':
            return {
              headerName: t(`summary.deposits.${key}`),
              field: key,
              minWidth: 150,
              flex: 1,
              type: 'number',
              valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
              headerAlign: 'center',
              align: 'center',
            };
          default:
            return {
              headerName: t(`summary.${key}`),
              field: key,
              minWidth: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            };
        }
      })
    : [];

  const onHandleSubmit = (data: GetDepositsSummaryParams) => {
    setSearchQuery(
      removeUndefinedProperties({
        ...data,
        start: data.start ? Math.round(data.start / 1000) : undefined,
        end: data.end ? Math.round(data.end / 1000) : undefined,
        depositMethod: data.depositMethod ? data.depositMethod : undefined,
        paymentProvider: data.paymentProvider ? data.paymentProvider : undefined,
      })
    );
  };

  if (!canReadDepositsSummary)
    return (
      <ErrorCard severity="info">
        {t('common.noPermissionToSeeThePage', OnlineCasinoPermissions.getDepositsSummary)}
      </ErrorCard>
    );

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <Stack
            spacing={2}
            sx={{ display: 'grid', gridGap: '15px 15px', gridTemplateColumns: '1fr 1fr', marginBottom: '15px' }}
          >
            <Input
              label={t('accountId')}
              name="accountId"
              control={control}
              sx={{ marginTop: 'auto', marginBottom: '0px' }}
            />
            <SelectInput
              name={'descending'}
              label={t('sorting')}
              options={
                sortings.map(s => ({
                  value: s.value,
                  label: s.label,
                })) ?? []
              }
            />
            <DateInput label={t('startDate')} name="start" />
            <DateInput label={t('endDate')} name="end" />
            <SelectInput
              name={'depositMethod'}
              label={t('paymentMethod')}
              options={
                paymentMethods.map(method => ({
                  value: method,
                  label: method,
                })) ?? []
              }
            />
            <SelectInput
              name={'paymentProvider'}
              label={t('paymentProvider')}
              options={
                paymentProviders.map(provider => ({
                  value: provider,
                  label: provider,
                })) ?? []
              }
            />
            <SelectInput
              name={'lowerThanThreshold'}
              label={t('summary.threshold.type')}
              options={
                thresholds.map(x => ({
                  value: x.value,
                  label: x.label,
                })) ?? []
              }
            />
            <Input label={t('summary.threshold.amount')} name="threshold" control={control} />
          </Stack>
          <LoadingButton loading={isLoading} type="submit" variant="contained" sx={{ mb: 3 }}>
            {t('search')}
          </LoadingButton>
        </form>
      </FormProvider>
      <Card>
        <DataGridContainer>
          <StyledDataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                  userId: false,
                },
              },
            }}
            columns={headers}
            rows={data}
            density="compact"
            loading={isLoading}
            pagination
            page={page}
            pageSize={numberOfRowsPerPage}
            paginationMode="server"
            onPageChange={p => {
              setPage(p);
            }}
            showColumnRightBorder={false}
            disableDensitySelector
            disableSelectionOnClick
            rowCount={summary?.pagingDetails?.totalItems ?? 0}
            components={{
              Pagination: DataGridPagination,
            }}
          />
        </DataGridContainer>
      </Card>
    </div>
  );
};

export default memo(DepositsSummary);
