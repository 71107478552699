import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faArrowsAlt, faRedo, faSearchMinus, faSearchPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, EIcons, Tooltip } from '@greenisland/components';
import { Dialog, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  dialogImage: {
    objectFit: 'cover',
    margin: 'auto',
    height: '40rem',
    [theme.breakpoints.down('xl')]: {
      height: '30rem',
    },
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
      width: '100%',
    },
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  transformIcons: {
    position: 'absolute',
    display: 'flex',
    bottom: 5,
    transform: 'translateX(-50%)',
    left: '50%',
    zIndex: 100,
    '& button': {
      margin: '0 5px',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
    zIndex: 100,
  },
  dragIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    zIndex: 100,
    cursor: 'move',
    background: theme.palette.primary.main,
    color: 'white',
    width: 48,
    minWidth: 48,
    maxWidth: 48,
    height: 48,
    minHeight: 48,
    maxHeight: 48,
    borderRadius: '50%',
    boxShadow: '0 1px 3px rgb(0 0 0 / 20%), 0 1px 1px rgb(0 0 0 / 14%), 0 2px 1px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  paper: {
    pointerEvents: 'auto',
    width: '45rem',
    height: '45rem',
    [theme.breakpoints.down('xl')]: {
      width: '35rem',
      height: '35rem',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: 'auto',
    },
    overflow: 'hidden',
  },
  dialogRoot: {
    pointerEvents: 'none',
  },
  dialogContainer: {
    alignItems: 'flex-end',
  },
}));

const ImageDialog = (props: {
  openImageDialog: boolean;
  setOpenImageDialog: (state: boolean) => void;
  image: string;
}) => {
  const { openImageDialog, setOpenImageDialog, image } = props;
  const classes = styles();
  const { t } = useTranslation();

  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [isPanning, setPanning] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dialogPosition, setDialogPosition] = useState({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });
  const [position, setPosition] = useState({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });

  const rotateRight = () => {
    if (rotation !== 270) {
      setRotation(rotation + 90);
    } else setRotation(0);

    setPosition({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });
  };

  const rotateLeft = () => {
    if (rotation !== -270) {
      setRotation(rotation - 90);
    } else setRotation(0);

    setPosition({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });
  };

  const zoomIn = () => setScale(scale * 1.5);
  const zoomOut = () => setScale(scale / 1.5);

  const onMouseDown = (e: any) => {
    e.preventDefault();
    setPanning(true);
    setPosition({
      ...position,
      oldClientX: e.clientX / scale,
      oldClientY: e.clientY / scale,
    });
  };

  const onMouseDownDialog = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
    setDialogPosition({
      ...dialogPosition,
      oldClientX: e.clientX,
      oldClientY: e.clientY,
    });
  };

  useEffect(() => {
    const mouseup = () => {
      setPanning(false);
      setIsDragging(false);
    };

    const mousemove = (e: any) => {
      if (isPanning) {
        switch (rotation) {
          case 90:
          case -270:
            setPosition({
              ...position,
              x: position.x + e.clientY / scale - position.oldClientY,
              y: position.y - e.clientX / scale + position.oldClientX,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
            break;
          case 180:
          case -180:
            setPosition({
              ...position,
              x: position.x - e.clientX / scale + position.oldClientX,
              y: position.y - e.clientY / scale + position.oldClientY,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
            break;
          case 270:
          case -90:
            setPosition({
              ...position,
              x: position.x - e.clientY / scale + position.oldClientY,
              y: position.y + e.clientX / scale - position.oldClientX,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
            break;
          default:
            setPosition({
              ...position,
              x: position.x + e.clientX / scale - position.oldClientX,
              y: position.y + e.clientY / scale - position.oldClientY,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
        }
      } else if (isDragging) {
        setDialogPosition({
          ...dialogPosition,
          x: dialogPosition.x + e.clientX - dialogPosition.oldClientX,
          y: dialogPosition.y + e.clientY - dialogPosition.oldClientY,
          oldClientX: e.clientX,
          oldClientY: e.clientY,
        });
      }
    };

    window.addEventListener('mouseup', mouseup);
    window.addEventListener('mousemove', mousemove);

    return () => {
      window.removeEventListener('mouseup', mouseup);
      window.removeEventListener('mousemove', mousemove);
    };
  });

  const closeDialog = () => {
    setOpenImageDialog(false);
    setRotation(0);
    setScale(1);
  };

  return (
    <Dialog
      open={openImageDialog}
      onClose={closeDialog}
      maxWidth="lg"
      fullWidth
      disableEnforceFocus
      disableAutoFocus
      disableScrollLock
      classes={{ root: classes.dialogRoot, scrollPaper: classes.dialogContainer }}
      PaperProps={{ className: classes.paper }}
      BackdropComponent={() => null}
      style={{ transform: `translate(${dialogPosition.x}px, ${dialogPosition.y}px)` }}
    >
      <div onMouseDown={onMouseDownDialog} className={classes.dragIcon}>
        <Tooltip content={t('moveScreen')}>
          <FontAwesomeIcon icon={faArrowsAlt} />
        </Tooltip>
      </div>
      <div>
        <Tooltip content={t('close')}>
          <Button
            className={classes.closeIcon}
            variant="contained"
            rounded
            onClick={closeDialog}
            justIcon={EIcons.faTimes}
          />
        </Tooltip>
      </div>
      <img
        className={classes.dialogImage}
        src={image}
        alt="id"
        style={{
          transform: `rotate(${rotation}deg) scale(${scale}) translate(${position.x}px, ${position.y}px)`,
        }}
        onMouseDown={onMouseDown}
      />
      <div className={classes.transformIcons}>
        <Tooltip content={t('zoomIn')}>
          <Button variant="contained" rounded onClick={zoomIn} justIcon={<FontAwesomeIcon icon={faSearchPlus} />} />
        </Tooltip>
        <Tooltip content={t('zoomOut')}>
          <Button variant="contained" rounded onClick={zoomOut} justIcon={<FontAwesomeIcon icon={faSearchMinus} />} />
        </Tooltip>
        <Tooltip content={t('undo')}>
          <Button variant="contained" rounded onClick={rotateLeft} justIcon={<FontAwesomeIcon icon={faUndo} />} />
        </Tooltip>
        <Tooltip content={t('redo')}>
          <Button variant="contained" rounded onClick={rotateRight} justIcon={<FontAwesomeIcon icon={faRedo} />} />
        </Tooltip>
      </div>
    </Dialog>
  );
};

export default memo(ImageDialog);
