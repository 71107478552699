import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch } from '@greenisland-core/store';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format, fromUnixTime } from 'date-fns';

const useStyles = makeStyles({
  actions: { padding: '16px 24px' },
});
interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  userID: string;
  note: {
    noteId: string;
    message: string;
    author: string;
    timestamp: number;
  };
}

const DeleteUserNoteDialog = ({ userID, open, setOpen, note }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const confirmDeleteUserNote = () => {
    dispatch(actionCreators.deleteUserNote(userID, note.noteId));
    setOpen(false);
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t('deleteUserNote')}</DialogTitle>
      <DialogContent>
        {`${t('deleteUserNote')} ${t('postedBy')} ${note.author} ${t('on')} ${format(
          fromUnixTime(note.timestamp),
          'dd/MM/yyyy HH:mm'
        )}?`}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="secondary" variant="transparent" onClick={() => setOpen(false)}>
          {t('no')}
        </Button>
        <Button onClick={() => confirmDeleteUserNote()} error>
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DeleteUserNoteDialog);
