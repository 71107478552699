import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EKillSwitchErrorTypeSchema,
  EKillSwitchStateSchema,
  ErrorSchema,
  getGetKillSwitchStateQueryKey,
  KillSwitchLambdaParamParameter,
  useGetKillSwitchState,
  useSetKillSwitchState,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { alpha, Box, Button, capitalize, Card, CardContent, Chip, Skeleton, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';

import { PermissionWrapper } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../../../app/hooks';
import { KILL_SWITCH_ERROR_REASONS } from '../../Constants/KillswitchErrorReasons';
import KillSwitchHeaderPopoverContent from './KillSwitchHeaderPopoverContent';
import KillSwitchWarningDialog from './KillSwitchWarningDialog';

const KillSwitchStateField = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const canGetKillSwitchState = usePermission(OnlineCasinoPermissions.getKillSwitchState);
  const canSetKillSwitchState = usePermission(OnlineCasinoPermissions.setKillSwitchState);

  const [showWarningDialog, setShowWarningDialog] = useState(false);

  const {
    data: killSwitchState,
    isLoading: isGetKillSwitchStateLoading,
    isError: isGetKillSwitchStateError,
    error: getKillSwitchStateError,
  } = useGetKillSwitchState({ query: { enabled: canGetKillSwitchState } });

  const { mutate: setKillSwitchState, isLoading: isSetKillSwitchStateLoading } = useSetKillSwitchState({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetKillSwitchStateQueryKey());
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const isKillSwitchStateEnabled = killSwitchState?.state === EKillSwitchStateSchema.Enabled;

  const renderStylesHandler = useCallback(
    (isEnabled: boolean) => {
      return {
        backgroundColor: alpha(isEnabled ? theme.palette.error.light : theme.palette.success.light, 0.5),
        color: isEnabled ? theme.palette.error.dark : theme.palette.success.dark,
        fontWeight: 500,
        mr: 2,
        minWidth: 120,
        height: 50,
        fontSize: 'large',
        borderRadius: 10,
        border: `2px solid ${isEnabled ? theme.palette.error.dark : theme.palette.success.dark}`,
        textTransform: 'capitalize',
      };
    },
    [theme.palette.error.dark, theme.palette.error.light, theme.palette.success.dark, theme.palette.success.light]
  );

  const handleSwitchSubmit = () => {
    const lambdaInput = isKillSwitchStateEnabled
      ? KillSwitchLambdaParamParameter.Revive
      : KillSwitchLambdaParamParameter.Kill;
    setKillSwitchState({ params: { lambdaInput } });
    setShowWarningDialog(false);
  };

  if (isGetKillSwitchStateLoading || isSetKillSwitchStateLoading)
    return <Skeleton variant="rectangular" height={140} />;

  if (isGetKillSwitchStateError) {
    if ('type' in getKillSwitchStateError && getKillSwitchStateError.type) {
      const errorReason: EKillSwitchErrorTypeSchema | undefined = getKillSwitchStateError.type;
      const translationKey = errorReason ? KILL_SWITCH_ERROR_REASONS[errorReason] : 'somethingWentWrong';
      return <ErrorState errorMessage={t(translationKey)} />;
    } else {
      return <ErrorState errorMessage={t('somethingWentWrong')} />;
    }
  }

  return (
    <PermissionWrapper
      errorMessage={t('settings.killswitch.permissions.fetchKillSwitchError')}
      isError={isGetKillSwitchStateError}
      isLoading={false}
      permission={OnlineCasinoPermissions.getKillSwitchState}
    >
      <>
        <Card sx={{ borderRadius: 5 }}>
          <KillSwitchHeaderPopoverContent
            title={t('settings.killswitch.fields.stateTitle')}
            message="settings.killswitch.stateActionsInfo"
          />
          <CardContent>
            <Box my={1}>
              <Chip
                label={
                  isKillSwitchStateEnabled
                    ? capitalize(t('settings.killswitch.state.killed'))
                    : capitalize(t('settings.killswitch.state.alive'))
                }
                color="error"
                sx={() => renderStylesHandler(isKillSwitchStateEnabled)}
              />
              <Button
                variant="outlined"
                disabled={!canSetKillSwitchState}
                onClick={() => setShowWarningDialog(true)}
                sx={{
                  ...renderStylesHandler(!isKillSwitchStateEnabled),
                  backgroundColor: 'initial',
                  borderRadius: 2,
                  '&:hover': {
                    backgroundColor: alpha(
                      !isKillSwitchStateEnabled ? theme.palette.error.light : theme.palette.success.light,
                      0.5
                    ),
                    border: `2px solid ${
                      !isKillSwitchStateEnabled ? theme.palette.error.dark : theme.palette.success.dark
                    }`,
                  },
                }}
              >
                {!isKillSwitchStateEnabled
                  ? capitalize(t('settings.killswitch.state.kill'))
                  : capitalize(t('settings.killswitch.state.revive'))}
              </Button>
            </Box>
          </CardContent>
        </Card>
        {showWarningDialog && (
          <KillSwitchWarningDialog
            open={showWarningDialog}
            onClose={() => {
              setShowWarningDialog(false);
            }}
            onSubmit={handleSwitchSubmit}
            description={
              isKillSwitchStateEnabled
                ? t('settings.killswitch.warnings.reviveEnvironment')
                : t('settings.killswitch.warnings.killEnvironment')
            }
          />
        )}
      </>
    </PermissionWrapper>
  );
};

export default KillSwitchStateField;
