import { StartupSettingsSchema } from '@greenisland/stores';
import { FeatureFlag } from 'src/routes/RouteInterfaces';

export const hasFeatureFlags = (tenant: StartupSettingsSchema, flags: FeatureFlag | FeatureFlag[]) => {
  if (Array.isArray(flags)) {
    return flags.every((flag: FeatureFlag) => tenant[flag]);
  } else {
    return tenant[flags as FeatureFlag];
  }
};
