import { memo, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { GameSessionsActivityPeriodParamParameter, useGetGameSessionsActivity } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, Skeleton, Tab, Tabs } from '@mui/material';
import { getUnixTime, subWeeks } from 'date-fns';

import { ErrorCard, Link, TabPanel } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../app/hooks';
import GamesActivityDatesFilters from './GamesActivityDatesFilters';
import GamesActivityTableData from './GamesActivityTableData';

interface Props {
  id: string;
}

const GamesActivity = ({ id }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const canReadGamesSessionsActivity = usePermission(OnlineCasinoPermissions.getGameSessionsActivity);
  const [userId, setUserId] = useState<string>(id);
  const [showAllData, setShowAllData] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [startDate, setStartDate] = useState(subWeeks(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [activityPeriod, setActivityPeriod] = useState<GameSessionsActivityPeriodParamParameter>(
    GameSessionsActivityPeriodParamParameter.SinceLastWeek
  );

  const {
    data: gamesActivity,
    isLoading,
    isError,
    error,
  } = useGetGameSessionsActivity(
    Number(userId),
    {
      activityPeriod,
      ...(selectedTab === 2 && {
        startDate: getUnixTime(startDate),
        endDate: getUnixTime(endDate),
      }),
    },
    {
      query: { enabled: canReadGamesSessionsActivity && !!userId },
    }
  );

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const activeTab = queryParams.get('activeTab');
      const activityPeriod = queryParams.get('activityPeriod');

      if (activeTab) setSelectedTab(Number(activeTab));
      if (activityPeriod) setActivityPeriod(activityPeriod as GameSessionsActivityPeriodParamParameter);
    }
  }, [location.search]);

  useEffect(() => {
    if (!id && params?.userId) {
      setUserId(params.userId);
      setShowAllData(true);
    }
  }, [id, params]);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    if (newValue === 0) setActivityPeriod(GameSessionsActivityPeriodParamParameter.SinceLastWeek);
    if (newValue === 1) setActivityPeriod(GameSessionsActivityPeriodParamParameter.SinceLastDeposit);
    if (newValue === 2) setActivityPeriod(GameSessionsActivityPeriodParamParameter.Custom);
    setSelectedTab(newValue);
  };

  if (!canReadGamesSessionsActivity) {
    return (
      <ErrorCard severity="warning" sx={{ height: 500 }}>
        {t('gamesession.permissions.error.fetchError')}
      </ErrorCard>
    );
  }

  if (isLoading) return <Skeleton variant="rectangular" height={304} />;

  if (isError) return <ErrorCard sx={{ height: 304 }}>{error?.message}</ErrorCard>;

  return (
    <Card elevation={showAllData ? 0 : 1}>
      {!showAllData && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('gamesActivity')} />
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            color="primary"
            size="small"
            component={Link}
            {...{
              to: `../gameactivities?activeTab=${selectedTab}&&activityPeriod=${activityPeriod}`,
            }}
          >
            {t('seeMore')}
          </Button>
        </Box>
      )}
      <CardContent sx={{ pt: 0 }}>
        <Box mt={1}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs period" variant="scrollable">
            <Tab label={t('activityLastWeek')} id="tab-1" aria-controls="tab-panel-1" />
            <Tab label={t('activitySinceLastDeposit')} id="tab-2" aria-controls="tab-panel-2" />
            {showAllData && <Tab label={t('activityCustom')} id="tab-3" aria-controls="tab-panel-3" />}
          </Tabs>
        </Box>
        <TabPanel sx={{ mt: 2 }} value={selectedTab} index={0}>
          <GamesActivityTableData data={gamesActivity} />
        </TabPanel>
        <TabPanel sx={{ mt: 2 }} value={selectedTab} index={1}>
          <GamesActivityTableData data={gamesActivity} />
        </TabPanel>
        {showAllData && (
          <TabPanel sx={{ mt: 2 }} value={selectedTab} index={2}>
            <GamesActivityDatesFilters
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <GamesActivityTableData data={gamesActivity} />
          </TabPanel>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(GamesActivity);
