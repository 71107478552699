import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EAuthorTypeSchema,
  getGetComplaintTicketQueryKey,
  UpdateComplaintEntrySchema,
  useUpdateComplaintEntry,
} from '@greenisland/stores';
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

type EditComplaintEntryDialogProps = {
  open: boolean;
  setOpen: (state: boolean) => void;
  complaintId: number;
  entryId: number;
  entryContent: string;
  entryAuthor: EAuthorTypeSchema;
};

const EditComplaintEntryDialog = ({
  open,
  setOpen,
  complaintId,
  entryId,
  entryContent,
  entryAuthor,
}: EditComplaintEntryDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateComplaintEntryMutation = useUpdateComplaintEntry({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetComplaintTicketQueryKey(complaintId));
        setOpen(false);
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { register, handleSubmit, errors, control } = useForm<UpdateComplaintEntrySchema>({
    defaultValues: {
      content: entryContent,
      authorType: entryAuthor,
    },
    mode: 'onChange',
  });

  const onSubmit = (data: UpdateComplaintEntrySchema) => {
    updateComplaintEntryMutation.mutate({
      complaintTicketId: complaintId,
      complaintEntryId: entryId,
      data: {
        content: data.content,
        authorType: data.authorType,
      },
    });
  };

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{capitalize(t('tasks.complaints.editComplaintTitle'))}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              size="small"
              label={t('tasks.complaints.content')}
              type="text"
              name="content"
              multiline
              minRows={3}
              inputRef={register({
                required: true,
                maxLength: 1024,
              })}
              error={!!errors.content}
            />
            <Controller
              name="authorType"
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  size="small"
                  select
                  fullWidth
                  label={t('tasks.complaints.authorType')}
                  value={value}
                  onChange={onChange}
                  error={!!errors.authorType}
                >
                  {Object.values(EAuthorTypeSchema).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {t(key)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpen(false)}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ minHeight: '36px', minWidth: '90px' }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={updateComplaintEntryMutation.isLoading}
          >
            {t('tasks.complaints.updateComplaintEntry')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditComplaintEntryDialog;
