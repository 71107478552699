import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Button, Inputfield } from '@greenisland/components';
import { Search } from '@mui/icons-material';
import { CircularProgress, IconButton, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        marginTop: '0px !important',
        '& label': { color: 'white' },
      },
      '& span': { color: 'white' },
    },
  })
);

const Searchfield = (props: {
  handleSubmit: (displayname: string) => void;
  fetching: boolean;
  placeholder?: string;
}) => {
  const { t } = useTranslation();
  const classes = styles();
  const { pathname } = useLocation();

  const [inputvalue, setInputvalue] = useState<string>('');

  const handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputvalue(event.currentTarget.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInputvalue('');
    props.handleSubmit(inputvalue);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Inputfield
        size="small"
        fullWidth
        value={inputvalue}
        onChange={handleChange}
        placeholder={props.placeholder || t('quickSearch')}
        endIcon={
          props.fetching && pathname !== `${pathname}/lookup` ? (
            <Button variant="transparent" color="secondary" rounded size="small">
              <CircularProgress color="secondary" size={15} />
            </Button>
          ) : (
            <IconButton color="secondary" type="submit" size="small">
              <Search />
            </IconButton>
          )
        }
      />
    </form>
  );
};

export default memo(Searchfield);
