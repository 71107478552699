import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, CheckData, Link, ReactTableGI } from '@greenisland/components';
import { actionCreators, PlayerOverviewLanguages, PlayerOverviewUniqueLoginsByCountry } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import colors from '../../../../../app/helpers/colors';
import { formatDecimal, setInvisible, transformCurrency } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridGap: '15px 15px',
    marginBottom: 15,
  },
  font: {
    fontFamily: theme.typography.fontFamily,
  },
  toolTip: {
    backgroundColor: theme.palette.background.default,
    padding: 8,
    border: `1px solid ${theme.palette.grey[500]}`,
  },
}));

interface ChartData {
  name: string;
  value: number;
}

const PlayerOverview = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { data: playerOverview, fetching } = useAppSelector(state => state.reportingPlayerOverview);
  const canReadPlayerOverview = usePermission(OnlineCasinoPermissions.getPlayerOverview);

  useEffect(() => {
    if (canReadPlayerOverview) dispatch(actionCreators.getPlayerOverview());
  }, [dispatch, canReadPlayerOverview]);

  const mutatedActiveUsers = () => {
    if (!playerOverview || playerOverview.activeUsers.length === 0) return undefined;
    const entries = Object.entries(playerOverview.activeUsers[0]);
    const headers: any = entries.map(([key]) => {
      switch (key) {
        case 'promoBalance':
        case 'cashBalance':
          return transformCurrency(key, t);
        case 'userId':
          return setInvisible(key);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    const rows: any = playerOverview.activeUsers.map(item => ({
      ...item,
      username: <Link to={`../../../users/${item.userId}/details`}>{item.username}</Link>,
    }));

    return { headers, rows };
  };

  const constructLanguagesUniqueLoginsData = (data: {
    languages: PlayerOverviewLanguages[];
    uniqueLoginsByCountry: PlayerOverviewUniqueLoginsByCountry[];
  }): Array<JSX.Element> => {
    const jsxToReturn: Array<JSX.Element> = [];

    Object.entries(data).forEach(entry => {
      let totals = 0;
      const dataToRender: Array<ChartData> = [];
      const title: string = t(entry[0]);
      const entry1: PlayerOverviewLanguages[] | PlayerOverviewUniqueLoginsByCountry[] = entry[1];
      entry1.forEach((entry: PlayerOverviewLanguages | PlayerOverviewUniqueLoginsByCountry) => {
        const chartData: ChartData = {
          name: '',
          value: 0,
        };
        const values = Object.values(entry);
        const name: string = values[0];
        const value: number = values[1];
        chartData.name = name;
        chartData.value = value;
        totals = totals + value;
        dataToRender.push(chartData);
      });

      const CustomTooltip = ({ active, payload, label }: any) => {
        if (active) {
          return (
            <div className={classes.toolTip}>
              <div>{label}</div>
              <div>{`${t('amount')}: ${formatDecimal(payload[0].value)}`}</div>
              <div>{`${t('percentage')}: ${formatDecimal((payload[0].value / totals) * 100, true)}`}%</div>
            </div>
          );
        }

        return null;
      };

      const element: JSX.Element = (
        <Card key={`${title}_key`}>
          <CardHeader>{title}</CardHeader>
          <CardBody className={classes.font}>
            <CheckData
              fallbackText={t('noData')}
              data={dataToRender}
              isLoading={fetching}
              render={({ data }) => (
                <ResponsiveContainer width="100%" minHeight={300}>
                  <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="value">
                      {data.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            />
          </CardBody>
        </Card>
      );

      jsxToReturn.push(element);
    });

    return jsxToReturn;
  };

  const numberOfUsers = playerOverview?.activeUsers.length;

  return canReadPlayerOverview ? (
    <div className={classes.container}>
      <Card>
        <CardHeader>{`${t('activeUsers')}: ${numberOfUsers} users last 30 minutes`}</CardHeader>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedActiveUsers()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} sortable pagination />}
          />
        </CardBody>
      </Card>

      {constructLanguagesUniqueLoginsData({
        languages: playerOverview?.languages || [],
        uniqueLoginsByCountry: playerOverview?.uniqueLoginsByCountry || [],
      })}
    </div>
  ) : null;
};

export default memo(PlayerOverview);
