import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FreeSpinsCampaignSchema, useGetFreeSpinsCampaignsV2 } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, capitalize } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';

import { Link, PermissionWrapper } from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { formatCurrency } from '@greenisland-common/helpers';

import { usePermission } from '../../../../../app/hooks';
import FreeSpinsCampaignDialog from './FreeSpinsCampaignDialog';

const PAGE_SIZE = 25;

const FreeSpinsProviderCampaigns = () => {
  const { t, i18n } = useTranslation();
  const { providerId = '' } = useParams();

  const canReadFreeSpinCampaings = usePermission(OnlineCasinoPermissions.getFreeSpinsCampaignsV2);
  const canAddFreeSpinCampaings = usePermission(OnlineCasinoPermissions.addFreeSpinsCampaign);

  const [isModalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const { data, isLoading, isError } = useGetFreeSpinsCampaignsV2(providerId, {
    query: { enabled: canReadFreeSpinCampaings },
  });

  const columns = useMemo<GridColumns<FreeSpinsCampaignSchema>>(
    () => [
      { field: 'name', headerName: capitalize(t('campaign')), flex: 2 },
      { field: 'amountOfSpins', headerName: capitalize(t('amountOfSpins')), flex: 1 },
      { field: 'stake', headerName: capitalize(t('stakePerSpin')), flex: 1 },
      {
        field: 'gameName',
        headerName: capitalize(t('game')),
        renderCell: params => (
          <Link
            key={params.row.game?.id}
            to={`../../content/games/providers/${providerId}/games/${params.row.game?.id}`}
          >
            {params.row.game?.name}
          </Link>
        ),
        flex: 2,
      },
    ],
    [providerId, t]
  );

  const rows = useMemo(
    () =>
      data?.map(campaign => ({
        ...campaign,
        stake: typeof campaign.stake === 'number' ? formatCurrency(campaign.stake, i18n.language) : '',
        gameName: campaign.game?.name ?? '',
        id: campaign.campaignId,
      })) ?? [],
    [i18n, data]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  return (
    <>
      {canAddFreeSpinCampaings && (
        <Button sx={{ mb: 2 }} variant="contained" onClick={() => setModalOpen(true)}>
          {t('addFreeSpinsCampaign')}
        </Button>
      )}
      <PermissionWrapper
        errorMessage={t('marketing.freeSpinsCampaigns.errors.fetchFreeSpinsCampaignsError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getFreeSpinsCampaignsV2}
      >
        <LimitedDataGrid
          density="compact"
          autoHeight
          rowHeight={50}
          loading={isLoading}
          columns={columns}
          rows={rows}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="client"
          components={{
            Footer: () => (
              <DataGridCustomPagination
                page={page}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[10, 25, 50, 100, 200]}
                count={data?.length ? Math.ceil(data.length / pageSize) : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                paginationMode="client"
              />
            ),
          }}
        />
      </PermissionWrapper>
      {isModalOpen && <FreeSpinsCampaignDialog onClose={() => setModalOpen(false)} isOpen={isModalOpen} />}
    </>
  );
};

export default memo(FreeSpinsProviderCampaigns);
