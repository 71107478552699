import React, { Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, Card, CardBody, CardHeader, CheckData, Link } from '@greenisland/components';
import { actionCreators, LinkedAccountData, LinkedAccountUsers } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridGap: '15px',
    marginBottom: 15,
  },
  linkedAccountContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    width: '100%',
  },
  indLinkedContainer: {
    flex: '1 1 33%',
  },
  linkedTitle: {
    margin: 0,
    marginBottom: 20,
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  tableCellExpand: {
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
  },
  collapseContainer: {
    display: 'grid',
    '& h5': {
      fontWeight: 'normal',
      margin: '1rem 0 1rem 0',
    },
  },
  button: {
    display: 'inline',
  },
});

const LinkedAccounts = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId = '' } = useParams();
  const [expanded, setExpanded] = useState('');
  const { data: userLinkedAccount, fetching } = useAppSelector(state => state.userLinkedAccounts);

  useEffect(() => {
    dispatch(actionCreators.getUserLinkedAccounts(userId));
  }, [dispatch, userId]);

  const prepareData = (userLinkedAccount: LinkedAccountUsers) => {
    const objectToReturn: { [key: string]: string[] } = {};

    userLinkedAccount.links.forEach((data: LinkedAccountData) => {
      if (objectToReturn[data.type]) {
        objectToReturn[data.type].push(data.data);
      } else {
        objectToReturn[data.type] = [data.data];
      }
    });

    return objectToReturn;
  };

  type ListProps = {
    data: {
      [key: string]: string[];
    };
  };

  const LinkedAccountsList = ({ data }: ListProps) => {
    if (data) {
      const testArray: any = [];
      const entries = Object.entries(data);
      entries.forEach((entry: any, index) => {
        const [key, data] = entry;

        testArray.push(
          <div key={`${key}-${index}`} className={classes.indLinkedContainer}>
            <h5 className={classes.linkedTitle}>
              <strong>{t(key)}</strong>
            </h5>
            {data && data.map((content: string, index: number) => <p key={`${key}-${index}`}>{content}</p>)}
          </div>
        );
      });

      return <div className={classes.linkedAccountContainer}>{testArray}</div>;
    }

    return null;
  };

  const handleExpandClick = (id: string) => {
    const idToUse: string = id === expanded ? '' : id;
    setExpanded(idToUse);
  };

  const transformRows = (object: LinkedAccountUsers, types: string[]) => {
    const rows: any = [];
    let indexMem = 0;
    Object.entries(object).forEach((entry, index) => {
      const [key, value] = entry;
      if (key === 'linkedUsername') {
        rows.push(
          <TableCell key={`${index}_TableCellRowGeneralNormal`}>
            <Link to={`../../users/${object.linkedUserId}/details`}>{`${value} (${types.join(',')})`}</Link>
          </TableCell>
        );
      }
      if (key) indexMem = index;
    });
    rows.push(
      <TableCell align="right" key={`${indexMem + 1}_TableCellRowGeneralExpand`}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.button}
          onClick={() => handleExpandClick(object.linkedUserId)}
        >
          {t('details')}
        </Button>
      </TableCell>
    );
    return rows;
  };

  return (
    <div className={classes.container}>
      <CheckData
        fallbackText={t('noData')}
        data={userLinkedAccount}
        isLoading={fetching}
        loadDataOnMount={userLinkedAccount?.userId !== userId}
        render={({ data }) => (
          <Card>
            <CardHeader>{data.username}</CardHeader>
            {data.linkedUsers.length > 0 ? (
              <CardBody>
                <Table>
                  <TableBody>
                    {data &&
                      data.linkedUsers &&
                      data.linkedUsers.map((row, index) => {
                        const isOpen: boolean = expanded === row.linkedUserId ? true : false;
                        const prepared = prepareData(row);
                        return (
                          <Fragment key={index}>
                            <TableRow key={`${index}_tableRowGeneral`}>
                              {transformRows(row, Object.keys(prepared))}
                            </TableRow>
                            <TableRow key={`${index}_tableRowDetails`}>
                              <TableCell
                                className={isOpen ? '' : classes.tableCellExpand}
                                colSpan={12}
                                style={{ minWidth: 100 }}
                              >
                                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                  <div className={classes.collapseContainer}>
                                    <LinkedAccountsList data={prepared} />
                                  </div>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </CardBody>
            ) : (
              <CardBody padding>
                <p>{t('noLinkedAccounts')}</p>
              </CardBody>
            )}
          </Card>
        )}
      />
    </div>
  );
};

export default memo(LinkedAccounts);
