import { memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Inputfield,
  Loading,
  TransferList,
} from '@greenisland/components';
import {
  AgentPermissionSchema,
  AgentPermissionsSchema,
  AgentRoleSchema,
  AgentRolesSchema,
  Authorization,
  useGetAgentRoleDetails,
  useGetListAgentRoles,
  useGetPermissions,
  useUpdateAgentRole,
} from '@greenisland/stores';
import { Stack } from '@mui/material';

type FormData = {
  name: string;
  crossEntityRole: boolean;
};

const SetRole = () => {
  const { t } = useTranslation();
  const { agentRoleId = '' } = useParams();
  const { data: agentPermissions } = useGetPermissions();
  const { data: agentRoles } = useGetListAgentRoles();
  const { data: agentRole } = useGetAgentRoleDetails(agentRoleId);
  const { mutate: setRole } = useUpdateAgentRole();
  const [selectedPermissions, setSelectedPermissions] = useState<AgentPermissionsSchema>([]);
  const [selectedRoles, setSelectedRoles] = useState<AgentRolesSchema>([]);
  const { register, handleSubmit, errors, control, reset } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    const permissionIds = selectedPermissions.map((perm: AgentPermissionSchema) => perm.permissionId);
    const agentRoleIds = selectedRoles.map((role: AgentRoleSchema) => role.roleId);
    setRole({
      agentRoleId: agentRoleId,
      data: { roleName: data.name, permissionIds, agentRoleIds, crossEntityRole: data.crossEntityRole },
    });
    if (!agentRoleId) reset();
  };

  useEffect(() => {
    if (agentRoleId && agentRole?.permissions) setSelectedPermissions(agentRole.permissions);
    if (agentRoleId && agentRole?.agentRoles) setSelectedRoles(agentRole.agentRoles);
  }, [agentRole, agentRoleId]);

  if ((agentRoleId && agentRoleId !== agentRole?.roleId) || !agentPermissions || !agentRoles) {
    return <Loading center />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardBody padding>
          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, 1fr)',
              gridGap: '15px 15px',
              marginBottom: 15,
            }}
          >
            <Inputfield
              label={t('name')}
              fullWidth
              error={!!errors.name}
              name={'name'}
              defaultValue={agentRoleId && agentRole?.roleName}
              inputRef={register({ required: true })}
            />
            <Controller
              defaultValue={(agentRoleId && agentRole?.crossEntityRole) || false}
              render={props => {
                return (
                  <Checkbox
                    label={t('crossEntityRole')}
                    color="primary"
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                );
              }}
              control={control}
              name={'crossEntityRole'}
            />
            {agentRoleId ? (
              <>
                <TransferList
                  helperText="category-subCategory-level-name"
                  leftTitle={'availablePermissions'}
                  rightTitle={'selectedPermissions'}
                  allItems={agentPermissions.map(item => ({
                    original: item,
                    name: `${item.level} ${item.path}`,
                    id: item.permissionId,
                  }))}
                  selectedItems={
                    (agentRoleId &&
                      agentRole?.permissions?.map(item => ({
                        original: item,
                        name: `${item.level} ${item.path}`,
                        id: item.permissionId,
                      }))) ||
                    []
                  }
                  onSelected={selected => setSelectedPermissions(selected as Authorization.AgentPermissions)}
                />
                <TransferList
                  leftTitle={'availableRoles'}
                  rightTitle={'selectedRoles'}
                  allItems={agentRoles.map(item => ({
                    original: item,
                    name: item.roleName,
                    id: item.roleId,
                    detailsLink: `../../${item.roleId}`,
                  }))}
                  selectedItems={
                    (agentRoleId &&
                      agentRole?.agentRoles?.map(item => ({
                        original: item,
                        name: item.roleName,
                        id: item.roleId,
                      }))) ||
                    []
                  }
                  onSelected={selected => setSelectedRoles(selected as Authorization.AgentRoles)}
                />
              </>
            ) : (
              <p>{t('createNewRoleInfo')}</p>
            )}
          </Stack>
        </CardBody>
        <CardFooter>
          <Button fullWidth color="primary" variant="transparent" size="small" type="submit">
            {t('save')}
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};

export default memo(SetRole);
