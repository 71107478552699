import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserLoyaltyDetails } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  DataGridPlain,
  DataGridPlainHeader,
  Link,
  NoData,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';

import { useGetLoyaltyUserErrorString } from './Hooks/useGetLoyaltyUserErrorString';
import loyaltyBadges from '../../../../../../app/constants/ranks';
import { formatDecimal, getDateTimeFromUnix } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import AwardLoyaltyPointsDialog from '../AwardLoyaltyPointsDialog';

interface Props {
  userId: number;
}

const Loyalty = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadRank = usePermission(OnlineCasinoPermissions.getLoyaltyRankUsers);
  const canReadUserLoyaltyDetails = usePermission(OnlineCasinoPermissions.getUserLoyaltyDetails);
  const canAwardLoyaltyCoins = usePermission(OnlineCasinoPermissions.awardLoyaltyPointsToUser);
  const [open, setOpen] = useState<boolean>(false);
  const {
    data: loyaltyDetails,
    isLoading,
    isError,
    error,
  } = useGetUserLoyaltyDetails(userId, {
    query: { enabled: canReadUserLoyaltyDetails },
  });
  const getErrorString = useGetLoyaltyUserErrorString();

  if (isLoading) return <Skeleton variant="rectangular" height={359} />;

  const { color, imagePath } = loyaltyBadges[loyaltyDetails?.rank || 'Standard'] || loyaltyBadges.Standard;

  const headers: GridColumns = [
    {
      headerName: '',
      field: 'title',
      renderCell: ({ value }) => <DataGridPlainHeader value={value} />,
      width: 200,
    },
    {
      headerName: 'value',
      field: 'value',
      renderCell: params => {
        let value;
        switch (params.row.title) {
          case 'statusPoints':
          case 'pointsToNextRank':
          case 'pointsToRankDown':
          case 'spendablePoints':
            value = formatDecimal(params.value, true);
            break;
          default:
            value = params.value;
        }
        return value;
      },
      flex: 1,
    },
  ];

  const rows: GridRowsProp = Object.entries(loyaltyDetails || []).map(([title, value], i) => {
    const row = { id: i, title };
    switch (title) {
      case 'rank':
        Object.assign(row, {
          value:
            canReadRank && value ? (
              <Link
                sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                to={`../../reporting/loyalty/ranks/${value.toLowerCase()}`}
              >
                <img style={{ height: 18 }} src={imagePath} alt={value} />
                <Box component="span" sx={{ ml: 1 }} color={color}>
                  {value}
                </Box>
              </Link>
            ) : (
              t('noRank')
            ),
        });
        break;
      case 'lastUpdated':
        Object.assign(row, { value: getDateTimeFromUnix(value, true) });
        break;
      default:
        Object.assign(row, { value });
    }
    return row;
  });

  return (
    <PermissionWrapper
      errorMessage={getErrorString(error)}
      isError={isError || !loyaltyDetails}
      isLoading={isLoading}
      permission={OnlineCasinoPermissions.getUserLoyaltyDetails}
    >
      <>
        <Card sx={{ pl: 0, pr: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CardHeader title={capitalize(t('loyalty'))} />
            {canAwardLoyaltyCoins && (
              <Button sx={{ mr: 2 }} size="small" variant="outlined" color="secondary" onClick={() => setOpen(true)}>
                {t('award')}
              </Button>
            )}
          </Box>
          <CardContent sx={{ pl: 0, pr: 0 }}>
            {rows.length > 0 ? (
              <DataGridContainer>
                <DataGridPlain
                  sx={{
                    '& .Mui-error': {
                      height: '100%',
                      backgroundColor: `rgb(126,10,15,0.1)`,
                      color: 'error.main',
                    },
                  }}
                  columns={headers}
                  rows={rows}
                />
              </DataGridContainer>
            ) : (
              <NoData />
            )}
          </CardContent>
        </Card>
        <AwardLoyaltyPointsDialog open={open} setOpen={setOpen} userId={userId} />
      </>
    </PermissionWrapper>
  );
};

export default Loyalty;
