import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader } from '@greenisland/components';
import { useGetIndividualAction } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress } from '@mui/material';

import { usePermission } from '../../../../../../app/hooks';
import IndividualActionForm from './IndividualActionForm';

const IndividualAction = () => {
  const { t } = useTranslation();
  const { campaignId, actionId }: any = useParams();
  const canAccessIndividualActions = usePermission(OnlineCasinoPermissions.addIndividualAction);

  const { data: individualAction, isLoading } = useGetIndividualAction(campaignId, actionId, {
    query: { enabled: canAccessIndividualActions && actionId !== 'add' },
  });

  if (!canAccessIndividualActions) return null;
  if (isLoading) return <CircularProgress />;

  return (
    <Box sx={{ maxWidth: '550px' }}>
      <Card>
        <CardHeader>{actionId === 'add' ? t('addAction') : `${t('editAction')} ${individualAction?.name}`}</CardHeader>
        <CardBody>
          <IndividualActionForm individualAction={individualAction} actionId={actionId} campaignId={campaignId} />
        </CardBody>
      </Card>
    </Box>
  );
};

export default IndividualAction;
