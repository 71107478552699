import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleCheck, faCircleInfo, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EResponsibleGamingTestResponseStateSchema } from '@greenisland/stores';
import { Box, Tooltip, useTheme } from '@mui/material';

interface Props {
  state: EResponsibleGamingTestResponseStateSchema;
}

const ResponsibleGamingTestResponsesState = ({ state }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const renderStateHandler = useCallback(
    (state: EResponsibleGamingTestResponseStateSchema) => {
      let color, title, icon;

      switch (state) {
        case EResponsibleGamingTestResponseStateSchema.processed: {
          color = theme.palette.success.main;
          title = 'responsibleGaming.testResponses.titles.processed';
          icon = faCircleCheck;
          break;
        }
        case EResponsibleGamingTestResponseStateSchema.unprocessed:
          color = theme.palette.text.primary;
          title = 'responsibleGaming.testResponses.titles.unprocessed';
          icon = faCircleQuestion;
          break;
        case EResponsibleGamingTestResponseStateSchema.waitingForManualReview:
          color = theme.palette.warning.main;
          title = 'responsibleGaming.testResponses.titles.waitingForManualReview';
          icon = faCircleInfo;
          break;
      }

      return (
        <Tooltip title={t(title)} placement="right" arrow>
          <Box>
            <FontAwesomeIcon icon={icon} color={color} />
          </Box>
        </Tooltip>
      );
    },
    [t, theme.palette.success.main, theme.palette.text.primary, theme.palette.warning.main]
  );

  return renderStateHandler(state);
};

export default ResponsibleGamingTestResponsesState;
