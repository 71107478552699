import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EWithdrawalTypeSchema,
  getGetRequestedWithdrawalsV2QueryKey,
  useDeclineWithdrawalV2,
} from '@greenisland/stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

const DeclineDialog = memo(
  (props: { withdrawalId: string; destinationName: EWithdrawalTypeSchema; close: () => void }) => {
    const { withdrawalId, destinationName, close } = props;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const [inputValue, setInputValue] = useState<string>('');

    const { mutate: declineWithdrawal } = useDeclineWithdrawalV2({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetRequestedWithdrawalsV2QueryKey(destinationName));
          enqueueSnackbar(t('success'), { variant: 'success' });
        },
        onError: () => {
          enqueueSnackbar(t('error'), { variant: 'error' });
        },
      },
    });

    const handleSubmit = () => {
      if (inputValue.length > 0) {
        declineWithdrawal({ withdrawalId: parseInt(withdrawalId), data: inputValue });
        close();
      }
    };

    return (
      <Dialog open={true} fullWidth onClose={close}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{t('declineDialog')}</DialogTitle>
          <DialogContent>
            <TextField
              label={t('reason')}
              error={inputValue.length === 0}
              fullWidth
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          </DialogContent>
          <DialogActions sx={{ minWidth: 'auto !important', maxWidth: 'inherit !important' }}>
            <Button onClick={() => close()} color="secondary" variant="text">
              {t('cancel')}
            </Button>
            <Button type="submit" color="error" disabled={inputValue.length === 0}>
              {t('decline')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);

export default DeclineDialog;
