import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import {
  ErrorSchema,
  EVerificationStatus,
  VerificationsSchema,
  VerificationStatusArguments,
} from '@greenisland/stores';
import { VerificationStatusArgumentsSchema } from '@greenisland/stores/dist/api/schemas';
import { Box, Button, CircularProgress } from '@mui/material';

import { VerificationStatusSpan } from '../../../../../../../../app/helpers/transformFunctions';

interface Props {
  userId: string;
  status: any;
  updateStatus: UseMutationResult<
    VerificationsSchema,
    ErrorSchema,
    { userId: string; data: VerificationStatusArgumentsSchema },
    unknown
  >;
}

const UpdateVerificationStatusField = ({ userId, status, updateStatus }: Props) => {
  const { t } = useTranslation();

  const updateVerificationStatus: (values: VerificationStatusArguments) => void = async values =>
    updateStatus.mutate({ userId, data: values });

  return (
    <Box>
      <VerificationStatusSpan value={status.verificationStatus} />
      {status.verificationStatus === EVerificationStatus.Unverified && (
        <Button
          size="small"
          sx={{ ml: 2 }}
          variant="outlined"
          onClick={() => updateVerificationStatus({ verificationStatus: EVerificationStatus.NeedsVerification })}
        >
          {updateStatus.isLoading ? <CircularProgress size={20} /> : t('needsVerification')}
        </Button>
      )}
    </Box>
  );
};

export default UpdateVerificationStatusField;
