import { Authorization } from '@greenisland/stores';
import { ReadPermission, WritePermission } from '@greenisland-core/permissions';

export const AuthorizationPermissions = {
  ping: new ReadPermission('/ping', Authorization.EProductType.Authorization),
  login: new WritePermission('/authorization/login', Authorization.EProductType.Authorization),
  loginTokens: new ReadPermission('/authorization/login/$logincode', Authorization.EProductType.Authorization),
  logoff: new WritePermission('/authorization/logoff', Authorization.EProductType.Authorization),
  loginWithChallenge: new WritePermission('/authorization/loginchallenge', Authorization.EProductType.Authorization),
  resetPassword: new WritePermission('/authorization/resetpassword', Authorization.EProductType.Authorization),
  changePassword: new WritePermission('/authorization/changepassword', Authorization.EProductType.Authorization),
  getStartup: new ReadPermission('/authorization/startup', Authorization.EProductType.Authorization),
  getListEntities: new ReadPermission('/authorization/entities', Authorization.EProductType.Authorization),
  addEntity: new WritePermission('/authorization/entities', Authorization.EProductType.Authorization),
  getEntityDetails: new ReadPermission('/authorization/entities/$entityid', Authorization.EProductType.Authorization),
  updateEntity: new WritePermission('/authorization/entities/$entityid', Authorization.EProductType.Authorization),
  deleteEntity: new WritePermission('/authorization/entities/$entityid', Authorization.EProductType.Authorization),
  getListProducts: new ReadPermission('/authorization/products', Authorization.EProductType.Authorization),
  addProduct: new WritePermission('/authorization/products', Authorization.EProductType.Authorization),
  getProductDetails: new ReadPermission('/authorization/products/$productid', Authorization.EProductType.Authorization),
  updateProduct: new WritePermission('/authorization/products/$productid', Authorization.EProductType.Authorization),
  deleteProduct: new WritePermission('/authorization/products/$productid', Authorization.EProductType.Authorization),
  getCurrentPermissions: new ReadPermission(
    '/authorization/permissions/current',
    Authorization.EProductType.Authorization
  ),
  getPermissions: new ReadPermission('/authorization/permissions', Authorization.EProductType.Authorization),
  listPermissionPropertyNames: new ReadPermission(
    '/authorization/permissions/name/$permissionid',
    Authorization.EProductType.Authorization
  ),
  addNamePermissions: new WritePermission(
    '/authorization/permissions/name/$permissionid',
    Authorization.EProductType.Authorization
  ),
  deleteNamePermissions: new WritePermission(
    '/authorization/permissions/name/$permissionid',
    Authorization.EProductType.Authorization
  ),
  getListAgents: new ReadPermission('/authorization/agents', Authorization.EProductType.Authorization),
  addAgent: new WritePermission('/authorization/agents', Authorization.EProductType.Authorization),
  getCurrentAgent: new ReadPermission('/authorization/agents/current', Authorization.EProductType.Authorization),
  getAgentDetails: new ReadPermission('/authorization/agents/$agentid', Authorization.EProductType.Authorization),
  updateAgent: new WritePermission('/authorization/agents/$agentid', Authorization.EProductType.Authorization),
  deleteAgent: new WritePermission('/authorization/agents/$agentid', Authorization.EProductType.Authorization),
  deleteAgentEntityReference: new WritePermission(
    '/authorization/agents/$agentid/$entityid',
    Authorization.EProductType.Authorization
  ),
  resetAgentPassword: new WritePermission(
    '/authorization/agents/$agentid/resetpassword',
    Authorization.EProductType.Authorization
  ),
  lockAgent: new WritePermission('/authorization/agents/$agentid/lock', Authorization.EProductType.Authorization),
  unlockAgent: new WritePermission('/authorization/agents/$agentid/lock', Authorization.EProductType.Authorization),
  getListAgentRoles: new ReadPermission('/authorization/agentroles', Authorization.EProductType.Authorization),
  addAgentRole: new WritePermission('/authorization/agentroles', Authorization.EProductType.Authorization),
  getAgentRoleDetails: new ReadPermission(
    '/authorization/agentroles/$agentroleid',
    Authorization.EProductType.Authorization
  ),
  updateAgentRole: new WritePermission(
    '/authorization/agentroles/$agentroleid',
    Authorization.EProductType.Authorization
  ),
  deleteAgentRole: new WritePermission(
    '/authorization/agentroles/$agentroleid',
    Authorization.EProductType.Authorization
  ),
  getAuditLogs: new WritePermission('/authorization/audit', Authorization.EProductType.Authorization),
};

export type AuthorizationPermissionTypes = keyof typeof AuthorizationPermissions;
