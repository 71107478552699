import { memo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EPaymentCredentialWhitelistTypeSchema,
  getGetDepositDetailsQueryKey,
  useMarkDepositSafe,
  useWhitelistCredentials,
} from '@greenisland/stores';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';

import { SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    marginLeft: 24,
  },
  container: {
    display: 'grid',
    gridGap: '16px 16px',
    marginBottom: 16,
  },
  nickname: {
    fontSize: theme.typography.h5.fontSize,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontWeight: theme.typography.h5.fontWeight,
  },
  details: {
    fontSize: theme.typography.body1.fontSize,
    letterSpacing: theme.typography.body1.letterSpacing,
    fontWeight: theme.typography.body1.fontWeight,
    display: 'grid',
    margin: '32px 16px',
    gridGap: 32,
    textTransform: 'capitalize',
    gridTemplateColumns: 'auto auto auto auto',
    alignItems: 'start',
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  subDetails: {
    fontSize: theme.typography.caption.fontSize,
    letterSpacing: theme.typography.caption.letterSpacing,
    fontWeight: theme.typography.caption.fontWeight,
    fontStyle: 'italic',
  },
  actionButton: {
    alignSelf: 'flex-start',
    justifySelf: 'flex-start',
    width: '100%',
  },
  centered: {
    textAlign: 'center',
  },
  confirmButton: {
    variant: 'contained',
  },
  actions: {
    padding: '16px 24px',
  },
}));

interface Credential {
  id?: number;
  value: string;
  type: EPaymentCredentialWhitelistTypeSchema;
}

type MarkAsSafeProps = {
  userId: string;
  isOpen: boolean;
  depositId: string;
  onClose: () => void;
};

const MarkAsSafeDialog = ({ userId, isOpen, depositId, onClose }: MarkAsSafeProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [openWhitelist, setOpenWhitelist] = useState<boolean>(false);
  const methods = useForm<Credential>();
  const { handleSubmit, errors } = methods;

  const { mutate: addWhitelistCredentials } = useWhitelistCredentials({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: e => {
        enqueueSnackbar(`${e.code}:${t('error')}`, { variant: 'error' });
      },
    },
  });
  const { mutate: markAsSafe } = useMarkDepositSafe({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.markAsSafe'), { variant: 'success' });
      },
      onError: e => {
        enqueueSnackbar(`${e.code}: ${t('deposits.errors.markAsSafe')}`, { variant: 'error' });
      },
    },
  });

  const onSubmit = (data: Credential) => {
    if (data.value != undefined) {
      addWhitelistCredentials({ userId: parseInt(userId), whitelistType: data.type, data: [data.value] });
    }
    markAsSafe({ depositId });
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            {openWhitelist
              ? t('deposits.confirmations.whitelistCredentials.title')
              : t('deposits.confirmations.markAsSafe.title')}
          </DialogTitle>
          <DialogContentText className={classes.description}>
            {openWhitelist
              ? t('deposits.confirmations.whitelistCredentials.description')
              : t('deposits.confirmations.markAsSafe.description')}
          </DialogContentText>
          {openWhitelist && (
            <DialogContent className={classes.container}>
              <Input
                name="value"
                label={t('value')}
                type={'text'}
                required
                error={!!errors.value}
                rules={{ min: 1 }}
                defaultValue={''}
              />
              <SelectInput
                name="type"
                label={t('type')}
                options={Object.values(EPaymentCredentialWhitelistTypeSchema).map(action => ({
                  label: action,
                  value: action,
                }))}
                defaultValue={EPaymentCredentialWhitelistTypeSchema.cardHolderName}
              />
            </DialogContent>
          )}
          <DialogActions className={classes.actions}>
            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                onClose();
                setOpenWhitelist(false);
              }}
            >
              {t('cancel')}
            </Button>
            <Button variant="contained" type="submit">
              {openWhitelist ? t('submit') : t('deposits.actions.markAsSafe')}
            </Button>
            {!openWhitelist && (
              <Button variant="contained" onClick={() => setOpenWhitelist(true)}>
                {t('deposits.actions.alsoWhitelistCredential')}
              </Button>
            )}
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default memo(MarkAsSafeDialog);
