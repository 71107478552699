import React, { memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, Card, CardBody, CardHeader, Inputfield, Select } from '@greenisland/components';
import {
  Base64File,
  Base64FileSchema,
  CampaignTemplateDetails,
  CampaignTemplateUpdate,
  EMIMEType,
  TemplateTypes,
} from '@greenisland/stores';
import makeStyles from '@mui/styles/makeStyles';

import { FileUpload } from '@greenisland-common/components/molecules/FileUpload/FileUpload';
import NavPills from '@greenisland-common/components/molecules/NavPills/NavPills';
import TextEditor from '@greenisland-common/components/molecules/TextEditor/TextEditor';

const styles = makeStyles({
  navPill: {
    '& button': {
      borderRadius: '3px',
      boxShadow: 'none',
    },
  },
  innerContainer: {
    display: 'grid',
    gridGap: '15px',
  },
  inputWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gridGap: '15px',
  },
  errorText: {
    color: 'orangered',
  },
  saveButton: {
    marginTop: 15,
  },
});

type TemplateFormProps = {
  onSubmit: (data: CampaignTemplateUpdate) => void;
  templateTypes: TemplateTypes;
  templateData?: CampaignTemplateDetails;
  returnLink: string;
};

type FormData = {
  name: string;
  type: any;
  ENTitle: string;
  ENBody: string;
  ENImage: Base64File | string;
  NLTitle: string;
  NLBody: string;
  NLImage: Base64File | string;
  FRTitle: string;
  FRBody: string;
  FRImage: Base64File | string;
};

const TemplateForm = ({ onSubmit, templateTypes, templateData }: TemplateFormProps) => {
  const classes = styles();
  const { t } = useTranslation();
  const { templateType }: any = useParams();

  const [activeTab, setActiveTab] = useState(0);
  const { register, handleSubmit, control, errors, watch, getValues } = useForm<FormData>({
    defaultValues: {
      name: templateData?.name,
      type: templateData?.type,
      ENTitle: templateData?.content.find(language => language.language === 'EN')?.title,
      ENBody: templateData?.content.find(language => language.language === 'EN')?.body,
      ENImage: templateData?.content.find(language => language.language === 'EN')?.imageUrl,
      NLTitle: templateData?.content.find(language => language.language === 'NL')?.title,
      NLBody: templateData?.content.find(language => language.language === 'NL')?.body,
      NLImage: templateData?.content.find(language => language.language === 'NL')?.imageUrl,
      FRTitle: templateData?.content.find(language => language.language === 'FR')?.title,
      FRBody: templateData?.content.find(language => language.language === 'FR')?.body,
      FRImage: templateData?.content.find(language => language.language === 'FR')?.imageUrl,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const values = getValues();
  const typeWatch = watch('type');

  const getOptions = (key: string) => {
    if (templateTypes.length > 0) {
      const selectedEntry = templateTypes.find((entry: any) => entry.name === key);
      if (selectedEntry && selectedEntry.availableFields) return selectedEntry.availableFields;
      return [];
    }
    return [];
  };

  useEffect(() => {
    const keys = Object.keys(errors)
      .map(key => key.substring(0, 2))
      .sort((_, b) => {
        if (b === 'EN') return 1;
        if (b === 'FR') return -1;
        return 0;
      });
    for (let i = 0; i < keys.length; i++) {
      const language = keys[i];
      if (language === 'EN') {
        setActiveTab(0);
        break;
      }
      if (language === 'NL') {
        setActiveTab(1);
        break;
      }
      if (language === 'FR') {
        setActiveTab(2);
        break;
      }
    }
  }, [errors]);

  const onSubmitForm = (data: FormData) => {
    onSubmit({
      name: data.name,
      type: data.type,
      content: [
        {
          body: data.ENBody,
          image: typeof data.ENImage === 'string' ? null : (data.ENImage as any),
          language: 'EN',
          title: data.ENTitle,
        },
        {
          body: data.NLBody,
          image: typeof data.NLImage === 'string' ? null : (data.NLImage as any),
          language: 'NL',
          title: data.NLTitle,
        },
        {
          body: data.FRBody,
          image: typeof data.FRImage === 'string' ? null : (data.FRImage as any),
          language: 'FR',
          title: data.FRTitle,
        },
      ],
    });
  };

  const hasOptions = () => {
    const availableFields = templateTypes.find((entry: any) => entry.name === typeWatch)?.availableFields;
    return typeWatch && templateTypes.length > 0 && availableFields && availableFields.length > 0;
  };

  const constructTabs = () => {
    const languages = ['EN', 'NL', 'FR'];
    const toReturn: Array<any> = languages.map(language => {
      return {
        tabButton: t(language),
        tabContent: (
          <>
            <CardHeader>{t(language)}</CardHeader>
            <CardBody>
              <div className={classes.innerContainer}>
                <div className={classes.inputWrapper}>
                  <Inputfield
                    error={!!errors[`${language}Title` as keyof FormData]}
                    type="text"
                    label={t('title')}
                    fullWidth
                    name={`${language}Title`}
                    inputRef={register({ required: true, validate: value => value.length > 0 })}
                  />
                  <Controller
                    render={({ value, onChange }) => (
                      <FileUpload
                        error={!!errors[`${language}Image` as keyof FormData]}
                        id="loyaltyShop"
                        type="image"
                        onChange={(file: Base64FileSchema) => onChange(file)}
                        defaultFile={value}
                        buttonTitle="uploadImage"
                        shouldBeAbleToSave={false}
                        requirements={{
                          dimensions: { width: 600 },
                          mimeTypes: [EMIMEType.Imagejpeg, EMIMEType.Imagejpg],
                        }}
                      />
                    )}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={getValues(`${language}Image`)}
                    name={`${language}Image`}
                  />
                </div>
                {!!errors[`${language.toUpperCase()}Body` as keyof FormData] && (
                  <span className={classes.errorText}>{t('bodyRequired')}</span>
                )}
                <Controller
                  name={`${language.toUpperCase()}Body`}
                  control={control}
                  defaultValue={values[`${language.toUpperCase()}Body` as keyof FormData]}
                  rules={{ required: true, validate: value => value.length > 0 }}
                  render={({ onChange, value }) => <TextEditor onEditorChange={onChange} value={value} />}
                />
              </div>
            </CardBody>
          </>
        ),
      };
    });

    return toReturn;
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Card>
        <CardHeader>{`${templateType === 'add' ? t('addTemplate') : t('editTemplate')}`}</CardHeader>
        <CardBody padding>
          <div className={classes.innerContainer}>
            <div className={classes.inputWrapper}>
              <Inputfield
                error={!!errors.name}
                type="text"
                label={t('name')}
                fullWidth
                name="name"
                inputRef={register({ required: true, validate: value => value.length > 0 })}
              />
              {templateTypes.length > 0 && (
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      label={t('templateType')}
                      menuItems={templateTypes.map((entry: any) => {
                        return { value: entry.name, label: entry.name };
                      })}
                      onChange={e => onChange(e)}
                      disabled={templateType !== 'add'}
                      selectedValue={value}
                    />
                  )}
                  rules={{ required: true, validate: value => value.length > 0 }}
                  control={control}
                  fullWidth
                  name="type"
                />
              )}
            </div>
            <div>
              <p>{t('templateFormatExplanation')}</p>
              <p>{t('availableFields')}:</p>
              {hasOptions() ? (
                <ul>
                  {getOptions(typeWatch).map((type: string) => (
                    <li key={type}>{type}</li>
                  ))}
                </ul>
              ) : (
                <p>{t('noAvailableFields')}</p>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody padding>
          {templateTypes.length > 0 && (
            <NavPills
              classes={{ root: classes.navPill }}
              alignCenter
              tabs={constructTabs()}
              active={activeTab}
              onChangeTab={setActiveTab}
            />
          )}
        </CardBody>
      </Card>
      <Button className={classes.saveButton} fullWidth color="primary" variant="contained" type="submit">
        {t('save')}
      </Button>
    </form>
  );
};

export default memo(TemplateForm);
