import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { To, useNavigate, useParams } from 'react-router';
import { GameConfigurationSchema, useGetGameV2 } from '@greenisland/stores';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Stack } from '@mui/material';

import DeleteConfigurationDialog from './DeleteConfigurationDialog';
import GameConfigurationForm from './GameConfigurationForm';

const EditGameConfigurations = () => {
  const { t } = useTranslation();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const navigate = useNavigate();
  const [configurations, setConfigurations] = useState<Array<GameConfigurationSchema | undefined>>([]);
  const [deleteDialog, setDialogOpen] = useState({ open: false, index: -1 });
  const { gameId }: any = useParams();
  const { data: game } = useGetGameV2(gameId, { query: { enabled: Boolean(gameId) } });

  useEffect(() => {
    setConfigurations(game?.gameConfigurationsV2 ?? []);
  }, [setConfigurations, game]);

  const navigateTo = (url: To) => {
    if (isDirty) {
      const check = window.confirm(t('unsavedChanges'));
      if (check) {
        navigate(url);
      }
    } else {
      navigate(url);
    }
  };

  const onDelete = (index: number) => {
    if (!configurations?.[index]?.pkey) {
      setConfigurations(current => current.filter((config, i) => index !== i));
    }
  };

  return (
    <div>
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => navigateTo(`../../games/providers/${game?.providerFk}/games/${game?.gameId}`)}
          variant="outlined"
          startIcon={<ChevronLeftIcon />}
        >
          {t('game')}
        </Button>
        <Button
          onClick={() => setConfigurations(value => [...value, undefined])}
          startIcon={<AddIcon />}
          variant={'contained'}
          disabled={configurations.findIndex(configuration => configuration?.pkey === undefined) !== -1}
        >
          {t('addConfiguration')}
        </Button>
      </Stack>
      {configurations.map((config, index) => (
        <GameConfigurationForm
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          key={index}
          configuration={config}
          index={index}
          setDelete={setDialogOpen}
        />
      ))}
      <DeleteConfigurationDialog
        open={deleteDialog.open}
        setOpen={state => setDialogOpen({ open: state, index: -1 })}
        config={deleteDialog.index}
        deleteConfig={() => onDelete(deleteDialog.index)}
      />
    </div>
  );
};

export default memo(EditGameConfigurations);
