import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  AnnualUserReportAcceptedResponseSchema,
  AnnualUserReportResponseSchema,
  getGetAnnualUserReportQueryKey,
  useGetAnnualUserReport,
  useGetOrCreateAnnualUserReport,
  UserGeneralSchema,
} from '@greenisland/stores';
import { useSnackbar } from 'notistack';

function isLoaded(
  data: AnnualUserReportResponseSchema | AnnualUserReportAcceptedResponseSchema | void | undefined
): data is AnnualUserReportResponseSchema {
  return (data as AnnualUserReportResponseSchema)?.totalDeposits !== undefined;
}

function isFileGenerated(data: AnnualUserReportResponseSchema): data is AnnualUserReportResponseSchema {
  return (
    (data as AnnualUserReportResponseSchema)?.documentUri !== undefined &&
    (data as AnnualUserReportResponseSchema)?.documentUri !== null
  );
}

const download = async (url: string, fileName: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });
    const blob = await response.blob();

    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = blobUrl;
    a.setAttribute('download', fileName);

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Download failed:', error);
  }
};

export function useAnnualReport(user: UserGeneralSchema | undefined, selectedYear: number) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [tableData, setTableData] = useState<AnnualUserReportResponseSchema[]>([]);
  const [isPolling, setIsPolling] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const isDownloadingRef = useRef(isDownloading);
  useEffect(() => {
    isDownloadingRef.current = isDownloading;
  }, [isDownloading]);

  const userId = user ? parseInt(user?.userId) : -1;
  const fileName = `annual_report-${selectedYear}-user-${userId}`;

  // Reset tableData when a valid user changes
  useEffect(() => {
    if (userId) {
      setTableData([]);
    }
  }, [userId]);

  const addOrUpdateReport = (newReport: AnnualUserReportResponseSchema) => {
    setTableData(prevTableData => {
      const existingIndex = prevTableData.findIndex(report => report.year === newReport.year);

      let updatedTableData;
      if (existingIndex >= 0) {
        updatedTableData = [...prevTableData];
        updatedTableData[existingIndex] = newReport;
      } else {
        updatedTableData = [...prevTableData, newReport];
      }

      return updatedTableData.sort((a, b) => b.year - a.year);
    });
  };

  const { data: getAnnualUserReportData, mutate: getOrCreateAnnualUserReport } = useGetOrCreateAnnualUserReport({
    mutation: {
      onSuccess: async data => {
        await queryClient.invalidateQueries(getGetAnnualUserReportQueryKey(userId, selectedYear));
        if (!isLoaded(data) || (isDownloadingRef.current && !isFileGenerated(data))) {
          startPolling();
        } else {
          setIsPolling(false);
        }
      },
      onError: () => {
        setIsPolling(false);
        setIsDownloading(false);
        enqueueSnackbar(t('reporting.annualReport.request.errors.general'), { variant: 'error' });
      },
    },
  });

  const { data: getPollData, refetch } = useGetAnnualUserReport(userId, selectedYear, {
    query: { enabled: false },
  });

  useEffect(() => {
    if (isLoaded(getAnnualUserReportData)) {
      if (!isDownloadingRef.current) {
        addOrUpdateReport(getAnnualUserReportData);
        return;
      }
      if (isFileGenerated(getAnnualUserReportData)) {
        download(getAnnualUserReportData.documentUri as string, fileName);
        setIsDownloading(false);
      }
    }
    if (isLoaded(getPollData)) {
      if (!isDownloadingRef.current) {
        addOrUpdateReport(getPollData);
        return;
      }
      if (isFileGenerated(getPollData)) {
        download(getPollData.documentUri as string, fileName);
        setIsDownloading(false);
      }
    }
  }, [getAnnualUserReportData, getPollData]);

  const startPolling = useCallback(async () => {
    const pollInterval = 1000;
    const timeout = 30000;

    const poll = async (startTime: number) => {
      const elapsedTime = Date.now() - startTime;

      if (elapsedTime >= timeout) {
        setIsPolling(false);
        enqueueSnackbar(t('reporting.annualReport.request.timeout'), { variant: 'error' });
        return;
      }

      try {
        const response = await refetch();

        if (response.data && isLoaded(response.data) && (!isDownloadingRef.current || isFileGenerated(response.data))) {
          setIsPolling(false);
          return;
        }

        setTimeout(() => poll(startTime), pollInterval);
      } catch {
        setIsPolling(false);
        setIsDownloading(false);
        enqueueSnackbar(t('reporting.annualReport.request.errors.general'), { variant: 'error' });
      }
    };

    setIsPolling(true);
    poll(Date.now());
  }, [refetch, enqueueSnackbar, setIsDownloading, t]);

  const viewReport = () => {
    if (isPolling) return;
    setIsPolling(true);
    getOrCreateAnnualUserReport({ userId: userId, year: selectedYear, data: { includeDocument: false } });
  };

  const downloadReport = () => {
    if (isPolling) return;
    setIsPolling(true);
    setIsDownloading(true);
    getOrCreateAnnualUserReport({ userId: userId, year: selectedYear, data: { includeDocument: true } });
  };

  return {
    tableData,
    isPolling,
    viewReport,
    downloadReport,
  };
}
