import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EResponsibleGamingTestResponseResultSchema } from '@greenisland/stores';
import { capitalize, Typography, useTheme } from '@mui/material';

interface Props {
  result: EResponsibleGamingTestResponseResultSchema;
}

const ResponsibleGamingTestResponsesResult = ({ result }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const renderResultHandler = useCallback(
    (result: EResponsibleGamingTestResponseResultSchema) => {
      let color, title;

      switch (result) {
        case EResponsibleGamingTestResponseResultSchema.problemGambler: {
          color = theme.palette.error.main;
          title = 'responsibleGaming.testResponses.titles.problemGambler';
          break;
        }
        case EResponsibleGamingTestResponseResultSchema.nonProblemGambler:
          color = theme.palette.success.main;
          title = 'responsibleGaming.testResponses.titles.nonProblemGambler';
          break;
        case EResponsibleGamingTestResponseResultSchema.none:
          color = theme.palette.text.secondary;
          title = 'responsibleGaming.testResponses.titles.none';
          break;
      }

      return (
        <Typography variant="body2" color={color}>
          {capitalize(t(title))}
        </Typography>
      );
    },
    [t, theme.palette.error.main, theme.palette.success.main, theme.palette.text.secondary]
  );

  return renderResultHandler(result);
};

export default ResponsibleGamingTestResponsesResult;
