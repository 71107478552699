import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader } from '@greenisland/components';

import config from '../../../../config';

const Info = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader>{t('information')}</CardHeader>
      <CardBody padding>
        <p>{t('BOversion', { version: config.version })}</p>
        <Button size="medium" variant="outlined" onClick={() => window.location.reload()}>
          {t('checkNewVersion')}
        </Button>
      </CardBody>
    </Card>
  );
};

export default Info;
