import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, CheckData, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import { formatCurrency, setInvisible, transformUnixDate } from '../../app/helpers/transformFunctions';

const GameTransactions = () => {
  const { gameroundId = '' } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetching, data: gameRoundTransactions } = useAppSelector(state => state.gameRoundTransactions);
  const fetchGameRoundTransactions = (transactionId: string) =>
    dispatch(actionCreators.getGameRoundTransactions(transactionId));

  useEffect(() => {
    fetchGameRoundTransactions(gameroundId);
  }, []);

  const mutatedData = () => {
    if (!gameRoundTransactions || gameRoundTransactions.length === 0) return undefined;
    const keys = Object.keys(gameRoundTransactions[0]);

    const headers: any = keys.map(key => {
      switch (key) {
        case 'id':
          return setInvisible(key);
        case 'timestamp':
          return transformUnixDate(key, t, true);
        case 'transactionAmount':
          return {
            Header: t(key),
            accessor: key,
            Cell: (options: any) => (
              <span>
                {formatCurrency(
                  options.row.values.transactionAmount.amount / 100,
                  options.row.values.transactionAmount.currency
                )}
              </span>
            ),
            sortType: 'basic',
          };
        case 'balanceAfter':
          return {
            Header: t(key),
            accessor: key,
            Cell: (options: any) => (
              <span>
                {formatCurrency(options.row.values.balanceAfter.amount / 100, options.row.values.balanceAfter.currency)}
              </span>
            ),
            sortType: 'basic',
          };
        default:
          return { Header: t(key), accessor: key };
      }
    });

    const rows = gameRoundTransactions.map(item => ({ ...item }));
    return { headers, rows };
  };

  return (
    <Card>
      <CardHeader>{t('transactions')}</CardHeader>
      <CardBody>
        <CheckData
          data={mutatedData()}
          fallbackText={t('noData')}
          isLoading={fetching}
          checkProperties={['headers', 'rows']}
          render={({ data }) => (
            <ReactTableGI id="GameServerGameTransactions" columns={data.headers} data={data.rows} sortable />
          )}
        />
      </CardBody>
    </Card>
  );
};

export default GameTransactions;
