import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateTestUserRequestSchema, ERegulatorSchema } from '@greenisland/stores';
import { Box, capitalize, Checkbox, FormControlLabel } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';

import { useTenantContext } from '../../../../../../app/context/TenantContext';
import CollapseSectionWrapper from './CollapseSectionWrapper';

const TestUserNameSettings = () => {
  const tenant = useTenantContext();
  const { t } = useTranslation();

  const { control, setValue } = useFormContext<CreateTestUserRequestSchema>();

  const [isNameChosen, setIsNameChosen] = useState(false);

  useEffect(() => {
    if (!isNameChosen) {
      setValue('name.firstName', '');
      setValue('name.lastName', '');
      setValue('name.lastNamePrefix', '');
      setValue('name.secondLastName', '');
    }
  }, [isNameChosen, setValue]);

  return (
    <CollapseSectionWrapper title={t('name')}>
      <Box>
        <FormControlLabel
          control={<Checkbox color="primary" size="medium" />}
          onChange={(_, value) => setIsNameChosen(value)}
          label={t('common.chooseName')}
          checked={isNameChosen}
          sx={{ '.MuiTypography-root': { fontWeight: isNameChosen ? 600 : 400 } }}
        />
        <Box mt={2}>
          <Input
            fullWidth
            size="small"
            label={capitalize(t('common.chooseFirstName'))}
            control={control}
            type="text"
            name="name.firstName"
            required={isNameChosen}
            disabled={!isNameChosen}
            rules={
              isNameChosen
                ? {
                    required: t('fieldIsRequired'),
                  }
                : {}
            }
            sx={{ mb: 2 }}
            defaultValue=""
          />
          {tenant?.regulatorV2 === ERegulatorSchema.KSA && (
            <Input
              fullWidth
              size="small"
              label={capitalize(t('common.chooseLastNamePrefix'))}
              control={control}
              type="text"
              name="name.lastNamePrefix"
              disabled={!isNameChosen}
              sx={{ mb: 2 }}
              defaultValue=""
            />
          )}
          <Input
            fullWidth
            size="small"
            label={capitalize(t('common.chooseLastName'))}
            control={control}
            type="text"
            name="name.lastName"
            required={isNameChosen}
            disabled={!isNameChosen}
            rules={
              isNameChosen
                ? {
                    required: t('fieldIsRequired'),
                  }
                : {}
            }
            sx={{ mb: 2 }}
            defaultValue=""
          />
          {tenant?.regulatorV2 === ERegulatorSchema.DGOJ && (
            <Input
              fullWidth
              size="small"
              label={capitalize(t('common.chooseSecondLastName'))}
              control={control}
              type="text"
              name="name.secondLastName"
              disabled={!isNameChosen}
              sx={{ mb: 2 }}
              defaultValue=""
            />
          )}
        </Box>
      </Box>
    </CollapseSectionWrapper>
  );
};

export default TestUserNameSettings;
