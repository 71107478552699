import React from 'react';
import { EAddictionCategoryClassificationSchema } from '@greenisland/stores';
import { Box } from '@mui/material';

interface Props {
  value: EAddictionCategoryClassificationSchema;
}

const ClassificationResultField = ({ value }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...(value === EAddictionCategoryClassificationSchema.LowRisk && { color: '#434343' }),
        ...(value === EAddictionCategoryClassificationSchema.ModerateRisk && { color: '#DD7A1F' }),
        ...(value === EAddictionCategoryClassificationSchema.HighRisk && { color: '#EA262A' }),
        ...(value === EAddictionCategoryClassificationSchema.NonProblem && { color: '#549041' }),
      }}
    >
      {value}
    </Box>
  );
};

export default ClassificationResultField;
