import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  EIcons,
  Link,
  Loading,
  ReactTableGI,
  Tooltip,
} from '@greenisland/components';
import { actionCreators, TemplatesTypesEnum } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { usePermission } from '../../../../../app/hooks';

const LegacyDefaultTemplates = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const canReadDefaultTemplate = usePermission(OnlineCasinoPermissions.getDefaultCampaignTemplate);
  const canReadDefaultTemplates = usePermission(OnlineCasinoPermissions.getDefaultTemplates);
  const canDeleteDefaultTemplate = usePermission(OnlineCasinoPermissions.deleteDefaultTemplate);
  const canAddDefaultTemplate = usePermission([
    OnlineCasinoPermissions.addCampaignTemplate,
    OnlineCasinoPermissions.getTemplateTypes,
  ]);
  const [deleteDialog, setDeleteDialog] = useState<{ type: TemplatesTypesEnum; name: string } | undefined>();
  const { selectedEntity, selectedProduct } = useAppSelector(state => state.entities);
  const { data: campaignTemplates, fetching } = useAppSelector(state => state.campaignTemplates);

  useEffect(() => {
    if (canReadDefaultTemplates) dispatch(actionCreators.getCampaignDefaultTemplates());
  }, [dispatch, canReadDefaultTemplates]);

  const headers: Column[] = [
    { Header: String(t('name')), accessor: 'name' },
    { Header: String(t('type')), accessor: 'type' },
    { id: 'actions', accessor: 'actions', disableSorting: true } as Column,
  ];

  const rows = useMemo(() => {
    return campaignTemplates && Array.isArray(campaignTemplates)
      ? campaignTemplates?.map(template => ({
          ...template,
          actions: (
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              {canReadDefaultTemplate && (
                <Tooltip content={t('editTemplate')}>
                  <Link
                    to={`../default/${template.type}`}
                    button
                    buttonColor="primary"
                    buttonSize="small"
                    buttonVariant="transparent"
                    icon={EIcons.faPen}
                  />
                </Tooltip>
              )}
              {canDeleteDefaultTemplate && (
                <Tooltip content={t('deleteTemplate')}>
                  <Button
                    variant="transparent"
                    color="secondary"
                    error
                    rounded
                    justIcon={EIcons.faTrash}
                    size="small"
                    onClick={() => setDeleteDialog({ type: template.type as TemplatesTypesEnum, name: template.name })}
                  />
                </Tooltip>
              )}
            </Box>
          ),
        }))
      : [];
  }, [t, campaignTemplates, canDeleteDefaultTemplate, canReadDefaultTemplate]);

  const handleDialogAgree = () => {
    if (deleteDialog) {
      dispatch(actionCreators.deleteCampaignDefaultTemplate(deleteDialog.type));
      setDeleteDialog(undefined);
    }
  };

  return canReadDefaultTemplates ? (
    <>
      <Card>
        <CardHeader
          linkChildren={
            canAddDefaultTemplate ? (
              <Link to={`/${selectedEntity}/${selectedProduct}/marketing/default/add`} button icon={EIcons.faPlus}>
                {t('addTemplate')}
              </Link>
            ) : undefined
          }
        >
          {t('defaultTemplates')}
        </CardHeader>
        <CardBody>
          {fetching || !campaignTemplates ? (
            <Loading center />
          ) : (
            <ReactTableGI columns={headers} data={rows} sortable />
          )}
        </CardBody>
      </Card>
      <Dialog
        open={Boolean(deleteDialog)}
        onClose={() => setDeleteDialog(undefined)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('confirmDeleteTemplateTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary" id="alert-dialog-description">
            {deleteDialog?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button color="secondary" variant="transparent" onClick={() => setDeleteDialog(undefined)}>
            {t('cancel')}
          </Button>
          <Button onClick={handleDialogAgree} error>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default LegacyDefaultTemplates;
