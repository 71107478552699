import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faBars, faSignOutAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@greenisland/components';
import { useAppSelector } from '@greenisland-core/store';
import { IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { usePermission } from '../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../pages/OnlineCasino/Lookup/LookupPermissions';
import Searchbar from './Searchbar';
import SearchBarGameServer from './SearchBarGameServer';

interface Props {
  drawerOpen: boolean;
  handleDrawerToggle: () => void;
  handleLogout: () => void;
}

const useTopBarStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    position: 'fixed',
    zIndex: 999,
    backgroundColor: '#fff',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('md')]: { width: `calc(100% - ${theme.spacing(32)})` },
  },
  search: { flexGrow: 1 },
  menuButton: { width: theme.spacing(5), [theme.breakpoints.up('md')]: { display: 'none' } },
  logoutButton: { [theme.breakpoints.down('md')]: { display: 'none' } },
}));

const TopAppBar = ({ drawerOpen, handleDrawerToggle, handleLogout }: Props) => {
  const { t } = useTranslation();
  const classes = useTopBarStyles();
  const hasLookupPermissions = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const entities = useAppSelector(state => state.authentication.data?.startupAgent?.entities);
  const { selectedEntity, selectedProduct } = useAppSelector(state => state.entities);

  const product = useMemo(
    () =>
      entities
        ?.find(({ entityName }) => entityName === selectedEntity)
        ?.products?.find(({ productName }) => productName === selectedProduct),
    [entities, selectedEntity, selectedProduct]
  );

  return (
    <div className={classes.container}>
      <div className={classes.search}>
        {hasLookupPermissions && product?.productType === 'OnlineCasino' && (
          <Searchbar closeDrawer={handleDrawerToggle} />
        )}
        {hasLookupPermissions && product?.productType === 'GameServer' && (
          <SearchBarGameServer closeDrawer={handleDrawerToggle} />
        )}
      </div>
      <div title={t('logout')} className={classes.logoutButton}>
        <Button
          startIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
          id="logoutButton"
          aria-label={t('logout')}
          variant="transparent"
          rounded
          justIcon
          color="secondary"
          onClick={handleLogout}
        />
      </div>
      <div title={drawerOpen ? t('closeMenu') : t('openMenu')} className={classes.menuButton}>
        <IconButton aria-label={t('openDrawer')} onClick={handleDrawerToggle} color="secondary">
          <FontAwesomeIcon icon={drawerOpen ? faTimes : faBars} />
        </IconButton>
      </div>
    </div>
  );
};

export default TopAppBar;
