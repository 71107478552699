import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ESortOrderSchema, PlayingBreakSchema, useGetAllPlayingBreaks } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Skeleton, Stack, useTheme } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  DataGridPagination,
  DateTime,
  ErrorCard,
  FontAwesomeIcon,
  Link,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../app/hooks';
import CancelPlayingBreakDialog from './CancelPlayingBreakDialog';
import SetPlayingBreakDialog from './SetPlayingBreakDialog';

type Props = {
  userId: string;
};

const PAGE_SIZE = 5;

const PlayingBreak = ({ userId }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const canReadPlayingBreak = usePermission(OnlineCasinoPermissions.getPlayingBreak);
  const canSetPlayingBreak = usePermission(OnlineCasinoPermissions.cancelPlayingBreak);
  const canDeletePlayingBreak = usePermission(OnlineCasinoPermissions.cancelPlayingBreak);

  const {
    data: playingBreakData,
    isLoading,
    isError,
    error,
  } = useGetAllPlayingBreaks(
    {
      userId: parseInt(userId),
      currentPageNumber: page,
      resultsPerPage: PAGE_SIZE,
      endDateSortOrder: ESortOrderSchema.descending,
    },
    { query: { enabled: canReadPlayingBreak } }
  );

  if (!canReadPlayingBreak)
    return (
      <ErrorCard severity="warning" sx={{ height: 300 }}>
        {t('noEntities')}
      </ErrorCard>
    );
  if (isLoading) return <Skeleton variant="rectangular" height={300} />;
  if (isError || !playingBreakData) return <ErrorCard>{error}</ErrorCard>;

  const isCurrentPlayingBreak = ({ endDate }: PlayingBreakSchema) => {
    return Date.now() / 1000 < (endDate ?? -1);
  };

  const headers: GridColumns = [
    {
      headerName: capitalize(t('startDate')),
      field: 'startDate',
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => <DateTime dateTime={row.startDate ?? 0} />,
    },
    {
      headerName: capitalize(t('endDate')),
      field: 'endDate',
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => (
        <Box sx={{ color: isCurrentPlayingBreak(row) ? theme.palette.primary.main : undefined }}>
          <Stack direction="row">
            {row.originalEndDate && row.endDate !== row.originalEndDate && (
              <Box sx={{ marginRight: 1, textDecoration: 'line-through' }}>
                {<DateTime dateTime={row.originalEndDate ?? 0} />}
              </Box>
            )}
            {<DateTime dateTime={row.endDate ?? 0} />}
          </Stack>
        </Box>
      ),
    },
    { headerName: '', field: 'actions', renderCell: ({ value }) => value, align: 'right', flex: 1 },
  ];

  const rows: GridRowsProp =
    playingBreakData.items.map((item, i) => {
      return {
        ...item,
        id: i,
        actions:
          canDeletePlayingBreak && isCurrentPlayingBreak(item) ? (
            <Button variant="text" color="error" onClick={() => setOpenCancelDialog(true)}>
              <FontAwesomeIcon icon={faTimes} sx={{ marginRight: 1 }} /> {t('cancel')}
            </Button>
          ) : undefined,
      };
    }) ?? [];

  return (
    <>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('playingBreak')} />
          <div>
            {canSetPlayingBreak && (
              <Button variant="outlined" color="secondary" size="small" onClick={() => setOpenAddDialog(true)}>
                {t('setPlayingBreak')}
              </Button>
            )}
            {canReadPlayingBreak && (
              <Button
                sx={{ ml: 2 }}
                variant="outlined"
                color="primary"
                size="small"
                component={Link}
                to="../playingBreaks"
              >
                {t('seeMore')}
              </Button>
            )}
          </div>
        </Box>
        <CardContent>
          <DataGridContainer>
            <StyledDataGrid
              autoHeight
              rows={rows}
              columns={headers}
              page={playingBreakData?.pagingDetails?.currentPage}
              rowCount={playingBreakData?.pagingDetails?.totalItems}
              pagination
              paginationMode="server"
              pageSize={PAGE_SIZE}
              onPageChange={setPage}
              components={{
                Pagination: DataGridPagination,
              }}
            />
          </DataGridContainer>
        </CardContent>
      </Card>
      {openAddDialog && (
        <SetPlayingBreakDialog
          openDialog={openAddDialog}
          setOpenDialog={setOpenAddDialog}
          userId={parseInt(userId)}
          currentPlayingBreak={playingBreakData.items.find(isCurrentPlayingBreak)}
        />
      )}
      {openCancelDialog && (
        <CancelPlayingBreakDialog
          openDialog={openCancelDialog}
          setOpenDialog={setOpenCancelDialog}
          userId={parseInt(userId)}
        />
      )}
    </>
  );
};

export default memo(PlayingBreak);
