import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLoyaltyTopSavers, useGetLoyaltyTotalBalances } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, Card, CardContent, CardHeader } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, Link, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { formatDecimal } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../Lookup/LookupPermissions';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridGap: '15px 15px',
    marginBottom: 15,
  },
});

const LoyaltyCredits = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canReadLoyaltyTotalBalances = usePermission(OnlineCasinoPermissions.getLoyaltyTotalBalances);
  const canReadLoyaltyTopSavers = usePermission(OnlineCasinoPermissions.getLoyaltyTopSavers);

  const {
    data: loyaltyTotalBalances,
    isLoading: isLoyaltyTotalBalancesLoading,
    isError: isLoyaltyTotalBalancesError,
  } = useGetLoyaltyTotalBalances({ query: { enabled: canReadLoyaltyTotalBalances } });

  const {
    data: loyaltyTopSavers,
    isLoading: isLoyaltyTopSaversLoading,
    isError: isLoyaltyTopSaversError,
  } = useGetLoyaltyTopSavers({ query: { enabled: canReadLoyaltyTopSavers } });

  const headersLoyaltyTotalBalances: GridColumns = [
    { headerName: capitalize(t('spendablePoints')), field: 'spendablePoints', flex: 1, minWidth: 20, sortable: false },
    { headerName: capitalize(t('statusPoints')), field: 'statusPoints', flex: 1, minWidth: 20, sortable: false },
  ];

  const rowsLoyaltyTotalBalances: GridRowsProp = loyaltyTotalBalances
    ? [
        {
          spendablePoints: formatDecimal(loyaltyTotalBalances.spendablePoints, true),
          statusPoints: formatDecimal(loyaltyTotalBalances.statusPoints, true),
          id: 1,
        },
      ]
    : [];

  const headersLoyaltyTopSavers: GridColumns = [
    {
      headerName: capitalize(t('username')),
      field: 'username',
      renderCell: ({ row }) =>
        canLookupUser ? <Link to={`../../users/${row.userId}/details`}>{row.username}</Link> : undefined,
      flex: 1,
      minWidth: 40,
    },
    {
      headerName: capitalize(t('spendablePoints')),
      field: 'spendablePoints',
      renderCell: ({ value }) => formatDecimal(value, true),
      flex: 1,
      minWidth: 20,
    },
    {
      headerName: capitalize(t('statusPoints')),
      field: 'statusPoints',
      renderCell: ({ value }) => formatDecimal(value, true),
      flex: 1,
      minWidth: 20,
    },
  ];

  const rowsLoyaltyTopSavers: GridRowsProp = loyaltyTopSavers?.items
    ? [
        ...loyaltyTopSavers.items.map((item, i) => ({
          ...item,
          spendablePoints: item.loyaltyBalances?.spendablePoints,
          statusPoints: item.loyaltyBalances?.statusPoints,
          id: i,
        })),
      ]
    : [];

  return loyaltyTotalBalances ? (
    <div className={classes.container}>
      <Card>
        <CardHeader>{capitalize(t('balances'))}</CardHeader>
        <CardContent>
          <PermissionWrapper
            errorMessage={t('loyaltyReporting.totalbalances.permissions.fetchError')}
            isError={isLoyaltyTotalBalancesError}
            isLoading={isLoyaltyTotalBalancesLoading}
            permission={OnlineCasinoPermissions.getLoyaltyRankDistributions}
          >
            <DataGridContainer>
              <LimitedDataGrid
                autoHeight
                columns={headersLoyaltyTotalBalances}
                rows={rowsLoyaltyTotalBalances}
                hideFooter
              />
            </DataGridContainer>
          </PermissionWrapper>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>{capitalize(t('topSavers'))}</CardHeader>
        <CardContent>
          <PermissionWrapper
            errorMessage={t('loyaltyReporting.topsavers.permissions.fetchError')}
            isError={isLoyaltyTopSaversError}
            isLoading={isLoyaltyTopSaversLoading}
            permission={OnlineCasinoPermissions.getLoyaltyTopSavers}
          >
            <DataGridContainer>
              <LimitedDataGrid autoHeight columns={headersLoyaltyTopSavers} rows={rowsLoyaltyTopSavers} />
            </DataGridContainer>
          </PermissionWrapper>
        </CardContent>
      </Card>
    </div>
  ) : null;
};

LoyaltyCredits.defaultProps = {
  userRights: [],
};

export default memo(LoyaltyCredits);
