import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GameSortingTypeParameter,
  useGetGameCategories,
  useGetGameStudios,
  useGetGameThemes,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  capitalize,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import useDeleteGameFilter from './hooks/useDeleteGameFilter';
import useGetGameFiltersCheckboxData from './hooks/useGetGameFiltersCheckboxData';
import useGetGameFiltersQuery from './hooks/useGetGameFiltersQuery';
import useSelectGameFilter from './hooks/useSelectGameFilter';
import useUpdateGameFilter from './hooks/useUpdateGameFilter';
import { usePermission } from '../../../../../app/hooks';
import useGetTranslationSchemaLocale from '../Components/utils/useGetTranslationSchemaLocale';
import {
  ChipData,
  convertCheckboxItemsToChipsData,
  convertDropdownItemsToChipsData,
  GameFilterType,
  getGameFilterTypeLabel,
  getSelectedChipsFilters,
} from './constants';
import GamesFilterMenu from './GamesFilterMenu';

interface Props {
  hideStudio?: boolean;
  hideThemes?: boolean;
  hideCategory?: boolean;
}

const GamesFilter = ({ hideStudio, hideCategory, hideThemes }: Props) => {
  const { t } = useTranslation();

  const canReadCategories = usePermission(OnlineCasinoPermissions.getGameCategories);
  const canReadThemes = usePermission(OnlineCasinoPermissions.getGameThemes);
  const canReadStudios = usePermission(OnlineCasinoPermissions.getGameStudios);

  const [chips, setChips] = useState<ChipData[]>([]);
  const checkboxFilterItems = useGetGameFiltersCheckboxData();
  const selectFilterHandler = useSelectGameFilter(setChips);
  const updateFilterHandler = useUpdateGameFilter();
  const deleteFilterHandler = useDeleteGameFilter(setChips);
  const { data: themes } = useGetGameThemes({ query: { enabled: canReadThemes } });
  const { data: categories } = useGetGameCategories({ query: { enabled: canReadCategories } });
  const { data: studios } = useGetGameStudios({ query: { enabled: canReadStudios } });
  const { sortByQuery, searchQuery, themesQuery, categoriesQuery, studiosQuery, additionalFiltersQuery } =
    useGetGameFiltersQuery();

  const getTranslation = useGetTranslationSchemaLocale();

  useEffect(() => {
    const selectedThemesChips = convertDropdownItemsToChipsData(themes, themesQuery, GameFilterType.THEME);
    const selectedStudiosChips = convertDropdownItemsToChipsData(studios, studiosQuery, GameFilterType.STUDIO);
    const selectedCategoriesChips = convertDropdownItemsToChipsData(
      categories,
      categoriesQuery,
      GameFilterType.CATEGORY
    );
    const selectedAdditionalFilterChips = convertCheckboxItemsToChipsData(additionalFiltersQuery);

    setChips([
      ...selectedThemesChips,
      ...selectedStudiosChips,
      ...selectedCategoriesChips,
      ...selectedAdditionalFilterChips,
    ]);
  }, [additionalFiltersQuery, categories, categoriesQuery, studios, studiosQuery, themes, themesQuery]);

  return canReadCategories || canReadThemes || canReadStudios ? (
    <Paper sx={{ backgroundColor: 'white' }} variant="outlined" elevation={0}>
      <>
        <Box display="flex" alignItems="center" flexWrap="wrap" paddingX={2} paddingY={1}>
          <SearchIcon />
          <Box ml={3} flexGrow={1}>
            <InputBase
              fullWidth
              size="small"
              type="text"
              value={searchQuery || ''}
              placeholder={capitalize(t('common.search.contentPlaceholder'))}
              onChange={event => updateFilterHandler(GameFilterType.SEARCH, event.target.value)}
            />
          </Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="sortby" color="info">
              {t('content.games.sort.title')}
            </InputLabel>
            <Select
              id={GameFilterType.SORT_BY}
              value={sortByQuery || GameSortingTypeParameter.releaseDate}
              label={capitalize(t('content.games.sort.title'))}
              onChange={e => updateFilterHandler(GameFilterType.SORT_BY, e.target.value)}
              color="info"
            >
              {Object.keys(GameSortingTypeParameter).map(sortType => (
                <MenuItem key={sortType} value={sortType}>
                  {t(`content.games.sort.${sortType}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Divider />
      </>
      <Box display="flex" alignItems="center" flexWrap="wrap" paddingX={2} paddingY={1}>
        {chips.length === 0 ? (
          <Typography variant="subtitle2" textTransform="initial">
            {t('content.games.filter.noFilter')}
          </Typography>
        ) : (
          <Stack component="div" direction="row" flexWrap="wrap" gap={1}>
            {chips.map(chip => (
              <Chip
                key={chip?.id}
                label={
                  <span>
                    <strong>{capitalize(t(getGameFilterTypeLabel(chip?.type as GameFilterType)))}:</strong>{' '}
                    {capitalize(chip?.label)}
                  </span>
                }
                variant="outlined"
                onDelete={() => deleteFilterHandler(chip)}
              />
            ))}
          </Stack>
        )}
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" flexWrap="wrap" padding={1}>
        {canReadThemes && !hideThemes && (
          <GamesFilterMenu
            label={t('content.games.filter.theme')}
            options={
              themes
                ?.map(t => {
                  return {
                    id: t.code,
                    label: getTranslation(t.nameTranslations) ?? t.code,
                  };
                })
                .sort((a, b) => {
                  return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' });
                }) ?? []
            }
            selected={getSelectedChipsFilters(chips, GameFilterType.THEME)}
            onClick={selected => selectFilterHandler(GameFilterType.THEME, selected)}
            multiple
          />
        )}
        {canReadStudios && !hideStudio && (
          <GamesFilterMenu
            label={t('content.games.filter.studio')}
            options={
              studios
                ?.map(t => ({ id: t.code as string, label: t.gameStudioName }))
                .sort((a, b) => {
                  return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' });
                }) ?? []
            }
            selected={getSelectedChipsFilters(chips, GameFilterType.STUDIO)}
            onClick={selected => selectFilterHandler(GameFilterType.STUDIO, selected)}
            multiple
          />
        )}
        {canReadCategories && !hideCategory && (
          <GamesFilterMenu
            label={t('content.games.filter.category')}
            options={
              categories
                ?.map(t => {
                  return {
                    id: t.code,
                    label: getTranslation(t.codeNameTranslations) ?? t.code,
                  };
                })
                .sort((a, b) => {
                  return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' });
                }) ?? []
            }
            selected={getSelectedChipsFilters(chips, GameFilterType.CATEGORY)}
            onClick={selected => selectFilterHandler(GameFilterType.CATEGORY, selected)}
            multiple
          />
        )}
        <Box ml={1}>
          {checkboxFilterItems?.map(item => (
            <FormControlLabel
              key={item?.filterType}
              control={<Checkbox />}
              label={item?.label}
              checked={item?.isChecked}
              onChange={(_, checked) =>
                selectFilterHandler(GameFilterType.ADDITIONAL, {
                  id: item?.filterType,
                  label: item?.label,
                  selected: checked,
                })
              }
            />
          ))}
        </Box>
      </Box>
    </Paper>
  ) : null;
};

export default GamesFilter;
