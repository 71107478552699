import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { EWithdrawProviderSchema, useGetWithdrawalPinCodeHook, WithdrawalRequestSchema } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, IconButton, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import {
  DataGridContainer,
  ErrorCard,
  FontAwesomeIcon,
  Link,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { formatCurrency, getDateTimeFromUnix, StatusSpan } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import useAccountData from '../../../hooks/useAccountData';
import CodeDialog from '../CodeDialog';

interface Props {
  userId: string;
}

const WithdrawRequests = ({ userId }: Props) => {
  const { t } = useTranslation();
  const [pinCode, setPinCode] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const canReadEventstream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canReadWithdrawalRequests = usePermission(OnlineCasinoPermissions.getUserWithdrawalRequests);
  const canReadWithdrawalPinCode = usePermission(OnlineCasinoPermissions.getWithdrawalPinCode);

  const { data: accountData, isLoading, isError, error, permission } = useAccountData(userId);

  const getWithdrawalPinCode = useGetWithdrawalPinCodeHook();

  if (!permission) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={300} />;
  if (isError || !accountData) return <ErrorCard>{error?.message}</ErrorCard>;

  const headers: GridColumns = [
    {
      headerName: capitalize(t('createdOn')),
      field: 'createdOn',
      valueFormatter: ({ value }) => getDateTimeFromUnix(value),
      width: 200,
    },
    {
      headerName: capitalize(t('modifiedOn')),
      field: 'modifiedOn',
      valueFormatter: ({ value }) => (value ? getDateTimeFromUnix(value) : null),
      width: 150,
    },
    { headerName: capitalize(t('amount')), field: 'amount', valueFormatter: ({ value }) => formatCurrency(value) },
    { headerName: capitalize(t('balance')), field: 'balance', valueFormatter: ({ value }) => formatCurrency(value) },
    { headerName: capitalize(t('destination')), field: 'destination' },
    { headerName: capitalize(t('status')), field: 'status', renderCell: ({ value }) => <StatusSpan value={value} /> },
    { headerName: '', field: 'actions', renderCell: ({ value }) => value, align: 'right', width: 220 },
  ];

  const rows: GridRowsProp = accountData.withdrawalRequests.map((item: WithdrawalRequestSchema, i) => {
    return {
      ...item,
      id: i,
      actions: (
        <>
          {item.destination === EWithdrawProviderSchema.casino && canReadWithdrawalPinCode && (
            <Button
              sx={{ mr: 1 }}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={async () => {
                try {
                  const pinCode = await getWithdrawalPinCode(item.id);
                  setPinCode(pinCode?.pinCode || t('noPinCode'));
                } catch {
                  enqueueSnackbar(t('error'), { variant: 'error' });
                }
              }}
            >
              {t('revealPinCode')}
            </Button>
          )}
          {canReadEventstream && (
            <IconButton
              size="small"
              color="secondary"
              component={Link}
              {...{ to: `../../users/${userId}/withdrawals/${item.streamId.toLowerCase()}` }}
            >
              <FontAwesomeIcon icon={faEye} />
            </IconButton>
          )}
        </>
      ),
    };
  });

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader title={t('withdrawalRequests')} />
        {canReadWithdrawalRequests && (
          <Button size="small" variant="outlined" color="primary" component={Link} to={`../withdrawal-requests`}>
            {t('seeMore')}
          </Button>
        )}
      </Box>
      <CardContent>
        <DataGridContainer>
          {rows.length > 0 ? (
            <StyledDataGrid autoHeight density="compact" columns={headers} rows={rows} hideFooter />
          ) : (
            <NoData />
          )}
        </DataGridContainer>
      </CardContent>
      <CodeDialog isOpen={Boolean(pinCode)} onClose={() => setPinCode(null)} code={pinCode || ''} />
    </Card>
  );
};

export default memo(WithdrawRequests);
