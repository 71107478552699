import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';

import Searchfield from './Searchfield';

interface Props {
  closeDrawer?: () => void;
  className?: string;
}

const SearchBarGameServer = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedEntity, selectedProduct } = useAppSelector(state => state.entities);
  const { fetching, data: playerSearch } = useAppSelector(state => state.searchPlayer);
  const fetchQuickSearch = (externalPlayerId: string) => dispatch(actionCreators.searchPlayer(externalPlayerId));

  useEffect(() => {
    if (playerSearch && playerSearch.entries) {
      if (playerSearch.entries.length === 1 && playerSearch.entries[0].externalId) {
        navigate(`/${selectedEntity}/${selectedProduct}/players/${playerSearch.entries[0].externalId}/details`, {
          state: {
            name: 'Details',
          },
        });
      } else if (playerSearch.entries.length > 1 && location.pathname !== 'lookup') {
        navigate(
          `/${selectedEntity}/${selectedProduct}/lookup?searchKey=${localStorage.getItem('gameServerSearchKey')}`,
          { state: { name: 'Lookup' } }
        );
      } else {
        dispatch(actionCreators.setErrorAlert(t('userNotFound')));
      }
    }
  }, [playerSearch]);

  const handleSubmit = (params: string) => {
    if (props.closeDrawer !== undefined) {
      props.closeDrawer();
    }
    if (params) {
      localStorage.setItem('gameServerSearchKey', params);
    }
    fetchQuickSearch(params);
  };

  return (
    <div className={props.className}>
      <Searchfield handleSubmit={handleSubmit} fetching={fetching} placeholder={t('quickSearchRedPanda')} />
    </div>
  );
};

export default memo(SearchBarGameServer);
