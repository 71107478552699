import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, CheckData, Link, ReactTableGI } from '@greenisland/components';
import { actionCreators, WithdrawalRequest } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  setInvisible,
  transformCurrency,
  transformStatus,
  transformUnixDate,
} from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { transformToCSVWithdrawals } from '../AccountData/helpers/TransformToCSV';
import { EventStreamParamsType } from '../Constants/Eventstreams';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridGap: '15px 15px',
      marginBottom: 15,
    },
    green: { color: 'green' },
    red: { color: 'red' },
    orange: { color: 'orange' },
    greyItalic: {
      color: 'grey',
      fontStyle: 'ITALIC',
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  })
);

const SearchWithdrawRequests = () => {
  const canReadWithdrawalRequests = usePermission(OnlineCasinoPermissions.getUserWithdrawalRequests);
  return canReadWithdrawalRequests ? <SearchWithdrawRequestsInnerChild /> : null;
};

const SearchWithdrawRequestsInnerChild = () => {
  const { t } = useTranslation();
  const classes = styles();
  const dispatch = useAppDispatch();
  const { userId = '' } = useParams();
  const canReadEventstream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const { data: withdrawalRequests, fetching } = useAppSelector(state => state.userWithdrawalRequests);

  const fetchWithdrawRequests = async (startDate: number, endDate: number) =>
    dispatch(actionCreators.getUserWithdrawalRequests(userId, startDate, endDate));
  const [searchParams, setSearchParams] = useSearchParams();

  const mutatedWithdrawalRequests = () => {
    if (!withdrawalRequests || withdrawalRequests.length === 0) return undefined;
    const keys = Object.keys(withdrawalRequests[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'createdOn':
          return transformUnixDate(key, t);
        case 'modifiedOn':
          return transformUnixDate(key, t);
        case 'status':
          return transformStatus(key, t);
        case 'streamId':
          return setInvisible(key);
        case 'amount':
        case 'balance':
          return transformCurrency(key, t);
        default:
          return { Header: key, accessor: key };
      }
    });

    headers.push({ id: 'history', accessor: 'history', disableFilters: true, disableSortBy: true });

    const rows = withdrawalRequests.map((item: WithdrawalRequest) => {
      return {
        ...item,
        history:
          canReadEventstream && item?.streamId ? (
            <Link to={`../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=${item.streamId.toLowerCase()}`}>
              {t('history')}
            </Link>
          ) : undefined,
      };
    });
    return { headers, rows };
  };

  const download = (content: string, fileName: string, mimeType: string) => {
    const a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (URL && 'download' in a) {
      a.href = URL.createObjectURL(new Blob([content], { type: mimeType }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
  };

  const handleDownload = () => {
    if (withdrawalRequests) {
      const csv = transformToCSVWithdrawals(withdrawalRequests);
      if (csv) {
        download(csv, `withdrawals_${userId}.csv`, 'text/csv;encoding:utf-8');
      }
    }
  };

  return (
    <div className={classes.container}>
      <DateRangePicker
        unixStartDate={Number(searchParams.get('startdate')) * 1000}
        unixEndDate={Number(searchParams.get('enddate')) * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
          fetchWithdrawRequests(startDate, endDate);
        }}
      />
      <div>
        <Button
          disabled={!withdrawalRequests || !withdrawalRequests?.length}
          variant="outlined"
          size="small"
          onClick={handleDownload}
        >
          {t('exportToCSV')}
        </Button>
      </div>
      <Card>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedWithdrawalRequests()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => <ReactTableGI columns={data.headers} data={data.rows} pagination sortable />}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(SearchWithdrawRequests);
