import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button, Card, CardBody, CardHeader, EIcons, ReactTableGI } from '@greenisland/components';

import TableCellActions from '@greenisland-common/components/molecules/TableCellActions';

const smsGroups = [
  { id: '0001', name: 'GIAdmins', phoneNumbers: ['+32 123 456 1 1', '+32 123 456 1 2', '+32 123 456 1 3'] },
  { id: '0002', name: 'GIFrontend', phoneNumbers: ['+32 123 456 2'] },
  { id: '0003', name: 'GISupport', phoneNumbers: ['+32 123 456 3 1', '+32 123 456 3 2', '+32 123 456 3 3'] },
  { id: '0004', name: 'GILegal', phoneNumbers: ['+32 123 456 4 1', '+32 123 456 4 2'] },
];

const SmsGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useMemo(() => {
    return smsGroups.map(smsGroup => {
      return {
        ...smsGroup,
        phoneNumbersAmount: smsGroup.phoneNumbers.length,
        actions: <TableCellActions onEdit={() => navigate(`./edit/${smsGroup.id}`)} onDelete={() => undefined} />,
      };
    });
  }, []);

  return (
    <>
      <Button startIcon={EIcons.faPlus} onClick={() => navigate('./add')}>
        {t('messaging.notificationGroups.addPhoneGroup')}
      </Button>
      <Card>
        <CardHeader></CardHeader>
        <CardBody>
          <ReactTableGI
            columns={[
              { Header: '#', accessor: 'phoneNumbersAmount' },
              { Header: 'name', accessor: 'name' },
              { accessor: 'actions' },
            ]}
            data={data}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default SmsGroups;
