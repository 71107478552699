import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CheckData, ReactTableGI } from '@greenisland/components';
import { useGetBonusRequestSimulation } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';

import {
  setInvisible,
  transformCurrency,
  transformDecimal,
  transformDefault,
  transformPercentage,
} from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

const BonusRequestSimulation = () => {
  const { t } = useTranslation();
  const canReadBonusRequestSimulation = usePermission(OnlineCasinoPermissions.getBonusRequestSimulation);
  const { data: bonusRequestSimulation, isLoading: fetching } = useGetBonusRequestSimulation({
    query: { enabled: canReadBonusRequestSimulation },
  });

  const mutatedData = () => {
    if (!bonusRequestSimulation || bonusRequestSimulation.length === 0) return undefined;
    const keys = Object.keys(bonusRequestSimulation[0]);
    const headers: any = keys.map(key => {
      switch (key) {
        case 'promoBackClaimables':
        case 'promoBackAvailables':
        case 'maxClaimable':
          return transformPercentage(key, t);
        case 'netDepositLastWeek':
          return transformCurrency(key, t);
        case 'simulationId':
          return setInvisible(key);
        case 'availablesAwarded':
          return transformDecimal(key, t);
        case 'factor':
          return transformDecimal(key, t, true);
        default:
          return transformDefault(key, t, true);
      }
    });

    return { headers, rows: bonusRequestSimulation };
  };

  return canReadBonusRequestSimulation ? (
    <Card>
      <CardBody>
        <CheckData
          fallbackText={t('noData')}
          data={mutatedData()}
          isLoading={fetching}
          checkProperties={['headers', 'rows']}
          render={({ data }) => (
            <ReactTableGI columns={data.headers} data={data.rows} pagination={data.rows.length > 5} sortable />
          )}
        />
      </CardBody>
    </Card>
  ) : null;
};

export default memo(BonusRequestSimulation);
