import { EUpdateBoostBadRequestTypeSchema } from '@greenisland/stores';

type GlobalBoostsErrorReasons = {
  [key in EUpdateBoostBadRequestTypeSchema]: string;
};

export const GLOBAL_BOOST_ERROR_REASONS: GlobalBoostsErrorReasons = {
  [EUpdateBoostBadRequestTypeSchema.BoostsNotEnabled]: 'boost.global.errors.boostsNotEnabled',
  [EUpdateBoostBadRequestTypeSchema.OverlappingGlobalBoost]: 'boost.global.errors.overlappingGlobalBoost',
  [EUpdateBoostBadRequestTypeSchema.InvalidBoostName]: 'boost.global.errors.invalidBoostName',
  [EUpdateBoostBadRequestTypeSchema.InvalidContentFilterId]: 'boost.global.errors.invalidContentFilterId',
  [EUpdateBoostBadRequestTypeSchema.InvalidFactor]: 'boost.global.errors.invalidFactor',
  [EUpdateBoostBadRequestTypeSchema.InvalidDuration]: 'boost.global.errors.invalidDuration',
  [EUpdateBoostBadRequestTypeSchema.InvalidMaxPayout]: 'boost.global.errors.invalidMaxPayout',
  [EUpdateBoostBadRequestTypeSchema.InvalidStartDate]: 'boost.global.errors.invalidStartDate',
  [EUpdateBoostBadRequestTypeSchema.InvalidAggregationFunction]: 'boost.global.errors.invalidAggregationFunction',
};
