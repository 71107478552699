import React, { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { actionCreators, EMIMEType, GameStudioArguments, GameStudioSchema } from '@greenisland/stores';
import { useAppDispatch } from '@greenisland-core/store';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';

import FileUpload from '@greenisland-common/components/molecules/FileUpload';
import Input from '@greenisland-common/components/molecules/Input';

const useStyles = makeStyles({
  dialogContent: { display: 'grid', gridGap: 16 },
  image: { width: '200px', height: 'fit-content' },
  actions: { padding: '16px 24px' },
});

const GameStudioDialog = (props: {
  open: boolean;
  setOpen: (state: boolean) => void;
  gameStudio: GameStudioSchema;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { register, handleSubmit, errors, control, setValue } = useForm<{
    gameStudioName: string;
    urlName: string;
    code: string;
    logo: any;
  }>({
    defaultValues: {
      gameStudioName: props.gameStudio.gameStudioName,
      urlName: props.gameStudio.urlName,
      code: props.gameStudio.code,
      logo: props.gameStudio.logoUrl,
    },
  });

  const addGameStudio = async (gameStudio: GameStudioArguments) => dispatch(actionCreators.addGameStudio(gameStudio));
  const updateGameStudio = async (gameStudio: GameStudioArguments, gameStudioId: string) =>
    dispatch(actionCreators.updateGameStudio(gameStudio, gameStudioId));

  const handleClose = () => {
    props.setOpen(false);
  };

  const saveGameStudio = (data: any) => {
    props.gameStudio.gameStudioId ? updateGameStudio(data, props.gameStudio.gameStudioId) : addGameStudio(data);
    handleClose();
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={props.open} onClose={handleClose}>
      <form onSubmit={handleSubmit(saveGameStudio)}>
        <DialogTitle>{props.gameStudio?.gameStudioId ? t('editGameStudio') : t('addGameStudio')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Controller
            render={() => (
              <FileUpload
                error={!!errors.logo}
                id="gamestudioImage"
                type="image"
                onChange={file => setValue('logo', file)}
                defaultFile={props.gameStudio.logoUrl}
                buttonTitle="uploadImage"
                requirements={{
                  dimensions: { maxWidth: 180, height: 20 },
                  mimeTypes: [EMIMEType.Imagepng],
                }}
              />
            )}
            control={control}
            rules={{ required: true }}
            defaultValue={props.gameStudio.logoUrl}
            name={'logo'}
          />
          <Input
            fullWidth
            inputRef={register({ required: true })}
            label={t('gameStudio')}
            name="gameStudioName"
            error={errors.gameStudioName ? true : false}
            helperText={errors.gameStudioName && t('required')}
          />
          <Input
            label={t('urlName')}
            name="urlName"
            inputRef={register({ required: true })}
            error={errors.urlName ? true : false}
            helperText={errors.urlName && t('required')}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="text" onClick={handleClose} color="secondary">
            {t('cancel')}
          </Button>
          <Button variant="contained" type="submit" color="primary">
            {t('add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(GameStudioDialog);
