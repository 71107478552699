import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetGameStudios } from '@greenisland/stores';
import { GameStudiosSchema } from '@greenisland/stores/dist/api/schemas';
import { Skeleton } from '@mui/material';

import { AutoCompleteInput } from '@greenisland-common/components/atoms';

import { capitalize } from '@greenisland-common/helpers/formatters';

import { ContentFiltersPrimaryKeysType } from '../helpers/ContentFilterFormContext';

interface Props {
  type: 'included' | 'excluded';
}

const ContentFilterStudioProviders = ({ type }: Props) => {
  const { t } = useTranslation();
  const { data: studios, isLoading } = useGetGameStudios();
  const studiosOptions = useMemo(() => studios?.map(studio => ({ ...studio })) ?? [], [studios]);

  if (isLoading) return <Skeleton variant="rounded" height={56} animation="wave" />;

  return (
    <AutoCompleteInput
      size="small"
      multiple={true}
      name={`${type}Filter.${ContentFiltersPrimaryKeysType.gameStudioPKeys}`}
      label={capitalize(t('addGameStudio'))}
      options={studiosOptions}
      getLabel={option => option?.gameStudioName ?? ''}
      rules={{
        required: true,
        setValueAs: (studios: GameStudiosSchema) => {
          return studios?.map(studio => Number(studio?.gameStudioId));
        },
      }}
    />
  );
};

export default ContentFilterStudioProviders;
