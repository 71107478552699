import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, CheckData, Link, ReactTableGI } from '@greenisland/components';
import { actionCreators } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { setInvisible, transformCurrency } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../Lookup/LookupPermissions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 1fr)',
      gridGap: '15px 15px',
      marginBottom: 15,
    },
    cardContainer: {
      width: 'calc(100% / 3)',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    bold: {
      fontWeight: 'bold',
    },
  })
);

const PlayerActivity = () => {
  const canReadPlayerActivity = usePermission(OnlineCasinoPermissions.getPlayersActivity);
  return canReadPlayerActivity ? <PlayerActivityInnerChild /> : null;
};

const PlayerActivityInnerChild = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });

  const { data: playerActivity, fetching } = useAppSelector(state => state.reportingPlayerActivity);
  const fetchPlayerActivity = (startDate: number, endDate: number) => {
    dispatch(actionCreators.getPlayerActivity(startDate, endDate));
  };

  const mutatedData = () => {
    if (!playerActivity || playerActivity.length === 0) return undefined;
    const keys = Object.keys(playerActivity[0]);

    const headers: any = keys.map(key => {
      switch (key) {
        case 'username':
          return {
            Header: t(key),
            accessor: key,
            Cell: (row: any): any => {
              const userId = row.row.values.userId;
              const username = row.row.values.username;
              return canLookupUser ? <Link to={`../../../users/${userId}/details`}>{username}</Link> : undefined;
            },
          };
        case 'gamingGross':
        case 'bets':
        case 'wins':
          return transformCurrency(key, t);
        case 'userId':
          return setInvisible(key);
        default:
          return { Header: t(key), accessor: key };
      }
    });

    return { headers, rows: playerActivity };
  };

  return (
    <div className={classes.container}>
      <DateRangePicker
        unixStartDate={Number(searchParams.get('startdate')) * 1000}
        unixEndDate={Number(searchParams.get('enddate')) * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
          fetchPlayerActivity(startDate, endDate);
        }}
      />
      <Card>
        <CardBody>
          <CheckData
            fallbackText={t('noData')}
            data={mutatedData()}
            isLoading={fetching}
            checkProperties={['headers', 'rows']}
            render={({ data }) => (
              <ReactTableGI columns={data.headers} data={data.rows} pagination={data.rows.length > 5} sortable />
            )}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default memo(PlayerActivity);
