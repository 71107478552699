import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetUserGeneralQueryKey,
  OrderBechargeErrorSchema,
  useAddToBonusBlacklist,
  useRemoveFromBonusBlacklist,
  UserGeneralSchema,
} from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress } from '@mui/material';
import { getUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

import GenericField from './GenericField';

interface Props {
  user: UserGeneralSchema;
}

const PromotionBlacklistedField = ({ user }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { isBonusBlacklisted } = user;

  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  const { mutate: addToBonusBlacklist, isLoading: isAddToBonusBlacklistLoading } = useAddToBonusBlacklist({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: (error: OrderBechargeErrorSchema) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  });

  const { mutate: removeFromBonusBlacklist, isLoading: isRemoveFromBonusBlacklistLoading } =
    useRemoveFromBonusBlacklist({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
          enqueueSnackbar(t('success'), { variant: 'success' });
        },
        onError: (error: OrderBechargeErrorSchema) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        },
      },
    });

  const handleBonusBlacklist = () => {
    if (isBonusBlacklisted) {
      removeFromBonusBlacklist({ userId: Number(user.userId) });
    } else {
      const unixExpirationDate = expirationDate ? getUnixTime(expirationDate) : null;
      addToBonusBlacklist({ userId: Number(user.userId), data: { expirationDate: unixExpirationDate } });
    }
  };

  if (isAddToBonusBlacklistLoading || isRemoveFromBonusBlacklistLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <GenericField
      value={isBonusBlacklisted}
      error={isBonusBlacklisted}
      tooltip={
        isBonusBlacklisted
          ? t('lookup.verification.removeFromBlacklistTitle')
          : t('lookup.verification.addToBlacklistTitle')
      }
      description={
        isBonusBlacklisted
          ? t('lookup.verification.removeFromBlacklistDescription')
          : t('lookup.verification.addToBlacklistDescription')
      }
      togglePermissions={
        isBonusBlacklisted
          ? OnlineCasinoPermissions.removeFromBonusBlacklist
          : OnlineCasinoPermissions.addToBonusBlacklist
      }
      onToggle={handleBonusBlacklist}
      withDate={!isBonusBlacklisted}
      dateLabel={`${t('expirationDate')} (${t('optional')})`}
      dateValue={expirationDate}
      setDateValue={setExpirationDate}
    />
  );
};

export default PromotionBlacklistedField;
