import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PromotionalCalendarSchema } from '@greenisland/stores';
import { Box, capitalize, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SelectInput } from '@greenisland-common/components/atoms';
import SwitchInput from '@greenisland-common/components/atoms/SwitchInput';
import Input from '@greenisland-common/components/molecules/Input';

import { LoyaltyRankOptionsStatus } from '../../constants';
import { CalendarPromotionDialogSchema, useGetLoyaltyRankOptions } from './helpers';

interface Props {
  selectedPromotionalCalendar?: PromotionalCalendarSchema;
}

const PromotionalCalendarGeneralSettings = ({ selectedPromotionalCalendar }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control } = useFormContext<CalendarPromotionDialogSchema>();
  const loyaltyRankOptions = useGetLoyaltyRankOptions(
    LoyaltyRankOptionsStatus.DISABLE_INACTIVE,
    selectedPromotionalCalendar?.minimalLoyaltyRankRequirement
  );

  return (
    <>
      <SwitchInput
        label={t('marketing.promotionalCalendar.titles.activate')}
        name="active"
        defaultValue={selectedPromotionalCalendar?.active}
        sx={{ mb: 2 }}
      />
      <Stack spacing={2}>
        <Typography fontWeight={500} variant="body1" fontSize="large" sx={{ pt: 1, mb: 1 }}>
          {capitalize(t('common.generalSettings'))}
        </Typography>
        <Box display="flex" flexDirection="column">
          <Input
            size="small"
            label={capitalize(t('marketing.promotionalCalendar.titles.calendarName'))}
            control={control}
            type="text"
            name="name"
            required
            rules={{ required: t('fieldIsRequired') }}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
                mb: 2,
              },
              [theme.breakpoints.up('md')]: {
                width: '45%',
              },
            }}
            defaultValue={selectedPromotionalCalendar?.name}
          />
          <Input
            size="small"
            label={capitalize(t('marketing.promotionalCalendar.titles.calendarType'))}
            control={control}
            type="text"
            name="calendarType"
            required
            rules={{ required: t('fieldIsRequired') }}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
                mb: 2,
              },
              [theme.breakpoints.up('md')]: {
                width: '45%',
              },
            }}
            defaultValue={selectedPromotionalCalendar?.calendarType}
          />
          <SelectInput
            size="small"
            name={`minimalLoyaltyRankRequirement`}
            label={t('marketing.promotionalCalendar.titles.minimalLoyaltyRank')}
            options={loyaltyRankOptions}
            rules={{ required: t('fieldIsRequired') }}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
                mb: 2,
              },
              [theme.breakpoints.up('md')]: {
                width: '45%',
              },
            }}
            required={true}
            clearSelect={true}
            defaultValue={selectedPromotionalCalendar?.minimalLoyaltyRankRequirement}
          />
        </Box>
      </Stack>
    </>
  );
};

export default PromotionalCalendarGeneralSettings;
