import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckData } from '@greenisland/components';
import {
  BackofficeApi,
  getGetGameProviderQueryKey,
  useGetGameProvider,
  useGetGameStudios,
  useUpdateGameProvider,
} from '@greenisland/stores';
import { GameProviderArgumentsSchema } from '@greenisland/stores/dist/api/schemas/gameProviderArgumentsSchema';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../../../app/hooks';
import ProviderFormEdit from './ProviderFormEdit';

const ProviderDetails = memo(() => {
  const { t } = useTranslation();
  const { providerId = '' } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const canReadGameProvider = usePermission(OnlineCasinoPermissions.getGameProvider);
  const canReadGameStudios = usePermission(OnlineCasinoPermissions.getGameStudios);
  const [countries, setCountries] = useState({ fetching: true, data: [], error: '' });

  const {
    data: gameStudios,
    isLoading: isLoadingGameStudios,
    isError: gameStudiosError,
  } = useGetGameStudios({
    query: { enabled: canReadGameStudios },
  });
  const {
    data: gameProvider,
    isLoading: isLoadingGameProvider,
    isError: gameProviderError,
  } = useGetGameProvider(providerId, {
    query: { enabled: canReadGameProvider },
  });

  const isLoading = isLoadingGameProvider || isLoadingGameStudios;
  const isError = gameProviderError || gameStudiosError;

  const updateMutation = useUpdateGameProvider({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('success'), {
          variant: 'success',
        });
        await queryClient.invalidateQueries(getGetGameProviderQueryKey(providerId));
        navigate('./../');
      },
    },
  });

  const fetchCountryList = async () => {
    try {
      const { data } = await BackofficeApi.calls.getCountryList();
      setCountries({ fetching: false, data: data, error: '' });
    } catch (e) {
      setCountries({ ...countries, fetching: false, error: t('errorFetchingCountries') });
    }
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const submit = async (values: GameProviderArgumentsSchema) => {
    updateMutation.mutate({ providerId, data: values });
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !gameStudios || !gameProvider) {
    return <ErrorState />;
  }

  return (
    <CheckData
      data={gameProvider}
      fallbackText={t('noData')}
      render={({ data: gameProviderData }) =>
        countries?.error ? (
          <ErrorState errorMessage={countries.error} />
        ) : (
          <ProviderFormEdit
            gameStudios={gameStudios ? gameStudios : []}
            gameProvider={gameProviderData}
            countries={countries.data}
            submit={submit}
            error={updateMutation.error?.message || ''}
          />
        )
      }
    />
  );
});

ProviderDetails.displayName = 'ProviderDetails';

export default ProviderDetails;
