import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { EUserStatusSchema, getGetUserGeneralQueryKey, UserGeneralSchema, useSetUserStatus } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useSnackbar } from 'notistack';

import GenericField from './GenericField';

interface Props {
  user: UserGeneralSchema;
}

const TooManyLogins = ({ user }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const updateMutation = useSetUserStatus({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: async () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { isLockedStatus } = user;

  const handleUpdateUserStatus = (reason?: string) => {
    updateMutation.mutate({ userId: user.userId, data: { status: EUserStatusSchema.LockedTooManyLogins, reason } });
  };

  return (
    <GenericField
      withReason
      value={isLockedStatus?.tooManyLogins}
      error={isLockedStatus?.tooManyLogins}
      disabled={!isLockedStatus?.tooManyLogins}
      tooltip={
        isLockedStatus?.tooManyLogins
          ? t('lookup.verification.unlockTooManyLoginsTitle')
          : t('lookup.verification.lockTooManyLoginsTitle')
      }
      description={t('lookup.verification.unlockTooManyLoginsDescription')}
      togglePermissions={OnlineCasinoPermissions.setUserStatus}
      onToggle={handleUpdateUserStatus}
    />
  );
};

export default TooManyLogins;
