import { useTranslation } from 'react-i18next';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { ERegulatorSchema, useGetUserGeneral, useGetUserIdentity } from '@greenisland/stores';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';

import {
  DataGridContainer,
  DataGridPlain,
  DataGridPlainHeader,
  ErrorCard,
  FontAwesomeIcon,
  NoData,
} from '@greenisland-common/components/atoms';

import { useTenantContext } from '../../../../../app/context/TenantContext';
import { usePermission } from '../../../../../app/hooks';

interface Props {
  userId: string;
}

const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    renderCell: ({ value }) => <DataGridPlainHeader value={value} />,
    width: 200,
  },
  { headerName: '', field: 'value', renderCell: ({ value }) => value, flex: 1 },
];

const Identity = ({ userId }: Props) => {
  const { t, i18n } = useTranslation();
  const tenant = useTenantContext();
  const canReadUserIdentity = usePermission(OnlineCasinoPermissions.getUserIdentity);
  const canReadUserGeneral = usePermission(OnlineCasinoPermissions.getUserGeneral);

  const {
    data: identity,
    isLoading: isLoadingIdentity,
    isError: isErrorIdentity,
    error: errorIdentity,
  } = useGetUserIdentity(userId, { query: { enabled: canReadUserIdentity } });
  const {
    data: user,
    isLoading: isLoadingUserGeneral,
    isError: isErrorUserGeneral,
    error: errorUserGeneral,
  } = useGetUserGeneral(userId, { query: { enabled: canReadUserGeneral } });

  const isLoading = isLoadingIdentity || isLoadingUserGeneral;
  const isError = isErrorIdentity || isErrorUserGeneral;
  const error = errorIdentity || errorUserGeneral;

  if (!canReadUserIdentity) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={323} />;
  if (isError || !identity || !user) return <ErrorCard sx={{ height: 323 }}>{error?.message}</ErrorCard>;

  const regionNames = new Intl.DisplayNames([i18n.language], { type: 'region' });

  const rows: GridRowsProp = [
    { id: 1, title: t('userId'), value: user.userId },
    {
      id: 2,
      title: t('displayname'),
      value: user?.displayName || identity.firstName,
    },
    { id: 3, title: t('firstname'), value: identity.firstName },
    ...(tenant?.regulatorV2 === ERegulatorSchema.KSA && identity?.lastNamePrefix
      ? [{ id: 4, title: t('lastNamePrefix'), value: identity.lastNamePrefix }]
      : []),
    { id: 5, title: t('lastname'), value: identity.lastName },
    {
      id: 6,
      title: t('dateOfBirth'),
      value: format(new Date(identity.dateOfBirth), 'dd-MM-yyyy'),
    },
    {
      id: 7,
      title: t('nationality'),
      value:
        identity?.nationality && identity.nationality.length > 1
          ? regionNames.of(identity.nationality) || t('notKnown')
          : t('notKnown'),
    },
    { id: 8, title: t('sex'), value: identity?.sex },
    {
      id: 9,
      title: t('lookup.userDetailsMenu.identity.globalUserId'),
      value: (
        <Box sx={{ width: '100%' }}>
          {user.globalUserId ? (
            <Box sx={{ display: 'flex' }}>
              <Box component="span" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {user.globalUserId}
              </Box>
              <Button sx={{ padding: 0 }} onClick={() => navigator.clipboard.writeText(user.globalUserId as string)}>
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Box>
          ) : (
            <Box sx={{ color: 'error.main' }}>{t('lookup.userDetailsMenu.identity.noGlobalUserIdFound')}</Box>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Card sx={{ pl: 0, pr: 0 }}>
      <CardHeader title={t('identity')} />
      <CardContent sx={{ pl: 0, pr: 0 }}>
        {rows.length > 0 ? (
          <DataGridContainer>
            <DataGridPlain columns={headers} rows={rows} />
          </DataGridContainer>
        ) : (
          <NoData />
        )}
      </CardContent>
    </Card>
  );
};

export default Identity;
