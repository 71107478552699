import { Outlet } from 'react-router';
import { faMessage } from '@fortawesome/free-solid-svg-icons';

import {
  AddEmailGroup,
  AddNotificationsTrigger,
  AddSmsGroup,
  EditEmailGroup,
  EditNotificationsTrigger,
  EditSmsGroup,
  EmailGroupsOverview,
  NotificationsTriggersOverview,
  SmsGroupsOverview,
  TemplateForm,
} from '../../pages/OnlineCasino/Notifications';
import { BaseRoute } from '../RouteInterfaces';

export const messagingRoutes: Array<BaseRoute> = [
  {
    name: 'Notifications',
    path: 'notifications',
    collapse: true,
    element: Outlet,
    icon: faMessage,
    children: [
      { name: 'Notification Triggers', path: 'triggers', element: NotificationsTriggersOverview },
      {
        name: 'Add notifications trigger',
        path: 'triggers/add',
        element: AddNotificationsTrigger,
        hiddenLink: true,
      },
      {
        name: 'Edit notifications trigger',
        path: 'triggers/edit/:id',
        element: EditNotificationsTrigger,
        hiddenLink: true,
      },
      { name: 'Notification Templates', path: 'templates', element: TemplateForm },
      { name: 'Email Groups', path: 'emailgroups', element: EmailGroupsOverview },
      { name: 'Add email group', path: 'emailgroups/add', element: AddEmailGroup, hiddenLink: true },
      { name: 'Edit email group', path: 'emailgroups/edit/:id', element: EditEmailGroup, hiddenLink: true },
      { name: 'SMS Groups', path: 'smsgroups', element: SmsGroupsOverview },
      { name: 'Add SMS group', path: 'smsgroups/add', element: AddSmsGroup, hiddenLink: true },
      { name: 'Edit SMS group', path: 'smsgroups/edit/:id', element: EditSmsGroup, hiddenLink: true },
    ],
  },
];

export default messagingRoutes;
